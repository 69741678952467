import React from 'react';

const StatusIcon = ({ classNames }) => {
  return (
    <svg
      className={classNames}
      fill="none"
      height="160"
      viewBox="0 0 200 160"
      width="200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M127.009 23.087L102.454 27.8458C98.8314 28.5478 95.1347 27.0566 93.0133 24.0378C88.8282 18.0821 79.7451 18.9722 76.7958 25.6271L65.4967 51.1224C64.5136 53.3407 63.007 55.2875 61.1061 56.7956L29.4928 81.8772C20.3895 89.0996 25.0236 103.745 36.6246 104.416L41.4719 104.696C50.3398 105.209 54.5326 115.879 48.3866 122.292C40.7932 130.215 49.0438 142.977 59.3858 139.305L81.3521 131.505C84.978 130.218 88.96 130.373 92.4748 131.938L126.802 147.229C134.929 150.85 144.403 146.641 147.164 138.183L147.979 135.69C149.268 131.741 152.137 128.504 155.902 126.749L187.743 111.913C198.612 106.848 194.061 90.4665 182.137 91.7338C171.285 92.8872 165.964 78.8821 174.845 72.539L176.884 71.0826C186.901 63.9272 179.839 48.1869 167.834 50.9129L143.966 56.3325C134.267 58.5349 127.965 46.4179 135.337 39.7415C142.588 33.1743 136.614 21.2257 127.009 23.087Z"
        fill="#1B42B2"
        opacity="0.1"
      />
      <path
        clipRule="evenodd"
        d="M100 122C76.842 122 58 103.158 58 80C58 56.842 76.842 38 100 38C123.158 38 142 56.842 142 80C142 103.158 123.158 122 100 122Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M100 122C76.842 122 58 103.158 58 80C58 56.842 76.842 38 100 38C123.158 38 142 56.842 142 80C142 103.158 123.158 122 100 122Z"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeWidth="2"
      />
      <path
        d="M68 80H64M100 44V48V44ZM100 112V116V112ZM136 80H132H136Z"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M106 78C106 81.314 103.314 84 100 84C96.686 84 94 81.314 94 78C94 74.686 96.686 72 100 72C103.314 72 106 74.686 106 78Z"
        fill="#FFEBE5"
        fillRule="evenodd"
        stroke="#FF5226"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M60 38.038C70.414 28.106 84.508 22 100 22C131.98 22 158 48.018 158 80M76 38H60V22"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="M100 72V60M95.758 82.242L78 100"
        stroke="#FF5226"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="M158 80C158 111.98 131.98 138 100 138C68.02 138 42 111.98 42 80"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default StatusIcon;
