import React, { useRef, useState } from 'react';
import BoxStep from '../../../common/BoxStep';
import OrderConfirmationIcon from '../../../../assets/icons/orderConfirmationIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { AnimatePresence, motion } from 'framer-motion';
import RegisterConfirmation from './RegisterConfirmation';
import { getServerConfig } from '@features/general/generalSlice';
import SuccessRegisterConfirmation from './SuccessRegisterConfirmation';
import { useAppSelector } from '@config/hooks';

type Props = {
  bankAccount: string;
  email: string;
  loyaltyProgramEnabled: boolean;
  orderId: string;
  successPageData: any;
};

const OrderConfirmationTransfer = ({
  bankAccount,
  email,
  loyaltyProgramEnabled,
  orderId,
  successPageData,
}: Props) => {
  const accountNumberRef = useRef(null);
  const emailRef = useRef(null);

  const [accountNumberCopied, setAccountNumberCopied] = useState(false);
  const [emailCopied, setEmailCopied] = useState(false);
  const [sent, setSent] = useState(false);

  const { methodOfPurchase } = successPageData;
  const { loginEnabled } = useAppSelector(getServerConfig);

  const tooltip = (
    visible: boolean,
    onClick: React.MouseEventHandler<SVGSVGElement>
  ) => (
    <>
      <div className="order-confirmation-page__copy-icon">
        <AnimatePresence>
          {visible && (
            <motion.div
              animate={{
                opacity: 1,
                y: 0,
              }}
              className="order-confirmation-page__copy-tooltip"
              exit={{
                opacity: 0,
                y: '-20%',
              }}
              initial={{
                opacity: 0,
                y: '-20%',
                x: '50%',
              }}
              transition={{ duration: 0.2 }}
            >
              Skopiowano!
            </motion.div>
          )}
        </AnimatePresence>
        <FontAwesomeIcon
          className="font-size-24 spacer-left-20"
          icon={faCopy}
          onClick={onClick}
        />
      </div>
    </>
  );

  return sent ? (
    <SuccessRegisterConfirmation />
  ) : (
    <BoxStep>
      <div className="order-confirmation-page">
        <OrderConfirmationIcon />
        <h4 className="h400 spacer-bottom-8 spacer-top-24">
          Dziękujemy za zamówienie
        </h4>
        <p className="body-l spacer-bottom-40 max-width-545">
          Dołożymy wszelkich starań, by nasze posiłki spełniły wszystkie Państwa
          wymagania. Możesz teraz złożyć kolejne zamówienie.{' '}
          {methodOfPurchase === 'guest' &&
            'Zachęcamy również do zarejestrowania konta w Panelu Klienta.'}
        </p>
        <h5>Nr konta do przelewu:</h5>
        <div className="order-confirmation-page__copy-container">
          <p ref={accountNumberRef}>{bankAccount}</p>
          {tooltip(accountNumberCopied, () => {
            if (!accountNumberCopied) {
              setAccountNumberCopied(true);
              const accountNumber =
                // @ts-ignore
                accountNumberRef?.current?.innerText.replace(/ /g, '');
              navigator?.clipboard?.writeText(accountNumber);
              setTimeout(() => setAccountNumberCopied(false), 2000);
            }
          })}
        </div>
        <h5>
          W tytule płatności prosimy wpisać Imię i Nazwisko, oraz przesłanie na
          nasz email potwierdzenia dokonania płatności
        </h5>
        <div className="order-confirmation-page__copy-container">
          <p className="color-gray-500" ref={emailRef}>
            {email}
          </p>
          {tooltip(emailCopied, () => {
            if (!emailCopied) {
              setEmailCopied(true);
              // @ts-ignore
              const emailText = emailRef?.current?.innerText;
              navigator?.clipboard?.writeText(emailText);
              setTimeout(() => setEmailCopied(false), 2000);
            }
          })}
        </div>
      </div>
      {methodOfPurchase === 'guest' && loginEnabled && (
        <RegisterConfirmation
          loyaltyProgramEnabled={loyaltyProgramEnabled}
          orderId={orderId}
          setSent={setSent}
          successPageData={successPageData}
        />
      )}
    </BoxStep>
  );
};

export default OrderConfirmationTransfer;
