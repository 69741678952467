import { RefObject, useState } from 'react';
import useIsWide from '../../../../hooks/useIsWide';
import { useGetApiSearchedCompanyQuery } from '../../../../services/api/form/open/open.api';
import BoxStep from '../../../common/BoxStep';
import Card from '../../../common/Card';
import DietDescriptionAndMenuModal from './menuModal/DietDescriptionAndMenuModal';
import { DietT } from '../../../../@types/menu.type';
import { getSelectedCity } from '../../../../redux/selectors/summaryReducer.selectors';
import { getServerConfig } from '../../../../features/general/generalSlice';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import { classNamesUtil } from '../../../../utils/classNamesUtil.util';
import { storeDetails } from '@redux/actions/orderFormDietDetailsActions';

type Props = {
  customSortDiets: () => any;
  dietRef: RefObject<HTMLDivElement>;
  diets: DietT[];
  handleSelectDiet: (array: DietT | undefined) => void;
  optionHeight: { height: number };
  readMoreDiet: string;
  selectedDiet: DietT | undefined;
  setListHeight: () => void;
  setReadMoreDiet: () => void;
};

const ChooseDietStep = ({
  customSortDiets,
  dietRef,
  diets,
  handleSelectDiet,
  optionHeight,
  readMoreDiet,
  selectedDiet,
  setListHeight,
  setReadMoreDiet,
}: Props) => {
  const dispatch = useAppDispatch();

  const isWide = useIsWide('max', 'desktop');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedReadMoreDiet, setSelectedReadMoreDiet] = useState<DietT>();

  const { cityId } = useAppSelector(getSelectedCity);
  const { companyId } = useAppSelector(getServerConfig) || {};

  const { data } = useGetApiSearchedCompanyQuery(
    {
      companyId,
      cityId,
    },
    { skip: !companyId || !cityId }
  );

  return (
    <>
      {isModalOpen && (
        <DietDescriptionAndMenuModal
          diets={diets}
          isOpen={isModalOpen}
          onClick={() => {
            handleSelectDiet(selectedReadMoreDiet);
            setIsModalOpen(false);
          }}
          onRequestClose={() => {
            setIsModalOpen(false);
          }}
          priceCategory={data?.priceCategory}
          selectedDiet={selectedReadMoreDiet}
        />
      )}
      <BoxStep checkIcon ref={dietRef} title="Wybierz swoją dietę">
        <div
          className={classNamesUtil('steps-page__list', {
            'steps-page__list--flex': isWide,
            'steps-page__list--grid-order-form': !isWide,
          })}
        >
          {customSortDiets()(
            diets.filter(diet => !diet?.menuConfiguration)
          )?.map((diet: DietT) => {
            const { dietId, dietImageUrl, dietName, dietTag, formDescription } =
              diet || {};

            return (
              <Card
                containerClass="diet-card--order-form"
                diet={diet}
                dietDescription={formDescription}
                dietImageUrl={dietImageUrl}
                dietName={dietName}
                dietTag={dietTag}
                fixedBigHeight={diets?.some(item => item?.formDescription)}
                heightList={optionHeight?.height}
                isActive={selectedDiet?.dietId === dietId}
                isNewDescription
                key={dietId}
                onClick={() => {
                  handleSelectDiet(diet);
                }}
                readMore={readMoreDiet}
                readMoreOnClick={() => {
                  setSelectedReadMoreDiet(diet);
                  dispatch(storeDetails('selectedReadMoreDiet', diet));
                  setIsModalOpen(true);
                }}
                setHeight={setListHeight}
                setReadMore={setReadMoreDiet}
              />
            );
          })}
        </div>
      </BoxStep>
    </>
  );
};

export default ChooseDietStep;
