import React, { useEffect, useRef, useState } from 'react';
import ProgressBar from '../components/common/ProgressBar';
import { useAppDispatch, useAppSelector } from '@config/hooks';
import {
  getSelectedCaloriesId,
  getSelectedDiet,
  getSelectedOption,
  getSelectedProgram,
} from '@redux/selectors/orderFormSelector';
import {
  mealExchangeSelectProgram,
  resetOrderForm,
  restoreInitialOrderForm,
  select,
  setDefaultDays,
  storeMeals,
  storePrices,
  toggleOrderCheckbox,
} from '@redux/actions/orderFormActions';
import { useEffectOnce } from 'react-use';
import { storeHistory } from '@redux/actions/orderFormHistoryActions';
import {
  checkIsParamsValid,
  clearBasketLocalStorage,
  mapMeals,
  scrollToRef,
  setSelectedCityInStorage,
} from '../utils';
import {
  checkCustomDietOptions,
  getDietOptions,
} from '@components/pages/firstStep/helpers/firstStepHelpers';
import { storeDetails } from '@redux/actions/orderFormDietDetailsActions';
import { storeSideOrders } from '@redux/actions/sideOrdersActions';
import OrderSelection from '../components/pages/firstStep/components/OrderSelection';
import {
  getCompanyDietDetails,
  isCateringHaveMealConfiguration,
} from '@redux/selectors/orderFormDietDetailsSelector';
import { getBasket, getBasketEdition } from '@redux/selectors/basketSelector';
import { getOrderHistory } from '@redux/selectors/orderFormHistory.selector';
import { useNavigate } from 'react-router-dom';
import Navigation from '../components/common/navigation';
import SelectCity from '../components/pages/firstStep/components/SelectCity';
import {
  getAddressDetails,
  getSelectedCity,
} from '@redux/selectors/summaryReducer.selectors';
import {
  chooseExistingAddress,
  selectCityOrZipcode,
} from '@redux/actions/summaryActions';
import CheckBasketModal from '../components/common/checkBasketModal';
import { getServerConfig } from '@features/general/generalSlice';
import MealErrorModal from '../components/pages/mealExchangeStep/components/mealErrorModal';
import { useEnhancedEcommercePageInfo } from '@hooks/useEnhancedEcommercePageInfo';
import {
  useGetApiDietCaloriesDetailsListQuery,
  useGetApiDietCaloriesDetailsQuery,
  useGetApiDietDetailsQuery,
  useGetApiFormSettingsQuery,
  useGetApiSideOrdersQuery,
} from '@services/api/form/open/open.api';
import usePromoCode from '../hooks/usePromoCode.hook';
import { getHasMenuConfigurationStarted } from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.selector';

const StepSelectDiet = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);

  const inviteCode = urlParams.get('inviteCode');
  const dietParams = urlParams.get('diet');
  const optionParams = urlParams.get('option');
  const caloriesParams = urlParams.get('calories');
  const daysParams = urlParams.get('days');
  const { companyId } = useAppSelector(getServerConfig);

  const { data: formSettings } = useGetApiFormSettingsQuery(undefined, {
    skip: !companyId,
  });

  const selectedCity = useAppSelector(getSelectedCity);
  const selectedDiet = useAppSelector(getSelectedDiet);
  const selectedOption = useAppSelector(getSelectedOption);
  const selectedCaloriesId = useAppSelector(getSelectedCaloriesId);
  const companyDietDetails = useAppSelector(getCompanyDietDetails);
  const basket = useAppSelector(getBasket);
  const orderHistory = useAppSelector(getOrderHistory);
  const { city } = useAppSelector(getAddressDetails);
  const { isEdited } = useAppSelector(getBasketEdition) || null;
  const selectedProgram = useAppSelector(getSelectedProgram);
  const isMenuConfigurationStarted = useAppSelector(
    getHasMenuConfigurationStarted
  );
  const isMealConfigurationEnabled = useAppSelector(
    isCateringHaveMealConfiguration
  );

  const [isBasketModalOpen, setIsBasketModalOpen] = useState(false);
  const [stage, changeStage] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');

  const dietRef = useRef(null);
  const variantRef = useRef(null);
  const caloriesRef = useRef(null);
  const mealsRef = useRef(null);
  const programRef = useRef(null);

  const { data: dietDetails } = useGetApiDietDetailsQuery(companyId, {
    skip: !companyId,
  });

  const { fetchPromoCode } = usePromoCode();

  useEffect(() => {
    if (dietDetails) {
      dispatch(storeDetails('companyDietDetails', dietDetails));
    }
  }, [dietDetails, dispatch]);

  const { data: dietCaloriesDetailsList } =
    useGetApiDietCaloriesDetailsListQuery(
      {
        companyId,
        sectorId: city.sectorId,
      },
      { skip: !companyId || !city.sectorId }
    );

  useEffect(() => {
    if (dietCaloriesDetailsList) {
      dispatch(
        storeDetails('companyDietCaloriesDetails', dietCaloriesDetailsList)
      );
    }
  }, [dietCaloriesDetailsList, dispatch]);

  const { data: sideOrders } = useGetApiSideOrdersQuery(undefined, {
    skip: !companyId,
  });

  useEffect(() => {
    if (sideOrders && sideOrders?.length > 0) {
      // TODO: ONEOFFS
      dispatch(storeSideOrders(sideOrders));
    }
  }, [dispatch, sideOrders]);

  useEffectOnce(() => {
    if (!selectedDiet?.dietId) {
      dispatch(select('selectedOption', ''));
      dispatch(select('selectedCalories', ''));
      dispatch(storeMeals(''));
      dispatch(storePrices(''));
    }

    dispatch(storeHistory(1));

    dispatch(storeDetails('companyId', companyId));

    const storageBasket = localStorage.getItem('diet-basket-form');
    const parsedStorageBasket = storageBasket && JSON.parse(storageBasket);
    const basketName =
      parsedStorageBasket && parsedStorageBasket[0]?.companyName;

    if (basketName && basketName !== companyId) {
      clearBasketLocalStorage();
      dispatch(resetOrderForm());
      dispatch(restoreInitialOrderForm());
    }
  });

  useEffect(() => {
    if (inviteCode) {
      fetchPromoCode(inviteCode, false).catch(e => {
        console.log(e);
      });
    }
    // it's intentionally, don't change it
  }, [dispatch, inviteCode]); // eslint-disable-line

  useEffect(() => {
    if (getDietOptions(selectedDiet)?.length === 1) {
      dispatch(select('selectedOption', getDietOptions(selectedDiet)[0]));
    }
  }, [dispatch, selectedDiet]);

  useEffect(() => {
    if (!selectedOption?.dietOptionId) {
      dispatch(select('selectedCalories', ''));
      dispatch(storeMeals(''));
      dispatch(storePrices(''));
    }
  }, [dispatch, selectedOption.dietOptionId]);

  const { data, isFetching } = useGetApiDietCaloriesDetailsQuery(
    {
      companyId,
      dietCaloriesId: selectedCaloriesId,
    },
    { skip: !companyId || !selectedCaloriesId }
  );

  useEffect(() => {
    if (!isFetching && selectedCaloriesId && data) {
      const { meals, prices } = data;

      dispatch(storeMeals(mapMeals(meals, selectedCaloriesId)));
      dispatch(storePrices(prices));
    }
  }, [data, dispatch, isFetching, selectedCaloriesId]);

  useEffect(() => {
    if (basket?.length && orderHistory?.length < 2 && !isEdited) {
      setIsBasketModalOpen(true);
    }
  }, [basket?.length, isEdited, orderHistory?.length]);

  useEffect(() => {
    if (
      checkIsParamsValid(
        companyDietDetails,
        dietParams,
        optionParams,
        caloriesParams
      ) &&
      dietParams &&
      optionParams &&
      caloriesParams
    ) {
      const selectedDietTest = companyDietDetails.find(
        (diet: { dietName: string }) =>
          diet.dietName.toLowerCase() === dietParams.toLowerCase()
      );

      const selectedOptionTest =
        selectedDietTest &&
        selectedDietTest.dietOptions.find(
          (option: { dietOptionName: string }) =>
            option.dietOptionName.toLowerCase() === optionParams.toLowerCase()
        );

      const selectedCaloriesTest =
        selectedOptionTest &&
        selectedOptionTest.dietCalories.find(
          (calories: { calories: number }) =>
            calories.calories === parseInt(caloriesParams, 10)
        );

      if (
        companyDietDetails.length > 0 &&
        orderHistory.some((historyItem: number) => historyItem === 1)
      ) {
        selectedDietTest &&
          dispatch(
            mealExchangeSelectProgram(
              selectedDietTest?.menuConfiguration
                ? 'menuConfiguration'
                : 'standard'
            )
          );
        selectedDietTest && dispatch(select('selectedDiet', selectedDietTest));
        selectedOptionTest &&
          dispatch(select('selectedOption', selectedOptionTest));
        selectedCaloriesTest &&
          dispatch(select('selectedCalories', selectedCaloriesTest));
        daysParams && dispatch(setDefaultDays(+daysParams));

        Object.keys(selectedCity).length && setRedirect(true);
      }
    }
  }, [
    caloriesParams,
    companyDietDetails,
    daysParams,
    dietParams,
    dispatch,
    optionParams,
    orderHistory,
    selectedCity,
  ]);

  useEffect(() => {
    if (dietParams) {
      dispatch(selectCityOrZipcode('', 'city'));
      clearBasketLocalStorage();
    }
  }, [dietParams, dispatch]);

  useEffect(() => {
    if (stage === 'program' && city) {
      scrollToRef(programRef);
    }
    if (stage === 'diet' && city && selectedProgram) {
      scrollToRef(dietRef);
    }
    if (stage === 'variant' && selectedDiet) {
      if (selectedDiet?.dietOptions?.length > 1) {
        // fixes sometimes buggy scrolling, especially when choosing diet from modal
        setTimeout(() => {
          scrollToRef(variantRef);
        }, 350);
      } else {
        scrollToRef(caloriesRef);
      }
    }
    if (stage === 'calories' && selectedOption) {
      scrollToRef(caloriesRef);
    }
    if (
      stage === 'meals' &&
      checkCustomDietOptions(selectedDiet, formSettings)
    ) {
      scrollToRef(mealsRef);
    }
  }, [
    city,
    formSettings,
    selectedDiet,
    selectedOption,
    selectedProgram,
    sideOrders,
    stage,
  ]);

  useEffect(() => {
    if (stage) {
      dispatch(toggleOrderCheckbox('testOrder', false));
    }
  }, [dispatch, isEdited, stage]);

  const resetSelectedValues = () => {
    dispatch(storeMeals(''));
    // TODO: usunąć wraz z usunięciem wszystkich cen liczonych na froncie
    dispatch(storePrices(''));
  };

  const { canSend, sendEvent } = useEnhancedEcommercePageInfo();
  useEffect(() => {
    canSend && sendEvent();
  }, [canSend, sendEvent]);

  // TODO ANY
  const handleSelectCity = (item: any) => {
    setSelectedValue(item);
    if (selectedCity?.cityId !== item.cityId) {
      changeStage(isMealConfigurationEnabled ? 'program' : 'diet');
      setSelectedCityInStorage(item, basket);
      dispatch(selectCityOrZipcode(item, 'city'));
      dispatch(chooseExistingAddress(0));

      if (
        !checkIsParamsValid(
          companyDietDetails,
          dietParams,
          optionParams,
          caloriesParams
        ) &&
        selectedCity?.sectorId !== item?.sectorId
      ) {
        resetSelectedValues();
      }
    }
  };

  useEffect(() => {
    if (selectedCity.cityId && redirect) {
      navigate('/zamowienie', { replace: true });
    }
  }, [navigate, redirect, selectedCity.cityId]);

  return (
    <>
      <CheckBasketModal
        daysParams={daysParams}
        isBasketModalOpen={isBasketModalOpen}
        setIsBasketModalOpen={setIsBasketModalOpen}
      />
      <Navigation />
      <MealErrorModal sideOrdersValidation />
      <ProgressBar />
      <div className="steps-page">
        <div className="steps-page__container">
          <SelectCity
            city={city}
            handleSelectCity={handleSelectCity}
            setSelectedValue={setSelectedValue}
          />
          {!checkIsParamsValid(
            companyDietDetails,
            dietParams,
            optionParams,
            caloriesParams
          ) && (
            <OrderSelection
              caloriesRef={caloriesRef}
              changeStage={changeStage}
              city={city}
              dietRef={dietRef}
              diets={companyDietDetails}
              isMenuConfigurationStarted={isMenuConfigurationStarted}
              mealsRef={mealsRef}
              programRef={programRef}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              // @ts-ignore
              stage={stage}
              variantRef={variantRef}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default StepSelectDiet;
