import * as actionTypes from '../actions/actionTypes';
import { nanoid } from '@reduxjs/toolkit';

const initialState = {
  orderId: nanoid(),
  selectedDiet: '',
  selectedOption: '',
  selectedCalories: '',
  selectedDietCaloriesIdPrices: [],
  selectedMeals: '',
  days: '',
  selectedSectorId: '',
  selectedDays: [],
  testOrder: false,
  saturdays: true,
  sundays: true,
  selectedProgram: '',
  filteredWeekends: [],
  sets: 1,
  selectedSideOrders: [],
  minMaxModalStatus: '',
  mealExchange: {
    id: nanoid(),
    mealExchangeEditModalClickedArea: '',
    mealExchangeEditModalStatus: false,
    // TODO: MENUCONF
    // TODO: EVENTY te ceny brać z BE
    basicPricePerDay: {
      basicPrice: 0,
      allMeals: 0,
      meals: 0,
      minimum: 0,
      sideOrders: 0,
    },
    totalPrice: {
      totalPriceAfterDiscountWithSideOrders: 0,
      totalPriceAfterDiscount: 0,
      totalPriceBeforeDiscount: 0,
      changesOnMenuConfigurationBeforeDiscount: 0,
      sideOrdersPriceSum: 0,
    },
  },
};

export const orderForm = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STORE_PRICES:
      return {
        ...state,
        selectedDietCaloriesIdPrices: action.prices,
      };
    case actionTypes.RESET_SIDE_ORDERS:
      return {
        ...state,
        selectedSideOrders: [],
      };
    case actionTypes.RESTORE_INITIAL_ORDER_FORM:
      return { ...initialState, orderId: nanoid() };
    case actionTypes.STORE_LAST_ITEM_INTO_ORDER_FORM:
      return action.item;

    case actionTypes.RESET_PREVIOUSLY_SELECTED_DIET_DETAILS:
      return {
        ...state,
        selectedDiet: '',
        selectedOption: '',
        selectedCalories: '',
        selectedMeals: '',
        selectedProgram: '',
        selectedSideOrders: [],
        sets: 1,
      };
    case actionTypes.STORE_CITY:
      return {
        ...state,
        selectedSectorId: action.sector,
        selectedCity: action.name,
      };
    case actionTypes.STORE_CATERING:
      return {
        ...state,
        catering: action.name,
      };
    case actionTypes.STORE_ORDER_FROM_PRICING:
      return {
        ...state,
        selectedDiet: action.diet,
        selectedOption: action.option,
        selectedCalories: action.calories,
        selectedMeals: action.meals,
        days: action.days,
        selectedSectorId: action.sector,
        catering: action.cateringName,
      };
    case actionTypes.UPDATE_ORDER_FORM_REDUCER:
      return {
        ...state,
        ...action.order,
      };
    case actionTypes.RESET_CALENDAR: {
      return {
        ...state,
        filteredWeekends: [],
        selectedDays: [],
      };
    }
    case actionTypes.SET_DEFAULT_WEEKENDS:
      return {
        ...state,
        saturdays: action.saturdays,
        sundays: action.sundays,
      };
    case actionTypes.SET_DEFAULT_DAYS:
      return {
        ...state,
        days: action.days,
      };
    case actionTypes.STORE_EXTRA_DAYS:
      return {
        ...state,
        selectedDays: [...state.selectedDays, ...action.days],
      };
    case actionTypes.DELETE_DAY:
      return {
        ...state,
        selectedDays: state.selectedDays.filter(day => day !== action.day),
      };
    case actionTypes.STORE_DAY:
      return {
        ...state,
        selectedDays: state.selectedDays.concat(action.day),
      };
    case actionTypes.RESET_FILTERED_DAYS:
      return {
        ...state,
        filteredWeekends: action.days,
      };
    case actionTypes.STORE_FILTERED_WEEKENDS:
      return {
        ...state,
        filteredWeekends: state.filteredWeekends.concat(action.days),
      };
    case actionTypes.TOGGLE_DAY: {
      const containsSelectedDay = state.selectedDays.includes(action.day);
      if (containsSelectedDay) {
        return {
          ...state,
          selectedDays: state.selectedDays.filter(
            selectedDay => selectedDay !== action.day
          ),
          days: state.days - 1,
        };
      }
      return {
        ...state,
        selectedDays: state.selectedDays.concat(action.day),
        days: state.days + 1,
      };
    }
    case actionTypes.SELECT_DAYS:
      return {
        ...state,
        selectedDays: action.days,
      };
    case actionTypes.TOGGLE_CHECKBOX:
      return {
        ...state,
        [action.field]: action.checked,
      };
    case actionTypes.SET_DAYS:
      return {
        ...state,
        days: action.days,
      };
    case actionTypes.TOGGLE_MEAL:
      return {
        ...state,
        selectedMeals: state.selectedMeals?.map(meal => {
          if (meal.dietMealId === action.dietMealId) {
            if (action.numberOfSelectedMeals === action.minimumNumberOfMeals) {
              return {
                ...meal,
                selected: meal.selected ? meal.selected : !meal.selected,
              };
            }
            return {
              ...meal,
              selected:
                action.numberOfSelectedMeals > action.minimumNumberOfMeals
                  ? !meal.selected
                  : meal.selected,
            };
          } else {
            return meal;
          }
        }),
      };
    case actionTypes.STORE_MEALS:
      return {
        ...state,
        selectedMeals: action.meals,
      };
    case actionTypes.SELECT: {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case actionTypes.CHANGE_STEP: {
      return {
        ...state,
        step: action.step,
      };
    }
    case actionTypes.CHANGE_MIN_MAX_MODAL_STATUS: {
      return {
        ...state,
        minMaxModalStatus: action.status,
      };
    }
    case actionTypes.MEAL_EXCHANGE_SELECT_PROGRAM:
      return {
        ...state,
        selectedProgram: action.program,
      };
    case actionTypes.MEAL_EXCHANGE_CHANGE_EDIT_MODAL_CLICKED_AREA: {
      const mealExchangeEditModalClickedArea =
        action.mealExchangeEditModalClickedArea;

      return {
        ...state,
        mealExchange: {
          ...state.mealExchange,
          mealExchangeEditModalClickedArea,
          mealExchangeEditModalStatus: !!mealExchangeEditModalClickedArea,
        },
      };
    }
    case actionTypes.MEAL_EXCHANGE_CHANGE_EDIT_MODAL_STATUS: {
      const mealExchangeEditModalStatus = action.mealExchangeEditModalStatus;

      return {
        ...state,
        mealExchange: {
          ...state.mealExchange,
          mealExchangeEditModalStatus,
        },
      };
    }
    case actionTypes.RESET_ORDER_FORM: {
      return initialState;
    }
    default:
      return state;
  }
};
