import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { PriceCategory } from 'typesRoot/cateringCityDetails.type';
import { formApi } from '../index';
import { FormSettingsT } from 'typesRoot/settings.type';
import { ParamsT, TopCityT } from 'typesRoot/companyData.type';
import { DietT } from 'typesRoot/menu.type';
import { SideOrderT } from 'typesRoot/sideOrder.type';
import { DietCaloriesDetailsT } from 'typesRoot/DietCaloriesDetails.type';
import {
  LoyaltyProgramDetailsT,
  ZipCodeT,
} from '@features/orderForm/stepSummary/types/stepSummary.type';
import { SupportedCityT } from 'typesRoot/city/city.type';

export const openApi = formApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    getApiFormSettings: build.query<FormSettingsT, void>({
      query: () => ({
        method: 'GET',
        url: 'form/open/form-settings',
      }),
    }),
    getApiParams: build.query<ParamsT, string>({
      query: companyId => ({
        method: 'GET',
        url: `form/open/company-details/${companyId}/params`,
      }),
    }),
    getApiOrigins: build.query<
      Array<{
        name: string;
        originId: number;
      }>,
      void
    >({
      query: () => ({
        method: 'GET',
        url: 'form/open/origins',
      }),
    }),
    getApiDietDetails: build.query<DietT, string>({
      query: companyId => ({
        method: 'GET',
        url: `form/open/company-details/${companyId}/dietDetails`,
      }),
    }),
    getApiSideOrders: build.query<Array<SideOrderT>, void>({
      query: () => ({
        method: 'GET',
        url: 'form/open/possible-side-orders?withCustomDates=true',
      }),
    }),
    getApiTopCities: build.query<Array<TopCityT>, string>({
      query: companyId => ({
        method: 'GET',
        url: `form/open/company-details/${companyId}/top-cities`,
      }),
    }),
    getApiSearchedCompany: build.query<
      {
        orderPossibleOn: string;
        orderPossibleTo: string;
        priceCategory: PriceCategory;
        sectorId: number;
      },
      {
        cityId: string;
        companyId: string;
      }
    >({
      query: ({ cityId, companyId }) => ({
        method: 'GET',
        url: `form/open/search/${companyId}?cityId=${cityId}`,
      }),
    }),
    getApiDietCaloriesDetails: build.query<
      DietCaloriesDetailsT,
      {
        companyId: string;
        dietCaloriesId: string | number;
      }
    >({
      query: ({ companyId, dietCaloriesId }) => ({
        method: 'GET',
        url: `form/open/company-details/${companyId}/dietCaloriesDetails/${dietCaloriesId}`,
      }),
    }),
    getApiDietCaloriesDetailsList: build.query<
      Array<DietCaloriesDetailsT>,
      {
        companyId: string;
        sectorId: string | number;
      }
    >({
      query: ({ companyId, sectorId }) => ({
        method: 'GET',
        url: `form/open/company-details/${companyId}/dietCaloriesDetails`,
        params: {
          mealPricesSectorId: sectorId,
        },
      }),
    }),
    getApiCity: build.query<Array<SupportedCityT>, string>({
      query: cityId => ({
        method: 'GET',
        url: `form/open/cities/id?cityIds=${cityId}`,
      }),
    }),
    getApiZipCodesSearch: build.query<
      {
        moreAvailable: boolean;
        zipCodes: Array<ZipCodeT>;
      },
      { cityId: string; query: string }
    >({
      query: ({ cityId, query }) => ({
        method: 'GET',
        url: `form/open/city/${cityId}/zip-codes/search/select-list?query=${query}`,
      }),
    }),
    getApiLoyaltyProgramDetails: build.query<LoyaltyProgramDetailsT, string>({
      query: companyId => ({
        method: 'GET',
        url: 'form/open/loyalty-program/regulation',
        headers: {
          'company-id': companyId,
        },
      }),
    }),
  }),
});

export const {
  useGetApiCityQuery,
  useGetApiDietCaloriesDetailsListQuery,
  useGetApiDietCaloriesDetailsQuery,
  useGetApiDietDetailsQuery,
  useGetApiFormSettingsQuery,
  useGetApiLoyaltyProgramDetailsQuery,
  useGetApiOriginsQuery,
  useGetApiParamsQuery,
  useGetApiSearchedCompanyQuery,
  useGetApiSideOrdersQuery,
  useGetApiTopCitiesQuery,
  useLazyGetApiCityQuery,
  useLazyGetApiZipCodesSearchQuery,
} = openApi;
