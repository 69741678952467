import React from 'react';
import Button from '../../../../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import {
  changeCalculatorStep,
  changeIsModalOpen,
} from '../../../../../../features/dietCalculator/dietCalculatorSlice';
import { useDispatch } from 'react-redux';
import CaloriesIcon from '../../../../../../assets/icons/CaloriesIcon';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';

const DietCalculatorStepFifth = ({
  calorie,
  caloriesList,
  handleSelectCalorie,
}) => {
  const dispatch = useDispatch();

  const calorieRounding = Math.floor(calorie / 50) * 50;

  return (
    <>
      <div className="hidden-up-md">
        <Button
          colorClean
          containerClass="cursor-pointer color-gray-500 spacer-bottom-12"
          icon={<FontAwesomeIcon icon={faArrowLeft} />}
          label={
            <span className="label-s color-gray-500">Edytuj konfigurację</span>
          }
          onClick={() => {
            dispatch(changeCalculatorStep(1));
          }}
        />
      </div>
      <div className="calculator-modal__summary-header--shortened">
        <CaloriesIcon className="meal-icon-color" />
        <div>
          <p className="body-m spacer-bottom-4 text-align-center">
            Dla tej diety najbliższa dostępna kaloryczność to
          </p>
          <h3 className="h200 spacer-bottom-24">{calorieRounding} kcal</h3>
        </div>
        <Button
          icon={
            <FontAwesomeIcon className="font-size-20" icon={faCheckCircle} />
          }
          label="Wybierz kaloryczność"
          onClick={() => {
            const findCalorieItem = caloriesList.reduce((prev, curr) =>
              Math.abs(curr.calories - calorieRounding) <
              Math.abs(prev.calories - calorieRounding)
                ? curr
                : prev
            );

            handleSelectCalorie(findCalorieItem);
            dispatch(changeCalculatorStep(1));
            dispatch(changeIsModalOpen(false));
          }}
        />
      </div>
    </>
  );
};

export default DietCalculatorStepFifth;
