import { ReactNode } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

type Props = {
  children: ReactNode;
  shouldShow: boolean;
};

const AnimationContainer = ({ children, shouldShow = false }: Props) => {
  return (
    <AnimatePresence>
      {shouldShow && (
        <motion.div
          animate={{
            opacity: 1,
            height: 'auto',
          }}
          exit={{
            opacity: 0,
            height: 0,
          }}
          initial={{
            opacity: 0,
            height: 'auto',
          }}
          transition={{ duration: 0.3 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AnimationContainer;
