export const classNamesUtil = (
  staticClass: string,
  otherClass?: Record<string, boolean>
) => {
  if (!otherClass) {
    return staticClass;
  }

  const keys = Object.keys(otherClass);

  if (keys.length === 0) {
    return staticClass;
  }

  const filterKeys = keys.filter(item => otherClass[item]);

  if (filterKeys.length === 0) {
    return staticClass;
  }

  return `${staticClass} ${filterKeys.join(' ')}`;
};
