import React from 'react';
import PropTypes from 'prop-types';

const CartIcon = ({
  className,
  color = '#06257F',
  height = '32',
  width = '32',
}) => {
  return (
    <svg
      className={className}
      fill="none"
      height={height}
      viewBox="0 0 32 32"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3333 9.33366H21.3333V8.00033C21.3333 6.58584 20.7713 5.22928 19.7712 4.22909C18.771 3.2289 17.4144 2.66699 15.9999 2.66699C14.5854 2.66699 13.2289 3.2289 12.2287 4.22909C11.2285 5.22928 10.6666 6.58584 10.6666 8.00033V9.33366H6.66659C6.31296 9.33366 5.97382 9.47413 5.72378 9.72418C5.47373 9.97423 5.33325 10.3134 5.33325 10.667V25.3337C5.33325 26.3945 5.75468 27.4119 6.50482 28.1621C7.25497 28.9122 8.27239 29.3337 9.33325 29.3337H22.6666C23.7274 29.3337 24.7449 28.9122 25.495 28.1621C26.2452 27.4119 26.6666 26.3945 26.6666 25.3337V10.667C26.6666 10.3134 26.5261 9.97423 26.2761 9.72418C26.026 9.47413 25.6869 9.33366 25.3333 9.33366ZM13.3333 8.00033C13.3333 7.29308 13.6142 6.6148 14.1143 6.11471C14.6144 5.61461 15.2927 5.33366 15.9999 5.33366C16.7072 5.33366 17.3854 5.61461 17.8855 6.11471C18.3856 6.6148 18.6666 7.29308 18.6666 8.00033V9.33366H13.3333V8.00033ZM23.9999 25.3337C23.9999 25.6873 23.8594 26.0264 23.6094 26.2765C23.3593 26.5265 23.0202 26.667 22.6666 26.667H9.33325C8.97963 26.667 8.64049 26.5265 8.39044 26.2765C8.14039 26.0264 7.99992 25.6873 7.99992 25.3337V12.0003H10.6666V13.3337C10.6666 13.6873 10.8071 14.0264 11.0571 14.2765C11.3072 14.5265 11.6463 14.667 11.9999 14.667C12.3535 14.667 12.6927 14.5265 12.9427 14.2765C13.1928 14.0264 13.3333 13.6873 13.3333 13.3337V12.0003H18.6666V13.3337C18.6666 13.6873 18.8071 14.0264 19.0571 14.2765C19.3072 14.5265 19.6463 14.667 19.9999 14.667C20.3535 14.667 20.6927 14.5265 20.9427 14.2765C21.1928 14.0264 21.3333 13.6873 21.3333 13.3337V12.0003H23.9999V25.3337Z"
        fill={color}
      />
    </svg>
  );
};

CartIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default CartIcon;
