import React from 'react';
import { useAppDispatch } from '../../../../../config/hooks';
import { DeliveryMethodEnum } from '../../../../../features/pickupPoint/types/deliveryMethod.enum';
import {
  changeDeliveryMethod,
  selectCityOrZipcode,
  selectDeliveryHours,
} from '../../../../../redux/actions/summaryActions';
import Button from '../../../../common/Button';
import { FormSettingsT } from '../../../../../@types/settings.type';

type Props = {
  deliveryMethod: DeliveryMethodEnum;
  orderSettings: FormSettingsT['orderSettings'] | undefined;
  selectedProfileAddress: any;
  setTimeOfDelivery: (s: string) => void;
};

const DeliveryMethod = ({
  deliveryMethod,
  orderSettings,
  selectedProfileAddress,
  setTimeOfDelivery,
}: Props) => {
  const dispatch = useAppDispatch();

  return orderSettings?.orderingToPickupPointEnabled ? (
    <div className="steps-page-third__tab-wrapper">
      <Button
        colorBlank={deliveryMethod !== DeliveryMethodEnum.E}
        colorPrimary={deliveryMethod === DeliveryMethodEnum.E}
        label="Dostawa na adres"
        onClick={() => {
          if (deliveryMethod === DeliveryMethodEnum.P) {
            dispatch(changeDeliveryMethod(DeliveryMethodEnum.E));
            dispatch(selectDeliveryHours(''));
            setTimeOfDelivery('');

            if (selectedProfileAddress?.supportedCity?.cityId) {
              dispatch(
                selectCityOrZipcode(
                  selectedProfileAddress?.supportedCity,
                  'city'
                )
              );
            }
          }
        }}
      />

      <Button
        colorBlank={deliveryMethod !== DeliveryMethodEnum.P}
        colorPrimary={deliveryMethod === DeliveryMethodEnum.P}
        label="Odbiór w punkcie"
        onClick={() => {
          if (deliveryMethod === DeliveryMethodEnum.E) {
            dispatch(changeDeliveryMethod(DeliveryMethodEnum.P));
            dispatch(selectDeliveryHours(''));
            setTimeOfDelivery('');
          }
        }}
      />
    </div>
  ) : (
    <></>
  );
};

export default DeliveryMethod;
