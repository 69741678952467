type Props = {
  color?: string;
  height?: string;
  width?: string;
};

const ThumbUpIcon = ({
  color = '#8FA1B3',
  height = '20',
  width = '20',
}: Props): JSX.Element => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 20 20"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3017 8.08003C19.0207 7.74268 18.669 7.47111 18.2716 7.2845C17.8741 7.09789 17.4406 7.00078 17.0015 7.00003H12.4411L13.0011 5.57003C13.2341 4.94392 13.3117 4.27064 13.2272 3.60796C13.1428 2.94527 12.8988 2.31297 12.5163 1.76527C12.1337 1.21757 11.624 0.770839 11.0309 0.463387C10.4378 0.155934 9.77889 -0.00305942 9.11079 4.45911e-05C8.91842 0.000445952 8.73024 0.0563197 8.56882 0.160967C8.4074 0.265614 8.27959 0.414594 8.20071 0.590043L5.35047 7.00003H3.00026C2.20454 7.00003 1.44141 7.3161 0.878756 7.87871C0.316098 8.44131 0 9.20438 0 10V17C0 17.7957 0.316098 18.5587 0.878756 19.1213C1.44141 19.6839 2.20454 20 3.00026 20H15.7314C16.4332 19.9998 17.1128 19.7535 17.6519 19.304C18.1909 18.8546 18.5553 18.2304 18.6816 17.54L19.9517 10.54C20.0303 10.1074 20.0128 9.6628 19.9005 9.2377C19.7881 8.8126 19.5837 8.41738 19.3017 8.08003ZM5.00043 18H3.00026C2.73502 18 2.48065 17.8946 2.29309 17.7071C2.10554 17.5196 2.00017 17.2652 2.00017 17V10C2.00017 9.73481 2.10554 9.48045 2.29309 9.29292C2.48065 9.10538 2.73502 9.00002 3.00026 9.00002H5.00043V18ZM18.0016 10.18L16.7315 17.18C16.6889 17.413 16.565 17.6232 16.3818 17.7734C16.1987 17.9236 15.9682 18.0039 15.7314 18H7.00061V8.21003L9.72085 2.09004C10.0009 2.17167 10.2609 2.31045 10.4846 2.49762C10.7082 2.6848 10.8907 2.91631 11.0204 3.17753C11.15 3.43876 11.2242 3.72403 11.238 4.01534C11.2519 4.30665 11.2052 4.59767 11.101 4.87003L10.5709 6.30003C10.458 6.60229 10.4198 6.92738 10.4598 7.24756C10.4997 7.56774 10.6165 7.87351 10.8003 8.13877C10.984 8.40402 11.2292 8.62089 11.5149 8.77086C11.8006 8.92084 12.1184 8.99946 12.4411 9.00002H17.0015C17.1484 8.99979 17.2936 9.03192 17.4267 9.09414C17.5598 9.15636 17.6775 9.24714 17.7715 9.36002C17.8679 9.47135 17.9385 9.60257 17.9782 9.74431C18.018 9.88606 18.026 10.0348 18.0016 10.18Z"
        fill={color}
      />
    </svg>
  );
};

export default ThumbUpIcon;
