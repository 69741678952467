interface EmptyMenuProps {
  colorPrimary?: string;
  colorSecondary?: string;
  width?: string;
  height?: string;
}

const EmptyMenu = ({
  colorPrimary = '#ffffff',
  colorSecondary = '#1f2e4d',
  height = '80',
  width = '80',
}: EmptyMenuProps) => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 80 80"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M21 20H28V12H21V20Z"
        fill={colorPrimary}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M52 20H59V12H52V20Z"
        fill={colorPrimary}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M22 32C21.4477 32 21 32.4477 21 33V60C21 60.5523 21.4477 61 22 61H58C58.5523 61 59 60.5523 59 60V33C59 32.4477 58.5523 32 58 32H22ZM50 34V41H57V34H50ZM48 34H41V41H48V34ZM57 43H50V50H57V43ZM48 52H41V59H48V52ZM41 50H48V43H41V50ZM39 34V41H32V34H39ZM50 52V59H57V52H50ZM39 52H32V59H39V52ZM30 52V59H23V52H30ZM39 50H32V43H39V50ZM30 43H23V50H30V43ZM30 34V41H23V34H30Z"
        fill={colorSecondary}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M21 11C20.4477 11 20 11.4477 20 12V15H15C14.4477 15 14 15.4477 14 16V67C14 67.5523 14.4477 68 15 68H65C65.5523 68 66 67.5523 66 67V16C66 15.4477 65.5523 15 65 15H60V12C60 11.4477 59.5523 11 59 11H52C51.4477 11 51 11.4477 51 12V15H29V12C29 11.4477 28.5523 11 28 11H21ZM22 19V13H27V19H22ZM16 66V27H64V66H16ZM64 17V25H16V17H20V20C20 20.5523 20.4477 21 21 21H28C28.5523 21 29 20.5523 29 20V17H51V20C51 20.5523 51.4477 21 52 21H59C59.5523 21 60 20.5523 60 20V17H64ZM58 13V19H53V13H58Z"
        fill={colorSecondary}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default EmptyMenu;
