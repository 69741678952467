import Downshift from 'downshift';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';
import { findDietTagImg } from '../../utils/index';
import { classNamesUtil } from 'utilsRoot/classNamesUtil.util';
import { useGetDietTagInfoAllQuery } from '@services/api/form/open/dietTagInfo.api';

export const Select = ({
  containerClass,
  dietUrl,
  disabled,
  id,
  items,
  label,
  placeholder,
  selectedItem,
  selectedItemKey,
  selectItem,
  selectLink,
  smallHeight,
}) => {
  const { data: dietTagsData } = useGetDietTagInfoAllQuery();

  return (
    <>
      <Downshift
        id={id}
        itemToString={item => (item ? item[selectedItemKey] : '')}
        onChange={item => selectItem(item)}
      >
        {({ getItemProps, getToggleButtonProps, isOpen }) => (
          <div
            className={classNamesUtil('select', {
              [`${containerClass}`]: !!containerClass,
            })}
          >
            {label && (
              <p className="label-m color-gray-500 spacer-bottom-8">{label}</p>
            )}
            <div
              className={classNamesUtil('select__button label-m', {
                'select__button--link': !!selectLink,
                'select__button--small': !!smallHeight,
                'select__button--open': !!isOpen,
              })}
              {...getToggleButtonProps()}
            >
              <span
                className={classNamesUtil({
                  'select__selectedItem--link': !!selectLink,
                  'select__selectedItem': !dietUrl,
                  'select__selectedItemWithImg': !!dietUrl,
                })}
              >
                {(() => {
                  if (selectedItem && Object.keys(selectedItem).length > 0) {
                    if (dietUrl) {
                      return (
                        <span className="display-flex align-items-center">
                          <img
                            alt={selectedItem?.dietName}
                            src={
                              selectedItem?.dietImageUrl
                                ? selectedItem?.dietImageUrl
                                : findDietTagImg(
                                    selectedItem?.dietTag,
                                    dietTagsData
                                  )
                            }
                          />
                          {selectedItem[selectedItemKey]}
                        </span>
                      );
                    }

                    if (!selectedItem[selectedItemKey]) {
                      return placeholder ? placeholder : 'Wybierz...';
                    }

                    return selectedItem[selectedItemKey];
                  }

                  if (placeholder) {
                    return placeholder;
                  }

                  return 'Wybierz...';
                })()}
              </span>
              {!disabled && (
                <span
                  className={classNamesUtil('select__selectedItem-icon', {
                    'select__selectedItem-icon-select-link':
                      selectLink || selectedItem,
                    'select__selectedItem-icon--open': isOpen,
                  })}
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </span>
              )}
            </div>
            <div className="select__itemsWrapper">
              {isOpen && !disabled && (
                <div className="select__downshiftDropdown">
                  {items.map((item, index) =>
                    dietUrl ? (
                      <div
                        className="select__dropdownItemWithUrl label-m"
                        key={index}
                        {...getItemProps({ key: index, index, item })}
                      >
                        {dietUrl && (
                          <img
                            alt={item?.dietName}
                            src={
                              item?.dietImageUrl
                                ? item?.dietImageUrl
                                : findDietTagImg(item?.dietTag, dietTagsData)
                            }
                          />
                        )}
                        {item[selectedItemKey]}
                      </div>
                    ) : (
                      <div
                        className="select__dropdownItem label-m"
                        key={index}
                        {...getItemProps({ key: index, index, item })}
                      >
                        {item[selectedItemKey]}
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </Downshift>
    </>
  );
};

Select.propTypes = {
  containerClass: PropTypes.string,
  dietUrl: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.string,
  selectedItem: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  selectedItemKey: PropTypes.string.isRequired,
  selectItem: PropTypes.func.isRequired,
  selectLink: PropTypes.bool,
  smallHeight: PropTypes.bool,
};
