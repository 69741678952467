import SmallMenu from '@assets/icons/SmallMenu';

const MenuNotPublishedYet = () => {
  return (
    <div className="display-flex flex-direction-column justify-content-center align-items-center text-align-center spacer-vertical-90">
      <SmallMenu />

      <h3 className="h400 spacer-bottom-6">Menu niedostępne</h3>

      <p className="body-l">
        Catering nie opublikował jeszcze menu na wybrany dzień
      </p>
    </div>
  );
};

export default MenuNotPublishedYet;
