import AnimationContainer from '@features/orderForm/stepShoppingCart/views/oneOffs/AnimationContainer';
import OneOffsShoppingCart from '@features/orderForm/stepShoppingCart/views/oneOffs/OneOffsShoppingCart';
import { SideOrderT } from 'typesRoot/sideOrder.type';

import OneOffsCarousel from './OneOffsCarousel';
import { useAppSelector } from '@config/hooks';
import { getOneOffsSelectedFees } from '@features/orderForm/stepShoppingCart/redux/oneOffSideOrders.selector';
import { useGetPossibleSideOrdersQuery } from '@services/api/form/open/possibleSideOrders/possibleSideOrders.api';
import { getServerConfig } from '@features/general/generalSlice';

const OneOffSideOrders = () => {
  const selectedFees = useAppSelector(getOneOffsSelectedFees) || [];
  const { companyId } = useAppSelector(getServerConfig);

  const { data } = useGetPossibleSideOrdersQuery(
    { companyName: companyId, withCustomDates: true },
    {
      skip: !companyId,
    }
  );
  const { oneOffs = [] } = data || {};

  const availableFees = oneOffs.filter(
    oneOffSideOrder =>
      !selectedFees.some(
        (selectedFee: SideOrderT) =>
          selectedFee.possibleSideOrderId ===
          oneOffSideOrder.possibleSideOrderId
      )
  );

  return (
    <>
      <AnimationContainer shouldShow={!!selectedFees?.length}>
        <OneOffsShoppingCart selectedFees={selectedFees} />
      </AnimationContainer>
      <AnimationContainer shouldShow={!!availableFees?.length}>
        <OneOffsCarousel availableFees={availableFees} />
      </AnimationContainer>
    </>
  );
};

export default OneOffSideOrders;
