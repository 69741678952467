import React from 'react';
import PropTypes from 'prop-types';

const SmallMealIcon = ({ className, height = 80, width = 80 }) => {
  return (
    <svg
      className={className}
      fill="none"
      height={height}
      viewBox="0 0 80 80"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M67 49H13C11.9 49 11 48.1 11 47C11 45.9 11.9 45 13 45H67C68.1 45 69 45.9 69 47C69 48.1 68.1 49 67 49Z"
        fill="white"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M67 49H13C11.9 49 11 48.1 11 47C11 45.9 11.9 45 13 45H67C68.1 45 69 45.9 69 47C69 48.1 68.1 49 67 49Z"
        fill="#ECEEF8"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M13.458 49C16.421 53.629 29 59.205 29 66H51C51 59.205 63.579 53.629 66.542 49H13.458Z"
        fill="white"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M13.458 49C16.421 53.629 29 59.205 29 66H51C51 59.205 63.579 53.629 66.542 49H13.458Z"
        fill="white"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M23 32V31C23 27.684 25.684 25 29 25C37.844 25 41 33.156 41 42"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M20 42C20 36.473 24.473 32 30 32H32M26 42C26 39.789 27.789 38 30 38H35M34 35H30C26.131 35 23 38.131 23 42M30 41H35M59 42C59 36.473 54.527 32 49 32H42M53 42C53 39.789 51.211 38 49 38H44M43 35H49C52.869 35 56 38.131 56 42M49 41H44M27 30C27 28.894 27.894 28 29 28C36.185 28 38 34.814 38 42M23 32V31C23 27.684 25.684 25 29 25C37.844 25 41 33.156 41 42"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M46 26C46 27.657 44.657 29 43 29C41.343 29 40 27.657 40 26C40 24.343 41.343 23 43 23C44.657 23 46 24.343 46 26Z"
        fill="white"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M46 26C46 27.657 44.657 29 43 29C41.343 29 40 27.657 40 26C40 24.343 41.343 23 43 23C44.657 23 46 24.343 46 26Z"
        fill="white"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M39 20.5C39 22.433 37.433 24 35.5 24C33.567 24 32 22.433 32 20.5C32 18.567 33.567 17 35.5 17C37.433 17 39 18.567 39 20.5Z"
        fill="white"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M39 20.5C39 22.433 37.433 24 35.5 24C33.567 24 32 22.433 32 20.5C32 18.567 33.567 17 35.5 17C37.433 17 39 18.567 39 20.5Z"
        fill="#ECEEF8"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M55 27C55 28.657 53.657 30 52 30C50.343 30 49 28.657 49 27C49 25.343 50.343 24 52 24C53.657 24 55 25.343 55 27Z"
        fill="white"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M55 27C55 28.657 53.657 30 52 30C50.343 30 49 28.657 49 27C49 25.343 50.343 24 52 24C53.657 24 55 25.343 55 27Z"
        fill="#ECEEF8"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M49 18C49 19.657 47.657 21 46 21C44.343 21 43 19.657 43 18C43 16.343 44.343 15 46 15C47.657 15 49 16.343 49 18Z"
        fill="white"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M49 18C49 19.657 47.657 21 46 21C44.343 21 43 19.657 43 18C43 16.343 44.343 15 46 15C47.657 15 49 16.343 49 18Z"
        fill="#ECEEF8"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M28.5 63H51.5H28.5Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M28.5 63H51.5"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

SmallMealIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default SmallMealIcon;
