import { getPrice } from './orderFormPrices';

export const getEnhancedEcommerceProducts = (
  product,
  selectedCity,
  code,
  productPrice,
  companyId,
  clientLifecycleState
) => {
  const {
    saturdays,
    selectedCalories: { calories, dietCaloriesId },
    selectedDays,
    selectedDiet: { dietId, dietName },
    selectedOption: { dietOptionId },
    selectedSideOrders,
    sets,
    sundays,
    testOrder,
  } = product;

  const gtmData = {
    brand: companyId,
    category: 'Diety',
    id: `${dietId}-${dietOptionId}-${dietCaloriesId}`,
    name: dietName,
    variant: calories,
    price: productPrice / sets,
    quantity: sets,
    coupon: code,
    dimension12: selectedDays.length,
    dimension13: selectedSideOrders && selectedSideOrders.length > 0 ? 1 : 0,
    dimension14: testOrder ? 1 : 0,
    dimension15: saturdays || sundays ? 1 : 0,
    dimension16: new Date(selectedDays[0])?.toLocaleDateString(),
    dimension17: new Date([...selectedDays].reverse()[0])?.toLocaleDateString(),
    dimension18: selectedCity && selectedCity.name,
  };

  if (clientLifecycleState) {
    gtmData.dimension20 = clientLifecycleState;
  }

  return gtmData;
};

export const getEnhancedEcommerceCartStep = (
  basket,
  selectedCity,
  code,
  step,
  companyId
) => {
  if (!basket.length) {
    return null;
  }

  return {
    event: 'checkout',
    pageType: 'Checkout - Cart',
    ecommerce: {
      currencyCode: 'PLN', // Currency type.
      checkout: {
        actionField: {
          step,
        },
        products: basket?.map(item =>
          getEnhancedEcommerceProducts(
            item,
            selectedCity,
            code,
            getPrice(item, code, selectedCity?.sectorId),
            companyId
          )
        ),
      },
    },
  };
};

export const handleAddToCartEnhancedEcommerceData = (
  product,
  selectedCity,
  code,
  productPrice,
  companyId
) => {
  return {
    event: 'addToCart',
    eventCategory: 'Ecommerce',
    eventAction: 'Add to Cart',
    pageType: 'Product details',
    ecommerce: {
      currencyCode: 'PLN', // Currency type.
      add: {
        products: [
          getEnhancedEcommerceProducts(
            product,
            selectedCity,
            code,
            productPrice,
            companyId
          ),
        ],
      },
    },
  };
};

export const handleRemoveFromCartEnhancedEcommerceData = (
  product,
  selectedCity,
  code,
  productPrice,
  companyId
) => {
  return {
    event: 'removeFromCart',
    eventCategory: 'Ecommerce',
    eventAction: 'Remove from Cart',
    pageType: 'Product details',
    ecommerce: {
      currencyCode: 'PLN', // Currency type.
      remove: {
        products: [
          getEnhancedEcommerceProducts(
            product,
            selectedCity,
            code,
            productPrice,
            companyId
          ),
        ],
      },
    },
  };
};

export const handleEcommerceData = (basket, finalPrice, selectedCity) => {
  if (basket.length === 0) {
    return null;
  }

  const { sectorId } = selectedCity;

  return {
    transactionId: basket[0].orderId,
    transactionTotal: finalPrice,
    transactionProducts: basket.map(item => {
      const {
        selectedCalories: { calories, dietCaloriesId },
        selectedDiet: { dietId, dietName },
        selectedDietCaloriesIdPrices,
        selectedOption: { dietOptionId, dietOptionName },
        sets,
      } = item;

      const price = selectedDietCaloriesIdPrices?.find(
        sector => sector?.sectorId === sectorId
      );

      return {
        sku: `${dietId}-${dietOptionId}-${dietCaloriesId}`,
        name: `${dietName}-${dietOptionName}-${calories}`,
        price: (price ? price?.price : 0) * sets,
        quantity: sets,
      };
    }),
  };
};

export const handleEnhancedEcommerceData = (
  basket,
  loggedIn,
  finalPrice,
  typOfPayment,
  selectedCity,
  code,
  newOrderIds,
  previousOrdersNumber,
  clientId,
  companyId,
  shoppingCartId,
  clientLifecycleState,
  email,
  crm_transaction_id
) => {
  if (!basket.length) {
    return null;
  }
  return {
    pageType: 'Transaction',
    ID: newOrderIds.join(', '),
    userLoggedIn: loggedIn ? 1 : 0,
    userID: clientId,
    new_client: previousOrdersNumber ? 0 : 1,
    orders_cnt: previousOrdersNumber,
    userMail: email,
    ecommerce: {
      currencyCode: 'PLN', // Currency type.
      purchase: {
        actionField: {
          id: shoppingCartId, // Transaction ID.
          affiliation: 'Online Store', // The store or affiliation from which this transaction occurred (e.g. Google Store).
          revenue: finalPrice, // Total transaction value (incl. tax and shipping)
          tax: 0, // The total tax associated with the transaction.
          shipping: 0, // The shipping cost associated with the transaction.
          payment_method: typOfPayment, // Payment method.
          crm_transaction_id, // for kuchniavikinga some ID, else undefined
        },
        products: basket?.map(item =>
          getEnhancedEcommerceProducts(
            item,
            selectedCity,
            code,
            getPrice(item, code, selectedCity?.sectorId),
            companyId,
            clientLifecycleState
          )
        ),
      },
    },
  };
};
