import {
  configureStore,
  isRejectedWithValue,
  Middleware,
} from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { formApi } from '../services/api/form';
import { modalErrorPresentationE } from '../features/modalError/types/modalError.type';
import { setModalError } from '../features/modalError/services/redux/modalError.slice';

const hostname = window.location.hostname;

const rtkQueryErrorLogger: Middleware =
  ({ dispatch }) =>
  next =>
  action => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      const { status } = action.payload;
      if (status === 490) {
        const {
          data: {
            message = '',
            presentation = modalErrorPresentationE.Modal,
          } = {},
        } = action.payload;
        message &&
          dispatch(
            setModalError({
              message,
              presentation,
            })
          );
      }
    }

    return next(action);
  };

export default configureStore({
  reducer: rootReducer,
  devTools: hostname === 'localhost',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(formApi.middleware, rtkQueryErrorLogger),
  preloadedState: {},
});
