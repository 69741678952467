import { MenuConfMenuMealT } from '@features/orderForm/stepMenuConfiguration/types/menuConf.type';
import { useAppSelector } from '@config/hooks';
import {
  getMenuConfActiveDay,
  getMenuConfCustomDeliveryMeals,
} from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.selector';

export const useIsCaloriesSwitchPossible = (
  carouselId: string,
  options: Array<MenuConfMenuMealT>
) => {
  const customDeliveryMeals = useAppSelector(getMenuConfCustomDeliveryMeals);
  const activeDay = useAppSelector(getMenuConfActiveDay);

  if (customDeliveryMeals && activeDay && activeDay in customDeliveryMeals) {
    const selectedDietCaloriesId = (customDeliveryMeals[activeDay] || {})[
      carouselId
    ]?.dietCaloriesMealId;

    const carouselMeal = options.find(
      el => el.dietCaloriesMealId === selectedDietCaloriesId
    );
    return carouselMeal && carouselMeal.caloriesSwitchPossible;
  }
  return false;
};
