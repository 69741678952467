export const getDayDiscount = (discounts, daysAmount) => {
  if (!(daysAmount && discounts)) {
    return null;
  }
  return discounts.reduce(
    (acc, curr) => {
      const accExtraDays = daysAmount - acc.minimumDays;
      const currExtraDay = daysAmount - curr.minimumDays;

      if (currExtraDay < 0) {
        return acc;
      }

      if (currExtraDay < accExtraDays) {
        return curr;
      }
      return acc;
    },
    { minimumDays: 0, discount: 0, discountType: 'PERCENTAGE' }
  );
};
