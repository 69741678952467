import { combineReducers } from 'redux';
import locale from '../features/locale/localeSlice';
import general from '../features/general/generalSlice';
import dietCalculator from '../features/dietCalculator/dietCalculatorSlice';

import { orderForm } from '@redux/reducers/orderFormReducer';
import { basketReducer } from '@redux/reducers/basketReducer';
import { sideOrdersReducer } from '@redux/reducers/sideOrdersReducer';
import { summaryReducer } from '@redux/reducers/summaryReducer';
import { profileReducer } from '@redux/reducers/profileReducer';
import { orderFormHistoryReducer } from '@redux/reducers/orderFormHistoryReducer';
import { orderFormDietDetailsReducer } from '@redux/reducers/orderFormDietDetailsReducer';
import { formApi } from '@services/api/form';
import enhancedEcommerceSlice from '../redux/slices/enhancedEcommerce.slice';
import salesManagoSlice from '@salesManago/services/redux/salesManago.slice';
import modalErrorSlice from '../features/modalError/services/redux/modalError.slice';
import serverOverloadSlice from '../redux/slices/serverOverload.slice';
import combineOrderFormSlice from '../features/orderForm/common/services/redux/combineOrderFormSlice';

const rootReducer = combineReducers({
  [formApi.reducerPath]: formApi.reducer,
  basketReducer,
  dietCalculator,
  enhancedEcommerceSlice,
  general,
  locale,
  modalErrorSlice,
  order: combineOrderFormSlice,
  orderForm,
  orderFormDietDetailsReducer,
  orderFormHistoryReducer,
  profileReducer,
  salesManagoSlice,
  serverOverload: serverOverloadSlice,
  sideOrdersReducer,
  summaryReducer,
});

export default rootReducer;
