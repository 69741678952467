import React from 'react';

const MenuIcon = ({
  height = '160',
  primaryColor = '#1B42B2',
  secondaryColor = 'white',
  tertiaryMarkColor = '#FF5226',
  width = '200',
}) => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 200 160"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M146.294 95.6071L166.746 91.2118C179.27 88.5203 182.875 72.3819 172.687 64.6168L165.359 59.0311C159.706 54.7222 157.853 47.037 160.922 40.6254L166.367 29.2473C173.129 15.1201 156.437 1.38596 143.877 10.7419L140.538 13.2289C133.532 18.4472 123.53 16.2641 119.336 8.60115C114.554 -0.135732 102.552 -1.48207 95.9534 5.97806L54.6666 52.6523C53.4472 54.031 51.9725 55.1606 50.3239 55.979L35.3612 63.4069C23.3491 69.3701 29.1585 87.5679 42.404 85.4677C52.2726 83.903 59.4435 94.6189 54.2332 103.145L34.1364 136.031C25.829 149.625 41.6438 165.085 55.0457 156.471L82.8442 138.604C90.5111 133.677 100.765 136.669 104.578 144.946L105.751 147.493C112.476 162.09 134.339 157.322 134.375 141.251L134.446 110.238C134.462 103.181 139.395 97.0899 146.294 95.6071Z"
        fill={primaryColor}
        opacity="0.1"
      />
      <path
        clipRule="evenodd"
        d="M60 138C57.7909 138 56 136.209 56 134V26C56.0066 23.7936 57.7936 22.0066 60 22H140C142.206 22.0066 143.993 23.7936 144 26V134C144 136.209 142.209 138 140 138H60Z"
        fill={secondaryColor}
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M64 32V38C64 42.4183 67.5817 46 72 46V32"
        stroke={tertiaryMarkColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M80 32V38C80 42.4183 76.4183 46 72 46V74"
        stroke={tertiaryMarkColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M128 74V32C133.013 34.8659 136.076 40.2262 136 46C136.076 51.7738 133.013 57.1341 128 60"
        stroke={tertiaryMarkColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M102 74C111.941 74 120 65.9411 120 56C120 46.0589 111.941 38 102 38C92.0589 38 84 46.0589 84 56C84 65.9411 92.0589 74 102 74Z"
        fill="white"
        stroke={tertiaryMarkColor}
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="M92 56C92.0066 50.4799 96.4799 46.0066 102 46"
        stroke={tertiaryMarkColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M112 56C111.993 61.5201 107.52 65.9934 102 66"
        stroke={tertiaryMarkColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M128 88H114"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M106 88H72"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M128 98H108"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M100 98H72"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M128 108H116"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M108 108H72"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M128 118H112"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M104 118H72"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default MenuIcon;
