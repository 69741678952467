import { createSelector } from 'reselect';
import { getCompanyDietCaloriesDetails } from './orderFormDietDetailsSelector';
import { currentDiscountDetails } from 'utilsRoot/orderFormPrices';
import { getOrderSettings } from './formSettingsSelector';
import { getISODateFromMillis } from '@adapters/dates/dates.adapter';
import { createCalendarDatesArray } from '@features/orderForm/stepMenuConfiguration/utils/menuConf.utils';

export const getBasketReducer = state => state.basketReducer;

export const getOrderForm = state => state.orderForm;
export const getDays = state => getOrderForm(state).days;

export const getSelectedOption = state => getOrderForm(state).selectedOption;
export const getSelectedCalories = state =>
  getOrderForm(state).selectedCalories;
export const getSelectedCaloriesId = state =>
  getSelectedCalories(state).dietCaloriesId;
export const getSelectedDays = state => getOrderForm(state).selectedDays;
export const getSelectedDaysISO = state =>
  getSelectedDays(state).map(day => getISODateFromMillis(day));

export const getSelectedMeals = state => getOrderForm(state).selectedMeals;
export const getSelectedProgram = state => getOrderForm(state).selectedProgram;
export const getMinMaxModalStatus = state =>
  getOrderForm(state).minMaxModalStatus;

export const getSelectedDiet = state => getOrderForm(state).selectedDiet;
export const getSelectedDietId = state => getSelectedDiet(state).dietId;
export const getSelectedDietDiscounts = state =>
  getSelectedDiet(state).discounts;

export const getMealExchange = state => getOrderForm(state).mealExchange;
export const getMealExchangeEditModalClickedArea = state =>
  getMealExchange(state).mealExchangeEditModalClickedArea;
export const getMealExchangeEditModalStatus = state =>
  getMealExchange(state).mealExchangeEditModalStatus;

export const getCurrentDiscountDetails = createSelector(
  [getDays, getSelectedDietDiscounts],
  (daysAmount, discounts) => currentDiscountDetails(discounts, daysAmount)
);

export const getDietClosestDiscountDetails = createSelector(
  [getDays, getSelectedDietDiscounts],
  (daysAmount, discounts) => {
    if (!(daysAmount && discounts)) {
      return null;
    }
    if (daysAmount === 0) {
      return null;
    }
    const closestDiscount = discounts.reduce(
      (acc, curr) => {
        const accMinusDays = acc.minimumDays - daysAmount;
        const currMinusDay = curr && curr.minimumDays - daysAmount;
        if (accMinusDays <= 0) {
          return curr;
        }
        if (currMinusDay <= 0) {
          return acc;
        }
        if (accMinusDays < currMinusDay) {
          return acc;
        }
        return curr;
      },
      { minimumDays: 0, discount: 0, discountType: 'PERCENTAGE' }
    );

    return {
      ...closestDiscount,
      barPercentage: (daysAmount * 100) / closestDiscount.minimumDays,
      daysTillDiscount:
        closestDiscount && closestDiscount.minimumDays - daysAmount,
    };
  }
);

export const getBiggestDiscount = createSelector(
  [getSelectedDietDiscounts],
  discounts => {
    if (!discounts) {
      return null;
    }
    return (
      discounts &&
      discounts.reduce((acc, curr, index) => {
        if (index === 0) {
          return curr;
        }

        if (acc?.minimumDays < (curr && curr?.minimumDays)) {
          acc = curr;
        }
        return acc;
      }, {})
    );
  }
);

export const getDiscountAmount = createSelector(
  [getCurrentDiscountDetails],
  discountDetails => {
    if (!discountDetails || !discountDetails.discount) {
      return null;
    }
    if (discountDetails && discountDetails.discountType === 'PERCENTAGE') {
      return `${discountDetails.discount}% rabatu za długość zamówienia`;
    }
    return (
      discountDetails &&
      `${discountDetails.discount}PLN/dzień rabatu za długość zamówienia`
    );
  }
);

export const getMinMaxSelectedMeals = createSelector(
  [getOrderSettings, getSelectedCaloriesId, getCompanyDietCaloriesDetails],
  (orderSettings, selectedCaloriesId, companyDietCaloriesDetails) => {
    const { maximumSelectedMeals, minimumSelectedMeals } = orderSettings || {};

    const min = minimumSelectedMeals ? minimumSelectedMeals : 1;

    const find = companyDietCaloriesDetails.find(
      item => item.dietCaloriesId === selectedCaloriesId
    );

    if (
      find &&
      (find?.menuConfigurationMaxMealsNumber ||
        find?.menuConfigurationMaxMealsNumber === 0)
    ) {
      return { min, max: find.menuConfigurationMaxMealsNumber };
    }

    return { min, max: maximumSelectedMeals ? maximumSelectedMeals : 0 };
  }
);

export const getMenuConfCalendarDays = createSelector(
  [getSelectedDaysISO],
  selectedDays => {
    const dateFrom = selectedDays[0];
    const dateTo = selectedDays[selectedDays.length - 1];
    return createCalendarDatesArray(dateFrom, dateTo);
  }
);
