import { useState } from 'react';

import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../styles/code.module.scss';
import { CodeT } from 'typesRoot/code.type';
import { useAppDispatch } from '@config/hooks';
import { DiscountTypeE } from 'typesRoot/discount.enum';
import { resetPromoCode } from '@redux/actions/basketActions';
import { updateSalesManagoEvent } from '@salesManago/services/redux/salesManago.slice';

type Props = {
  code: CodeT;
  isFirstCode?: boolean;
  isThereSecondCode?: boolean;
};

const ActiveCode = ({ code }: Props) => {
  const dispatch = useAppDispatch();

  const [isCloseHovered, setIsCloseHovered] = useState(false);

  const { code: codeName, discount, discountType } = code;

  return (
    <div className={styles['code']}>
      <p className={styles['name']}>{codeName}</p>

      {discount && (
        <p className={styles['discount']}>{`-${discount}${
          discountType === DiscountTypeE.TOTAL ? ' zł' : '%'
        }`}</p>
      )}

      <FontAwesomeIcon
        className={isCloseHovered ? styles['icon--hover'] : styles['icon']}
        icon={faTimesCircle}
        onClick={() => {
          dispatch(resetPromoCode());

          dispatch(
            updateSalesManagoEvent({ event: 'updatePromoCode', value: true })
          );
          dispatch(resetPromoCode());
        }}
        onMouseEnter={() => setIsCloseHovered(true)}
        onMouseLeave={() => setIsCloseHovered(false)}
      />
    </div>
  );
};

export default ActiveCode;
