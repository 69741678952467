import { Field } from 'formik';
import { forwardRef } from 'react';
import { useAppDispatch } from '../../../../../config/hooks';
import { DeliveryMethodEnum } from '../../../../../features/pickupPoint/types/deliveryMethod.enum';
import DeliveryPickupPoint from '../../../../../features/pickupPoint/views/DeliveryPickupPoint';
import {
  changeClientInput,
  changeDeliveryMethod,
  selectCityOrZipcode,
  selectDeliveryHours,
} from '../../../../../redux/actions/summaryActions';
import BoxStep from '../../../../common/BoxStep';
import Button from '../../../../common/Button';
import ComboboxCitiesOrder from '../../../../common/search/ComboboxCitiesOrder';
import ComboboxZipCodes from '../../../../common/search/ComboboxZipCodes';
import { AddressDetails } from '../AddressDetails';
import { classNamesUtil } from '../../../../../utils/classNamesUtil.util';

type Props = {
  city: any;
  companyId: string;
  copyCompanyData: boolean;
  deliveryMethod: DeliveryMethodEnum;
  deliveryTimesOptions: any;
  errors: any;
  handleChange: any;
  orderSettings: any;
  touched: any;
  hoursRef: any;
};

const DeliveryDetails = forwardRef(
  (
    {
      city,
      companyId,
      copyCompanyData,
      deliveryMethod,
      deliveryTimesOptions,
      errors,
      handleChange,
      orderSettings,
      touched,
    }: Props,
    hoursRef
  ) => {
    const dispatch = useAppDispatch();

    return (
      <BoxStep title="Dane do dostawy">
        {orderSettings?.orderingToPickupPointEnabled && (
          <div className="steps-page-third__tab-wrapper spacer-top-40">
            <Button
              colorBlank={deliveryMethod !== DeliveryMethodEnum.N}
              colorPrimary={deliveryMethod === DeliveryMethodEnum.N}
              label="Dostawa na adres"
              onClick={() => {
                dispatch(changeDeliveryMethod(DeliveryMethodEnum.N));
                dispatch(selectDeliveryHours(''));
              }}
            />
            <Button
              colorBlank={deliveryMethod !== DeliveryMethodEnum.P}
              colorPrimary={deliveryMethod === DeliveryMethodEnum.P}
              label="Odbiór w punkcie"
              onClick={() => {
                dispatch(changeDeliveryMethod(DeliveryMethodEnum.P));
                dispatch(selectDeliveryHours(''));
              }}
            />
          </div>
        )}

        {orderSettings?.orderingToPickupPointEnabled &&
          deliveryMethod === DeliveryMethodEnum.P && (
            <>
              <Field name="pickupPointId">
                {/*@ts-ignore*/}
                {({ field, form }) => (
                  <DeliveryPickupPoint
                    companyId={companyId}
                    //@ts-ignore
                    errorMsg={errors[field.name]}
                    onSelect={pickupPointId => {
                      form.setFieldValue(field.name, pickupPointId);
                    }}
                  />
                )}
              </Field>
              <div className="spacer-bottom-24">
                <h3 className="label-l spacer-bottom-8">
                  Podaj domyślny adres dostawy
                </h3>
                <p className="body-m">
                  Potrzebujemy Twojego adresu dostawy na wypadek
                  problemów/chwilowego zamknięcia wybranego punktu odbioru
                </p>
              </div>
            </>
          )}

        <AddressDetails
          copyCompanyData={copyCompanyData}
          deliveryMethod={deliveryMethod}
          deliveryTimesOptions={deliveryTimesOptions}
          errors={errors}
          handleChange={handleChange}
          isHourObligatory={orderSettings?.obligatoryHourPreference}
          ref={hoursRef}
          touched={touched}
        >
          <div className="steps-page-third__wrapper spacer-top-40">
            <Field data-lpignore="true" name="formikCity">
              {/*@ts-ignore*/}
              {({ field, form }) => (
                <div className="spacer-bottom-24">
                  <p
                    className={classNamesUtil(
                      'label-m spacer-bottom-8 color-gray-500',
                      {
                        'color-error':
                          touched['formikCity'] && errors.formikCity,
                      }
                    )}
                  >
                    Miasto <span className="color-error">*</span>
                  </p>
                  <div className="steps-page-third__search-bar">
                    <ComboboxCitiesOrder
                      companyName={companyId}
                      initialSelectedItem={city}
                      onSelect={cityItem => {
                        form.setFieldValue(field.name, cityItem);
                        dispatch(selectCityOrZipcode(cityItem, 'city'));

                        if (copyCompanyData) {
                          dispatch(
                            changeClientInput('companyCity', cityItem.name)
                          );
                        }
                      }}
                    />
                  </div>
                  {touched['formikCity'] && errors['formikCity'] && (
                    <p className="color-error body-m spacer-top-4">
                      {/*@ts-ignore*/}
                      {errors['formikCity']}
                    </p>
                  )}
                </div>
              )}
            </Field>
            <Field data-lpignore="true" name="formikZipCode">
              {/*@ts-ignore*/}
              {({ field, form }) => (
                <div className="spacer-bottom-24">
                  <p
                    className={classNamesUtil(
                      'label-m spacer-bottom-8 color-gray-500',
                      {
                        'color-error':
                          touched['formikZipCode'] && errors['formikZipCode'],
                      }
                    )}
                  >
                    Kod pocztowy <span className="color-error">*</span>
                  </p>
                  <div className="steps-page-third__search-bar">
                    <ComboboxZipCodes
                      isError={
                        !!touched['formikZipCode'] && !!errors['formikZipCode']
                      }
                      onSelect={item => {
                        form.setFieldValue(field.name, item.code);
                        dispatch(selectCityOrZipcode(item, 'zipCode'));

                        if (copyCompanyData) {
                          dispatch(
                            changeClientInput('companyZipCode', item.code)
                          );
                        }
                      }}
                    />
                  </div>
                  {touched['formikZipCode'] && errors['formikZipCode'] && (
                    <p className="color-error body-m spacer-top-4 steps-page-third__small-input">
                      {/*@ts-ignore*/}
                      {errors['formikZipCode']}
                    </p>
                  )}
                </div>
              )}
            </Field>
          </div>
        </AddressDetails>
      </BoxStep>
    );
  }
);

export default DeliveryDetails;
