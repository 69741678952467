import pl from '../translations/pl.json';
import en_GB from '../translations/en-GB.json';

export default function importMessages(lang: string) {
  switch (lang) {
    case 'pl':
      return pl;
    case 'en_GB':
      return en_GB;
    default:
      return en_GB;
  }
}
