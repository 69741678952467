import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { resetPromoCode } from '../../../redux/actions/basketActions';
import { Link } from 'react-router-dom';
import { batch } from 'react-redux';
import {
  changeShowModalError,
  getModalError,
  setModalErrorLoading,
} from '../services/redux/modalError.slice';
import { modalErrorPresentationE } from '../types/modalError.type';
import { getPromoCode } from '../../../redux/selectors/basketSelector';
import CustomModal from '../../../components/common/CustomModal';
import OrderClearIcon from '../../../assets/icons/orderClearIcon';
import Button from '../../../components/common/Button';
import { useAppDispatch, useAppSelector } from '../../../config/hooks';
import { toastNotification } from '../../../utils';
import { classNamesUtil } from '../../../utils/classNamesUtil.util';

const ModalError = () => {
  const dispatch = useAppDispatch();
  const promoCode = useAppSelector(getPromoCode);
  const modalState = useAppSelector(getModalError);

  const { code } = promoCode || {};
  const {
    message = '',
    presentation = modalErrorPresentationE.Modal,
    showModal: isErrorModalOpen = false,
  } = modalState;

  const isThereAPromoCode = !!code;

  const handleCloseModal = (shouldResetCode = false) => {
    if (shouldResetCode) {
      batch(() => {
        dispatch(resetPromoCode());
      });
    }
    batch(() => {
      dispatch(setModalErrorLoading(false));
      dispatch(changeShowModalError(false));
    });
  };

  if (presentation === modalErrorPresentationE.Toast) {
    toastNotification(message, 'error');
    dispatch(changeShowModalError(false));
    return <></>;
  }

  return presentation === modalErrorPresentationE.Modal ? (
    <CustomModal
      className="modal modal-error-order"
      isOpen={isErrorModalOpen}
      onRequestClose={() => {
        handleCloseModal();
      }}
      overlayClassName="overlay-form-fixed"
    >
      <FontAwesomeIcon
        className="modal-x-icon"
        icon={faTimes}
        onClick={() => {
          handleCloseModal();
        }}
        size="3x"
      />
      <div className="modal-error-wrapper">
        <OrderClearIcon className="order-clean-icon" />
        <h4 className="h400 spacer-bottom-8 spacer-top-24">Wystąpił błąd</h4>
        <p className="body-l">{message}</p>
      </div>
      <div className="steps-page__divide " />
      <div
        className={classNamesUtil('display-flex padding-all-16', {
          'justify-content-space-between': isThereAPromoCode,
          'justify-content-flex-end': !isThereAPromoCode,
        })}
      >
        <Button
          colorClean={isThereAPromoCode}
          colorPrimary={!isThereAPromoCode}
          label={isThereAPromoCode ? 'Usuń kod' : 'Ok'}
          onClick={() => {
            handleCloseModal(true);
          }}
        />
        {isThereAPromoCode && (
          <Link to="/koszyk">
            <Button
              colorPrimary
              label="Edytuj zamówienie"
              onClick={() => {
                handleCloseModal();
              }}
            />
          </Link>
        )}
      </div>
    </CustomModal>
  ) : (
    <></>
  );
};

export default ModalError;
