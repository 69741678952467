export const MENU_CONFIGURATION_ROUTE = 'wybierz-posilki';

export const breakpointsCarouselCalendar = {
  0: {
    slidesPerView: 3.5,
    spaceBetween: 8,
  },
  350: {
    slidesPerView: 5,
    spaceBetween: 8,
  },
  400: {
    slidesPerView: 5.5,
    spaceBetween: 8,
  },
  450: {
    slidesPerView: 6,
    spaceBetween: 8,
  },
  500: {
    slidesPerView: 6.5,
    spaceBetween: 8,
  },
  550: {
    slidesPerView: 7,
    spaceBetween: 8,
  },
  600: {
    slidesPerView: 7.5,
    spaceBetween: 8,
  },
  650: {
    slidesPerView: 8,
    spaceBetween: 8,
  },
  700: {
    slidesPerView: 8.5,
    spaceBetween: 8,
  },
  750: {
    slidesPerView: 9,
    spaceBetween: 8,
  },
  800: {
    slidesPerView: 9.5,
    spaceBetween: 8,
  },
  850: {
    slidesPerView: 10,
    spaceBetween: 8,
  },
  900: {
    slidesPerView: 10.5,
    spaceBetween: 8,
  },
  950: {
    slidesPerView: 11,
    spaceBetween: 8,
  },
  1000: {
    slidesPerView: 11.5,
    spaceBetween: 8,
  },
  1050: {
    slidesPerView: 12,
    spaceBetween: 8,
  },
  1100: {
    slidesPerView: 13,
    spaceBetween: 8,
  },
  1150: {
    slidesPerView: 14,
    spaceBetween: 8,
  },
  1200: {
    slidesPerView: 15,
    spaceBetween: 8,
  },
  1250: {
    slidesPerView: 17,
    spaceBetween: 8,
  },
};

export const slidePerViewSingleCatering = {
  0: {
    slidesPerView: 3.5,
    spaceBetween: 8,
  },
  350: {
    slidesPerView: 5,
    spaceBetween: 8,
  },
  400: {
    slidesPerView: 5.5,
    spaceBetween: 8,
  },
  450: {
    slidesPerView: 6,
    spaceBetween: 8,
  },
  500: {
    slidesPerView: 6.5,
    spaceBetween: 8,
  },
  550: {
    slidesPerView: 7,
    spaceBetween: 8,
  },
  600: {
    slidesPerView: 7.5,
    spaceBetween: 8,
  },
  650: {
    slidesPerView: 8,
    spaceBetween: 8,
  },
  700: {
    slidesPerView: 8.5,
    spaceBetween: 8,
  },
  750: {
    slidesPerView: 9,
    spaceBetween: 8,
  },
  800: {
    slidesPerView: 9.5,
    spaceBetween: 8,
  },
  850: {
    slidesPerView: 10,
    spaceBetween: 8,
  },
  900: {
    slidesPerView: 10.5,
    spaceBetween: 8,
  },
  950: {
    slidesPerView: 11,
    spaceBetween: 8,
  },
  992: {
    slidesPerView: 8.5,
    spaceBetween: 8,
  },
  1100: {
    slidesPerView: 9,
    spaceBetween: 8,
  },
  1150: {
    slidesPerView: 9.5,
    spaceBetween: 8,
  },
  1200: {
    slidesPerView: 10,
    spaceBetween: 8,
  },
  1250: {
    slidesPerView: 10.5,
    spaceBetween: 8,
  },
  1300: {
    slidesPerView: 11,
    spaceBetween: 8,
  },
};
