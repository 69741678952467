export enum ProgramTypeE {
  MenuConfiguration = 'menuConfiguration',
  Standard = 'standard',
}

export type OrderFormT = {
  days: number;
  saturdays: boolean;
  sundays: boolean;
  selectedDays: Array<number>;
  filteredWeekends: Array<number>;
  testOrder: boolean;
  selectedDietCaloriesIdPrices: number;
  sets: number;
  selectedProgram: ProgramTypeE;
};
