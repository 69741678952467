import { useState } from 'react';

import { SideOrderOrCourseT } from '@features/coursesAsSideOrders/types/coursesAsSideOrders.type';
import { parseKeyFromSideOrderId } from '@features/coursesAsSideOrders/utils/coursesAsSideOrders.util';
import SelectSideOrdersModal from '@features/coursesAsSideOrders/views/SelectSideOrdersModal';
import { SideOrderCard, Spinner } from '@dietly/design-system-library';
import { courseAsSideOrdersStyle as styles } from '@dietly/design-system-library';
import SideOrderDetailsModal from '@features/coursesAsSideOrders/views/SideOrderDetailsModal';
import { SelectedSideOrdersT } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.slice';

import BoxStep from '@components/common/BoxStep';
import { useGetSideOrdersWithCoursesQuery } from '@services/api/form/open/possibleSideOrders/possibleSideOrders.api';
import { useAppSelector } from '@config/hooks';
import { getIsKuchniaVikinga } from '@features/general/generalSlice';
import { getSelectedDays } from '@redux/selectors/orderFormSelector';
import { isSideOderInOrderDays } from '../utils/sideOrders.util';
import { getISODateFromMillis } from '@adapters/dates/dates.adapter';

type Props = {
  companyId: string;
  selectedSideOrders: SelectedSideOrdersT;
};

const SideOrdersGrid = ({ companyId, selectedSideOrders }: Props) => {
  const isKuchniaVikinga = useAppSelector(getIsKuchniaVikinga);
  const selectedDaysISO = useAppSelector(getSelectedDays)
    .sort()
    .map((day: number) => getISODateFromMillis(day));
  const { data, isLoading } = useGetSideOrdersWithCoursesQuery({ companyId });
  const [isDetailOrderModalOpen, setIsDetailOrderModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSideOrder, setSelectedSideOrder] =
    useState<null | SideOrderOrCourseT>(null);

  const handleDetailOrderModalOpen = (sideOrder: SideOrderOrCourseT) => {
    setIsDetailOrderModalOpen(true);
    setSelectedSideOrder(sideOrder);
  };
  const handleSelectSideOrderModalOpen = (sideOrder: SideOrderOrCourseT) => {
    setIsModalOpen(true);
    setIsDetailOrderModalOpen(false);
    setSelectedSideOrder(sideOrder);
  };

  if (isLoading || data === undefined) {
    return <Spinner />;
  }

  return (
    <div>
      {isDetailOrderModalOpen && selectedSideOrder && (
        <SideOrderDetailsModal
          companyId={companyId}
          handleSelectSideOrderModalOpen={handleSelectSideOrderModalOpen}
          isOpen={isDetailOrderModalOpen}
          selectedSideOrder={selectedSideOrder}
          setIsOpen={setIsDetailOrderModalOpen}
        />
      )}
      {isModalOpen && selectedSideOrder && (
        <SelectSideOrdersModal
          isOpen={isModalOpen}
          selectedSideOrder={selectedSideOrder}
          selectedSideOrders={selectedSideOrders}
          setIsOpen={setIsModalOpen}
          setSelectedSideOrder={setSelectedSideOrder}
        />
      )}
      <BoxStep title="Wybierz dodatki">
        <p className="body-m" data-cy="side-orders">
          Dodatki możesz wybrać na każdy dzień lub wybrane dni zamówienia.
        </p>

        <div className={`${styles['grid-container']} spacer-top-12`}>
          {Object.values(data).map((sideOrder: SideOrderOrCourseT) => {
            const key = parseKeyFromSideOrderId(sideOrder.sideOrderId);

            if (isKuchniaVikinga) {
              const isSideOrderAvailable = isSideOderInOrderDays({
                orderDateStart: selectedDaysISO[0],
                orderDateEnd: selectedDaysISO.at(-1),
                sideOrderFrom: sideOrder.dateFrom,
                sideOrderTo: sideOrder.dateTo,
              });

              if (!isSideOrderAvailable) {
                return null;
              }
            }
            return (
              <SideOrderCard
                handleOpenModalDetail={handleDetailOrderModalOpen}
                handleOpenModalSelectOrder={handleSelectSideOrderModalOpen}
                key={key}
                selectedSideOrders={selectedSideOrders}
                sideOrder={sideOrder}
              />
            );
          })}
        </div>
      </BoxStep>
    </div>
  );
};

export default SideOrdersGrid;
