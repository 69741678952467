import React, { ReactElement, useState } from 'react';

import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../styles/banner.module.scss';
import { BannerTypeE } from '../types/banner.type';
import {
  getBannerClass,
  getBannerColor,
  getBannerIcon,
} from '../utils/banner.util';

type Props = {
  closeIcon?: boolean;
  containerClass?: string;
  onClose?: () => void;
  type: BannerTypeE;
  text: string | ReactElement;
};

const Banner = ({ closeIcon, containerClass, onClose, text, type }: Props) => {
  const [showBanner, setShowBanner] = useState(true);

  return showBanner ? (
    <div
      className={`${styles['dds-banner']} ${getBannerClass(type, styles)}${
        containerClass ? ` ${containerClass}` : ''
      }`}
    >
      <div className={styles['dds-banner__icon']}>
        <FontAwesomeIcon
          color={getBannerColor(type)}
          icon={getBannerIcon(type)}
        />
      </div>
      <div
        className={
          closeIcon || onClose
            ? styles['dds-banner__label--with-icon']
            : styles['dds-banner__label']
        }
      >
        {text}
      </div>
      {(closeIcon || onClose) && (
        <FontAwesomeIcon
          className={styles['close']}
          color={getBannerColor(type)}
          icon={faTimes}
          onClick={() => {
            setShowBanner(false);
            !!onClose && onClose();
          }}
        />
      )}
    </div>
  ) : (
    <></>
  );
};

export default Banner;
