import React from 'react';
import BoxStep from '../../../common/BoxStep';
import OrderConfirmationIcon from '../../../../assets/icons/orderConfirmationIcon';
import { Link } from 'react-router-dom';
import Button from '../../../common/Button';

const OrderConfirmationExternalVoucher = () => {
  return (
    <BoxStep>
      <div className="text-align-center">
        <OrderConfirmationIcon />
        <h4 className="h300 spacer-bottom-8 spacer-top-24">
          Dziękujemy za zamówienie
        </h4>
        <p className="body-l padding-bottom-40">
          Gdy tylko zweryfikujemy Twoje zamówienie, przejdziemy do jego
          realizacji.
        </p>
        <Link className="spacer-vertical-40 display-block" to="/">
          <Button
            colorBlank
            containerClass="spacer-auto"
            label="Wróć na stronę główną"
            sizeMedium
          />
        </Link>
      </div>
    </BoxStep>
  );
};

export default OrderConfirmationExternalVoucher;
