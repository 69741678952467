// @ts-ignore
import StarIconRate from '../../assets/icons/StarIconRate';
import { DietT } from '../../@types/menu.type';
import { classNamesUtil } from '../../utils/classNamesUtil.util';

type Props = {
  containerClass?: string;
  numberOfRates?: DietT['reviewsNumber'] | undefined;
  rate: DietT['averageFeedback'] | undefined;
  numberOfRatesClass?: string;
  extendedNumberOfRatesDescription?: boolean;
};

const BigStarRating = ({
  containerClass,
  extendedNumberOfRatesDescription,
  numberOfRates = 0,
  numberOfRatesClass,
  rate,
}: Props) => {
  return (
    <>
      <div
        className={classNamesUtil(
          'display-flex catering-offer__diet-opinion catering-offer__diet-opinion--warning spacer-right-4',
          {
            [`${containerClass}`]: !!containerClass,
          }
        )}
      >
        <div className="catering-offer__diet-opinion-wrapper">
          <StarIconRate />
        </div>
        <span className="color-white label-m">{rate?.toFixed(2)}</span>
      </div>
      {Number(numberOfRates) > 0 && (
        <div
          className={classNamesUtil('label-m display-flex align-items-center', {
            [`${numberOfRatesClass}`]: !!numberOfRatesClass,
          })}
        >
          <span className="spacer-right-10 color-gray-400">
            ({numberOfRates}
            {extendedNumberOfRatesDescription && ' opinii'})
          </span>
        </div>
      )}
    </>
  );
};

export default BigStarRating;
