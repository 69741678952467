import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { usePrevious } from 'react-use';
import { DateTime } from 'luxon';
import { getInitialDatesArray } from '../../../../../utils';
import {
  getApiMenu,
  useGetApiMenuWithAllergensQuery,
} from '../../../../../services/api/form/open/company.api';
import CateringMenu from './CateringMenu';
import MenuSelectDietModal from './MenuSelectDietModal';
import SwiperClass from 'swiper/types/swiper-class';
import {
  getIsKuchniaVikinga,
  getServerConfig,
} from '../../../../../features/general/generalSlice';
import {
  CompanyDietDetailsT,
  DietT,
  MenuIngredientsAllergensT,
  MenuT,
} from '../../../../../@types/menu.type';
import { useGetApiFormSettingsQuery } from '../../../../../services/api/form/open/open.api';
import { useAppSelector } from '../../../../../config/hooks';
import { selectCompanyDietCaloriesDetailsDietCaloriesIdByDietId } from '@redux/selectors/orderFormDietDetailsSelector';
import { getQuery } from '../../helpers/menuHelpers';

type Props = {
  centeredSlides?: boolean;
  companyDietDetails: CompanyDietDetailsT;
  companyName: string | undefined;
  companyParams: { menuVisibleInDietly: boolean };
  customTitle?: string;
  hideSelect?: boolean;
  specificDiet?: DietT;
};

export const Menu = forwardRef(
  (
    {
      centeredSlides,
      companyDietDetails,
      companyName,
      companyParams: { menuVisibleInDietly },
      customTitle,
      hideSelect,
      specificDiet,
    }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const prevCompanyName = usePrevious(companyName);
    const { apiKey, companyId, serverUrl } =
      useAppSelector(getServerConfig) || {};

    const {
      data: {
        orderSettings: { menuDaysAhead = 0, menuDaysBack = 0 } = {},
      } = {},
    } = useGetApiFormSettingsQuery(undefined, {
      skip: !companyId,
    });

    const [selectedDiet, setSelectedDiet] = useState<DietT>();
    const [dates, setDates] = useState<string[]>([]);
    const [wasNewDietsFetched, setWasNewDietsFetched] = useState(false);
    const [menus, setMenus] = useState<MenuT>({});
    const [isLoading, setIsLoading] = useState(false);
    const [swiperMenu, setSwiperMenu] = useState<SwiperClass | null>(null);
    const [highlightedDate, setHighLightedDate] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const selectedCaloriesId = useAppSelector(
      selectCompanyDietCaloriesDetailsDietCaloriesIdByDietId
    );
    const isKuchniaVikinga = useAppSelector(getIsKuchniaVikinga);

    const {
      data: menuWithAllergensData,
      isFetching: isFetchingMenuWithAllergens,
    } = useGetApiMenuWithAllergensQuery(
      {
        companyName: companyName || '',
        dietCaloriesId: selectedCaloriesId,
        dates,
      },
      {
        skip:
          !isKuchniaVikinga ||
          !selectedCaloriesId ||
          dates.length === 0 ||
          !companyName,
      }
    );

    const fetchInitialMenu = useCallback(
      async (
        // TODO ANY
        selectedDiet: any,
        dates: (string | string[])[],
        cb: (arg0: boolean) => any
      ) => {
        cb && cb(true);

        try {
          if (companyName && !isKuchniaVikinga) {
            const result = await getApiMenu(
              companyName,
              getQuery(selectedDiet, dates),
              serverUrl,
              apiKey
            );
            const { data } = result || {};
            setMenus(data);
            cb && cb(false);
          }
        } catch (error) {
          console.log(error);
          cb && cb(false);
        }
      },
      [apiKey, companyName, isKuchniaVikinga, serverUrl]
    );

    useEffect(() => {
      if (isKuchniaVikinga && selectedCaloriesId) {
        setMenus(menuWithAllergensData as MenuIngredientsAllergensT);
      }
    }, [isKuchniaVikinga, menuWithAllergensData, selectedCaloriesId]);

    useEffect(() => {
      if (prevCompanyName !== companyName) {
        setWasNewDietsFetched(false);
        setHighLightedDate(DateTime.local().toISODate() as string);
        setDates(
          getInitialDatesArray(
            menuDaysBack || 1,
            menuDaysAhead || 1
          ) as Array<string>
        );
        setSelectedDiet(specificDiet);
        menuDaysBack &&
          menuDaysAhead &&
          fetchInitialMenu(
            specificDiet,
            getInitialDatesArray(menuDaysBack, menuDaysAhead) as Array<string>,
            setIsLoading
          ).then(() => setWasNewDietsFetched(true));
      }
    }, [
      prevCompanyName,
      companyName,
      companyDietDetails,
      fetchInitialMenu,
      selectedDiet,
      specificDiet,
      dates,
      swiperMenu,
      menuDaysBack,
      menuDaysAhead,
    ]);

    const setAndFetchDietData = (diet?: DietT) => {
      fetchInitialMenu(
        diet,
        getInitialDatesArray() as Array<string>,
        setIsLoading
      );
      setDates(getInitialDatesArray() as Array<string>);
      setSelectedDiet(diet);
      setHighLightedDate(DateTime.local().toISODate() as string);
    };

    return (
      <>
        <MenuSelectDietModal
          companyDietDetails={companyDietDetails}
          isModalOpen={isModalOpen}
          selectedDiet={selectedDiet}
          setAndFetchDietData={setAndFetchDietData}
          setIsModalOpen={setIsModalOpen}
        />
        <CateringMenu
          centeredSlides={centeredSlides}
          companyDietDetails={companyDietDetails}
          customTitle={customTitle}
          dates={dates}
          hideSelect={hideSelect}
          highlightedDate={highlightedDate}
          isLoading={isLoading && isFetchingMenuWithAllergens}
          isMenuVisibleInDietly={menuVisibleInDietly}
          menus={menus}
          ref={ref}
          selectedDiet={selectedDiet}
          setAndFetchDietData={setAndFetchDietData}
          setHighLightedDate={setHighLightedDate}
          setIsLoading={setIsLoading}
          setIsModalOpen={setIsModalOpen}
          setSwiperMenu={setSwiperMenu}
          swiperMenu={swiperMenu}
          wasNewDietsFetched={wasNewDietsFetched}
        />
      </>
    );
  }
);
