import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BoxStep from '../../../common/BoxStep';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSyncAlt } from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowRight,
  faExclamationCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { useEffectOnce, useMeasure, useWindowSize } from 'react-use';
import {
  getOrderForm,
  getSelectedProgram,
} from '@redux/selectors/orderFormSelector';
import {
  changeMinMaxModalStatus,
  mealExchangeChangeEditModalClickedArea,
  mealExchangeSelectProgram,
  resetCalendar,
  resetPreviouslySelectedDietDetails,
  resetSideOrders,
  select,
  toggleMeal,
} from '@redux/actions/orderFormActions';
import Checkbox from '../../../common/checkbox';
import Button from '../../../common/Button';
import { Link } from 'react-router-dom';
import {
  calculateEstimateCalories,
  checkCustomDietOptions,
  checkIfAllMealsSelected,
  checkIfForwardButtonActive,
  getDietOptionCalories,
  getDietOptions,
  getMinimumNumberOfMeals,
  getNumberOfSelectedMeals,
  handleMeal,
} from '../helpers/firstStepHelpers';
import { customNumSort, customSortDiets } from 'utilsRoot/index';
import {
  getCompanyDietCaloriesMenuConfiguration,
  getExchangeDiet,
  isCateringHaveMealConfiguration,
} from '@redux/selectors/orderFormDietDetailsSelector';
import TypeOfProgram from './TypeOfProgram';
import MealExchangeEditModal from '../../../common/mealExchangeEditModal';
import DietCalculator from './dietCalculator/DietCalculator';
import { changeIsModalOpen } from '@features/dietCalculator/dietCalculatorSlice';
import KcalIcon from '../../../../assets/icons/KcalIcon';
import VariantStep from './VariantStep';
import ChooseDietStep from './ChooseDietStep';
import { getBasketEdition } from '@redux/selectors/basketSelector';
import { useGetApiFormSettingsQuery } from '@services/api/form/open/open.api';
import { useAppSelector } from '@config/hooks';
import { getServerConfig } from '@features/general/generalSlice';
import { viewItemGA4 } from '@features/GA4/events/ecommerceEvents';
import { classNamesUtil } from 'utilsRoot/classNamesUtil.util';
import { changeLimitedByMaximumSelectedMeals } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.slice';
import { ProgramTypeE } from 'typesRoot/orderForm.type';
import { selectBaseDeliveryMeals } from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.slice';
import { prepareLightMeals } from '@features/orderForm/stepMenuConfiguration/utils/menuConf.utils';
import useCalculateItemPrice from '@services/hook/calculatePrices/useCalculateItemPrice.hook';

const OrderSelection = ({
  caloriesRef,
  changeStage,
  city,
  dietRef,
  diets,
  isMenuConfigurationStarted,
  mealsRef,
  programRef,
  selectedValue,
  setSelectedValue,
  variantRef,
}) => {
  const dispatch = useDispatch();

  const [optionHeightRef, optionHeight] = useMeasure();
  const { width } = useWindowSize();

  const [listHeight, setListHeight] = useState(null);
  const [nextStepIcon, setNextStepIcon] = useState(false);
  const [readMoreDiet, setReadMoreDiet] = useState('');
  const [readMoreOption, setReadMoreOption] = useState('');
  const [optionClick, setOptionClick] = useState('');
  const [selectedMealsLength, setSelectedMealsLength] = useState(0);
  const [showMessagesFixed, setShowMessagesFixed] = useState(false);

  const displayMinimumSelectedMeals = false;

  const [minimumSelectedMealsInfo, setMinimumSelectedMealsInfo] =
    useState(false);
  const isMealConfigurationEnabled = useAppSelector(
    isCateringHaveMealConfiguration
  );
  const exchangeDiet = useAppSelector(getExchangeDiet);
  const selectedProgram = useAppSelector(getSelectedProgram);
  const { companyId } = useAppSelector(getServerConfig);
  const orderForm = useAppSelector(getOrderForm);
  const { selectedCalories, selectedDiet, selectedMeals, selectedOption } =
    orderForm || {};

  const { data: formSettings } = useGetApiFormSettingsQuery(undefined, {
    skip: !companyId,
  });

  const companyDietCaloriesMenuConfiguration = useAppSelector(
    getCompanyDietCaloriesMenuConfiguration
  );
  const { isEdited } = useAppSelector(getBasketEdition);

  const calories = getDietOptionCalories(
    selectedOption,
    selectedProgram,
    companyDietCaloriesMenuConfiguration
  );

  const { prices } = useCalculateItemPrice();

  const { totalDietWithDiscountsAndSideOrdersCost } = prices || {};

  useEffect(() => {
    if (
      city &&
      selectedProgram &&
      selectedDiet &&
      selectedOption &&
      !calories.length
    ) {
      setShowMessagesFixed(true);
    } else {
      setShowMessagesFixed(false);
    }
  }, [selectedDiet, selectedOption, selectedProgram, calories, city]);

  useEffectOnce(() => {
    if (
      isEdited &&
      selectedProgram === 'standard' &&
      selectedMeals &&
      selectedMeals?.length
    ) {
      setSelectedMealsLength(
        selectedMeals.filter(item => item.selected).length
      );
    }
  });

  useEffect(() => {
    if (width < 768) {
      setListHeight(0);
      setReadMoreOption('');
    }
  }, [width]);

  const handleSelectProgram = selectedCard => {
    if (selectedProgram !== selectedCard) {
      setSelectedValue(selectedCard);
      if (
        isMenuConfigurationStarted &&
        selectedProgram === ProgramTypeE.MenuConfiguration &&
        !selectedValue
      ) {
        dispatch(mealExchangeChangeEditModalClickedArea('firstStepProgram'));
      } else {
        if (selectedCard !== selectedProgram) {
          dispatch(resetPreviouslySelectedDietDetails());
          changeStage(
            selectedCard === ProgramTypeE.MenuConfiguration ? 'variant' : 'diet'
          );
          dispatch(mealExchangeSelectProgram(selectedCard));
          if (selectedCard === ProgramTypeE.MenuConfiguration) {
            dispatch(select('selectedDiet', exchangeDiet));
          }
        }
      }
    }
    dispatch(resetCalendar());
  };

  const handleSelectDiet = diet => {
    const { dietOptions } = diet || {};

    dispatch(select('selectedDiet', diet));
    dispatch(select('selectedMeals', ''));

    if (dietOptions?.length > 1) {
      dispatch(select('selectedOption', ''));
      changeStage('variant');
    } else {
      dispatch(select('selectedCalories', ''));
      dispatch(select('selectedOption', dietOptions[0]));
      changeStage('calories');
    }
    if (selectedProgram !== 'standard') {
      dispatch(mealExchangeSelectProgram('standard'));
    }

    setListHeight(0);
    setReadMoreOption('');
  };

  const handleSelectOption = (e, option) => {
    if (selectedOption?.dietOptionId !== option.dietOptionId) {
      setSelectedValue(option);
      setOptionClick(e);

      const isOptionDifferentThanBaseOptionInMenuConfiguration =
        isMenuConfigurationStarted &&
        selectedProgram === ProgramTypeE.MenuConfiguration &&
        selectedOption.dietOptionId !== option.dietOptionId;

      const shouldWarnAboutMenuConfigurationReset =
        (isMenuConfigurationStarted &&
          selectedProgram === ProgramTypeE.MenuConfiguration &&
          !selectedValue) ||
        isOptionDifferentThanBaseOptionInMenuConfiguration;

      if (shouldWarnAboutMenuConfigurationReset) {
        dispatch(mealExchangeChangeEditModalClickedArea('firstStepVariant'));
      } else {
        changeStage('calories');
        dispatch(select('selectedOption', option));
        if (selectedOption?.dietOptionId) {
          dispatch(select('selectedCalories', ''));
          dispatch(select('selectedMeals', ''));
        }
        dispatch(resetSideOrders());
      }
    }
  };

  const handleSelectCalorie = dietCalories => {
    if (selectedCalories?.dietCaloriesId !== dietCalories.dietCaloriesId) {
      setSelectedValue(dietCalories);
      if (
        isMenuConfigurationStarted &&
        selectedProgram === ProgramTypeE.MenuConfiguration &&
        !selectedValue
      ) {
        dispatch(mealExchangeChangeEditModalClickedArea('firstStepCalories'));
      } else {
        changeStage('meals');
        dispatch(select('selectedCalories', dietCalories));
        dispatch(resetSideOrders());
      }
    }
  };

  const handleSelectMeals = dietMealId => {
    setSelectedValue(dietMealId);
    if (
      isMenuConfigurationStarted &&
      selectedProgram === ProgramTypeE.MenuConfiguration &&
      !selectedValue
    ) {
      dispatch(mealExchangeChangeEditModalClickedArea('firstStepMeals'));
    } else {
      dispatch(
        handleMeal(
          dietMealId,
          toggleMeal,
          selectedMeals,
          formSettings,
          displayMinimumSelectedMeals,
          setMinimumSelectedMealsInfo
        )
      );
      if (selectedProgram === ProgramTypeE.MenuConfiguration) {
        dispatch(resetSideOrders());
        dispatch(changeMinMaxModalStatus(''));
      }
    }
  };

  return (
    <>
      {showMessagesFixed && (
        <div className="caution-message caution-message--fixed caution-message--error">
          <FontAwesomeIcon icon={faExclamationCircle} />
          <div className="color-error">
            Brak ceny dla tej diety w systemie. Skontaktuj się z cateringiem.
          </div>
        </div>
      )}
      <MealExchangeEditModal
        handleSelectCalorie={handleSelectCalorie}
        handleSelectMeals={handleSelectMeals}
        handleSelectOption={handleSelectOption}
        handleSelectProgram={handleSelectProgram}
        optionClick={optionClick}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
      />
      <DietCalculator
        caloriesList={calories}
        handleSelectCalorie={handleSelectCalorie}
      />
      {isMealConfigurationEnabled && city && (
        <BoxStep checkIcon ref={programRef} title="Wybierz rodzaj programu">
          <TypeOfProgram
            diets={diets}
            handleSelectProgram={handleSelectProgram}
            selectedProgram={selectedProgram}
          />
        </BoxStep>
      )}
      {city &&
        (selectedProgram === 'standard' || !isMealConfigurationEnabled) && (
          <ChooseDietStep
            customSortDiets={customSortDiets}
            dietRef={dietRef}
            diets={diets}
            handleSelectDiet={handleSelectDiet}
            optionHeight={optionHeight}
            readMoreDiet={readMoreDiet}
            selectedDiet={selectedDiet}
            setListHeight={setListHeight}
            setReadMoreDiet={setReadMoreDiet}
          />
        )}
      {selectedDiet && selectedDiet?.dietOptions?.length > 1 && (
        <VariantStep
          getDietOptions={getDietOptions}
          handleSelectOption={handleSelectOption}
          listHeight={listHeight}
          optionHeight={optionHeight}
          optionHeightRef={optionHeightRef}
          readMoreOption={readMoreOption}
          selectedDiet={selectedDiet}
          selectedOption={selectedOption}
          setListHeight={setListHeight}
          setReadMoreOption={setReadMoreOption}
          variantRef={variantRef}
        />
      )}
      {selectedDiet && selectedOption && calories?.length > 0 && (
        <BoxStep checkIcon ref={caloriesRef} title="Wybierz kaloryczność">
          <ul className="steps-page__list steps-page__list--flex">
            {calories?.map(dietCalories => {
              const { calories: dietCaloriesCalories, dietCaloriesId } =
                dietCalories || {};

              return (
                <li
                  className={classNamesUtil(
                    'steps-page__item min-width-130 cypress-calorie',
                    {
                      'steps-page__item--alternative-active':
                        dietCaloriesCalories === selectedCalories?.calories,
                    }
                  )}
                  key={dietCaloriesId}
                  onClick={() => {
                    handleSelectCalorie(dietCalories);
                  }}
                >
                  <div>
                    {dietCaloriesCalories === selectedCalories?.calories && (
                      <FontAwesomeIcon
                        className="spacer-right-10"
                        icon={faCheck}
                      />
                    )}
                    {dietCaloriesCalories}
                  </div>
                </li>
              );
            })}
            {calories?.length > 1 &&
              width > 430 &&
              !(calories?.length > 3 && width < 430) && (
                <li
                  className="steps-page__item min-width-130"
                  onClick={() => {
                    dispatch(changeIsModalOpen(true));
                  }}
                >
                  <KcalIcon className="path-fill-primaryColor spacer-right-8" />
                  Dopasuj kaloryczność
                </li>
              )}
          </ul>
          {calories?.length > 3 && width < 430 && (
            <Button
              colorBlank
              containerClass="steps-page__item width-100-per"
              icon={
                <KcalIcon className="path-fill-primaryColor spacer-right-8" />
              }
              label="Dopasuj kaloryczność"
              onClick={() => {
                dispatch(changeIsModalOpen(true));
              }}
            />
          )}
        </BoxStep>
      )}
      {selectedCalories?.dietCaloriesId &&
        checkCustomDietOptions(selectedDiet, formSettings) && (
          <BoxStep checkIcon ref={mealsRef} title="Wybierz posiłki">
            <ul className="steps-page__list steps-page__list--flex">
              {selectedMeals &&
                customNumSort('mealPriority')(selectedMeals)?.map(meal => (
                  <li
                    className={classNamesUtil('steps-page__item cypress-meal', {
                      'steps-page__item--alternative-active': meal?.selected,
                    })}
                    key={meal?.dietMealId}
                    onClick={() => {
                      handleSelectMeals(meal?.dietMealId);
                    }}
                  >
                    <Checkbox checked={meal?.selected} readOnly />
                    {meal?.mealName}
                  </li>
                ))}
            </ul>
            {minimumSelectedMealsInfo && (
              <div className="label-m color-error spacer-top-16">
                Minimalna ilość posiłków to{' '}
                <span className="font-weight-500">
                  {getMinimumNumberOfMeals(formSettings)}
                </span>
              </div>
            )}
            {!checkIfAllMealsSelected(selectedMeals) && selectedMeals && (
              <div className="body-m color-gray-800 spacer-top-16">
                Ilość wybranych posiłków:{' '}
                <span className="font-weight-500">
                  {getNumberOfSelectedMeals(selectedMeals)} ok.{' '}
                  {calculateEstimateCalories(selectedCalories, selectedMeals)}
                  kcal
                </span>
              </div>
            )}
          </BoxStep>
        )}
      {checkIfForwardButtonActive('first', orderForm, city, formSettings) && (
        <div className="steps-page__button">
          <Link to="/zamowienie">
            <Button
              blockButton
              icon={
                nextStepIcon ? (
                  <FontAwesomeIcon icon={faSyncAlt} spin />
                ) : (
                  <FontAwesomeIcon icon={faArrowRight} />
                )
              }
              iconRight
              label="Następny krok"
              onClick={() => {
                if (
                  isEdited &&
                  selectedProgram === 'standard' &&
                  selectedMealsLength !==
                    selectedMeals.filter(item => item.selected).length
                ) {
                  dispatch(changeLimitedByMaximumSelectedMeals(true));
                }

                setNextStepIcon(true);

                selectedProgram === ProgramTypeE.MenuConfiguration &&
                  dispatch(
                    selectBaseDeliveryMeals(prepareLightMeals(selectedMeals))
                  );

                viewItemGA4(
                  orderForm,
                  city,
                  totalDietWithDiscountsAndSideOrdersCost
                );
              }}
              sizeLarge
            />
          </Link>
        </div>
      )}
    </>
  );
};

OrderSelection.propTypes = {
  companyParams: PropTypes.object,
  diets: PropTypes.array,
};

export default OrderSelection;
