import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useEffectOnce, usePrevious } from 'react-use';
import { OrderFormT } from '../../../../@types/orderForm.type';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import { resetFilteredDays } from '../../../../redux/actions/orderFormActions';
import { getPromoCode } from '../../../../redux/selectors/basketSelector';
import {
  getBiggestDiscount,
  getDietClosestDiscountDetails,
  getSelectedProgram,
} from '../../../../redux/selectors/orderFormSelector';
import DiscountBar from '../../../common/discountBar';
import { getInitialOffset, toggleWeekendDays } from '../utils/calendar.utils';
import CalendarBase from './CalendarBase';
import { useGetApiFormSettingsQuery } from '../../../../services/api/form/open/open.api';
import {
  getIsKuchniaVikinga,
  getServerConfig,
} from '../../../../features/general/generalSlice';

type Props = {
  orderForm: OrderFormT;
  setSelectedValue: Dispatch<SetStateAction<number>>;
  textCalendar1?: string;
  textCalendar2?: string;
  validateQuantityOfOrder: string;
  validationDays: {
    value: string;
    message: string;
  };
};

const Calendar = ({
  orderForm,
  setSelectedValue,
  textCalendar1,
  textCalendar2,
  validateQuantityOfOrder,
  validationDays,
}: Props) => {
  const dispatch = useAppDispatch();

  const [offset, setOffset] = useState(0);

  const promoCode = useAppSelector(getPromoCode);
  const selectedProgram = useAppSelector(getSelectedProgram);
  const biggestDiscount = useAppSelector(getBiggestDiscount);
  const closestDietDiscountDetails = useAppSelector(
    getDietClosestDiscountDetails
  );
  const prevSaturdays = usePrevious(orderForm.saturdays);
  const prevSundays = usePrevious(orderForm.sundays);

  const { companyId } = useAppSelector(getServerConfig);
  const isKuchniaVikinga = useAppSelector(getIsKuchniaVikinga);

  const { data: formSettings } = useGetApiFormSettingsQuery(undefined, {
    skip: !companyId,
  });

  useEffectOnce(() => {
    setOffset(
      getInitialOffset(
        selectedProgram,
        orderForm,
        formSettings,
        isKuchniaVikinga
      )
    );
  });

  useEffect(() => {
    if (orderForm.saturdays !== prevSaturdays) {
      dispatch(toggleWeekendDays('saturday', isKuchniaVikinga));

      if (orderForm.saturdays) {
        const noSaturdays =
          orderForm.filteredWeekends &&
          orderForm.filteredWeekends.filter(
            (day: number) => DateTime.fromMillis(day).weekday !== 6
          );

        dispatch(resetFilteredDays(noSaturdays));
      }
    }
  }, [
    dispatch,
    isKuchniaVikinga,
    orderForm.filteredWeekends,
    orderForm.saturdays,
    prevSaturdays,
  ]);

  useEffect(() => {
    if (orderForm.sundays !== prevSundays) {
      dispatch(toggleWeekendDays('sunday', isKuchniaVikinga));

      if (orderForm.sundays) {
        const noSundays =
          orderForm.filteredWeekends &&
          orderForm.filteredWeekends.filter(
            (day: number) => DateTime.fromMillis(day).weekday !== 7
          );

        dispatch(resetFilteredDays(noSundays));
      }
    }
  }, [
    dispatch,
    isKuchniaVikinga,
    orderForm.filteredWeekends,
    orderForm.sundays,
    prevSundays,
  ]);

  return (
    <div className="calendar">
      {textCalendar1 && (
        <p className="body-m spacer-bottom-12">{textCalendar1}</p>
      )}

      <div className="calendar__wrapper">
        <CalendarBase
          baseDate={DateTime.local().plus({ months: offset })}
          calendarPosition="left"
          offset={offset}
          setOffset={setOffset}
          setSelectedValue={setSelectedValue}
          validation={validationDays.value === 'Days not selected'}
        />

        <div className="hidden-down-xl">
          <CalendarBase
            baseDate={DateTime.local().plus({ months: offset + 1 })}
            calendarPosition="right"
            offset={offset}
            setOffset={setOffset}
            setSelectedValue={setSelectedValue}
            validation={validationDays.value === 'Days not selected'}
          />
        </div>
      </div>

      {validationDays.value === 'Days not selected' && (
        <div className="calendar__day-validation">
          <FontAwesomeIcon
            className="spacer-right-16 font-size-20"
            icon={faExclamationCircle}
          />
          {validationDays.message}
        </div>
      )}

      {textCalendar2 && <p className="body-m spacer-top-12">{textCalendar2}</p>}

      <div className="calendar__footer">
        {orderForm.days > 0 &&
          !validateQuantityOfOrder &&
          !!orderForm.selectedDays?.length &&
          biggestDiscount &&
          biggestDiscount.minimumDays > orderForm.days &&
          !promoCode?.code?.separate &&
          !orderForm.testOrder && (
            <div className="width-100-per">
              <DiscountBar
                closestDietDiscountDetails={closestDietDiscountDetails}
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default Calendar;
