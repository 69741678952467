import { SideOrderOrCourseTimeRestrictionT } from '@features/coursesAsSideOrders/types/coursesAsSideOrders.type';
import { Prettify } from 'typesRoot/generics.type';

export type SideOrderT = {
  amount: number;
  brand?: null;
  customDates: boolean;
  defaultPrice: number;
  deleted: boolean;
  deliveriesLimit: any | null;
  description: string | null;
  imageUrl: string | null;
  limitedByMaximumSelectedMeals: boolean;
  maxQuantity: number | null;
  minQuantity: number | null;
  name: string;
  possibleSideOrderId: number;
  publiclyAvailable: boolean;
  taxValue: number | null;
} & SideOrderOrCourseTimeRestrictionT;

export type OneOffSideOrderT = Prettify<
  Omit<SideOrderT, 'customDates'> & { customDates: true }
>;

export const isOneOffSideOrder = (
  sideOrder: SideOrderT
): sideOrder is OneOffSideOrderT => sideOrder.customDates;

export type OneOffWithoutAmountT = Prettify<Omit<OneOffSideOrderT, 'amount'>>;

export type PossibleSideOrderWithoutAmount = Prettify<
  Omit<SideOrderT, 'amount'>
>;

export type GetPossibleSideOrdersResponseT = {
  oneOffs: Array<OneOffWithoutAmountT>;
  possibleSideOrders: Array<PossibleSideOrderWithoutAmount>;
};
