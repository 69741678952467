import React from 'react';

const MealIcon = ({ className }) => {
  return (
    <svg
      className={className}
      fill="none"
      height="205"
      viewBox="0 0 218 205"
      width="218"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M155.294 114.607L175.747 110.212C188.271 107.52 191.876 91.382 181.688 83.6169L174.359 78.0312C168.706 73.7223 166.853 66.0371 169.922 59.6255L175.368 48.2474C182.13 34.1202 165.438 20.3861 152.877 29.742L149.539 32.229C142.533 37.4473 132.531 35.2642 128.336 27.6013C123.554 18.8644 111.553 17.518 104.954 24.9782L63.6671 71.6525C62.4476 73.0311 60.9729 74.1607 59.3243 74.9791L44.3617 82.407C32.3495 88.3702 38.159 106.568 51.4044 104.468C61.273 102.903 68.4439 113.619 63.2337 122.145L43.1368 155.031C34.8294 168.625 50.6442 184.085 64.0462 175.471L91.8446 157.604C99.5116 152.677 109.765 155.669 113.579 163.947L114.752 166.493C121.476 181.09 143.339 176.322 143.376 160.251L143.446 129.238C143.462 122.181 148.395 116.09 155.294 114.607Z"
        fill="#06257F"
        opacity="0.1"
      />
      <path
        clipRule="evenodd"
        d="M163 116H55C52.8 116 51 114.2 51 112C51 109.8 52.8 108 55 108H163C165.2 108 167 109.8 167 112C167 114.2 165.2 116 163 116Z"
        fill="white"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M163 116H55C52.8 116 51 114.2 51 112C51 109.8 52.8 108 55 108H163C165.2 108 167 109.8 167 112C167 114.2 165.2 116 163 116Z"
        fill="#ECEEF8"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M55.916 116C61.842 125.258 87 136.41 87 150H131C131 136.41 156.158 125.258 162.084 116H55.916Z"
        fill="white"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M55.916 116C61.842 125.258 87 136.41 87 150H131C131 136.41 156.158 125.258 162.084 116H55.916Z"
        fill="white"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M69 102C69 90.946 77.946 82 89 82H93M81 102C81 97.578 84.578 94 89 94H99M97 88H89C81.262 88 75 94.262 75 102M89 100H99M147 102C147 90.946 138.054 82 127 82H113M135 102C135 97.578 131.422 94 127 94H117M115 88H127C134.738 88 141 94.262 141 102M127 100H117M83 78C83 75.788 84.788 74 87 74C101.37 74 105 87.628 105 102M75 82V80C75 73.368 80.368 68 87 68C104.688 68 111 84.312 111 102"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M121 70C121 73.314 118.314 76 115 76C111.686 76 109 73.314 109 70C109 66.686 111.686 64 115 64C118.314 64 121 66.686 121 70Z"
        fill="white"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M121 70C121 73.314 118.314 76 115 76C111.686 76 109 73.314 109 70C109 66.686 111.686 64 115 64C118.314 64 121 66.686 121 70Z"
        fill="#FFEBE5"
        fillRule="evenodd"
        stroke="#FF5226"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M107 59C107 62.866 103.866 66 100 66C96.134 66 93 62.866 93 59C93 55.134 96.134 52 100 52C103.866 52 107 55.134 107 59Z"
        fill="white"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M107 59C107 62.866 103.866 66 100 66C96.134 66 93 62.866 93 59C93 55.134 96.134 52 100 52C103.866 52 107 55.134 107 59Z"
        fill="white"
        fillRule="evenodd"
        stroke="#FF5226"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M139 72C139 75.314 136.314 78 133 78C129.686 78 127 75.314 127 72C127 68.686 129.686 66 133 66C136.314 66 139 68.686 139 72Z"
        fill="white"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M139 72C139 75.314 136.314 78 133 78C129.686 78 127 75.314 127 72C127 68.686 129.686 66 133 66C136.314 66 139 68.686 139 72Z"
        fill="white"
        fillRule="evenodd"
        stroke="#FF5226"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M127 54C127 57.314 124.314 60 121 60C117.686 60 115 57.314 115 54C115 50.686 117.686 48 121 48C124.314 48 127 50.686 127 54Z"
        fill="white"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M127 54C127 57.314 124.314 60 121 60C117.686 60 115 57.314 115 54C115 50.686 117.686 48 121 48C124.314 48 127 50.686 127 54Z"
        fill="white"
        fillRule="evenodd"
        stroke="#FF5226"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M86 144H132H86Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M86 144H132"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default MealIcon;
