import React from 'react';

const GuestOrderConfirmationLoyaltyProgramIcon = ({ classes }) => {
  return (
    <svg
      className={classes}
      fill="none"
      height="160"
      viewBox="0 0 200 160"
      width="200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M127.009 23.0877L102.454 27.8465C98.8315 28.5485 95.1348 27.0573 93.0135 24.0385C88.8283 18.0828 79.7452 18.9729 76.7959 25.6278L65.4969 51.1231C64.5137 53.3415 63.0071 55.2882 61.1062 56.7963L29.4929 81.8779C20.3896 89.1003 25.0237 103.745 36.6247 104.417L41.472 104.697C50.3399 105.21 54.5327 115.879 48.3867 122.293C40.7934 130.216 49.044 142.978 59.386 139.305L81.3522 131.506C84.9781 130.218 88.9601 130.373 92.4749 131.939L126.802 147.23C134.929 150.85 144.403 146.642 147.165 138.184L147.979 135.69C149.268 131.742 152.137 128.504 155.902 126.75L187.743 111.913C198.612 106.849 194.061 90.4672 182.137 91.7345C171.285 92.8879 165.965 78.8828 174.845 72.5397L176.884 71.0833C186.901 63.9279 179.839 48.1876 167.834 50.9136L143.966 56.3333C134.268 58.5356 127.965 46.4186 135.337 39.7422C142.588 33.175 136.614 21.2264 127.009 23.0877Z"
        fill="#1B42B2"
        opacity="0.1"
      />
      <path
        clipRule="evenodd"
        d="M42 22H158V138H42V22Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M42 22H158V138H42V22Z"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M42 22H158V46H42V22Z"
        fill="#F2F5FC"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M42 22H158V46H42V22Z"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeWidth="2"
      />
      <path
        d="M122 122H76M112 114H86H112Z"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M60 34C60 37.314 57.314 40 54 40C50.686 40 48 37.314 48 34C48 30.686 50.686 28 54 28C57.314 28 60 30.686 60 34Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M60 34C60 37.314 57.314 40 54 40C50.686 40 48 37.314 48 34C48 30.686 50.686 28 54 28C57.314 28 60 30.686 60 34Z"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M78 34C78 37.314 75.314 40 72 40C68.686 40 66 37.314 66 34C66 30.686 68.686 28 72 28C75.314 28 78 30.686 78 34Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M78 34C78 37.314 75.314 40 72 40C68.686 40 66 37.314 66 34C66 30.686 68.686 28 72 28C75.314 28 78 30.686 78 34Z"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M96 34C96 37.314 93.314 40 90 40C86.686 40 84 37.314 84 34C84 30.686 86.686 28 90 28C93.314 28 96 30.686 96 34Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M96 34C96 37.314 93.314 40 90 40C86.686 40 84 37.314 84 34C84 30.686 86.686 28 90 28C93.314 28 96 30.686 96 34Z"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M124 80C124 93.254 113.254 104 100 104C86.746 104 76 93.254 76 80C76 66.746 86.746 56 100 56C113.254 56 124 66.746 124 80Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M124 80C124 93.254 113.254 104 100 104C86.746 104 76 93.254 76 80C76 66.746 86.746 56 100 56C113.254 56 124 66.746 124 80Z"
        fill="white"
        fillRule="evenodd"
        stroke="#FF5226"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M105.864 95.1339C104.97 94.6878 104.404 93.7719 104.404 92.7719V90.0558C104.6 89.8358 104.822 89.5498 105.06 89.2138C106.004 87.8818 106.716 86.4158 107.212 84.8778C108.098 84.6038 108.754 83.7838 108.754 82.8098V79.9098C108.754 79.2718 108.47 78.7018 108.028 78.3038V74.1138C108.028 74.1138 108.89 67.5898 100.056 67.5898C91.2242 67.5898 92.0842 74.1138 92.0842 74.1138V78.3038C91.6442 78.7018 91.3602 79.2718 91.3602 79.9098V82.8098C91.3602 83.5738 91.7622 84.2458 92.3622 84.6338C93.0862 87.7878 94.9842 90.0558 94.9842 90.0558V92.7058C94.9842 93.6718 94.4562 94.5598 93.6082 95.0238L87.1422 98.5499C86.7002 98.7919 86.3062 99.0918 85.9482 99.4278C89.9022 102.292 94.7462 104 100 104C105.246 104 110.082 102.298 114.032 99.4438C113.658 99.1138 113.244 98.8238 112.786 98.5958L105.864 95.1339Z"
        fill="white"
        fillRule="evenodd"
        stroke="#FF5226"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default GuestOrderConfirmationLoyaltyProgramIcon;
