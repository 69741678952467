import React from 'react';

const CalendarIcon = () => {
  return (
    <svg
      fill="none"
      height="32"
      viewBox="0 0 40 32"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34 0.5H6C2.96243 0.5 0.5 2.96243 0.5 6V26C0.5 29.0376 2.96243 31.5 6 31.5H34C37.0376 31.5 39.5 29.0376 39.5 26V6C39.5 2.96243 37.0376 0.5 34 0.5Z"
        stroke="#E6EAED"
      />
      <path
        d="M17 17.5C17 18.3284 16.3284 19 15.5 19C14.6716 19 14 18.3284 14 17.5C14 16.6716 14.6716 16 15.5 16C16.3284 16 17 16.6716 17 17.5Z"
        fill="#8FA1B3"
      />
      <path
        d="M20 19C20.8284 19 21.5 18.3284 21.5 17.5C21.5 16.6716 20.8284 16 20 16C19.1716 16 18.5 16.6716 18.5 17.5C18.5 18.3284 19.1716 19 20 19Z"
        fill="#8FA1B3"
      />
      <path
        d="M26 17.5C26 18.3284 25.3284 19 24.5 19C23.6716 19 23 18.3284 23 17.5C23 16.6716 23.6716 16 24.5 16C25.3284 16 26 16.6716 26 17.5Z"
        fill="#8FA1B3"
      />
      <path
        d="M15.5 23.5C16.3284 23.5 17 22.8284 17 22C17 21.1716 16.3284 20.5 15.5 20.5C14.6716 20.5 14 21.1716 14 22C14 22.8284 14.6716 23.5 15.5 23.5Z"
        fill="#8FA1B3"
      />
      <path
        d="M21.5 22C21.5 22.8284 20.8284 23.5 20 23.5C19.1716 23.5 18.5 22.8284 18.5 22C18.5 21.1716 19.1716 20.5 20 20.5C20.8284 20.5 21.5 21.1716 21.5 22Z"
        fill="#8FA1B3"
      />
      <path
        d="M24.5 23.5C25.3284 23.5 26 22.8284 26 22C26 21.1716 25.3284 20.5 24.5 20.5C23.6716 20.5 23 21.1716 23 22C23 22.8284 23.6716 23.5 24.5 23.5Z"
        fill="#8FA1B3"
      />
      <path
        clipRule="evenodd"
        d="M31 10C31 8.3 29.7 7 28 7H26V6C26 5.4 25.6 5 25 5C24.4 5 24 5.4 24 6V7H16V6C16 5.4 15.6 5 15 5C14.4 5 14 5.4 14 6V7H12C10.3 7 9 8.3 9 10V24C9 25.7 10.3 27 12 27H28C29.7 27 31 25.7 31 24V10ZM14 10C14 10.6 14.4 11 15 11C15.6 11 16 10.6 16 10V9H24V10C24 10.6 24.4 11 25 11C25.6 11 26 10.6 26 10V9H28C28.6 9 29 9.4 29 10V13H11V10C11 9.4 11.4 9 12 9H14V10ZM11 15H29V24C29 24.6 28.6 25 28 25H12C11.4 25 11 24.6 11 24V15Z"
        fill="#8FA1B3"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default CalendarIcon;
