import {
  useLazyGetApiDiscountCodesQuery,
  usePostApiDiscountCodesMutation,
} from '@services/api/form/open/discountCodes.api';
import { useAppDispatch, useAppSelector } from '@config/hooks';
import {
  promoCodeFetchingError,
  storePromoCode,
} from '@redux/actions/basketActions';
import {
  getBasket,
  getPromoCode,
  getUniqueDaysNumber,
} from '@redux/selectors/basketSelector';
import { getSelectedCity } from '@redux/selectors/summaryReducer.selectors';
import { checkIsSalesManagoEnabled } from '@salesManago/helpers';
import { salesManagoUpdatePromoCode } from '@salesManago/events/shopCart';
import { getServerConfig } from '@features/general/generalSlice';
import useCalculateCartPrice from '../services/hook/calculatePrices/useCalculateCartPrice.hook';
import { getOneOffsSelectedFees } from '@features/orderForm/stepShoppingCart/redux/oneOffSideOrders.selector';
import { getSelectedSideOrders } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.selector';

const usePromoCode = () => {
  const dispatch = useAppDispatch();
  const { finalSum } = useCalculateCartPrice();

  const promoCode = useAppSelector(getPromoCode);
  const selectedCity = useAppSelector(getSelectedCity);
  const basket = useAppSelector(getBasket);
  const selectedFees = useAppSelector(getOneOffsSelectedFees) || [];
  const uniqueDaysNumber = useAppSelector(getUniqueDaysNumber);
  const { companyId, salesManago, serverUrl } = useAppSelector(getServerConfig);
  const selectedSideOrders = useAppSelector(getSelectedSideOrders);

  const { cityId } = selectedCity || {};

  const { prices } = useCalculateCartPrice();

  const [postApiDiscountCodes, { isLoading: postDiscountCodesIsLoading }] =
    usePostApiDiscountCodesMutation();

  const [getApiDiscountCodes, { isFetching: getDiscountCodesIsFetching }] =
    useLazyGetApiDiscountCodesQuery();

  const isLoading = postDiscountCodesIsLoading || getDiscountCodesIsFetching;

  const addCode = async (code: string) => {
    const result = await getApiDiscountCodes({ code, companyId });

    if (result.isError) {
      dispatch(
        promoCodeFetchingError('Podany kod nie istnieje lub nie jest dostępny')
      );

      return Promise.reject(new Error());
    }

    dispatch(storePromoCode(result.data));

    if (
      checkIsSalesManagoEnabled(salesManago) &&
      !!promoCode.code?.promoCodeId &&
      !!basket?.length &&
      !!basket[0].eventId
    ) {
      salesManagoUpdatePromoCode({
        code: promoCode.code?.code,
        item: basket[0],
        selectedCity,
        selectedFees,
        description: 'Dodanie promoCode',
        serverUrl,
        salesManago,
        companyId,
        selectedSideOrders,
        cartPrices: prices?.cart,
      }).catch(e => {
        console.log(e, 'e');
      });

      return Promise.resolve(result.data);
    }
  };

  const fetchPromoCode = async (code: string, checkCode = true) => {
    if (code && code.length) {
      if (!checkCode) {
        await addCode(code);
        return null;
      }

      const res = await postApiDiscountCodes({
        companyId,
        body: {
          code,
          cityId,
          deliveriesNumber: uniqueDaysNumber,
          dietWithRegularSideOrdersCost: finalSum,
          dietCaloriesIds: basket.map(
            (item: { selectedCalories: { dietCaloriesId: number } }) =>
              item.selectedCalories.dietCaloriesId
          ),
        },
      });

      if ('error' in res) {
        // @ts-ignore
        if (res.error.status === 404) {
          dispatch(
            promoCodeFetchingError(
              'Podany kod nie istnieje lub nie jest dostępny'
            )
          );
        }

        return Promise.reject(new Error());
      }

      await addCode(code);
    }

    return null;
  };

  return { fetchPromoCode, isLoading };
};

export default usePromoCode;
