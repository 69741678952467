import { useState } from 'react';
import {
  agreementsText1, agreementsText2, marketingAgreementsArray,
} from '@components/pages/StepSummary/consts/MarketingAgreements.const';
import { classNamesUtil } from '@dietly/design-system-library';
import Checkbox from '@components/common/checkbox';
import { toggleAgreementsCheckbox } from '@redux/actions/summaryActions';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@config/hooks';
import { getSummaryReducer } from '@redux/selectors/summaryReducer.selectors';


const MarketingAgreements = () => {
  const [showInfo, setShowInfo] = useState(false);
  const dispatch = useDispatch();
  const summaryReducer = useAppSelector(getSummaryReducer);

  const handleShowInfo = () => {
    setShowInfo(prevState => !prevState);
  };
  const handleToggleAgreement = (name: string, checked: boolean) => {
    dispatch(toggleAgreementsCheckbox(name, checked));
  };

  return (
    <>
      <div className="body-s spacer-bottom-16">
        <p>{agreementsText1}</p>
      </div>
      <div className={'display-flex flex-direction-column'}>
        {marketingAgreementsArray.map(({ name, text }) => (
          <Checkbox
            checked={summaryReducer[name]}
            containerClass="align-items-top spacer-bottom-16"
            id={name}
            key={name}
            label={<p className="body-s">{text}</p>}
            name={name}
            onChange={({ target: { checked } }) => handleToggleAgreement(name, checked)}
          />
        ))}
      </div>
      <div className="body-s spacer-bottom-16">
        <p>
          Rozumiem, że{' '}
          <span className={classNamesUtil('', { 'display-none': !showInfo })}>
            {' '}
            {agreementsText2}
          </span>{' '}
          <span
            className={'text-decoration-underline font-weight-500'}
            onClick={handleShowInfo}
            onKeyDown={handleShowInfo}
            role="button"
            tabIndex={0}
          >
            zobacz {showInfo ? `mniej` : `więcej`}
          </span>
        </p>
      </div>
    </>
  );
};

export default MarketingAgreements;
