// @ts-ignore
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { customNumSort } from '../../../../../utils';
import {
  checkIfHighlightedDateIsSecondToLast,
  getNewHighlightedDate,
  parseDay,
} from '../../helpers/menuHelpers';
import ArrowLeft1Icon from '../../../../../assets/icons/ArrowLeft1Icon';
import ArrowRight1Icon from '../../../../../assets/icons/ArrowRight1Icon';
import EmptyMenu from '../../../../../assets/icons/EmptyMenu';
import Button from '../../../../common/Button';
import SmallMenu from '../../../../../assets/icons/SmallMenu';
import SwiperClass from 'swiper/types/swiper-class';
import { faSync } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuMealIngredientT, MenuT } from '../../../../../@types/menu.type';
import { classNamesUtil } from '../../../../../utils/classNamesUtil.util';
import { Collapsible } from '@components/common/Collapsible/Collapsible';
import { useAppSelector } from '@config/hooks';
import { getIsKuchniaVikinga } from '@features/general/generalSlice';

type Props = {
  centeredSlides: boolean | undefined;
  dates: string[];
  highlightedDate: string;
  isLoading: boolean;
  isMenuVisibleInDietly: boolean;
  isWide: boolean;
  menus: MenuT;
  setHighLightedDate: (date: string) => void;
  setSwiperMenu: (swiper: SwiperClass) => void;
  swiperMenu: {
    slideNext: () => void;
    slidePrev: () => void;
  } | null;
};

const MenuCarousel = ({
  centeredSlides,
  dates,
  highlightedDate,
  isLoading,
  isMenuVisibleInDietly,
  isWide,
  menus,
  setHighLightedDate,
  setSwiperMenu,
  swiperMenu,
}: Props) => {
  const isKuchniaVikinga = useAppSelector(getIsKuchniaVikinga);

  return (
    <>
      {isMenuVisibleInDietly ? (
        <div>
          {isLoading ? (
            <div className="catering-menu__loader">
              <FontAwesomeIcon
                className="font-size-60 color-gray-400"
                icon={faSync}
                spin
              />
              <span className="label-l spacer-top-16">Ładowanie menu...</span>
            </div>
          ) : (
            <div className="catering-menu__calendar">
              <Swiper
                allowTouchMove={false}
                centeredSlides={centeredSlides && !isWide}
                initialSlide={dates.indexOf(highlightedDate) - 1}
                observeParents
                observer
                observeSlideChildren
                onSwiper={setSwiperMenu}
                slidesPerView={isWide ? 3 : centeredSlides ? 1.3 : 1}
                spaceBetween={8}
                threshold={20}
              >
                {dates?.map(date => {
                  return (
                    <SwiperSlide key={date} style={{ height: 'auto' }}>
                      {
                        <div
                          className={classNamesUtil(
                            'flex-1 catering-menu__box',
                            {
                              'catering-menu__box--gray':
                                date !== highlightedDate && isWide,
                              'catering-menu__box--shadow':
                                date === highlightedDate,
                            }
                          )}
                          key={date}
                        >
                          <div className="catering-menu__buttons">
                            <Button
                              colorClean
                              containerClass={classNamesUtil(
                                'color-gray-800 cursor-pointer',
                                {
                                  'visibility-hidden':
                                    date !== highlightedDate && isWide,
                                }
                              )}
                              icon={<ArrowLeft1Icon color="#62748C" />}
                              onClick={() => {
                                if (
                                  !checkIfHighlightedDateIsSecondToLast(
                                    highlightedDate,
                                    dates
                                  )
                                ) {
                                  swiperMenu?.slidePrev();
                                  return setHighLightedDate(
                                    getNewHighlightedDate(
                                      highlightedDate,
                                      'down'
                                    ) as string
                                  );
                                }
                                return null;
                              }}
                            />
                            <div className="display-flex flex-direction-column align-items-center justify-content-center">
                              <div className="label-m text-align-center color-gray-400">
                                <div className="catering-menu__date">
                                  {parseDay('d.LL.yyyy', date)}
                                </div>
                                <div className="spacer-bottom-16 color-gray-400">
                                  {parseDay('cccc', date)}
                                </div>
                              </div>
                            </div>
                            <Button
                              colorClean
                              containerClass={classNamesUtil(
                                'color-gray-800 cursor-pointer',
                                {
                                  'visibility-hidden':
                                    date !== highlightedDate && isWide,
                                }
                              )}
                              icon={<ArrowRight1Icon color="#62748C" />}
                              onClick={() => {
                                if (
                                  dates.indexOf(highlightedDate) ===
                                  dates.length - 2
                                ) {
                                  return null;
                                }
                                swiperMenu?.slideNext();
                                return setHighLightedDate(
                                  getNewHighlightedDate(
                                    highlightedDate,
                                    'up'
                                  ) as string
                                );
                              }}
                            />
                          </div>
                          {menus && menus[date]?.length ? (
                            <div className="catering-menu__box-menu">
                              <span className="h300 spacer-bottom-16 hidden-up-md">
                                {parseDay('cccc', date)},{' '}
                                {parseDay('d.LL.yyyy', date)}
                              </span>
                              {customNumSort('priority')(menus[date])?.map(
                                meal => {
                                  return (
                                    <div
                                      className="spacer-top-12"
                                      key={meal?.menuMealId}
                                    >
                                      <h6 className="catering-menu__meal-name">
                                        {meal?.mealName}:
                                      </h6>
                                      <div className="body-m dataText">
                                        {meal?.menuMealName}
                                      </div>
                                      {isKuchniaVikinga && (
                                        <div className="body-m dataText">
                                          <Collapsible
                                            bodyTextHidable={
                                              'Składniki:\n' +
                                              meal?.ingredients
                                                ?.map(
                                                  (item: MenuMealIngredientT) =>
                                                    item.name
                                                )
                                                ?.join(', ') +
                                              '\nAlergeny:\n' +
                                              meal?.allergens?.join(', ')
                                            }
                                            bodyTextVisible={''}
                                            breakLines={true}
                                            headerText={[
                                              'Pokaż składniki i alergeny',
                                              'Zwiń',
                                            ]}
                                            isOpenAtStart={false}
                                            linkColor="bluePrimary"
                                          />
                                        </div>
                                      )}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          ) : (
                            <div className="catering-menu__empty-card">
                              <EmptyMenu />
                              <h6 className="label-m text-align-center">
                                Menu nie jest dostępne
                              </h6>
                            </div>
                          )}
                        </div>
                      }
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          )}
        </div>
      ) : (
        <div className="catering-menu__loader">
          <SmallMenu color="#1F2E4D" />
          <span className="label-l spacer-top-16 text-align-center max-width-330">
            Niestety catering nie udostępnia rozpisanego menu dla tej diety
          </span>
        </div>
      )}
    </>
  );
};
export default MenuCarousel;
