import { useEffect, useState } from 'react';
import { useGetPickupPointsQuery } from '../services/api/deliveryPickupPoint.api';
import { faCheck, faSync } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  choosePickupPoint,
  selectCityOrZipcode,
} from '../../../redux/actions/summaryActions';
import { getSummaryReducer } from '../../../redux/selectors/summaryReducer.selectors';
import { useAppDispatch, useAppSelector } from '../../../config/hooks';
import { DietlyOpenCityT } from '../../../services/api/form/open/formOpenCities.api';
import ComboboxCitiesOrder from '../../../components/common/search/ComboboxCitiesOrder';
import { ComboboxSize } from '../../../@types/city/city.type';
import { getServerConfig } from '../../general/generalSlice';
import { renderOpeningHours } from '../utils/renderOpeningHours.util';

type Props = {
  companyId: string;
  errorMsg: string;
  onSelect: (pickupPointId: number) => void;
};

const DeliveryPickupPoint = ({ companyId, errorMsg, onSelect }: Props) => {
  const dispatch = useAppDispatch();
  const [city, setCity] = useState<DietlyOpenCityT>();

  const summaryReducer = useAppSelector(getSummaryReducer);
  const { apiKey, serverUrl } = useAppSelector(getServerConfig);

  const { pickupPointId: selectedPickupPointId } = summaryReducer || {};

  const { data: pickupPoints = [], isFetching } = useGetPickupPointsQuery(
    { cityId: city?.cityId || 0, companyId, serverUrl, apiKey },
    {
      skip: !city?.cityId,
    }
  );

  useEffect(() => {
    return () => {
      dispatch(choosePickupPoint('', null));
    };
  }, [dispatch]);

  const handleSelectPickupPoint = (
    pickupPointId: number,
    discount: number | null
  ) => {
    dispatch(choosePickupPoint(pickupPointId, discount));
    onSelect(pickupPointId);
    dispatch(selectCityOrZipcode(city, 'city'));
  };

  const handleSelectCity = (city: DietlyOpenCityT) => {
    setCity(city);

    if (selectedPickupPointId) {
      dispatch(choosePickupPoint('', null));
    }
  };

  return (
    <div className="steps-page-third__list spacer-bottom-24">
      <h4 className="label-l spacer-bottom-24">Znajdź punkt odbioru</h4>

      <p className="label-m spacer-bottom-8 color-gray-500">Miasto</p>
      <ComboboxCitiesOrder
        companyName={companyId}
        containerClass="spacer-bottom-24"
        initialSelectedItem={city}
        onSelect={handleSelectCity}
        size={ComboboxSize.L}
      />

      {errorMsg && (
        <div className="color-error body-m spacer-bottom-24">{errorMsg}</div>
      )}

      {(() => {
        if (isFetching) {
          return (
            <FontAwesomeIcon
              className="font-size-40 color-gray-400"
              icon={faSync}
              spin
            />
          );
        }

        if (city?.cityId) {
          return pickupPoints?.length ? (
            <>
              <div className="label-m color-gray-500">
                Wybierz punkt odbioru
              </div>
              {pickupPoints.map(point => {
                const {
                  apartmentNumber,
                  city: { cityName } = {},
                  discount,
                  holidaysFrom,
                  holidaysTo,
                  houseNumber,
                  name: pointName,
                  pickupPointId,
                  saturdayFrom,
                  saturdayTo,
                  street,
                  weekdaysFrom,
                  weekdaysTo,
                  zipCode,
                } = point;

                const selected = selectedPickupPointId === pickupPointId;

                return (
                  <div
                    className={`steps-page-third__list-item ${
                      selected ? 'steps-page-third__list-item--active' : ''
                    }`}
                    key={pickupPointId}
                    onClick={() => {
                      handleSelectPickupPoint(pickupPointId, discount);
                    }}
                    onKeyDown={() => {
                      handleSelectPickupPoint(pickupPointId, discount);
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <div>
                      <p>
                        {pointName}, {street} {houseNumber}
                        {apartmentNumber && `/${apartmentNumber}`}, {zipCode}{' '}
                        {cityName}
                      </p>
                      <div className="body-s">
                        {renderOpeningHours(
                          holidaysFrom,
                          holidaysTo,
                          saturdayFrom,
                          saturdayTo,
                          weekdaysFrom,
                          weekdaysTo
                        )}
                      </div>
                    </div>
                    {selected && (
                      <FontAwesomeIcon
                        className="spacer-left-8"
                        icon={faCheck}
                      />
                    )}
                  </div>
                );
              })}
            </>
          ) : (
            <div className="label-m color-gray-500">
              Nie znaleziono punktów odbioru dla miasta {city?.name}
            </div>
          );
        }

        return null;
      })()}
    </div>
  );
};

export default DeliveryPickupPoint;
