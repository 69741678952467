import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { SideOrderT } from 'typesRoot/sideOrder.type';
import {
  changeOneOffAmount,
  removeOneOff,
} from '@features/orderForm/stepShoppingCart/redux/oneOffSideOrders.slice';
import {
  changeAdditionalFeeAmountInLocalStorage,
  deleteAdditionalFeeFromLocalStorage,
} from '@features/orderForm/stepShoppingCart/utils/stepShoppingCart.util';
import { useAppDispatch } from '@config/hooks';
import useSalesManagoUpdateOneOffs from '@salesManago/services/hooks/useSalesManagoUpdateOneOffs';
import placeholderUrl from '@assets/images/placeholder.jpg';
import { classNamesUtil } from '@dietly/design-system-library';
import { parsePrice } from 'utilsRoot/index';
import DaysAmount from '@components/common/DaysAmount';

type Props = {
  selectedFees: Array<SideOrderT>;
};

const OneOffsShoppingCart = ({ selectedFees }: Props) => {
  const dispatch = useAppDispatch();

  const { salesManagoRemoveOneOff } = useSalesManagoUpdateOneOffs();

  const handleRemoveClick = (oneOff: SideOrderT) => {
    dispatch(removeOneOff(oneOff));
    deleteAdditionalFeeFromLocalStorage(oneOff.possibleSideOrderId);

    salesManagoRemoveOneOff(oneOff);
  };

  const handleAmountClick = (clickedOneOff: SideOrderT, newAmount: number) => {
    const { possibleSideOrderId } = clickedOneOff;
    changeAdditionalFeeAmountInLocalStorage(possibleSideOrderId, newAmount);
    dispatch(changeOneOffAmount({ oneOff: clickedOneOff, newAmount }));
  };

  return (
    <section className="product-basket">
      <h3 className="label-l spacer-bottom-24">Dodane produkty</h3>
      <AnimatePresence>
        {selectedFees.map(oneOff => {
          const {
            amount,
            defaultPrice,
            description,
            imageUrl,
            maxQuantity,
            minQuantity,
            name,
            possibleSideOrderId,
          } = oneOff;

          const canDecrease = amount > (minQuantity || 1);
          const canIncrease =
            (maxQuantity && amount < maxQuantity) || !maxQuantity;

          return (
            <motion.div
              animate={{
                opacity: 1,
                height: 'auto',
                marginBottom: 24,
                paddingBottom: 24,
              }}
              className="product-basket__product"
              exit={{
                opacity: 0,
                height: 0,
                marginBottom: -20,
                paddingBottom: -20,
              }}
              initial={{
                opacity: 0,
                height: 0,
                marginBottom: 24,
                paddingBottom: 24,
              }}
              key={possibleSideOrderId}
              transition={{ duration: 0.2 }}
            >
              <div className="product-basket__wrapper">
                <div
                  className={classNamesUtil('product-basket__image', {
                    'product-basket__image--empty': !imageUrl,
                  })}
                >
                  <img
                    alt="one off side order detailed"
                    src={imageUrl || placeholderUrl}
                  />
                </div>
                <div className="product-basket__header-mobile">
                  <h6>{name}</h6>
                  <div className="display-flex flex-direction-column">
                    <span>{parsePrice(defaultPrice * amount)} zł</span>
                    <div className="body-m spacer-vertical-8">jednorazowo</div>
                  </div>
                </div>
              </div>
              <div className="width-100-per">
                <div className="product-basket__header-desktop">
                  <h6>{name}</h6>
                  <div className="display-flex flex-direction-column align-items-end">
                    <span>{parsePrice(defaultPrice * amount)} zł</span>
                    <div className="body-m spacer-vertical-8">jednorazowo</div>
                  </div>
                </div>
                <div className="product-basket__description">
                  <div>
                    <p className="spacer-bottom-24 spacer-right-20">
                      {description}
                    </p>
                    <div
                      className="product-basket__button"
                      onClick={() => handleRemoveClick(oneOff)}
                      onKeyDown={() => handleRemoveClick(oneOff)}
                      role="button"
                      tabIndex={0}
                    >
                      <FontAwesomeIcon
                        className="color-gray-400 spacer-right-8"
                        icon={faTrash}
                      />
                      Usuń produkt
                    </div>
                  </div>
                  <div className="display-flex">
                    <div className="product-basket__amount">
                      <DaysAmount
                        days={amount}
                        decrease={() => {
                          if (canDecrease) {
                            handleAmountClick(oneOff, oneOff.amount - 1);
                          }
                        }}
                        disableDecrease={!canDecrease}
                        disableIncrease={!canIncrease}
                        increase={() => {
                          if (canIncrease) {
                            handleAmountClick(oneOff, oneOff.amount + 1);
                          }
                        }}
                        withoutBorders
                      />
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          );
        })}
      </AnimatePresence>
    </section>
  );
};

export default OneOffsShoppingCart;
