const EmptyImageCourseAsSideOrder = ({
  height = '64',
  width = '64',
}: {
  height?: string;
  width?: string;
}) => {
  return (
    <svg
      fill="none"
      height={height}
      preserveAspectRatio="none"
      viewBox="0 0 215 215"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="#F2F4F7" height="215" rx="6" width="215" />
      <path
        d="M81.0832 134.916V87.923L83.9165 90.7563V134.916C83.9165 136.481 85.185 137.749 86.7498 137.749H129.25C129.73 137.749 130.183 137.63 130.579 137.419L132.627 139.467C131.684 140.168 130.515 140.583 129.25 140.583H86.7498C83.6202 140.583 81.0832 138.046 81.0832 134.916Z"
        fill="#8FA1B3"
      />
      <path
        d="M86.7498 119.333V93.5896L89.5832 96.423V110.246L96.4946 103.334L98.4981 105.338L89.5832 114.253V119.333C89.5832 120.115 90.2174 120.749 90.9998 120.749H113.91L116.743 123.583H90.9998C88.6526 123.583 86.7498 121.68 86.7498 119.333Z"
        fill="#8FA1B3"
      />
      <path
        clipRule="evenodd"
        d="M115.083 89.5827C111.954 89.5827 109.417 92.1197 109.417 95.2493C109.417 98.379 111.954 100.916 115.083 100.916C118.213 100.916 120.75 98.379 120.75 95.2493C120.75 92.1197 118.213 89.5827 115.083 89.5827ZM112.25 95.2493C112.25 93.6845 113.518 92.416 115.083 92.416C116.648 92.416 117.917 93.6845 117.917 95.2493C117.917 96.8142 116.648 98.0827 115.083 98.0827C113.518 98.0827 112.25 96.8142 112.25 95.2493Z"
        fill="#8FA1B3"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M81.3532 79.3493L77.8349 75.8309C77.2817 75.2777 76.3847 75.2777 75.8314 75.8309C75.2782 76.3842 75.2782 77.2812 75.8314 77.8344L138.165 140.168C138.718 140.721 139.615 140.721 140.168 140.168C140.721 139.615 140.721 138.718 140.168 138.164L134.917 132.913V81.0827C134.917 77.9531 132.379 75.416 129.25 75.416H86.7498C84.2248 75.416 82.0855 77.0675 81.3532 79.3493ZM89.5832 87.5792L113.667 111.663L118.331 106.998C118.885 106.444 119.782 106.444 120.335 106.998L126.417 113.079V85.3327C126.417 84.5503 125.782 83.916 125 83.916H90.9998C90.2174 83.916 89.5832 84.5503 89.5832 85.3327V87.5792ZM115.67 113.666L122.753 120.749H125C125.782 120.749 126.417 120.115 126.417 119.333V117.086L119.333 110.003L115.67 113.666ZM125.551 123.547L132.083 130.079V81.0827C132.083 79.5179 130.815 78.2493 129.25 78.2493H86.7498C85.185 78.2493 83.9165 79.5179 83.9165 81.0827V81.9125L86.7853 84.7813C87.0556 82.6946 88.8395 81.0827 90.9998 81.0827H125C127.347 81.0827 129.25 82.9855 129.25 85.3327V119.333C129.25 121.493 127.638 123.277 125.551 123.547Z"
        fill="#8FA1B3"
        fillRule="evenodd"
      />
      <path
        d="M116.5 132.083H99.4998C98.7174 132.083 98.0832 131.448 98.0832 130.666C98.0832 129.884 98.7174 129.249 99.4998 129.249H116.5C117.282 129.249 117.917 129.884 117.917 130.666C117.917 131.448 117.282 132.083 116.5 132.083Z"
        fill="#8FA1B3"
      />
    </svg>
  );
};

export default EmptyImageCourseAsSideOrder;
