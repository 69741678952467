import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import {
  getCustomMenuConfigurationDietTexts,
  getCustomStandardDietTexts,
  getMenuConfigurationDietTexts,
  getStandardDietTexts,
} from '../../../../redux/selectors/formSettingsSelector';
import SmallMealIcon from '../../../../assets/icons/smallMealIcon';
import Button from '../../../common/Button';
import SmallReplaceIcon from '../../../../assets/icons/smallReplaceIcon';
import {
  defaultMenuConfigurationDietTexts,
  getDefaultStandardDietTexts,
} from '../helpers/firstStepHelpers';
import { nanoid } from '@reduxjs/toolkit';
import { useGetApiFormSettingsQuery } from '../../../../services/api/form/open/open.api';
import { useAppSelector } from '../../../../config/hooks';
import { getServerConfig } from '../../../../features/general/generalSlice';
import { classNamesUtil } from '../../../../utils/classNamesUtil.util';

const TypeOfProgram = ({ diets, handleSelectProgram, selectedProgram }) => {
  const { companyId } = useAppSelector(getServerConfig);

  const { data: { generalInformation: { cateringName = '' } = {} } = {} } =
    useGetApiFormSettingsQuery(undefined, {
      skip: !companyId,
    });

  const hasCustomStandardDietTexts = useAppSelector(getCustomStandardDietTexts);
  const customStandardDietTexts = useAppSelector(getStandardDietTexts);
  const hasCustomMenuConfigurationDietTexts = useAppSelector(
    getCustomMenuConfigurationDietTexts
  );
  const customMenuConfigurationDietTexts = useAppSelector(
    getMenuConfigurationDietTexts
  );

  const standardDietsCount = diets.filter(
    diet => !diet?.menuConfiguration
  ).length;

  const standardDietTexts = hasCustomStandardDietTexts
    ? customStandardDietTexts
    : getDefaultStandardDietTexts(standardDietsCount, cateringName);
  const menuConfigurationDietTexts = hasCustomMenuConfigurationDietTexts
    ? customMenuConfigurationDietTexts
    : defaultMenuConfigurationDietTexts;

  return (
    <div
      className={`type-of-program${
        diets.length > 1 ? ' type-of-program--both-programs' : ''
      }`}
    >
      <div
        className={classNamesUtil(
          'type-of-program__card type-of-program__card--highlighted',
          {
            'type-of-program__card--selected':
              selectedProgram === 'menuConfiguration',
          }
        )}
      >
        {selectedProgram === 'menuConfiguration' && (
          <div className="type-of-program__check">
            <FontAwesomeIcon className="font-size-15" icon={faCheck} />
          </div>
        )}
        <div className="type-of-program__card-top">
          <SmallReplaceIcon
            className="small-replace-icon"
            height={66}
            width={87}
          />
          <h4>Program z Wyborem Menu</h4>
        </div>
        <div className="type-of-program__card-bottom">
          <ul className="spacer-bottom-8">
            {menuConfigurationDietTexts.map(text => (
              <li
                className="display-flex align-items-center spacer-bottom-16"
                key={nanoid()}
              >
                <FontAwesomeIcon
                  className="font-size-16 color-primary-500 spacer-right-16"
                  icon={faCheck}
                />
                <p className="body-m">{text}</p>
              </li>
            ))}
          </ul>
          <Button
            colorBlank
            containerClass={
              selectedProgram === 'menuConfiguration'
                ? 'type-of-program__button-active'
                : ''
            }
            icon={
              selectedProgram === 'menuConfiguration' && (
                <FontAwesomeIcon icon={faCheck} />
              )
            }
            label={
              selectedProgram === 'menuConfiguration'
                ? 'Wybrany'
                : 'Wybierz program'
            }
            onClick={() => handleSelectProgram('menuConfiguration')}
            sizeMedium
          />
        </div>
      </div>
      {diets.length > 1 && (
        <div
          className={classNamesUtil('type-of-program__card', {
            'type-of-program__card--selected': selectedProgram === 'standard',
          })}
        >
          {selectedProgram === 'standard' && (
            <div className="type-of-program__check">
              <FontAwesomeIcon className="font-size-15" icon={faCheck} />
            </div>
          )}

          <div className="type-of-program__card-top">
            <SmallMealIcon className="small-meal-icon" height={66} width={87} />
            <h4>Program Standardowy</h4>
          </div>
          <div className="type-of-program__card-bottom">
            <ul className="spacer-bottom-8">
              {standardDietTexts.map(text => (
                <li
                  className="display-flex align-items-center spacer-bottom-16"
                  key={nanoid()}
                >
                  <FontAwesomeIcon
                    className="font-size-16 color-primary-500 spacer-right-16"
                    icon={faCheck}
                  />
                  <p className="body-m">{text}</p>
                </li>
              ))}
            </ul>
            <Button
              colorBlank
              containerClass={
                selectedProgram === 'standard'
                  ? 'type-of-program__button-active'
                  : ''
              }
              icon={
                selectedProgram === 'standard' && (
                  <FontAwesomeIcon icon={faCheck} />
                )
              }
              label={
                selectedProgram === 'standard' ? 'Wybrany' : 'Wybierz program'
              }
              onClick={() => handleSelectProgram('standard')}
              sizeMedium
            />
          </div>
        </div>
      )}
    </div>
  );
};

TypeOfProgram.propTypes = {
  diets: PropTypes.array,
  handleSelectProgram: PropTypes.func.isRequired,
  selectedProgram: PropTypes.string.isRequired,
};

export default TypeOfProgram;
