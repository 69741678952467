import * as actionTypes from '../actions/actionTypes';

export const orderFormHistoryReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.STORE_HISTORY:
      return state.concat(action.step);
    default:
      return state;
  }
};
