import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navigation from '../components/common/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/pro-regular-svg-icons';
import { useEffectOnce } from 'react-use';
import { Link, useNavigate } from 'react-router-dom';
import {
  getLoggedInOrderPaymentStatus,
  getOpenOrderPaymentStatus,
} from '../services/api/form/order.api';
import StatusIcon from '../assets/icons/statusIcon';
import Button from '../components/common/Button';
import { getServerConfig } from '../features/general/generalSlice';
import { useEnhancedEcommercePageInfo } from '../hooks/useEnhancedEcommercePageInfo';
import { useAppSelector } from '../config/hooks';

const cancelTokenSource = axios.CancelToken.source();

const StepTransactionStatus = () => {
  const navigate = useNavigate();

  const [methodOfPurchase, setMethodOfPurchase] = useState('');
  const [orderId, setOrderId] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState('NOT_PAID');
  const [isFetching, setIsFetching] = useState(true);
  const { apiKey, companyId, serverUrl } = useAppSelector(getServerConfig);

  useEffectOnce(() => {
    // @ts-ignore
    const data = JSON?.parse(sessionStorage?.getItem('success-page-data-form'));
    setMethodOfPurchase(data.methodOfPurchase);
    if (!data) {
      navigate('/', { replace: true });
    }
    // @ts-ignore
    const order = JSON?.parse(window?.sessionStorage?.getItem('order-id-form'));
    order ? setOrderId(order) : setIsFetching(false);
  });

  useEffect(() => {
    if (
      orderId &&
      methodOfPurchase.length > 0 &&
      serverUrl &&
      companyId &&
      apiKey
    ) {
      const getOrderData = async () => {
        const apiAction =
          methodOfPurchase === 'guest'
            ? getOpenOrderPaymentStatus
            : getLoggedInOrderPaymentStatus;
        const { data } = await apiAction(
          orderId,
          cancelTokenSource,
          serverUrl,
          companyId,
          apiKey
        );
        if (data === 'PAID') {
          clearInterval(getPaymentStatusInterval);
          cancelTokenSource.cancel();
          setPaymentStatus('PAID');
        }
      };

      const getPaymentStatusInterval = setInterval(getOrderData, 5000);
      setTimeout(() => {
        clearInterval(getPaymentStatusInterval);
        cancelTokenSource.cancel();
        setIsFetching(false);
      }, 120000);
      return () => {
        clearInterval(getPaymentStatusInterval);
        cancelTokenSource.cancel();
      };
    }
  }, [apiKey, companyId, methodOfPurchase, orderId, serverUrl]);

  useEffect(() => {
    if (paymentStatus === 'PAID') {
      // @ts-ignore
      window.location = `${window.location.href.split('#')[0]}#/zaplacono`;
    }
  }, [orderId, paymentStatus]);

  const { canSend, sendEvent } = useEnhancedEcommercePageInfo();
  useEffect(() => {
    canSend && sendEvent();
  }, [canSend, sendEvent]);

  return (
    <>
      <Navigation />
      <div className="steps-page">
        <div className="steps-page__container">
          {isFetching ? (
            <section className="steps-page__loader">
              <FontAwesomeIcon
                className="font-size-60 color-primary"
                icon={faSync}
                spin
              />
              <span className="steps-page__loader-info">
                Trwa weryfikacja statusu płatności...
              </span>
            </section>
          ) : (
            <div className="box-step">
              <div className="display-flex flex-direction-column align-items-center">
                <StatusIcon classNames="status-icon-color" />
                <h3 className="h300 spacer-top-24 spacer-bottom-8">
                  Transakcja w trakcie weryfikacji
                </h3>
                <p className="body-l spacer-bottom-40 max-width-545 text-align-center">
                  Dziękujemy za złożenie zamówienia. Gdy tylko transakcja
                  zostanie zweryfikowana, przejdziemy do jego realizacji.
                  Potwierdzenie wyślemy na Twój adres email.
                </p>
                <div className="steps-page__transaction-page-button-wrapper">
                  <Link to="/">
                    <Button
                      colorBlank
                      label="Wróć na stronę główną"
                      sizeMedium
                    />
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StepTransactionStatus;
