import { RootState } from '../../config/hooks';

export const getSummaryReducer = (state: RootState) => state.summaryReducer;

export const getAddressDetails = (state: RootState) =>
  state.summaryReducer.newAddressDetails;

export const getSelectedCity = (state: RootState) =>
  state.summaryReducer.newAddressDetails?.city;

export const getZipCode = (state: RootState) =>
  state.summaryReducer.newAddressDetails?.zipCode;

export const getSelectedProfileAddress = (state: RootState) =>
  state.summaryReducer.selectedProfileAddress;

export const getNewClientDetails = (state: RootState) =>
  state.summaryReducer.newClientDetails;

export const getPickupPointDiscount = (state: RootState) =>
  getSummaryReducer(state).pickupPointDiscount;
