import { OrderResponseT } from '@features/orderForm/stepSummary/types/stepSummary.type';

export const generateTransactionId = (
  response: OrderResponseT,
  companyId: string
): string => {
  const { orders } = response;
  const ascOrdersArray = orders
    .map(order => Number(order.orderId))
    .sort((a, b) => a - b);
  const REFER = `${companyId}_${ascOrdersArray
    .toString()
    .replaceAll(',', ':')}`;

  return REFER;
};
