import { ProfilePreferencesTypeE } from '../types/profilePreferences.type';

export const marketingAgreementsArray = [
  {
    type: ProfilePreferencesTypeE.allowEmail,
    text: 'E-mail',
    name: 'allowEmail'
  },
  {
    type: ProfilePreferencesTypeE.allowSMS,
    text: 'SMS',
    name: 'allowSMS'
  },
  {
    type: ProfilePreferencesTypeE.allowPhone,
    text: 'Telefon (połączenia telefoniczne)',
    name: 'allowPhone'
  },
  {
    type: ProfilePreferencesTypeE.allowPlatform,
    text: 'Platforma (notyfikacje push)',
    name: 'allowPlatform'
  },
];

export const agreementsText1 =
  'Wyrażam zgodę na przetwarzanie przez Masterlife Solutions Sp. z o.o. z\n' +
  '          siedzibą w Warszawie, moich danych osobowych, w celu przekazywania mi\n' +
  '          informacji marketingowych oraz handlowych, które dotyczą\n' +
  '          usług/produktów marki Dietly, za pomocą:';

export const agreementsText2 =
  '            nie wyrażenie zgody spowoduje nie otrzymywanie informacji o\n' +
  '            wydarzeniach, konkursach i promocjach, za pomocą kanału kontaktu, na\n' +
  '            który nie wyraziłem zgody. Jednocześnie oświadczam, że\n' +
  '            zostałam/zostałem poinformowana/poinformowany, że przysługuje mi\n' +
  '            prawo do wycofania zgody na przetwarzanie danych osobowych w\n' +
  '            dowolnym momencie i mogę tego dokonać w wersji elektronicznej na\n' +
  '            adres iodo@dietly.pl Rozumiem, że wycofanie zgody nie wpływa na\n' +
  '            zgodność z prawem przetwarzania, którego dokonano na podstawie zgody\n' +
  '            przed jej wycofaniem.';
