import { SideOrderT } from 'typesRoot/sideOrder.type';
import { selectOneOffs } from '@features/orderForm/stepShoppingCart/redux/oneOffSideOrders.slice';
import { Dispatch } from 'redux';

export const deleteAdditionalFeeFromLocalStorage = (
  possibleSideOrderId: number
) => {
  const item = localStorage.getItem('additional-fee-form');
  const parsedItem = item && JSON.parse(item);

  if (parsedItem && parsedItem.length > 0) {
    const newAdditionalFee = parsedItem.filter(
      (product: SideOrderT) =>
        product.possibleSideOrderId !== possibleSideOrderId
    );
    return localStorage.setItem(
      'additional-fee-form',
      JSON.stringify(newAdditionalFee)
    );
  }
  return null;
};

export const addAdditionalFeeToLocalStorage = (product: SideOrderT) => {
  const additionalFeeLocalStorage = JSON.parse(
    localStorage.getItem('additional-fee-form') as string
  );

  if (!additionalFeeLocalStorage) {
    return localStorage.setItem(
      'additional-fee-form',
      JSON.stringify([product])
    );
  }

  additionalFeeLocalStorage.push(product);

  return localStorage.setItem(
    'additional-fee-form',
    JSON.stringify(additionalFeeLocalStorage)
  );
};

export const changeAdditionalFeeAmountInLocalStorage = (
  id: number,
  value: number
) => {
  const additionalFeeLocalStorage = JSON.parse(
    localStorage.getItem('additional-fee-form') as string
  );

  const additionalFeeAmount = additionalFeeLocalStorage.map(
    (item: SideOrderT) =>
      item.possibleSideOrderId === id ? { ...item, amount: value } : item
  );

  return localStorage.setItem(
    'additional-fee-form',
    JSON.stringify(additionalFeeAmount)
  );
};

export const checkIsAdditionalFeesInLocalStorage =
  () => (dispatch: Dispatch) => {
    const oneOffsInLS = localStorage.getItem('additional-fee-form');

    if (oneOffsInLS) {
      const listOfOneOffsInLS = JSON.parse(oneOffsInLS);
      if (!!listOfOneOffsInLS && !!listOfOneOffsInLS?.length) {
        dispatch(selectOneOffs(listOfOneOffsInLS));
      }
    }
  };
