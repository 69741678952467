import { Select } from '../../../../common/select';
import Button from '../../../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { findDietTagImg } from '../../../../../utils';
import { DietT } from '../../../../../@types/menu.type';
import { useGetDietTagInfoAllQuery } from '../../../../../services/api/form/open/dietTagInfo.api';

type Props = {
  companyDietDetails: DietT[];
  isDesktop: boolean;
  selectedDiet: DietT | undefined;
  setAndFetchDietData: (diet: DietT | undefined) => void;
  setIsModalOpen: (bool: boolean) => void;
  wasNewDietsFetched: boolean | undefined;
};

const MenuCarouselDietSelect = ({
  companyDietDetails,
  isDesktop,
  selectedDiet,
  setAndFetchDietData,
  setIsModalOpen,
  wasNewDietsFetched,
}: Props) => {
  const { data: dietTagsData } = useGetDietTagInfoAllQuery();

  const findSelectedDietIndex = (
    selectedDiet: DietT | undefined,
    diets: DietT[]
  ) => {
    if (!selectedDiet || !diets) {
      return 0;
    }
    return diets?.findIndex(diet => diet?.dietId === selectedDiet?.dietId);
  };

  const getNewIndex = (
    direction: string,
    currentIndex: number,
    length: number
  ) => {
    if (direction === 'up') {
      if (currentIndex + 1 === length) {
        return 0;
      }
      return currentIndex + 1;
    }
    if (currentIndex === 0) {
      return length - 1;
    }
    return currentIndex - 1;
  };

  const getNextDiet = (
    direction: string,
    currentIndex: number,
    length: number
  ) => {
    const nextDietIndex =
      getNewIndex(direction, currentIndex, length ?? 0) ?? 0;
    return companyDietDetails[nextDietIndex];
  };

  return (
    <>
      {isDesktop ? (
        <div className="catering-menu__carousel-diets-desktop noselect">
          {wasNewDietsFetched && companyDietDetails?.length > 0 && (
            <div className="width-500 display-flex">
              <div className="width-400 spacer-right-8">
                <Select
                  dietUrl
                  id="menuPicker"
                  items={companyDietDetails}
                  selectedItem={selectedDiet}
                  selectedItemKey="dietName"
                  selectItem={diet => {
                    setAndFetchDietData(diet);
                  }}
                />
              </div>
              <Button
                colorBlank
                containerClass="spacer-right-4"
                icon={
                  <FontAwesomeIcon
                    className="color-gray-400"
                    icon={faArrowDown}
                  />
                }
                onClick={() => {
                  setAndFetchDietData(
                    getNextDiet(
                      'down',
                      findSelectedDietIndex(selectedDiet, companyDietDetails),
                      companyDietDetails?.length
                    )
                  );
                }}
              />
              <Button
                colorBlank
                icon={
                  <FontAwesomeIcon
                    className="color-gray-400"
                    icon={faArrowUp}
                  />
                }
                onClick={() => {
                  setAndFetchDietData(
                    getNextDiet(
                      'up',
                      findSelectedDietIndex(selectedDiet, companyDietDetails),
                      companyDietDetails?.length
                    )
                  );
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <div
          className="catering-menu__carousel-diets-wrapper catering-menu__carousel-diets-wrapper--no-border spacer-bottom-16"
          onClick={() => setIsModalOpen(true)}
          onKeyDown={() => setIsModalOpen(true)}
          role="button"
          tabIndex={0}
        >
          <img
            alt={
              selectedDiet?.dietName ? selectedDiet?.dietName : 'Placeholder'
            }
            src={
              selectedDiet?.dietImageUrl
                ? selectedDiet?.dietImageUrl
                : findDietTagImg(selectedDiet?.dietTag, dietTagsData)
            }
          />
          <div className="display-flex justify-content-space-between align-items-center width-100-per">
            <p className="color-gray-800">{selectedDiet?.dietName}</p>
            <FontAwesomeIcon
              className="color-gray-500 spacer-right-16"
              icon={faChevronDown}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default MenuCarouselDietSelect;
