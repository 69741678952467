export const roundToTwoDecimal = (number: number) => {
  if (isNaN(number)) {
    return 0;
  }
  if (!number) {
    return 0;
  }
  if (number > 0) {
    return Math.round(number * 100) / 100;
  }
  return 0;
};
