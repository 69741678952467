import { Helmet } from 'react-helmet';
import { useAppSelector } from '../../../config/hooks';
import { getGA4Id } from '../../general/generalSlice';

const GA4 = () => {
  const ga4Id = useAppSelector(getGA4Id);

  if (!ga4Id) {
    return <></>;
  }

  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${ga4Id}`}
      />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${ga4Id}');
        `}
      </script>
    </Helmet>
  );
};

export default GA4;
