const CarouselMealSkeleton = () => {
  return (
    <div className="meal__skeleton">
      <div className="meal__skeleton-top">
        <div className="meal__skeleton-img"></div>
        <div className="meal__skeleton-paragraphs">
          <div className="meal__skeleton-line" />
          <div className="meal__skeleton-line" />
          <div className="meal__skeleton-line" />
        </div>
      </div>
      <div className="meal__skeleton-bottom">
        <div className="meal__skeleton-line--long" />
        <div className="meal__skeleton-line--long" />
      </div>
      <div className="meal--shine" />
    </div>
  );
};

export default CarouselMealSkeleton;
