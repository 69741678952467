import { useMemo } from 'react';
import { SelectedSideOrdersT } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.slice';

type Props = {
  selectedSideOrders: SelectedSideOrdersT;
};

const useSideOrdersSummary = ({ selectedSideOrders }: Props) => {
  const { numberOfSideOrders, priceOfSideOrders } = useMemo(() => {
    let numberOfSideOrders = 0;
    let priceOfSideOrders = 0;

    Object.values(selectedSideOrders).forEach(day => {
      Object.values(day).forEach(sideOrder => {
        numberOfSideOrders += sideOrder.multiplier;
        priceOfSideOrders += sideOrder.defaultPrice * sideOrder.multiplier;
      });
    });

    return { numberOfSideOrders, priceOfSideOrders };
  }, [selectedSideOrders]);
  return { numberOfSideOrders, priceOfSideOrders };
};

export default useSideOrdersSummary;
