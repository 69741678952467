import React from 'react';
import PropTypes from 'prop-types';

const BlikIcon = ({ height = '160', width = '200' }) => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 200 160"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M146.294 95.6071L166.746 91.2118C179.27 88.5203 182.875 72.3819 172.687 64.6168L165.359 59.0311C159.706 54.7222 157.853 47.037 160.922 40.6254L166.367 29.2473C173.129 15.1201 156.437 1.38599 143.877 10.7419L140.538 13.2289C133.532 18.4472 123.53 16.2641 119.336 8.60118C114.554 -0.135703 102.552 -1.48204 95.9534 5.97808L54.6666 52.6524C53.4472 54.031 51.9725 55.1606 50.3239 55.979L35.3612 63.4069C23.3491 69.3701 29.1585 87.5679 42.404 85.4678C52.2726 83.9031 59.4435 94.6189 54.2332 103.145L34.1364 136.031C25.829 149.625 41.6438 165.085 55.0457 156.471L82.8442 138.604C90.5111 133.677 100.765 136.669 104.578 144.946L105.751 147.493C112.476 162.09 134.339 157.322 134.375 141.251L134.446 110.238C134.462 103.181 139.395 97.0899 146.294 95.6071Z"
        fill="#1B42B2"
        opacity="0.1"
      />
      <path
        d="M134.2 22H65.8C63.7013 22 62 23.7909 62 26V134C62 136.209 63.7013 138 65.8 138H134.2C136.299 138 138 136.209 138 134V26C138 23.7909 136.299 22 134.2 22Z"
        fill="white"
      />
      <path
        clipRule="evenodd"
        d="M65.8 23C64.3013 23 63 24.2942 63 26V134C63 135.706 64.3013 137 65.8 137H134.2C135.699 137 137 135.706 137 134V26C137 24.2942 135.699 23 134.2 23H65.8ZM61 26C61 23.2875 63.1013 21 65.8 21H134.2C136.899 21 139 23.2875 139 26V134C139 136.712 136.899 139 134.2 139H65.8C63.1013 139 61 136.712 61 134V26Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M154 49.188V80.768C154 84.74 150.782 87.958 146.812 87.958H116.084L102 98V87.958H91.188C87.218 87.958 84 84.74 84 80.768V49.188C84 45.218 87.218 42 91.188 42H146.812C150.782 42 154 45.218 154 49.188Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M91.188 43C87.7703 43 85 45.7703 85 49.188V80.768C85 84.1879 87.7705 86.958 91.188 86.958H102C102.552 86.958 103 87.4057 103 87.958V96.0588L115.503 87.1438C115.673 87.0229 115.876 86.958 116.084 86.958H146.812C150.23 86.958 153 84.1879 153 80.768V49.188C153 45.7703 150.23 43 146.812 43H91.188ZM83 49.188C83 44.6657 86.6657 41 91.188 41H146.812C151.334 41 155 44.6657 155 49.188V80.768C155 85.2921 151.334 88.958 146.812 88.958H116.404L102.581 98.8142C102.276 99.0316 101.875 99.0604 101.542 98.889C101.209 98.7175 101 98.3744 101 98V88.958H91.188C86.6655 88.958 83 85.2921 83 80.768V49.188Z"
        fill="#FF5226"
        fillRule="evenodd"
      />
      <path
        d="M62 120H138V134C138 136.209 136.209 138 134 138H66C63.7909 138 62 136.209 62 134V120Z"
        fill="#F2F5FC"
      />
      <path
        clipRule="evenodd"
        d="M61 120C61 119.448 61.4477 119 62 119H138C138.552 119 139 119.448 139 120V134C139 136.761 136.761 139 134 139H66C63.2386 139 61 136.761 61 134V120ZM63 121V134C63 135.657 64.3431 137 66 137H134C135.657 137 137 135.657 137 134V121H63Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        d="M87 22H113C113 24.2091 111.209 26 109 26H91C88.7909 26 87 24.2091 87 22Z"
        fill="#F2F5FC"
      />
      <path
        clipRule="evenodd"
        d="M86 22C86 21.4477 86.4477 21 87 21H113V22H114C114 24.7614 111.761 27 109 27H91C88.2386 27 86 24.7614 86 22ZM88.1707 23C88.5825 24.1652 89.6938 25 91 25H109C110.306 25 111.417 24.1652 111.829 23H88.1707Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        d="M100 133C102.209 133 104 131.209 104 129C104 126.791 102.209 125 100 125C97.7909 125 96 126.791 96 129C96 131.209 97.7909 133 100 133Z"
        fill="white"
      />
      <path
        clipRule="evenodd"
        d="M100 126C98.3431 126 97 127.343 97 129C97 130.657 98.3431 132 100 132C101.657 132 103 130.657 103 129C103 127.343 101.657 126 100 126ZM95 129C95 126.239 97.2386 124 100 124C102.761 124 105 126.239 105 129C105 131.761 102.761 134 100 134C97.2386 134 95 131.761 95 129Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M61 120C61 119.448 61.4477 119 62 119H138C138.552 119 139 119.448 139 120C139 120.552 138.552 121 138 121H62C61.4477 121 61 120.552 61 120Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        d="M93 65C93 62.2386 95.2386 60 98 60C100.761 60 103 62.2386 103 65C103 67.7614 100.761 70 98 70C95.2386 70 93 67.7614 93 65Z"
        fill="#F2F5FC"
      />
      <path
        clipRule="evenodd"
        d="M92 65C92 61.6863 94.6863 59 98 59C101.314 59 104 61.6863 104 65C104 68.3137 101.314 71 98 71C94.6863 71 92 68.3137 92 65ZM98 61C95.7909 61 94 62.7909 94 65C94 67.2091 95.7909 69 98 69C100.209 69 102 67.2091 102 65C102 62.7909 100.209 61 98 61Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        d="M135 65C135 62.2386 137.239 60 140 60C142.761 60 145 62.2386 145 65C145 67.7614 142.761 70 140 70C137.239 70 135 67.7614 135 65Z"
        fill="#F2F5FC"
      />
      <path
        clipRule="evenodd"
        d="M134 65C134 61.6863 136.686 59 140 59C143.314 59 146 61.6863 146 65C146 68.3137 143.314 71 140 71C136.686 71 134 68.3137 134 65ZM140 61C137.791 61 136 62.7909 136 65C136 67.2091 137.791 69 140 69C142.209 69 144 67.2091 144 65C144 62.7909 142.209 61 140 61Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        d="M107 65C107 62.2386 109.239 60 112 60C114.761 60 117 62.2386 117 65C117 67.7614 114.761 70 112 70C109.239 70 107 67.7614 107 65Z"
        fill="#F2F5FC"
      />
      <path
        clipRule="evenodd"
        d="M106 65C106 61.6863 108.686 59 112 59C115.314 59 118 61.6863 118 65C118 68.3137 115.314 71 112 71C108.686 71 106 68.3137 106 65ZM112 61C109.791 61 108 62.7909 108 65C108 67.2091 109.791 69 112 69C114.209 69 116 67.2091 116 65C116 62.7909 114.209 61 112 61Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        d="M121 65C121 62.2386 123.239 60 126 60C128.761 60 131 62.2386 131 65C131 67.7614 128.761 70 126 70C123.239 70 121 67.7614 121 65Z"
        fill="#F2F5FC"
      />
      <path
        clipRule="evenodd"
        d="M120 65C120 61.6863 122.686 59 126 59C129.314 59 132 61.6863 132 65C132 68.3137 129.314 71 126 71C122.686 71 120 68.3137 120 65ZM126 61C123.791 61 122 62.7909 122 65C122 67.2091 123.791 69 126 69C128.209 69 130 67.2091 130 65C130 62.7909 128.209 61 126 61Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
    </svg>
  );
};

BlikIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default BlikIcon;
