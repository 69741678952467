//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { useCombobox } from 'downshift';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useDebounce } from 'react-use';
import { Helmet } from 'react-helmet';
import { faLongArrowLeft, faMapPin } from '@fortawesome/pro-regular-svg-icons';
import useIsWide from '../../../hooks/useIsWide';
import Button from '../Button';
import { ComboboxSize } from '../../../@types/city/city.type';
import { getAddressDetails } from '../../../redux/selectors/summaryReducer.selectors';
import { useAppSelector } from '../../../config/hooks';
import { useLazyGetApiZipCodesSearchQuery } from '../../../services/api/form/open/open.api';
import { sanitizeZipCodeValue } from './helpers/zipCodesHelpers';
import { ZipCodeT } from '../../../features/orderForm/stepSummary/types/stepSummary.type';

type Props = {
  initialSelectedItem?: ZipCodeT;
  isError?: boolean;
  onSelect?: (k: ZipCodeT) => void;
  size?: ComboboxSize;
  forceCityId?: number | null;
};

const ComboboxZipCodes = ({
  forceCityId,
  initialSelectedItem,
  isError = false,
  onSelect,
  size = ComboboxSize.M,
}: Props) => {
  const [inputItems, setInputItems] = useState<Array<ZipCodeT>>([]);
  const [isLoadingMain, setIsLoadingMain] = useState(false);

  const newAddressDetails = useAppSelector(getAddressDetails);

  const { city: { cityId = 0 } = {} } = newAddressDetails;

  const isWide = useIsWide();

  const {
    closeMenu,
    getInputProps,
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    inputValue,
    isOpen,
    selectItem,
    setInputValue,
    toggleMenu,
  } = useCombobox({
    id: 'combobox-zip-codes',
    initialSelectedItem,
    items: inputItems,
    itemToString: (item: ZipCodeT | null) => (item ? item.code : ''),
    //HANDLE searching
    onSelectedItemChange: ({ selectedItem: newItem }) => {
      if (onSelect && newItem) {
        onSelect(newItem);
      }
    },
    onInputValueChange: ({ inputValue: newValue }) => {
      if (newValue[0] === '-') {
        setInputValue('');
      } else if (newValue[2]) {
        const dashCount = newValue.split('-').length - 1;
        if (dashCount >= 2) {
          const [first, ...rest] = newValue.split('-');
          setInputValue(`${first}-${rest[0]}`);
        } else if (dashCount === 1) {
          setInputValue(newValue);
        } else {
          const alphanumericRegex = /^[a-z0-9]+$/i;
          const newValueWithoutSpecialChars = newValue.replace(
            alphanumericRegex,
            ''
          );
          const firstTwoChars = newValueWithoutSpecialChars.slice(0, 2);
          setInputValue(
            `${firstTwoChars}-${newValueWithoutSpecialChars.slice(2)}`
          );
        }
      }
      if (newValue?.length === 2 && inputValue?.length === 1) {
        setInputValue(`${newValue}-`);
      }
      if (newValue?.length === 2 && inputValue?.length === 3) {
        setInputValue(newValue.substring(0, 1));
      }
    },
    onIsOpenChange: ({ isOpen, selectedItem: newItem }) => {
      if (newItem) {
        isOpen ? setInputValue('') : setInputValue(newItem?.code);
      }
    },
  });

  const inputProps = {
    autoComplete: 'off',
    maxLength: 6,
    onBlur: () => {
      const [firstItem] = inputItems;
      setIsLoadingMain(false);
      if (firstItem && inputItems?.length === 1) {
        selectItem(firstItem);
      }
    },
    onFocus: () => {
      if (!isOpen) {
        setInputValue('');
      }
    },
    onKeyPress: (event: React.KeyboardEvent) => {
      const [firstZipCode] = inputItems;
      if (event.charCode === 13 && firstZipCode) {
        selectItem(firstZipCode);
        closeMenu();
      }
    },
    type: 'text',
    value: inputValue || '',
  };

  const [getApiZipCodesSearch] = useLazyGetApiZipCodesSearchQuery();

  //HANDLE DEBOUNCE
  const [,] = useDebounce(
    async () => {
      await setIsLoadingMain(true);
      try {
        if (!!inputValue) {
          const value = sanitizeZipCodeValue(inputValue);
          const pattern = /^[0-9-]*$/;

          if (pattern.test(value)) {
            const { data } = await getApiZipCodesSearch(
              {
                cityId: forceCityId || cityId,
                query: value,
              },
              true
            );

            if (data) {
              setInputItems(data.zipCodes);
            }
          }
        }
      } catch (e) {
        console.log(`Failed to fetch zip codes by query. Error message: ${e}`);
      } finally {
        setIsLoadingMain(false);
      }
    },
    400,
    [inputValue]
  );

  //ANIMATIONS
  const list = {
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  };
  const variants = {
    visible: (i: number) => ({
      x: 0,
      opacity: 1,
      transition: {
        delay: i * 0.04,
      },
    }),
    hidden: { x: 45, opacity: 0 },
  };

  useEffect(() => {
    if (initialSelectedItem?.zipCodeId) {
      selectItem(initialSelectedItem);
    }
  }, [initialSelectedItem, selectItem]);

  const handleBackButton = () => {
    toggleMenu();
  };

  return (
    <>
      {isOpen && isWide && (
        <Helmet>
          {/* eslint-disable-next-line react/style-prop-object */}
          <body style="overflow: hidden;" />
        </Helmet>
      )}
      <div className="dds-combobox__container">
        <FontAwesomeIcon className="dds-combobox__location" icon={faMapPin} />
        <div
          className={`dds-combobox__input-container${
            isError ? ` dds-combobox__error` : ''
          }`}
          {...getToggleButtonProps()}
        >
          {isOpen && isWide && (
            <div
              className={`dds-combobox__mobile-bar dds-combobox__mobile-size-${size}`}
              onClick={e => e.stopPropagation()}
              onKeyDown={e => e.stopPropagation()}
              role="button"
              tabIndex={0}
            >
              <Button
                colorClean
                containerClass={`dds-combobox__mobile-back dds-combobox__mobile-size-${size}`}
                icon={<FontAwesomeIcon icon={faLongArrowLeft} />}
                onClick={() => handleBackButton()}
              />
              <input
                className={`dds-combobox__input-mobile dds-combobox__mobile-size-${size}`}
                {...getInputProps(inputProps)}
              />
            </div>
          )}
          <input
            className={`dds-combobox__input dds-combobox__input-size-${size}`}
            {...getInputProps(inputProps)}
          />
        </div>
        <FontAwesomeIcon
          className={`dds-combobox__chevron${
            isOpen ? ` dds-combobox__chevron--rotate` : ''
          }`}
          icon={faChevronDown}
        />
        <ul
          className={`${
            isOpen
              ? `dds-combobox__menu dds-combobox__menu-size-${size}`
              : `dds-combobox__menu--closed`
          }${
            isOpen && isWide ? ` dds-combobox__menu-mobile-size-${size}` : ''
          }`}
          {...getMenuProps()}
        >
          {isOpen && isLoadingMain ? (
            <motion.div
              animate="visible"
              className="dds-combobox__loader"
              initial="hidden"
              variants={list}
            >
              Ładowanie...
            </motion.div>
          ) : (
            <>
              {isOpen && !inputValue?.length && (
                <motion.li
                  animate="visible"
                  className="dds-combobox__label"
                  initial="hidden"
                  variants={list}
                >
                  Wprowadź kod
                </motion.li>
              )}
              {isOpen &&
                inputItems.map((item: ZipCodeT, index: number) => (
                  <motion.li
                    animate="visible"
                    className="dds-combobox__item"
                    initial="hidden"
                    key={`${item.zipCodeId}${index}`}
                    variants={variants}
                    {...getItemProps({ item, index })}
                  >
                    <div className="dds-combobox__item-code">
                      <FontAwesomeIcon
                        className="spacer-top-6 spacer-bottom-6"
                        icon={faMapPin}
                      />
                      <p>{item.code}</p>
                    </div>
                  </motion.li>
                ))}
              {isOpen &&
                !isLoadingMain &&
                !!inputValue?.length &&
                !inputItems?.length && (
                  <li className="dds-combobox__item dds-combobox__item--empty">
                    Brak kodu pocztowego.
                  </li>
                )}
            </>
          )}
        </ul>
      </div>
    </>
  );
};

export default ComboboxZipCodes;
