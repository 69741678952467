import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronCircleDown,
  faChevronCircleUp,
} from '@fortawesome/pro-light-svg-icons';
import {
  faCheckCircle,
  faExclamationCircle,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import UploadFileIcon from '../../../../assets/icons/uploadFileIcon';
import {
  changeHasPdfFile,
  changePdfFile,
  resetPdfFile,
  resetPromoCode,
} from '../../../../redux/actions/basketActions';
import { useDispatch } from 'react-redux';
import { getPdfFile } from '../../../../redux/selectors/basketSelector';
import { useAppSelector } from '../../../../config/hooks';

const PdfComponent = () => {
  const [isPdfOpen, togglePdf] = useState(false);
  const [inputFile, setInputFile] = useState<File | null>(null);
  const [errorSize, setErrorSize] = useState(false);
  const dispatch = useDispatch();
  const inputRef: MutableRefObject<HTMLInputElement | null | undefined> =
    useRef();
  const { hasFile } = useAppSelector(getPdfFile);

  useEffect(() => {
    if (inputFile?.type === 'application/pdf') {
      dispatch(changeHasPdfFile(true));
      dispatch(resetPromoCode());
      dispatch(changePdfFile(URL.createObjectURL(inputFile)));
    }
  }, [dispatch, inputFile]);

  const maxPdfSize = 5000000;

  return (
    <div>
      <div
        className="display-flex justify-content-space-between align-items-center body-m cursor-pointer"
        onClick={() => {
          togglePdf(!isPdfOpen);
        }}
        onKeyDown={() => {
          togglePdf(!isPdfOpen);
        }}
        role="button"
        tabIndex={0}
      >
        <div className="color-gray-800">
          Dodaj załącznik PDF <br />
          <span className="color-gray-400">(opcjonalnie)</span>
        </div>
        <div className="box-step__button">
          {isPdfOpen ? (
            <FontAwesomeIcon
              className="font-size-20 color-gray-400"
              icon={faChevronCircleUp}
            />
          ) : (
            <FontAwesomeIcon
              className="font-size-20 color-gray-400"
              icon={faChevronCircleDown}
            />
          )}
        </div>
      </div>
      <div>
        {(() => {
          if (isPdfOpen) {
            return (
              <div className="spacer-top-16">
                <label
                  className="font-size-15 color-primary font-weight-500 box-step__file-input-label"
                  htmlFor="pdf"
                >
                  <UploadFileIcon className="upload-file-icon" /> Wgraj dokument
                  PDF
                </label>
                <input
                  accept="application/pdf"
                  className="box-step__file-input"
                  id="pdf"
                  onChange={event => {
                    setErrorSize(false);
                    if (
                      event?.target?.files?.[0] &&
                      event.target.files[0].size > maxPdfSize
                    ) {
                      setErrorSize(true);
                      return;
                    }
                    if (event?.target?.files?.[0]) {
                      setInputFile(event.target.files[0]);
                    } else {
                      setInputFile(null);
                      dispatch(resetPdfFile());
                    }
                  }}
                  // TODO
                  // @ts-ignore
                  ref={inputRef}
                  type="file"
                />
                {hasFile && (
                  <>
                    <div className="display-flex color-success label-s spacer-top-16">
                      <FontAwesomeIcon
                        className="spacer-right-10 font-size-20"
                        icon={faCheckCircle}
                      />
                      Plik PDF został wgrany z powodzeniem. Zamówienie zostanie
                      potwierdzone po weryfikacji dokumentu przez catering.
                    </div>
                    <div
                      className="display-flex align-items-center color-error label-s spacer-top-16 cursor-pointer"
                      onClick={() => {
                        dispatch(resetPdfFile());

                        setInputFile(null);

                        if (inputRef?.current?.value) {
                          inputRef.current.value = '';
                        }
                      }}
                      onKeyDown={() => {
                        dispatch(resetPdfFile());

                        setInputFile(null);

                        if (inputRef?.current?.value) {
                          inputRef.current.value = '';
                        }
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      <FontAwesomeIcon
                        className="spacer-right-10 font-size-20"
                        icon={faTimes}
                      />
                      Anuluj dodany załącznik PDF
                    </div>
                  </>
                )}
                {(() => {
                  if (
                    inputFile?.type &&
                    inputRef.current &&
                    inputFile?.type !== 'application/pdf'
                  ) {
                    inputRef.current.value = '';
                    return (
                      <div className="display-flex align-items-center color-error label-s spacer-top-16">
                        <FontAwesomeIcon
                          className="spacer-right-10 font-size-20"
                          icon={faExclamationCircle}
                        />
                        Zły format pliku. Dokument powinien być w formacie PDF.
                      </div>
                    );
                  }
                  if (errorSize) {
                    return (
                      <div className="display-flex align-items-center color-error label-s spacer-top-16">
                        <FontAwesomeIcon
                          className="spacer-right-10 font-size-20"
                          icon={faExclamationCircle}
                        />
                        Plik jest zbyt duży. Maksymalna wielkość to{' '}
                        {maxPdfSize / 1000000}
                        MB.
                      </div>
                    );
                  }
                  return null;
                })()}
                {inputFile?.type !== 'application/pdf' && (
                  <footer className="box-step__footer body-m">
                    <strong className="font-weight-500">Ważne:</strong>
                    <br />
                    Wybierz ważny dokument, uprawniający do zniżki w danym
                    cateringu.
                  </footer>
                )}
              </div>
            );
          }
          return null;
        })()}
      </div>
    </div>
  );
};

export default PdfComponent;
