import { parseTypeAndIdFromKey } from '@features/coursesAsSideOrders/utils/coursesAsSideOrders.util';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  SideOrderOrCourseT,
  SideOrderOrCourseTypeE,
} from '@features/coursesAsSideOrders/types/coursesAsSideOrders.type';
import { Prettify } from 'typesRoot/generics.type';
import { checkIfTwoArraysOfStringsAreTheSame } from 'utilsRoot/array.util';

export type SelectedSideOrderT = Prettify<
  Pick<
    SideOrderOrCourseT,
    | 'defaultPrice'
    | 'limitedByMaximumSelectedMeals'
    | 'maxQuantity'
    | 'minQuantity'
    | 'dateFrom'
    | 'dateTo'
  > & {
    multiplier: number;
    sideOrderId: {
      id: number;
      type: SideOrderOrCourseTypeE;
    };
  }
>;

export type SideOrderIdT = `${SideOrderOrCourseTypeE}-${number}`;

export type SelectedSideOrdersT = Record<
  string,
  Record<SideOrderIdT, SelectedSideOrderT>
>;

type initialStateT = {
  clearLimitedByMaximumSelectedMeals: boolean;
  error: string;
  selectedSideOrders: SelectedSideOrdersT;
};

const initialState: initialStateT = {
  clearLimitedByMaximumSelectedMeals: false,
  error: '',
  selectedSideOrders: {},
};

// Użytkownik może najpierw dodać dodatki, potem wrócić na kalendarz, odznaczyć kilka dni i przejść do dodatków. W takim przypadku dodatki, które zostały dodane na usunięte dni powinny też zostać usunięte
export const checkShouldEraseSideOrders = createAsyncThunk(
  'stepSelectSideOrdersSlice/checkShouldEraseSideOrders',
  async (
    { selectedDaysISO }: { selectedDaysISO: Array<string> },
    // TODO: any
    { dispatch, getState }: any
  ) => {
    const selectedSideOrders = getState().order.stepSelectSideOrders
      .selectedSideOrders as SelectedSideOrdersT;

    const sideOrdersDates = Object.keys(selectedSideOrders);

    const areTwoArraysOfStringsTheSame = checkIfTwoArraysOfStringsAreTheSame(
      selectedDaysISO,
      sideOrdersDates
    );

    if (!areTwoArraysOfStringsTheSame) {
      const updatedSideOrders = Object.entries(
        selectedSideOrders
      ).reduce<SelectedSideOrdersT>((acc, curr) => {
        if (selectedDaysISO.includes(curr[0])) {
          acc[curr[0]] = curr[1];
        }
        return acc;
      }, {});
      dispatch(updateSideOrders(updatedSideOrders));
    }
  }
);

const stepSelectSideOrdersSlice = createSlice({
  name: 'stepSelectSideOrdersSlice',
  initialState,
  reducers: {
    increaseSideOrder: (state, action) => {
      const {
        activeDay,
        defaultPrice,
        limitedByMaximumSelectedMeals,
        maxQuantity,
        minQuantity,
        sideOrderKey,
      } = action.payload;

      const currentSideOrder =
        state.selectedSideOrders[activeDay]?.[sideOrderKey];

      const [type, id] = parseTypeAndIdFromKey(sideOrderKey);

      const multiplier =
        currentSideOrder?.multiplier && currentSideOrder.multiplier > 0
          ? currentSideOrder.multiplier + 1
          : minQuantity || 1;

      state.selectedSideOrders[activeDay] = {
        ...state.selectedSideOrders[activeDay],
        [sideOrderKey]: {
          defaultPrice,
          limitedByMaximumSelectedMeals,
          maxQuantity,
          minQuantity,
          multiplier,
          sideOrderId: { id, type },
        },
      };
    },
    decreaseSideOrder: (state, action) => {
      const { activeDay, minQuantity, sideOrderKey } = action.payload;

      const currentSideOrder =
        state.selectedSideOrders[activeDay]?.[sideOrderKey];

      if (currentSideOrder && currentSideOrder.multiplier > 0) {
        if (minQuantity && currentSideOrder.multiplier - 1 < minQuantity) {
          delete state.selectedSideOrders[activeDay]?.[sideOrderKey];
        } else {
          state.selectedSideOrders[activeDay] = {
            ...state.selectedSideOrders[activeDay],
            [sideOrderKey]: {
              ...currentSideOrder,
              multiplier: currentSideOrder.multiplier - 1,
            },
          };
        }
      } else {
        delete state.selectedSideOrders[activeDay]?.[sideOrderKey];
      }
    },
    removeSideOrder: (state, action) => {
      const { activeDay, sideOrderKey } = action.payload;

      delete state.selectedSideOrders[activeDay]?.[sideOrderKey];
    },
    updateSideOrders: (state, action) => {
      state.selectedSideOrders = action.payload;
    },
    clearSideOrderError(state) {
      state.error = '';
    },
    triggerSideOrderError(state, action) {
      state.error = action.payload;
    },
    changeLimitedByMaximumSelectedMeals: (state, action) => {
      state.clearLimitedByMaximumSelectedMeals = action.payload;
    },
    resetSideOrderState: () => initialState,
    resetErrorAndSelectedSideOrders: state => {
      state.error = '';
      state.selectedSideOrders = {};
    },
  },
});

export const {
  changeLimitedByMaximumSelectedMeals,
  clearSideOrderError,
  decreaseSideOrder,
  increaseSideOrder,
  removeSideOrder,
  resetErrorAndSelectedSideOrders,
  resetSideOrderState,
  triggerSideOrderError,
  updateSideOrders,
} = stepSelectSideOrdersSlice.actions;

export default stepSelectSideOrdersSlice.reducer;
