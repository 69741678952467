import { combineReducers } from '@reduxjs/toolkit';
import stepSelectSideOrdersSlice from '../../../stepSelectSideOrders/services/redux/stepSelectSideOrders.slice';
import oneOffSideOrdersSlice from '@features/orderForm/stepShoppingCart/redux/oneOffSideOrders.slice';
import stepMenuConfigurationSlice from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.slice';

const combineOrderFormSlice = combineReducers({
  // stepSelectDiet: stepSelectDietSlice,
  stepMenuConfiguration: stepMenuConfigurationSlice,
  stepSelectSideOrders: stepSelectSideOrdersSlice,
  oneOffSideOrders: oneOffSideOrdersSlice,
});

export default combineOrderFormSlice;
