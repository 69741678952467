export const checkIsSalesManagoEnabled = salesManago => {
  const { apiKey, clientId, owner, sha } = salesManago || {};

  return !!apiKey && !!clientId && !!owner && !!sha;
};

export const getTimeStamp = () => +new Date();

export const mapMealsName = meals =>
  meals
    ?.filter(meal => meal?.selected)
    ?.map(meal => meal?.mealName)
    ?.join(',');

export const capitalizeFirstLetter = string => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return null;
};

export const weekendParsed = (saturdays, sundays) =>
  `sobota_${saturdays ? 'tak' : 'nie'}, niedziela_${sundays ? 'tak' : 'nie'}`;
