import React from 'react';
import Checkbox from '../../../common/checkbox';
import { changeClientInput } from '../../../../redux/actions/summaryActions';
import { Field } from 'formik';
import { Input } from '../../../common/Input';
import { useAppDispatch } from '../../../../config/hooks';

type Props = {
  apartmentNumber: string;
  bill?: string;
  buildingNumber: string;
  city: {
    name: string;
  };
  company: boolean;
  companyApartmentNumber: string;
  companyCity: string;
  companyBuildingNumber: string;
  companyName: string;
  companyStreet: string;
  companyZipCode: string;
  copyCompanyData: boolean;
  errors: any;
  handleChange: (e: { target: { value: string } }) => void;
  setCopyCompanyData: (checked: boolean) => void;
  street: string;
  taxId: string;
  touched: any;
  zipCode: {
    code: string;
  };
};

const Invoice = ({
  apartmentNumber,
  bill,
  buildingNumber,
  city,
  company,
  companyApartmentNumber,
  companyBuildingNumber,
  companyCity,
  companyName,
  companyStreet,
  companyZipCode,
  copyCompanyData,
  errors,
  handleChange,
  setCopyCompanyData,
  street,
  taxId,
  touched,
  zipCode,
}: Props) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <Checkbox
        checked={company}
        containerClass="spacer-top-40"
        id="company"
        label={<span className="font-weight-500">Chcę fakturę</span>}
        name="company"
        onChange={e => {
          handleChange(e);
          const {
            target: { checked },
          } = e;
          dispatch(changeClientInput('company', checked));
        }}
      />

      {company && (
        <>
          <div className="steps-page-third__wrapper spacer-top-24">
            <Field
              component={Input}
              containerClass="spacer-bottom-24"
              data-lpignore="true"
              errorDown
              errorMsg={touched['companyName'] && errors['companyName']}
              id="companyName"
              label="Nazwa firmy"
              name="companyName"
              onChange={(e: { target: { value: string } }) => {
                handleChange(e);
                const {
                  target: { value },
                } = e;
                dispatch(changeClientInput('companyName', value));
              }}
              required
              value={companyName}
            />

            <Field
              component={Input}
              containerClass="spacer-bottom-24"
              data-lpignore="true"
              errorDown
              errorMsg={touched['taxId'] && errors['taxId']}
              id="taxId"
              label="Nip"
              maskCharacter=""
              masked
              maskPattern="9999999999"
              name="taxId"
              onChange={(e: { target: { value: string } }) => {
                handleChange(e);
                const {
                  target: { value },
                } = e;

                dispatch(changeClientInput('taxId', value));
              }}
              required
              value={taxId}
            />
          </div>

          <Checkbox
            id="sameData"
            label={
              <span className="font-weight-500">
                Takie same jak adres dostawy
              </span>
            }
            name="sameData"
            onChange={e => {
              const {
                target: { checked },
              } = e;

              setCopyCompanyData(checked);

              if (checked) {
                dispatch(changeClientInput('companyCity', city.name));
                dispatch(changeClientInput('companyZipCode', zipCode.code));
                dispatch(changeClientInput('companyStreet', street));
                dispatch(
                  changeClientInput('companyApartmentNumber', apartmentNumber)
                );
                dispatch(
                  changeClientInput('companyBuildingNumber', buildingNumber)
                );
              }
            }}
          />

          <div className="steps-page-third__wrapper spacer-top-24">
            <Field
              component={Input}
              containerClass="spacer-bottom-24"
              data-lpignore="true"
              disabled={copyCompanyData}
              errorDown
              errorMsg={touched['companyCity'] && errors.companyCity}
              id="companyCity"
              label="Miasto"
              name="companyCity"
              onChange={(e: { target: { value: string } }) => {
                handleChange(e);
                const {
                  target: { value },
                } = e;
                dispatch(changeClientInput('companyCity', value));
              }}
              required
              value={companyCity}
            />

            <Field
              component={Input}
              containerClass="steps-page-third__small-input spacer-bottom-24"
              data-lpignore="true"
              disabled={copyCompanyData}
              errorDown
              errorMsg={touched['companyZipCode'] && errors.companyZipCode}
              id="companyZipCode"
              label="Kod pocztowy"
              name="companyZipCode"
              onChange={(e: { target: { value: string } }) => {
                handleChange(e);
                const {
                  target: { value },
                } = e;
                dispatch(changeClientInput('companyZipCode', value));
              }}
              required
              value={companyZipCode}
            />

            <Field
              component={Input}
              containerClass="spacer-bottom-24"
              data-lpignore="true"
              disabled={copyCompanyData}
              errorDown
              errorMsg={touched['companyStreet'] && errors.companyStreet}
              id="companyStreet"
              label="Ulica"
              name="companyStreet"
              onChange={(e: { target: { value: string } }) => {
                handleChange(e);
                const {
                  target: { value },
                } = e;

                dispatch(changeClientInput('companyStreet', value));
              }}
              required
              value={companyStreet}
            />

            <div className="display-flex">
              <Field
                component={Input}
                containerClass="steps-page-third__small-input spacer-bottom-24 spacer-right-24"
                data-lpignore="true"
                disabled={copyCompanyData}
                errorDown
                errorMsg={
                  touched['companyBuildingNumber'] &&
                  errors.companyBuildingNumber
                }
                id="companyBuildingNumber"
                label="Nr budynku"
                name="companyBuildingNumber"
                onChange={(e: { target: { value: string } }) => {
                  handleChange(e);
                  const {
                    target: { value },
                  } = e;
                  dispatch(changeClientInput('companyBuildingNumber', value));
                }}
                required
                value={companyBuildingNumber}
              />

              <Field
                component={Input}
                containerClass="steps-page-third__small-input spacer-bottom-24"
                data-lpignore="true"
                disabled={copyCompanyData}
                errorDown
                errorMsg={
                  touched['companyApartmentNumber'] &&
                  errors.companyApartmentNumber
                }
                id="companyApartmentNumber"
                label="Nr mieszkania"
                name="companyApartmentNumber"
                onChange={(e: { target: { value: string } }) => {
                  handleChange(e);
                  const {
                    target: { value },
                  } = e;
                  dispatch(changeClientInput('companyApartmentNumber', value));
                }}
                value={companyApartmentNumber}
              />
            </div>

            {bill && <p className="body-m spacer-bottom-24">{bill}</p>}
          </div>
        </>
      )}
    </>
  );
};

export default Invoice;
