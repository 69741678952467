import { useAppDispatch, useAppSelector } from '@config/hooks';
import { useEffect, useState } from 'react';
import useCalculateCartPrice from '@services/hook/calculatePrices/useCalculateCartPrice.hook';
import {
  getSalesManagoSlice,
  updateSalesManagoEvent,
} from '@salesManago/services/redux/salesManago.slice';
import { useSalesManagoUpdatePromoCode } from '@salesManago/services/hooks/useSalesManagoUpdatePromoCode';

export const useSalesManagoBeacon = () => {
  const dispatch = useAppDispatch();
  const salesManagoEvents = useAppSelector(getSalesManagoSlice);

  const [canSendEvent, setCanSendEvent] = useState(false);

  const { isFetching } = useCalculateCartPrice();

  const { trigger: triggerUpdatePromoCode } = useSalesManagoUpdatePromoCode();

  const { updatePromoCode = false } = salesManagoEvents || {};

  useEffect(() => {
    if (isFetching) {
      setCanSendEvent(true);
    }
  }, [isFetching]);

  // Update promo code effect
  useEffect(() => {
    if (updatePromoCode && canSendEvent) {
      console.log('updatePromoCode');
      dispatch(triggerUpdatePromoCode());
      dispatch(
        updateSalesManagoEvent({ event: 'updatePromoCode', value: false })
      );
      setCanSendEvent(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePromoCode, isFetching]);
};
