import axios from 'axios';

export type DietlyOpenCityT = {
  cityId: number;
  county: string;
  deliveryFee: number | null;
  deliveryTime: Array<DeliveryTimeT>;
  municipality: string;
  name: string;
  province: string;
  sanitizedName: string;
  sectorId: number;
};

type DeliveryTimeCityT = {
  cityId: number;
  name: string;
  deliveryFee: number;
  municipality: {
    municipalityId: number;
    name: string;
    county: {
      countyId: number;
      name: string;
      province: {
        provinceId: number;
        name: string;
        counties: Array<string>;
      };
      municipalities: Array<string>;
    };
    cities: Array<string>;
  };
  sector: string;
  awarded: boolean;
  goodspeedRegionId: number;
};

type SectorPricingT = {
  sectorPriceSettingId: number;
  minimumPrice: number;
  testPrice: number;
  fullPrice: number;
  dietCaloriesId: number;
  sector: string;
};

export type OpenCitiesT = {
  cityId: number;
  name: string;
  sanitizedName: string;
  municipality: string;
  county: string;
  province: string;
  sectorId: number;
  deliveryFee: number;
  deliveryTime: Array<DeliveryTimeT>;
};

export type DeliveryTimeT = {
  deliveryTimeId: number;
  timeFrom: {
    hour: number;
    minute: number;
    second: number;
    nano: number;
  };
  timeTo: {
    hour: number;
    minute: number;
    second: number;
    nano: number;
  };
  sector: {
    sectorId: number;
    name: string;
    deleted: boolean;
    description: string;
    sectorPriceSettings: SectorPricingT[];
    deliveryTimes: Array<string>;
    cities: DeliveryTimeCityT[];
  };
};

export const getApiFormOpenCitiesSearch = async (
  serverUrl: string,
  apiKey: string,
  companyName: string,
  numberOfResults: number,
  query: string
): Promise<OpenCitiesT[]> => {
  const response = await axios({
    url: `${serverUrl}/api/form/open/cities/search?query=${query}&numberOfResults=${numberOfResults}`,
    headers: {
      'api-key': apiKey,
      'company-id': companyName,
    },
  });

  return response.data;
};

export const getApiFormOpenCitiesTop = async (
  serverUrl: string,
  apiKey: string,
  companyName: string,
  numberOfResults: number
): Promise<OpenCitiesT[]> => {
  const response = await axios({
    url: `${serverUrl}/api/form/open/cities/top-${numberOfResults}`,
    headers: {
      'api-key': apiKey,
      'company-id': companyName,
    },
  });
  return response.data;
};
