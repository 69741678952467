import {
  sendUserComEvent,
  USER_COM_EVENT, USER_COM_TYPE,
} from '../api';

export const userComRemoveProductEvent = (item) => {
  const {
    selectedDiet: { dietId, dietName },
  } = item;

  const data = {
    payload: {
      product_id: dietId,
      product_name: dietName,
      URL: window.location.origin,
      event_type: USER_COM_EVENT.REMOVE,
    }
  };

  sendUserComEvent({ type: USER_COM_TYPE.PRODUCT_EVENT, data });
};
