import { ForwardedRef, forwardRef } from 'react';
import { useWindowSize } from 'react-use';
import MenuCarousel from './MenuCarousel';
import MenuCarouselDietSelect from './MenuCarouselDietSelect';
import { DietT, MenuT } from '../../../../../@types/menu.type';
import SwiperClass from 'swiper/types/swiper-class';

type Props = {
  centeredSlides: boolean | undefined;
  companyDietDetails: DietT[];
  customTitle?: string;
  dates: string[];
  hideSelect?: boolean;
  highlightedDate: string;
  isLoading: boolean;
  isMenuVisibleInDietly: boolean;
  menus: MenuT;
  selectedDiet: DietT | undefined;
  setAndFetchDietData: (diet: DietT | undefined) => void;
  setHighLightedDate: (date: string) => void;
  setIsModalOpen: (bool: boolean) => void;
  setSwiperMenu: (swiper: SwiperClass) => void;
  setIsLoading: (bool: boolean) => void;
  swiperMenu: {
    slideNext: () => void;
    slidePrev: () => void;
  } | null;
  wasNewDietsFetched?: boolean;
};

const CateringMenu = forwardRef(
  (
    {
      centeredSlides,
      companyDietDetails,
      customTitle,
      dates,
      hideSelect,
      highlightedDate,
      isLoading,
      isMenuVisibleInDietly,
      menus,
      selectedDiet,
      setAndFetchDietData,
      setHighLightedDate,
      setIsModalOpen,
      setSwiperMenu,
      swiperMenu,
      wasNewDietsFetched,
    }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { width } = useWindowSize();
    const isWide = width > 768;

    const isReviewPage = () => {
      if (typeof window !== 'undefined') {
        return window.location.href.includes('recenzja');
      }

      return false;
    };

    return (
      <section className="catering-menu" ref={ref}>
        <div className="display-flex justify-content-space-between align-items-center spacer-bottom-16">
          <h2 className="h300 color-gray-700 catering-menu__title">
            {customTitle || (isReviewPage() ? 'Aktualne menu' : 'Menu')}
          </h2>
        </div>
        {!hideSelect && (
          <MenuCarouselDietSelect
            companyDietDetails={companyDietDetails}
            isDesktop={isWide}
            selectedDiet={selectedDiet}
            setAndFetchDietData={setAndFetchDietData}
            setIsModalOpen={setIsModalOpen}
            wasNewDietsFetched={wasNewDietsFetched}
          />
        )}
        <MenuCarousel
          centeredSlides={centeredSlides}
          dates={dates}
          highlightedDate={highlightedDate}
          isLoading={isLoading}
          isMenuVisibleInDietly={isMenuVisibleInDietly}
          isWide={isWide}
          menus={menus}
          setHighLightedDate={setHighLightedDate}
          setSwiperMenu={setSwiperMenu}
          swiperMenu={swiperMenu}
        />
      </section>
    );
  }
);

export default CateringMenu;
