import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { findDietTagImg, parseOrderPeriod, parsePrice } from 'utilsRoot/index';
import Button from '@components/common/Button';
import { faCalendarAlt, faPencil } from '@fortawesome/pro-light-svg-icons';
import DaysAmount from '@components/common/DaysAmount';
import {
  changeSetsInBasket,
  editItemInBasket,
} from '@redux/actions/basketActions';
import { RemoveDietModal } from '@components/pages/basket/components/removeDietModal';
import { checkIsSalesManagoEnabled } from '@salesManago/helpers';
import { salesManagoUpdateAmount } from '@salesManago/events/shopCart';
import { getPromoCode } from '@redux/selectors/basketSelector';
import { getServerConfig } from '@features/general/generalSlice';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '@config/hooks';
import { useGetDietTagInfoAllQuery } from '@services/api/form/open/dietTagInfo.api';
import AddOns from '@features/orderForm/stepShoppingCart/views/AddOns';
import { BasketItemT } from 'typesRoot/basketItem.type';
import { CalculatePriceT } from 'typesRoot/calculatePrice.type';
import { updateSideOrders } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.slice';
import { changeNumberOfSets } from '@components/pages/basket/helpers/basketHelpers';
import { getOneOffsSelectedFees } from '@features/orderForm/stepShoppingCart/redux/oneOffSideOrders.selector';
import {
  editMenuConfiguration,
  updateMealsData,
} from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.slice';

type Props = {
  item: BasketItemT;
  itemPrice: number;
  lastItem: boolean;
  mealExchange: boolean;
  numberOfSets: number;
  prices: CalculatePriceT | undefined;
  testOrder: boolean;
};

export const ShoppingCartItem = ({
  item,
  itemPrice,
  lastItem,
  mealExchange,
  numberOfSets,
  prices,
  testOrder,
}: Props) => {
  const { data: dietTagsData } = useGetDietTagInfoAllQuery();

  const {
    orderId,
    selectedCalories,
    selectedDays,
    selectedDiet,
    selectedMeals,
    selectedOption,
    selectedSideOrders,
    sets,
    stepMenuConfiguration,
  } = item || {};

  const totalPrices = prices?.items[item.orderId];

  const { dietImageUrl, dietName, dietTag } = selectedDiet || {};
  const { dietOptionName } = selectedOption || {};
  const { calories } = selectedCalories || {};
  const { code } = useAppSelector(getPromoCode) || {};
  const dispatch = useAppDispatch();
  const selectedFees = useAppSelector(getOneOffsSelectedFees);
  const { companyId, salesManago, serverUrl } = useAppSelector(getServerConfig);

  const [isOpen, setIsOpen] = useState(false);

  const handleEditCartItem = () => {
    dispatch(editItemInBasket(orderId, 1));
    dispatch(updateSideOrders(selectedSideOrders));
    if (mealExchange) {
      dispatch(editMenuConfiguration(stepMenuConfiguration));
      setTimeout(() => {
        dispatch(updateMealsData({ value: true, carouselId: 'all' }));
      }, 1);
    }
  };

  const handleEditMenuConfiguration = () => {
    dispatch(editItemInBasket(orderId, 3));
    dispatch(updateSideOrders(selectedSideOrders));
    dispatch(editMenuConfiguration(stepMenuConfiguration));
    setTimeout(() => {
      dispatch(updateMealsData({ value: true, carouselId: 'all' }));
    }, 1);
  };

  return (
    <div
      className={`shopping-cart-item ${
        lastItem ? 'shopping-cart-item--last-item' : ''
      }`}
    >
      <div className="shopping-cart-item__description">
        <RemoveDietModal
          isOpen={isOpen}
          item={item}
          itemPrice={itemPrice}
          numberOfSets={numberOfSets}
          orderId={orderId}
          setIsOpen={setIsOpen}
        />
        <img
          alt={dietName ? dietName : 'Placeholder'}
          className="shopping-cart-item__image"
          src={
            dietImageUrl ? dietImageUrl : findDietTagImg(dietTag, dietTagsData)
          }
        />
        <div>
          <div className="shopping-cart-item__title-wrapper">
            <p className="shopping-cart-item__details-paragraph">
              {dietName}
              <span className="spacer-horizontal-6">•</span>
            </p>
            <p className="shopping-cart-item__details-paragraph">
              {dietOptionName}
              <span className="spacer-horizontal-6">•</span>
            </p>
            <p className="shopping-cart-item__details-paragraph">
              {calories} kcal
            </p>
            {testOrder && (
              <>
                <span className="spacer-horizontal-6">•</span>
                <p className="label-l">(Zamówienie testowe)</p>
              </>
            )}
          </div>
          <div className="display-flex align-items-center flex-wrap">
            <div className="display-flex align-items-center spacer-bottom-8 spacer-right-8">
              <FontAwesomeIcon
                className="font-size-20 color-gray-400 spacer-right-8"
                icon={faCalendarAlt}
              />
              <div>{parseOrderPeriod(selectedDays)}</div>
            </div>
            <div className="shopping-cart-item__days">
              {selectedDays?.length} dni
            </div>
          </div>

          <div className="shopping-cart-item__adds-on-desktop">
            <AddOns
              mealExchange={mealExchange}
              selectedMeals={selectedMeals}
              totalMealsChosenCost={totalPrices?.totalMealsChosenCost || 0}
              totalSideOrdersCost={totalPrices?.totalSideOrdersCost || 0}
            />
          </div>
        </div>

        <div className="shopping-cart-item__amount">
          <div className="shopping-cart-item__circle-buttons">
            <DaysAmount
              days={sets}
              decrease={() => {
                changeNumberOfSets(item, 'minus', dispatch, changeSetsInBasket);
                if (
                  checkIsSalesManagoEnabled(salesManago) &&
                  !!item.eventId &&
                  item.sets > 1
                ) {
                  salesManagoUpdateAmount({
                    amount: item.sets - 1,
                    eventId: item.eventId,
                    order: item,
                    code,
                    selectedFees,
                    serverUrl,
                    salesManago,
                    companyId,
                    selectedSideOrders,
                    cartPrices: prices?.cart,
                  }).catch(e => {
                    console.log(e, 'e');
                  });
                }
              }}
              increase={() => {
                changeNumberOfSets(item, 'add', dispatch, changeSetsInBasket);
                if (checkIsSalesManagoEnabled(salesManago) && !!item.eventId) {
                  salesManagoUpdateAmount({
                    amount: item.sets + 1,
                    eventId: item.eventId,
                    order: item,
                    code,
                    selectedFees,
                    serverUrl,
                    salesManago,
                    companyId,
                    selectedSideOrders,
                    cartPrices: prices?.cart,
                  }).catch(e => {
                    console.log(e, 'e');
                  });
                }
              }}
              withoutBorders
            />
          </div>
          <div>
            <p className="shopping-cart-item__price">
              {totalPrices
                ? parsePrice(
                    totalPrices?.totalDietWithoutSideOrdersCost +
                      totalPrices?.totalMealsChosenCost
                  )
                : 0}{' '}
              zł
            </p>
            <div className="white-space-nowrap">
              {parsePrice(totalPrices?.perDayDietCost)} zł / dzień
            </div>
          </div>
        </div>

        <div className="shopping-cart-item__adds-on-mobile">
          <AddOns
            mealExchange={mealExchange}
            selectedMeals={selectedMeals}
            spaceBetween
            totalMealsChosenCost={totalPrices?.totalMealsChosenCost || 0}
            totalSideOrdersCost={totalPrices?.totalSideOrdersCost || 0}
          />
        </div>
      </div>

      <div className="shopping-cart-item__actions-buttons">
        <div className="display-flex align-items-center spacer-top-24">
          <Button
            colorClean
            containerClass="cursor-pointer"
            icon={<FontAwesomeIcon icon={faPencil} />}
            label="Zmień dietę"
            onClick={handleEditCartItem}
          />
          <span>•</span>
        </div>
        {mealExchange && (
          <div className="display-flex align-items-center spacer-top-24">
            <Button
              colorClean
              containerClass="cursor-pointer"
              icon={<FontAwesomeIcon icon={faSync} />}
              label="Konfiguruj menu"
              onClick={handleEditMenuConfiguration}
            />
            <span>•</span>
          </div>
        )}
        <div className="display-flex align-items-center spacer-top-24">
          <Button
            colorClean
            containerClass="cursor-pointer action-button__delete"
            icon={<FontAwesomeIcon icon={faTrash} />}
            label="Usuń dietę"
            onClick={() => {
              setIsOpen(true);
            }}
          />
        </div>
      </div>
    </div>
  );
};
