import React from 'react';
import BoxStep from '../../../common/BoxStep';
import ProfileIcon from '../../../../assets/icons/ProfileIcon';
import { Link } from 'react-router-dom';
import Button from '../../../common/Button';
import { getServerConfig } from '../../../../features/general/generalSlice';
import { useAppSelector } from '../../../../config/hooks';

const SuccessRegisterConfirmation = () => {
  const { isCustomPanel } = useAppSelector(getServerConfig);

  return (
    <BoxStep>
      <div className="display-flex flex-direction-column align-items-center">
        <ProfileIcon className="profile-icon-color" />
        <h3 className="h300 spacer-top-24 spacer-bottom-8">
          {isCustomPanel
            ? 'Konto zostało założone'
            : 'Konto Dietly zostało założone'}
        </h3>
        <p className="body-l spacer-bottom-40 max-width-545 text-align-center">
          Wysłaliśmy Ci wiadomość w której możesz potwierdź swój adres email i
          zalogować się do Twojego Panelu {isCustomPanel ? 'Klienta' : 'Dietly'}
          .
        </p>
        <Link to="/">
          <Button colorBlank label="Wróć na stronę główną" sizeMedium />
        </Link>
      </div>
    </BoxStep>
  );
};

export default SuccessRegisterConfirmation;
