import { createSlice } from '@reduxjs/toolkit';
import {
  modalErrorPresentationE,
  modalErrorSliceInitialStateT,
} from '../../types/modalError.type';
import { RootState } from '../../../../config/hooks';

const initialState: modalErrorSliceInitialStateT = {
  isLoading: false,
  message: '',
  presentation: modalErrorPresentationE.Modal,
  showModal: false,
};

const modalErrorSlice = createSlice({
  name: 'modalErrorSlice',
  initialState,
  reducers: {
    changeShowModalError: (state, action) => {
      state.showModal = action.payload;
    },
    setModalError: (state, action) => {
      const { message, presentation } = action.payload;
      state.showModal = true;
      state.message = message;
      state.presentation = presentation;
    },
    setModalErrorLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const getModalError = (state: RootState) => state.modalErrorSlice;

export const getShowModalError = (state: RootState) =>
  getModalError(state).showModal;

export const getModalErrorLoading = (state: RootState) =>
  getModalError(state).isLoading;

// export const getModalErrorMessage = (state: RootState) =>
//   getModalError(state).message;
//
// export const getModalErrorPresentation = (state: RootState) =>
//   getModalError(state).presentation;

export const { changeShowModalError, setModalError, setModalErrorLoading } =
  modalErrorSlice.actions;

export default modalErrorSlice.reducer;
