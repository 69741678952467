import { DateTime } from 'luxon';
import { ProgramTypeE } from 'typesRoot/orderForm.type';
import { BasketItemT } from 'typesRoot/basketItem.type';
import { SimpleOrdersT } from '@features/orderForm/stepSummary/types/stepSummary.type';
import { DeliveryMethodEnum } from '@features/pickupPoint/types/deliveryMethod.enum';
import {
  SideOrderOrCourseCalculatePriceT,
  SideOrderOrCourseTypeE,
} from '@features/coursesAsSideOrders/types/coursesAsSideOrders.type';
import { parseTypeAndIdFromKey } from '@features/coursesAsSideOrders/utils/coursesAsSideOrders.util';
import {
  SelectedSideOrdersT,
  SideOrderIdT,
} from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.slice';
import { MethodOfPurchaseEnum } from 'typesRoot/methodOfPurchase.enum';

export const mapDeliveryDates = (selectedDays: Array<number>) =>
  selectedDays.map(day => DateTime.fromMillis(day).toISODate());

export const mapSelectedMeals = (
  selectedMeals: Array<{ selected: boolean; dietCaloriesMealId: number }>
) => {
  if (!selectedMeals) {
    return [];
  }

  return selectedMeals?.reduce(
    (
      acc: Array<{
        amount: number;
        dietCaloriesMealId: number;
      }>,
      curr
    ) => {
      const isSelected = curr.selected;
      if (isSelected) {
        acc.push({
          amount: 1,
          dietCaloriesMealId: curr.dietCaloriesMealId,
        });
      }
      return acc;
    },
    []
  );
};

export const generateSideOrdersList = (
  selectedSideOrders: SelectedSideOrdersT
): Array<SideOrderOrCourseCalculatePriceT> => {
  const list = [];

  for (const [key, value] of Object.entries(selectedSideOrders)) {
    for (const [sideOrderKey, item] of Object.entries(value)) {
      const { multiplier } = item;

      if (multiplier > 0) {
        const obj = {
          deliveryDates: [key],
          multiplier,
          sideOrderKey: sideOrderKey as SideOrderIdT,
        };
        list.push(obj);
      }
    }
  }

  const result: Record<string, SideOrderOrCourseCalculatePriceT> = {};

  list.forEach(({ deliveryDates, multiplier, sideOrderKey }) => {
    const key = `${multiplier}-${sideOrderKey}`;

    const resultKey = result[key];

    if (
      resultKey &&
      'deliveryDates' in resultKey &&
      Array.isArray(resultKey.deliveryDates)
    ) {
      resultKey.deliveryDates = resultKey.deliveryDates.concat(deliveryDates);
    } else {
      const [type, id] = parseTypeAndIdFromKey(sideOrderKey);
      if (type === SideOrderOrCourseTypeE.POSSIBLE_SIDE_ORDER) {
        result[key] = {
          deliveryDates: [...deliveryDates],
          multiplier,
          possibleSideOrderId: id as number,
        };
      }
      if (type === SideOrderOrCourseTypeE.COURSE_AS_SIDE_ORDER) {
        result[key] = {
          courseAsSideOrderId: id as number,
          deliveryDates: [...deliveryDates],
          multiplier,
        };
      }
    }
  });

  return Object.values(result);
};

export const generateSimpleOrders = (
  data: {
    deliveryMethod: DeliveryMethodEnum;
    pickupPointId: number | null;
    methodOfPurchase: MethodOfPurchaseEnum;
    selectedProfileAddress: { profileAddressId: number } | null;
    deliveryHour: string;
    company: boolean;
    note: string;
    // TODO: any;
    paymentType: any;
  },
  basket: Array<BasketItemT>
) => {
  const simpleOrders: Array<SimpleOrdersT> = [];

  basket.forEach(item => {
    const {
      orderId,
      selectedCalories,
      selectedDays,
      selectedMeals,
      selectedProgram,
      selectedSideOrders,
      sets,
      stepMenuConfiguration,
      testOrder,
    } = item;

    const pickupPointId =
      data.deliveryMethod === DeliveryMethodEnum.P ? data.pickupPointId : null;

    const profileAddressId =
      data.methodOfPurchase === MethodOfPurchaseEnum.Login &&
      data.selectedProfileAddress?.profileAddressId
        ? data.selectedProfileAddress.profileAddressId
        : null;

    const deliveryDates = mapDeliveryDates(selectedDays) as Array<string>;
    const { customDeliveryMealsForCalculatePrice } = stepMenuConfiguration;
    const customDeliveryMeals =
      selectedProgram !== ProgramTypeE.MenuConfiguration
        ? {}
        : customDeliveryMealsForCalculatePrice;

    const deliveryMeals = mapSelectedMeals(selectedMeals);
    const sideOrders = generateSideOrdersList(selectedSideOrders);

    for (let i = 0; i < sets; i++) {
      simpleOrders.push({
        itemId: orderId,
        addressId: null,
        deliveryDates,
        customDeliveryMeals,
        deliveryMeals,
        dietCaloriesId: selectedCalories.dietCaloriesId,
        hourPreference: data.deliveryHour,
        invoice: data.company,
        note: data.note,
        paymentType: data.paymentType,
        pickupPointId,
        profileAddressId,
        sideOrders,
        testOrder,
      });
    }
  });

  return simpleOrders;
};
