import {
  SideOrderOrCourseT,
  SideOrderOrCourseTypeE,
} from '@features/coursesAsSideOrders/types/coursesAsSideOrders.type';
import { SideOrderIdT } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.slice';

export const parseKeyFromSideOrderId = (
  sideOrderId: SideOrderOrCourseT['sideOrderId']
): SideOrderIdT => `${sideOrderId.type}-${sideOrderId.id}`;

export const parseTypeAndIdFromKey = (
  key: SideOrderIdT
): [SideOrderOrCourseTypeE, number] => {
  const [type, id] = key.split('-');
  const castedId = Number(id);
  return [type as SideOrderOrCourseTypeE, castedId];
};
