type Props = {
  height?: string;
  width?: string;
};

const StarIconRate = ({ height = '24', width = '24' }: Props) => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="white" height="24" rx="4" width="24" />
      <path
        d="M18.9765 10.4175C18.9324 10.2932 18.8521 10.184 18.7454 10.1031C18.6387 10.0223 18.5101 9.9733 18.3753 9.96208L14.3977 9.39788L12.6152 5.88347C12.5579 5.76854 12.4686 5.67161 12.3573 5.60379C12.246 5.53597 12.1174 5.5 11.986 5.5C11.8547 5.5 11.726 5.53597 11.6148 5.60379C11.5035 5.67161 11.4141 5.76854 11.3569 5.88347L9.57432 9.39108L5.59673 9.96208C5.46735 9.97997 5.34572 10.0328 5.24563 10.1145C5.14555 10.1962 5.07102 10.3035 5.0305 10.4243C4.99341 10.5424 4.99008 10.668 5.02087 10.7878C5.05166 10.9076 5.1154 11.0169 5.20526 11.1041L8.09233 13.8232L7.39328 17.6843C7.36833 17.8117 7.38139 17.9434 7.43094 18.0639C7.48049 18.1843 7.56445 18.2885 7.6729 18.364C7.77861 18.4375 7.90329 18.4809 8.03295 18.4893C8.1626 18.4977 8.2921 18.4708 8.4069 18.4116L11.986 16.5966L15.5512 18.4184C15.6493 18.4722 15.7601 18.5004 15.8727 18.5C16.0208 18.5005 16.1652 18.4553 16.2852 18.3708C16.3936 18.2953 16.4776 18.1911 16.5271 18.0707C16.5767 17.9502 16.5898 17.8185 16.5648 17.6911L15.8658 13.83L18.7528 11.1109C18.8538 11.0277 18.9283 10.9185 18.9679 10.7957C19.0075 10.673 19.0105 10.5418 18.9765 10.4175Z"
        fill="#FFB700"
      />
    </svg>
  );
};

export default StarIconRate;
