import { useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';

import {
  getDayNumber,
  getMonthNameShort,
  getWeekdayNameShort,
} from '@adapters/dates/dates.adapter';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getMenuConfCalendarDays,
  getSelectedDaysISO,
} from '@redux/selectors/orderFormSelector';
import { Settings } from 'luxon';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useAppDispatch, useAppSelector } from '@config/hooks';
import {
  getMenuConfActiveDay,
  getMenuConfLoadingCarouselId,
} from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.selector';
import {
  changeActiveDay,
  changeMenuConfDay,
} from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.slice';
import { scrollUp } from '@utils/common';
import Button from '@components/common/Button';
import { classNamesUtil } from '@dietly/design-system-library';
import { breakpointsCarouselCalendar } from '@features/orderForm/stepMenuConfiguration/consts/menuConf.const';

type SwiperElement = {
  slideNext: () => void;
  slidePrev: () => void;
  slideTo: (n: number) => void;
  activeIndex: number;
  realIndex: number;
} | null;

type Props = {
  companyId: string;
};

const OrderFormCarouselCalendar = ({ companyId }: Props) => {
  Settings.defaultLocale = 'pl';
  // Selectors
  const activeDay = useAppSelector(getMenuConfActiveDay);
  const selectedDays = useAppSelector(getSelectedDaysISO);
  const calendarDatesArray = useAppSelector(
    getMenuConfCalendarDays
  ) as Array<string>;
  const loadingCarouselId = useAppSelector(getMenuConfLoadingCarouselId);

  // Hooks
  const dispatch = useAppDispatch();
  const [swiper, setSwiper] = useState<SwiperElement | null>(null);

  // Consts
  const shouldDisableNextButton =
    selectedDays.indexOf(activeDay) === selectedDays.length - 1;
  const shouldDisablePreviousButton = selectedDays.indexOf(activeDay) === 0;
  const shouldDisableCalendarDay = loadingCarouselId !== '';

  const handleChangeDay = (date: string) => {
    dispatch(changeMenuConfDay({ companyId, date, scrollUp }));
  };

  const handleClickPreviousDay = () => {
    dispatch(
      changeActiveDay(selectedDays[selectedDays.indexOf(activeDay) - 1])
    );
  };

  const handleClickNextDay = () => {
    dispatch(
      changeActiveDay(selectedDays[selectedDays.indexOf(activeDay) + 1])
    );
  };

  // Effects
  useEffectOnce(() => {
    // Ten efekt ma wybrać pierwszy dzień zgodnie z tym, który pierwszy dzień został ustawiony na kroku kalendarza
    if (!activeDay) {
      const [firstSelectedDay = ''] = selectedDays;
      dispatch(changeActiveDay(firstSelectedDay));
    }
  });

  useEffect(() => {
    // Ten efekt scrolluje karuzelę do wybranej daty
    const indexOfActiveDay = calendarDatesArray.indexOf(activeDay);
    swiper?.slideTo(indexOfActiveDay);
  }, [activeDay, calendarDatesArray, swiper]);

  return (
    <>
      <div className="carousel-calendar">
        <div className="container carousel-calendar__container">
          <div className="carousel-calendar__wrapper">
            <div className="carousel-calendar__overlay carousel-calendar__overlay--left" />
            <Button
              colorBlank
              containerClass="carousel-calendar__arrow carousel-calendar__arrow--left"
              disabled={shouldDisablePreviousButton}
              icon={<FontAwesomeIcon icon={faChevronLeft} />}
              onClick={handleClickPreviousDay}
            />
            <Swiper
              breakpoints={breakpointsCarouselCalendar}
              centeredSlides
              centeredSlidesBounds
              longSwipesRatio={0.2}
              onSwiper={setSwiper}
              style={{
                paddingTop: 5,
              }}
              threshold={20}
            >
              {calendarDatesArray.map(day => {
                const available = selectedDays.includes(day);
                return (
                  <SwiperSlide key={day}>
                    <div
                      className={classNamesUtil('carousel-calendar__date', {
                        'carousel-calendar__date--active': activeDay === day,
                        'carousel-calendar__date--available': available,
                        'carousel-calendar__date--disabled':
                          shouldDisableCalendarDay,
                      })}
                      data-cy={
                        available
                          ? 'carousel-day-available'
                          : 'carousel-day-not-available'
                      }
                      onClick={() => handleChangeDay(day)}
                      onKeyDown={() => handleChangeDay(day)}
                      role="button"
                      tabIndex={0}
                    >
                      <span>{getWeekdayNameShort(day)}</span>
                      <div>{getDayNumber(day)}</div>
                      <span>{getMonthNameShort(day)}</span>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <Button
              colorBlank
              containerClass="carousel-calendar__arrow carousel-calendar__arrow--right"
              disabled={shouldDisableNextButton}
              icon={<FontAwesomeIcon icon={faChevronRight} />}
              onClick={handleClickNextDay}
            />
            <div className="carousel-calendar__overlay carousel-calendar__overlay--right" />
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderFormCarouselCalendar;
