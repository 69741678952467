import { Fragment, useState } from 'react';

import { SideOrderOrCourseT } from '@features/coursesAsSideOrders/types/coursesAsSideOrders.type';
import { parseKeyFromSideOrderId } from '@features/coursesAsSideOrders/utils/coursesAsSideOrders.util';
import { getSelectedSideOrdersError } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.selector';
import {
  clearSideOrderError,
  SelectedSideOrdersT,
  updateSideOrders,
} from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.slice';
import {
  calculateSummary,
  decreaseAllSideOrders,
  decreaseSideOrdersItem,
  increaseAllSideOrders,
  increaseSideOrdersItem,
} from '@features/orderForm/stepSelectSideOrders/utils/sideOrders.util';
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getMinMaxSelectedMeals,
  getSelectedDays,
} from '@redux/selectors/orderFormSelector';
import { parseNumber } from '@utils/parseNumber.util';
import { DateTime } from 'luxon';

import { selectSideOrdersModalStyle as styles } from '@dietly/design-system-library';
import EmptyImageCourseAsSideOrder from '@assets/icons/EmptyImageCourseAsSideOrder';
import { useAppDispatch, useAppSelector } from '@config/hooks';
import Button from '@components/common/Button';
import CustomModalTS from '@components/common/CustomModalTS';
import DaysAmount from '@components/common/DaysAmount';
import { capitalizeFirstLetter } from '@salesManago/helpers';
import { declination } from 'utilsRoot/index';
import Banner from '@features/banner/views/Banner';
import { BannerTypeE } from '@features/banner/types/banner.type';
import { getDeliveryMealCounts } from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.selector';
import { getIsKuchniaVikinga } from '@features/general/generalSlice';
import { generateDateList } from '../utils/sideOrders.util';

type Props = {
  isOpen: boolean;
  selectedSideOrder: SideOrderOrCourseT;
  setIsOpen: (b: boolean) => void;
  setSelectedSideOrder: (p: null | SideOrderOrCourseT) => void;
  selectedSideOrders: SelectedSideOrdersT;
};

const SelectSideOrdersModal = ({
  isOpen,
  selectedSideOrder,
  selectedSideOrders,
  setIsOpen,
  setSelectedSideOrder,
}: Props) => {
  const dispatch = useAppDispatch();
  const selectedDays = useAppSelector(getSelectedDays);
  const { max: mealsLimit } = useAppSelector(getMinMaxSelectedMeals);
  const error = useAppSelector(getSelectedSideOrdersError);

  const deliveryMealCounts = useAppSelector(getDeliveryMealCounts);

  const isKuchniaVikinga = useAppSelector(getIsKuchniaVikinga);

  const [allSideOrdersAmount, setAllSideOrdersAmount] = useState(0);

  const [sideOrdersState, setSideOrdersState] =
    useState<SelectedSideOrdersT>(selectedSideOrders);

  const sortedDays: Array<number> = [...selectedDays].sort();
  const sortedDaysISO = [...sortedDays].map(day =>
    DateTime.fromMillis(day).toISODate()
  );
  const daysSideOrderAvailableInOrder = generateDateList({
    dateFrom: selectedSideOrder.dateFrom,
    dateTo: selectedSideOrder.dateTo,
    startLimit: sortedDaysISO[0] || '',
    endLimit: sortedDaysISO.at(-1) || '',
  });

  const sortedDaysFilteredBySideOrderAvailability: Array<number> = [
    ...selectedDays,
  ]
    .filter(item => {
      return daysSideOrderAvailableInOrder &&
        daysSideOrderAvailableInOrder.length > 0
        ? daysSideOrderAvailableInOrder?.includes(
            item && DateTime.fromMillis(item).toISODate()
          )
        : true;
    })
    .sort();

  const sortedDaysLogic = isKuchniaVikinga
    ? sortedDaysFilteredBySideOrderAvailability
    : sortedDays;

  const {
    defaultPrice,
    imageUrl,
    limitedByMaximumSelectedMeals,
    maxQuantity,
    minQuantity,
    title,
  } = selectedSideOrder || {};

  const selectedSideOrderKey = parseKeyFromSideOrderId(
    selectedSideOrder.sideOrderId
  );

  const closeModal = () => {
    setSelectedSideOrder(null);
    setIsOpen(false);
    setAllSideOrdersAmount(0);
    dispatch(clearSideOrderError());
  };

  const { days, numberOfSideOrders, priceOfSideOrders } = calculateSummary(
    sideOrdersState,
    selectedSideOrderKey
  );

  const monthsDictionary: Record<number, string> = {
    1: 'stycznia',
    2: 'lutego',
    3: 'marca',
    4: 'kwietnia',
    5: 'maja',
    6: 'czerwca',
    7: 'lipca',
    8: 'sierpnia',
    9: 'września',
    10: 'października',
    11: 'listopada',
    12: 'grudnia',
  };

  return (
    <CustomModalTS
      childrenContainerClass={`${styles['container']}`}
      className={styles['custom-modal-side-orders']}
      isModalOpen={isOpen}
      setIsModalOpen={setIsOpen}
    >
      <div className={`h100 ${styles['top-wrapper']}`}>
        <FontAwesomeIcon
          className={`${styles['close']} cursor-pointer`}
          icon={faTimes}
          onClick={() => {
            closeModal();
          }}
        />

        <h6 className="label-l">Wybierz dni dodatku:</h6>

        <div className="display-flex">
          <div className="spacer-right-16">
            {imageUrl ? (
              <img alt={title} height={80} src={imageUrl} width={80} />
            ) : (
              <EmptyImageCourseAsSideOrder />
            )}
          </div>

          <div className="display-flex flex-direction-column justify-content-center">
            <p className="label-m spacer-bottom-6">{title}</p>

            <p className="label-m color-success spacer-all-0">
              {parseNumber(defaultPrice)} zł / dzień
            </p>
          </div>
        </div>
      </div>

      <div className={styles['center-wrapper']}>
        <div
          className={`${styles['center-wrapper-day']} ${styles['center-wrapper-day-all-days']}`}
        >
          <div className="display-flex flex-direction-column">
            <p className="label-s">Ustaw wszystkie dni na x sztuk:</p>
            <p className="font-weight-400 label-s color-gray-500">
              Zmiana wartości nadpisze poniższe ustawienia
            </p>
          </div>

          <div className={styles['center-wrapper-day-amount']}>
            <DaysAmount
              days={allSideOrdersAmount}
              decrease={() => {
                dispatch(
                  decreaseAllSideOrders({
                    allSideOrdersAmount,
                    defaultPrice,
                    limitedByMaximumSelectedMeals,
                    maxQuantity,
                    minQuantity,
                    selectedSideOrderKey,
                    setAllSideOrdersAmount,
                    setSideOrdersState,
                    sideOrdersState,
                    ...(isKuchniaVikinga
                      ? {
                          sortedDays: sortedDaysFilteredBySideOrderAvailability,
                        }
                      : { sortedDays: sortedDays }),
                  })
                );
              }}
              disableDecrease={allSideOrdersAmount === 0}
              increase={() => {
                dispatch(
                  increaseAllSideOrders({
                    allSideOrdersAmount,
                    defaultPrice,
                    deliveryMealCounts,
                    limitedByMaximumSelectedMeals,
                    maxQuantity,
                    mealsLimit,
                    minQuantity,
                    selectedSideOrderKey,
                    selectedSideOrders,
                    setAllSideOrdersAmount,
                    setSideOrdersState,
                    sideOrdersState,
                    ...(isKuchniaVikinga
                      ? {
                          sortedDays: sortedDaysFilteredBySideOrderAvailability,
                        }
                      : { sortedDays: sortedDays }),
                  })
                );
              }}
              withoutBorders
            />
          </div>
        </div>

        {error === 'maxMeals' && (
          <Banner
            containerClass="spacer-top-8"
            onClose={() => {
              dispatch(clearSideOrderError());
            }}
            text={`Nie możesz dodać więcej sztuk tego dodatku ponieważ z uwagi na gabaryt jest on liczony jako kolejny posiłek. Limit posiłków w wybranym przez Ciebie wariancie diety to ${mealsLimit}.`}
            type={BannerTypeE.Error}
          />
        )}

        {error === 'maxSideOrders' && (
          <Banner
            containerClass="spacer-top-8"
            onClose={() => {
              dispatch(clearSideOrderError());
            }}
            text={`Możesz dodać maksymalnie ${declination(
              maxQuantity,
              'dodatek',
              'dodatki',
              'dodatków'
            )} na każdy dzień. Jeśli chcesz dodać więcej dodatków, musisz złożyć nowe zamówienie.`}
            type={BannerTypeE.Error}
          />
        )}

        {error === 'minMeals' && (
          <Banner
            containerClass="spacer-top-8"
            onClose={() => {
              dispatch(clearSideOrderError());
            }}
            text={`Minimalna ilość dodania to ${minQuantity}. Wartość ta przekracza limit który wynosi ${mealsLimit} razem z wybranymi wcześniej posiłkami`}
            type={BannerTypeE.Error}
          />
        )}

        {sortedDaysLogic.map((day: number) => {
          const dateTime = DateTime.fromMillis(day).setLocale('pl-PL');
          const parseDay = `${capitalizeFirstLetter(dateTime.weekdayLong)}, ${
            dateTime.day
          } ${monthsDictionary[dateTime.month]}`;

          const isoDay = DateTime.fromMillis(day).toISODate() || '';

          const sideOrder = sideOrdersState[isoDay]?.[selectedSideOrderKey];
          const multiplier = sideOrder?.multiplier || 0;

          return (
            <Fragment key={isoDay}>
              <div className={styles['center-wrapper-day']}>
                <p
                  className={`display-flex ${
                    multiplier > 0 ? 'label-s' : 'body-s'
                  }`}
                >
                  {multiplier > 0 && (
                    <FontAwesomeIcon
                      className="font-size-20 spacer-right-10"
                      icon={faCheck}
                    />
                  )}

                  {parseDay}
                </p>

                <div className={styles['center-wrapper-day-amount']}>
                  <DaysAmount
                    days={multiplier}
                    decrease={() => {
                      dispatch(
                        decreaseSideOrdersItem({
                          isoDay,
                          minQuantity,
                          multiplier,
                          selectedSideOrderKey,
                          setAllSideOrdersAmount,
                          setSideOrdersState,
                        })
                      );
                    }}
                    disableDecrease={multiplier === 0}
                    increase={() => {
                      dispatch(
                        increaseSideOrdersItem({
                          defaultPrice,
                          isoDay,
                          limitedByMaximumSelectedMeals,
                          maxQuantity,
                          mealsLimit,
                          minQuantity,
                          multiplier,
                          selectedSideOrderKey,
                          selectedMealsLength: deliveryMealCounts[
                            isoDay
                          ] as number,
                          selectedSideOrders,
                          setAllSideOrdersAmount,
                          setSideOrdersState,
                          sideOrdersState,
                        })
                      );
                    }}
                    withoutBorders
                  />
                </div>
              </div>

              {error === `maxSingleMeals-${isoDay}` && (
                <Banner
                  onClose={() => {
                    dispatch(clearSideOrderError());
                  }}
                  text={`Nie możesz dodać więcej sztuk tego dodatku ponieważ z uwagi na gabaryt jest on liczony jako kolejny posiłek. Limit posiłków w wybranym przez Ciebie wariancie diety to ${mealsLimit}.`}
                  type={BannerTypeE.Error}
                />
              )}

              {error === `maxSingleSideOrder-${isoDay}` && (
                <Banner
                  onClose={() => {
                    dispatch(clearSideOrderError());
                  }}
                  text={`Możesz dodać maksymalnie ${declination(
                    maxQuantity,
                    'dodatek',
                    'dodatki',
                    'dodatków'
                  )} na każdy dzień. Jeśli chcesz dodać więcej dodatków, musisz złożyć nowe zamówienie.`}
                  type={BannerTypeE.Error}
                />
              )}

              {error === `minSingleMeals-${isoDay}` && (
                <Banner
                  containerClass="spacer-top-8"
                  onClose={() => {
                    dispatch(clearSideOrderError());
                  }}
                  text={`Minimalna ilość dodania to ${minQuantity}. Wartość ta przekracza limit który wynosi ${mealsLimit} razem z wybranymi wcześniej posiłkami`}
                  type={BannerTypeE.Error}
                />
              )}
            </Fragment>
          );
        })}
      </div>

      <div className={styles['bottom-wrapper']}>
        <div>
          <p className="label-l color-success spacer-bottom-4">
            Cena razem: {parseNumber(priceOfSideOrders)} zł
          </p>

          <p className="body-s color-gray-600 spacer-all-0">
            Wybrano:{' '}
            {declination(numberOfSideOrders, 'dodatek', 'dodatki', 'dodatków')}{' '}
            na {declination(days, 'dzień', 'dni', 'dni')}
          </p>
        </div>

        <Button
          colorPrimary
          label="Dodaj"
          onClick={() => {
            dispatch(updateSideOrders(sideOrdersState));
            closeModal();
          }}
          sizeMedium
        />
      </div>
    </CustomModalTS>
  );
};

export default SelectSideOrdersModal;
