import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { PickupPointT } from '../../types/deliveryPickupPoint.type';
import { formApi } from '../../../../services/api/form';

export const deliveryPickupPointApi = formApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    getPickupPoints: build.query<
      Array<PickupPointT>,
      {
        cityId: number;
        companyId: string;
        serverUrl: string;
        apiKey: string;
      }
    >({
      query: ({ apiKey, cityId, companyId, serverUrl }) => ({
        method: 'GET',
        url: `${serverUrl}/api/form/open/pickup-points/city/${cityId}`,
        headers: {
          'company-id': companyId,
          'api-key': apiKey,
        },
      }),
      transformResponse: (response: Array<PickupPointT>) =>
        response.filter(item => item.active),
    }),
  }),
});

export const { useGetPickupPointsQuery } = deliveryPickupPointApi;
