import { getTimeStamp } from '../helpers';
import { salesManagoApiAddContactExtEvent } from '../api';

export const salesManagoLoginEvent = async (email, serverUrl, salesManago) => {
  try {
    await salesManagoApiAddContactExtEvent(
      {
        contactEvent: {
          contactExtEventType: 'LOGIN',
          date: getTimeStamp(),
          description: 'Logowanie',
          detail1: 'Logowanie',
        },
        email,
      },
      serverUrl,
      salesManago
    );
  } catch (e) {
    console.log(e, 'e');
  }
};
