import {
  MenuConfigurationChangeCaloriesArgsT,
  MenuConfigurationChangeCaloriesReturnT,
  MenuConfigurationMealsPerDayArgsT,
  MenuConfigurationMealsPerDayReturnT,
  MenuConfigurationSettingsT,
} from '@features/orderForm/stepMenuConfiguration/types/menuConf.type';
import { formApi } from '@services/api/form';
import {
  refetchMeals,
  updateMealsData,
} from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.slice';

export const orderFormSteps = formApi.injectEndpoints({
  endpoints: build => ({
    getMenuConfigurationMealsPerDay: build.query<
      MenuConfigurationMealsPerDayReturnT,
      MenuConfigurationMealsPerDayArgsT
    >({
      query: ({
        cityId,
        companyId,
        date,
        dietCaloriesId,
        dietCaloriesMealIds,
      }) => ({
        method: 'GET',
        url: `form/open/order-form/steps/menu-configuration/meals`,
        params: {
          date,
          cityId,
          dietCaloriesId,
          dietCaloriesMealIds,
        },
        headers: {
          'company-id': companyId,
        },
      }),
      keepUnusedDataFor: 3600,
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          companyId: `${queryArgs.companyId}`,
          date: `${queryArgs.date}`,
        };
      },
      //TODO: MENUCONF może ten merge out
      merge: (currentCache, newItems) => {
        return newItems;
      },
      forceRefetch: ({ currentArg, previousArg, state }) => {
        const {
          order: {
            // TODO: MENUCONF types
            // @ts-expect-error types
            stepMenuConfiguration: { shouldRefetchMeals = false },
          },
        } = state;
        return (
          !previousArg ||
          previousArg.date !== currentArg?.date ||
          shouldRefetchMeals
        );
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(updateMealsData({ value: true }));
        dispatch(refetchMeals(false));
      },
    }),
    getMenuConfigurationSettings: build.query<
      MenuConfigurationSettingsT,
      { dietCaloriesId: number; companyId: string; cityId: number }
    >({
      query: ({ cityId, companyId, dietCaloriesId }) => ({
        method: 'GET',
        url: `form/open/order-form/steps/menu-configuration/settings`,
        params: {
          cityId,
          dietCaloriesId,
        },
        headers: {
          'company-id': companyId,
        },
      }),
      keepUnusedDataFor: 3600,
    }),
    getMenuConfigurationChangeCalories: build.query<
      MenuConfigurationChangeCaloriesReturnT,
      MenuConfigurationChangeCaloriesArgsT
    >({
      query: ({
        cityId,
        companyId,
        date,
        dietCaloriesId,
        dietCaloriesMealId,
      }) => ({
        method: 'GET',
        url: `form/open/order-form/steps/menu-configuration/meals/${dietCaloriesMealId}/change-calories`,
        params: {
          date,
          cityId,
          dietCaloriesId,
          dietCaloriesMealId,
        },
        headers: {
          'company-id': companyId,
        },
      }),
      keepUnusedDataFor: 3600,
    }),
  }),
});

export const {
  useGetMenuConfigurationChangeCaloriesQuery,
  useGetMenuConfigurationMealsPerDayQuery,
  useGetMenuConfigurationSettingsQuery,
} = orderFormSteps;
