import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { checkBasketForZeroPrices } from '@components/pages/basket/helpers/basketHelpers';
import Button from '../../../../components/common/Button';
import { getEnhancedEcommerceCartStep } from 'utilsRoot/enhanceEcommerce';
import { Link } from 'react-router-dom';
import { faSyncAlt } from '@fortawesome/pro-light-svg-icons/faSyncAlt';
import { useAppSelector } from '@config/hooks';
import {
  getBasket,
  getFinalSumAfterDiscountAndDeliveryFee,
  getPromoCode,
  getTestOrderNumberOfSetsValidation,
} from '@redux/selectors/basketSelector';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { getSelectedCity } from '@redux/selectors/summaryReducer.selectors';
import { useGetApiFormSettingsQuery } from '@services/api/form/open/open.api';
import { checkoutGA4 } from '../../../GA4/events/ecommerceEvents';

type Props = {
  companyId: string;
  enhancedEcommerce: boolean;
  nextStepIcon: boolean;
  setNextStepIcon: (b: boolean) => void;
};

const NextStepButton = ({
  companyId,
  enhancedEcommerce,
  nextStepIcon,

  setNextStepIcon,
}: Props) => {
  const sendDataToGTM = useGTMDispatch();

  const { data: { testOrderSettings: { maximumPerDay = 0 } = {} } = {} } =
    useGetApiFormSettingsQuery(undefined, {
      skip: !companyId,
    });

  const testOrderNumberOfSetsValidation = useAppSelector(
    getTestOrderNumberOfSetsValidation
  );
  const basket = useAppSelector(getBasket);
  const selectedCity = useAppSelector(getSelectedCity);
  const finalAmountToPayAfterDiscountsAndDeliveryFee = useAppSelector(
    getFinalSumAfterDiscountAndDeliveryFee
  );

  const promoCode = useAppSelector(getPromoCode);

  const { code } = promoCode || {};
  const { activeDietlyPay, promoCodeType } = code || {};

  return (
    <>
      {testOrderNumberOfSetsValidation && maximumPerDay && (
        <div className="display-flex color-error spacer-top-20">
          <FontAwesomeIcon
            className="font-size-20 spacer-right-8"
            icon={faExclamationCircle}
          />
          <div className="body-m color-error">
            Maksymalna ilość zestawów testowych to {maximumPerDay}. Zmniejsz
            ilość aby przejść dalej.
          </div>
        </div>
      )}
      {testOrderNumberOfSetsValidation ||
      !checkBasketForZeroPrices(basket, selectedCity?.sectorId) ||
      (activeDietlyPay &&
        (promoCodeType === 'GLOBAL' || promoCodeType === 'INDIVIDUAL')) ? (
        <Button
          blockButton
          containerClass="spacer-top-24"
          disabled
          iconRight
          label="Przejdź do podsumowania"
          onClick={() => {
            if (enhancedEcommerce) {
              setTimeout(() => {
                sendDataToGTM(
                  getEnhancedEcommerceCartStep(
                    basket,
                    selectedCity,
                    code,
                    2,
                    companyId
                  ) as object
                );
                checkoutGA4(
                  basket,
                  selectedCity,
                  finalAmountToPayAfterDiscountsAndDeliveryFee,
                  code
                );
              });
            }
          }}
          sizeMedium
        />
      ) : (
        <Link
          onClick={event => {
            if (
              testOrderNumberOfSetsValidation ||
              !checkBasketForZeroPrices(basket, selectedCity?.sectorId)
            ) {
              event.preventDefault();
            }
          }}
          to="/platnosc"
        >
          <Button
            blockButton
            containerClass="spacer-top-24"
            icon={nextStepIcon && <FontAwesomeIcon icon={faSyncAlt} spin />}
            iconRight
            label="Przejdź do podsumowania"
            onClick={() => {
              setNextStepIcon(true);
              if (enhancedEcommerce) {
                setTimeout(() => {
                  sendDataToGTM(
                    getEnhancedEcommerceCartStep(
                      basket,
                      selectedCity,
                      code,
                      2,
                      companyId
                    ) as object
                  );
                  checkoutGA4(
                    basket,
                    selectedCity,
                    finalAmountToPayAfterDiscountsAndDeliveryFee,
                    code
                  );
                });
              }
            }}
            sizeMedium
          />
        </Link>
      )}
    </>
  );
};

export default NextStepButton;
