// @ts-nocheck
import React from 'react';
import styles from './sidebar.module.scss';
import { Helmet } from 'react-helmet';
import Button from '../Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { AnimatePresence, motion } from 'framer-motion';

type Props = {
  children: React.ReactNode;
  isSidebarOpen: boolean;
  setIsSidebarOpen: (b: boolean) => void;
};

const Sidebar = ({ children, isSidebarOpen, setIsSidebarOpen }: Props) => {
  return (
    <AnimatePresence>
      {isSidebarOpen && (
        <>
          <Helmet>
            {/*eslint-disable-next-line*/}
            <body style="overflow: hidden;" />
          </Helmet>
          <motion.div
            animate={{ opacity: 1 }}
            className={styles['container']}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            key="sidebar_container"
            transition={{ duration: 0.2 }}
          >
            <div className={styles['left']} />
            <motion.div
              animate={{ opacity: 1, x: 0 }}
              className={styles['right']}
              exit={{ opacity: 0, x: '100%' }}
              initial={{ opacity: 0, x: '100%' }}
              key="sidebar_item"
              transition={{ duration: 0.3 }}
            >
              <Button
                buttonLink
                icon={<FontAwesomeIcon icon={faArrowLeft} />}
                label="Anuluj"
                onClick={() => {
                  setIsSidebarOpen(false);
                }}
              />
              {children}
            </motion.div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default Sidebar;
