import React from 'react';
import { parsePrice } from '../../../../utils';
import {
  getBasket,
  getFinalSumAfterDiscountAndDeliveryFee,
} from '../../../../redux/selectors/basketSelector';
import { getSelectedCity } from '../../../../redux/selectors/summaryReducer.selectors';
import { getServerConfig } from '../../../../features/general/generalSlice';
import { useGetApiFormSettingsQuery } from '../../../../services/api/form/open/open.api';
import { useAppSelector } from '../../../../config/hooks';

const Loan = () => {
  const finalAmountToPayAfterDiscountsAndDeliveryFee = useAppSelector(
    getFinalSumAfterDiscountAndDeliveryFee
  );
  const basket = useAppSelector(getBasket);
  const { sectorId } = useAppSelector(getSelectedCity);
  const { companyId, loan } = useAppSelector(getServerConfig);

  const { data: { generalInformation } = {} } = useGetApiFormSettingsQuery(
    undefined,
    {
      skip: !companyId,
    }
  );

  const { cateringName = '', logo = '' } = generalInformation || {};

  const items_ids = basket
    .map(({ orderId }: { orderId: string }) => orderId)
    ?.join(' || ');

  const items_names = basket
    .map(
      ({
        selectedDiet,
      }: {
        selectedDiet: {
          dietName: string;
        };
      }) => selectedDiet?.dietName
    )
    ?.join(' || ');

  const items_prices = basket
    .map(
      ({
        selectedDietCaloriesIdPrices,
      }: {
        selectedDietCaloriesIdPrices: Array<{
          sectorId: number;
          price: number;
        }>;
      }) =>
        selectedDietCaloriesIdPrices?.find(
          (price: { sectorId: number }) => price?.sectorId === sectorId
        )?.price
    )
    ?.join(' || ');

  const origin = window.location.origin;
  const pathname = window.location.pathname;
  const redirectUrl = `${origin + pathname}#/zaplacono`;

  return (
    <>
      <input name="url_back_as_get" type="hidden" value="true" />
      <input name="partner_id" type="hidden" value={loan?.partnerId} />
      <input name="items_ids" type="hidden" value={items_ids} />
      <input name="items_names" type="hidden" value={items_names} />
      <input name="items_prices" type="hidden" value={items_prices} />
      <input
        name="amount"
        type="hidden"
        value={parsePrice(
          finalAmountToPayAfterDiscountsAndDeliveryFee
          // @ts-ignore
        )?.replace(',', '.')}
      />
      {logo && <input name="logo" type="hidden" value={logo} />}
      {cateringName && (
        <input name="header" type="hidden" value={cateringName} />
      )}
      <input name="url_back" type="hidden" value={redirectUrl} />
      <input name="btn_back_label" type="hidden" value="Powrót do formularza" />
    </>
  );
};

export default Loan;
