import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetApiParamsQuery } from '../../../../services/api/form/open/open.api';
import Button from '../../../common/Button';
import Modal from 'react-modal';
import GuestOrderConfirmationLoyaltyProgramIcon from '../../../../assets/icons/guestOrderConfirmationLoyaltyProgramIcon';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import PigGrey from '../../../../assets/icons/pigGrey';
import { getIfBasketContainsOnlyTestOrders } from '../../../../redux/selectors/basketSelector';
import {
  getIsCustomLoyaltyProgram,
  getServerConfig,
} from '../../../../features/general/generalSlice';
import {
  burakLoyaltyProgramData,
  defaultLoyaltyProgramData,
} from '../../../../data/loyaltyProgramData';
import { useAppSelector } from '../../../../config/hooks';

type Props = {
  isModalOpen: boolean;
  onClickHandlerGuest: () => void;
  onClickHandlerRegister: () => void;
  setIsModalOpen: (b: boolean) => void;
};

const ModalAreYouSureWithoutRegister = ({
  isModalOpen,
  onClickHandlerGuest,
  onClickHandlerRegister,
  setIsModalOpen,
}: Props) => {
  const doesBasketContainOnlyTestOrders = useAppSelector(
    getIfBasketContainsOnlyTestOrders
  );
  const { companyId } = useAppSelector(getServerConfig);
  const { data: params } = useGetApiParamsQuery(companyId, {
    skip: !companyId,
  });

  const isCustomLoyaltyProgram = useAppSelector(getIsCustomLoyaltyProgram);

  const loyaltyProgramData = isCustomLoyaltyProgram
    ? burakLoyaltyProgramData.modalAreYouSureWithoutRegister
    : defaultLoyaltyProgramData.modalAreYouSureWithoutRegister;

  return (
    <Modal
      className="modal-register-check"
      isOpen={isModalOpen}
      overlayClassName="overlay-form-fixed"
    >
      <div className="modal-register-check__no-register">
        <GuestOrderConfirmationLoyaltyProgramIcon classes="guest-order-confirmation-loyalty-program-icon" />

        <FontAwesomeIcon
          className="modal-register-check__no-register-close"
          icon={faTimes}
          onClick={() => setIsModalOpen(false)}
        />

        <p className="h400 text-align-center spacer-bottom-20">
          Na pewno chcesz kontynuować bez rejestracji?
        </p>

        <p className="modal-register-check__no-register-text">
          Składając zamówienie gościnne stracisz możliwość edycji swojego
          zamówienia, adresów dostawy oraz podglądu i oceny posiłków.
        </p>

        {params?.loyaltyProgramEnabled && !doesBasketContainOnlyTestOrders && (
          <div className="modal-register-check__no-register-info">
            <PigGrey classes="spacer-right-4" />

            <span>Rejestrując się, otrzymasz</span>

            <div>
              <span className="modal-register-check__no-register-info-points">
                500
              </span>

              <span>{loyaltyProgramData.text1}</span>
            </div>
          </div>
        )}
      </div>

      <div className="modal-register-check__no-register-buttons">
        <Button
          colorClean
          label="Tak, kontynuuj jako gość"
          onClick={onClickHandlerGuest}
          sizeMedium
        />

        <Button
          label="Zamówienie z rejestracją"
          onClick={onClickHandlerRegister}
          sizeMedium
        />
      </div>
    </Modal>
  );
};

export default ModalAreYouSureWithoutRegister;
