//@ts-nocheck
import * as actionTypes from '../actions/actionTypes';

export const changeMethodOfPurchase = method => ({
  type: actionTypes.CHANGE_METHOD_OF_PURCHASE,
  method,
});

export const changeDeliveryMethod = value => ({
  type: actionTypes.CHANGE_DELIVERY_METHOD,
  value,
});

export const chooseExistingAddress = addressId => ({
  type: actionTypes.CHOOSE_ADDRESS,
  addressId,
});

export const choosePickupPoint = (pickupPointId, discount) => ({
  type: actionTypes.CHOOSE_PICKUP_POINT_ID,
  pickupPointId,
  discount,
});

export const changeAddressInput = (field, value) => ({
  type: actionTypes.CHANGE_ADDRESS_INPUT,
  field,
  value,
});

export const changeClientInput = (field, value) => ({
  type: actionTypes.CHANGE_CLIENT_DETAILS_INPUT,
  field,
  value,
});

export const selectDeliveryHours = id => ({
  type: actionTypes.SELECT_DELIVERY_HOURS,
  id,
});

export const toggleAgreementsCheckbox = (field, checked) => ({
  type: actionTypes.AGREEMENTS_CHECKBOX_TOGGLE,
  field,
  checked,
});

export const toggleAllAgreementsCheckboxes = (fields, payload) => ({
  type: actionTypes.ALL_AGREEMENTS_CHECKBOXES_TOGGLE,
  fields,
  payload,
});

export const selectCityOrZipcode = (option, optionType) => ({
  type: actionTypes.SELECT_CITY_OR_ZIPCODE,
  option,
  optionType,
});

export const resetSelectedZipCode = () => ({
  type: actionTypes.RESET_ZIPCODE,
});

export const changePaymentType = value => ({
  type: actionTypes.CHANGE_PAYMENT_TYPE,
  value,
});

export const resetSummary = () => ({
  type: actionTypes.RESET_SUMMARY,
});

export const selectOrigin = origin => ({
  type: actionTypes.SELECT_ORIGIN,
  origin,
});
