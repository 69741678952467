import App from './App';
import store from './config/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import ConnectedIntlProvider from './providers/ConnectedIntlProvider';
import './config/luxonConfig';
import { ToastContainer } from 'react-toastify';
import UserComScripts from './userCom/components/UserComScripts';
import 'react-toastify/dist/ReactToastify.css';
import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';

const container = document.getElementById('root');

const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <Provider store={store}>
    <ConnectedIntlProvider>
      <ToastContainer />
      <HashRouter>
        <App />
      </HashRouter>
      <UserComScripts />
    </ConnectedIntlProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
