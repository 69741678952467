import { faEnvelope, faPhoneAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import UserIcon from '../../../../../assets/icons/userIcon';
import BoxStep from '../../../../common/BoxStep';
import Button from '../../../../common/Button';
import { usePrefix } from '../../services/usePrefix.hook';
import { usePostApiLogoutMutation } from '../../../../../services/api/form/profile/profile.api';

type Props = {
  profileDetails: any;
};

const LoggedInCustomerData = ({ profileDetails }: Props) => {
  const { email, firstName, lastName, phoneNumber } = profileDetails || {};

  const { phoneToDisplay } = usePrefix(phoneNumber);

  const [logout] = usePostApiLogoutMutation();

  return (
    <BoxStep>
      <h3 className="h400 color-gray-700 spacer-bottom-40">Dane klienta</h3>
      <div className="display-flex align-items-center flex-wrap">
        <div className="spacer-right-40">
          <UserIcon className="user-icon-color" />
        </div>
        <div>
          <div className="display-flex align-items-center spacer-bottom-16">
            <div className="h200 spacer-right-32">
              {firstName} {lastName}
            </div>
            <Button
              colorBlank
              label="Wyloguj"
              onClick={() => {
                logout();
              }}
            />
          </div>
          <div className="display-flex align-items-center body-xl">
            <FontAwesomeIcon
              className="font-size-18 spacer-right-10"
              icon={faEnvelope}
            />
            {email}
          </div>
          <div className="display-flex align-items-center body-xl">
            <FontAwesomeIcon
              className="font-size-18 spacer-right-10"
              icon={faPhoneAlt}
            />
            {phoneToDisplay}
          </div>
        </div>
      </div>
    </BoxStep>
  );
};

export default LoggedInCustomerData;
