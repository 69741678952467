import { SelectedSideOrdersT } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.slice';
import { DateTime } from 'luxon';
import { MealT } from 'typesRoot/basketItem.type';
import {
  BaseDeliveryMealT,
  CustomDeliveryMealsBasketT,
  CustomDeliveryMealsT,
  GenerateMealsT,
  MealFromAnotherCaloriesT,
  MenuConfMealT,
  SimpleMealT,
  StepMenuConfigurationSliceT,
} from '@features/orderForm/stepMenuConfiguration/types/menuConf.type';
import { nanoid } from '@reduxjs/toolkit';
import { RefObject } from 'react';
import {
  getISODateFromMillis,
  getLocaleString,
  getMillisFromISO,
  getWeekdayNameLong,
} from '@adapters/dates/dates.adapter';
import { capitalizeFirstLetter } from '@salesManago/helpers';

export const amountOfSelectedSideOrders = (
  selectedSideOrders: SelectedSideOrdersT,
  activeDay: string
) =>
  Object.values(selectedSideOrders[activeDay] || {}).reduce(
    (sum, value) => sum + (value?.multiplier || 0),
    0
  );

export const amountOfSelectedSideOrdersLimitedByMaximumSelectedMeals = (
  selectedSideOrders: SelectedSideOrdersT,
  activeDay: string
) =>
  prepareAmountOfSelectedSideOrdersLimitedByMaximumSelectedMeals(
    selectedSideOrders[activeDay] || {}
  );

export const prepareAmountOfSelectedSideOrdersLimitedByMaximumSelectedMeals = (
  selectedSideOrdersForActiveDay: SelectedSideOrdersT[keyof SelectedSideOrdersT]
) =>
  Object.values(selectedSideOrdersForActiveDay || {}).reduce(
    (sum, value) =>
      sum + (value?.limitedByMaximumSelectedMeals ? value?.multiplier || 0 : 0),
    0
  );

export const createCalendarDatesArray = (dateFrom: string, dateTo: string) => {
  if (!dateFrom || !dateTo) {
    return [];
  }
  const start = DateTime.fromISO(dateFrom);
  const end = DateTime.fromISO(dateTo);
  const offset = end.diff(start).as('days');

  const plusMinusDays = 19 - offset < 0 ? 3 : (19 - offset) / 2;

  const calendarStart = start
    .minus({ days: Math.ceil(plusMinusDays + 1) })
    .toISODate();
  const calendarEnd = end.plus({ days: Math.ceil(plusMinusDays) }).toISODate();

  if (!!calendarStart && !!calendarEnd) {
    return createDatesArray(calendarStart, calendarEnd);
  }

  return [];
};

export const createDatesArray = (
  dateFrom: string,
  dateTo: string
): Array<string> => {
  if (!dateFrom || !dateTo) {
    return [];
  }

  const start = DateTime.fromISO(dateFrom);
  const end = DateTime.fromISO(dateTo);
  const dates: Array<string> = [];

  let currentDate = start;

  while (currentDate <= end) {
    const currDateISO = currentDate.toISODate();
    if (!!currDateISO) {
      dates.push(currDateISO);
    }
    currentDate = currentDate.plus({ days: 1 });
  }

  return dates;
};

export const prepareMenuConfTitle = (date: string) =>
  `${capitalizeFirstLetter(getWeekdayNameLong(date))} (${getLocaleString(
    date
  )}r.)`;

export const applyReviewColor = (
  reviewsScore: number,
  reviewsNumber: number,
  red: string,
  yellow: string,
  green: string
) => {
  if (reviewsNumber === 0) {
    return green;
  }
  if (reviewsScore >= 66) {
    return green;
  }
  if (reviewsScore >= 33 && reviewsScore < 66) {
    return yellow;
  }
  return red;
};

export const prepareLightMeals = (
  selectedMeals: Array<MealT>
): Array<BaseDeliveryMealT> => {
  const sortedMeals = [...selectedMeals].sort(
    (a, b) => a.mealPriority - b.mealPriority
  );
  return sortedMeals.map((meal: MealT) => ({
    deleted: !meal.selected,
    dietCaloriesMealId: meal.dietCaloriesMealId,
    name: meal.mealName.trim(),
  }));
};

export const prepareInitialCustomDeliveryMeals = (
  baseDeliveryMeals: Array<BaseDeliveryMealT>
) =>
  baseDeliveryMeals.reduce<Record<string, SimpleMealT>>((acc, curr) => {
    if (!curr.deleted) {
      const key = nanoid();
      acc[key] = {
        dietCaloriesMealId: curr.dietCaloriesMealId,
        name: curr.name,
      };
    }
    return acc;
  }, {});

export const prepareNewCustomDeliveryMeals = (
  newMeal: { carouselId: string; dietCaloriesMealId: number; name: string },
  customDeliveryMealsForActiveDay: Record<string, SimpleMealT>
) => {
  return {
    ...customDeliveryMealsForActiveDay,
    [newMeal.carouselId]: {
      dietCaloriesMealId: newMeal.dietCaloriesMealId,
      name: newMeal.name,
    },
  };
};

export const prepareAddCustomDeliveryMeals = (
  newCarouselId: string,
  dietCaloriesMealId: number,
  name: string,
  customDeliveryMealsForActiveDay: Record<string, SimpleMealT>
) => {
  return {
    ...customDeliveryMealsForActiveDay,
    [newCarouselId]: { dietCaloriesMealId, name },
  };
};

export const prepareDeleteCustomDeliveryMeals = (
  carouselId: string,
  customDeliveryMealsForActiveDay: Record<string, SimpleMealT>
) => {
  const customDeliveryMealsClone = {
    ...customDeliveryMealsForActiveDay,
  };

  delete customDeliveryMealsClone[carouselId];
  return customDeliveryMealsClone;
};

export const prepareChangeCaloriesCustomDeliveryMeals = (
  oldCarouselId: string,
  dietCaloriesMealId: number,
  name: string,
  customDeliveryMealsForActiveDay: Record<string, SimpleMealT>
) => {
  const customDeliverMealsClone = { ...customDeliveryMealsForActiveDay };
  return {
    ...customDeliverMealsClone,
    [oldCarouselId]: { dietCaloriesMealId, name },
  };
};

export const generateMealsWithoutCaloriesChanges = ({
  customDeliveryMealsForActiveDay,
  mealsFromBE,
  mealsOrder,
}: GenerateMealsT): Array<MenuConfMealT> => {
  const selectedMeals = Object.values(customDeliveryMealsForActiveDay).map(
    el => el.name
  );
  const emptyMeals = mealsOrder.filter(
    baseMeal => !selectedMeals.some(selectedMeal => selectedMeal === baseMeal)
  );
  return mealsOrder.reduce<Array<MenuConfMealT>>((acc, curr) => {
    const isMealDeleted = emptyMeals.includes(curr);
    if (isMealDeleted) {
      const deletedMeal = mealsFromBE.find(meal => meal.name === curr);
      if (deletedMeal) {
        acc.push({ ...deletedMeal, carouselId: nanoid() });
      }
    }
    const mealOrMeals = Object.entries(customDeliveryMealsForActiveDay).filter(
      entry => entry[1].name === curr
    );
    if (mealOrMeals.length === 1) {
      const carouselId = mealOrMeals.flat()[0];
      const remainingMeal = mealOrMeals.flat()[1];
      if (
        remainingMeal &&
        typeof remainingMeal === 'object' &&
        'dietCaloriesMealId' in remainingMeal &&
        carouselId
      ) {
        const theMeal = mealsFromBE.find(
          meal =>
            meal.name === curr &&
            meal.options.some(
              option =>
                option.dietCaloriesMealId === remainingMeal.dietCaloriesMealId
            )
        );
        // TODO: MENUCONF types
        // @ts-expect-error
        acc.push({ ...theMeal, carouselId });
      }
      return acc;
    }
    if (mealOrMeals.length > 1) {
      const thoseMeals = mealsFromBE.filter(meal => meal.name === curr);
      const mealsToAdd = mealOrMeals.map(entry => {
        const carouselId = entry[0];
        const { dietCaloriesMealId } = entry[1];
        const theMeal = thoseMeals.find(meal =>
          meal.options.some(
            option => option.dietCaloriesMealId === dietCaloriesMealId
          )
        );
        return { ...theMeal, carouselId };
      });

      // TODO: MENUCONF types
      // @ts-expect-error
      acc.push(...mealsToAdd);

      return acc;
    }
    return acc;
  }, []);
};

export const prepareCustomDeliveryMeals = (
  customDeliveryMeals: CustomDeliveryMealsT
): CustomDeliveryMealsBasketT => {
  if (!customDeliveryMeals || Object.keys(customDeliveryMeals).length === 0) {
    return {};
  }

  return Object.entries(
    customDeliveryMeals as CustomDeliveryMealsT
  ).reduce<CustomDeliveryMealsBasketT>((acc, curr) => {
    const [day, entry] = curr;

    acc[day] = Object.values(entry).reduce<
      CustomDeliveryMealsBasketT[keyof CustomDeliveryMealsBasketT]
    >((acc2, curr2) => {
      const sameMealIndex = acc2.findIndex(
        el => el.dietCaloriesMealId === curr2.dietCaloriesMealId
      );
      if (sameMealIndex !== -1) {
        const sameMeal = acc2[sameMealIndex];
        if (sameMeal) {
          const newArr = [
            ...acc2.slice(0, sameMealIndex),
            {
              amount: sameMeal.amount + 1,
              dietCaloriesMealId: sameMeal.dietCaloriesMealId,
            },
            ...acc2.slice(sameMealIndex + 1),
          ];
          acc2 = newArr;
        }
      } else {
        acc2.push({
          amount: 1,
          dietCaloriesMealId: curr2.dietCaloriesMealId,
        });
      }
      return acc2;
    }, []);

    return acc;
  }, {});
};

export const scrollToNextMeal = (
  refs: Record<string, RefObject<HTMLDivElement>>,
  id: string
) => {
  const indexOfClickedItem = Object.keys(refs).indexOf(id);
  refs &&
    Object.values(refs)[indexOfClickedItem + 1]?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
};

export const checkIsDietWithMenuConfiguration = (
  customDeliveryMeals: CustomDeliveryMealsT
) => customDeliveryMeals && Object.keys(customDeliveryMeals).length > 0;

export const prepareMenuConfigurationData = (
  menuConfiguration: StepMenuConfigurationSliceT
) => {
  const { baseDeliveryMeals, customDeliveryMeals } = menuConfiguration;

  return {
    stepMenuConfiguration: {
      baseDeliveryMeals,
      customDeliveryMeals,
      customDeliveryMealsForCalculatePrice:
        prepareCustomDeliveryMeals(customDeliveryMeals),
    },
  };
};

export const prepareFirstConfiguredDay = (
  customDeliveryMeals: CustomDeliveryMealsT
) => {
  if (!customDeliveryMeals || Object.keys(customDeliveryMeals).length === 0) {
    return '';
  }
  const days = Object.keys(customDeliveryMeals);
  if (days.length === 1) {
    return days[0];
  }

  const millisDates = days.map(isoDate => getMillisFromISO(isoDate));
  const firstDateMillis = [...millisDates].sort((a, b) => a - b)[0];
  return firstDateMillis ? getISODateFromMillis(firstDateMillis) : '';
};

export const parseCaloriesFromItem = (item: MealFromAnotherCaloriesT) => {
  const match = item.name.match(/\b\d+\b/);
  if (match) {
    return parseInt(match[0]);
  }
  return 0;
};

export const checkIsThisTheLastMealOfItsType = (
  mealsPerDay: MenuConfMealT[],
  index: number
) => {
  const secondMeal = mealsPerDay[index + 1];
  if (Object.keys(secondMeal || {}).length === 0) {
    return true;
  }
  const firstMeal = mealsPerDay[index];
  return !!firstMeal && !!secondMeal && firstMeal.name !== secondMeal.name;
};

export const checkIsThisTheOnlyMealOfItsType = (
  mealsPerDay: MenuConfMealT[],
  theMeal: MenuConfMealT
) => mealsPerDay.filter(meal => meal.name === theMeal.name).length === 1;
