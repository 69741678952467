import { DateTime } from 'luxon';
import { DietlyOpenCityT } from '../../../../services/api/form/open/formOpenCities.api';
import { toastNotification } from '../../../../utils';
import { ZipCodeT } from '../../../../features/orderForm/stepSummary/types/stepSummary.type';

type AddressesT = Array<{
  cityId: number;
  zipCodeId: number;
  street: string;
  houseNumber: string;
  apartmentNumber: string;
}>;

const checkDuplicatedAddress = (
  addresses: AddressesT,
  street: string,
  houseNumber: string,
  apartmentNumber: string,
  cityId: number,
  zipCodeId: number
) => {
  if (!(addresses && cityId)) {
    return false;
  }

  const sameId = (a: number, b: number) => {
    if (a && b) {
      return a === b;
    }

    return false;
  };

  const sameStreet = (a: string, b: string) => {
    if (a && b) {
      return (
        a.trim().toLowerCase().replace(/\s+/g, ' ') ===
        b.trim().toLowerCase().replace(/\s+/g, ' ')
      );
    }

    return false;
  };

  const sameItem = (a: string, b: string) => {
    if (!a && !b) {
      return true;
    }

    if (a && b) {
      return (
        a.toLowerCase().replace(/\s+/g, '') ===
        b.toLowerCase().replace(/\s+/g, '')
      );
    }

    return false;
  };

  return !!addresses.find(
    address =>
      sameId(address.cityId, cityId) &&
      sameId(address.zipCodeId, zipCodeId) &&
      sameStreet(address.street, street) &&
      sameItem(address.houseNumber, houseNumber) &&
      sameItem(address.apartmentNumber, apartmentNumber)
  );
};

export const saveAddress = async (
  values: {
    apartmentNumber: string;
    floor: string;
    formikCity: DietlyOpenCityT | null;
    formikZipCode: ZipCodeT | null;
    gateKey: string;
    houseNumber: string;
    info: string;
    staircase: string;
    street: string;
  },
  profileAddresses: AddressesT,
  setIsSidebarOpen: (b: boolean) => void,
  postApiAddress: (data: {
    deliverySpot: string;
    street: string;
    houseNumber: string;
    billingAddress: boolean;
    cityId: number;
    zipCodeId: number;
    floor: string;
    gateKey: string;
    staircase: string;
    dateAdded: string | null;
    apartmentNumber: string;
    info: string;
  }) => void
) => {
  const {
    apartmentNumber,
    floor,
    formikCity,
    formikZipCode,
    gateKey,
    houseNumber,
    info,
    staircase,
    street,
  } = values;

  if (
    formikCity?.cityId &&
    formikZipCode?.zipCodeId &&
    checkDuplicatedAddress(
      profileAddresses,
      street,
      houseNumber,
      apartmentNumber,
      formikCity.cityId,
      formikZipCode.zipCodeId
    )
  ) {
    toastNotification(
      'Adres nie może być taki sam jak już istniejący.',
      'error'
    );
    return;
  }

  if (formikCity?.cityId && formikZipCode?.zipCodeId) {
    const data = {
      apartmentNumber,
      billingAddress: false,
      cityId: formikCity.cityId,
      dateAdded: DateTime.local().toISO({ includeOffset: false }),
      deliverySpot: 'DOOR',
      floor,
      gateKey,
      houseNumber,
      info,
      staircase,
      street,
      zipCodeId: formikZipCode.zipCodeId,
    };

    try {
      await postApiAddress(data);

      toastNotification('Pomyślnie dodano nowy adres.', 'success');

      setIsSidebarOpen(false);
    } catch (e) {
      toastNotification('Wystąpił błąd, spróbuj jeszcze raz', 'error');
      console.log(e, 'e');
    }
  }
};
