import { Field } from 'formik';
import React, { forwardRef } from 'react';
import { useAppDispatch } from '../../../../../config/hooks';
import { DeliveryMethodEnum } from '../../../../../features/pickupPoint/types/deliveryMethod.enum';
import DeliveryPickupPoint from '../../../../../features/pickupPoint/views/DeliveryPickupPoint';
import { selectCityOrZipcode } from '../../../../../redux/actions/summaryActions';
import ComboboxCitiesOrder from '../../../../common/search/ComboboxCitiesOrder';
import ComboboxZipCodes from '../../../../common/search/ComboboxZipCodes';
import { AddressDetails } from '../AddressDetails';
import { classNamesUtil } from '../../../../../utils/classNamesUtil.util';

type Props = {
  city: any;
  companyId: string;
  deliveryMethod: DeliveryMethodEnum;
  deliveryTimesOptions: any;
  errors: any;
  handleChange: any;
  orderSettings: any;
  selectedProfileAddress: any;
  touched: any;
  hoursRef: any;
};

const PickupPoint = forwardRef(
  (
    {
      city,
      companyId,
      deliveryMethod,
      deliveryTimesOptions,
      errors,
      handleChange,
      orderSettings,
      selectedProfileAddress,
      touched,
    }: Props,
    hoursRef
  ) => {
    const dispatch = useAppDispatch();

    return (
      <>
        <Field name="pickupPointId">
          {/*@ts-ignore*/}
          {({ field, form }) => (
            <DeliveryPickupPoint
              companyId={companyId}
              // @ts-ignore
              errorMsg={errors['pickupPointId']}
              onSelect={pickupPointId => {
                form.setFieldValue(field.name, pickupPointId);
              }}
            />
          )}
        </Field>
        {!selectedProfileAddress && (
          <>
            <div className="spacer-bottom-24">
              <h3 className="label-l spacer-bottom-8">
                Podaj domyślny adres dostawy
              </h3>
              <p className="body-m">
                Potrzebujemy Twojego adresu dostawy na wypadek
                problemów/chwilowego zamknięcia wybranego punktu odbioru
              </p>
            </div>

            <AddressDetails
              deliveryMethod={deliveryMethod}
              deliveryTimesOptions={deliveryTimesOptions}
              errors={errors}
              handleChange={handleChange}
              isHourObligatory={orderSettings?.obligatoryHourPreference}
              ref={hoursRef}
              touched={touched}
            >
              <div className="steps-page-third__wrapper spacer-top-40">
                <Field data-lpignore="true" name="formikCity">
                  {/*@ts-ignore*/}
                  {({ field, form }) => (
                    <div className="spacer-bottom-24">
                      <p
                        className={classNamesUtil(
                          'label-m spacer-bottom-8 color-gray-500',
                          {
                            'color-error':
                              touched['formikCity'] && errors['formikCity'],
                          }
                        )}
                      >
                        Miasto <span className="color-error">*</span>
                      </p>
                      <div className="steps-page-third__search-bar">
                        <ComboboxCitiesOrder
                          companyName={companyId}
                          initialSelectedItem={city}
                          onSelect={cityItem => {
                            form.setFieldValue(field.name, cityItem);
                            dispatch(selectCityOrZipcode(cityItem, 'city'));
                          }}
                        />
                      </div>
                      {touched['formikCity'] && errors['formikCity'] && (
                        <p className="color-error body-m spacer-top-4">
                          {/*@ts-ignore*/}
                          {errors['formikCity']}
                        </p>
                      )}
                    </div>
                  )}
                </Field>
                <Field data-lpignore="true" name="formikZipCode">
                  {/*@ts-ignore*/}
                  {({ field, form }) => (
                    <div className="spacer-bottom-24">
                      <p
                        className={classNamesUtil(
                          'label-m spacer-bottom-8 color-gray-500',
                          {
                            'color-error':
                              touched['formikZipCode'] &&
                              errors['formikZipCode'],
                          }
                        )}
                      >
                        Kod pocztowy <span className="color-error">*</span>
                      </p>
                      <div className="steps-page-third__search-bar">
                        <ComboboxZipCodes
                          isError={
                            !!touched['formikZipCode'] &&
                            !!errors['formikZipCode']
                          }
                          onSelect={item => {
                            form.setFieldValue(field.name, item);
                            dispatch(selectCityOrZipcode(item, 'zipCode'));
                          }}
                        />
                      </div>
                      {touched['formikZipCode'] && errors['formikZipCode'] && (
                        <p className="color-error body-m spacer-top-4 steps-page-third__small-input">
                          {/*@ts-ignore*/}
                          {errors['formikZipCode'].code}
                        </p>
                      )}
                    </div>
                  )}
                </Field>
              </div>
            </AddressDetails>
          </>
        )}
      </>
    );
  }
);

export default PickupPoint;
