import { useMemo } from 'react';
import { useAppSelector } from '@config/hooks';
import { getProfile } from '@redux/selectors/profileSelector';
import { getSummaryReducer } from '@redux/selectors/summaryReducer.selectors';
import {
  getBasketReducer,
  getOrderForm,
  getSelectedDaysISO,
} from '@redux/selectors/orderFormSelector';
import { getLoyaltyPoints } from '@redux/selectors/basketSelector';
import { getSelectedSideOrders } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.selector';
import { getOneOffsSelectedFees } from '@features/orderForm/stepShoppingCart/redux/oneOffSideOrders.selector';
import {
  getCalculateItemPriceCustomDeliveryMeals,
  getMenuConfShouldUpdateData,
} from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.selector';

const useCalculatePrice = () => {
  const deliveryDates = useAppSelector(getSelectedDaysISO);
  const shouldUpdateData = useAppSelector(getMenuConfShouldUpdateData);

  const { profile } = useAppSelector(getProfile) || {};

  const { newAddressDetails, pickupPointId } =
    useAppSelector(getSummaryReducer) || {};
  const { basket, promoCode } = useAppSelector(getBasketReducer) || {};
  const orderForm = useAppSelector(getOrderForm);

  const { usedLoyaltyPoints } = useAppSelector(getLoyaltyPoints) || 0;
  const selectedSideOrders = useAppSelector(getSelectedSideOrders);
  const selectedFees = useAppSelector(getOneOffsSelectedFees);
  const promoCodes = useMemo(
    () => [promoCode?.code ? promoCode.code.code : ''],
    [promoCode.code]
  );

  const calculateItemPriceCustomDeliveryMeals = useAppSelector(
    getCalculateItemPriceCustomDeliveryMeals
  );

  return {
    basket,
    calculateItemPriceCustomDeliveryMeals,
    deliveryDates,
    isUserLogged: !!profile?.email,
    loyaltyProgramPoints: usedLoyaltyPoints,
    loyaltyProgramPointsGlobal: 0,
    newAddressDetails,
    orderForm,
    pickupPointId,
    promoCodes,
    selectedFees,
    selectedSideOrders,
    shouldUpdateItemMenuConfData: shouldUpdateData,
  };
};

export default useCalculatePrice;
