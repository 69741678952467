import {
  getMenuConfActiveDay,
  getMenuConfCustomDeliveryMeals,
  getMenuConfLoadingCarouselId,
  getSelectedMealsWithSideOrdersAmountForActiveDay,
} from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.selector';
import {
  addCustomDeliveryMeal,
  updateMealsData,
} from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.slice';
import { MenuConfMealT } from '@features/orderForm/stepMenuConfiguration/types/menuConf.type';
import { prepareAddCustomDeliveryMeals } from '@features/orderForm/stepMenuConfiguration/utils/menuConf.utils';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { nanoid } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '@config/hooks';
import Button from '@components/common/Button';
import { parsePrice } from 'utilsRoot/index';

type Props = {
  additionPrice: MenuConfMealT['nextMealAdditionPrice'];
  carouselId: string;
  carouselName: string;
  maxMealsNumber: number;
};

const OrderFormCarouselMealsAddMeal = ({
  additionPrice,
  carouselId,
  carouselName,
  maxMealsNumber,
}: Props) => {
  const dispatch = useAppDispatch();
  const loadingCarouselId = useAppSelector(getMenuConfLoadingCarouselId);
  const isLoading =
    loadingCarouselId === carouselId || loadingCarouselId === 'all';

  const activeDay = useAppSelector(getMenuConfActiveDay);
  const customDeliveryMeals = useAppSelector(getMenuConfCustomDeliveryMeals);
  const numberOfMealsAndSideOrders = useAppSelector(
    getSelectedMealsWithSideOrdersAmountForActiveDay
  );

  const showButton =
    !!additionPrice &&
    !!carouselId &&
    numberOfMealsAndSideOrders < maxMealsNumber &&
    !isLoading;

  const handleAddExistingMeal = () => {
    // Dodaj istniejącego meala = zaktualizuj customDeliveryMeals, nie pobieraj danych dla nowego posiłku (jest w cache), zaktualizuj mealsPerDay
    const newCarouselId = nanoid();
    const customDeliveryMealsForActiveDay = customDeliveryMeals[activeDay];
    if (!!customDeliveryMealsForActiveDay) {
      const mealsCarouselForActiveDay =
        customDeliveryMealsForActiveDay[carouselId];
      if (!!mealsCarouselForActiveDay) {
        const newDietCaloriesMealId =
          mealsCarouselForActiveDay.dietCaloriesMealId;

        const newCustomDeliveryMealsForActiveDay =
          prepareAddCustomDeliveryMeals(
            newCarouselId,
            newDietCaloriesMealId,
            carouselName,
            customDeliveryMealsForActiveDay
          );

        dispatch(addCustomDeliveryMeal(newCustomDeliveryMealsForActiveDay));
        dispatch(updateMealsData({ value: true, carouselId: newCarouselId }));
      }
    }

    // sendEvent(EventName.AddMeal);
  };

  if (!showButton) {
    return null;
  }

  return (
    <div className="carousel-meals__add-button">
      <Button
        colorBlank
        containerClass="spacer-top-24 color-primary"
        disabled={!showButton}
        icon={<FontAwesomeIcon className="font-size-16" icon={faPlus} />}
        label={`Dodaj posiłek za ${parsePrice(additionPrice)} zł`}
        onClick={handleAddExistingMeal}
      />
    </div>
  );
};

export default OrderFormCarouselMealsAddMeal;
