import React from 'react';

const OrderSmallIllustration = () => {
  return (
    <svg
      fill="none"
      height="49"
      viewBox="0 0 48 49"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M39 46H9L6.75 43H41.25L39 46Z"
        fill="white"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M6.75 43H41.25L39 11.5H9L6.75 43Z"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M30 11.5V7C30 4.1005 27.3137 1.75 24 1.75C20.6863 1.75 18 4.1005 18 7V11.5H30Z"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M19.5 16C19.5 15.1715 18.8285 14.5 18 14.5C17.1715 14.5 16.5 15.1715 16.5 16C16.5 16.8285 17.1715 17.5 18 17.5C18.8285 17.5 19.5 16.8285 19.5 16Z"
        fill="white"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M31.5 16C31.5 15.1715 30.8285 14.5 30 14.5C29.1715 14.5 28.5 15.1715 28.5 16C28.5 16.8285 29.1715 17.5 30 17.5C30.8285 17.5 31.5 16.8285 31.5 16Z"
        fill="white"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default OrderSmallIllustration;
