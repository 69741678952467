import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons';
import { PriceCategory } from '../../@types/cateringCityDetails.type';

type DollarRatingsT = {
  containerClass?: string;
  priceCategory?: PriceCategory | null;
};

const DollarRatings = ({ containerClass, priceCategory }: DollarRatingsT) => {
  return (
    <div
      className={`catering-header__dollar-container ${
        containerClass ? containerClass : ''
      }`}
    >
      <div className="catering-header__dollar-wrapper">
        <FontAwesomeIcon
          className={`catering-header__dollar${
            priceCategory ? ` catering-header__dollar--active` : ''
          }`}
          icon={faDollarSign}
        />
        <FontAwesomeIcon
          className={`catering-header__dollar${
            priceCategory && priceCategory !== 'CHEAP'
              ? ` catering-header__dollar--active`
              : ''
          }`}
          icon={faDollarSign}
        />
        <FontAwesomeIcon
          className={`catering-header__dollar${
            priceCategory === 'EXPENSIVE'
              ? ` catering-header__dollar--active `
              : ''
          }`}
          icon={faDollarSign}
        />
      </div>
    </div>
  );
};

export default DollarRatings;
