import { getOrderReducer } from '@features/orderForm/common/services/redux/commonOrderFormSelectors';
import { SelectedSideOrdersT } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.slice';
import { RootState } from '@config/hooks';

export const getStepSelectSideOrdersSlice = (state: RootState) =>
  getOrderReducer(state).stepSelectSideOrders;
export const getSelectedSideOrders = (state: RootState): SelectedSideOrdersT =>
  getStepSelectSideOrdersSlice(state).selectedSideOrders;
export const getSelectedSideOrdersError = (state: RootState): string =>
  getStepSelectSideOrdersSlice(state).error;
export const getChooseSideOrdersLimitedByMaximumSelectedMeals = (
  state: RootState
) => getStepSelectSideOrdersSlice(state).clearLimitedByMaximumSelectedMeals;
