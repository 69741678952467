import { useState } from 'react';
import Button from '../../../common/Button';
import { batch } from 'react-redux';
import {
  changeClientInput,
  changeMethodOfPurchase,
} from '@redux/actions/summaryActions';
import { getPromoCode } from '@redux/selectors/basketSelector';
import {
  redirectToLoginPage,
  savePromoCodeInTheLocalStorage,
} from '../utils/thirdStepHelpers.utils';
import SmallMaleIcon from '../../../../assets/icons/SmallMaleIcon';
import BoxStep from '../../../common/BoxStep';
import ModalAreYouSureWithoutRegister from './ModalAreYouSureWithoutRegister';
import {
  getIsKuchniaVikinga,
  getIsThirdCookiesEnabled,
  getServerConfig,
} from '@features/general/generalSlice';
import { useAppDispatch, useAppSelector } from '@config/hooks';
import SummaryPrices from '../../../../features/orderForm/stepSummary/views/summaryPrices/SummaryPrices';
import YourOrderInfo from '../../../../features/orderForm/stepSummary/views/yourOrderInfo/YourOrderInfo';

const Login = () => {
  const dispatch = useAppDispatch();
  const promoCode = useAppSelector(getPromoCode);
  const isThirdCookiesEnabled = useAppSelector(getIsThirdCookiesEnabled);
  const config = useAppSelector(getServerConfig);
  const isKuchniaVikinga = useAppSelector(getIsKuchniaVikinga);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onClickHandlerGuest = () => {
    batch(() => {
      dispatch(changeMethodOfPurchase('guest'));
      dispatch(changeClientInput('willingToRegister', false));
    });
  };
  const onClickHandlerRegister = () => {
    batch(() => {
      dispatch(changeMethodOfPurchase('register'));
      dispatch(changeClientInput('willingToRegister', true));
    });
  };
  return (
    <>
      <ModalAreYouSureWithoutRegister
        isModalOpen={isModalOpen}
        onClickHandlerGuest={onClickHandlerGuest}
        onClickHandlerRegister={onClickHandlerRegister}
        setIsModalOpen={setIsModalOpen}
      />

      <div className="auth-page">
        <BoxStep>
          <div className="auth-page__container">
            <SmallMaleIcon className="min-width-64 spacer-bottom-8 small-male-icon-color spacer-right-40" />

            <div className="width-100-per">
              <div>
                <h3 className="h300 spacer-bottom-8">
                  {isKuchniaVikinga
                    ? 'Cześć, przygotuj się na nowości!'
                    : 'Witamy'}
                </h3>

                <p className="auth-page__text">
                  {isKuchniaVikinga
                    ? 'Mamy małą rewolucję w naszym systemie, więc jeśli Twój koszyk nagle zniknie po rejestracji, nie panikuj! Wystarczy, że wrócisz do formularza zamówienia żeby przywrócić go do życia. Dzięki za cierpliwość!'
                    : 'Jeżeli chcesz mieć możliwość edycji swojego zamówienia, adresów dostawy oraz podglądu i oceny posiłków - załóż konto klienta'}
                </p>
              </div>

              <div className="auth-page__buttons">
                <Button
                  label="Zamówienie z rejestracją"
                  onClick={() => dispatch(changeMethodOfPurchase('register'))}
                  sizeLarge
                />

                <Button
                  colorBlank
                  label="Zaloguj się"
                  onClick={async () => {
                    await redirectToLoginPage(
                      config,
                      isThirdCookiesEnabled,
                    );

                    savePromoCodeInTheLocalStorage(promoCode);
                  }}
                  sizeLarge
                />
              </div>

              <div className="steps-page__divide" />

              <div className="auth-page__button">
                <p className="auth-page__text spacer-bottom-24">
                  Nie chcesz się logować?
                </p>

                <Button
                  colorBlank
                  label="Zamówienie gościnne"
                  onClick={() => setIsModalOpen(true)}
                  sizeLarge
                />
              </div>
            </div>
          </div>
        </BoxStep>

        <aside className="auth-page__aside">
          <BoxStep borderTitle smallPaddings title="Twoje zamówienie">
            <YourOrderInfo />
          </BoxStep>

          <BoxStep borderTitle smallPaddings title="Podsumowanie">
            <SummaryPrices />
          </BoxStep>
        </aside>
      </div>
    </>
  );
};

export default Login;
