import React, { useState } from 'react';
import {
  checkIfNotAllMealsSelected,
  displaySelectedMeals,
  parseOrderDay,
  parsePrice,
  findDietTagImg,
} from '../../../../utils';
import { motion, AnimatePresence } from 'framer-motion';
import { useGetDietTagInfoAllQuery } from '../../../../services/api/form/open/dietTagInfo.api';

type Props = {
  item: any;
  itemDietPrice: any;
  itemDietPricePerDay: any;
  itemSideOrdersPrice: any;
};

export const OrderSummaryItem = ({
  item,
  itemDietPrice,
  itemDietPricePerDay,
  itemSideOrdersPrice,
}: Props) => {
  const { data: dietTagsData } = useGetDietTagInfoAllQuery();

  const {
    mealExchange,
    selectedCalories: { calories },
    selectedDays,
    selectedDiet: { dietImageUrl, dietName, dietTag },
    selectedMeals,
    selectedOption: { dietOptionName },
    selectedProgram,
    selectedSideOrders,
    sets,
    testOrder,
  } = item;

  const [visibility, toggleVisibility] = useState(false);

  return (
    <div className="steps-page__order-summary-container">
      <div className="steps-page__order-summary-wrapper">
        <img
          alt={dietName}
          className="steps-page__image steps-page__image--small"
          src={
            dietImageUrl ? dietImageUrl : findDietTagImg(dietTag, dietTagsData)
          }
        />

        <div>
          <div className="label-l spacer-bottom-4">
            {sets > 1 ? sets + 'x ' + dietName : dietName}
          </div>

          <div className="body-s spacer-bottom-4">
            {dietOptionName} • {calories}kcal
          </div>

          <div className="body-s">
            {parsePrice(
              selectedProgram === 'menuConfiguration'
                ? mealExchange?.totalPrice
                    ?.totalPriceAfterDiscountWithSideOrders ||
                    itemDietPrice + itemSideOrdersPrice ||
                    0
                : itemDietPrice + itemSideOrdersPrice
            )}{' '}
            zł
          </div>

          {testOrder && <div className="body-s">(Zamówienie testowe)</div>}

          {!visibility && !!selectedDays?.length && (
            <div
              className="label-s color-primary cursor-pointer spacer-top-6 display-inline-block"
              onClick={() => toggleVisibility(true)}
            >
              Zobacz szczegóły
            </div>
          )}
        </div>
      </div>

      <AnimatePresence>
        {visibility && !!selectedDays?.length && (
          <motion.div
            animate={{
              opacity: 1,
              height: 'auto',
              marginTop: 16,
            }}
            className="spacer-top-16"
            exit={{
              opacity: 0,
              height: 0,
              marginTop: 16,
            }}
            initial={{
              opacity: 0,
              height: 0,
              marginTop: 16,
            }}
            transition={{ duration: 0.2 }}
          >
            {checkIfNotAllMealsSelected(selectedMeals) && (
              <div className="display-flex justify-content-space-between spacer-bottom-8">
                <div className="body-s">Posiłki</div>

                <div className="label-s text-align-right">
                  {displaySelectedMeals(selectedMeals)}
                </div>
              </div>
            )}

            <div className="display-flex justify-content-space-between spacer-bottom-8">
              <div className="body-s">Ilość dni</div>

              <div className="label-s text-align-right">
                {selectedDays.length}
              </div>
            </div>

            <div className="display-flex justify-content-space-between spacer-bottom-8">
              <div className="body-s">Początek diety</div>

              <div className="label-s text-align-right">
                {parseOrderDay(selectedDays, 'first')}
              </div>
            </div>

            <div className="display-flex justify-content-space-between spacer-bottom-8">
              <div className="body-s">Zakończenie diety</div>

              <div className="label-s text-align-right">
                {parseOrderDay(selectedDays, 'last')}
              </div>
            </div>

            <div className="display-flex justify-content-space-between spacer-bottom-8">
              <div className="body-s">Liczba zestawów</div>

              <div className="label-s text-align-right">x{sets}</div>
            </div>

            {selectedSideOrders && selectedSideOrders.length > 0 && (
              <div className="display-flex justify-content-space-between spacer-bottom-8">
                <div className="body-s">Dodatki</div>

                <ul>
                  {selectedSideOrders &&
                    selectedSideOrders.length > 0 &&
                    selectedSideOrders.map((sideOrder: any) => (
                      <li
                        className="label-s text-align-right"
                        key={sideOrder.possibleSideOrderId}
                      >
                        {sideOrder.name}
                      </li>
                    ))}
                </ul>
              </div>
            )}

            {(itemSideOrdersPrice > 0 ||
              mealExchange?.totalPrice?.sideOrdersPriceSum > 0) && (
              <div className="display-flex justify-content-space-between spacer-bottom-8">
                <div className="body-s">Cena dodatków</div>

                <div className="label-s text-align-right">
                  +
                  {parsePrice(
                    mealExchange?.totalPrice?.sideOrdersPriceSum > 0
                      ? mealExchange?.totalPrice?.sideOrdersPriceSum
                      : itemSideOrdersPrice
                  )}{' '}
                  zł
                </div>
              </div>
            )}

            {!!mealExchange?.totalPrice
              ?.changesOnMenuConfigurationBeforeDiscount &&
              mealExchange?.totalPrice
                ?.changesOnMenuConfigurationBeforeDiscount !== 0 && (
                <div className="display-flex justify-content-space-between spacer-bottom-8">
                  <div className="body-s">Zmiany</div>

                  <div className="label-s text-align-right">
                    {mealExchange?.totalPrice
                      ?.changesOnMenuConfigurationBeforeDiscount > 0 && '+'}
                    {mealExchange?.totalPrice?.changesOnMenuConfigurationBeforeDiscount?.toFixed(
                      2
                    ) || 0}{' '}
                    zł
                  </div>
                </div>
              )}

            <div className="display-flex justify-content-space-between spacer-bottom-8">
              <div className="body-s">Cena diety</div>

              <div className="label-s text-align-right">
                {parsePrice(itemDietPrice)}
                zł{' '}
                <p className="color-gray-400">
                  {parsePrice(itemDietPricePerDay)} zł/dzień
                </p>
              </div>
            </div>

            {visibility && (
              <div
                className="label-s color-primary cursor-pointer display-inline-block"
                onClick={() => toggleVisibility(false)}
              >
                Ukryj szczegóły
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
