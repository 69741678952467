export enum USER_COM_TYPE {
  UPDATE_USER,
  EVENT,
  PRODUCT_EVENT,
}

export enum USER_COM_EVENT {
  // general
  ADD = 'add',
  REGISTER = 'register',
  LOGIN = 'login',
  ORDER = 'order',
  ORDER_DETAILS = 'order_details',
  ADD_TAG = 'add_tag',
  // product
  ADD_TO_CART = 'add to cart',
  CHECKOUT = 'checkout',
  REMOVE = 'remove',
}

type UserComProps = {
  event_name?: string;
  payload: Record<string, unknown>;
};

type UserComEventProps = {
  type: USER_COM_TYPE;
  data: UserComProps;
};

const MAX_ATTEMPTS = 5;

const updateUser = (data: UserComProps, attempts = 0) => {
  if (window?.UE) {
    window?.UE?.pageHit(data.payload);
  } else if (attempts < MAX_ATTEMPTS) {
    setTimeout(() => updateUser(data, attempts + 1), 1000);
  }
};

const sendEvent = (data: UserComProps, attempts = 0) => {
  if (typeof window?.userengage === 'function') {
    window?.userengage(`event.${data.event_name}`, data.payload);
  } else if (attempts < MAX_ATTEMPTS) {
    setTimeout(() => sendEvent(data, attempts + 1), 1000);
  }
};

const sendProductEvent = (data: UserComProps, attempts = 0) => {
  if (typeof window?.userengage === 'function') {
    window?.userengage(`product_event`, data.payload);
  } else if (attempts < MAX_ATTEMPTS) {
    setTimeout(() => sendProductEvent(data, attempts + 1), 1000);
  }
};

export const sendUserComEvent = ({ data, type }: UserComEventProps) => {
  switch (type) {
    case USER_COM_TYPE.UPDATE_USER:
      updateUser(data);
      break;
    case USER_COM_TYPE.EVENT:
      sendEvent(data);
      break;
    case USER_COM_TYPE.PRODUCT_EVENT:
      sendProductEvent(data);
      break;
    default:
      console.info('sendUserComEvent(): Incorrect type: ', type);
  }
};
