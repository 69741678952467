import { DateTime } from 'luxon';
import { DietT } from '../../../../@types/menu.type';

export const getQuery = (selectedDiet: DietT, dates: (string | string[])[]) => {
  const menusObject = {
    dietId: selectedDiet?.dietId,
    dates,
  };
  return encodeURIComponent(JSON.stringify(menusObject));
};

export const parseDay = (format: string, day: string) => {
  if (!day) {
    return '';
  }
  const luxonDay = DateTime.fromISO(day);
  return luxonDay.setLocale('pl').toFormat(format);
};

export const getNewHighlightedDate = (
  highlightedDate: string,
  direction: string
) => {
  const dateTime = DateTime.fromISO(highlightedDate);
  if (direction === 'up') {
    return dateTime.plus({ day: 1 }).toISODate();
  }
  return dateTime.minus({ day: 1 }).toISODate();
};

export const checkIfHighlightedDateIsSecondToLast = (
  highlightedDate: string,
  dates: string[]
) => {
  const index = dates?.findIndex(date => date === highlightedDate);
  return index === 1;
};
