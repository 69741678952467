import axios from 'axios';
import { formApi } from '..';
import { MenuIngredientsAllergensT } from 'typesRoot/menu.type';

export const getCompanyWidgetSettings = (serverUrl: string) =>
  axios(`${serverUrl}/api/form/open/company-widget-settings`, {
    method: 'GET',
    headers: {
      'api-key': '123',
    },
  });

export const getCompanyWidgetStyles = (serverUrl: string) =>
  axios(`${serverUrl}/api/form/open/company-widget-styles`, {
    method: 'GET',
    headers: {
      'api-key': '123',
    },
  });

export const getApiMenu = (
  companyName: string,
  query: string,
  serverUrl: string,
  apiKey: string
) =>
  axios(
    `${serverUrl}/api/form/open/company-details/${companyName}/menus?searchCriteria=${query}`,
    {
      method: 'GET',
      headers: {
        'api-key': apiKey,
        'company-id': companyName,
      },
    }
  );

export const getApiCompanyMenuMeals = (
  companyName: string,
  activeDay: string,
  mealsIdsWithDietOptionCalories: string,
  serverUrl: string,
  apiKey: string,
  baseDietOptionId: string
) =>
  axios(
    `${serverUrl}/api/form/open/company-details/${companyName}/menuMeals?date=${activeDay}`,
    {
      method: 'GET',
      headers: {
        'api-key': apiKey,
        'company-id': companyName,
      },
      params: {
        mealsIdsWithDietOptionCalories: mealsIdsWithDietOptionCalories,
        baseDietOptionId,
      },
    }
  );

export const companyApi = formApi.injectEndpoints({
  endpoints: build => ({
    getApiMenuWithAllergens: build.query<
      MenuIngredientsAllergensT,
      {
        companyName: string;
        dates: (string | string[])[];
        dietCaloriesId: string | number;
      }
    >({
      query: ({ companyName, dates, dietCaloriesId }) => {
        return {
          method: 'GET',
          url: `form/open/diet-calories/${dietCaloriesId}/menu?dates=${dates.join(
            ','
          )}`,
          headers: {
            'company-id': companyName,
          },
        };
      },
      keepUnusedDataFor: 3600,
    }),
  }),
});

export const { useGetApiMenuWithAllergensQuery } = companyApi;
