import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { Link, useLocation } from 'react-router-dom';
import { resetEdition } from '@redux/actions/basketActions';
import { useDispatch } from 'react-redux';
import { getOrderForm } from '@redux/selectors/orderFormSelector';
import {
  getBasket,
  getFinalSumAfterDiscountAndDeliveryFee,
  getPromoCode,
} from '@redux/selectors/basketSelector';
import { getEnhancedEcommerceCartStep } from 'utilsRoot/enhanceEcommerce';
import { getSelectedCity } from '@redux/selectors/summaryReducer.selectors';
import { getServerConfig } from '@features/general/generalSlice';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { useAppSelector } from '@config/hooks';
import { checkoutGA4 } from '@features/GA4/events/ecommerceEvents';
import { classNamesUtil } from 'utilsRoot/classNamesUtil.util';
import { useGetSideOrdersWithCoursesQuery } from '@services/api/form/open/possibleSideOrders/possibleSideOrders.api';

const ProgressBar = () => {
  const dispatch = useDispatch();
  const sendDataToGTM = useGTMDispatch();

  const { code } = useAppSelector(getPromoCode) || {};
  const { pathname } = useLocation();
  const orderForm = useAppSelector(getOrderForm);
  const basket = useAppSelector(getBasket);
  const selectedCity = useAppSelector(getSelectedCity);
  const { companyId, enhancedEcommerce } = useAppSelector(getServerConfig);
  const finalAmountToPayAfterDiscountsAndDeliveryFee = useAppSelector(
    getFinalSumAfterDiscountAndDeliveryFee
  );

  const { data: sideOrdersWithCourses } = useGetSideOrdersWithCoursesQuery({
    companyId,
  });

  const sideOrdersLength = sideOrdersWithCourses?.length || 0;

  const {
    selectedCalories,
    selectedDays,
    selectedDiet,
    selectedOption,
    selectedProgram,
  } = orderForm || {};

  const numberOfStep = (int: number) => {
    if (sideOrdersLength && selectedProgram === 'menuConfiguration') {
      return int;
    }

    if (sideOrdersLength || selectedProgram === 'menuConfiguration') {
      return int - 1;
    }

    return int - 2;
  };

  const steps = [
    {
      name: 'Wybierz dietę',
      visible: true,
      link: '/',
      id: 1,
    },
    {
      name: 'Wybierz dni',
      visible: true,
      link: '/zamowienie',
      id: 2,
    },
    {
      name: 'Wybierz dodatki',
      visible: !!sideOrdersLength,
      link: '/wybierz-dodatki',
      id: 3,
    },
    {
      name: 'Konfiguracja menu',
      visible: selectedProgram === 'menuConfiguration',
      link: '/wybierz-posilki',
      id: numberOfStep(4),
    },
    {
      name: 'Twój koszyk',
      visible: true,
      link: '/koszyk',
      id: numberOfStep(5),
    },
    {
      name: 'Twoje dane',
      visible: true,
      link: '/platnosc',
      id: numberOfStep(6),
    },
  ];

  const filteredSteps = steps.filter(item => item.visible);

  const isMenuConfigurationDataValid = (link: string) => {
    if (link !== '/wybierz-posilki') {
      return link;
    }

    if (
      selectedProgram === 'menuConfiguration' &&
      selectedCalories?.dietCaloriesId &&
      selectedDays?.length &&
      selectedDiet?.dietId &&
      selectedOption?.dietOptionId
    ) {
      return link;
    } else {
      return `${pathname}`;
    }
  };

  return (
    <div className="steps-page-progress">
      <div className="container">
        <div className="steps-page-progress__list">
          {filteredSteps.map((item, index) => {
            const { id, link, name } = item;

            return (
              <React.Fragment key={`${link}_${index}_${name}`}>
                {link === '/platnosc' &&
                code?.activeDietlyPay &&
                (code?.promoCodeType === 'GLOBAL' ||
                  code?.promoCodeType === 'INDIVIDUAL') ? (
                  <div className="steps-page-progress__item steps-page-progress__item--disabled">
                    <div
                      className={classNamesUtil('steps-page-progress__box', {
                        'steps-page-progress__box--active': pathname === link,
                        'steps-page-progress__box--completed':
                          pathname !== link,
                      })}
                    >
                      {pathname !== link ? (
                        <FontAwesomeIcon icon={faCheck} />
                      ) : (
                        id
                      )}
                    </div>
                    <span>{name}</span>
                  </div>
                ) : (
                  <Link
                    className={classNamesUtil('steps-page-progress__item', {
                      'steps-page-progress__item--active': pathname === link,
                      'steps-page-progress__item--completed': pathname !== link,
                    })}
                    onClick={() => {
                      if (link === '/koszyk') {
                        dispatch(resetEdition());
                      }
                      if (
                        link === '/platnosc' &&
                        !window.location.hash.includes('/platnosc')
                      ) {
                        if (enhancedEcommerce && !!basket.length) {
                          setTimeout(() => {
                            sendDataToGTM(
                              //TODO: TS
                              //@ts-ignore
                              getEnhancedEcommerceCartStep(
                                basket,
                                selectedCity,
                                code,
                                2,
                                companyId
                              )
                            );
                            checkoutGA4(
                              basket,
                              selectedCity,
                              finalAmountToPayAfterDiscountsAndDeliveryFee,
                              code
                            );
                          });
                        }
                      }
                    }}
                    to={isMenuConfigurationDataValid(link)}
                  >
                    <div
                      className={classNamesUtil('steps-page-progress__box', {
                        'steps-page-progress__box--active': pathname === link,
                        'steps-page-progress__box--completed':
                          pathname !== link,
                      })}
                    >
                      {pathname !== link ? (
                        <FontAwesomeIcon icon={faCheck} />
                      ) : (
                        id
                      )}
                    </div>
                    <span>{name}</span>
                  </Link>
                )}

                {index !== filteredSteps.length - 1 && (
                  <span className="steps-page-progress__arrow">{'>'}</span>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
