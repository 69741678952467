import {
  useGetApiProfileClientQuery,
  useGetApiProfileQuery,
} from '../services/api/form/profile/profile.api';
import { useEffect } from 'react';
import {
  markProfileAsFetched,
  storeProfile,
} from '../redux/actions/profileActions';
import {
  changeMethodOfPurchase,
  toggleAgreementsCheckbox,
} from '../redux/actions/summaryActions';
import { useAppDispatch } from '../config/hooks';

const useFetchProfile = (companyId: string) => {
  const dispatch = useAppDispatch();

  const { data: profileData, isFetching } = useGetApiProfileQuery(undefined, {
    skip: !companyId,
  });

  const { data: profileClient } = useGetApiProfileClientQuery(undefined, {
    skip: !companyId || !profileData,
  });

  useEffect(() => {
    if (profileData) {
      dispatch(
        storeProfile(
          profileData?.profile,
          profileData?.cities,
          profileData?.zipCodes
        )
      );
      dispatch(changeMethodOfPurchase('login'));
      dispatch(markProfileAsFetched());
    }
  }, [dispatch, profileData]);

  useEffect(() => {
    if (profileClient) {
      dispatch(
        toggleAgreementsCheckbox(
          'marketingCatering',
          profileClient?.allowMarketingMessages
        )
      );
    }
  }, [dispatch, profileClient]);

  return {
    allowMarketingMessages: profileClient?.allowMarketingMessages,
    isFetching,
  };
};

export default useFetchProfile;
