import { ThermoE } from '@features/badge/types/badge.type';
import { Prettify } from 'typesRoot/generics.type';

export enum SideOrderOrCourseTypeE {
  COURSE_AS_SIDE_ORDER = 'COURSE_AS_SIDE_ORDER',
  POSSIBLE_SIDE_ORDER = 'POSSIBLE_SIDE_ORDER',
}

export type SideOrderOrCourseTimeRestrictionT = {
  dateFrom?: string | null;
  dateTo?: string | null;
};

export type GenerateDateListParamsT = {
  endLimit: string;
  startLimit: string;
} & SideOrderOrCourseTimeRestrictionT;

export type SideOrderOrCourseT = {
  defaultPrice: number;
  imageUrl: string;
  limitedByMaximumSelectedMeals: boolean;
  maxQuantity: number | null;
  minQuantity: number | null;
  rateNumber: number | null;
  rateScore: number | null;
  sideOrderId: {
    type: SideOrderOrCourseTypeE;
    id: number;
  };
  subtitle: string;
  title: string;
} & SideOrderOrCourseTimeRestrictionT;

export type SideOrderOrCourseFromApiT = Prettify<
  Omit<SideOrderOrCourseT, 'sideOrderId'> & {
    id: {
      type: SideOrderOrCourseTypeE;
      possibleSideOrderId?: number;
      courseAsSideOrderId?: number;
    };
  }
>;

export type SideOrderOrCourseCalculatePriceT = {
  multiplier: number;
  possibleSideOrderId?: number;
  courseAsSideOrderId?: number;
  // deliveryDates jest opcjonalne gdyż opłaty jednorazowe nie zawierają tego pola a są dodawane do obiektu simpleOrders
  deliveryDates?: Array<string>;
};

export type GetSideOrdersWithCoursesReturnT = Array<SideOrderOrCourseT>;

export type GetSideOrdersWithCoursesArgsT = {
  companyId: string;
};

export type GetSideOrderOrCourseByIdReturnT = {
  allergens: Array<string> | null;
  categories: Array<string> | null;
  defaultPrice: number;
  imageUrl: string;
  ingredients: Array<string> | null;
  nutrition: {
    calories: number;
    carbohydrate: number;
    fat: number;
    protein: number;
  } | null;
  rateNumber: number | null;
  rateScore: number | null;
  subtitle: string;
  title: string;
  // TODO: POSIŁKODODATKI import
  thermo: ThermoE | null;
};

export type GetSideOrderOrCourseByIdArgsT = {
  companyId: string;
  type: SideOrderOrCourseTypeE;
  sideOrderOrCourseId: number;
};

export type SideOrderWithOrderDatesT = {
  orderDateStart: string;
  orderDateEnd: string;
  sideOrderFrom: string | null | undefined;
  sideOrderTo: string | null | undefined;
};
