import {
  sendUserComEvent,
  USER_COM_EVENT, USER_COM_TYPE,
} from '../api';

export const userComUpdateUserEvent = async (
  userCom,
  clientData,
  methodOfPurchase,
  code
) => {
  try {
    const {
      allowMessages,
      companyName,
      email,
      firstName,
      lastName,
      phoneNumber,
      taxId,
    } = clientData;

    const data = {
      payload: {
        agreement_data_processing: true,
        agreement_marketing: allowMessages,
        client_panel: !!methodOfPurchase ? methodOfPurchase !== 'guest' : true,
        company_name: companyName,
        email: email.toLowerCase(),
        first_name: firstName,
        last_name: lastName,
        NIP: taxId,
        phone_number: `${phoneNumber.includes('+') ? '' : '+'}${phoneNumber}`,
        user_id: email.toLowerCase(),
        event: {
          event_name: USER_COM_EVENT.REGISTER
        }
      }
    };

     sendUserComEvent({ type: USER_COM_TYPE.UPDATE_USER, data });

    if (!!code) {
      const { code: codeName = '', discount, discountType } = code;
      const userPromoCode = {
        active_code: codeName,
        active_code_percentage: discountType === 'PERCENTAGE' ? discount : 0,
        active_code_quota: discountType === 'TOTAL' ? discount : 0,
      };
      const data = {
        payload: {
          ...userPromoCode,
          user_id: userCom.email,
          event: {
            event_name: USER_COM_EVENT.ADD_TAG,
            tag_name: codeName,
          }
        }
      }

      sendUserComEvent({type: USER_COM_TYPE.UPDATE_USER, data})
    }
  } catch (e) {
    console.log('update user', e);
  }
};
