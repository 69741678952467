import { useEffect } from 'react';

import { usePrepareMealsPerDay } from '@features/orderForm/stepMenuConfiguration/services/hooks/usePrepareMealsPerDay';
import { useAppDispatch, useAppSelector } from '@config/hooks';
import {
  getMenuConfActiveDay,
  getMenuConfCustomDeliveryMeals,
} from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.selector';
import {
  initiateCustomDeliveryMeals,
  updateMealsData,
} from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.slice';

export const useGetMenuConfiguration = (companyId: string) => {
  const dispatch = useAppDispatch();
  const customDeliveryMeals = useAppSelector(getMenuConfCustomDeliveryMeals);
  const activeDay = useAppSelector(getMenuConfActiveDay);

  const { isSuccess, isUninitialized, mealsPerDay } =
    usePrepareMealsPerDay(companyId);

  useEffect(() => {
    // Ustaw przy wejściu na dzień (raz i tylko raz przy pierwszym wejściu) pole customDeliveryMeals przy wejściu na dany dzień
    if (
      customDeliveryMeals &&
      activeDay &&
      !(activeDay in customDeliveryMeals)
    ) {
      dispatch(initiateCustomDeliveryMeals());
    }
  }, [activeDay, customDeliveryMeals, dispatch]);

  useEffect(() => {
    if (isSuccess && !isUninitialized && mealsPerDay.length === 0) {
      dispatch(updateMealsData({ value: true, carouselId: 'all' }));
    }
    // Ten efekt updatuje diety kiedy masz zapisane dane w cache, ale z jakiegoś powodu nie zupdatowano mealsPerDay. Przykłady:
    // - klikasz wstecz/dalej w przeglądarce
    // - wracasz intencjonalnie na jeden z poprzednich kroków a potem znowu na konfigurację menu
    // Intencjonalnie nie dodaję 'dispatch', 'isSuccess' oraz 'mealsPerDay.length'
    // Chcę, żeby ten efekt wykonał się tylko po zaczytaniu danych z cache i tylko jeśli te dane już tam są
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUninitialized]);

  return mealsPerDay;
};
