import axios from 'axios';

export const postApiLoggedInOrderPdf = (
  // TODO unknown
  data: unknown,
  serverUrl: string,
  companyId: string,
  apiKey: string
) =>
  axios(`${serverUrl}/api/form/profile/order/pdf`, {
    method: 'POST',
    withCredentials: true,
    headers: {
      'company-id': companyId,
      'api-key': apiKey,
    },
    data,
  });

export const postApiOpenOrderPdf = (
  // TODO unknown
  data: unknown,
  serverUrl: string,
  companyId: string,
  apiKey: string
) =>
  axios(`${serverUrl}/api/form/open/order/pdf`, {
    method: 'POST',
    withCredentials: true,
    headers: {
      'content-type': 'multipart/form-data',
      'company-id': companyId,
      'api-key': apiKey,
    },
    data,
  });

export const postRegisterOrderConfirmation = (
  // TODO unknown
  data: unknown,
  serverUrl: string,
  companyId: string,
  apiKey: string
) =>
  axios(`${serverUrl}/api/open/signup/form/order`, {
    method: 'POST',
    headers: {
      'company-id': companyId,
      'api-key': apiKey,
    },
    data,
  });

export const getLoggedInOrderPaymentStatus = (
  orderId: string,
  // TODO ANY
  cancelTokenSource: any,
  serverUrl: string,
  companyId: string,
  apiKey: string
) =>
  axios(`${serverUrl}/api/form/profile/orders/${orderId}/payment/status`, {
    cancelToken: cancelTokenSource?.token,
    method: 'GET',
    withCredentials: true,
    headers: {
      'company-id': companyId,
      'api-key': apiKey,
    },
  });

export const getOpenOrderPaymentStatus = (
  orderId: string,
  // TODO ANY
  cancelTokenSource: any,
  serverUrl: string,
  companyId: string,
  apiKey: string
) =>
  axios(`${serverUrl}/api/form/open/orders/${orderId}/payment/status`, {
    cancelToken: cancelTokenSource?.token,
    method: 'GET',
    withCredentials: true,
    headers: {
      'company-id': companyId,
      'api-key': apiKey,
    },
    params: {
      token: 'a0d0ecfc98f7d54bdf7e656f8b1738a8',
    },
  });
