import { DateTime } from 'luxon';
import { Dispatch as ReactDispatch, SetStateAction } from 'react';
import { Dispatch } from 'redux';
import { OrderFormT } from 'typesRoot/orderForm.type';
import { DeliveryPlanType, FormSettingsT } from 'typesRoot/settings.type';
import { RootState } from '@config/hooks';
import {
  mealExchangeChangeEditModalClickedArea,
  selectOrderDays,
  setOrderDays,
  storeFilteredWeekends,
  toggleDay,
} from '@redux/actions/orderFormActions';
import {
  getOrderForm,
  getSelectedProgram,
} from '@redux/selectors/orderFormSelector';
import { checkIsDayInMealExchange } from 'utilsRoot/index';
import {
  checkIfNextWeekendDayAllowed,
  getFirstSelectedDay,
  getLatestSelectedDay,
  isDayNotDeliverableLogic,
  isDayOutsideDaysLimit,
} from './selectDays.utils';
import { formApi } from '@services/api/form';
import { getMenuConfCustomDeliveryMeals } from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.selector';

export const toggleOrderDay =
  (day: DateTime, setSelectedValue: ReactDispatch<SetStateAction<number>>) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const orderForm = getOrderForm(getState());
    const selectedProgram = getSelectedProgram(getState());
    const customDeliveryMeals = getMenuConfCustomDeliveryMeals(getState());

    const { data: formSettings } = await dispatch(
      //TODO: TS
      //@ts-expect-error
      formApi.endpoints.getApiFormSettings.initiate()
    );

    setSelectedValue(day.toMillis());

    if (
      selectedProgram === 'menuConfiguration' &&
      orderForm.selectedDays.includes(day.toMillis()) &&
      checkIsDayInMealExchange(day.toISODate(), customDeliveryMeals)
    ) {
      return dispatch(
        mealExchangeChangeEditModalClickedArea('secondStepEditMealExchangeDay')
      );
    }

    if (
      (!formSettings?.orderSettings?.deliveryOnSaturday ||
        !orderForm.saturdays) &&
      day.weekday === 6
    ) {
      return null;
    }

    if (
      (!formSettings?.orderSettings?.deliveryOnSunday || !orderForm.sundays) &&
      day.weekday === 7
    ) {
      return null;
    }

    return dispatch(toggleDay(day.toMillis()));
  };

const getDateObject = (calendarDay?: DateTime) => {
  const todayDate = DateTime.local();

  if (calendarDay) {
    return {
      todayNameOfTheWeek: calendarDay
        .setLocale('en-GB')
        .weekdayLong?.toUpperCase(),
      milliseconds: calendarDay.toMillis(),
    };
  }

  return {
    todayNameOfTheWeek: todayDate.setLocale('en-GB').weekdayLong?.toUpperCase(),
    milliseconds: todayDate.toMillis(),
  };
};

const getClosestDeliveryDayName = (
  selectedProgram: string,
  deliveryPlanByDayOfWeek?: DeliveryPlanType,
  menuConfigurationPlanByDayOfWeek?: DeliveryPlanType
) => {
  const addZero = (i: string | number) => {
    if (i < 10) {
      i = '0' + i;
    }
    return i;
  };

  const today = DateTime.local();

  const currentHour = [addZero(today.hour), addZero(today.minute)].join(':');

  const deliveryPlan = (
    selectedProgram === 'standard'
      ? deliveryPlanByDayOfWeek
      : menuConfigurationPlanByDayOfWeek
  )?.[today.setLocale('en-GB').weekdayLong?.toUpperCase() as string];

  if (!deliveryPlan) {
    return null;
  }

  if (currentHour > deliveryPlan.orderTime) {
    return deliveryPlan.deliveryDayAfterTime;
  }

  return deliveryPlan.deliveryDayBeforeTime;
};

const findExcludedDays = (
  selectedProgram: string,
  deliveryPlanByDayOfWeek?: DeliveryPlanType,
  menuConfigurationPlanByDayOfWeek?: DeliveryPlanType
) => {
  let today = DateTime.local();

  let excludedDays = [];

  const closestDeliveryDayName = getClosestDeliveryDayName(
    selectedProgram,
    deliveryPlanByDayOfWeek,
    menuConfigurationPlanByDayOfWeek
  );

  // solves the edge case when delivery plan is not set
  if (!closestDeliveryDayName) {
    return [today.plus({ days: 1 })];
  }

  while (
    today.setLocale('en-GB').weekdayLong?.toUpperCase() !==
    closestDeliveryDayName
  ) {
    if (
      today.setLocale('en-GB').weekdayLong?.toUpperCase() ===
      closestDeliveryDayName
    ) {
      break;
    }

    excludedDays.push(today);

    today = today.plus({ days: 1 });
  }

  return excludedDays;
};

export const isOrderingNotAllowed = (
  day: DateTime,
  selectedProgram: string,
  formSettings?: FormSettingsT
) => {
  const today = getDateObject();

  const calendarDay = getDateObject(day);

  const excludedDaysContainsCalendarDay = findExcludedDays(
    selectedProgram,
    formSettings?.orderSettings.deliveryPlanByDayOfWeek,
    formSettings?.orderSettings.menuConfigurationPlanByDayOfWeek
  ).findIndex(
    excludedDay => excludedDay.toLocaleString() === day.toLocaleString()
  );

  if (today.milliseconds > calendarDay.milliseconds) {
    return true;
  }

  if (excludedDaysContainsCalendarDay > -1) {
    return true;
  }

  return null;
};

export const getSelectedDays =
  (day: DateTime, isNotEditableEnabled = false) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const orderForm = getOrderForm(getState());

    const { data: formSettings } = await dispatch(
      //TODO: TS
      //@ts-expect-error
      formApi.endpoints.getApiFormSettings.initiate()
    );

    let selectedDays = [day.toMillis()];

    let deliveryDays = orderForm.days;

    let count = 0;

    let numberOfDays = 0;

    if (
      day.weekday === 6 &&
      (!formSettings?.orderSettings.deliveryOnSaturday || !orderForm.saturdays)
    ) {
      return null;
    }

    if (
      day.weekday === 7 &&
      (!formSettings?.orderSettings.deliveryOnSunday || !orderForm.sundays)
    ) {
      return null;
    }

    if (!deliveryDays) {
      dispatch(setOrderDays(1));
    }

    while (numberOfDays < deliveryDays - 1) {
      const nextDay = day
        .plus({
          days: count + 1,
        })
        .toMillis();

      if (
        isDayOutsideDaysLimit(
          nextDay,
          formSettings?.orderSettings.maximumOrderAdvanceDays
        )
      ) {
        dispatch(setOrderDays(selectedDays.length));
        break;
      }

      if (
        isDayNotDeliverableLogic(
          day.plus({
            days: count + 1,
          }),
          formSettings,
          isNotEditableEnabled
        ) ||
        checkIfNextWeekendDayAllowed(
          day.plus({
            days: count + 1,
          }),
          orderForm,
          formSettings
        )
      ) {
        count++;
        numberOfDays++;
        deliveryDays++;
      } else {
        selectedDays.push(day.plus({ days: count + 1 }).toMillis());
        numberOfDays++;
        count++;
      }
    }

    return dispatch(selectOrderDays(selectedDays));
  };

export const changeMonth = (
  direction: string,
  setOffset: (n: number) => void,
  offset: number
) => {
  setOffset(direction === 'forward' ? offset + 1 : offset - 1);
};

export const getInitialOffset = (
  selectedProgram: string,
  orderForm: OrderFormT,
  formSettings?: FormSettingsT,
  isNotEditableEnabled = false
) => {
  const today = DateTime.local();

  const lastMonthDay = DateTime.local(today?.year, today?.month)
    .daysInMonth as number;

  const daysArray = Array.from({ length: lastMonthDay }, (_, i) => i + 1);

  const every = daysArray.every(
    item =>
      isOrderingNotAllowed(
        DateTime.local(today?.year, today?.month, item),
        selectedProgram,
        formSettings
      ) ||
      isDayNotDeliverableLogic(
        DateTime.local(today?.year, today?.month, item),
        formSettings,
        isNotEditableEnabled
      ) ||
      checkIfNextWeekendDayAllowed(
        DateTime.local(today?.year, today?.month, item),
        orderForm,
        formSettings
      )
  );

  return every ? 1 : 0;
};

// this is used when latest selected day is weekend day (in toggling weekend days)
const getLatestSelectedWhichIsNotWeekendDay = (
  orderForm: OrderFormT,
  formSettings?: FormSettingsT
) => {
  if (orderForm.selectedDays?.length === 0) {
    return null;
  }

  return orderForm.selectedDays.reduce((acc, curr) => {
    if (acc > curr) {
      return acc;
    }

    // solves edge case with toggling saturdays when last selected day is saturday
    if (
      (!formSettings?.orderSettings.deliveryOnSaturday ||
        !orderForm.saturdays) &&
      DateTime.fromMillis(curr).weekday === 6
    ) {
      return acc;
    }

    // solves edge case with toggling sundays when last selected day is sunday
    if (
      (!formSettings?.orderSettings.deliveryOnSunday || !orderForm.sundays) &&
      DateTime.fromMillis(curr).weekday === 7
    ) {
      return acc;
    }

    return curr;
  }, 0);
};

const getFilteredWeekendDays = (
  weekendDay: 'saturday' | 'sunday',
  orderForm: OrderFormT,
  formSettings?: FormSettingsT,
  isNotEditableEnabled = false
) => {
  const firstDay = getFirstSelectedDay(orderForm.selectedDays);

  const lastDay =
    orderForm.saturdays &&
    formSettings?.orderSettings.deliveryOnSaturday &&
    orderForm.sundays &&
    formSettings?.orderSettings.deliveryOnSunday
      ? getLatestSelectedDay(orderForm.selectedDays)
      : getLatestSelectedWhichIsNotWeekendDay(orderForm, formSettings);

  const numericWeekendDay = weekendDay === 'saturday' ? 6 : 7;

  if (firstDay && lastDay && orderForm.selectedDays?.length > 0) {
    let days = [];

    let day = firstDay;

    while (day <= lastDay) {
      if (
        DateTime.fromMillis(day).weekday === numericWeekendDay &&
        !isDayNotDeliverableLogic(
          DateTime.fromMillis(day),
          formSettings,
          isNotEditableEnabled
        )
      ) {
        days.push(day);
      }

      day = DateTime.fromMillis(day).plus({ days: +1 }).toMillis();
    }

    return days;
  }

  return [];
};

const getFilteredDays = (
  orderForm: OrderFormT,
  formSettings?: FormSettingsT
) => {
  if (!orderForm.selectedDays) {
    return [];
  }

  if (
    (!formSettings?.orderSettings.deliveryOnSaturday || !orderForm.saturdays) &&
    (!formSettings?.orderSettings.deliveryOnSunday || !orderForm.sundays)
  ) {
    return orderForm.selectedDays.filter(day => {
      return (
        DateTime.fromMillis(day).weekday !== 6 &&
        DateTime.fromMillis(day).weekday !== 7
      );
    });
  }

  if (!formSettings?.orderSettings.deliveryOnSaturday || !orderForm.saturdays) {
    return orderForm.selectedDays.filter(day => {
      return DateTime.fromMillis(day).weekday !== 6;
    });
  }

  if (!formSettings?.orderSettings.deliveryOnSunday || !orderForm.sundays) {
    return orderForm.selectedDays.filter(day => {
      return DateTime.fromMillis(day).weekday !== 7;
    });
  }

  return orderForm.selectedDays.filter(day => {
    return DateTime.fromMillis(day).weekday !== 7;
  });
};

export const toggleWeekendDays =
  (weekendDay: 'saturday' | 'sunday', isNotEditableEnabled = false) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const orderForm = getOrderForm(getState());

    const { data: formSettings } = await dispatch(
      //TODO: TS
      //@ts-expect-error
      formApi.endpoints.getApiFormSettings.initiate()
    );

    // filtering weekend days
    const filteredSaturdays = getFilteredWeekendDays(
      'saturday',
      orderForm,
      formSettings,
      isNotEditableEnabled
    );

    const filteredSundays = getFilteredWeekendDays(
      'sunday',
      orderForm,
      formSettings,
      isNotEditableEnabled
    );

    if (
      (!formSettings?.orderSettings.deliveryOnSaturday ||
        !orderForm.saturdays) &&
      (!formSettings?.orderSettings.deliveryOnSunday || !orderForm.sundays) &&
      orderForm.selectedDays.length > 0
    ) {
      dispatch(setOrderDays(getFilteredDays(orderForm, formSettings).length));

      dispatch(
        storeFilteredWeekends([...filteredSaturdays, ...filteredSundays])
      );

      return dispatch(
        selectOrderDays(getFilteredDays(orderForm, formSettings))
      );
    }

    if (
      formSettings?.orderSettings.deliveryOnSaturday &&
      orderForm.saturdays &&
      formSettings?.orderSettings.deliveryOnSunday &&
      orderForm.sundays &&
      orderForm.selectedDays.length > 0
    ) {
      const combinedDays = [
        ...orderForm.selectedDays,
        ...orderForm.filteredWeekends,
      ];

      dispatch(setOrderDays(combinedDays.length));

      return dispatch(selectOrderDays(combinedDays));
    }

    if (weekendDay === 'saturday') {
      if (
        (!formSettings?.orderSettings.deliveryOnSaturday ||
          !orderForm.saturdays) &&
        orderForm.selectedDays.length > 0
      ) {
        // its neccessary for the days in the input to match selectedDays
        dispatch(setOrderDays(getFilteredDays(orderForm, formSettings).length));

        dispatch(storeFilteredWeekends([...filteredSaturdays]));

        return dispatch(
          selectOrderDays(getFilteredDays(orderForm, formSettings))
        );
      }

      if (
        formSettings?.orderSettings.deliveryOnSaturday &&
        orderForm.saturdays &&
        orderForm.selectedDays.length > 0
      ) {
        dispatch(
          setOrderDays(orderForm.selectedDays.length + filteredSaturdays.length)
        );

        return dispatch(
          selectOrderDays([...orderForm.selectedDays, ...filteredSaturdays])
        );
      }
    }

    if (weekendDay === 'sunday') {
      if (
        (!formSettings?.orderSettings.deliveryOnSunday || !orderForm.sundays) &&
        orderForm.selectedDays.length > 0
      ) {
        dispatch(setOrderDays(getFilteredDays(orderForm, formSettings).length));

        dispatch(storeFilteredWeekends([...filteredSundays]));

        return dispatch(
          selectOrderDays(getFilteredDays(orderForm, formSettings))
        );
      }

      if (
        formSettings?.orderSettings.deliveryOnSunday &&
        orderForm.sundays &&
        orderForm.selectedDays.length > 0
      ) {
        dispatch(
          setOrderDays(orderForm.selectedDays.length + filteredSundays.length)
        );

        return dispatch(
          selectOrderDays([...orderForm.selectedDays, ...filteredSundays])
        );
      }
    }

    return null;
  };
