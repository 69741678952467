export enum modalErrorPresentationE {
  Modal = 'MODAL',
  Toast = 'TOAST',
  Validation = 'VALIDATION',
}

export type modalErrorSliceInitialStateT = {
  isLoading: boolean;
  message: string;
  presentation: modalErrorPresentationE;
  showModal: boolean;
};
