import React from 'react';
import PropTypes from 'prop-types';
import { classNamesUtil } from '../../utils/classNamesUtil.util';

export const Checkbox = ({
  checked,
  containerClass,
  disabled,
  id,
  label,
  name,
  onChange,
  readOnly,
  value,
}) => {
  return (
    <div
      className={classNamesUtil('checkbox', {
        [`${containerClass}`]: !!containerClass,
      })}
    >
      <input
        checked={checked}
        className={classNamesUtil({ checkbox__disabled: disabled })}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        readOnly={readOnly}
        type="checkbox"
        value={value}
      />
      <label className="label-checkbox" htmlFor={id} id={id}>
        {label}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  containerClass: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
};

export default Checkbox;
