import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

type Props = {
  clearValidationStatus: () => void;
  setShowMessagesFixed: (b: boolean) => void;
  validationStatus: {
    message: string;
    value: string;
  };
};

const ValidationMessage = ({
  clearValidationStatus,
  setShowMessagesFixed,
  validationStatus,
}: Props) => {
  return (
    <div className="caution-message caution-message--error">
      <FontAwesomeIcon icon={faExclamationCircle} />

      <div className="color-error">{validationStatus.message}</div>

      <FontAwesomeIcon
        className="caution-message-fixed__close-icon"
        color="#8FA1B3"
        icon={faTimes}
        onClick={() => {
          clearValidationStatus();
          setShowMessagesFixed(false);
        }}
        onKeyDown={() => {
          clearValidationStatus();
          setShowMessagesFixed(false);
        }}
        role="button"
        tabIndex={0}
      />
    </div>
  );
};

export default ValidationMessage;
