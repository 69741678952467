import { useState } from 'react';

import { addAdditionalFeeToLocalStorage } from '@features/orderForm/stepShoppingCart/utils/stepShoppingCart.util';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Swiper, SwiperSlide } from 'swiper/react';
import { OneOffWithoutAmountT } from 'typesRoot/sideOrder.type';
import useSalesManagoUpdateOneOffs from '@salesManago/services/hooks/useSalesManagoUpdateOneOffs';
import OneOffDetailsModal from '@features/orderForm/stepShoppingCart/views/oneOffs/OneOffDetailsModal';
import { addOneOff } from '@features/orderForm/stepShoppingCart/redux/oneOffSideOrders.slice';
import Button from '@components/common/Button';
import { classNamesUtil } from '@dietly/design-system-library';
import placeholderUrl from '@assets/images/placeholder.jpg';
import { useAppDispatch } from '@config/hooks';

type Props = {
  availableFees: Array<OneOffWithoutAmountT>;
};

type SwiperElement = {
  slideNext: () => void;
  slidePrev: () => void;
  activeIndex: number;
  realIndex: number;
} | null;

const OneOffsCarousel = ({ availableFees }: Props) => {
  const dispatch = useAppDispatch();

  const [swiper, setSwiper] = useState<SwiperElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOneOff, setSelectedOneOff] =
    useState<OneOffWithoutAmountT | null>(null);

  const { salesManagoAddOneOff } = useSalesManagoUpdateOneOffs();
  const goNextSlide = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrevSlide = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };


  const handleAddOneOff = (oneOff: OneOffWithoutAmountT) => {
    const oneOffToAdd = { ...oneOff, amount: oneOff.minQuantity || 1 };
    dispatch(addOneOff(oneOffToAdd));
    addAdditionalFeeToLocalStorage(oneOffToAdd);

    salesManagoAddOneOff(oneOffToAdd);
  };

  return (
    <>
      {isModalOpen && selectedOneOff && (
        <OneOffDetailsModal
          isModalOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSelect={() => handleAddOneOff(selectedOneOff)}
          selectedOneOff={selectedOneOff}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      <section className="product-carousel">
        <div className="display-flex align-items-center justify-content-space-between spacer-bottom-16">
          <h3 className="label-l">
            Produkty, które możesz dodać do zamówienia
          </h3>
          <div className="product-carousel__buttons">
            <Button
              colorBlank
              containerClass="spacer-left-32"
              label={<FontAwesomeIcon icon={faArrowLeft} />}
              onClick={() => goPrevSlide()}
              sizeMedium
            />
            <Button
              colorBlank
              containerClass="spacer-left-4"
              label={<FontAwesomeIcon icon={faArrowRight} />}
              onClick={() => goNextSlide()}
              sizeMedium
            />
          </div>
        </div>
        <Swiper
          breakpoints={{
            0: {
              slidesPerView: 'auto',
            },
          }}
          longSwipesRatio={0.2}
          onSwiper={setSwiper}
          spaceBetween={16}
          threshold={20}
        >
          {availableFees.map(oneOff => {
            const { defaultPrice, imageUrl, name, possibleSideOrderId } =
              oneOff;

            return (
              <SwiperSlide
                className="product-carousel__product"
                key={possibleSideOrderId}
              >
                <div>
                  <div
                    className={classNamesUtil('product-carousel__image', {
                      'product-carousel__image--empty': !imageUrl,
                    })}
                  >
                    <img alt={name} src={imageUrl || placeholderUrl} />
                  </div>
                  <span className="product-carousel__info-icon">
                    <FontAwesomeIcon
                      className="font-size-20 "
                      icon={faInfoCircle}
                      onClick={() => {
                        setSelectedOneOff(oneOff);
                        setIsModalOpen(true);
                      }}
                    />
                  </span>
                  <h6 className="product-carousel__product-name">{name}</h6>
                  <span className="body-s">{defaultPrice?.toFixed(2)} zł</span>
                </div>
                <Button
                  blockButton
                  colorClean
                  containerClass="color-primary"
                  icon={<FontAwesomeIcon icon={faPlus} />}
                  label="Dodaj do zamówienia"
                  onClick={() => handleAddOneOff(oneOff)}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </section>
    </>
  );
};

export default OneOffsCarousel;
