import React from 'react';
import PropTypes from 'prop-types';
import BoxStep from '../../../common/BoxStep';
import { getServerConfig } from '../../../../features/general/generalSlice';
import ComboboxCitiesOrder from '../../../common/search/ComboboxCitiesOrder';
import {
  useGetApiTopCitiesQuery,
  useLazyGetApiCityQuery,
} from '../../../../services/api/form/open/open.api';
import { useAppSelector } from '../../../../config/hooks';
import { classNamesUtil } from '../../../../utils/classNamesUtil.util';

const SelectCity = ({ city, handleSelectCity, setSelectedValue }) => {
  const { companyId, isPopularCitiesVisible } = useAppSelector(getServerConfig);

  const { data: topCities } = useGetApiTopCitiesQuery(companyId, {
    skip: !companyId,
  });

  const [getApiCity] = useLazyGetApiCityQuery();

  return (
    <BoxStep checkIcon title="Wybierz miasto">
      <div className="steps-page__search">
        <ComboboxCitiesOrder
          companyName={companyId}
          initialSelectedItem={city}
          onSelect={item => {
            setSelectedValue(item);
            handleSelectCity(item);
          }}
        />
      </div>
      {isPopularCitiesVisible && !!topCities?.length && (
        <>
          <p className="label-l">Popularne miasta</p>
          <ul className="steps-page__list steps-page__list--flex steps-page__list--small-margin-top">
            {topCities?.map(topCity => (
              <li
                className={classNamesUtil('steps-page__item cypress-city', {
                  'steps-page__item--active': topCity?.cityId === city?.cityId,
                })}
                key={topCity?.cityId}
                onClick={async () => {
                  const res = await getApiCity(topCity?.cityId, true);

                  const city = res?.data?.[0];

                  if (city) {
                    handleSelectCity(city);
                  }
                }}
              >
                {topCity?.name}
              </li>
            ))}
          </ul>
        </>
      )}
    </BoxStep>
  );
};

SelectCity.propTypes = {
  city: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default SelectCity;
