import * as actionTypes from '../actions/actionTypes';

export const sideOrdersReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.STORE_SIDE_ORDERS:
      return action.sideOrders.map(item => ({ ...item, amount: 0 }));
    default:
      return state;
  }
};
