import React, { useEffect, useMemo, useState } from 'react';
import ProgressBar from '../components/common/ProgressBar';
import useIsWide from '../hooks/useIsWide';
import {
  getBasket,
  getBasketEdition,
  getPromoCode,
  getTestOrderNumberOfSetsValidation,
} from '../redux/selectors/basketSelector';
import EmptyBasket from '../components/pages/basket/components/EmptyBasket';
import { storeBasket } from '../redux/actions/basketActions';
import { getSelectedCity } from '../redux/selectors/summaryReducer.selectors';
import { useNavigate } from 'react-router-dom';
import {
  resetOrderForm,
  restoreInitialOrderForm,
  updateOrderFormReducer,
} from '../redux/actions/orderFormActions';
import { clearBasketLocalStorage } from '../utils';
import { storeHistory } from '../redux/actions/orderFormHistoryActions';
import Navigation from '../components/common/navigation';
import { getEnhancedEcommerceCartStep } from '../utils/enhanceEcommerce';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { getServerConfig } from '../features/general/generalSlice';
import { useEffectOnce } from 'react-use';
import BasketSummaryBar from '../components/pages/basket/components/BasketSummaryBar';
import { useEnhancedEcommercePageInfo } from '../hooks/useEnhancedEcommercePageInfo';
import { useAppDispatch, useAppSelector } from '../config/hooks';
import BackButton from '../features/orderForm/stepShoppingCart/views/BackButton';
import ShoppingCartContent from '../features/orderForm/stepShoppingCart/views/ShoppingCartContent';
import ShoppingCartSummary from '../features/orderForm/stepShoppingCart/views/ShoppingCartSummary';
import { userComCheckoutProductEvent } from '../userCom/productEvents/checkout';
import { checkIfUserComIsEnabled } from '@userCom/utilis/checkIfUserComIsEnabled';

const StepShoppingCart = () => {
  const navigate = useNavigate();
  const sendDataToGTM = useGTMDispatch();
  const isWide = useIsWide();

  const dispatch = useAppDispatch();

  //Selectors
  const basket = useAppSelector(getBasket);
  const promoCode = useAppSelector(getPromoCode);
  const edition = useAppSelector(getBasketEdition);
  const selectedCity = useAppSelector(getSelectedCity);
  const testOrderNumberOfSetsValidation = useAppSelector(
    getTestOrderNumberOfSetsValidation
  );
  const { companyId, enhancedEcommerce, userCom } =
    useAppSelector(getServerConfig);

  //Destructuring
  const { code } = promoCode || {};
  const { isEdited, step } = edition || {};

  const [nextStepIcon, setNextStepIcon] = useState(false);

  const { activeDietlyPay, promoCodeType } = code || {};

  //Remove params from url
  useEffect(() => {
    const search = window.location.search;
    const origin = window.location.origin;
    const pathname = window.location.pathname;

    if (search) {
      const url = `${origin + pathname}#/koszyk`;

      window.history.pushState({}, document.title, url);
    }
  }, []);

  useEffect(() => {
    const storageBasket = localStorage.getItem('diet-basket-form');
    const parsedStorageBasket = storageBasket && JSON.parse(storageBasket);

    if (parsedStorageBasket && parsedStorageBasket.length) {
      const basketName = parsedStorageBasket[0]?.companyName;

      if (basketName && basketName !== companyId) {
        clearBasketLocalStorage();
        dispatch(resetOrderForm());
        dispatch(restoreInitialOrderForm());
      }
      if (basket?.length === 0) {
        dispatch(storeBasket(parsedStorageBasket));
      }
    }
  }, [basket?.length, companyId, dispatch]);

  useEffect(() => {
    if (basket?.length > 0) {
      localStorage.setItem('diet-basket-form', JSON.stringify(basket));
    }
  }, [basket]);

  useEffect(() => {
    dispatch(storeHistory(3));
  }, [dispatch]);

  useEffect(() => {
    if (isEdited && basket?.length > 0) {
      const fullorder =
        basket &&
        basket?.find(
          (order: { orderId: string }) => order?.orderId === isEdited
        );
      const { orderId, selectedDays, ...rest } = fullorder || {};
      const order = { ...rest, selectedDays, days: selectedDays?.length };

      dispatch(updateOrderFormReducer(order));
      if (step === 1) {
        navigate('/', { replace: true });
      }
      if (step === 2) {
        navigate('/zamowienie', { replace: true });
      }
      if (step === 3) {
        navigate('/wybierz-posilki', { replace: true });
      }
    }
  }, [basket, dispatch, navigate, isEdited, step]);

  useEffect(() => {
    if (enhancedEcommerce && companyId && basket?.length) {
      setTimeout(() => {
        sendDataToGTM(
          getEnhancedEcommerceCartStep(
            basket,
            selectedCity,
            code,
            1,
            companyId
          ) as object
        );
      });
    }
  }, [basket, code, companyId, enhancedEcommerce, selectedCity, sendDataToGTM]);

  useEffectOnce(() => {
    dispatch(resetOrderForm());
    dispatch(restoreInitialOrderForm());
  });

  const { canSend, sendEvent } = useEnhancedEcommercePageInfo();
  useEffect(() => {
    canSend && sendEvent();
  }, [canSend, sendEvent]);

  //store data to prevent double event on re-render, when basket is not yet updated
  const basketDataForUserComEvent = useMemo(() => {
    if (basket.length > 0) {
      return basket;
    }
    return null;
  }, [basket]);

  useEffect(() => {
    if (basketDataForUserComEvent && checkIfUserComIsEnabled(userCom)) {
      userComCheckoutProductEvent(basketDataForUserComEvent, userCom);
    }
  }, [basketDataForUserComEvent, userCom]);

  return (
    <>
      <Navigation />
      <ProgressBar />
      {!basket?.length ? (
        <EmptyBasket />
      ) : (
        <div className="steps-page">
          <div className="steps-page__container">
            <BackButton />
            <div className="steps-page-basket">
              <ShoppingCartContent />
              <ShoppingCartSummary
                nextStepIcon={nextStepIcon}
                setNextStepIcon={setNextStepIcon}
              />
            </div>
          </div>
        </div>
      )}
      {isWide && !!basket?.length && (
        <BasketSummaryBar
          activeDietlyPay={activeDietlyPay}
          basket={basket}
          nextStepIcon={nextStepIcon}
          promoCodeType={promoCodeType}
          selectedCity={selectedCity}
          setNextStepIcon={setNextStepIcon}
          testOrderNumberOfSetsValidation={testOrderNumberOfSetsValidation}
        />
      )}
    </>
  );
};

export default StepShoppingCart;
