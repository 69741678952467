export const defaultLoyaltyProgramData = {
  agreements: {
    text1: ' (i zgarnij dodatkowe 500 punktów)',
  },
  basket: {
    text1: 'pkt do skarbonki',
    text2:
      'Skarbonka to nasz program lojalnościowy, dzięki któremu będziesz mógł w dowolnym momencie wymienić punkty na dodatkowe usługi lub skorzystać z nich jako "wirtualnej waluty" do uzyskania zniżki za zamówienie.',
  },
  loyaltyPoints: {
    text1: 'punktów skarbonki',
    text2: 'W skarbonce pozostało',
    text3: 'Twoja skarbonka',
    text4: 'pkt',
    text5: 'punktów',
    text6: 'Nie masz wystarczającej liczby punktów w skarbonce',
  },
  modalAreYouSureWithoutRegister: {
    text1: 'punktów lojalnościowych.',
  },
  orderFormSummaryPrices: {
    text1: 'Skarbonka',
  },
  registerConfirmation: {
    text1: 'pkt do skarbonki',
  },
};

export const burakLoyaltyProgramData = {
  agreements: {
    text1: ' (i zgarnij dodatkowe 500 Burak Coin)',
  },
  basket: {
    text1: 'Burak Coin',
    text2:
      'Burak Coin to wirtualna waluta Burak Dieta. Burak Coin możesz w dowolnym momencie wymienić na dodatkowe usługi lub skorzystać z nich jako "wirtualnej waluty" do uzyskania zniżki za zamówienie.',
  },
  loyaltyPoints: {
    text1: 'Burak Coin',
    text2: 'Pozostało',
    text3: 'Burak Coin',
    text4: 'BC',
    text5: 'Burak Coin',
    text6: 'Nie masz wystarczającej liczby Burak Coin',
  },
  modalAreYouSureWithoutRegister: {
    text1: 'Burak Coin.',
  },
  orderFormSummaryPrices: {
    text1: 'Burak Coin',
  },
  registerConfirmation: {
    text1: 'Burak Coin',
  },
};
