import React from 'react';
import { OrderFormT } from 'typesRoot/orderForm.type';
import { FormSettingsT } from 'typesRoot/settings.type';
import { useAppDispatch } from '@config/hooks';
import {
  mealExchangeChangeEditModalClickedArea,
  resetCalendar,
  setDefaultDays,
} from '@redux/actions/orderFormActions';
import { checkIsDayInMealExchange } from 'utilsRoot/index';
import Button from '../../../common/Button';
import { CustomDeliveryMealsT } from '@features/orderForm/stepMenuConfiguration/types/menuConf.type';

type Props = {
  customDeliveryMeals: CustomDeliveryMealsT;
  formSettings?: FormSettingsT;
  orderForm: OrderFormT;
  selectedProgram: any;
};

const UnselectAll = ({
  customDeliveryMeals,
  formSettings,
  orderForm,
  selectedProgram,
}: Props) => {
  const dispatch = useAppDispatch();

  return (
    <Button
      buttonLink
      label="Odznacz wszystkie dni"
      onClick={() => {
        if (
          selectedProgram === 'menuConfiguration' &&
          checkIsDayInMealExchange(
            orderForm?.selectedDays,
            customDeliveryMeals,
            true
          )
        ) {
          dispatch(
            mealExchangeChangeEditModalClickedArea(
              'secondStepEditMealExchangeRemoveAllDays'
            )
          );
        } else {
          dispatch(resetCalendar());
          dispatch(
            setDefaultDays(
              orderForm.testOrder
                ? formSettings?.testOrderSettings?.minimumDays || 1
                : formSettings?.orderSettings?.defaultDaySuggestion || 10
            )
          );
        }
      }}
    />
  );
};

export default UnselectAll;
