import { useAppSelector } from '@config/hooks';
import { getBasket } from '@redux/selectors/basketSelector';
import RecalculatePriceLoader from '../RecalculatePriceLoader';
import useCalculateCartPrice from '../../../../../services/hook/calculatePrices/useCalculateCartPrice.hook';
import { BasketItemT } from 'typesRoot/basketItem.type';
import { OrderSummaryItem } from '@components/pages/StepSummary/views/OrderSummaryItem';
import React from 'react';

const YourOrderInfo = () => {
  const { isError, isFetching, prices } = useCalculateCartPrice();

  const basket = useAppSelector(getBasket);

  return (
    <>
      <RecalculatePriceLoader isFetching={isFetching || isError} />

      {!!prices &&
        !!basket.length &&
        basket.map((item: BasketItemT) => {
          const price = prices.items[item.orderId];
          return (
            <OrderSummaryItem
              item={item}
              itemDietPrice={price?.totalDietWithoutSideOrdersCost || 0}
              itemDietPricePerDay={price?.perDayDietCost || 0}
              itemSideOrdersPrice={price?.totalSideOrdersCost || 0}
              key={item.orderId}
            />
          );
        })}
    </>
  );
};

export default YourOrderInfo;
