import { createSlice } from '@reduxjs/toolkit';
import { calculateCalories } from '../../components/pages/firstStep/components/dietCalculator/helpers/dietCalculatorHelpers';
import { RootState } from '../../config/hooks';

type InitialStateT = {
  isModalOpen: boolean;
  age: string;
  dietPreference: string;
  goal: string;
  height: string;
  physicalActivity: string;
  sex: string;
  step: number;
  weight: string;
  calorie: number;
};

const initialState: InitialStateT = {
  isModalOpen: false,
  age: '',
  dietPreference: '',
  goal: '',
  height: '',
  physicalActivity: '',
  sex: '',
  step: 1,
  weight: '',
  calorie: 0,
};

const dietCalculatorSlice = createSlice({
  name: 'dietCalculator',
  initialState,
  reducers: {
    changeIsModalOpen: (state, { payload }) => {
      state.isModalOpen = payload;
    },
    changeCalculatorValue: (state, { payload }) => {
      const { name, value } = payload;
      // TODO
      // @ts-ignore
      state[name] = value;
    },
    changeCalculatorStep: (state, { payload }) => {
      state.step = payload;
    },
    finishCalculator: state => {
      const { age, goal, height, physicalActivity, sex, weight } = state;

      state.calorie = calculateCalories(
        age,
        goal,
        height,
        physicalActivity,
        sex,
        weight
      );

      state.step = 5;
    },
  },
});

// reducer
export default dietCalculatorSlice.reducer;

// actions
export const {
  changeCalculatorStep,
  changeCalculatorValue,
  changeIsModalOpen,
  finishCalculator,
} = dietCalculatorSlice.actions;

export const getDietCalculatorReducer = (state: RootState) =>
  state.dietCalculator;
