import React, { useEffect } from 'react';
import Navigation from '../components/common/navigation';
import ProgressBar from '../components/common/ProgressBar';
import { getSelectedDays } from '@redux/selectors/orderFormSelector';
import { useEffectOnce } from 'react-use';
import { storeCity } from '@redux/actions/orderFormActions';
import { getServerConfig } from '@features/general/generalSlice';
import MealErrorModal from '../components/pages/mealExchangeStep/components/mealErrorModal';
import { useNavigate } from 'react-router-dom';
import SummaryBar from '@features/orderForm/stepMenuConfiguration/views/SummaryBar';
import { getSelectedCity } from '@redux/selectors/summaryReducer.selectors';
import { useEnhancedEcommercePageInfo } from '@hooks/useEnhancedEcommercePageInfo';
import { useAppDispatch, useAppSelector } from '@config/hooks';
import OrderFormCarouselCalendar from '@features/orderForm/stepMenuConfiguration/views/OrderFormCarouselCalendar';
import MenuConfiguration from '@features/orderForm/stepMenuConfiguration/views/MenuConfiguration';

const StepMenuConfiguration = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selectedCity = useAppSelector(getSelectedCity);
  const selectedDays = useAppSelector(getSelectedDays);
  const serverConfig = useAppSelector(getServerConfig) || {};

  const { companyId } = serverConfig || {};

  useEffectOnce(() => {
    dispatch(storeCity(selectedCity?.sectorId, selectedCity?.name));
  });

  const { canSend, sendEvent } = useEnhancedEcommercePageInfo();

  useEffect(() => {
    canSend && sendEvent();
  }, [canSend, sendEvent]);

  useEffect(() => {
    if (!selectedDays.length) {
      navigate('/', { replace: true });
    }
  }, [navigate, selectedDays.length]);
  return (
    <>
      <Navigation />
      <ProgressBar />
      <MealErrorModal />

      <OrderFormCarouselCalendar companyId={companyId} />

      <MenuConfiguration companyId={companyId} />
      <SummaryBar />
    </>
  );
};

export default StepMenuConfiguration;
