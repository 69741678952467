import React, { Dispatch, SetStateAction } from 'react';
import Button from '../../../common/Button';
import CalendarDay from './CalendarDay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowCircleLeft,
  faArrowCircleRight,
} from '@fortawesome/pro-regular-svg-icons';
import { Info } from 'luxon';
import { changeMonth } from '../utils/calendar.utils';
import { classNamesUtil } from '../../../../utils/classNamesUtil.util';

type Props = {
  baseDate: any;
  calendarPosition: any;
  offset: number;
  setOffset: Dispatch<SetStateAction<number>>;
  setSelectedValue: Dispatch<SetStateAction<number>>;
  validation: boolean;
};

const CalendarBase = ({
  baseDate,
  calendarPosition,
  offset,
  setOffset,
  setSelectedValue,
  validation,
}: Props) => {
  const monthStart = baseDate.startOf('month');
  const monthEnd = baseDate.endOf('month');
  const displayStart = monthStart.startOf('week');
  const displayEnd = monthEnd.endOf('week').plus({ day: 1 }).startOf('day');
  const displayedWeeksNumber = displayEnd.diff(displayStart, 'weeks').weeks;

  return (
    <div
      className={`calendar__container ${
        validation ? 'calendar__container--error' : ''
      }`}
    >
      <div className="calendar__top-header">
        <div
          className={classNamesUtil('calendar__arrow calendar__arrow-left', {
            'hidden-up-xl': calendarPosition === 'right',
          })}
        >
          <Button
            colorClean
            containerClass="cursor-pointer"
            icon={<FontAwesomeIcon icon={faArrowCircleLeft} />}
            onClick={() => changeMonth('backward', setOffset, offset)}
          />
        </div>
        <div className="calendar__top-header-title">
          {baseDate.setLocale('pl-PL').toLocaleString({ month: 'long' })}{' '}
          {baseDate.setLocale('pl-PL').toLocaleString({ year: 'numeric' })}
        </div>
        <div
          className={classNamesUtil('calendar__arrow calendar__arrow-right', {
            'hidden-up-xl': calendarPosition === 'left',
          })}
        >
          <Button
            colorClean
            containerClass="cursor-pointer"
            icon={<FontAwesomeIcon icon={faArrowCircleRight} />}
            onClick={() => changeMonth('forward', setOffset, offset)}
          />
        </div>
      </div>
      <div className="calendar__header">
        {Info.weekdays('short', { locale: 'pl' }).map(weekday => (
          <div className="calendar__day-header" key={weekday}>
            {weekday}
          </div>
        ))}
      </div>

      {/* Days board */}
      {displayedWeeksNumber &&
        displayStart &&
        // @ts-ignore
        [...Array(displayedWeeksNumber).keys()].map(weekNum => {
          const weekFirstDay = displayStart.plus({ weeks: weekNum });

          return (
            <div className="calendar__week" key={weekNum}>
              {/*@ts-ignore*/}
              {[...Array(7).keys()].map(dayNum => {
                const day = weekFirstDay.plus({ days: dayNum });
                return baseDate.month !== day.month ? (
                  <div
                    className="calendar__day calendar__day--empty"
                    key={dayNum}
                  />
                ) : (
                  <CalendarDay
                    baseDate={baseDate}
                    day={day}
                    key={dayNum}
                    setSelectedValue={setSelectedValue}
                  />
                );
              })}
            </div>
          );
        })}
    </div>
  );
};

export default CalendarBase;
