import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import {
  getFinalSumAfterDiscountAndDeliveryFee,
  getPdfFile,
  getPromoCode,
} from '@redux/selectors/basketSelector';
import {
  getAddressDetails,
  getNewClientDetails,
  getSummaryReducer,
} from '@redux/selectors/summaryReducer.selectors';
import BoxStep from '../../../common/BoxStep';
import * as yup from 'yup';
import Button from '../../../common/Button';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { faSyncAlt } from '@fortawesome/pro-light-svg-icons/faSyncAlt';
import {
  displayOrderButton,
  handlePlacePanelOrder,
  parseDeliveryTime,
} from '../utils/thirdStepHelpers.utils';
import {
  changeDeliveryMethod,
  changePaymentType,
  selectOrigin,
} from '@redux/actions/summaryActions';
import { Agreements } from './Agreements';
import CustomerData from './NotLoggedIn/CustomerData';
import DeliveryDetails from './NotLoggedIn/DeliveryDetails';
import { Select } from '../../../common/select';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import PaymentMethods from './PaymentMethods';
import Loan from './Loan';
import { getServerConfig } from '@features/general/generalSlice';
import PhoneNumberConfirmationModal from './NotLoggedIn/PhoneNumberConfirmationModal';
import { useEffectOnce } from 'react-use';
import { DeliveryMethodEnum } from '@features/pickupPoint/types/deliveryMethod.enum';
import { getModalErrorLoading } from '@features/modalError/services/redux/modalError.slice';
import Invoice from './Invoice';
import {
  initialPhoneNumberValues,
  phoneNumberErrorText,
  validatePhoneNumber,
} from '../utils/prefix.utils';
import {
  useGetApiFormSettingsQuery,
  useGetApiOriginsQuery,
} from '@services/api/form/open/open.api';
import { useAppSelector } from '@config/hooks';
import { classNamesUtil } from 'utilsRoot/classNamesUtil.util';
import SummaryPrices from '../../../../features/orderForm/stepSummary/views/summaryPrices/SummaryPrices';
import YourOrderInfo from '../../../../features/orderForm/stepSummary/views/yourOrderInfo/YourOrderInfo';
import { ScopeTypeE } from 'typesRoot/discount.enum';
import { getTemporarilyDisableOrder } from '@redux/slices/serverOverload.slice';
import useCalculateCartPrice from '@services/hook/calculatePrices/useCalculateCartPrice.hook';

type Props = {
  companyId: string;
  paymentTypes: Array<string>;
};

const NotLoggedInSummary = ({ companyId, paymentTypes }: Props) => {
  const hoursRef = useRef() as any;
  const [whereAboutCatering, setWhereAboutCatering] = useState('');
  const [activePayment, setActivePayment] = useState('ONLINE');
  const [copyCompanyData, setCopyCompanyData] = useState(false);
  const [
    isPhoneNumberConfirmationModalOpen,
    setIsPhoneNumberConfirmationModalOpen,
  ] = useState(false);
  const [selectedPrefix, setSelectedPrefix] = useState(
    initialPhoneNumberValues.phoneNumberPrefix
  );

  const navigate = useNavigate();
  const sendDataToGTM = useGTMDispatch();
  const dispatch = useDispatch();
  const {
    data: { additionalTexts, companyName: compName = '', orderSettings } = {},
  } = useGetApiFormSettingsQuery(undefined, {
    skip: !companyId,
  });

  const config = useAppSelector(getServerConfig);
  const { code: promoCode } = useAppSelector(getPromoCode);
  const summaryReducer = useAppSelector(getSummaryReducer);
  const newAddressDetails = useAppSelector(getAddressDetails);
  const newClientDetails = useAppSelector(getNewClientDetails);
  const finalAmountToPayAfterDiscountsAndDeliveryFee = useAppSelector(
    getFinalSumAfterDiscountAndDeliveryFee
  );
  const { hasFile } = useAppSelector(getPdfFile);
  const isPotentialModalErrorLoading = useAppSelector(getModalErrorLoading);
  const isOrderButtonTemporarilyDisabled = useAppSelector(
    getTemporarilyDisableOrder
  );

  const { isCustomPanel, loan, loginEnabled } = config || {};

  const { data: origins } = useGetApiOriginsQuery(undefined, {
    skip: !companyId,
  });

  const { prices } = useCalculateCartPrice();

  const { deliveryMethod, methodOfPurchase, pickupPointId } =
    summaryReducer || {};

  const {
    company,
    companyApartmentNumber,
    companyBuildingNumber,
    companyCity,
    companyName,
    companyStreet,
    companyZipCode,
    email,
    name,
    password,
    passwordConfirmation,
    phone,
    surname,
    taxId,
    willingToRegister,
  } = newClientDetails || {};

  const {
    apartmentNumber,
    buildingNumber,
    city,
    deliveryHour,
    floor,
    gate,
    gateCode,
    note,
    street,
    zipCode,
  } = newAddressDetails || {};

  const isFirstOrderRegister =
    willingToRegister &&
    promoCode.activeDietlyPay &&
    promoCode.promoCodeType === 'GLOBAL' &&
    promoCode?.scopeTypes?.includes(ScopeTypeE.CLIENT_PHONE_NUMBER_CONFIRMED);

  const shouldDisableOrderButton =
    !displayOrderButton(summaryReducer, isCustomPanel) ||
    isPotentialModalErrorLoading ||
    isOrderButtonTemporarilyDisabled;

  useEffectOnce(() => {
    dispatch(changeDeliveryMethod(DeliveryMethodEnum.N));
  });

  const handleSubmit = () => {
    dispatch(handlePlacePanelOrder(sendDataToGTM, navigate, prices));
  };

  const deliveryTimesOptions = city?.deliveryTime?.length
    ? parseDeliveryTime(city.deliveryTime)
    : [];

  const isDeliveryHoursSelectionMandatory =
    deliveryMethod !== DeliveryMethodEnum.P &&
    !!deliveryTimesOptions.length &&
    orderSettings?.obligatoryHourPreference;

  return (
    <>
      {isFirstOrderRegister && isPhoneNumberConfirmationModalOpen && (
        <PhoneNumberConfirmationModal
          companyName={compName}
          isPhoneNumberConfirmationModalOpen={
            isPhoneNumberConfirmationModalOpen
          }
          phoneNumber={phone}
          promoCode={promoCode}
          setIsPhoneNumberConfirmationModalOpen={
            setIsPhoneNumberConfirmationModalOpen
          }
          submitForm={handleSubmit}
        />
      )}

      <Formik
        enableReinitialize
        initialValues={{
          name: name || '',
          surname: surname || '',
          deliveryMethod: deliveryMethod || '',
          email: email || '',
          phone: phone || '',
          company: company || '',
          taxId: taxId || '',
          companyName: companyName || '',
          willingToRegister: willingToRegister || '',
          password: password || '',
          passwordConfirmation: passwordConfirmation || '',
          pickupPointId: pickupPointId || '',
          formikCity: city.name || '',
          formikZipCode: zipCode.code || '',
          street: street || '',
          buildingNumber: buildingNumber || '',
          apartmentNumber: apartmentNumber || '',
          gate: gate || '',
          floor: floor || '',
          gateCode: gateCode || '',
          deliveryHour: deliveryHour || '',
          note: note || '',
          companyStreet: companyStreet || '',
          companyCity: companyCity || '',
          companyZipCode: companyZipCode || '',
          companyApartmentNumber: companyApartmentNumber || '',
          companyBuildingNumber: companyBuildingNumber || '',
        }}
        onSubmit={handleSubmit}
        render={({ errors, handleChange, handleSubmit, touched }) => (
          <form
            action="https://provemacredit.pl/wniosek_partner"
            id="lbl-loan-form"
            method="POST"
            onSubmit={handleSubmit}
          >
            <div className="steps-page-third">
              <div className="steps-page-third__container">
                <CustomerData
                  errors={errors}
                  handleChange={handleChange}
                  loginEnabled={loginEnabled}
                  methodOfPurchase={methodOfPurchase}
                  newClientDetails={newClientDetails}
                  setSelectedPrefix={setSelectedPrefix}
                  touched={touched}
                />

                <DeliveryDetails
                  city={city}
                  companyId={companyId}
                  copyCompanyData={copyCompanyData}
                  deliveryMethod={deliveryMethod}
                  deliveryTimesOptions={deliveryTimesOptions}
                  errors={errors}
                  handleChange={handleChange}
                  hoursRef={hoursRef}
                  orderSettings={orderSettings}
                  touched={touched}
                />

                <BoxStep title="Pozostałe">
                  <div className="spacer-top-20">
                    <Invoice
                      apartmentNumber={apartmentNumber}
                      bill={additionalTexts?.bill}
                      buildingNumber={buildingNumber}
                      city={city}
                      company={company}
                      companyApartmentNumber={companyApartmentNumber}
                      companyBuildingNumber={companyBuildingNumber}
                      companyCity={companyCity}
                      companyName={companyName}
                      companyStreet={companyStreet}
                      companyZipCode={companyZipCode}
                      copyCompanyData={copyCompanyData}
                      errors={errors}
                      handleChange={handleChange}
                      setCopyCompanyData={setCopyCompanyData}
                      street={street}
                      taxId={taxId}
                      touched={touched}
                      zipCode={zipCode}
                    />

                    {origins && origins?.length > 0 && (
                      <Select
                        containerClass="steps-page-third__large-input spacer-top-24"
                        id="origin"
                        items={origins}
                        label="Skąd dowiedziałeś się o cateringu"
                        selectedItem={whereAboutCatering}
                        selectedItemKey="name"
                        selectItem={e => {
                          dispatch(selectOrigin(e.originId));
                          setWhereAboutCatering(e);
                        }}
                      />
                    )}
                  </div>
                </BoxStep>

                <Link className="hidden-down-md" to="/koszyk">
                  <Button
                    icon={<FontAwesomeIcon icon={faArrowLeft} />}
                    label="Wstecz"
                  />
                </Link>
              </div>

              <aside className="steps-page__aside">
                <BoxStep borderTitle smallPaddings title="Twoje zamówienie">
                  <YourOrderInfo />
                </BoxStep>

                <BoxStep borderTitle smallPaddings title="Podsumowanie">
                  <SummaryPrices />

                  <div
                    className={classNamesUtil('payment-methods-wrapper', {
                      'payment-methods-wrapper--without-border': hasFile,
                    })}
                  >
                    {!hasFile && (
                      <>
                        {additionalTexts?.payment && (
                          <p className="body-m spacer-bottom-16">
                            {additionalTexts.payment}
                          </p>
                        )}
                        <PaymentMethods
                          activePayment={activePayment}
                          finalAmountToPayAfterDiscountsAndDeliveryFee={
                            finalAmountToPayAfterDiscountsAndDeliveryFee
                          }
                          isLoanEnabled={loan?.isLoanEnabled}
                          partnerId={loan?.partnerId}
                          paymentTypes={paymentTypes}
                          setActivePayment={setActivePayment}
                        />
                      </>
                    )}

                    {loan?.isLoanEnabled &&
                      finalAmountToPayAfterDiscountsAndDeliveryFee > 0 && (
                        <Loan />
                      )}

                    <Agreements loggedInSummary={false} />

                    <Button
                      blockButton
                      disabled={shouldDisableOrderButton}
                      icon={
                        isPotentialModalErrorLoading && (
                          <FontAwesomeIcon icon={faSyncAlt} spin />
                        )
                      }
                      iconRight
                      label={
                        isPotentialModalErrorLoading
                          ? 'Składam zamówienie'
                          : 'Zapłać'
                      }
                      onClick={() => {
                        hasFile
                          ? dispatch(changePaymentType('EXTERNAL_VOUCHER'))
                          : dispatch(changePaymentType(activePayment));

                        if (
                          isDeliveryHoursSelectionMandatory &&
                          orderSettings?.showHourPreference &&
                          !deliveryHour
                        ) {
                          hoursRef?.current?.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                          });
                        }
                      }}
                      sizeMedium
                      type="submit"
                    />
                  </div>
                </BoxStep>
              </aside>
            </div>
          </form>
        )}
        validationSchema={yup.object().shape({
          apartmentNumber: yup
            .string()
            .max(255, 'Numer mieszkania jest za długi'),
          buildingNumber: yup
            .string()
            .required('Numer domu jest wymagany')
            .max(255, 'Numer domu jest za długi'),
          company: yup.boolean(),
          companyApartmentNumber: yup.string().when('company', {
            is: true,
            then: yup.string().max(255, 'Numer mieszkania jest za długi'),
          }),
          companyBuildingNumber: yup.string().when('company', {
            is: true,
            then: yup
              .string()
              .max(255, 'Numer domu jest za długi')
              .required('Numer domu jest wymagany'),
          }),
          companyCity: yup.string().when('company', {
            is: true,
            then: yup
              .string()
              .max(255, 'Nazwa miasta jest za długa')
              .required('Miasto jest wymagane'),
          }),
          companyName: yup.string().when('company', {
            is: true,
            then: yup.string().required('Nazwa firmy jest wymagana'),
          }),
          companyStreet: yup.string().when('company', {
            is: true,
            then: yup
              .string()
              .max(255, 'Numer ulicy jest za długi')
              .required('Ulica jest wymagana'),
          }),
          companyZipCode: yup.string().when('company', {
            is: true,
            then: yup
              .string()
              .max(255, 'Numer kodu pocztowego jest za długi')
              .required('Kod pocztowy jest wymagany'),
          }),
          deliveryHour: isDeliveryHoursSelectionMandatory
            ? yup.string().required('Godzina dostawy jest wymagana')
            : yup.string(),
          email: yup
            .string()
            .required('Email jest wymagany')
            .matches(/^(\S+$)/, 'Adres email nie może zawierać spacji')
            .email('Wpisz poprawny adres email'),
          floor: yup.string().max(10, 'Numer piętra jest za długi'),
          formikCity: yup.string().required('Miasto jest wymagane'),
          formikZipCode: yup.string().required('Kod pocztowy jest wymagany'),
          gate: yup.string().max(10, 'Numer klatki jest za długi'),
          gateCode: yup.string().max(255, 'Numer kodu do klatki jest za długi'),
          name: yup
            .string()
            .required('Imię jest wymagane')
            .max(255, 'Imię jest za długie'),
          note: yup.string().max(500, 'Notatka jest za długa'),
          password: yup.string().when('willingToRegister', {
            is: true,
            then: yup
              .string()
              .required('Hasło jest wymagane')
              .matches(/(?=.*[a-z])/, 'Wymagana przynajmniej 1 mała litera')
              .matches(/(?=.*[A-Z])/, 'Wymagana przynajmniej 1 wielka litera')
              .matches(/(?=.*[0-9])/, 'Wymagana przynajmniej 1 cyfra')
              .matches(/^[\S]+$/, 'Hasło powinno być bez spacji')
              .min(8, 'Hasło jest za krótkie')
              .max(128, 'Hasło jest za długie'),
          }),
          passwordConfirmation: yup.string().when('willingToRegister', {
            is: true,
            then: yup
              .string()
              .oneOf(
                [yup.ref('password'), null],
                'Hasła powinny być takie same'
              )
              .required('Potwierdzenie hasła jest wymagane'),
          }),
          phone: yup
            .number()
            .required('Telefon jest wymagany')
            .test('isValid', phoneNumberErrorText, phone =>
              validatePhoneNumber(selectedPrefix, phone)
            ),
          pickupPointId: yup
            .number()
            .when('deliveryMethod', (deliveryMethod, schema) => {
              return deliveryMethod === DeliveryMethodEnum.P
                ? schema.required('Punkt odbioru jest wymagany')
                : schema;
            }),
          staircase: yup.string().max(10, 'Numer klatki jest za długi'),
          street: yup
            .string()
            .required('Ulica jest wymagana')
            .max(255, 'Nazwa ulicy jest za długa'),
          surname: yup
            .string()
            .required('Nazwisko jest wymagane')
            .max(255, 'Nazwisko jest za długie'),
          taxId: yup.string().when('company', {
            is: true,
            then: yup
              .string()
              .test('nip', 'Wpisz poprawny numer nip', nip => {
                const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
                nip = nip?.replace(/[\s-]/g, '') || '';
                if (nip.length === 10 && parseInt(nip, 10) > 0) {
                  let sum = 0;
                  for (let i = 0; i < 9; i++) {
                    // @ts-ignore
                    sum += nip[i] * weights[i];
                  }
                  return sum % 11 === Number(nip[9]);
                }
                return false;
              })
              .required('Numer nip jest wymagany'),
          }),
          willingToRegister: yup.boolean(),
        })}
      />
    </>
  );
};

export default NotLoggedInSummary;
