import React from 'react';
import Card from '../../../common/Card';
import BoxStep from '../../../common/BoxStep';
import useIsWide from '../../../../hooks/useIsWide';
import { classNamesUtil } from '../../../../utils/classNamesUtil.util';

const VariantStep = ({
  getDietOptions,
  handleSelectOption,
  listHeight,
  optionHeight,
  optionHeightRef,
  readMoreOption,
  selectedDiet,
  selectedOption,
  setListHeight,
  setReadMoreOption,
  variantRef,
}) => {
  const isWide = useIsWide('max', 'desktop');

  return (
    <BoxStep checkIcon ref={variantRef} title="Wybierz wariant">
      <div
        className={classNamesUtil(
          'steps-page__list steps-page__list--variants',
          {
            'steps-page__list--flex': isWide,
            'steps-page__list--grid': !isWide,
          }
        )}
        ref={optionHeightRef}
        style={{ minHeight: listHeight + 8 }}
      >
        {getDietOptions(selectedDiet)?.map(option => {
          const { dietOptionDescription, dietOptionId, dietOptionName } =
            option || {};

          return (
            <Card
              containerClass="diet-card--order-form diet-card--variant-card no-select"
              dietDescription={dietOptionDescription}
              dietName={dietOptionName}
              fixedExtraSmallHeight={selectedDiet?.dietOptions.every(
                item => !item?.dietOptionDescription
              )}
              heightList={optionHeight?.height}
              isActive={selectedOption?.dietOptionId === dietOptionId}
              key={dietOptionId}
              onClick={e => {
                handleSelectOption(e, option);
              }}
              readMore={readMoreOption}
              setHeight={setListHeight}
              setReadMore={setReadMoreOption}
              textAlignLeft
              withoutImage
            />
          );
        })}
      </div>
    </BoxStep>
  );
};

export default VariantStep;
