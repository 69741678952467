import { SideOrderOrCourseCalculatePriceT } from '@features/coursesAsSideOrders/types/coursesAsSideOrders.type';

export const generateSelectedFees = (
  selectedFees: Array<{ amount: number; possibleSideOrderId: number }>,
  simpleOrders: Array<{
    sideOrders: Array<SideOrderOrCourseCalculatePriceT>;
  }>
) => {
  selectedFees?.length &&
    selectedFees.forEach(item => {
      simpleOrders[0]?.sideOrders.push({
        multiplier: item.amount,
        possibleSideOrderId: item.possibleSideOrderId,
      });
    });
};
