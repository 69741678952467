import { createSelector } from 'reselect';
import {
  calculateDiscountPerDay,
  getPrice,
  getPricePerDay,
  getSelectedSideOrdersPrice,
} from 'utilsRoot/orderFormPrices';
import {
  getAddressDetails,
  getPickupPointDiscount,
  getSelectedCity,
} from './summaryReducer.selectors';
import { DateTime } from 'luxon';
import {
  calculateMealExchangePrice,
  sumSideOrders,
} from '@components/pages/basket/helpers/basketHelpers';
import { CodeE, DiscountTypeE } from 'typesRoot/discount.enum';
import { getOrderSettings, getTestOrderSettings } from './formSettingsSelector';

export const getBasket = state => state.basketReducer.basket;
export const getBasketEdition = state => state.basketReducer.edition;
export const getLoyaltyPoints = state => state.basketReducer.loyaltyPoints;
export const getPdfFile = state => state.basketReducer.pdfFile;
export const getPromoCode = state => state.basketReducer.promoCode;
// TODO: ONEOFFS
export const getSelectedAdditionalFees = state =>
  state.basketReducer.additionalFees.selectedFees;

export const getNumberOfItemsInTheBasket = createSelector(
  [getBasket],
  basket => basket.length
);

export const getSelectedAdditionalFeesPrice = createSelector(
  [getSelectedAdditionalFees],
  selectedFees =>
    selectedFees?.reduce(
      (total, item) => item?.defaultPrice * item?.amount + total,
      0
    )
);

export const getPickupPointDiscountAllDays = createSelector(
  [getPickupPointDiscount, getBasket],
  (pickupPointDiscountPerDay, basket) => {
    if (!pickupPointDiscountPerDay) {
      return 0;
    }

    const flatDays = basket?.map(item => item.selectedDays).flat();

    if (!flatDays.length) {
      return 0;
    }

    const allDays = [...new Set(flatDays)].length;

    return pickupPointDiscountPerDay * allDays;
  }
);

export const getFinalSum = createSelector(
  [getBasket, getPromoCode, getAddressDetails],
  (basket, { code }, { city } = {}) => {
    return basket.reduce((acc, curr) => {
      const {
        days,
        mealExchange: {
          basicPricePerDay: { meals, minimum, sideOrders },
          days: customDays,
        } = {},
        selectedDiet: { discounts },
        selectedProgram,
        sets,
      } = curr;

      if (selectedProgram === 'menuConfiguration') {
        acc =
          acc +
          calculateMealExchangePrice(
            customDays,
            days,
            meals,
            minimum,
            discounts,
            code,
            sets
          ) +
          sumSideOrders(sideOrders, days, customDays, sets);
      } else {
        acc =
          acc +
          getPrice(curr, code, city?.sectorId) +
          getSelectedSideOrdersPrice(customDays, sets);
      }

      return acc;
    }, 0);
  }
);

// previously checkIfBasketContainsTestOrder
export const getIfBasketContainsTestOrder = createSelector(
  [getBasket],
  basket => basket.some(item => item.testOrder)
);

export const getIfBasketContainsOnlyTestOrders = createSelector(
  [getBasket],
  basket => basket.every(item => item.testOrder)
);

// previously countDayDeliveries
const getCountDayDeliveries = createSelector(
  [getBasket, getPromoCode, getSelectedCity],
  (basket, promoCode, city) => {
    const { sectorId } = city || {};
    const { code } = promoCode || {};
    return (
      basket &&
      basket.reduce((acc, curr) => {
        const {
          days,
          mealExchange: {
            basicPricePerDay: { minimum },
            days: daysMealExchange = {},
          },
          orderId,
          selectedDays,
          selectedDiet,
          sets,
          testOrder,
        } = curr;

        selectedDays.forEach(day => {
          const mealExchangePrice =
            daysMealExchange[DateTime.fromMillis(day).toISODate()]
              ?.dayPriceAdjustment?.meals || 0;

          const pricePerDay = getPricePerDay(curr, code, sectorId);
          const pricePerDayMealExchange = calculateDiscountPerDay(
            days,
            selectedDiet,
            mealExchangePrice,
            minimum,
            pricePerDay
          );

          const price = pricePerDay + pricePerDayMealExchange;

          if (!acc[day]) {
            acc[day] = {
              [orderId]: {
                quantity: sets,
                price,
                day,
                testOrder,
              },
            };
          } else {
            if (!acc[day][orderId]) {
              acc[day] = {
                ...acc[day],
                [orderId]: {
                  quantity: sets,
                  price,
                  day,
                  testOrder,
                },
              };
            } else {
              acc[day] = {
                [orderId]: {
                  quantity: acc[day][orderId].quantity + sets,
                  price,
                  day,
                  testOrder,
                },
              };
            }
          }
        });

        return acc;
      }, {})
    );
  }
);

const getFilterRepeatedDays = createSelector(
  [getCountDayDeliveries],
  countedDeliveries => {
    const countedDeliveriesArray = Object.values(countedDeliveries);
    return (
      countedDeliveriesArray &&
      countedDeliveriesArray.filter(day => {
        const orderDeliveries = Object.values(day);
        // skip test orders in calculating discounts
        const containsQuantityOverOne = orderDeliveries.some(
          delivery => delivery.quantity > 1
        );
        return orderDeliveries.length > 1 || containsQuantityOverOne;
      })
    );
  }
);

const getMapArrayOfPriceSumAndQuantityForRepeatedDeliveries = createSelector(
  [getFilterRepeatedDays],
  filteredRepeatedDays => {
    return (
      filteredRepeatedDays &&
      filteredRepeatedDays.map(day => {
        const ordersPricesAndQuantityArray = Object.values(day);
        return (
          ordersPricesAndQuantityArray &&
          ordersPricesAndQuantityArray.reduce((priceAcc, priceCurr) => {
            if (!priceAcc['quantity']) {
              if (!priceCurr.testOrder) {
                priceAcc = {
                  quantity: priceCurr.quantity,
                  sum: priceCurr.quantity * priceCurr.price,
                };
              } else {
                priceAcc = {
                  ...priceAcc,
                };
              }
            } else {
              if (!priceCurr.testOrder) {
                priceAcc = {
                  quantity: priceAcc.quantity + priceCurr.quantity,
                  sum: priceAcc.sum + priceCurr.quantity * priceCurr.price,
                };
              } else {
                priceAcc = {
                  ...priceAcc,
                };
              }
            }
            return priceAcc;
          }, {})
        );
      }, {})
    );
  }
);

const getMapAveragePrice = createSelector(
  [getMapArrayOfPriceSumAndQuantityForRepeatedDeliveries],
  repeatedDeliveriesArray => {
    return (
      repeatedDeliveriesArray &&
      repeatedDeliveriesArray.map(delivery => ({
        ...delivery,
        averagePrice: delivery.sum / delivery.quantity,
      }))
    );
  }
);

const getDiscountByOrderedDiets = createSelector(
  [getOrderSettings],
  orderSettings => {
    return Object?.keys(orderSettings?.discountByOrderedDiets ?? {});
  }
);

export const getDiscountForAdditionalSets = createSelector(
  [
    getMapAveragePrice,
    getIfBasketContainsTestOrder,
    getDiscountByOrderedDiets,
    getOrderSettings,
    getPromoCode,
  ],
  (
    averagePrice,
    checkIfBasketContainsTestOrder,
    discountConditions,
    orderSettings,
    { code }
  ) => {
    const { discountByOrderedDiets, discountForAllDiets } = orderSettings || {};
    const separatePromoCode = code && code.separate;
    if (checkIfBasketContainsTestOrder || separatePromoCode) {
      return 0;
    }
    if (discountForAllDiets) {
      return (
        averagePrice &&
        averagePrice.reduce((acc, curr) => {
          const discountAllDiets =
            discountConditions &&
            discountConditions.find((discount, i) => {
              if (!discountConditions[i + 1]) {
                return curr.quantity >= discount;
              }
              return (
                curr.quantity >= discount &&
                discountConditions[i + 1] > curr.quantity
              );
            });
          const percentageDiscount = discountByOrderedDiets[discountAllDiets];

          const discount =
            (curr.sum * (percentageDiscount ? percentageDiscount : 0)) / 100;
          acc = discount ? acc + discount : acc;
          return acc;
        }, 0)
      );
    }
    return (
      averagePrice &&
      averagePrice.reduce((acc, curr) => {
        const numberOfSetsEligableForDiscount = discountConditions
          .map((discount, i) => {
            if (discountConditions[i] > curr.quantity && i === 0) {
              return {
                quantity: 0,
                discountKey: discount,
              };
            }
            if (parseInt(discountConditions[i], 10) === curr.quantity) {
              return {
                quantity: 1,
                discountKey: discount,
              };
            }

            if (!discountConditions[i + 1]) {
              return {
                quantity: curr.quantity - discount + 1,
                discountKey: discount,
              };
            }

            if (curr.quantity > parseInt(discountConditions[i], 10)) {
              const maxDiscountedSetsAmount =
                discountConditions[i + 1] - discountConditions[i];
              const discountedSetsAmount =
                curr.quantity - discountConditions[i] + 1;

              return {
                quantity: Math.abs(
                  discountedSetsAmount > maxDiscountedSetsAmount
                    ? maxDiscountedSetsAmount
                    : discountedSetsAmount
                ),
                discountKey: discount,
              };
            }
            return {
              quantity: 0,
              discountKey: discount,
            };
          })
          .filter(item => item.quantity > 0);
        const progressiveDiscount =
          numberOfSetsEligableForDiscount &&
          numberOfSetsEligableForDiscount.reduce((progAcc, progCurr) => {
            progAcc =
              progAcc +
              (progCurr.quantity *
                discountByOrderedDiets[progCurr.discountKey] *
                curr.averagePrice) /
                100;
            return progAcc;
          }, 0);
        acc = acc + progressiveDiscount;
        return acc;
      }, 0)
    );
  }
);

export const getFinalAmountToPay = createSelector(
  [getDiscountForAdditionalSets, getFinalSum],
  (discountForAdditionalSets, finalSum) => {
    if (discountForAdditionalSets === 0 || isNaN(discountForAdditionalSets)) {
      return finalSum;
    }
    return finalSum - discountForAdditionalSets;
  }
);

export const getDiscountAfterPromoCode = createSelector(
  [getPromoCode, getFinalAmountToPay, getBasket],
  (promoCode, finalAmountToPay, basket) => {
    const {
      code,
      flags: { error },
    } = promoCode;
    const { discount, discountType, promoCodeType } = code;

    const basketContainsTestOrder = basket.some(item => item.testOrder);

    if (
      !code ||
      error === 'Niepoprawny kod' ||
      basketContainsTestOrder ||
      promoCodeType === CodeE.Global
    ) {
      return null;
    }

    if (discountType === DiscountTypeE.PERCENTAGE) {
      return (finalAmountToPay * discount) / 100;
    }
    return discount;
  }
);

export const getTestOrderNumberOfSetsValidation = createSelector(
  [getBasket, getTestOrderSettings],
  (basket, testOrderSettings) => {
    const { maximumPerDay } = testOrderSettings || {};

    const basketMaxSetsTestOrder = basket
      .filter(item => item?.testOrder)
      .reduce((totalSets, currentSet) => {
        return totalSets + currentSet?.sets;
      }, 0);

    return basketMaxSetsTestOrder > maximumPerDay;
  }
);

export const getUniqueDaysNumber = createSelector([getBasket], basket => {
  if (!basket) {
    return 0;
  }
  const uniqueDaysArray = basket?.reduce((acc, curr) => {
    curr?.selectedDays?.forEach(day => {
      if (!acc.includes(day)) {
        acc.push(day);
      }
    });
    return acc;
  }, []);
  return uniqueDaysArray?.length ?? 0;
});

export const getDeliveryFee = createSelector(
  [getUniqueDaysNumber, getSelectedCity],
  (uniqueDaysNumber, selectedCity) => {
    if (!uniqueDaysNumber || !selectedCity) {
      return 0;
    }
    const deliveryFee = selectedCity?.deliveryFee ?? 0;
    return deliveryFee * uniqueDaysNumber;
  }
);

export const getLoyaltyPointsDiscount = createSelector(
  [getLoyaltyPoints],
  loyaltyPoints => {
    return loyaltyPoints?.usedLoyaltyPoints / 100 ?? 0;
  }
);

export const getPdfFileDiscount = createSelector([getPdfFile], pdfFile => {
  const { hasFile } = pdfFile;
  return hasFile ? 0 : 1;
});

export const getFinalSumAfterDiscountAndDeliveryFee = createSelector(
  [
    getFinalSum,
    getDiscountForAdditionalSets,
    getDiscountAfterPromoCode,
    getLoyaltyPointsDiscount,
    getPdfFileDiscount,
    getDeliveryFee,
    getSelectedAdditionalFeesPrice,
    getPickupPointDiscountAllDays,
  ],
  (
    finalSum,
    discountForAdditionalSets,
    promoCodeDiscount,
    loyaltyPointsDiscount,
    pdfFileDiscount,
    deliveryFee,
    selectedAdditionalFees,
    pickupPointDiscount
  ) => {
    const additionalSets = isNaN(discountForAdditionalSets)
      ? 0
      : discountForAdditionalSets;
    const promoCode = isNaN(promoCodeDiscount) ? 0 : promoCodeDiscount;
    const loyaltyPoints = isNaN(loyaltyPointsDiscount)
      ? 0
      : loyaltyPointsDiscount;
    const finalAmount =
      (finalSum -
        additionalSets -
        promoCode -
        loyaltyPoints +
        selectedAdditionalFees +
        deliveryFee -
        pickupPointDiscount) *
      pdfFileDiscount;
    return finalAmount > 0 ? finalAmount : 0;
  }
);

export const getLowestPriceAndPercent = createSelector(
  [getBasket, getSelectedCity],
  (basket, city) => {
    const findValues = basket.reduce(
      (acc, curr) => {
        const { days, selectedDietCaloriesIdPrices, sets, testOrder } = curr;

        const findPrices = selectedDietCaloriesIdPrices.find(
          price => price.sectorId === city.sectorId
        );

        const price =
          (testOrder
            ? findPrices?.last30dayLowestTestPrice
            : findPrices?.last30dayLowestPrice) || 0;

        if (findPrices) {
          acc.lowestPrice += price * days * sets;
          acc.price += findPrices.price * days * sets;
        }

        return acc;
      },
      {
        lowestPrice: 0,
        price: 0,
      }
    );

    return {
      lowestPrice: findValues.lowestPrice,
      lowestPercentage: (
        (findValues.lowestPrice * 100) / findValues.price -
        100
      )?.toFixed(0),
    };
  }
);
