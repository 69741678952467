import {
  salesManagoApiAddContactExtEvent,
  salesManagoApiUpdateContactExtEvent,
} from '../api';
import { getTimeStamp, mapMealsName, weekendParsed } from '../helpers';
import { parseSideOrdersObjectToArray } from '@userCom/utilis/createSideOrdersArray';
import { DiscountTypeE } from 'typesRoot/discount.enum';
import { getISODateFromMillis } from '@adapters/dates/dates.adapter';

const createCartData = (
  order,
  code,
  selectedFees,
  description,
  companyId,
  selectedSideOrders,
  cartPrices
) => {
  const {
    amount,
    days,
    saturdays,
    selectedCalories: { calories },
    selectedDays,
    selectedDiet: { dietId, dietName },
    selectedMeals,
    sundays,
  } = order;

  const { totalCostToPay = 0 } = cartPrices || {};

  const firstDay = selectedDays[0];
  const lastDay = selectedDays[selectedDays.length - 1];
  const mealsLength = selectedMeals.length;

  return {
    contactExtEventType: 'CART',

    date: getTimeStamp(),

    description,

    // data zamówienia od w formacie YYYY-MM-DD,
    detail1: getISODateFromMillis(firstDay),

    // data zamówienia do w formacie YYYY-MM-DD,
    detail2: getISODateFromMillis(lastDay),

    // wariant diety liczba posiłków: 5, 4, 3
    detail3: mealsLength,

    // posiłki po przecinku
    detail4: mapMealsName(selectedMeals),

    // kaloryczność[kcal]np: 2000,
    detail9: calories,

    // koszt dzienny np: 70.90, lepiej z kropką, żeby nie rozdzielać wartości na dwie osobne
    detail10: totalCostToPay / days,

    // dni weekendowe np. sobota_tak, sobota_nie
    detail11: weekendParsed(saturdays, sundays),

    // Kod rabatowy: nazwa kuponu
    detail13: code.code ? code.code : null,

    // Rabat (%): wartość rabatu w procentach np: 10
    detail14:
      code.discount > 0
        ? code.discountType === DiscountTypeE.TOTAL
          ? 'zł'
          : '%'
        : null,

    // dni dostaw: ilość dni dostaw np. 10
    detail15: days,

    // ilość danej diety w zamówieniu, gdy klient zwiększa liczbę plusem do dwóch, trzech itd. Wartość liczbowa np.: 2
    detail16: amount,

    // źródło zamówienia
    detail17: 'formularz zamówienia',

    // nazwa diety oraz kaloryczność
    detail18: `${dietName}_${calories}`,

    // Dodatki na każdy dzień
    detail19:
      Object.keys(selectedSideOrders || {}).length > 0
        ? parseSideOrdersObjectToArray(selectedSideOrders).toString()
        : null,

    // dodatki jednorazowe
    detail20:
      selectedFees && !!selectedFees?.length
        ? selectedFees.map(selectedFee => selectedFee.name).toString()
        : null,

    // unikalny identyfikator sklepu
    location: companyId,

    // lista produktów oddzielona przecinkami
    products: dietId,

    // wartość zamówienia, wyrażona w liczbach, bez waluty
    value: totalCostToPay,
  };
};

export const salesManagoAddToCart = async ({
  cartPrices,
  code,
  companyId,
  email,
  order,
  salesManago,
  selectedFees,
  selectedSideOrders,
  serverUrl,
}) => {
  try {
    const contactEvent = createCartData(
      order,
      code,
      selectedFees,
      'Dodanie diety do koszyka',
      companyId,
      selectedSideOrders,
      cartPrices
    );

    const response = await salesManagoApiAddContactExtEvent(
      {
        contactEvent,
        email,
      },
      serverUrl,
      salesManago
    );

    const { data } = response;

    return data;
  } catch (e) {
    console.log(e, 'e');
  }
};

export const salesManagoUpdateCart = async (
  order,
  code,
  selectedCity,
  selectedFees,
  serverUrl,
  salesManago,
  companyId,
  selectedSideOrders,
  cartPrices
) => {
  try {
    const contactEvent = {
      ...createCartData(
        order,
        code,
        selectedFees,
        'Aktualizacja diety w koszyku',
        companyId,
        selectedSideOrders,
        cartPrices
      ),
      eventId: order.eventId,
    };

    await salesManagoApiUpdateContactExtEvent(
      {
        contactEvent,
      },
      serverUrl,
      salesManago
    );
  } catch (e) {
    console.log(e, 'e');
  }
};

export const salesManagoUpdateAmount = async ({
  amount,
  cartPrices,
  code,
  companyId,
  eventId,
  order,
  salesManago,
  selectedFees,
  selectedSideOrders,
  serverUrl,
}) => {
  try {
    const contactEvent = {
      ...createCartData(
        order,
        code,
        selectedFees,
        'Aktualizacja ilości diety w koszyku',
        companyId,
        selectedSideOrders,
        cartPrices
      ),
      detail16: amount,
      eventId,
    };

    await salesManagoApiUpdateContactExtEvent(
      {
        contactEvent,
      },
      serverUrl,
      salesManago
    );
  } catch (e) {
    console.log(e, 'e');
  }
};

export const salesManagoUpdatePromoCode = async (
  code,
  item,
  selectedFees,
  description,
  serverUrl,
  salesManago,
  companyId,
  selectedSideOrders,
  cartPrices
) => {
  try {
    const { eventId } = item;

    const contactEvent = {
      ...createCartData(
        item,
        code,
        selectedFees,
        description,
        companyId,
        selectedSideOrders,
        cartPrices
      ),
      eventId,
    };

    await salesManagoApiUpdateContactExtEvent(
      {
        contactEvent,
      },
      serverUrl,
      salesManago
    );
  } catch (e) {
    console.log(e, 'e');
  }
};

export const salesManagoRemoveCart = async (
  eventId,
  serverUrl,
  salesManago
) => {
  try {
    await salesManagoApiUpdateContactExtEvent(
      {
        contactEvent: {
          contactExtEventType: 'CANCELLED',
          date: getTimeStamp(),
          description: 'Usunięcie diety z koszyka',
          eventId,
        },
      },
      serverUrl,
      salesManago
    );
  } catch (e) {
    console.log(e, 'e');
  }
};
