import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../config/hooks';
import {
  getEnhancedEcommerceCurrentPage,
  getEnhancedEcommerceIsAdblockEnabled,
  getEnhancedEcommercePrevPage,
  getEnhancedEcommerceUserID,
} from '../redux/selectors/enhancedEcommerce.selector';
import { useEffectOnce, useMount, useUnmount } from 'react-use';
import {
  setCurrentPage,
  setIsAdblockEnabled,
  setPrevPage,
  setUserId,
} from '../redux/slices/enhancedEcommerce.slice';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import axios from 'axios';
import { getServerConfig } from '../features/general/generalSlice';
import { getProfileEmail } from '../redux/selectors/profileSelector';

export const useEnhancedEcommercePageInfo = () => {
  const dispatch = useAppDispatch();
  const sendDataToGTM = useGTMDispatch();
  const [canSend, setCanSend] = useState(false);

  const currentPage = useAppSelector(getEnhancedEcommerceCurrentPage);
  const prevPage = useAppSelector(getEnhancedEcommercePrevPage);
  const profileEmail = useAppSelector(getProfileEmail);
  const isAdblockEnabled = useAppSelector(getEnhancedEcommerceIsAdblockEnabled);
  const userID = useAppSelector(getEnhancedEcommerceUserID);
  const { apiKey, companyId, enhancedEcommerce, serverUrl } =
    useAppSelector(getServerConfig);

  const actualPage = window.location.hash.split('#')[1] || '/';

  const adblock = isAdblockEnabled !== 'idle' ? Number(isAdblockEnabled) : null;
  const userLoggedIn = Number(!!profileEmail);

  const fetchClientId = useCallback(async () => {
    const getClientCRMId = () =>
      axios(`${serverUrl}/api/form/profile/client/id`, {
        method: 'GET',
        headers: {
          'api-key': apiKey,
          'company-id': companyId,
        },
        withCredentials: true,
      });

    try {
      if (!userLoggedIn) {
        return dispatch(setUserId('N/A'));
      }
      const { data: clientId } = await getClientCRMId();

      dispatch(setUserId(clientId));
    } catch (err) {
      console.log(`Cannot fetch client id. Error message: ${err}`);
      dispatch(setUserId('N/A'));
    }
  }, [apiKey, companyId, dispatch, serverUrl, userLoggedIn]);

  const fetchIsAdblockEnabled = () => {
    fetch('https://www3.doubleclick.net', {
      method: 'HEAD',
      mode: 'no-cors',
      cache: 'no-store',
    })
      .then(() => {
        dispatch(setIsAdblockEnabled(false));
      })
      .catch(() => {
        dispatch(setIsAdblockEnabled(true));
      })
      .finally(() => {
        setCanSend(true);
      });
  };

  const fetchClientIdAndIsAdblock = async () => {
    await fetchClientId();
    fetchIsAdblockEnabled();
  };

  useEffectOnce(() => {
    isAdblockEnabled === 'idle'
      ? fetchClientIdAndIsAdblock()
      : setCanSend(true);
  });

  useEffect(() => {
    if (userLoggedIn && userID === 'N/A') {
      fetchClientId().catch(e => console.log(e));
    }
  }, [userLoggedIn, userID, fetchClientId]);

  useMount(() => {
    dispatch(setCurrentPage(actualPage));
  });
  useUnmount(() => {
    dispatch(setPrevPage(actualPage));
  });

  const enhancedEcommerceData = {
    event: 'pageview',
    pageType: currentPage,
    userLoggedIn, //  Status logowania użytkownika. Wartość: 0|1,
    userID, // ID użytkownika. Wartość powinna być dostępna przy logowaniu do serwisu i odpowiadać numerowi z CRM,
    internalReferrer: prevPage,
    adblock, // Status adblocka. Wartość: 0|1,
    closedOrders: 'N/A',
    error404: 'N/A',
    currencyCode: 'PLN', // Waluta
  };

  return {
    canSend,
    sendEvent: () => {
      setCanSend(false);
      return setTimeout(() => {
        if (enhancedEcommerce) {
          sendDataToGTM(enhancedEcommerceData);
        }
      });
    },
  };
};
