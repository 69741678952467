import React from 'react';

const EmptyStateMealIcon = ({ className, height = '65', width = '57' }) => {
  return (
    <svg
      className={className}
      fill="none"
      height={height}
      viewBox="0 0 57 65"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill="white"
        height="50.3636"
        rx="3"
        stroke="#1B42B2"
        strokeWidth="2"
        width="51.0528"
        x="1"
        y="1"
      />
      <path d="M15.4746 0.726562V51.6357" stroke="#1B42B2" strokeWidth="2" />
      <path
        d="M12.9988 10.5462C12.9988 11.7891 11.9743 12.8189 10.683 12.8189C9.39164 12.8189 8.36719 11.7891 8.36719 10.5462C8.36719 9.30326 9.39164 8.27344 10.683 8.27344C11.9743 8.27344 12.9988 9.30326 12.9988 10.5462Z"
        stroke="#1B42B2"
        strokeWidth="2"
      />
      <path
        d="M5.52519 15.2741L4.41992 11.2741L8.10414 10.5469"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M4.41972 11.2727L3.31445 8"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="M10.0515 17.093C10.0515 18.3359 9.02707 19.3658 7.73572 19.3658C6.44437 19.3658 5.41992 18.3359 5.41992 17.093C5.41992 15.8501 6.44437 14.8203 7.73572 14.8203C9.02707 14.8203 10.0515 15.8501 10.0515 17.093Z"
        stroke="#1B42B2"
        strokeWidth="2"
      />
      <path
        d="M5.41992 34.1805C5.41992 29.9172 7.53043 26.1357 10.7884 23.8022V44.5587C7.53042 42.2252 5.41992 38.4438 5.41992 34.1805Z"
        stroke="#1B42B2"
        strokeWidth="2"
      />
      <path
        d="M11.053 34.1808H5.1582M11.053 34.1808L6.63189 29.0898M11.053 34.1808L6.26347 39.6353"
        stroke="#1B42B2"
        strokeLinejoin="bevel"
        strokeWidth="2"
      />
      <path
        d="M21.8945 6.09375H45.4665C46.3436 6.09375 46.7958 7.14245 46.1937 7.78023L22.6217 32.7484C22.0005 33.4063 20.8945 32.9667 20.8945 32.0619V7.09375C20.8945 6.54147 21.3422 6.09375 21.8945 6.09375Z"
        fill="#E8ECF7"
        stroke="#1B42B2"
        strokeWidth="2"
      />
      <path
        d="M40.8959 8.73047L37.5801 12.0032"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M27.6322 21.8203L24.3164 25.093"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M30.4794 10.3181L25.7901 10.3181"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M30.5781 17.8196L27.2623 14.5469"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M45.8965 46.2734H22.3245C21.4474 46.2734 20.9952 45.2247 21.5974 44.587L45.1693 19.6188C45.7905 18.9609 46.8965 19.4005 46.8965 20.3053V45.2734C46.8965 45.8257 46.4488 46.2734 45.8965 46.2734Z"
        stroke="#1B42B2"
        strokeWidth="2"
      />
      <path
        d="M26.8951 43.6367L30.2109 40.364"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M40.1569 30.5469L43.4727 27.2741"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M37.3116 42.0491L42.0009 42.0491"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M37.209 34.5437L40.5248 37.8164"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M55.9992 49.4517C55.9992 57.4844 49.4007 63.9972 41.2623 63.9972C33.1238 63.9972 26.5254 57.4844 26.5254 49.4517C26.5254 41.419 33.1238 34.9062 41.2623 34.9062C49.4007 34.9062 55.9992 41.419 55.9992 49.4517Z"
        fill="#F2F5FC"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="M45.6328 44.7148L36.8904 53.4573"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M45.6895 53.5195L36.832 44.6621"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default EmptyStateMealIcon;
