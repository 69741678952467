//@ts-nocheck

import * as actionTypes from '../actions/actionTypes';

export const resetOrderForm = () => ({
  type: actionTypes.RESET_ORDER_FORM,
});

export const select = (field, value) => ({
  type: actionTypes.SELECT,
  field,
  value,
});

export const storeMeals = meals => ({
  type: actionTypes.STORE_MEALS,
  meals,
});

export const storePrices = prices => ({
  type: actionTypes.STORE_PRICES,
  prices,
});

export const toggleMeal = (
  dietMealId,
  numberOfSelectedMeals,
  minimumNumberOfMeals
) => ({
  type: actionTypes.TOGGLE_MEAL,
  dietMealId,
  numberOfSelectedMeals,
  minimumNumberOfMeals,
});

export const setOrderDays = days => ({
  type: actionTypes.SET_DAYS,
  days,
});

export const toggleOrderCheckbox = (field, checked) => ({
  type: actionTypes.TOGGLE_CHECKBOX,
  field,
  checked,
});

export const selectOrderDays = days => ({
  type: actionTypes.SELECT_DAYS,
  days,
});

export const toggleDay = day => ({
  type: actionTypes.TOGGLE_DAY,
  day,
});

export const storeFilteredWeekends = days => ({
  type: actionTypes.STORE_FILTERED_WEEKENDS,
  days,
});

export const resetFilteredDays = days => ({
  type: actionTypes.RESET_FILTERED_DAYS,
  days,
});

export const storeNextDay = day => ({
  type: actionTypes.STORE_DAY,
  day,
});

export const deleteLastDay = day => ({
  type: actionTypes.DELETE_DAY,
  day,
});

export const storeExtraDays = days => ({
  type: actionTypes.STORE_EXTRA_DAYS,
  days,
});

export const resetSideOrders = () => ({
  type: actionTypes.RESET_SIDE_ORDERS,
});

export const setDefaultDays = days => ({
  type: actionTypes.SET_DEFAULT_DAYS,
  days,
});

export const resetCalendar = () => ({
  type: actionTypes.RESET_CALENDAR,
});

export const updateOrderFormReducer = order => ({
  type: actionTypes.UPDATE_ORDER_FORM_REDUCER,
  order,
});

export const storeCity = (sector, name) => ({
  type: actionTypes.STORE_CITY,
  sector,
  name,
});

export const resetPreviouslySelectedDietDetails = () => ({
  type: actionTypes.RESET_PREVIOUSLY_SELECTED_DIET_DETAILS,
});

export const storeLastItemIntoOrderForm = item => ({
  type: actionTypes.STORE_LAST_ITEM_INTO_ORDER_FORM,
  item,
});

export const changeMinMaxModalStatus = status => ({
  type: actionTypes.CHANGE_MIN_MAX_MODAL_STATUS,
  status,
});

export const mealExchangeSelectProgram = program => ({
  type: actionTypes.MEAL_EXCHANGE_SELECT_PROGRAM,
  program,
});

export const mealExchangeChangeEditModalClickedArea =
  mealExchangeEditModalClickedArea => ({
    type: actionTypes.MEAL_EXCHANGE_CHANGE_EDIT_MODAL_CLICKED_AREA,
    mealExchangeEditModalClickedArea,
  });

export const mealExchangeChangeEditModalStatus =
  mealExchangeEditModalStatus => ({
    type: actionTypes.MEAL_EXCHANGE_CHANGE_EDIT_MODAL_STATUS,
    mealExchangeEditModalStatus,
  });

export const restoreInitialOrderForm = () => ({
  type: actionTypes.RESTORE_INITIAL_ORDER_FORM,
});
