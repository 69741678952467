import React from 'react';
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import {
  getMinMaxModalStatus,
  getMinMaxSelectedMeals,
} from '@redux/selectors/orderFormSelector';
import { changeMinMaxModalStatus } from '@redux/actions/orderFormActions';
import Modal from 'react-modal';
import MealIcon from '../../../../assets/icons/mealIcon';
import Button from '../../../common/Button';
import { useAppSelector } from '@config/hooks';

const MealErrorModal = ({ sideOrdersValidation }) => {
  const dispatch = useDispatch();

  const modalStatus = useAppSelector(getMinMaxModalStatus);
  const { min } = useAppSelector(getMinMaxSelectedMeals);

  return (
    <Modal
      className="custom-modal-another-catering"
      isOpen={!!modalStatus}
      overlayClassName="overlay-form-fixed"
    >
      <FontAwesomeIcon
        className="meal-modal__close font-size-18"
        icon={faTimes}
        onClick={() => {
          dispatch(changeMinMaxModalStatus(''));
        }}
      />
      <div className="display-flex flex-direction-column justify-content-space-between">
        <div className="text-align-center display-flex flex-direction-column align-items-center padding-all-24">
          <MealIcon className="meal-icon-color" />
          <p className="label-l font-size-20 spacer-bottom-8">
            Nie można {modalStatus === 'min' ? 'usunąć' : 'dodać'} kolejnego
            posiłku
          </p>
          <p className="body-l spacer-bottom-16">
            {sideOrdersValidation
              ? 'Osiągnięto maksymalną ilość dodatków.'
              : modalStatus === 'min'
              ? `Catering umożliwia składanie zamówień z liczbą ${min}
          posiłków na dzień.`
              : `Osiągnięto maksymalną ilość posiłków dla jednego dnia zamówienia.`}
          </p>
        </div>
        <div className="custom-modal-another-catering__buttons justify-content-flex-end">
          <Button
            label="OK, rozumiem"
            onClick={() => {
              dispatch(changeMinMaxModalStatus(''));
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

MealErrorModal.propTypes = {
  sideOrdersValidation: PropTypes.bool,
};

export default MealErrorModal;
