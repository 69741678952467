import { postProfileOrder } from '@services/api/form/open/shopping-cart.api';
import {
  setModalError,
  setModalErrorLoading,
} from '../../../modalError/services/redux/modalError.slice';
import { successOrderCallback } from './successOrderCallback.util';
import { OrderT } from '../types/stepSummary.type';
import { CalculatePriceT } from 'typesRoot/calculatePrice.type';

// TODO ANY
export const profileOrder =
  (
    order: OrderT,
    navigate: any,
    sendGTMTransactionEvents: (
      newOrderIds: Array<string>,
      previousOrdersNumber: number,
      clientId: number,
      shoppingCartId: number,
      clientLifecycleState: string,
      email: string,
      crm_transaction_id?: string
    ) => void,
    prices: CalculatePriceT
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setModalErrorLoading(true));

      const { data: response, error } = await dispatch(
        postProfileOrder.initiate(order)
      );

      if (error) {
        dispatch(setModalErrorLoading(false));
        const { message, presentation } = error.data;

        dispatch(
          setModalError({
            message,
            presentation,
          })
        );
      } else {
        dispatch(
          successOrderCallback(
            'profile',
            { ...response, signUp: order.signUp },
            navigate,
            sendGTMTransactionEvents,
            prices
          )
        );
      }
    } catch (e) {
      dispatch(setModalErrorLoading(false));
    }
  };
