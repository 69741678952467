import React from 'react';

const FemaleIcon = ({ className }) => {
  return (
    <svg
      className={className}
      fill="none"
      height="55"
      viewBox="0 0 55 55"
      width="55"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M54 27.5C54 42.135 42.135 54 27.5 54C12.864 54 1 42.135 1 27.5C1 12.865 12.864 1 27.5 1C42.135 1 54 12.865 54 27.5Z"
        fill="white"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M12.4869 45.6411L21.5999 41.7331C22.0929 41.4861 22.4959 40.6881 22.7789 40.1191C23.0339 39.6051 22.6529 39.0001 22.0789 39.0001H15.6339C15.6339 39.0001 13.3039 38.7721 11.5069 38.0001C10.4549 37.5481 10.0919 36.9511 10.7499 36.0141C12.6749 33.2731 16.6539 26.8221 16.7829 20.1581C16.7829 20.1581 17.0029 9.90212 27.1229 9.81812C33.0259 9.86712 35.8149 13.3421 37.1129 16.2291C37.8379 17.8391 38.1479 19.5951 38.2649 21.3581C38.6839 27.7091 41.9189 33.5171 43.4259 36.0001C43.9369 36.8421 43.6899 37.9631 42.8449 38.4691C41.4579 39.2981 39.7609 39.0001 39.7609 39.0001H33.2729C32.6399 39.0001 32.3599 39.8861 32.8569 40.2791C33.2629 40.5991 33.6499 40.8881 33.8589 41.0021L41.4389 45.5891C42.5339 46.1861 43.4069 47.0721 43.9999 48.1181C39.4509 51.7951 33.6639 54.0001 27.3589 54.0001C20.7849 54.0001 14.7749 51.6011 10.1429 47.6371C10.7479 46.8121 11.5369 46.1161 12.4869 45.6411Z"
        fill="#fff"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default FemaleIcon;
