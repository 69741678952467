import React from 'react';

import styles from '@features/coursesAsSideOrders/styles/sideOrdersDetailsModal.module.scss';
import {
  SideOrderOrCourseT,
  SideOrderOrCourseTypeE,
} from '@features/coursesAsSideOrders/types/coursesAsSideOrders.type';
import { UseStateT } from 'typesRoot/generics.type';
import CustomModalTS from '@components/common/CustomModalTS';
import { useGetSideOrderOrCourseByIdQuery } from '@services/api/form/open/possibleSideOrders/possibleSideOrders.api';
import { SideOrderDetailsContentModal } from '@dietly/design-system-library';
import { classNamesUtil } from 'utilsRoot/classNamesUtil.util';

type Props = {
  companyId: string;
  isOpen: boolean;
  setIsOpen: UseStateT<boolean>;
  handleSelectSideOrderModalOpen: (sideOrder: SideOrderOrCourseT) => void;
  selectedSideOrder: SideOrderOrCourseT;
};

const SideOrderDetailsModal = ({
  companyId,
  handleSelectSideOrderModalOpen,
  isOpen,
  selectedSideOrder,
  setIsOpen,
}: Props) => {
  const { data, isLoading } = useGetSideOrderOrCourseByIdQuery({
    companyId,
    type: selectedSideOrder.sideOrderId.type,
    sideOrderOrCourseId: selectedSideOrder.sideOrderId.id,
  });

  const isCourseAsSideOrder =
    selectedSideOrder.sideOrderId.type ===
    SideOrderOrCourseTypeE.COURSE_AS_SIDE_ORDER;
  const handleClick = () => {
    handleSelectSideOrderModalOpen(selectedSideOrder);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <CustomModalTS
      childrenContainerClass={styles['container-modal']}
      className={classNamesUtil(
        `custom-modal ${styles['custom-modal-side-orders-details']}`,
        {
          'height-auto': !isCourseAsSideOrder,
        }
      )}
      isModalOpen={isOpen}
      setIsModalOpen={closeModal}
    >
      <SideOrderDetailsContentModal
        closeModal={closeModal}
        data={data}
        handleSelectSideOrderModalOpen={handleClick}
        isCourseAsSideOrder={isCourseAsSideOrder}
        isLoading={isLoading}
        selectedSideOrder={selectedSideOrder}
      />
    </CustomModalTS>
  );
};

export default SideOrderDetailsModal;
