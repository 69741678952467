import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { formApi } from '../index';
import { ProfileClientT } from '../../../../@types/profile/profile.type';
import * as actionTypes from '../../../../redux/actions/actionTypes';
import { setUserId } from '../../../../redux/slices/enhancedEcommerce.slice';
import {
  changeDeliveryMethod,
  changeMethodOfPurchase,
} from '../../../../redux/actions/summaryActions';

export const profileApi = formApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    getApiProfileClient: build.query<ProfileClientT, void>({
      query: () => ({
        method: 'GET',
        url: 'form/profile/client',
      }),
    }),
    getApiLoyaltyPointsSum: build.query<ProfileClientT, void>({
      query: () => ({
        method: 'GET',
        url: 'form/profile/loyalty-program/points/sum',
      }),
    }),
    getApiProfile: build.query<any, void>({
      query: () => ({
        method: 'GET',
        url: 'form/profile/',
      }),
      providesTags: ['Profile'],
    }),
    postApiLogout: build.mutation<null, void>({
      query: () => ({
        url: 'form/open/logout',
        method: 'POST',
      }),
      async onQueryStarted(arg, { dispatch }) {
        dispatch({ type: actionTypes.RESET_PROFILE });
        dispatch(setUserId('N/A'));

        sessionStorage.removeItem('salesManagoLogin');
        sessionStorage.removeItem('userComLogin');

        dispatch(changeMethodOfPurchase(''));
        dispatch(changeDeliveryMethod(''));

        dispatch(
          // @ts-ignore
          formApi.util.updateQueryData('getApiProfile', undefined, () => null)
        );
        dispatch(
          formApi.util.updateQueryData(
            // @ts-ignore
            'getApiProfileClient',
            undefined,
            () => null
          )
        );
        dispatch(
          formApi.util.updateQueryData(
            // @ts-ignore
            'getApiLoyaltyPointsSum',
            undefined,
            () => null
          )
        );
      },
    }),
    postApiAddress: build.mutation<
      {
        data: {
          apartmentNumber: string | null;
          billingAddress: boolean;
          cityId: number;
          dateAdded: string | null;
          deliverySpot: string;
          floor: string | null;
          gateKey: string | null;
          hourPreference: string | null;
          houseNumber: string;
          info: string | null;
          profileAddressId: number;
          staircase: string | null;
          street: string;
          zipCodeId: number;
        };
      },
      {
        apartmentNumber: string;
        billingAddress: boolean;
        cityId: number;
        dateAdded: string | null;
        deliverySpot: string;
        floor: string;
        gateKey: string;
        houseNumber: string;
        info: string;
        staircase: string;
        street: string;
        zipCodeId: number;
      }
    >({
      query: body => ({
        url: 'form/profile/addresses',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Profile'],
    }),
  }),
});

export const {
  useGetApiLoyaltyPointsSumQuery,
  useGetApiProfileClientQuery,
  useGetApiProfileQuery,
  usePostApiAddressMutation,
  usePostApiLogoutMutation,
} = profileApi;
