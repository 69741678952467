import {
  checkIfNotAllMealsSelected,
  displaySelectedMeals,
  parsePrice,
} from 'utilsRoot/index';
import React from 'react';
import { MealT } from 'typesRoot/basketItem.type';

type Props = {
  mealExchange: boolean;
  selectedMeals: Array<MealT> | '';
  spaceBetween?: boolean;
  totalMealsChosenCost: number;
  totalSideOrdersCost: number;
};

const AddOns = ({
  mealExchange,
  selectedMeals,
  spaceBetween,
  totalMealsChosenCost,
  totalSideOrdersCost,
}: Props) => {
  const displayPlus = (num: number) => {
    if (num > 0) {
      return '+';
    }
    return '';
  };

  return mealExchange ? (
    <>
      <div
        className={`display-flex flex-wrap align-items-center body-m spacer-top-8 ${
          spaceBetween ? 'justify-content-space-between' : ''
        }`}
      >
        <div className="spacer-right-8">Suma dodatków:</div>
        <div>
          {displayPlus(totalSideOrdersCost)}
          {parsePrice(totalSideOrdersCost)} zł
        </div>
      </div>
      <div
        className={`display-flex flex-wrap align-items-center body-m spacer-top-8 ${
          spaceBetween ? 'justify-content-space-between' : ''
        }`}
      >
        <div className="spacer-right-8">Suma zmian posiłków w menu:</div>
        <div>
          {displayPlus(totalMealsChosenCost)}
          {parsePrice(totalMealsChosenCost)} zł
        </div>
      </div>
    </>
  ) : (
    <>
      {!!totalSideOrdersCost && (
        <div
          className={`display-flex align-items-top body-m spacer-top-8 spacer-bottom-8 ${
            spaceBetween ? 'justify-content-space-between' : ''
          }`}
        >
          <p className="spacer-right-8">Suma dodatków:</p>
          <div>+{parsePrice(totalSideOrdersCost)} zł</div>
        </div>
      )}
      {checkIfNotAllMealsSelected(selectedMeals) && (
        <div
          className={`display-flex align-items-top body-m spacer-top-8 ${
            spaceBetween ? 'justify-content-space-between' : ''
          }`}
        >
          <div className="spacer-right-10">Posiłki:</div>
          <div>{displaySelectedMeals(selectedMeals)}</div>
        </div>
      )}
    </>
  );
};

export default AddOns;
