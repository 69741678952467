import { CodeT } from '../../../../@types/code.type';
import { formApi } from '../index';

export const discountCodes = formApi.injectEndpoints({
  endpoints: build => ({
    postApiDiscountCodes: build.mutation<
      undefined,
      {
        companyId: string;
        body: {
          code: string;
          cityId?: number;
          deliveriesNumber?: number;
          dietWithRegularSideOrdersCost?: number;
          dietCaloriesIds?: Array<number>;
        };
      }
    >({
      query: ({ body, companyId }) => ({
        method: 'POST',
        url: '/form/open/discount-codes/validate',
        body,
        headers: {
          'company-id': companyId,
        },
      }),
    }),
    getApiDiscountCodes: build.query<
      CodeT,
      {
        code: string;
        companyId?: string;
      }
    >({
      query: ({ code, companyId }) => {
        const encodedCode = encodeURIComponent(code);
        return {
          url: `/form/open/discount-codes/${encodedCode}`,
          headers: {
            'company-id': companyId,
          },
        };
      },
      keepUnusedDataFor: 3600,
    }),
  }),
});

export const {
  useLazyGetApiDiscountCodesQuery,
  usePostApiDiscountCodesMutation,
} = discountCodes;
