import React from 'react';

const CoinAlternativeIcon = ({ className }) => {
  return (
    <svg
      className={className}
      fill="none"
      height="160"
      viewBox="0 0 200 160"
      width="200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M127.009 23.0875L102.454 27.8463C98.8315 28.5483 95.1348 27.0571 93.0135 24.0383C88.8283 18.0825 79.7452 18.9726 76.7959 25.6276L65.4969 51.1228C64.5137 53.3412 63.0071 55.288 61.1062 56.7961L29.4929 81.8776C20.3896 89.1001 25.0237 103.745 36.6247 104.416L41.472 104.697C50.3399 105.21 54.5327 115.879 48.3867 122.292C40.7934 130.216 49.044 142.977 59.386 139.305L81.3522 131.506C84.9781 130.218 88.9601 130.373 92.4749 131.939L126.802 147.23C134.929 150.85 144.403 146.641 147.165 138.184L147.979 135.69C149.268 131.741 152.137 128.504 155.902 126.75L187.743 111.913C198.612 106.848 194.061 90.467 182.137 91.7343C171.285 92.8876 165.965 78.8826 174.845 72.5395L176.884 71.083C186.901 63.9277 179.839 48.1873 167.834 50.9134L143.966 56.333C134.268 58.5354 127.965 46.4184 135.337 39.7419C142.588 33.1748 136.614 21.2262 127.009 23.0875Z"
        fill="#1B42B2"
        opacity="0.1"
      />
      <path
        clipRule="evenodd"
        d="M135.496 35.3C154.632 54.436 152.416 87.676 130.546 109.546C108.676 131.414 75.4358 133.632 56.2998 114.496C37.1638 95.36 39.3798 62.118 61.2498 40.25C83.1178 18.38 116.36 16.164 135.496 35.3Z"
        fill="#F2F5FC"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M130.546 109.546C110.318 129.772 80.3737 133.172 60.8477 118.432C62.0457 120.018 63.3477 121.544 64.7837 122.98C83.9197 142.116 117.162 139.9 139.032 118.03C160.9 96.1621 163.116 62.9201 143.98 43.7841C142.544 42.3481 141.018 41.0461 139.432 39.8481C154.172 59.3741 150.772 89.3181 130.546 109.546Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M130.546 109.546C110.318 129.772 80.3737 133.172 60.8477 118.432C62.0457 120.018 63.3477 121.544 64.7837 122.98C83.9197 142.116 117.162 139.9 139.032 118.03C160.9 96.1621 163.116 62.9201 143.98 43.7841C142.544 42.3481 141.018 41.0461 139.432 39.8481C154.172 59.3741 150.772 89.3181 130.546 109.546Z"
        fill="white"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M133.374 106.718L141.662 115.006L133.374 106.718Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M133.374 106.718L141.662 115.006"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M133.374 106.718L141.662 115.006L133.374 106.718Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M133.374 106.718L141.662 115.006"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M126.106 113.592L134.592 122.076L126.106 113.592Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M126.106 113.592L134.592 122.076"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M126.106 113.592L134.592 122.076L126.106 113.592Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M126.106 113.592L134.592 122.076"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M139.354 98.554L147.838 107.04L139.354 98.554Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M139.354 98.554L147.838 107.04"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M139.354 98.554L147.838 107.04L139.354 98.554Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M139.354 98.554L147.838 107.04"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M144.18 89.238L152.668 97.726L144.18 89.238Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M144.18 89.238L152.668 97.726"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M144.18 89.238L152.668 97.726L144.18 89.238Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M144.18 89.238L152.668 97.726"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M117.818 119.446L126.304 127.93L117.818 119.446Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M117.818 119.446L126.304 127.93"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M117.818 119.446L126.304 127.93L117.818 119.446Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M117.818 119.446L126.304 127.93"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M108.208 123.978L116.694 132.462L108.208 123.978Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M108.208 123.978L116.694 132.462"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M108.208 123.978L116.694 132.462L108.208 123.978Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M108.208 123.978L116.694 132.462"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M94.478 127.218L102.964 135.704L94.478 127.218Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M94.478 127.218L102.964 135.704"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M94.478 127.218L102.964 135.704L94.478 127.218Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M94.478 127.218L102.964 135.704"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M79.896 126.78L88.298 135.18L79.896 126.78Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M79.896 126.78L88.298 135.18"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M79.896 126.78L88.298 135.18L79.896 126.78Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M79.896 126.78L88.298 135.18"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M147.894 75.9819L156.38 84.4679L147.894 75.9819Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M147.894 75.9819L156.38 84.4679"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M147.894 75.9819L156.38 84.4679L147.894 75.9819Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M147.894 75.9819L156.38 84.4679"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M148.176 62.1221L156.676 70.6221L148.176 62.1221Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M148.176 62.1221L156.676 70.6221"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M148.176 62.1221L156.676 70.6221L148.176 62.1221Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M148.176 62.1221L156.676 70.6221"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M126.336 44.4581C141.046 59.1681 139.344 84.7201 122.532 101.532C105.72 118.342 80.1676 120.046 65.4576 105.336C50.7476 90.6261 52.4516 65.0741 69.2636 48.2641C86.0736 31.4521 111.628 29.7481 126.336 44.4581Z"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeDasharray="1.98 5.95"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M156.14 129.14C156.14 132.456 152.562 135.14 148.14 135.14L156.14 129.14Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M46.1396 125.14C46.1396 129.562 49.7176 133.14 54.1396 133.14M154.14 33.1401C154.14 27.6121 150.562 23.1401 146.14 23.1401M154.14 111.14C154.14 122.194 145.194 131.14 134.14 131.14M156.14 129.14C156.14 132.456 152.562 135.14 148.14 135.14"
        stroke="#FF5226"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default CoinAlternativeIcon;
