import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@config/hooks';

type initialStateT = {
  updatePromoCode: boolean;
};
const initialState: initialStateT = {
  updatePromoCode: false,
};

const salesManagoSlice = createSlice({
  name: 'salesManagoSlice',
  initialState,
  reducers: {
    updateSalesManagoEvent: (
      state,
      action: PayloadAction<{ event: keyof initialStateT; value: boolean }>
    ) => {
      const { event, value } = action.payload;
      state[event] = value;
    },
  },
});
export const { updateSalesManagoEvent } = salesManagoSlice.actions;

export default salesManagoSlice.reducer;

export const getSalesManagoSlice = (state: RootState) => state.salesManagoSlice;
