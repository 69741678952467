import { useGetDietTagInfoAllQuery } from '../../../../services/api/form/open/dietTagInfo.api';
import { findDietTagImg } from '../../../../utils';

type Props = {
  calories: number;
  dietImageUrl: string;
  dietName: string;
  dietOptionName: string;
  dietTag: string;
};

const YourOrder = ({
  calories,
  dietImageUrl,
  dietName,
  dietOptionName,
  dietTag,
}: Props) => {
  const { data: dietTagsData } = useGetDietTagInfoAllQuery();

  return (
    <div className="steps-page-second__wrapper">
      <div
        className="steps-page__image"
        style={{
          background: `url(${
            dietImageUrl || findDietTagImg(dietTag, dietTagsData)
          }) center center no-repeat`,
          backgroundSize: 'cover',
        }}
      />
      <div>
        <p className="label-l color-gray-800 spacer-bottom-4">{dietName}</p>
        <p className="body-m">
          {calories} kcal, {dietOptionName}
        </p>
      </div>
    </div>
  );
};

export default YourOrder;
