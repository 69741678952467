import styles from '../styles/SummaryPrices.module.scss';
import { AnimatePresence, motion } from 'framer-motion';

type Props = {
  isFetching: boolean;
  sizeSmall?: boolean;
  text?: string;
};

const RecalculatePriceLoader = ({
  isFetching,
  sizeSmall,
  text = 'Przeliczamy cenę Twojego zamówienia',
}: Props) => {
  return (
    <AnimatePresence>
      {isFetching && (
        <motion.div
          animate={{ opacity: 1 }}
          className={
            sizeSmall
              ? styles['recalculate-price--small']
              : styles['recalculate-price']
          }
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          transition={{ duration: 0.1 }}
        >
          <div className={styles['custom-loader']} />

          <p className="label-s spacer-top-8">{text}</p>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default RecalculatePriceLoader;
