import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { xLauncherTypeHeader } from '../../const/api.const';

const server = sessionStorage.getItem('server-url') || '';
const baseUrl = `${server}/api` || '';

const customBaseQuery = fetchBaseQuery({
  baseUrl,
  credentials: 'include',
  prepareHeaders: async (headers, { getState }) => {
    headers.set('X-Launcher-Type', xLauncherTypeHeader);
    const {
      //@ts-expect-error
      general: {
        config: { server: { apiKey = '', companyId = '' } = {} } = {},
      } = {},
    } = getState();
    if (apiKey && companyId) {
      headers.set('api-key', apiKey);
      headers.set('company-id', companyId);
    }
    return headers;
  },
});

export const formApi = createApi({
  baseQuery: customBaseQuery,
  endpoints: () => ({}),
  reducerPath: 'formApi',
  tagTypes: ['Profile'],
  keepUnusedDataFor: 3600,
});
