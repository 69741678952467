interface ArrowLeft1IconProps {
  color?: string;
  width?: string;
  height?: string;
}

const ArrowLeft1Icon = ({
  color = 'black',
  height = '24',
  width = '24',
}: ArrowLeft1IconProps) => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.07501 11.6202C6.12261 11.4974 6.19397 11.3853 6.28501 11.2902L11.285 6.29019C11.3783 6.19695 11.4889 6.12299 11.6108 6.07253C11.7326 6.02207 11.8632 5.99609 11.995 5.99609C12.2613 5.99609 12.5167 6.10188 12.705 6.29019C12.7983 6.38342 12.8722 6.49411 12.9227 6.61594C12.9731 6.73776 12.9991 6.86833 12.9991 7.00019C12.9991 7.26649 12.8933 7.52188 12.705 7.71019L9.40501 11.0002H16.995C17.2602 11.0002 17.5146 11.1055 17.7021 11.2931C17.8897 11.4806 17.995 11.735 17.995 12.0002C17.995 12.2654 17.8897 12.5198 17.7021 12.7073C17.5146 12.8948 17.2602 13.0002 16.995 13.0002H9.40501L12.705 16.2902C12.7987 16.3831 12.8731 16.4937 12.9239 16.6156C12.9747 16.7375 13.0008 16.8682 13.0008 17.0002C13.0008 17.1322 12.9747 17.2629 12.9239 17.3848C12.8731 17.5066 12.7987 17.6172 12.705 17.7102C12.6121 17.8039 12.5014 17.8783 12.3796 17.9291C12.2577 17.9798 12.127 18.006 11.995 18.006C11.863 18.006 11.7323 17.9798 11.6104 17.9291C11.4886 17.8783 11.378 17.8039 11.285 17.7102L6.28501 12.7102C6.19397 12.6151 6.12261 12.5029 6.07501 12.3802C5.975 12.1367 5.975 11.8636 6.07501 11.6202Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowLeft1Icon;
