import {
  calculatedChangedDaysPriceSum,
  calculatedUnchangedDaysPriceSum,
  currentDiscountDetails,
} from 'utilsRoot/orderFormPrices';

export const changeNumberOfSets = (
  order,
  operation,
  dispatch,
  changeSetsInBasket
) => {
  const previousNumberOfSets = order && order.sets;
  let currentNumberOfSets;
  if (operation === 'add') {
    currentNumberOfSets = previousNumberOfSets + 1;
    return dispatch(changeSetsInBasket(order?.orderId, currentNumberOfSets));
  }
  currentNumberOfSets = previousNumberOfSets - 1;
  return (
    previousNumberOfSets > 1 &&
    dispatch(changeSetsInBasket(order?.orderId, currentNumberOfSets))
  );
};

export const deleteItemFromSessionStorage = id => {
  const item = localStorage.getItem('diet-basket-form');
  const parsedItem = item && JSON.parse(item);

  if (parsedItem && parsedItem.length > 0) {
    const newBasket = parsedItem.filter(cartItem => cartItem.orderId !== id);
    return localStorage.setItem('diet-basket-form', JSON.stringify(newBasket));
  }
  return null;
};

export const checkBasketForZeroPrices = (basket, sectorId) => {
  return basket?.every(basketItem => {
    return basketItem.selectedDietCaloriesIdPrices
      .filter(sectorItem => sectorItem?.sectorId === sectorId)
      .every(priceItem => priceItem?.price > 0);
  });
};

export const checkIfCaloriesDietIsInMenuConfiguration = (
  selectedCaloriesId,
  companyDietCaloriesMenuConfiguration
) =>
  companyDietCaloriesMenuConfiguration.some(
    item => item.dietCaloriesId === selectedCaloriesId
  );

export const calculateMealExchangePrice = (
  mealDays,
  days,
  meals,
  minimum,
  discounts,
  code,
  sets
) => {
  const mealExchangeDaysArray = Object.values(mealDays || {});
  const changedDaysCount = mealExchangeDaysArray.length;
  const unchangedDaysCount = days - changedDaysCount;

  return (
    (calculatedUnchangedDaysPriceSum(
      meals,
      minimum,
      unchangedDaysCount,
      currentDiscountDetails(discounts, days),
      code
    ) +
      calculatedChangedDaysPriceSum(
        mealExchangeDaysArray,
        meals,
        minimum,
        currentDiscountDetails(discounts, days),
        code
      )) *
    sets
  );
};

export const sumSideOrders = (sideOrders, days, mealDays, sets) => {
  const sideOrdersBasicPriceSum = sideOrders * days;
  const sideOrdersPriceAdjustment = Object.values(mealDays || {}).reduce(
    (acc, curr) => {
      acc = acc + curr.dayPriceAdjustment.sideOrders;
      return acc;
    },
    0
  );
  return (sideOrdersBasicPriceSum + sideOrdersPriceAdjustment) * sets;
};
