export enum DiscountTypeE {
  TOTAL = 'TOTAL',
  PERCENTAGE = 'PERCENTAGE',
}

export enum CodeE {
  Global = 'GLOBAL',
  Individual = 'INDIVIDUAL',
}

export enum ScopeTypeE {
  CITY_AWARDED = 'CITY_AWARDED',
  CLIENT_NO_DIETLY_ORDERS = 'CLIENT_NO_DIETLY_ORDERS',
  CLIENT_NO_DIETLY_OR_MOBILE_ORDERS = 'CLIENT_NO_DIETLY_OR_MOBILE_ORDERS',
  CLIENT_NO_MOBILE_ORDERS = 'CLIENT_NO_MOBILE_ORDERS',
  CLIENT_WITH_DIETLY_ORDERS = 'CLIENT_WITH_DIETLY_ORDERS',
  CLIENT_WITH_DIETLY_OR_MOBILE_ORDERS = 'CLIENT_WITH_DIETLY_OR_MOBILE_ORDERS',
  CLIENT_WITH_MOBILE_ORDERS = 'CLIENT_WITH_MOBILE_ORDERS',
  CLIENT_PHONE_NUMBER_CONFIRMED = 'CLIENT_PHONE_NUMBER_CONFIRMED',
  ORDER_MOBILE = 'ORDER_MOBILE',
  ORDER_DIETLY = 'ORDER_DIETLY',
}
