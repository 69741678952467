import { useMedia } from 'react-use';

import {
  getMenuConfActiveDay,
  getMenuConfCustomDeliveryMeals,
} from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.selector';
import {
  changeActiveDay,
  updateMealsData,
} from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.slice';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSelectedDaysISO } from '@redux/selectors/orderFormSelector';
import { useAppDispatch, useAppSelector } from '@config/hooks';
import { scrollUp } from '@utils/common';
import Button from '@components/common/Button';

const NextAndPrevButtons = () => {
  const dispatch = useAppDispatch();

  const activeDay = useAppSelector(getMenuConfActiveDay);
  const selectedDays = useAppSelector(getSelectedDaysISO);
  const customDeliveryMeals = useAppSelector(getMenuConfCustomDeliveryMeals);

  const isWide = useMedia('(min-width: 768px)', false);

  const shouldDisableNextButton =
    selectedDays.indexOf(activeDay) === selectedDays.length - 1;
  const shouldDisablePreviousButton = selectedDays.indexOf(activeDay) === 0;

  const handleClickPreviousDay = () => {
    scrollUp();
    const previousDay = selectedDays[selectedDays.indexOf(activeDay) - 1];
    const shouldUpdateAlreadyCachedData =
      previousDay && customDeliveryMeals && previousDay in customDeliveryMeals;
    dispatch(changeActiveDay(previousDay));
    if (shouldUpdateAlreadyCachedData) {
      //To służy do inicjalnego zaktualizowania posiłków na już odwiedzonym dniu
      dispatch(updateMealsData({ value: true }));
    }
  };

  const handleClickNextDay = () => {
    scrollUp();
    const nextDay = selectedDays[selectedDays.indexOf(activeDay) + 1];
    const shouldUpdateAlreadyCachedData =
      nextDay && customDeliveryMeals && nextDay in customDeliveryMeals;
    dispatch(changeActiveDay(nextDay));
    if (shouldUpdateAlreadyCachedData) {
      //To służy do inicjalnego zaktualizowania posiłków na już odwiedzonym dniu
      dispatch(updateMealsData({ value: true }));
    }
  };

  return (
    <div className="steps-page__buttons">
      <Button
        buttonLink={!isWide}
        colorBlank={isWide}
        containerClass="color-primary"
        disabled={shouldDisablePreviousButton}
        icon={<FontAwesomeIcon icon={faArrowLeft} />}
        label="Poprzedni dzień"
        onClick={handleClickPreviousDay}
        sizeLarge
      />
      <Button
        buttonLink={!isWide}
        colorBlank={isWide}
        containerClass="color-primary"
        disabled={shouldDisableNextButton}
        icon={<FontAwesomeIcon icon={faArrowRight} />}
        iconRight
        label="Następny dzień"
        onClick={handleClickNextDay}
        sizeLarge
      />
    </div>
  );
};

export default NextAndPrevButtons;
