import React, { KeyboardEvent, MouseEvent, RefObject, useState } from 'react';
import TextTruncate from 'react-text-truncate';

import { selectCustomMeal } from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.slice';
import { MenuConfMenuMealT } from '@features/orderForm/stepMenuConfiguration/types/menuConf.type';
import {
  applyReviewColor,
  scrollToNextMeal,
} from '@features/orderForm/stepMenuConfiguration/utils/menuConf.utils';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuConfMealDetailsModal from '@features/orderForm/stepMenuConfiguration/views/modals/MenuConfMealDetailsModal';
import { useAppDispatch } from '@config/hooks';
import { classNamesUtil } from '@dietly/design-system-library';
import CustomLightbox from '@components/common/CustomLightbox';
import { InfoIcon } from '@assets/icons/infoIcon';
import placeholderUrl from '@assets/images/placeholder.jpg';

type Props = {
  carouselId: string;
  carouselName: string;
  coursePhotoVisibleInForm: boolean;
  isSelected: boolean;
  meal: MenuConfMenuMealT;
  refs: Record<string, RefObject<HTMLDivElement>>;
  slideHorizontally: () => void;
  visibleIngredients: boolean;
  visibleNutrition: boolean;
};
const CarouselMeal = ({
  carouselId,
  carouselName,
  coursePhotoVisibleInForm = false,
  isSelected,
  meal,
  refs,
  slideHorizontally,
  visibleIngredients,
  visibleNutrition,
}: Props) => {
  const dispatch = useAppDispatch();
  const [index, setIndex] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    details,
    dietCaloriesMealId,
    info = '',
    label = '',
    name = '',
    reviewsNumber = 0,
    reviewsScore = 0,
  } = meal;

  const imageUrl = details ? details.imageUrl || '' : '';

  const handleExchangeMealClick = () => {
    if (isSelected) {
      return;
    }
    dispatch(
      selectCustomMeal({ carouselId, dietCaloriesMealId, name: carouselName })
    );
    scrollToNextMeal(refs, carouselId);
    slideHorizontally();
  };

  const handleOpenModal = (
    e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>
  ) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  return (
    <>
      {isModalOpen && details && (
        <MenuConfMealDetailsModal
          carouselName={carouselName}
          details={details}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          visibleIngredients={visibleIngredients}
        />
      )}
      <div
        className="meal"
        onClick={handleExchangeMealClick}
        onKeyDown={handleExchangeMealClick}
        role="button"
        tabIndex={0}
      >
        {isSelected && (
          <div className="meal__check">
            <FontAwesomeIcon className="font-size-15" icon={faCheck} />
          </div>
        )}

        <div
          className={classNamesUtil('meal__container', {
            'meal__container--active': isSelected,
          })}
        >
          <div className="display-flex">
            {coursePhotoVisibleInForm && (
              <>
                <img
                  alt="menu meal"
                  className="meal__image"
                  height={80}
                  onClick={e => {
                    if (imageUrl) {
                      e.stopPropagation();
                      setIndex(0);
                    }
                  }}
                  src={imageUrl || placeholderUrl}
                  width={80}
                />
                {imageUrl && (
                  <CustomLightbox
                    closeOnClick
                    index={index}
                    isOpen={index !== -1}
                    onClose={() => {
                      setIndex(-1);
                    }}
                    singleImage
                    slides={[{ src: imageUrl }]}
                  />
                )}
              </>
            )}

            <div>
              <div
                className={classNamesUtil('', {
                  'display-flex spacer-bottom-4': !coursePhotoVisibleInForm,
                })}
              >
                <div className="meal__label-wrapper">
                  <div className="meal__label meal__label--gray">{label}</div>
                </div>

                <div
                  className={classNamesUtil('display-flex align-items-center', {
                    'spacer-vertical-4': coursePhotoVisibleInForm,
                  })}
                >
                  {!!reviewsNumber && (
                    <div
                      className={`meal__label ${applyReviewColor(
                        reviewsScore || 0,
                        reviewsNumber,
                        'meal__label--red',
                        'meal__label--yellow',
                        'meal__label--green'
                      )}`}
                    >
                      {reviewsScore}%
                    </div>
                  )}

                  <div className="label-s color-gray-900">
                    {reviewsNumber} opinii
                  </div>
                </div>
              </div>

              <div className="meal__title">
                <TextTruncate
                  element="h5"
                  line={2}
                  text={name}
                  truncateText="…"
                />
              </div>
            </div>
          </div>

          {!!info && visibleNutrition && (
            <div className="meal__info-wrapper" role="button" tabIndex={0}>
              <InfoIcon color="#62748C" height="24" width="24" />

              <div className="color-gray-400">
                <span>{info}</span>
              </div>
            </div>
          )}

          {!!details && visibleNutrition && (
            <div
              className="meal__details"
              onClick={e => handleOpenModal(e)}
              onKeyDown={e => handleOpenModal(e)}
              role="button"
              tabIndex={0}
            >
              Zobacz szczegóły
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CarouselMeal;
