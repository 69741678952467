import { useCallback, useEffect, useState } from 'react';

import {
  CustomDeliveryMealsT,
  MenuConfMealT,
} from '@features/orderForm/stepMenuConfiguration/types/menuConf.type';
import { generateMealsWithoutCaloriesChanges } from '@features/orderForm/stepMenuConfiguration/utils/menuConf.utils';
import { getSelectedCaloriesId } from '@redux/selectors/orderFormSelector';
import { useAppDispatch, useAppSelector } from '@config/hooks';
import { getSelectedCity } from '@redux/selectors/summaryReducer.selectors';
import {
  getMenuConfActiveDay,
  getMenuConfCustomDeliveryMeals,
  getMenuConfDietCaloriesMealIdsToRequest,
  getMenuConfShouldUpdateData,
} from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.selector';
import { useGetMenuConfigurationMealsPerDayQuery } from '@services/api/form/open/order-form/steps/orderFormSteps.api';
import { updateMealsData } from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.slice';

export const usePrepareMealsPerDay = (companyId: string) => {
  // Selectors
  const { cityId } = useAppSelector(getSelectedCity) || {};
  const activeDay = useAppSelector(getMenuConfActiveDay);
  const customDeliveryMeals = useAppSelector(getMenuConfCustomDeliveryMeals);
  const selectedDietCaloriesId = useAppSelector(getSelectedCaloriesId);
  const shouldUpdateMealsData = useAppSelector(getMenuConfShouldUpdateData);
  const dietCaloriesMealIds = useAppSelector(
    getMenuConfDietCaloriesMealIdsToRequest
  );

  // Hooks
  const dispatch = useAppDispatch();
  const [mealsPerDay, setMealsPerDay] = useState<Array<MenuConfMealT>>([]);

  // Queries
  const { data, isSuccess, isUninitialized } =
    useGetMenuConfigurationMealsPerDayQuery(
      {
        cityId,
        companyId,
        dietCaloriesMealIds,
        date: activeDay,
        dietCaloriesId: selectedDietCaloriesId,
      },
      {
        skip:
          !activeDay ||
          !customDeliveryMeals ||
          !(activeDay in customDeliveryMeals) ||
          !selectedDietCaloriesId ||
          !dietCaloriesMealIds ||
          !dietCaloriesMealIds.length,
      }
    );

  // Consts
  const { meals: mealsFromBE = [] } = data || {};
  const customDeliveryMealsForActiveDay = customDeliveryMeals[
    activeDay
  ] as CustomDeliveryMealsT[keyof CustomDeliveryMealsT];
  const mealsOrder = Array.from(
    new Set(mealsFromBE.map(el => el.name))
  ) as Array<string>;

  const generateData = useCallback(() => {
    return generateMealsWithoutCaloriesChanges({
      customDeliveryMealsForActiveDay,
      mealsOrder,
      mealsFromBE,
    });
  }, [customDeliveryMealsForActiveDay, mealsFromBE, mealsOrder]);

  // Effects
  useEffect(() => {
    // Efekt do aktualizowania posiłków na podstawie danych z BE
    if (shouldUpdateMealsData) {
      setMealsPerDay(generateData());
      setTimeout(() => {
        dispatch(updateMealsData({ value: false }));
      }, 1);
    }
    //Daję to bo nie chcę generateData w tablicy zależności - oszczędzam rerenderów
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, shouldUpdateMealsData]);

  return { mealsPerDay, isSuccess, isUninitialized };
};
