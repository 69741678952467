import { useMedia } from 'react-use';

const UseIsWide = (
  dimension: 'max' | 'min' = 'max',
  breakpoint: 'mobile' | 'desktop' = 'mobile'
) =>
  useMedia(
    `(${dimension}-width: ${breakpoint === 'mobile' ? 576 : 767}px)`,
    false
  );

export default UseIsWide;
