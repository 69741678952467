import ProductReturn from '@assets/icons/ProductReturn';
import styles from '../styles/FreeDeliveryFastReturn.module.scss';

export const FreeDeliveryFastReturn = () => {
  return (
    <div className={styles['top-container']}>
      <ProductReturn />
      <div className={styles['container']}>
        <span>Zakup bez ryzyka</span>
        <p>
          Jeśli zrezygnujesz z zamówienia, zwrócimy Ci pieniądze za
          niewykorzystane dni diety
        </p>
      </div>
    </div>
  );
};
