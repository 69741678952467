import React from 'react';
import { CalculatePriceItemT } from '../../../../@types/calculatePrice.type';
import { parseNumber } from '../../../../@utils/parseNumber.util';
import { useAppSelector } from '../../../../config/hooks';
import { getDiscountAmount } from '../../../../redux/selectors/orderFormSelector';

type Props = {
  days: number;
  prices?: CalculatePriceItemT;
};

const SecondStepPrices = ({ days, prices }: Props) => {
  const discountAmount = useAppSelector(getDiscountAmount);

  const {
    perDayDietWithDiscountsCost = 0,
    totalDietWithDiscountsAndSideOrdersCost = 0,
    totalDietWithoutSideOrdersCost = 0,
    totalSideOrdersCost = 0,
  } = prices || {};

  const discountPrice =
    totalDietWithoutSideOrdersCost - totalDietWithDiscountsAndSideOrdersCost;

  const toPay = totalDietWithDiscountsAndSideOrdersCost - totalSideOrdersCost;

  const price = days
    ? {
        discountPrice,
        perDayDietWithDiscountsCost,
        toPay,
        totalDietWithDiscountsAndSideOrdersCost,
        totalDietWithoutSideOrdersCost,
        totalSideOrdersCost,
      }
    : {
        discountPrice: 0,
        perDayDietWithDiscountsCost: 0,
        toPay: 0,
        totalDietWithDiscountsAndSideOrdersCost: 0,
        totalDietWithoutSideOrdersCost: 0,
        totalSideOrdersCost: 0,
      };

  return (
    <>
      <div className="display-flex justify-content-space-between spacer-vertical-16">
        <span className="body-m color-gray-800">Cena za dzień</span>

        <span className="h400 color-primary-400">
          {parseNumber(price.perDayDietWithDiscountsCost)} zł
        </span>
      </div>

      <div className="steps-page__divide" />

      <div className="display-flex justify-content-space-between spacer-vertical-16">
        <span className="body-m color-gray-800">Cena łącznie</span>

        <span className="label-m">
          {parseNumber(price.totalDietWithoutSideOrdersCost)} zł
        </span>
      </div>

      {price.discountPrice > 0 && (
        <div className="display-flex flex-direction-column spacer-vertical-16">
          <div className="display-flex justify-content-space-between">
            <span className="body-m color-gray-800">Zniżka</span>

            <span className="label-m">
              -{parseNumber(price.discountPrice)} zł
            </span>
          </div>

          {discountAmount && (
            <div className="steps-page-second__summary-wrapper">
              <span color="color-info">{discountAmount}</span>
            </div>
          )}
        </div>
      )}

      <div className="steps-page__divide" />

      <div className="display-flex justify-content-space-between spacer-vertical-16">
        <span className="body-m color-gray-800">Do zapłaty</span>

        <div className="display-flex flex-direction-column align-items-end">
          <span className="label-m" data-cy="to-pay">
            {parseNumber(price.toPay)} zł
          </span>

          {price.totalDietWithoutSideOrdersCost > price.toPay && (
            <span className="body-m line-through color-gray-400 spacer-top-4">
              {parseNumber(price.totalDietWithoutSideOrdersCost)} zł
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default SecondStepPrices;
