import {
  faFireFlameCurved,
  faSnowflake,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../../styles/badge.module.scss';
import { UnreachableError } from 'utilsRoot/unreachableError';
import { ThermoE } from '../../types/badge.type';

type Props = {
  thermo: ThermoE;
};
const ThermoBadge = ({ thermo }: Props) => {
  switch (thermo) {
    case ThermoE.COLD:
      return (
        <div className={styles['cold']}>
          <FontAwesomeIcon icon={faSnowflake} />
          Na zimno
        </div>
      );
    case ThermoE.WARM:
      return (
        <div className={styles['hot']}>
          <FontAwesomeIcon icon={faFireFlameCurved} />
          Na ciepło
        </div>
      );
    case ThermoE.COLD_WARM:
      return (
        <div className="display-flex">
          <div className={styles['cold']}>
            <FontAwesomeIcon icon={faSnowflake} />
            Na zimno
          </div>
          <div className={styles['hot']}>
            <FontAwesomeIcon icon={faFireFlameCurved} />
            Na ciepło
          </div>
        </div>
      );
    default:
      throw new UnreachableError(
        thermo,
        `Unexpected thermo type '${thermo}' in ThermoBadge`
      );
  }
};

export default ThermoBadge;
