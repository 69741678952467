import { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { useDispatch } from 'react-redux';
import {
  changeAddressInput,
  changeClientInput,
  selectDeliveryHours,
} from '@redux/actions/summaryActions';
import { getAddressDetails } from '@redux/selectors/summaryReducer.selectors';
import { Select } from '../../../common/select';
import { Input } from '../../../common/Input';
import { Textarea } from '../../../common/textArea';
import { Field } from 'formik';
import { DeliveryMethodEnum } from '@features/pickupPoint/types/deliveryMethod.enum';
import { useGetApiFormSettingsQuery } from '@services/api/form/open/open.api';
import { useAppSelector } from '@config/hooks';
import { getServerConfig } from '@features/general/generalSlice';

type Props = {
  children: any;
  copyCompanyData?: boolean;
  deliveryMethod: any;
  deliveryTimesOptions: any;
  errors: any;
  handleChange: any;
  isHourObligatory: any;
  touched: any;
};

export const AddressDetails = forwardRef(
  (
    {
      children,
      copyCompanyData,
      deliveryMethod,
      deliveryTimesOptions,
      errors,
      handleChange,
      isHourObligatory,
      touched,
    }: Props,
    ref?: any
  ) => {
    const dispatch = useDispatch();
    const { companyId } = useAppSelector(getServerConfig) || {};

    const { data: formSettings } = useGetApiFormSettingsQuery(undefined, {
      skip: !companyId,
    });

    const newAddressDetails = useAppSelector(getAddressDetails);

    const { additionalTexts, orderSettings } = formSettings || {};

    const {
      apartmentNumber,
      buildingNumber,
      city,
      floor,
      gate,
      gateCode,
      note,
      street,
    } = newAddressDetails || {};

    const [timeOfDelivery, setTimeOfDelivery] = useState({});

    const handleInputOnChange = (
      e: ChangeEvent<HTMLInputElement>,
      companyName?: string
    ) => {
      const {
        target: { name, value },
      } = e;

      handleChange(e);

      if (copyCompanyData && companyName) {
        dispatch(changeClientInput(companyName, value));
      }

      dispatch(changeAddressInput(name, value));
    };

    useEffect(() => {
      dispatch(selectDeliveryHours(''));
      setTimeOfDelivery({});
    }, [city, dispatch]);

    return (
      <div>
        {children}
        <div className="steps-page-third__wrapper">
          {/*@ts-ignore*/}
          <Input
            containerClass="spacer-bottom-24"
            errorDown
            errorMsg={touched['street'] && errors.street}
            id="street"
            label="Ulica"
            name="street"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputOnChange(e, 'companyStreet')
            }
            required
            value={street}
          />
          <div className="display-flex">
            {/*@ts-ignore*/}
            <Input
              containerClass="steps-page-third__small-input spacer-bottom-24 spacer-right-24"
              errorDown
              errorMsg={touched['buildingNumber'] && errors.buildingNumber}
              id="buildingNumber"
              label="Nr budynku"
              name="buildingNumber"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputOnChange(e, 'companyBuildingNumber')
              }
              required
              value={buildingNumber}
            />
            {/*@ts-ignore*/}
            <Input
              containerClass="steps-page-third__small-input spacer-bottom-24"
              errorDown
              errorMsg={touched['apartmentNumber'] && errors.apartmentNumber}
              id="apartmentNumber"
              label="Nr mieszkania"
              name="apartmentNumber"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputOnChange(e, 'companyApartmentNumber')
              }
              value={apartmentNumber}
            />
          </div>
        </div>
        <div className="steps-page-third__input-container">
          <div className="steps-page-third__input-wrapper steps-page-third__input-wrapper--right-margin">
            {/*@ts-ignore*/}
            <Input
              containerClass="steps-page-third__small-input spacer-bottom-24 spacer-right-24"
              errorDown
              errorMsg={touched['floor'] && errors.floor}
              id="floor"
              label="Piętro"
              name="floor"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputOnChange(e)
              }
              value={floor}
            />
            {/*@ts-ignore*/}
            <Input
              containerClass="steps-page-third__small-input spacer-bottom-24"
              errorDown
              errorMsg={touched['gate'] && errors.gate}
              id="gate"
              label="Klatka"
              name="gate"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputOnChange(e)
              }
              value={gate}
            />
          </div>
          <div className="steps-page-third__input-wrapper">
            {/*@ts-ignore*/}
            <Input
              containerClass="steps-page-third__small-input steps-page-third__small-input--no-flex spacer-bottom-24 flex-1"
              errorDown
              errorMsg={touched['gateCode'] && errors.gateCode}
              id="gateCode"
              label="Kod domofonu"
              name="gateCode"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputOnChange(e)
              }
              value={gateCode}
            />
            <div className="display-flex label-s color-gray-500 flex-1 spacer-left-24">
              <FontAwesomeIcon
                className="spacer-right-10"
                icon={faExclamationCircle}
              />
              <p className="max-width-175">
                Kod domofonu pomoże dostawcy w bezkontaktowej obsłudze
                zamówienia.
              </p>
            </div>
          </div>
        </div>
        {!!additionalTexts?.address && (
          <p className="body-m spacer-bottom-40">{additionalTexts.address}</p>
        )}
        <div ref={ref}>
          {(() => {
            if (
              orderSettings?.showHourPreference &&
              deliveryMethod !== DeliveryMethodEnum.P
            ) {
              return deliveryTimesOptions?.length ? (
                <Field name="deliveryHour">
                  {/*@ts-ignore*/}
                  {({ field, form }) => (
                    <>
                      <Select
                        containerClass={`steps-page-third__medium-input spacer-bottom-24 ${
                          touched['deliveryHour'] &&
                          errors.deliveryHour &&
                          'steps-page-third__medium-input--error'
                        }`}
                        id="deliveryHour"
                        items={deliveryTimesOptions}
                        // @ts-ignore
                        label={
                          <>
                            Godzina dostawy
                            {isHourObligatory && (
                              <span className="color-error spacer-left-4 ">
                                *
                              </span>
                            )}
                          </>
                        }
                        placeholder="Nie wybrano"
                        selectedItem={
                          field.value ? { name: field.value } : timeOfDelivery
                        }
                        selectedItemKey="name"
                        selectItem={(e: { name: string }) => {
                          form.setFieldValue(field.name, e.name);

                          dispatch(selectDeliveryHours(e.name));

                          setTimeOfDelivery(e);
                        }}
                      />
                      {touched['deliveryHour'] && errors.deliveryHour && (
                        <p className="color-error body-m spacer-top-4 spacer-bottom-24">
                          {touched['deliveryHour'] && errors.deliveryHour}
                        </p>
                      )}
                    </>
                  )}
                </Field>
              ) : (
                <div className="body-m spacer-bottom-24">
                  <div className="label-m spacer-bottom-8 color-gray-500">
                    Godzina dostawy
                  </div>
                  Wybór godziny dostawy w danym mieście jest niedostępny
                </div>
              );
            }
            return null;
          })()}
        </div>
        {orderSettings?.showHourPreference &&
          additionalTexts?.hourPreference &&
          deliveryMethod !== DeliveryMethodEnum.P && (
            <p className="body-m spacer-bottom-40">
              {additionalTexts.hourPreference}
            </p>
          )}
        {/*@ts-ignore*/}
        <Textarea
          containerClass="max-width-465 spacer-top-24"
          error={touched['note'] && errors.note}
          id="note"
          label="Uwagi do adresu"
          maxLength={500}
          name="note"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInputOnChange(e)
          }
          placeholder="Wpisz uwagi dla dostawcy"
          rows="4"
          value={note}
        />

        {!!additionalTexts?.generalOrderInformation && (
          <p className="body-m spacer-top-16">
            {additionalTexts.generalOrderInformation}
          </p>
        )}
      </div>
    );
  }
);
