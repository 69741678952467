import React from 'react';
import PropTypes from 'prop-types';

const SmallReplaceIcon = ({ className, height = 64, width = 64 }) => {
  return (
    <svg
      className={className}
      fill="none"
      height={height}
      viewBox="0 0 64 64"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M7 59C5.35 59 4 57.65 4 56V38C4 36.35 5.35 35 7 35C8.65 35 10 36.35 10 38V56C10 57.65 8.65 59 7 59Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M7 59C5.35 59 4 57.65 4 56V38C4 36.35 5.35 35 7 35C8.65 35 10 36.35 10 38V56C10 57.65 8.65 59 7 59Z"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M7 41C6.45 41 6 40.55 6 40C6 39.45 6.45 39 7 39C7.55 39 8 39.45 8 40C8 40.55 7.55 41 7 41Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M7 45C6.45 45 6 44.55 6 44C6 43.45 6.45 43 7 43C7.55 43 8 43.45 8 44C8 44.55 7.55 45 7 45Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        d="M7 35V6"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M11 6V19C11 21.2 9.2 23 7 23C4.8 23 3 21.2 3 19V6"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M56.9893 59C55.3393 59 53.9893 57.65 53.9893 56V36C53.9893 34.35 55.3393 33 56.9893 33C58.6383 33 59.9893 34.35 59.9893 36V56C59.9893 57.65 58.6383 59 56.9893 59Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M56.9893 59C55.3393 59 53.9893 57.65 53.9893 56V36C53.9893 34.35 55.3393 33 56.9893 33C58.6383 33 59.9893 34.35 59.9893 36V56C59.9893 57.65 58.6383 59 56.9893 59Z"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M56.9893 39C56.4393 39 55.9893 38.55 55.9893 38C55.9893 37.45 56.4393 37 56.9893 37C57.5393 37 57.9893 37.45 57.9893 38C57.9893 38.55 57.5393 39 56.9893 39Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M56.9893 43C56.4393 43 55.9893 42.55 55.9893 42C55.9893 41.45 56.4393 41 56.9893 41C57.5393 41 57.9893 41.45 57.9893 42C57.9893 42.55 57.5393 43 56.9893 43Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M54.9893 33.7832C55.5213 33.3022 56.2183 33.0002 56.9893 33.0002C57.2893 33.0002 57.5743 33.0582 57.8473 33.1412L57.9893 33.0002C61.0673 29.9222 60.9893 25.0002 60.9893 19.0002C60.9893 14.0002 61.5303 6.00024 54.9893 6.00024V33.7832Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M54.9893 33.7832C55.5213 33.3022 56.2183 33.0002 56.9893 33.0002C57.2893 33.0002 57.5743 33.0582 57.8473 33.1412L57.9893 33.0002C61.0673 29.9222 60.9893 25.0002 60.9893 19.0002C60.9893 14.0002 61.5303 6.00024 54.9893 6.00024V33.7832Z"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M50 33C50 42.941 41.941 51 32 51C22.059 51 14 42.941 14 33C14 23.059 22.059 15 32 15C41.941 15 50 23.059 50 33Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M50 33C50 42.941 41.941 51 32 51C22.059 51 14 42.941 14 33C14 23.059 22.059 15 32 15C41.941 15 50 23.059 50 33Z"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M39.91 36.51H35.38C35.1148 36.51 34.8604 36.6154 34.6729 36.8029C34.4854 36.9904 34.38 37.2448 34.38 37.51C34.38 37.7752 34.4854 38.0296 34.6729 38.2171C34.8604 38.4046 35.1148 38.51 35.38 38.51H37.78C36.6769 39.6627 35.2544 40.4593 33.6952 40.7974C32.1359 41.1355 30.5112 40.9996 29.0298 40.4072C27.5483 39.8149 26.2779 38.7931 25.3816 37.4732C24.4853 36.1532 24.0042 34.5955 24 33C24 32.7348 23.8946 32.4804 23.7071 32.2929C23.5196 32.1054 23.2652 32 23 32C22.7348 32 22.4804 32.1054 22.2929 32.2929C22.1054 32.4804 22 32.7348 22 33C22.0053 34.9528 22.5822 36.8613 23.6596 38.49C24.737 40.1187 26.2677 41.3964 28.0627 42.1652C29.8578 42.9341 31.8387 43.1605 33.761 42.8166C35.6833 42.4727 37.4628 41.5735 38.88 40.23V42C38.88 42.2652 38.9854 42.5196 39.1729 42.7071C39.3604 42.8946 39.6148 43 39.88 43C40.1452 43 40.3996 42.8946 40.5871 42.7071C40.7746 42.5196 40.88 42.2652 40.88 42V37.5C40.8775 37.2416 40.7752 36.9943 40.5943 36.8097C40.4135 36.6251 40.1683 36.5177 39.91 36.51ZM32 23C29.4364 23.0073 26.9735 23.9989 25.12 25.77V24C25.12 23.7348 25.0146 23.4804 24.8271 23.2929C24.6396 23.1054 24.3852 23 24.12 23C23.8548 23 23.6004 23.1054 23.4129 23.2929C23.2254 23.4804 23.12 23.7348 23.12 24V28.5C23.12 28.7652 23.2254 29.0196 23.4129 29.2071C23.6004 29.3946 23.8548 29.5 24.12 29.5H28.62C28.8852 29.5 29.1396 29.3946 29.3271 29.2071C29.5146 29.0196 29.62 28.7652 29.62 28.5C29.62 28.2348 29.5146 27.9804 29.3271 27.7929C29.1396 27.6054 28.8852 27.5 28.62 27.5H26.22C27.3225 26.3479 28.7441 25.5515 30.3024 25.2131C31.8607 24.8747 33.4846 25.0097 34.9656 25.6009C36.4466 26.192 37.7172 27.2122 38.6142 28.5306C39.5113 29.849 39.9938 31.4054 40 33C40 33.2652 40.1054 33.5196 40.2929 33.7071C40.4804 33.8946 40.7348 34 41 34C41.2652 34 41.5196 33.8946 41.7071 33.7071C41.8946 33.5196 42 33.2652 42 33C42 31.6868 41.7413 30.3864 41.2388 29.1732C40.7362 27.9599 39.9997 26.8575 39.0711 25.9289C38.1425 25.0003 37.0401 24.2638 35.8268 23.7612C34.6136 23.2587 33.3132 23 32 23Z"
        fill="#06257F"
      />
    </svg>
  );
};

SmallReplaceIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default SmallReplaceIcon;
