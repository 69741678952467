import SmallReplaceIcon from '@assets/icons/smallReplaceIcon';
import CalendarSmallIllustration from '@assets/icons/calendarSmallIllustration';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
type Props = {
  selectedDaysLength: number;
};

const YouCanConfigureMenuLaterMobile = ({ selectedDaysLength = 0 }: Props) => {
  return (
    <>
      <div className="steps-page__info-container">
        <div>
          <SmallReplaceIcon
            className="small-replace-icon"
            height={48}
            width={48}
          />
        </div>
        <p className="steps-page__info-text">
          Możesz skonfigurować menu teraz lub też później w panelu klienta
        </p>
      </div>
      <div className="steps-page__days-container">
        <CalendarSmallIllustration />
        <div className="steps-page__days">
          <p className="label-s color-gray-500 spacer-bottom-4 white-space-nowrap">
            Liczba dni zamówienia:
          </p>
          <div className="steps-page__days-count">
            <p className="label-l color-gray-800">{selectedDaysLength}</p>
            <Link to="/zamowienie">
              <div className="display-flex align-items-center">
                <div className="steps-page__days-count-icon">
                  <FontAwesomeIcon className="color-primary" icon={faPen} />
                </div>
                <p className="body-s color-primary">Zmień</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default YouCanConfigureMenuLaterMobile;
