import NextStepButton from '@components/pages/StepSelectDays/views/NextStepButton';
import useIsWide from '@hooks/useIsWide';
import useCalculateItemPrice from '@services/hook/calculatePrices/useCalculateItemPrice.hook';
import RecalculatePriceLoader from '@features/orderForm/stepSummary/views/RecalculatePriceLoader';
import { parseNumber } from '@utils/parseNumber.util';

const ThirdStepSummaryBar = () => {
  const isMobile = useIsWide();
  const { isFetching, prices } = useCalculateItemPrice();

  const totalDietWithDiscountsAndSideOrdersCost =
    prices?.totalDietWithDiscountsAndSideOrdersCost || 0;

  return isMobile ? (
    <div className="steps-page-second__summary-bar">
      <RecalculatePriceLoader isFetching={isFetching} sizeSmall />
      <div className="display-flex flex-direction-column justify-content-center">
        <p className="summary-bar__price-title">Suma zamówienia:</p>
        <p className="label-l color-gray-800">
          {parseNumber(totalDietWithDiscountsAndSideOrdersCost)} zł
        </p>
      </div>
      <NextStepButton prices={prices} />
    </div>
  ) : null;
};

export default ThirdStepSummaryBar;
