import { checkIsSalesManagoEnabled } from '@salesManago/helpers';
import { salesManagoUpdateCart } from '@salesManago/events/shopCart';
import { SideOrderT } from 'typesRoot/sideOrder.type';
import { useAppSelector } from '@config/hooks';
import { getServerConfig } from '@features/general/generalSlice';
import { getBasket, getPromoCode } from '@redux/selectors/basketSelector';
import { getSelectedCity } from '@redux/selectors/summaryReducer.selectors';
import { getOneOffsSelectedFees } from '@features/orderForm/stepShoppingCart/redux/oneOffSideOrders.selector';
import useCalculateCartPrice from '@services/hook/calculatePrices/useCalculateCartPrice.hook';

const useSalesManagoUpdateOneOffs = () => {
  const basket = useAppSelector(getBasket);
  const { companyId, salesManago, serverUrl } = useAppSelector(getServerConfig);
  const { code } = useAppSelector(getPromoCode) || {};
  const selectedCity = useAppSelector(getSelectedCity);
  const selectedFees = useAppSelector(getOneOffsSelectedFees) || [];

  const { prices: cartPrices } = useCalculateCartPrice();

  const isSalesManagoEnabled =
    checkIsSalesManagoEnabled(salesManago) && basket?.length;

  const salesManagoAddOneOff = (oneOff: SideOrderT) => {
    if (isSalesManagoEnabled) {
      const feesList = [...selectedFees];
      feesList.push(oneOff);

      salesManagoUpdateCart(
        basket[0],
        code,
        selectedCity,
        feesList,
        serverUrl,
        salesManago,
        companyId,
        cartPrices
      ).catch(e => {
        console.log(e, 'e');
      });
    }
  };

  const salesManagoRemoveOneOff = (oneOff: SideOrderT) => {
    if (isSalesManagoEnabled) {
      const feesList = [...selectedFees].filter(
        item => item.possibleSideOrderId !== oneOff.possibleSideOrderId
      );

      salesManagoUpdateCart(
        basket[0],
        code,
        selectedCity,
        feesList,
        serverUrl,
        salesManago,
        companyId,
        cartPrices
      ).catch(e => {
        console.log(e, 'e');
      });
    }
  };

  return {
    salesManagoAddOneOff,
    salesManagoRemoveOneOff,
  };
};

export default useSalesManagoUpdateOneOffs;
