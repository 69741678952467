import * as React from 'react';
import { preventKeyPress } from '../../utils';
import Button from './Button';
import { classNamesUtil } from 'utilsRoot/classNamesUtil.util';
import MinusCircleIcon from '@assets/icons/MinusCircleIcon';
import PlusCircle1Icon from '@assets/icons/PlusCircle1Icon';

type Props = {
  change?: (e?: any) => void;
  days: string | number;
  decrease?: (e?: any) => void;
  disableDecrease?: boolean;
  disableIncrease?: boolean;
  disableInput?: boolean;
  increase?: (e?: any) => void;
  onClickInput?: (e: any) => void;
  placeholder?: string;
  withoutBorders?: boolean;
};

const DaysAmount = ({
  change,
  days,
  decrease,
  disableDecrease,
  disableIncrease,
  increase,
  onClickInput,
  withoutBorders,
}: Props) => (
  <div className="amount-carousel">
    <Button
      colorBlank={!withoutBorders}
      colorClean={withoutBorders}
      containerClass={classNamesUtil('', {
        'amount-carousel__without-padding': !!withoutBorders,
        'color-primary': !disableDecrease,
      })}
      disabled={disableDecrease}
      icon={<MinusCircleIcon color="var(--primaryColor)" height="20px" width="20px"/>}
      onClick={decrease}
    />
    {withoutBorders ? (
      <span className="label-m color-gray-800 spacer-horizontal-10">
        {days}
      </span>
    ) : (
      <input
        onChange={change}
        onClick={onClickInput}
        onKeyDown={preventKeyPress}
        placeholder="0"
        size={2}
        type="text"
        value={days || 0}
      />
    )}

    <Button
      colorBlank={!withoutBorders}
      colorClean={withoutBorders}
      containerClass={classNamesUtil('', {
        'amount-carousel__without-padding': !!withoutBorders,
        'color-primary': !disableIncrease,
      })}
      disabled={disableIncrease}
      icon={<PlusCircle1Icon color="var(--primaryColor)" height="20px" width="20px"/>}
      onClick={increase}
    />
  </div>
);

export default DaysAmount;
