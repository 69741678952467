import React from 'react';
import { useDispatch } from 'react-redux';
import {
  changeCalculatorStep,
  changeCalculatorValue,
} from '../../../../../../features/dietCalculator/dietCalculatorSlice';
import FemaleIcon from '../../../../../../assets/icons/FemaleIcon';
import MaleIcon from '../../../../../../assets/icons/MaleIcon';
import Button from '../../../../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { classNamesUtil } from '../../../../../../utils/classNamesUtil.util';

const DietCalculatorStepFirst = ({ sex, step }) => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="calculator-modal__mobile-padding">
        <div>
          <h2 className="h300">Podaj swoją płeć</h2>
          <p className="body-m spacer-top-16 spacer-bottom-14">
            Zapotrzebowanie kaloryczne zależy od wielu czynników. Innego wzoru
            używa się wyliczając zapotrzebowanie dla kobiet, innego dla
            mężczyzn.
          </p>
        </div>
        <div className="spacer-top-40">
          <span className="label-m">Jestem:</span>
          <div className="display-flex spacer-top-8">
            <div
              className={classNamesUtil(
                'suggestion__card-sex spacer-right-16',
                {
                  'suggestion__card-sex--active': sex === 'female',
                  'suggestion__card-sex--not-active': sex === 'male',
                }
              )}
              id="female"
              onClick={() => {
                dispatch(
                  changeCalculatorValue({ name: 'sex', value: 'female' })
                );
                dispatch(changeCalculatorStep(2));
              }}
              onKeyDown={() => {
                dispatch(
                  changeCalculatorValue({ name: 'sex', value: 'female' })
                );
                dispatch(changeCalculatorStep(2));
              }}
              role="button"
              tabIndex={0}
            >
              <FemaleIcon className="path-stroke-primaryColor" />
              <span
                className={classNamesUtil('suggestion__card-sex-text', {
                  'suggestion__card-sex-text--not-active': sex === 'male',
                })}
                id="female"
              >
                Kobietą
              </span>
            </div>
            <div
              className={classNamesUtil(
                'suggestion__card-sex spacer-right-16',
                {
                  'suggestion__card-sex--active': sex === 'male',
                  'suggestion__card-sex--not-active': sex === 'female',
                }
              )}
              id="male"
              onClick={() => {
                dispatch(changeCalculatorValue({ name: 'sex', value: 'male' }));
                dispatch(changeCalculatorStep(2));
              }}
              onKeyDown={() => {
                dispatch(
                  changeCalculatorValue({ name: 'sex', value: 'female' })
                );
                dispatch(changeCalculatorStep(2));
              }}
              role="button"
              tabIndex={0}
            >
              <MaleIcon className="path-stroke-primaryColor" />
              <span
                className={classNamesUtil('suggestion__card-sex-text', {
                  'suggestion__card-sex-text--not-active': sex === 'female',
                })}
                id="male"
              >
                Mężczyzną
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="calculator-modal__footer-modal calculator-modal__footer-modal--center">
        <span className="label-s color-gray-900">
          {step}/<span className="color-primary">5</span>
        </span>
        <Button
          containerClass="calculator-modal__footer-modal-button"
          disabled={sex === ''}
          icon={<FontAwesomeIcon icon={faArrowRight} />}
          iconRight
          label="Dalej"
          onClick={() => {
            if (sex !== '') {
              dispatch(changeCalculatorStep(2));
            }
          }}
        />
      </div>
    </>
  );
};

export default DietCalculatorStepFirst;
