import { nanoid } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { ChangeEvent } from 'react';
import { Dispatch } from 'redux';
import { OrderFormT } from 'typesRoot/orderForm.type';
import { FormSettingsT } from 'typesRoot/settings.type';
import { RootState } from '@config/hooks';
import { getServerConfig } from '@features/general/generalSlice';
import {
  addToBasket,
  resetEdition,
  updateItem,
} from '@redux/actions/basketActions';
import {
  deleteLastDay,
  selectOrderDays,
  setOrderDays,
  storeCity,
  storeExtraDays,
  storeNextDay,
} from '@redux/actions/orderFormActions';
import {
  getBasket,
  getBasketEdition,
  getPromoCode,
} from '@redux/selectors/basketSelector';
import { getOrderForm } from '@redux/selectors/orderFormSelector';
import { getSelectedCity } from '@redux/selectors/summaryReducer.selectors';
import { salesManagoAddToCart } from '@salesManago/events/shopCart';
import { checkIsSalesManagoEnabled } from '@salesManago/helpers';
import { formApi } from '@services/api/form';
import { getSelectedSideOrders } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.selector';
import { resetSideOrderState } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.slice';
import { getOneOffsSelectedFees } from '@features/orderForm/stepShoppingCart/redux/oneOffSideOrders.selector';
import { getStepMenuConfigurationSlice } from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.selector';
import { prepareMenuConfigurationData } from '@features/orderForm/stepMenuConfiguration/utils/menuConf.utils';
import { resetMenuConfiguration } from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.slice';
import { getProfileDetails } from '@redux/selectors/profileSelector';
import { CartPricesT } from 'typesRoot/calculatePrice.type';
import { getMillisFromISO } from '@adapters/dates/dates.adapter';

export const handleChangeDays =
  (event: ChangeEvent<HTMLInputElement>) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const orderForm = getOrderForm(getState());

    const { data: formSettings } = await dispatch(
      //TODO: TS
      //@ts-expect-error
      formApi.endpoints.getApiFormSettings.initiate()
    );

    const numberValue = Number(event.target.value);

    // used when user decreases number of days
    if (orderForm.selectedDays.length > 0) {
      if (numberValue < orderForm.days) {
        const slicedSelectedDays = orderForm.selectedDays.slice(0, numberValue);

        dispatch(selectOrderDays(slicedSelectedDays));
      }

      if (numberValue > orderForm.days) {
        const extraDays = selectExtraDays(
          numberValue - orderForm.days,
          orderForm.selectedDays,
          orderForm,
          formSettings
        );

        dispatch(storeExtraDays(extraDays));
      }
    }

    dispatch(setOrderDays(numberValue));
  };

export const getLatestSelectedDay = (selectedDays: Array<number>) => {
  if (selectedDays && selectedDays.length === 0) {
    return 0;
  }

  return (
    selectedDays &&
    selectedDays.reduce((acc, curr) => {
      if (acc > curr) {
        return acc;
      }

      return curr;
    }, 0)
  );
};

export const getFirstSelectedDay = (selectedDays: Array<number>) => {
  if (selectedDays && selectedDays.length === 0) {
    return null;
  }
  return (
    selectedDays &&
    selectedDays.reduce((acc, curr) => {
      if (acc < curr) {
        return acc;
      }
      return curr;
    })
  );
};

export const isDayNotDeliverable = (
  day: DateTime,
  formSettings?: FormSettingsT
) => {
  const noDeliveryDaysInMili =
    (formSettings?.orderSettings.noDeliveryDays &&
      [...formSettings.orderSettings.noDeliveryDays].map(day =>
        getMillisFromISO(day)
      )) ||
    [];

  return noDeliveryDaysInMili.includes(day.toMillis());
};

export const isDayNotDeliverableAndNotEditable = (
  day: DateTime,
  formSettings?: FormSettingsT
) => {
  const noDeliveryDaysAndNotEditableInMili =
    (formSettings?.orderSettings?.noDeliveryDaysForNewOrders &&
      [...formSettings.orderSettings.noDeliveryDaysForNewOrders]?.map(day =>
        getMillisFromISO(day)
      )) ||
    [];

  return noDeliveryDaysAndNotEditableInMili.includes(day.toMillis());
};

export const isDayNotDeliverableLogic = (
  day: DateTime,
  formSettings?: FormSettingsT,
  isNotEditableEnabled = false
) =>
  isNotEditableEnabled
    ? isDayNotDeliverableAndNotEditable(day, formSettings) ||
      isDayNotDeliverable(day, formSettings)
    : isDayNotDeliverable(day, formSettings);

export const isDayOutsideDaysLimit = (
  day: number,
  maximumOrderAdvanceDays?: number | null
) =>
  day >
  DateTime.local()
    .plus({ days: maximumOrderAdvanceDays || 0 })
    .toMillis();

export const checkIfNextWeekendDayAllowed = (
  nextDay: DateTime,
  orderForm: OrderFormT,
  formSettings?: FormSettingsT
) => {
  const weekDay = nextDay.weekday;

  if (
    weekDay === 6 &&
    (!orderForm?.saturdays || !formSettings?.orderSettings.deliveryOnSaturday)
  ) {
    return true;
  }

  return (
    weekDay === 7 &&
    (!orderForm?.sundays || !formSettings?.orderSettings.deliveryOnSunday)
  );
};

// this method is used when adding extra days in the days input
export const selectExtraDays = (
  extraDays: number,
  selectedDays: Array<number>,
  orderForm: OrderFormT,
  formSettings?: FormSettingsT,
  isNotEditableEnabled = false
) => {
  const latestDay = DateTime.fromMillis(getLatestSelectedDay(selectedDays));

  let days = [];
  let count = 0;
  let numberOfDays = 0;
  let extraDeliveryDays = extraDays;

  while (numberOfDays < extraDeliveryDays) {
    const nextDay = latestDay
      .plus({
        days: count + 1,
      })
      .toMillis();

    if (
      isDayOutsideDaysLimit(
        nextDay,
        formSettings?.orderSettings.maximumOrderAdvanceDays
      )
    )
      break;

    if (
      isDayNotDeliverableLogic(
        latestDay.plus({
          days: count + 1,
        }),
        formSettings,
        isNotEditableEnabled
      ) ||
      checkIfNextWeekendDayAllowed(
        latestDay.plus({
          days: count + 1,
        }),
        orderForm,
        formSettings
      )
    ) {
      count++;
      numberOfDays++;
      extraDeliveryDays++;
    } else {
      days.push(latestDay.plus({ days: count + 1 }).toMillis());
      numberOfDays++;
      count++;
    }
  }

  return days;
};

export const getSelectedDaysLength = (selectedDays: Array<number>) =>
  selectedDays && selectedDays.length;

export const validateQuantityOfOrder = (
  basket: Array<{ testOrder: boolean; orderId: string; sets: number }>,
  basketEdition: { isEdited: string },
  orderForm: OrderFormT,
  formSettings?: FormSettingsT
) => {
  const editedTestOrder = basket?.some(
    item => item?.orderId === basketEdition.isEdited
  );

  const basketMaxSetsTestOrder = basket
    ?.filter(item => item?.testOrder)
    ?.reduce((totalSets, currentSet) => {
      return totalSets + currentSet?.sets;
    }, 0);

  const selectedOrderLength = getSelectedDaysLength(orderForm.selectedDays);

  if (formSettings && selectedOrderLength !== 0) {
    if (!orderForm.testOrder) {
      if (
        formSettings.orderSettings.minimumDays &&
        selectedOrderLength < formSettings.orderSettings.minimumDays
      ) {
        return `Minimalny okres zamówienia to ${formSettings.orderSettings.minimumDays} dni.`;
      }

      if (
        formSettings.orderSettings.maximumDays &&
        selectedOrderLength > formSettings.orderSettings.maximumDays
      ) {
        return `Maksymalny okres zamówienia to ${formSettings.orderSettings.maximumDays} dni.`;
      }
    }

    if (formSettings.testOrderSettings.allowTestOrder && orderForm.testOrder) {
      if (
        basketMaxSetsTestOrder >=
          formSettings.testOrderSettings.maximumPerDay &&
        !editedTestOrder
      ) {
        return `Maksymalna liczba zestawów testowych w koszyku to ${formSettings.testOrderSettings.maximumPerDay} zestawy.`;
      }

      if (selectedOrderLength < formSettings.testOrderSettings.minimumDays) {
        return `Minimalny okres zamówienia testowego to ${formSettings.testOrderSettings.minimumDays} dni.`;
      }

      if (selectedOrderLength > formSettings.testOrderSettings.maximumDays) {
        return `Maksymalny okres zamówienia testowego to ${formSettings.testOrderSettings.maximumDays} dni.`;
      }

      if (orderForm.sets > formSettings.testOrderSettings.maximumPerDay) {
        return `Maksymalny liczba zestawów testowych to ${formSettings.testOrderSettings.maximumPerDay} zestawy.`;
      }
    }
  }

  return '';
};

export const setDays =
  (direction: 'minus' | 'plus') =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const { days } = getOrderForm(getState()) || {};

    if (direction === 'minus' && +days > 1) {
      return dispatch(setOrderDays(+days - 1));
    }
    if (direction === 'plus') {
      dispatch(setOrderDays(+days + 1));
    }
    return null;
  };

export const findNextEligibleDay = (
  orderForm: OrderFormT,
  formSettings?: FormSettingsT,
  isNotEditableEnabled = false
) => {
  const latestDay = DateTime.fromMillis(
    getLatestSelectedDay(orderForm.selectedDays)
  );

  let count = 1;

  while (
    isDayNotDeliverableLogic(
      latestDay.plus({
        days: count,
      }),
      formSettings,
      isNotEditableEnabled
    ) ||
    checkIfNextWeekendDayAllowed(
      latestDay.plus({
        days: count,
      }),
      orderForm,
      formSettings
    )
  ) {
    count++;
  }

  return latestDay.plus({
    days: count,
  });
};

export const addOrSubtractDay =
  (operation: 'add' | 'minus') =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const orderForm = getOrderForm(getState());

    const { data: formSettings } = await dispatch(
      //TODO: TS
      //@ts-expect-error
      formApi.endpoints.getApiFormSettings.initiate()
    );

    if (operation === 'add') {
      return dispatch(
        storeNextDay(findNextEligibleDay(orderForm, formSettings).toMillis())
      );
    }

    // deletes last day
    return (
      getSelectedDaysLength(orderForm.selectedDays) > 1 &&
      dispatch(deleteLastDay(getLatestSelectedDay(orderForm.selectedDays)))
    );
  };

export const addOrderToBasket =
  (cartPrices: CartPricesT | undefined) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const orderForm = getOrderForm(getState());
    const basket = getBasket(getState());
    const selectedCity = getSelectedCity(getState());
    const promoCode = getPromoCode(getState());
    const selectedFees = getOneOffsSelectedFees(getState());
    const selectedSideOrders = getSelectedSideOrders(getState());
    const stepMenuConfiguration = getStepMenuConfigurationSlice(getState());
    const profile = getProfileDetails(getState());
    const { salesManago, serverUrl } = getServerConfig(getState()) || {};

    const { email = '' } = profile || {};

    const { data: formSettings } = await dispatch(
      //TODO: TS
      //@ts-expect-error
      formApi.endpoints.getApiFormSettings.initiate()
    );

    const menuConfigurationData = prepareMenuConfigurationData(
      stepMenuConfiguration
    );

    const orderData = {
      orderId: nanoid(),
      ...orderForm,
      companyName: formSettings?.companyName,
      catering: formSettings?.generalInformation.cateringName,
      eventId: '',
      ...menuConfigurationData,
      selectedSectorId: selectedCity?.sectorId,
      selectedSideOrders,
    };

    dispatch(storeCity(selectedCity?.sectorId, selectedCity?.name));

    if (basket?.length === 0) {
      localStorage.setItem('selected-city-form', JSON.stringify(selectedCity));
    }

    if (checkIsSalesManagoEnabled(salesManago)) {
      try {
        const response = await salesManagoAddToCart({
          email,
          order: { ...orderForm, amount: orderForm.sets },
          code: promoCode?.code,
          selectedFees,
          serverUrl,
          salesManago,
          companyId: formSettings?.companyId,
          selectedSideOrders,
          cartPrices,
        });
        orderData.eventId = response.eventId;
      } catch (e) {
        console.log(e, 'e');
      }
    }

    dispatch(addToBasket(orderData));
    dispatch(resetMenuConfiguration());
    dispatch(resetSideOrderState());
  };

export const updateItemInBasket =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    const orderForm = getOrderForm(getState());
    const basketEdition = getBasketEdition(getState());
    const selectedSideOrders = getSelectedSideOrders(getState());
    const stepMenuConfiguration = getStepMenuConfigurationSlice(getState());

    const { data: formSettings } = await dispatch(
      //TODO: TS
      //@ts-expect-error
      formApi.endpoints.getApiFormSettings.initiate()
    );

    const menuConfigurationData = prepareMenuConfigurationData(
      stepMenuConfiguration
    );

    if (basketEdition.isEdited) {
      const newItem = {
        ...orderForm,
        catering: formSettings?.generalInformation.cateringName,
        companyName: formSettings?.companyName,
        ...menuConfigurationData,
        selectedSideOrders,
      };
      dispatch(updateItem(basketEdition.isEdited, newItem));

      dispatch(resetEdition());
      dispatch(resetMenuConfiguration());
      dispatch(resetSideOrderState());
    }
  };
