import { OneOffWithoutAmountT } from 'typesRoot/sideOrder.type';
import { classNamesUtil } from '@dietly/design-system-library';
import placeholderUrl from '@assets/images/placeholder.jpg';
import { parsePrice } from 'utilsRoot/index';
import Button from '@components/common/Button';
import CustomModalTS from '@components/common/CustomModalTS';

type Props = {
  isModalOpen: boolean;
  onClose: () => void;
  onSelect: () => void;
  selectedOneOff: OneOffWithoutAmountT;
  setIsModalOpen: (value: boolean) => void;
};
const OneOffDetailsModal = ({
  isModalOpen,
  onClose,
  onSelect,
  selectedOneOff,
  setIsModalOpen,
}: Props) => {
  return (
    <CustomModalTS isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
      <div className="padding-all-24">
        <div
          className={classNamesUtil('modal-additional__image', {
            'modal-additional__image--empty': !selectedOneOff.imageUrl,
          })}
        >
          <img
            alt="one off side order"
            src={selectedOneOff.imageUrl || placeholderUrl}
          />
        </div>
        <h4 className="h400 spacer-bottom-8">{selectedOneOff?.name}</h4>
        <span className="label-l color-success">
          {parsePrice(selectedOneOff?.defaultPrice)} zł
        </span>
        {selectedOneOff?.description && (
          <div className="spacer-top-24">
            <span className="label-m color-gray-500 spacer-bottom-8 display-inline-block">
              Opis
            </span>
            <p className="body-l">{selectedOneOff?.description}</p>
          </div>
        )}
      </div>
      <div className="modal-additional__buttons">
        <Button colorClean label="Zamknij" onClick={onClose} />
        <Button
          colorPrimary
          label="Dodaj do zamówienia"
          onClick={() => {
            onSelect();
            onClose();
          }}
        />
      </div>
    </CustomModalTS>
  );
};

export default OneOffDetailsModal;
