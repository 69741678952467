import React, { useEffect, useState } from 'react';
import BoxStep from '../components/common/BoxStep';
import ProfileIcon from '../assets/icons/ProfileIcon';
import { Link } from 'react-router-dom';
import Button from '../components/common/Button';
import Navigation from '../components/common/navigation';
import { useDispatch } from 'react-redux';
import { getServerConfig } from '../features/general/generalSlice';
import { useEnhancedEcommercePageInfo } from '../hooks/useEnhancedEcommercePageInfo';
import { useAppSelector } from '../config/hooks';

const StepTransactionFailure = () => {
  const dispatch = useDispatch();

  const [paymentUrl, setPaymentUrl] = useState('');

  const { apiKey, companyId, serverUrl } = useAppSelector(getServerConfig);

  useEffect(() => {
    if (serverUrl && companyId && apiKey) {
      const paymentSessionStorage =
        window?.sessionStorage?.getItem('payment-url-form');

      setPaymentUrl(paymentSessionStorage || '');
    }
  }, [serverUrl, companyId, apiKey, dispatch]);

  const { canSend, sendEvent } = useEnhancedEcommercePageInfo();
  useEffect(() => {
    canSend && sendEvent();
  }, [canSend, sendEvent]);

  return (
    <>
      <Navigation />
      <div className="steps-page">
        <div className="steps-page__container">
          <BoxStep>
            <div className="display-flex flex-direction-column align-items-center">
              <ProfileIcon className="profile-icon-color" />
              <h3 className="h300 spacer-top-24 spacer-bottom-8">
                Problem z płatnością
              </h3>
              <p className="body-l spacer-bottom-40 max-width-545 text-align-center">
                Niestety, wystąpił problem z płatnością za zamówienie. Mogło to
                być spowodowane brakiem połączenia internetowego. Sprawdź swoje
                połączenie i spróbuj jeszcze raz.
              </p>
              <div className="steps-page__transaction-page-button-wrapper">
                <Link to="/">
                  <Button colorBlank label="Wróć na stronę główną" sizeMedium />
                </Link>
                <a href={paymentUrl}>
                  <Button label="Ponów płatność" sizeMedium />
                </a>
              </div>
            </div>
          </BoxStep>
        </div>
      </div>
    </>
  );
};

export default StepTransactionFailure;
