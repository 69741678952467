import React from 'react';
import { Helmet } from 'react-helmet';
import {
  getServerConfig,
} from '@features/general/generalSlice';
import { useAppSelector } from '@config/hooks';

const UserComScripts = () => {
  const { userCom } = useAppSelector(getServerConfig) || {};

  return (
    <>
      {userCom?.apiKey && (
        <Helmet>
        <script data-cfasync="false">{`window.civchat = { apiKey: "${userCom?.apiKey}"}`}</script>
        <script
          data-cfasync="false"
          src={`https://${userCom?.appSubdomain}.user.com/widget.js`}
        />
      </Helmet>)}
    </>
  );
};

export default UserComScripts;
