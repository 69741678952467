import React from 'react';

const PigGrey = ({ classes }) => {
  return (
    <svg
      className={classes}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.3 10.8438C3.93979 11.259 3.98486 11.2957 3.60948 11.695C3.15828 12.1741 2.32411 12.3817 1.69091 12.4695C1.47198 12.5013 1.27528 12.6269 1.14745 12.8168C1.01961 13.0066 0.972033 13.2436 1.01599 13.4716L1.63403 16.7455C1.66437 16.9251 2.1459 17.2326 2.27482 17.3244C2.80186 17.7117 3.44644 17.8434 4.00761 18.1628C5.14696 18.8111 5.74195 20.7637 7 21.0016"
        stroke="#8FA1B3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M18.2529 7.01172C21.0284 8.42107 23.1442 11.2517 22.9925 14.6334C22.8614 17.0518 20.9975 19.7994 19.0002 21.0016"
        stroke="#8FA1B3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M7.19971 8.04774C8.06939 7.37476 8.80366 7.13776 9.79985 6.78906"
        stroke="#8FA1B3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M11 21C12.4036 21.0877 13.5911 21.1269 15 21"
        stroke="#8FA1B3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M3.20039 7.43329C3.1943 7.19292 3.33232 6.98302 3.52549 6.93889C4.30119 6.75118 6.23466 6.47034 7.17689 8.00765C8.37211 9.95793 6.77848 11.4206 5.58326 11.4206C4.44422 11.4211 3.30477 10.0915 3.20039 7.43329Z"
        fillRule="evenodd"
        stroke="#8FA1B3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M11 21V22.4286C11 22.7442 10.6744 23 10.2727 23H7.72727C7.32561 23 7 22.7442 7 22.4286V21"
        stroke="#8FA1B3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M15 21V22.25C15 22.6642 15.3256 23 15.7273 23H18.2727C18.6744 23 19 22.6642 19 22.25V21"
        stroke="#8FA1B3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M11.4999 9C10.1369 7.89392 9.56936 6 9.99972 4.5C10.4301 3.00004 11.9999 1.5 13.9998 1.5C15.9997 1.5 17.4999 3 17.9997 4.5C18.4994 6 17.7602 8.0321 16.4999 9"
        stroke="#8FA1B3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M10.5 9.49872C10.5 9.49872 14 7.99869 17 9.49871"
        stroke="#8FA1B3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default PigGrey;
