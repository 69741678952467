import { useCallback, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { useEffectOnce } from 'react-use';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';
import { customFind } from 'utilsRoot/index';
import BoxStep from '../../../../common/BoxStep';
import Button from '../../../../common/Button';
import { faSyncAlt } from '@fortawesome/pro-light-svg-icons/faSyncAlt';
import DeliveryMethod from './DeliveryMethod';
import ExistingAddress from './ExistingAddress';
import LoggedInCustomerData from './LoggedInCustomerData';
import {
  getFinalSumAfterDiscountAndDeliveryFee,
  getPdfFile,
} from '@redux/selectors/basketSelector';
import {
  getProfile,
  getProfileDetails,
} from '@redux/selectors/profileSelector';
import {
  getAddressDetails,
  getNewClientDetails,
  getSelectedProfileAddress,
  getSummaryReducer,
} from '@redux/selectors/summaryReducer.selectors';
import {
  changeClientInput,
  changeDeliveryMethod,
  changePaymentType,
  chooseExistingAddress,
  selectCityOrZipcode,
  selectDeliveryHours,
} from '@redux/actions/summaryActions';
import {
  displayOrderButton,
  getProfileAddressesCitiesIds,
  handlePlacePanelOrder,
  parseDeliveryTime,
} from '../../utils/thirdStepHelpers.utils';
import { Agreements } from '../Agreements';
import { Link, useNavigate } from 'react-router-dom';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import PaymentMethods from '../PaymentMethods';
import Loan from '../Loan';
import { getServerConfig } from '@features/general/generalSlice';
import PickupPoint from './PickupPoint';
import SidebarAddressDetails from './SidebarAddressDetails';
import Sidebar from '../../../../common/Sidebar/Sidebar';
import { DeliveryMethodEnum } from '@features/pickupPoint/types/deliveryMethod.enum';
import { getModalErrorLoading } from '@features/modalError/services/redux/modalError.slice';
import Invoice from '../Invoice';
import {
  useGetApiCityQuery,
  useGetApiFormSettingsQuery,
} from '@services/api/form/open/open.api';
import { useAppSelector } from '@config/hooks';
import { classNamesUtil } from 'utilsRoot/classNamesUtil.util';
import SummaryPrices from '../../../../../features/orderForm/stepSummary/views/summaryPrices/SummaryPrices';
import YourOrderInfo from '../../../../../features/orderForm/stepSummary/views/yourOrderInfo/YourOrderInfo';
import { getTemporarilyDisableOrder } from '@redux/slices/serverOverload.slice';
import useCalculateCartPrice from '@services/hook/calculatePrices/useCalculateCartPrice.hook';

type Props = {
  paymentTypes: Array<string>;
};

export const LoggedInSummary = ({ paymentTypes }: Props) => {
  const hoursRef = useRef() as any;
  const [activePayment, setActivePayment] = useState('ONLINE');
  const [timeOfDelivery, setTimeOfDelivery] = useState({});
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [copyCompanyData, setCopyCompanyData] = useState(false);

  const sendDataToGTM = useGTMDispatch();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const config = useAppSelector(getServerConfig) || {};

  const {
    companyId,
    isCustomPanel,
    loan: { isLoanEnabled, partnerId },
  } = config;

  const {
    data: {
      additionalTexts,
      orderSettings,
      orderSettings: {
        obligatoryHourPreference = false,
        orderingToPickupPointEnabled = false,
        showHourPreference = false,
      } = {},
    } = {},
  } = useGetApiFormSettingsQuery(undefined, {
    skip: !companyId,
  });

  const profileDetails = useAppSelector(getProfileDetails);
  const profile = useAppSelector(getProfile);
  const selectedProfileAddress = useAppSelector(getSelectedProfileAddress);
  const newAddressDetails = useAppSelector(getAddressDetails);
  const summaryReducer = useAppSelector(getSummaryReducer);
  const finalAmountToPayAfterDiscountsAndDeliveryFee = useAppSelector(
    getFinalSumAfterDiscountAndDeliveryFee
  );
  const { hasFile } = useAppSelector(getPdfFile);
  const isPotentialModalErrorLoading = useAppSelector(getModalErrorLoading);
  const newClientDetails = useAppSelector(getNewClientDetails);
  const isOrderButtonTemporarilyDisabled = useAppSelector(
    getTemporarilyDisableOrder
  );

  const { deliveryMethod, pickupPointId } = summaryReducer || {};

  const { prices } = useCalculateCartPrice();

  const {
    apartmentNumber,
    buildingNumber,
    city,
    deliveryHour,
    floor,
    gate,
    gateCode,
    note,
    street,
    zipCode,
  } = newAddressDetails || {};

  const {
    company,
    companyApartmentNumber,
    companyBuildingNumber,
    companyCity,
    companyName,
    companyStreet,
    companyZipCode,
    taxId,
  } = newClientDetails || {};

  const { cities, zipCodes } = profile;

  const {
    companyName: profileCompanyName,
    firstName,
    profileAddresses,
    taxId: profileTaxId,
  } = profileDetails || {};

  const deliveryTimesOptions = city?.deliveryTime?.length
    ? parseDeliveryTime(city.deliveryTime)
    : [];

  const cityIds = getProfileAddressesCitiesIds(profileDetails);

  const { data: supportedCities, isSuccess } = useGetApiCityQuery(cityIds, {
    skip: !companyId || !firstName || cityIds.length === 0,
  });

  const shouldDisableOrderButton =
    !displayOrderButton(summaryReducer, isCustomPanel, 'login') ||
    isPotentialModalErrorLoading ||
    isOrderButtonTemporarilyDisabled;

  useEffect(() => {
    if (isSuccess) {
      dispatch(chooseExistingAddress(0));
    }
  }, [isSuccess, dispatch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mapProfileAddresses = () => {
    if (!profileAddresses) {
      return null;
    }

    return profileAddresses.reduce(
      (acc: Array<any>, curr: { cityId: number }) => {
        const supportedCity = customFind(
          'cityId',
          curr?.cityId
        )(supportedCities);
        acc.push({ ...curr, supportedCity });
        return acc;
      },
      []
    );
  };

  const sortProfileAddresses = useCallback(() => {
    const sortedSupportedAddresses = mapProfileAddresses()
      .filter(
        (address: { supportedCity: { sectorId: number } }) =>
          address?.supportedCity?.sectorId
      )
      .sort((prev: { street: string }, next: { street: string }) => {
        const prevAddress = prev.street;
        const nextAddress = next.street;
        return prevAddress.localeCompare(nextAddress, 'pl', {
          sensitivity: 'accent',
        });
      });
    const sortedUnSupportedAddresses = mapProfileAddresses()
      .filter(
        (address: { supportedCity: { sectorId: number } }) =>
          !address?.supportedCity?.sectorId
      )
      .sort((prev: { street: string }, next: { street: string }) => {
        const prevAddress = prev.street;
        const nextAddress = next.street;
        return prevAddress.localeCompare(nextAddress, 'pl', {
          sensitivity: 'accent',
        });
      });
    return [...sortedSupportedAddresses, ...sortedUnSupportedAddresses];
  }, [mapProfileAddresses]);

  useEffectOnce(() => {
    dispatch(changeDeliveryMethod(DeliveryMethodEnum.E));
  });

  useEffect(() => {
    const supportedAddress =
      sortProfileAddresses().find(address => address.cityId === city.cityId) ||
      sortProfileAddresses()[0];
    if (
      supportedCities &&
      supportedCities?.length > 0 &&
      !selectedProfileAddress &&
      deliveryMethod === DeliveryMethodEnum.E &&
      supportedAddress?.supportedCity?.sectorId
    ) {
      dispatch(selectCityOrZipcode(supportedAddress?.supportedCity, 'city'));
      dispatch(chooseExistingAddress(supportedAddress));
    }
  }, [
    city.cityId,
    deliveryMethod,
    dispatch,
    selectedProfileAddress,
    sortProfileAddresses,
    supportedCities,
  ]);

  useEffect(() => {
    dispatch(selectDeliveryHours(''));
    setTimeOfDelivery({});
  }, [city, dispatch]);

  const isDeliveryHoursSelectionMandatory =
    deliveryMethod !== DeliveryMethodEnum.P && obligatoryHourPreference;

  useEffect(() => {
    if (company && copyCompanyData && selectedProfileAddress) {
      dispatch(
        changeClientInput(
          'companyApartmentNumber',
          selectedProfileAddress?.apartmentNumber
        )
      );

      dispatch(
        changeClientInput(
          'companyBuildingNumber',
          selectedProfileAddress?.houseNumber
        )
      );

      dispatch(
        changeClientInput('companyStreet', selectedProfileAddress?.street)
      );

      const city = cities?.[selectedProfileAddress?.cityId]?.name;

      if (city) {
        dispatch(changeClientInput('companyCity', city));
      }

      const zipCode = zipCodes?.[selectedProfileAddress?.zipCodeId]?.code;

      if (zipCode) {
        dispatch(changeClientInput('companyZipCode', zipCode));
      }
    }
  }, [
    company,
    dispatch,
    selectedProfileAddress,
    copyCompanyData,
    cities,
    zipCodes,
  ]);

  return (
    <>
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      >
        <SidebarAddressDetails
          companyId={companyId}
          setIsSidebarOpen={setIsSidebarOpen}
        />
      </Sidebar>
      <Formik
        enableReinitialize
        initialValues={{
          deliveryMethod: deliveryMethod || '',
          deliveryHour: deliveryHour || '',
          pickupPointId: pickupPointId || '',
          formikCity: city.name || '',
          formikZipCode: zipCode || '',
          street: street || '',
          buildingNumber: buildingNumber || '',
          apartmentNumber: apartmentNumber || '',
          gate: gate || '',
          floor: floor || '',
          gateCode: gateCode || '',
          note: note || '',
          companyName: profileCompanyName || companyName || '',
          companyStreet: companyStreet || '',
          companyCity: companyCity || '',
          companyZipCode: companyZipCode || '',
          companyApartmentNumber: companyApartmentNumber || '',
          companyBuildingNumber: companyBuildingNumber || '',
          company: company || '',
          taxId: profileTaxId || taxId || '',
        }}
        onSubmit={(values, actions) => {
          if (
            deliveryMethod !== DeliveryMethodEnum.P &&
            !values.deliveryHour &&
            !deliveryHour &&
            isDeliveryHoursSelectionMandatory
          ) {
            return actions.setFieldError(
              'deliveryHour',
              'Godzina dostawy jest wymagana'
            );
          }
          dispatch(handlePlacePanelOrder(sendDataToGTM, navigate, prices));
        }}
        render={({ errors, handleChange, handleSubmit, touched }) => (
          <form
            action="https://provemacredit.pl/wniosek_partner"
            id="lbl-loan-form"
            method="POST"
            onSubmit={handleSubmit}
          >
            <div className="steps-page-third">
              <div className="steps-page-third__container">
                <LoggedInCustomerData profileDetails={profileDetails} />

                <BoxStep title="Sposób dostawy">
                  <div className="spacer-top-20">
                    <DeliveryMethod
                      deliveryMethod={deliveryMethod}
                      orderSettings={orderSettings}
                      selectedProfileAddress={selectedProfileAddress}
                      setTimeOfDelivery={setTimeOfDelivery}
                    />

                    <div>
                      {(() => {
                        if (deliveryMethod === DeliveryMethodEnum.E) {
                          return (
                            <ExistingAddress
                              additionalTexts={additionalTexts}
                              deliveryTimesOptions={deliveryTimesOptions}
                              errors={errors}
                              hoursRef={hoursRef}
                              orderSettings={orderSettings}
                              selectedProfileAddress={selectedProfileAddress}
                              setIsSidebarOpen={setIsSidebarOpen}
                              setTimeOfDelivery={setTimeOfDelivery}
                              sortProfileAddresses={sortProfileAddresses}
                              timeOfDelivery={timeOfDelivery}
                              touched={touched}
                            />
                          );
                        }

                        if (
                          orderingToPickupPointEnabled &&
                          deliveryMethod === DeliveryMethodEnum.P
                        ) {
                          return (
                            <PickupPoint
                              city={city}
                              companyId={companyId}
                              deliveryMethod={deliveryMethod}
                              deliveryTimesOptions={deliveryTimesOptions}
                              errors={errors}
                              handleChange={handleChange}
                              hoursRef={hoursRef}
                              orderSettings={orderSettings}
                              selectedProfileAddress={selectedProfileAddress}
                              touched={touched}
                            />
                          );
                        }

                        return null;
                      })()}
                    </div>
                  </div>
                </BoxStep>

                <BoxStep title="Pozostałe">
                  <div className="spacer-top-20">
                    <Invoice
                      apartmentNumber={apartmentNumber}
                      buildingNumber={buildingNumber}
                      city={city}
                      company={company}
                      companyApartmentNumber={companyApartmentNumber}
                      companyBuildingNumber={companyBuildingNumber}
                      companyCity={companyCity}
                      companyName={profileCompanyName || companyName}
                      companyStreet={companyStreet}
                      companyZipCode={companyZipCode}
                      copyCompanyData={copyCompanyData}
                      errors={errors}
                      handleChange={handleChange}
                      setCopyCompanyData={setCopyCompanyData}
                      street={street}
                      taxId={profileTaxId || taxId}
                      touched={touched}
                      zipCode={zipCode}
                    />
                  </div>
                </BoxStep>

                <div className="hidden-down-md">
                  <Link to="/koszyk">
                    <Button
                      icon={<FontAwesomeIcon icon={faArrowLeft} />}
                      label="Wstecz"
                    />
                  </Link>
                </div>
              </div>

              <aside className="steps-page__aside">
                <BoxStep borderTitle smallPaddings title="Twoje zamówienie">
                  <YourOrderInfo />
                </BoxStep>

                <BoxStep borderTitle smallPaddings title="Podsumowanie">
                  <SummaryPrices />

                  <div
                    className={classNamesUtil('payment-methods-wrapper', {
                      'payment-methods-wrapper--without-border': !!hasFile,
                    })}
                  >
                    {!hasFile && (
                      <>
                        {additionalTexts?.payment && (
                          <p className="body-m spacer-bottom-16">
                            {additionalTexts.payment}
                          </p>
                        )}
                        <PaymentMethods
                          activePayment={activePayment}
                          finalAmountToPayAfterDiscountsAndDeliveryFee={
                            finalAmountToPayAfterDiscountsAndDeliveryFee
                          }
                          isLoanEnabled={isLoanEnabled}
                          partnerId={partnerId}
                          paymentTypes={paymentTypes}
                          setActivePayment={setActivePayment}
                        />
                      </>
                    )}

                    {isLoanEnabled &&
                      finalAmountToPayAfterDiscountsAndDeliveryFee > 0 && (
                        <Loan />
                      )}

                    <Agreements loggedInSummary />

                    <Button
                      blockButton
                      disabled={shouldDisableOrderButton}
                      icon={
                        isPotentialModalErrorLoading && (
                          <FontAwesomeIcon icon={faSyncAlt} spin />
                        )
                      }
                      iconRight
                      label={
                        isPotentialModalErrorLoading
                          ? 'Składam zamówienie'
                          : 'Zamów'
                      }
                      onClick={() => {
                        hasFile
                          ? dispatch(changePaymentType('EXTERNAL_VOUCHER'))
                          : dispatch(changePaymentType(activePayment));

                        if (
                          isDeliveryHoursSelectionMandatory &&
                          showHourPreference &&
                          !deliveryHour
                        ) {
                          hoursRef?.current?.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                          });
                        }
                      }}
                      sizeMedium
                      type="submit"
                    />
                  </div>
                </BoxStep>
              </aside>
            </div>
          </form>
        )}
        validationSchema={yup.object().shape({
          apartmentNumber: yup
            .string()
            .when('deliveryMethod', (deliveryMethod, schema) => {
              return deliveryMethod === DeliveryMethodEnum.P &&
                !selectedProfileAddress
                ? schema.max(255, 'Numer mieszkania jest za długi')
                : schema;
            }),
          buildingNumber: yup
            .string()
            .when('deliveryMethod', (deliveryMethod, schema) => {
              return deliveryMethod === DeliveryMethodEnum.P &&
                !selectedProfileAddress
                ? schema
                    .required('Numer domu jest wymagany')
                    .max(255, 'Numer domu jest za długi')
                : schema;
            }),
          company: yup.boolean(),
          companyApartmentNumber: yup.string().when('company', {
            is: true,
            then: yup.string().max(255, 'Numer mieszkania jest za długi'),
          }),
          companyBuildingNumber: yup.string().when('company', {
            is: true,
            then: yup
              .string()
              .max(255, 'Numer domu jest za długi')
              .required('Numer domu jest wymagany'),
          }),
          companyCity: yup.string().when('company', {
            is: true,
            then: yup
              .string()
              .max(255, 'Nazwa miasta jest za długa')
              .required('Miasto jest wymagane'),
          }),
          companyName: yup.string().when('company', {
            is: true,
            then: yup.string().required('Nazwa firmy jest wymagana'),
          }),
          companyStreet: yup.string().when('company', {
            is: true,
            then: yup
              .string()
              .max(255, 'Numer ulicy jest za długi')
              .required('Ulica jest wymagana'),
          }),
          companyZipCode: yup.string().when('company', {
            is: true,
            then: yup
              .string()
              .max(255, 'Numer kodu pocztowego jest za długi')
              .required('Kod pocztowy jest wymagany'),
          }),
          deliveryHour: isDeliveryHoursSelectionMandatory
            ? yup.string().required('Godzina dostawy jest wymagana')
            : yup.string(),
          deliveryMethod: yup.string(),
          floor: yup
            .string()
            .when('deliveryMethod', (deliveryMethod, schema) => {
              return deliveryMethod === DeliveryMethodEnum.P &&
                !selectedProfileAddress
                ? schema.max(10, 'Numer piętra jest za długi')
                : schema;
            }),
          formikCity: yup
            .string()
            .when('deliveryMethod', (deliveryMethod, schema) => {
              return deliveryMethod === DeliveryMethodEnum.P &&
                !selectedProfileAddress
                ? schema.required('Miasto jest wymagane')
                : schema;
            }),
          formikZipCode: yup
            .object()
            .shape({
              code: yup.string(),
              zipCodeId: yup.number(),
            })
            .when('deliveryMethod', (deliveryMethod, schema) => {
              return deliveryMethod === DeliveryMethodEnum.P &&
                !selectedProfileAddress
                ? schema.shape({
                    code: yup.string().required('Kod pocztowy jest wymagany'),
                  })
                : schema;
            }),
          gateCode: yup
            .string()
            .when('deliveryMethod', (deliveryMethod, schema) => {
              return deliveryMethod === DeliveryMethodEnum.P &&
                !selectedProfileAddress
                ? schema.max(255, 'Numer kodu do klatki jest za długi')
                : schema;
            }),
          note: yup
            .string()
            .when('deliveryMethod', (deliveryMethod, schema) => {
              return deliveryMethod === DeliveryMethodEnum.P &&
                !selectedProfileAddress
                ? schema.max(500, 'Notatka jest za długa')
                : schema;
            }),
          pickupPointId: yup
            .number()
            .when('deliveryMethod', (deliveryMethod, schema) => {
              return deliveryMethod === DeliveryMethodEnum.P
                ? schema.required('Punkt odbioru jest wymagany')
                : schema;
            }),
          staircase: yup
            .string()
            .when('deliveryMethod', (deliveryMethod, schema) => {
              return deliveryMethod === DeliveryMethodEnum.P &&
                !selectedProfileAddress
                ? schema.max(10, 'Numer klatki jest za długi')
                : schema;
            }),
          street: yup
            .string()
            .when('deliveryMethod', (deliveryMethod, schema) => {
              return deliveryMethod === DeliveryMethodEnum.P &&
                !selectedProfileAddress
                ? schema
                    .required('Ulica jest wymagana')
                    .max(255, 'Nazwa ulicy jest za długa')
                : schema;
            }),
          taxId: yup.string().when('company', {
            is: true,
            then: yup
              .string()
              .test('nip', 'Wpisz poprawny numer nip', nip => {
                const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
                nip = nip?.replace(/[\s-]/g, '') || '';
                if (nip.length === 10 && parseInt(nip, 10) > 0) {
                  let sum = 0;
                  for (let i = 0; i < 9; i++) {
                    // @ts-ignore
                    sum += nip[i] * weights[i];
                  }
                  return sum % 11 === Number(nip[9]);
                }
                return false;
              })
              .required('Numer nip jest wymagany'),
          }),
        })}
      />
    </>
  );
};

LoggedInSummary.propTypes = {
  companyId: PropTypes.string,
};
