// @ts-nocheck
import { Link } from 'react-router-dom';
import { getFinalSumAfterDiscountAndDeliveryFee } from '../../../../redux/selectors/basketSelector';
import { parsePrice } from '../../../../utils';
import Button from './../../../../components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/pro-light-svg-icons/faSyncAlt';
import { checkBasketForZeroPrices } from '../helpers/basketHelpers';
import ArrowRight1Icon from '../../../../assets/icons/ArrowRight1Icon';
import { useAppSelector } from '../../../../config/hooks';

type Props = {
  activeDietlyPay: boolean;
  basket: {}[];
  nextStepIcon: boolean;
  promoCodeType: 'GLOBAL' | 'INDIVIDUAL';
  selectedCity: {};
  setNextStepIcon: (bool: boolean) => void;
  testOrderNumberOfSetsValidation: boolean;
};

const BasketSummaryBar = ({
  activeDietlyPay,
  basket,
  nextStepIcon,
  promoCodeType,
  selectedCity,
  setNextStepIcon,
  testOrderNumberOfSetsValidation,
}: Props): JSX.Element => {
  const finalAmountToPayAfterDiscountsAndDeliveryFee = useAppSelector(
    getFinalSumAfterDiscountAndDeliveryFee
  );

  return (
    <div className="steps-page-second__summary-bar">
      <div className="display-flex flex-direction-column justify-content-center">
        <p className="summary-bar__price-title">Suma zamówienia:</p>
        <p className="label-l color-gray-800">
          {parsePrice(finalAmountToPayAfterDiscountsAndDeliveryFee)} zł
        </p>
      </div>
      {testOrderNumberOfSetsValidation ||
      !checkBasketForZeroPrices(basket, selectedCity?.sectorId) ||
      (activeDietlyPay &&
        (promoCodeType === 'GLOBAL' || promoCodeType === 'INDIVIDUAL')) ? (
        <Button
          blockButton
          containerClass="spacer-top-24"
          disabled
          icon={<ArrowRight1Icon color="white" />}
          iconRight
          label="Podsumowanie"
          sizeMedium
        />
      ) : (
        <Link
          onClick={event => {
            if (
              testOrderNumberOfSetsValidation ||
              !checkBasketForZeroPrices(basket, selectedCity?.sectorId)
            ) {
              event.preventDefault();
            }
          }}
          to="/platnosc"
        >
          <Button
            blockButton
            containerClass="spacer-top-24"
            icon={
              (nextStepIcon && <FontAwesomeIcon icon={faSyncAlt} spin />) || (
                <ArrowRight1Icon color="white" />
              )
            }
            iconRight
            label="Podsumowanie"
            onClick={() => setNextStepIcon(true)}
            sizeMedium
          />
        </Link>
      )}
    </div>
  );
};

export default BasketSummaryBar;
