import { formApi } from '../index';
import {
  DietTagInfoListT,
  DietTagInfoT,
} from '../../../../@types/dietTagInfo.type';

export const dietTagInfoApi = formApi.injectEndpoints({
  endpoints: build => ({
    getDietTagInfoAll: build.query<DietTagInfoListT, void>({
      query: () => ({
        method: 'GET',
        url: `/form/open/diet-tag-info/all`,
      }),
      keepUnusedDataFor: 3600,
      transformResponse: (response: DietTagInfoListT) =>
        response.sort((prev, next) => prev['name'].localeCompare(next['name'])),
    }),
    getDietTagInfo: build.query<
      DietTagInfoT,
      {
        dietTagId: string;
      }
    >({
      query: ({ dietTagId }) => ({
        method: 'GET',
        url: `/form/open/diet-tag-info`,
        params: {
          dietTagId,
        },
      }),
      keepUnusedDataFor: 3600,
    }),
  }),
});

export const { useGetDietTagInfoAllQuery, useGetDietTagInfoQuery } =
  dietTagInfoApi;
