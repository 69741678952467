import React from 'react';

const MaleIcon = ({ className }) => {
  return (
    <svg
      className={className}
      fill="none"
      height="64"
      viewBox="0 0 64 64"
      width="64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M60 32C60 47.4634 47.4634 60 32 60C16.5366 60 4 47.4634 4 32C4 16.5366 16.5366 4 32 4C47.4634 4 60 16.5366 60 32Z"
        fill="white"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M23.6661 47.9038L15.3115 52.4609C14.8213 52.7283 14.3807 53.0569 13.9707 53.4161C18.8427 57.5221 25.1306 59.9998 32.0006 59.9998C38.8199 59.9998 45.0676 57.5591 49.9248 53.508C49.4768 53.1298 48.9908 52.7895 48.4519 52.5201L39.5056 48.0475C38.3497 47.4695 37.6196 46.2883 37.6196 44.996V41.4849C37.8711 41.1997 38.1585 40.8309 38.4659 40.3977C39.6853 38.6754 40.6077 36.7809 41.2469 34.7935C42.3933 34.4395 43.2386 33.3797 43.2386 32.1203V28.3735C43.2386 27.5494 42.873 26.8129 42.3025 26.2973V20.8812C42.3025 20.8812 43.4151 12.4526 32.0006 12.4526C20.5861 12.4526 21.6987 20.8812 21.6987 20.8812V26.2973C21.1281 26.8129 20.7626 27.5494 20.7626 28.3735V32.1203C20.7626 33.1071 21.2813 33.9757 22.0569 34.4765C22.993 38.5529 25.4444 41.4849 25.4444 41.4849V44.9083C25.4444 46.1572 24.7629 47.3058 23.6661 47.9038Z"
        fill="white"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default MaleIcon;
