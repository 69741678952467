import React from 'react';
import Button from '../../../../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import {
  changeCalculatorStep,
  changeCalculatorValue,
  finishCalculator,
} from '../../../../../../features/dietCalculator/dietCalculatorSlice';
import { useDispatch } from 'react-redux';
import {
  faWeight,
  faSalad,
  faDumbbell,
} from '@fortawesome/pro-regular-svg-icons';
import { classNamesUtil } from '../../../../../../utils/classNamesUtil.util';

const DietCalculatorStepFourth = ({ goal, step }) => {
  const dispatch = useDispatch();

  const handleClick = value => {
    dispatch(changeCalculatorValue({ name: 'goal', value }));
  };

  return (
    <>
      <div className="calculator-modal__mobile-padding">
        <div>
          <h2 className="h300">Jaki jest twój cel?</h2>
          <p className="body-m spacer-top-16 spacer-bottom-14">
            Dzięki tej informacji będziemy mogli zasugerować Ci dietę o
            kaloryczności odpowiedniej dla Twojego zapotrzebowania i celu.
          </p>
        </div>
        <div
          className={classNamesUtil('calculator-modal__card-checkbox', {
            'calculator-modal__card-checkbox--active': goal === 'less',
          })}
          onClick={() => {
            handleClick('less');
          }}
          onKeyDown={() => {
            handleClick('less');
          }}
          role="button"
          tabIndex={0}
        >
          <div className="display-flex align-items-center flex-1">
            <FontAwesomeIcon
              className="color-gray-400 font-size-20 spacer-right-20"
              icon={faWeight}
            />
            <p className="calculator-modal__card-checkbox-title label-m">
              Chcę schudnąć
            </p>
          </div>
        </div>
        <div
          className={classNamesUtil('calculator-modal__card-checkbox', {
            'calculator-modal__card-checkbox--active': goal === 'same',
          })}
          onClick={() => {
            handleClick('same');
          }}
          onKeyDown={() => {
            handleClick('same');
          }}
          role="button"
          tabIndex={0}
        >
          <div className="display-flex align-items-center flex-1">
            <FontAwesomeIcon
              className="color-gray-400 font-size-20 spacer-right-20"
              icon={faSalad}
            />
            <p className="calculator-modal__card-checkbox-title label-m">
              Chcę zdrowo się odżywiać
            </p>
          </div>
        </div>
        <div
          className={classNamesUtil('calculator-modal__card-checkbox', {
            'calculator-modal__card-checkbox--active': goal === 'more',
          })}
          onClick={() => {
            handleClick('more');
          }}
          onKeyDown={() => {
            handleClick('more');
          }}
          role="button"
          tabIndex={0}
        >
          <div className="display-flex align-items-center flex-1">
            <FontAwesomeIcon
              className="color-gray-400 font-size-20 spacer-right-20"
              icon={faDumbbell}
            />
            <p className="calculator-modal__card-checkbox-title label-m">
              Chcę nabrać masy
            </p>
          </div>
        </div>
      </div>
      <div className="calculator-modal__footer-modal calculator-modal__footer-modal--space-between">
        <Button
          colorBlank
          icon={<FontAwesomeIcon icon={faArrowLeft} />}
          label="Wstecz"
          onClick={() => {
            dispatch(changeCalculatorStep(3));
          }}
        />
        <span className="label-s color-gray-900">
          {step}/<span className="color-primary">5</span>
        </span>
        <Button
          disabled={goal === ''}
          icon={<FontAwesomeIcon icon={faArrowRight} />}
          iconRight
          label="Zakończ"
          onClick={() => {
            dispatch(finishCalculator());
          }}
        />
      </div>
    </>
  );
};

export default DietCalculatorStepFourth;
