import { Manager, Popper, Reference } from 'react-popper';
import * as PopperJS from '@popperjs/core';
import { classNamesUtil } from '../../utils/classNamesUtil.util';

type Props = {
  children: React.ReactNode;
  containerClass?: string;
  placementTooltip?: PopperJS.Placement;
  tooltipClass?: string;
  tooltipNone?: boolean;
  tooltipText: string | React.ReactNode;
  tooltipL?: boolean;
  tooltipM?: boolean;
  tooltipS?: boolean;
  tooltipXs?: boolean;
  tooltipXxs?: boolean;
};

const Tooltip = ({
  children,
  containerClass,
  placementTooltip,
  tooltipClass,
  tooltipL,
  tooltipM,
  tooltipNone,
  tooltipS,
  tooltipText = '',
  tooltipXs,
  tooltipXxs,
}: Props) => {
  return (
    <div className={`has-tooltip ${containerClass}`}>
      <Manager>
        <Reference>{({ ref }) => <div ref={ref}>{children}</div>}</Reference>
        <Popper placement={placementTooltip}>
          {({ arrowProps, placement, ref }) => (
            <div
              className={classNamesUtil('tooltip', {
                [`tooltip-${placement}`]: !!placement,
                'tooltip-s': !!tooltipS,
                'tooltip-m': !!tooltipM,
                'tooltip-l': !!tooltipL,
                'tooltip-xs': !!tooltipXs,
                'tooltip-xxs': !!tooltipXxs,
                'display-none': !!tooltipNone,
              })}
              data-placement={placement}
              ref={ref}
            >
              <div className={`tooltip-body ${tooltipClass}`}>
                {tooltipText}
              </div>
              <div className="tooltip-arrow" ref={arrowProps.ref} />
            </div>
          )}
        </Popper>
      </Manager>
    </div>
  );
};

export default Tooltip;
