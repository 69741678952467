import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';
import Button from '../../../common/Button';
import { faSyncAlt } from '@fortawesome/pro-light-svg-icons/faSyncAlt';
import { setOrderDays } from '@redux/actions/orderFormActions';
import { handleAddToCartEnhancedEcommerceData } from 'utilsRoot/enhanceEcommerce';
import {
  getOrderForm,
  getSelectedDaysISO,
  getSelectedProgram,
} from '@redux/selectors/orderFormSelector';
import {
  getBasket,
  getBasketEdition,
  getPromoCode,
} from '@redux/selectors/basketSelector';
import { getSelectedCity } from '@redux/selectors/summaryReducer.selectors';
import { getServerConfig } from '@features/general/generalSlice';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import ArrowRight1Icon from '../../../../assets/icons/ArrowRight1Icon';
import { useAppDispatch, useAppSelector } from '@config/hooks';
import {
  addOrderToBasket,
  updateItemInBasket,
  validateQuantityOfOrder,
} from '../utils/selectDays.utils';
import { useGetApiFormSettingsQuery } from '@services/api/form/open/open.api';
import { AddToOrRemoveFromCartE } from '@features/GA4/types/GA4.type';
import { addToOrRemoveFromCartGA4 } from '@features/GA4/events/ecommerceEvents';
import { checkShouldEraseSideOrders } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.slice';
import { CalculatePriceItemT } from 'typesRoot/calculatePrice.type';
import { useGetSideOrdersWithCoursesQuery } from '@services/api/form/open/possibleSideOrders/possibleSideOrders.api';
import { ProgramTypeE } from 'typesRoot/orderForm.type';
import useUserComAddEvent from '@userCom/hooks/useUserComAddEvent.hook';
import { useSalesManago } from '@salesManago/services/hooks/useSalesManago';
import useCalculateCartPrice from '@services/hook/calculatePrices/useCalculateCartPrice.hook';

type Props = {
  customButtonIcon?: boolean;
  prices: CalculatePriceItemT | undefined;
  setValidationStatus?: (value: { message: string; value: string }) => void;
};

const NextStepButton = ({
  customButtonIcon,
  prices,
  setValidationStatus,
}: Props) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { companyId, enhancedEcommerce } =
    useAppSelector(getServerConfig) || {};

  const { data: formSettings } = useGetApiFormSettingsQuery(undefined, {
    skip: !companyId,
  });

  const { prices: allPrices } = useCalculateCartPrice();

  const { trigger: triggerUserComAddEvent } = useUserComAddEvent(prices);
  const { triggerSalesManagoUpdateCart } = useSalesManago();

  //Selectors
  const orderForm = useAppSelector(getOrderForm);
  const basket = useAppSelector(getBasket);
  const basketEdition = useAppSelector(getBasketEdition);
  const selectedCity = useAppSelector(getSelectedCity);
  const promoCode = useAppSelector(getPromoCode);
  const selectedProgram = useAppSelector(getSelectedProgram);
  const selectedDaysISO = useAppSelector(getSelectedDaysISO);

  const { data: sideOrdersWithCourses } = useGetSideOrdersWithCoursesQuery({
    companyId,
  });

  const sideOrdersLength = sideOrdersWithCourses?.length || 0;

  //Destructuring
  const { days, selectedDays } = orderForm || {};

  const { code } = promoCode || {};
  const { isEdited } = basketEdition || {};
  const {
    totalDietWithDiscountsAndSideOrdersCost = 0,
    totalDietWithoutSideOrdersCost = 0,
    totalDietWithSideOrdersCost = 0,
  } = prices || {};

  const sendDataToGTM = useGTMDispatch();

  const [nextStepIcon, setNextStepIcon] = useState(false);

  const buttonValidation = () => {
    if (selectedDaysISO) {
      dispatch(checkShouldEraseSideOrders({ selectedDaysISO }));
    }
    if (days !== selectedDays?.length) {
      dispatch(setOrderDays(selectedDays?.length));
    }

    if (selectedDays?.length === 0) {
      return (
        setValidationStatus &&
        setValidationStatus({
          message: 'Musisz wybrać dni zamówienia, aby przejść dalej',
          value: 'Days not selected',
        })
      );
    }
    if (!totalDietWithoutSideOrdersCost) {
      return (
        setValidationStatus &&
        setValidationStatus({
          message:
            'Brak ceny dla tej diety w systemie. Skontaktuj się z cateringiem.',
          value: 'Price zero',
        })
      );
    }
    if (
      validateQuantityOfOrder(basket, basketEdition, orderForm, formSettings)
    ) {
      return (
        setValidationStatus &&
        setValidationStatus({
          message: validateQuantityOfOrder(
            basket,
            basketEdition,
            orderForm,
            formSettings
          ),
          value: 'Validate quantity of order',
        })
      );
    }

    return true;
  };

  const handleAnalytics = () => {
    triggerUserComAddEvent();
    triggerSalesManagoUpdateCart();
    if (!isEdited && enhancedEcommerce && companyId) {
      sendDataToGTM(
        handleAddToCartEnhancedEcommerceData(
          orderForm,
          selectedCity,
          code,
          totalDietWithSideOrdersCost,
          companyId
        )
      );
      addToOrRemoveFromCartGA4(
        orderForm,
        selectedCity,
        totalDietWithDiscountsAndSideOrdersCost,
        AddToOrRemoveFromCartE.Add
      );
    }
  };

  if (pathname === '/zamowienie' && sideOrdersLength) {
    return !validateQuantityOfOrder(
      basket,
      basketEdition,
      orderForm,
      formSettings
    ) &&
      selectedDays?.length &&
      !!totalDietWithoutSideOrdersCost ? (
      <Link to="/wybierz-dodatki">
        <Button
          blockButton
          containerClass="spacer-top-24"
          icon={
            (nextStepIcon && <FontAwesomeIcon icon={faSyncAlt} spin />) ||
            (!isEdited && <ArrowRight1Icon color="white" />)
          }
          iconRight
          label="Przejdź dalej"
          onClick={buttonValidation}
          sizeMedium
        />
      </Link>
    ) : (
      <Button
        blockButton
        containerClass="spacer-top-24"
        icon={
          (nextStepIcon && <FontAwesomeIcon icon={faSyncAlt} spin />) ||
          customButtonIcon || <ArrowRight1Icon color="white" />
        }
        iconRight
        label="Przejdź dalej"
        onClick={buttonValidation}
        sizeMedium
      />
    );
  }

  const isMenuConfiguration =
    selectedProgram === ProgramTypeE.MenuConfiguration;

  return !validateQuantityOfOrder(
    basket,
    basketEdition,
    orderForm,
    formSettings
  ) &&
    selectedDays?.length > 0 &&
    !!totalDietWithoutSideOrdersCost ? (
    <Link to={isMenuConfiguration ? '/wybierz-posilki' : '/koszyk'}>
      <Button
        blockButton
        containerClass="spacer-top-24"
        icon={
          (nextStepIcon && <FontAwesomeIcon icon={faSyncAlt} spin />) ||
          (!isEdited && <ArrowRight1Icon color="white" />)
        }
        iconRight
        label={
          isMenuConfiguration
            ? 'Konfiguruj menu'
            : isEdited
            ? 'Zapisz zmiany'
            : 'Dodaj do koszyka'
        }
        onClick={() => {
          if (!isMenuConfiguration) {
            isEdited
              ? dispatch(updateItemInBasket())
              : dispatch(addOrderToBasket(allPrices?.cart));

            setNextStepIcon(true);
            handleAnalytics();
          }
        }}
        sizeMedium
      />
    </Link>
  ) : (
    <Button
      blockButton
      containerClass="spacer-top-24"
      icon={
        (nextStepIcon && <FontAwesomeIcon icon={faSyncAlt} spin />) ||
        customButtonIcon || <ArrowRight1Icon color="white" />
      }
      iconRight
      label={
        isMenuConfiguration
          ? 'Konfiguruj menu'
          : isEdited
          ? 'Zapisz zmiany'
          : 'Dodaj do koszyka'
      }
      onClick={buttonValidation}
      sizeMedium
    />
  );
};

export default NextStepButton;
