import {
  DietlyOpenCityT,
  getApiFormOpenCitiesSearch,
  getApiFormOpenCitiesTop,
} from '../../../../services/api/form/open/formOpenCities.api';

export const sanitizeInputValue = (word: string) => {
  if (word.includes('ł')) {
    // @ts-ignore
    const newWord = [...word];
    newWord.map((letter, index) =>
      letter === 'ł' ? newWord.splice(index, 1, 'l') : letter
    );
    return newWord
      .join('')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }
  return word
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
};

export const initiallyFetchTop30Cities = async (
  serverUrl: string,
  apiKey: string,
  companyName: string,
  setTop30Cities: (cities: DietlyOpenCityT[]) => void,
  setInputItems: (cities: DietlyOpenCityT[]) => void,
  setLoader: (b: boolean) => void
) => {
  setLoader(true);
  try {
    const top30Cities = await getApiFormOpenCitiesTop(
      serverUrl,
      apiKey,
      companyName,
      30
    );
    setInputItems(top30Cities);
    setTop30Cities(top30Cities);
  } catch (e) {
    console.log(
      `Failed to fetch initial top 30 cities by catering. Error message: ${e}`
    );
  } finally {
    setLoader(false);
  }
};

export const filterCitiesByCatering = async (
  serverUrl: string,
  apiKey: string,
  companyName: string,
  inputValue: string,
  setInputItems: (cities: DietlyOpenCityT[]) => void,
  setLoader: (b: boolean) => void
) => {
  setLoader(true);
  try {
    const value = sanitizeInputValue(inputValue);
    const filteredCities = await getApiFormOpenCitiesSearch(
      serverUrl,
      apiKey,
      companyName,
      30,
      value
    );
    setInputItems(filteredCities);
  } catch (e) {
    console.log(
      `Failed to fetch cities by catering with supported query. Error message: ${e}`
    );
  } finally {
    setLoader(false);
  }
};
