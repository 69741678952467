import { RefObject, useState } from 'react';

import { useIsCaloriesSwitchPossible } from '@features/orderForm/stepMenuConfiguration/services/hooks/useIsCaloriesSwitchPossible';
import { getMenuConfSelectedMealsAmountPerDay } from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.selector';
import {
  deleteCustomDeliveryMeal,
  updateMealsData,
} from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.slice';
import {
  MenuConfigurationSettingsT,
  MenuConfMenuMealT,
} from '@features/orderForm/stepMenuConfiguration/types/menuConf.type';
import { faSyncAlt, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '@config/hooks';
import MenuConfChangeCaloriesModal from '@features/orderForm/stepMenuConfiguration/views/modals/MenuConfChangeCaloriesModal';

type Props = {
  calories: number;
  carouselId: string;
  carouselName: string;
  isTheOnlyMeal: boolean;
  menuConfigurationSettings?: MenuConfigurationSettingsT;
  options: Array<MenuConfMenuMealT>;
  refs: Record<string, RefObject<HTMLDivElement>>;
};
const OrderFormCarouselMealsTitle = ({
  calories,
  carouselId,
  carouselName,
  isTheOnlyMeal,
  menuConfigurationSettings,
  options,
  refs,
}: Props) => {
  const dispatch = useAppDispatch();
  const selectedMealsAmount = useAppSelector(
    getMenuConfSelectedMealsAmountPerDay
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const caloriesSwitchPossible = useIsCaloriesSwitchPossible(
    carouselId,
    options
  );
  const { minMealsNumber = 0, removingMealsPossible } =
    menuConfigurationSettings || {};

  const handleDeleteMeal = () => {
    // Usuń meala = zaktualizuj customDeliveryMeals, nie pobieraj ceny dodania posiłku (jest w cache), zaktualizuj mealsPerDay
    dispatch(deleteCustomDeliveryMeal(carouselId));
    dispatch(updateMealsData({ value: true, carouselId }));
    // sendEvent(EventName.DeleteMeal);
  };

  const handleChangeCalories = () => {
    setIsModalOpen(true);
  };

  const showRemoveButton =
    (removingMealsPossible || !isTheOnlyMeal) &&
    selectedMealsAmount > minMealsNumber;

  return (
    <>
      {isModalOpen && (
        <MenuConfChangeCaloriesModal
          calories={calories}
          carouselId={carouselId}
          carouselName={carouselName}
          isOpen={isModalOpen}
          refs={refs}
          setIsOpen={setIsModalOpen}
        />
      )}
      <div className="carousel-meals__top-wrapper">
        <div className="display-flex align-items-center">
          <h3>{carouselName}</h3>
          <div className="hidden-down-sm">
            {showRemoveButton && (
              <div
                className="color-gray-500 cursor-pointer spacer-left-16"
                onClick={handleDeleteMeal}
                onKeyDown={handleDeleteMeal}
                role="button"
                tabIndex={0}
              >
                <FontAwesomeIcon className="font-size-16" icon={faTrash} />
              </div>
            )}
          </div>
        </div>
        <div className="hidden-down-sm">
          <span className="carousel-meals__top-wrapper-label">
            {calories} kcal
          </span>
          {caloriesSwitchPossible && (
            <div
              className="label-s color-primary display-inline-block cursor-pointer"
              onClick={handleChangeCalories}
              onKeyDown={handleChangeCalories}
              role="button"
              tabIndex={0}
            >
              <FontAwesomeIcon
                className="spacer-right-4"
                icon={faSyncAlt}
                spin={isModalOpen}
              />
              Zmień kaloryczność
            </div>
          )}
        </div>
        <div className="display-flex align-items-center justify-content-space-between spacer-vertical-16 hidden-up-sm">
          <div className="display-flex align-items-center">
            <span className="carousel-meals__top-wrapper-label">
              {calories} kcal
            </span>
            {caloriesSwitchPossible && (
              <div
                className="label-s color-primary display-inline-block cursor-pointer"
                onClick={handleChangeCalories}
                onKeyDown={handleChangeCalories}
                role="button"
                tabIndex={0}
              >
                <FontAwesomeIcon className="spacer-right-4" icon={faSyncAlt} />
                Zmień kaloryczność
              </div>
            )}
          </div>
          {showRemoveButton && (
            <div
              className="color-gray-500 cursor-pointer"
              onClick={handleDeleteMeal}
              onKeyDown={handleDeleteMeal}
              role="button"
              tabIndex={0}
            >
              <FontAwesomeIcon className="font-size-16" icon={faTrash} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OrderFormCarouselMealsTitle;
