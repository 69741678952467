import { useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import { CalculatePriceRequestT } from '../../api/form/open/shopping-cart.api';
import {
  generateSideOrdersList,
  mapSelectedMeals,
} from '@components/pages/StepSummary/utils/generateOrderData.util';
import { PaymentTypeE } from 'typesRoot/common.enum';
import useCalculatePrice from './useCalculatePrice.hook';
import { DEBOUNCE_CALCULATE_PRICE } from './calculatePrice.const';
import useCalculatePriceRequestsHook from './useCalculatePriceRequests.hook';
import { useAppSelector } from '@config/hooks';
import { getServerConfig } from '@features/general/generalSlice';

const useCalculateItemPrice = () => {
  const { companyId } = useAppSelector(getServerConfig) || {};

  const {
    calculateItemPriceCustomDeliveryMeals,
    deliveryDates,
    isUserLogged,
    newAddressDetails,
    orderForm,
    pickupPointId,
    selectedSideOrders,
    shouldUpdateItemMenuConfData,
  } = useCalculatePrice();

  const {
    orderId,
    selectedCalories,
    selectedMeals,
    selectedProgram,
    testOrder,
  } = orderForm;
  const deliveryDatesDependency = deliveryDates.toString();

  const [data, setData] = useState<CalculatePriceRequestT | null>(null);

  const simpleOrders = useMemo(() => {
    return [
      {
        itemId: orderId,
        deliveryDates,
        customDeliveryMeals: calculateItemPriceCustomDeliveryMeals,
        deliveryMeals: mapSelectedMeals(selectedMeals),
        dietCaloriesId: selectedCalories.dietCaloriesId,
        paymentType: PaymentTypeE.ONLINE,
        pickupPointId,
        sideOrders: generateSideOrdersList(selectedSideOrders),
        testOrder,
      },
    ];
    // Ograniczam niepotrzebnych requestów na calculate-price na kroku konfiguracji menu poprzez ominięcie deliveryDates oraz calculatePriceCustomDeliveryMeals oraz dodanie shouldUpdateItemMenuConfData i deliveryDatesDependency
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deliveryDatesDependency,
    orderId,
    pickupPointId,
    selectedCalories.dietCaloriesId,
    selectedMeals,
    selectedProgram,
    selectedSideOrders,
    shouldUpdateItemMenuConfData,
    testOrder,
  ]);

  const orderData = useMemo(
    () => ({
      promoCodes: [],
      simpleOrders,
      loyaltyProgramPoints: 0,
      loyaltyProgramPointsGlobal: 0,
      companyId,
      cityId: newAddressDetails.city.cityId,
    }),
    [companyId, newAddressDetails.city.cityId, simpleOrders]
  );

  useDebounce(
    () => {
      // @ts-ignore
      setData(orderData);
    },
    DEBOUNCE_CALCULATE_PRICE,
    [orderData]
  );

  const orderHasDates = data?.simpleOrders.every(
    order => order.deliveryDates.length > 0
  );

  const isSkip =
    !data ||
    data?.simpleOrders?.length === 0 ||
    !newAddressDetails.city.cityId ||
    !orderHasDates;

  const { isFetching, prices } = useCalculatePriceRequestsHook(
    data as CalculatePriceRequestT,
    isUserLogged,
    isSkip
  );

  return {
    isFetching,
    prices: prices?.items[orderId],
  };
};

export default useCalculateItemPrice;
