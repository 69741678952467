import { useAppDispatch, useAppSelector } from '@config/hooks';
import {
  getMenuConfActiveDay,
  getMenuConfBaseDeliveryMeals,
  getMenuConfigurationMealsPerDayState,
} from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.selector';
import {
  getSelectedCaloriesId,
  getSelectedDaysISO,
  getSelectedMeals,
} from '@redux/selectors/orderFormSelector';
import { getSelectedCity } from '@redux/selectors/summaryReducer.selectors';
import { useGetMenuConfiguration } from '@features/orderForm/stepMenuConfiguration/services/hooks/useGetMenuConfiguration';
import React, { RefObject } from 'react';
import { useGetMenuConfigurationSettingsQuery } from '@services/api/form/open/order-form/steps/orderFormSteps.api';
import { useEffectOnce } from 'react-use';
import { selectBaseDeliveryMeals } from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.slice';
import {
  checkIsThisTheLastMealOfItsType,
  checkIsThisTheOnlyMealOfItsType,
  prepareLightMeals,
  prepareMenuConfTitle,
} from '@features/orderForm/stepMenuConfiguration/utils/menuConf.utils';
import InfoToast from '@features/orderForm/stepMenuConfiguration/views/InfoToast';
import MenuConfigurationError from '@features/orderForm/stepMenuConfiguration/views/MenuConfigurationError';
import BoxStep from '@components/common/BoxStep';
import MealsAndCaloriesAndSideOrdersSummary from '@features/orderForm/stepMenuConfiguration/views/MealsAndCaloriesAndSideOrdersSummary';
import OrderFormCarouselMeals from '@features/orderForm/stepMenuConfiguration/views/OrderFormCarouselMeals/OrderFormCarouselMeals';
import MenuNotPublishedYet from '@features/orderForm/stepMenuConfiguration/views/MenuNotPublishedYet';
import MenuConfSideOrders from '@features/orderForm/stepMenuConfiguration/views/MenuConfSideOrders';
import YouCanConfigureMenuLaterMobile from '@features/orderForm/stepMenuConfiguration/views/YouCanConfigureMenuLaterMobile';
import NextAndPrevButtons from '@features/orderForm/stepMenuConfiguration/views/NextAndPrevButtons';
import Spinner from '@components/common/Spinner';

type Props = { companyId: string };
const MenuConfiguration = ({ companyId }: Props) => {
  // Selectors
  const activeDay = useAppSelector(getMenuConfActiveDay);
  const selectedDays = useAppSelector(getSelectedDaysISO);
  const baseDeliveryMeals = useAppSelector(getMenuConfBaseDeliveryMeals);
  const selectedMeals = useAppSelector(getSelectedMeals);
  const queryState = useAppSelector(
    getMenuConfigurationMealsPerDayState(activeDay, companyId)
  );
  const dietCaloriesId = useAppSelector(getSelectedCaloriesId);
  const { cityId } = useAppSelector(getSelectedCity);
  const { data, isError } = queryState || {};

  // Hooks
  const dispatch = useAppDispatch();
  const mealsPerDay = useGetMenuConfiguration(companyId) || [];

  // Consts

  const hasCachedDataForActiveDay =
    data && typeof data === 'object' && 'meals' in data;

  const showLoader = !hasCachedDataForActiveDay || mealsPerDay.length === 0;
  const showError = isError && !showLoader;
  const isMenuPublished = mealsPerDay.some(
    someMeal => someMeal.options?.length > 0
  );

  const refs = mealsPerDay.reduce<Record<string, RefObject<HTMLDivElement>>>(
    (acc, curr) => {
      acc[curr.carouselId] = React.createRef<HTMLDivElement>();
      return acc;
    },
    {}
  );

  // Queries
  const { data: menuConfigurationSettings } =
    useGetMenuConfigurationSettingsQuery(
      { cityId, companyId, dietCaloriesId },
      {
        skip: !dietCaloriesId || !cityId,
      }
    );

  // Effects
  useEffectOnce(() => {
    // Zabezpieczenie: gdyby na kroku konfiguracji nie było wybranych posiłków bazowych
    if (!baseDeliveryMeals.length) {
      dispatch(selectBaseDeliveryMeals(prepareLightMeals(selectedMeals)));
    }
  });

  return (
    <div className="steps-page steps-page--meal-exchange-page">
      <div className="steps-page__container">
        <InfoToast
          description="Możesz skonfigurować menu teraz lub później w panelu klienta"
          title="Pamiętaj!"
        />
        {showError ? (
          <MenuConfigurationError />
        ) : showLoader ? (
          <BoxStep mealExchangePage>
            <div className="basic-loader basic-loader--min-height">
              <Spinner />
            </div>
          </BoxStep>
        ) : (
          <>
            <BoxStep
              containerClass="steps-page__box-step-borders"
              mealExchangePage
              noBottomMargin
              overflowHidden
              title={
                activeDay
                  ? `Konfiguruj menu na ${prepareMenuConfTitle(activeDay)}`
                  : ''
              }
            >
              {isMenuPublished && !isError ? (
                <>
                  <MealsAndCaloriesAndSideOrdersSummary
                    mealsPerDay={mealsPerDay}
                  />
                  {mealsPerDay.map((mealOptionsList, index) => {
                    const isThisTheLastMealOfThisType =
                      checkIsThisTheLastMealOfItsType(mealsPerDay, index);
                    const isThisTheOnlyMealOfThisType =
                      checkIsThisTheOnlyMealOfItsType(
                        mealsPerDay,
                        mealOptionsList
                      );
                    return (
                      <div
                        data-cy="menu-configuration-carousel"
                        key={mealOptionsList.carouselId}
                        ref={refs[mealOptionsList.carouselId]}
                      >
                        <OrderFormCarouselMeals
                          companyId={companyId}
                          isLastMeal={isThisTheLastMealOfThisType}
                          isTheOnlyMeal={isThisTheOnlyMealOfThisType}
                          mealOptionsList={mealOptionsList}
                          menuConfigurationSettings={menuConfigurationSettings}
                          refs={refs}
                        />
                      </div>
                    );
                  })}
                </>
              ) : (
                <MenuNotPublishedYet />
              )}

              <MenuConfSideOrders
                companyId={companyId}
                maxMealsNumber={menuConfigurationSettings?.maxMealsNumber || 0}
              />
            </BoxStep>

            <NextAndPrevButtons />
            <YouCanConfigureMenuLaterMobile
              selectedDaysLength={selectedDays.length}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default MenuConfiguration;
