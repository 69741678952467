import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@config/hooks';
import { getSummaryReducer } from '@redux/selectors/summaryReducer.selectors';
import { getProfile } from '@redux/selectors/profileSelector';
import {
  getBasket,
  getTestOrderNumberOfSetsValidation,
} from '@redux/selectors/basketSelector';
import { useUnmount } from 'react-use';
import { storeHistory } from '@redux/actions/orderFormHistoryActions';
import { useNavigate } from 'react-router-dom';
import {
  changeMethodOfPurchase,
  resetSelectedZipCode,
} from '@redux/actions/summaryActions';
import NotLoggedInSummary from '../components/pages/StepSummary/views/NotLoggedInSummary';
import Login from '../components/pages/StepSummary/views/Login';
import { LoggedInSummary } from '@components/pages/StepSummary/views/LoggedIn/LoggedInSummary';
import {
  checkIfContinueParam,
  retrieveDataIfContinueParam,
} from '@components/pages/StepSummary/utils/thirdStepHelpers.utils';
import Navigation from '../components/common/navigation';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/pro-regular-svg-icons';
import ProgressBar from '../components/common/ProgressBar';
import { getServerConfig } from '@features/general/generalSlice';
import { useEnhancedEcommercePageInfo } from '@hooks/useEnhancedEcommercePageInfo';
import { useGetApiFormSettingsQuery } from '@services/api/form/open/open.api';
import useFetchProfile from '../hooks/useFetchProfile';

const StepSummary = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sendDataToGTM = useGTMDispatch();

  const summaryReducer = useAppSelector(getSummaryReducer);
  const profileReducer = useAppSelector(getProfile);
  const basket = useAppSelector(getBasket);
  const testOrderNumberOfSetsValidation = useAppSelector(
    getTestOrderNumberOfSetsValidation
  );
  const { companyId, enhancedEcommerce, loginEnabled } =
    useAppSelector(getServerConfig);

  const { data: { paymentInformation: { paymentTypes = [] } = {} } = {} } =
    useGetApiFormSettingsQuery(undefined, {
      skip: !companyId,
    });

  const { methodOfPurchase, newAddressDetails, paymentType, paymentUrl } =
    summaryReducer || {};

  const { profile } = profileReducer || {};

  useEffect(() => {
    if (testOrderNumberOfSetsValidation) {
      navigate('/koszyk', { replace: true });
    }
  }, [navigate, testOrderNumberOfSetsValidation]);

  const { isFetching } = useFetchProfile(companyId);

  useEffect(() => {
    if (companyId) {
      dispatch(storeHistory(4));

      const storageBasket = JSON.parse(
        // @ts-ignore
        localStorage.getItem('diet-basket-form')
      );

      if (!storageBasket?.length) {
        navigate('/', { replace: true });
      }
    }
  }, [companyId, dispatch, navigate]);

  useEffect(() => {
    if (newAddressDetails?.city?.cityId && companyId) {
      dispatch(resetSelectedZipCode());
    }
  }, [newAddressDetails?.city?.cityId, companyId, dispatch]);

  useEffect(() => {
    if (paymentUrl && paymentType === 'ONLINE') {
      sessionStorage.setItem(
        'payment-url-form',
        (window.location = paymentUrl)
      );

      window.location = paymentUrl;
    }
  }, [paymentType, paymentUrl]);

  useEffect(() => {
    if (companyId) {
      dispatch(retrieveDataIfContinueParam());
    }
  }, [companyId, dispatch]);

  useEffect(() => {
    if (enhancedEcommerce && basket?.length && checkIfContinueParam()) {
      setTimeout(() => {
        sendDataToGTM({
          event: 'GAEvent',
          eventCategory: 'Login',
          eventAction: 'Login success',
          eventLabel: 'N/A',
        });
      });
    }
  }, [basket?.length, enhancedEcommerce, sendDataToGTM]);

  const { canSend, sendEvent } = useEnhancedEcommercePageInfo();

  useEffect(() => {
    canSend && sendEvent();
  }, [canSend, sendEvent]);

  useUnmount(() => {
    loginEnabled && dispatch(changeMethodOfPurchase(''));
  });

  return (
    <>
      <Navigation />

      <ProgressBar />

      <div className="steps-page">
        <div className="steps-page__container">
          {(() => {
            if (isFetching) {
              return (
                <section className="steps-page__loader">
                  <FontAwesomeIcon
                    className="font-size-60 color-primary"
                    icon={faSync}
                    spin
                  />
                </section>
              );
            }

            if (!methodOfPurchase) {
              return <Login />;
            }

            if (
              methodOfPurchase === 'login' &&
              profile?.email &&
              loginEnabled
            ) {
              return <LoggedInSummary paymentTypes={paymentTypes} />;
            }

            return (
              <NotLoggedInSummary
                companyId={companyId}
                paymentTypes={paymentTypes}
              />
            );
          })()}
        </div>
      </div>
    </>
  );
};

export default StepSummary;
