import { formApi } from '../index';
import {
  CalculatePriceItemT,
  CalculatePriceT,
  CartPricesT,
} from 'typesRoot/calculatePrice.type';
import { SimpleOrdersT } from '@features/orderForm/stepSummary/types/stepSummary.type';
import Cookies from 'universal-cookie';
import { GUEST_COOKIE_NAME } from '@hooks/useGuestCookie.hooks';

const cookies = new Cookies()

export type CalculatePriceRequestT = {
  promoCodes: Array<string> | null;
  simpleOrders: Array<
    Omit<
      SimpleOrdersT,
      'addressId' | 'profileAddressId' | 'invoice' | 'note' | 'hourPreference'
    >
  >;
  loyaltyProgramPoints: number;
  loyaltyProgramPointsGlobal: number;
  companyId: string;
  cityId: number;
};

const normalizedPrices = (res: {
  items: Array<CalculatePriceItemT>;
  cart: CartPricesT;
}) => ({
  ...res,
  items: res.items.reduce(
    (
      acc: Record<CalculatePriceItemT['itemId'], CalculatePriceItemT>,
      curr: CalculatePriceItemT
    ) => {
      acc[curr.itemId] = curr;
      return acc;
    },
    {}
  ),
});

export const shoppingCart = formApi.injectEndpoints({
  endpoints: build => ({
    postOpenOrder: build.mutation({
      query: body => ({
        method: 'POST',
        url: 'form/open/shopping-cart/order',
        body,
        headers: {
          'company-id': body.companyId,
          'x-guest-session': cookies.get(GUEST_COOKIE_NAME)
        },
      }),
    }),
    // @ts-ignore
    postProfileOrder: build.mutation({
      query: body => ({
        method: 'POST',
        url: 'form/profile/shopping-cart/order',
        body,
        headers: {
          'company-id': body.companyId,
          'x-guest-session': cookies.get(GUEST_COOKIE_NAME)
        },
      }),
    }),
    getOpenCalculatePrice: build.query<CalculatePriceT, CalculatePriceRequestT>(
      {
        query: body => ({
          method: 'POST',
          url: 'form/open/shopping-cart/calculate-price',
          body,
          headers: {
            'company-id': body.companyId,
            'x-guest-session': cookies.get(GUEST_COOKIE_NAME)
          },
        }),
        transformResponse: (res: {
          items: Array<CalculatePriceItemT>;
          cart: CartPricesT;
        }) => normalizedPrices(res),
        keepUnusedDataFor: 3600,
      }
    ),
    getProfileCalculatePrice: build.query<
      CalculatePriceT,
      CalculatePriceRequestT
    >({
      query: body => ({
        method: 'POST',
        url: 'form/profile/shopping-cart/calculate-price',

        body,
        headers: {
          'company-id': body.companyId,
          'x-guest-session': cookies.get(GUEST_COOKIE_NAME)
        },
      }),
      transformResponse: (res: {
        items: Array<CalculatePriceItemT>;
        cart: CartPricesT;
      }) => normalizedPrices(res),
      keepUnusedDataFor: 3600,
    }),
  }),
});

export const {
  useGetOpenCalculatePriceQuery,
  useGetProfileCalculatePriceQuery,
} = shoppingCart;

export const { postOpenOrder, postProfileOrder } = shoppingCart.endpoints;
