import { useCallback, useEffect } from 'react';
import './style.scss';
import { Route, Routes } from 'react-router-dom';
import StepSelectDiet from './pages/StepSelectDiet';
import StepSelectDays from './pages/StepSelectDays';
import StepSelectSideOrders from './pages/StepSelectSideOrders';
import StepMenuConfiguration from './pages/StepMenuConfiguration';
import StepShoppingCart from './pages/StepShoppingCart';
import StepSummary from './pages/StepSummary';
import StepTransactionConfirmation from './pages/StepTransactionConfirmation';
import StepTransactionFailure from './pages/StepTransactionFailure';
import StepTransactionStatus from './pages/StepTransactionStatus';
import Modal from 'react-modal';
// @ts-ignore
import ReactFbq from 'react-fbq';
import ReactGA from 'react-ga';
import { styleSvgTag } from '@data/styleSvgTag';
import ScrollToTop from './components/common/scrollToTop';
import { useEffectOnce } from 'react-use';
import { checkIsBasketInLocalStorage, replaceStyles } from './utils';
import { getBasket, getPromoCode } from '@redux/selectors/basketSelector';
import {
  getAddressDetails,
  getSelectedCity,
} from '@redux/selectors/summaryReducer.selectors';
import { getProfileDetails } from '@redux/selectors/profileSelector';
import { checkIsSalesManagoEnabled } from '@salesManago/helpers';
import { salesManagoLoginEvent } from '@salesManago/events/login';
import { salesManagoAddToCart } from '@salesManago/events/shopCart';
import { storeBasket } from '@redux/actions/basketActions';
import { salesManagoApiNewOModifyingExistingContact } from '@salesManago/api';
import {
  changeThirdCookies,
  changeWordpressCompany,
  fetchConfig,
  getConfigLoader,
  getConfigRequestError,
  getServerConfig,
  getStyleConfig,
  getWordpressCompany,
} from '@features/general/generalSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/pro-regular-svg-icons';
import { AnimatePresence, motion } from 'framer-motion';
import axios from 'axios';
import { checkIfUserComIsEnabled } from '@userCom/utilis/checkIfUserComIsEnabled';
import { userComLoginEvent } from '@userCom/events/login';
import { userComUpdateUserEvent } from '@userCom/users/update';
import { useModalErrorAxiosInterceptor } from '@features/modalError/hooks/useModalErrorAxiosInterceptor';
import ModalError from './features/modalError/views/modalError';
import { getShowModalError } from '@features/modalError/services/redux/modalError.slice';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { useAppDispatch, useAppSelector } from '@config/hooks';
import useFetchProfile from './hooks/useFetchProfile';
import GA4 from './features/GA4/config/GA4';
import buildInfo from './buildInfo';
import { xLauncherTypeHeader } from '@services/const/api.const';
import { checkIsAdditionalFeesInLocalStorage } from '@features/orderForm/stepShoppingCart/utils/stepShoppingCart.util';
import { getOneOffsSelectedFees } from '@features/orderForm/stepShoppingCart/redux/oneOffSideOrders.selector';
import { useSalesManagoBeacon } from '@salesManago/services/hooks/useSalesManagoBeacon';
import { getSelectedSideOrders } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.selector';
import useGuestCookie from '@hooks/useGuestCookie.hooks';

const date = new Date(buildInfo.buildDate);

const buildDate = `${date.toLocaleDateString()} || ${date.toLocaleTimeString()}`;

Modal.setAppElement('body');

axios.interceptors.request.use(config => {
  if (config?.headers) {
    config.headers['X-Launcher-Type'] = xLauncherTypeHeader;
  }

  return config;
});

const App = () => {
  const dispatch = useAppDispatch();
  const configStyles = useAppSelector(getStyleConfig);
  const { analytics, companyId, salesManago, serverUrl, userCom } =
    useAppSelector(getServerConfig) || {};
  const wordpressCompany = useAppSelector(getWordpressCompany);

  const showModalError = useAppSelector(getShowModalError) || false;

  const selectedSideOrders = useAppSelector(getSelectedSideOrders);

  useEffect(() => {
    if (
      window.location.host === 'localhost:3000' ||
      window.location.host === 'localhost:3001' ||
      window.location.host === 'localhost:3002'
    ) {
      sessionStorage.setItem(
        'company-id',
        process.env['REACT_APP_COMPANY_ID'] || ''
      );

      sessionStorage.setItem(
        'server-url',
        process.env['REACT_APP_SERVER_URL'] || ''
      );
    }
  }, []);

  useEffect(() => {
    const session = sessionStorage.getItem('company-id');
    axios.defaults.headers.common['company-id'] = session;

    dispatch(changeWordpressCompany(session));

    sessionStorage.removeItem('company-id');
  }, [dispatch]);

  useEffect(() => {
    const server = sessionStorage.getItem('server-url');

    if (wordpressCompany && server) {
      // @ts-ignore
      dispatch(fetchConfig(server));
    }
  }, [dispatch, wordpressCompany]);

  useEffect(() => {
    if (analytics) {
      if (analytics?.google) {
        ReactGA.initialize(analytics?.google);
        ReactGA.pageview(window.location.pathname + window.location.search);
      }

      if (analytics?.cateringPixelFb) {
        ReactFbq.initialize({
          id: analytics?.cateringPixelFb,
        });
        ReactFbq.pageView();
      }

      if (analytics?.ourPixelFb) {
        ReactFbq.initialize({
          id: analytics?.ourPixelFb,
        });
        ReactFbq.pageView();
      }
    }
  }, [analytics]);

  useEffect(() => {
    replaceStyles(configStyles);
  }, [configStyles]);

  useEffect(() => {
    if (configStyles?.elementsToHide) {
      const replace = configStyles?.elementsToHide
        .replaceAll(' ', '')
        .split(',');

      // TODO ANY
      replace.forEach((item: any) => {
        const element = document.querySelector(item);
        if (element) {
          element.classList.add('display-none');
        }
      });
    }
  }, [configStyles?.elementsToHide]);

  useEffect(() => {
    if (configStyles?.overwriteContent) {
      const element = document.getElementById('root');
      element?.classList.add('overwrite-content');
    }
  }, [configStyles?.overwriteContent]);

  const basket = useAppSelector(getBasket);
  const addressDetails = useAppSelector(getAddressDetails);
  const profile = useAppSelector(getProfileDetails);
  const selectedCity = useAppSelector(getSelectedCity);
  const promoCode = useAppSelector(getPromoCode);
  const { code } = promoCode || {};
  const selectedFees = useAppSelector(getOneOffsSelectedFees);
  const configLoader = useAppSelector(getConfigLoader);
  const configRequestError = useAppSelector(getConfigRequestError);

  // checking if the browser has enabled or disabled third cookies
  useEffect(() => {
    const receiveMessage = function (evt: { data: string }) {
      if (evt.data === 'MM:3PCunsupported') {
        dispatch(changeThirdCookies(true));
      } else if (evt.data === 'MM:3PCsupported') {
        dispatch(changeThirdCookies(false));
      }
    };
    window.addEventListener('message', receiveMessage, false);
  }, [dispatch]);

  // Remove all default wordpress style
  useEffect(() => {
    const href = window.location.href;
    const hostname = window.location.hostname;

    if (hostname !== 'localhost' && href?.includes('#/')) {
      const styleTagList = Array.from(document.getElementsByTagName('style'));

      styleTagList.forEach(item => {
        item?.remove();
      });

      const styleTag = document.createElement('style');
      document.head.appendChild(styleTag);
      styleTag.type = 'text/css';
      styleTag.innerText = styleSvgTag;
    }
  }, []);

  const { allowMarketingMessages } = useFetchProfile(companyId);

  // SalesManago
  useEffect(() => {
    if (serverUrl) {
      const salesManagoLogin = JSON.parse(
        // @ts-ignore
        sessionStorage.getItem('salesManagoLogin')
      );

      if (
        checkIsSalesManagoEnabled(salesManago) &&
        !salesManagoLogin &&
        !!profile?.email
      ) {
        salesManagoLoginEvent(profile?.email, serverUrl, salesManago).then(
          () => {
            sessionStorage.setItem('salesManagoLogin', 'true');
          }
        );
      }
    }
  }, [profile?.email, salesManago, serverUrl]);

  useEffect(() => {
    if (salesManago) {
      const { email, firstName, lastName, phoneNumber } = profile || {};
      const { clientId } = salesManago;

      if (
        checkIsSalesManagoEnabled(salesManago) &&
        email &&
        typeof allowMarketingMessages === 'boolean'
      ) {
        const upsertContact = async () => {
          const tags = [];
          const removeTags = [];

          if (allowMarketingMessages) {
            removeTags.push('Masterlife');
          } else {
            tags.push('Masterlife');
          }

          const response = await salesManagoApiNewOModifyingExistingContact(
            {
              async: false,
              contact: {
                email,
                name: `${firstName} ${lastName}`,
                phone: phoneNumber,
              },
              tags,
              removeTags,
            },
            serverUrl,
            salesManago
          );

          const {
            data: { contactId },
          } = response || {};

          const salesMenagoTagFirst = document.createElement('script');
          salesMenagoTagFirst.type = 'text/javascript';
          salesMenagoTagFirst.innerHTML = `var _smid = '${clientId}';var _smclientid = '${contactId}';`;
          document.body.appendChild(salesMenagoTagFirst);
        };
        upsertContact();
      }
    }
  }, [allowMarketingMessages, profile, salesManago, serverUrl]);

  useEffect(() => {
    if (salesManago) {
      if (checkIsSalesManagoEnabled(salesManago)) {
        const { clientId } = salesManago;

        const getSalesManagoUrl = () => {
          if (
            clientId === '5nq0liansu9jzelz' ||
            clientId === '75436203184569c4'
          ) {
            return 'www';
          }

          if (
            clientId === 'e91162955114bc0a' ||
            clientId === '9a6fcb8fbb206d42' ||
            clientId === '840a88965590f7e7' ||
            clientId === '9c03d62af9383ed1'
          ) {
            return 'app2';
          }

          return 'app3';
        };

        const url = getSalesManagoUrl();

        const salesManagoTag = document.createElement('script');
        salesManagoTag.innerHTML = `var _smid = '${clientId}';(function(w, r, a, sm, s ) {w['SalesmanagoObject'] = r;w[r] = w[r] || function () {( w[r].q = w[r].q || [] ).push(arguments)};sm = document.createElement('script'); sm.type = 'text/javascript'; sm.async = false; sm.src = a;s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(sm, s);})(window, 'sm', ('https:' == document.location.protocol ? 'https://' : 'http://') + '${url}.salesmanago.pl/static/sm.js');`;
        salesManagoTag.async = false;
        document.body.appendChild(salesManagoTag);
      }
    }
  }, [salesManago]);

  const sendSalesManagoEventAfterLogin = useCallback(async () => {
    const isContinueParam = window.location.href.includes('continue=1');

    if (
      checkIsSalesManagoEnabled(salesManago) &&
      isContinueParam &&
      !!profile?.email
    ) {
      const storageBasket = JSON.parse(
        // @ts-ignore
        localStorage.getItem('diet-basket-form')
      );

      const event = async () =>
        await Promise.all(
          storageBasket.map(async (item: { eventId: string }) => {
            if (!item.eventId) {
              const response = await salesManagoAddToCart({
                email: profile?.email,
                order: item,
                code,
                selectedFees,
                serverUrl,
                salesManago,
                companyId,
                selectedSideOrders,
                // TODO: EVENTY
                cartPrices: {},
              });
              return { ...item, eventId: response?.eventId };
            }
            return item;
          })
        );

      const response = await event();

      localStorage.setItem('diet-basket-form', JSON.stringify(response));
      dispatch(storeBasket(response));
    }
  }, [
    code,
    companyId,
    dispatch,
    profile?.email,
    salesManago,
    selectedFees,
    selectedSideOrders,
    serverUrl,
  ]);

  useEffect(() => {
    sendSalesManagoEventAfterLogin();
  }, [
    code,
    dispatch,
    sendSalesManagoEventAfterLogin,
    profile?.email,
    selectedCity,
  ]);

  useSalesManagoBeacon();

  useGuestCookie();

  // UserCom

  useEffect(() => {
    // @ts-ignore
    const userComLogin = JSON.parse(sessionStorage.getItem('userComLogin'));
    if (
      checkIfUserComIsEnabled(userCom) &&
      !userComLogin &&
      profile?.email
    ) {
      userComLoginEvent();
      sessionStorage.setItem('userComLogin', 'true');
    }
  }, [profile?.email, userCom]);

  useEffect(() => {
    if (checkIfUserComIsEnabled(userCom) && profile?.email) {
      userComUpdateUserEvent(userCom, profile, undefined, undefined);
    }
  }, [profile, userCom]);

  useEffectOnce(() => {
    console.log(`Build Date: ${buildDate.toString()}`);
  });

  useEffectOnce(() => {
    checkIsBasketInLocalStorage(basket, addressDetails, dispatch);
    dispatch(checkIsAdditionalFeesInLocalStorage());
  });

  useEffect(() => {
    if (navigator?.userAgent?.match(/iPhone/i)) {
      const viewportmeta = document.querySelector('meta[name="viewport"]');

      if (viewportmeta) {
        // @ts-ignore
        viewportmeta.content =
          'width=device-width, initial-scale=1.0, shrink-to-fit=no, maximum-scale=1';
        document.body.addEventListener(
          'gesturestart',
          () => {
            // @ts-ignore
            viewportmeta.content = 'minimum-scale=0.25, maximum-scale=1.6';
          },
          false
        );
      }
    }
  });

  useModalErrorAxiosInterceptor(dispatch);
  if (configRequestError) {
    return (
      <AnimatePresence>
        <iframe
          src="https://mindmup.github.io/3rdpartycookiecheck/start.html"
          style={{ display: 'none' }}
          title="Check third-party cookies"
        />
        <motion.section
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className="text-align-center">
            <h3 className="h200 spacer-bottom-24">Coś poszło nie tak.</h3>
            <p className="h400">
              Odświeżenie strony powinno pomóc.
              <br />
              Jeżeli problem będzie się powtarzał, skontaktuj się z nami.
            </p>
          </div>
        </motion.section>
      </AnimatePresence>
    );
  }

  return (
    <>
      {showModalError && <ModalError />}

      <iframe
        src="https://mindmup.github.io/3rdpartycookiecheck/start.html"
        style={{ display: 'none' }}
        title="Check third-party cookies"
      />

      <GA4 />

      <AnimatePresence>
        {configLoader ? (
          <motion.section
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            style={{
              height: '100vh',
              width: '100vw',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <FontAwesomeIcon
              className="font-size-60 color-gray-400"
              icon={faSync}
              spin
            />
          </motion.section>
        ) : (
          <GTMProvider state={{ id: analytics?.googleTagManager || '' }}>
            <motion.div
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
            >
              <ScrollToTop />
              <Routes>
                <Route
                  element={<StepSelectDiet />}
                  path={`${process.env.PUBLIC_URL}/`}
                />

                <Route
                  element={<StepSelectDays />}
                  path={`${process.env.PUBLIC_URL}/zamowienie`}
                />

                <Route
                  element={<StepSelectSideOrders companyId={companyId} />}
                  path={`${process.env.PUBLIC_URL}/wybierz-dodatki`}
                />

                <Route
                  element={<StepMenuConfiguration />}
                  path={`${process.env.PUBLIC_URL}/wybierz-posilki`}
                />

                <Route
                  element={<StepShoppingCart />}
                  path={`${process.env.PUBLIC_URL}/koszyk`}
                />

                <Route
                  element={<StepSummary />}
                  path={`${process.env.PUBLIC_URL}/platnosc`}
                />

                <Route
                  element={<StepTransactionStatus />}
                  path={`${process.env.PUBLIC_URL}/status_platnosci`}
                />

                <Route
                  element={<StepTransactionConfirmation />}
                  path={`${process.env.PUBLIC_URL}/zaplacono`}
                />

                <Route
                  element={<StepTransactionFailure />}
                  path={`${process.env.PUBLIC_URL}/wystapil_problem`}
                />
              </Routes>
            </motion.div>
          </GTMProvider>
        )}
      </AnimatePresence>
    </>
  );
};

export default App;
