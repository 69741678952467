// @ts-ignore
import { findDietTagImg } from '../../../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import CustomModal from '../../../../common/CustomModal';
import { CompanyDietDetailsT, DietT } from '../../../../../@types/menu.type';
import { classNamesUtil } from '../../../../../utils/classNamesUtil.util';
import { useGetDietTagInfoAllQuery } from '../../../../../services/api/form/open/dietTagInfo.api';

type Props = {
  companyDietDetails: CompanyDietDetailsT;
  isModalOpen: boolean;
  selectedDiet: DietT | undefined;
  setAndFetchDietData: (diet: DietT) => void;
  setIsModalOpen: (bool: boolean) => void;
};

const MenuSelectDietModal = ({
  companyDietDetails,
  isModalOpen,
  selectedDiet,
  setAndFetchDietData,
  setIsModalOpen,
}: Props) => {
  const { data: dietTagsData } = useGetDietTagInfoAllQuery();

  return (
    <CustomModal
      className="custom-modal"
      isOpen={isModalOpen}
      overlayClassName="custom-modal-overlay"
    >
      <div className="top-bar-modal">
        <h2 className="top-bar-modal__title h300">Wybierz dietę</h2>
        <span
          className="top-bar-modal__close"
          onClick={() => setIsModalOpen(false)}
          onKeyDown={() => setIsModalOpen(false)}
          role="button"
          tabIndex={0}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>
      <div>
        {companyDietDetails?.map(diet => (
          <div
            className="catering-menu__carousel-diets-mobile-modal-item"
            key={diet?.dietId}
            onClick={() => {
              setAndFetchDietData(diet);
              setIsModalOpen(false);
            }}
            onKeyDown={() => {
              setAndFetchDietData(diet);
              setIsModalOpen(false);
            }}
            role="button"
            tabIndex={0}
          >
            <img
              alt={diet?.dietName ? diet?.dietName : 'Placeholder'}
              src={
                diet?.dietImageUrl
                  ? diet?.dietImageUrl
                  : findDietTagImg(diet?.dietTag, dietTagsData)
              }
            />
            <p
              className={classNamesUtil('', {
                'color-primary': diet === selectedDiet,
              })}
            >
              {diet?.dietName}
              {diet === selectedDiet && (
                <FontAwesomeIcon
                  className="font-size-18 spacer-right-18"
                  icon={faCheck}
                />
              )}
            </p>
          </div>
        ))}
      </div>
    </CustomModal>
  );
};

export default MenuSelectDietModal;
