import React from 'react';
import PropTypes from 'prop-types';

const DiscountBar = ({ closestDietDiscountDetails }) => {
  const handleDiscountType = () => {
    if (!closestDietDiscountDetails) {
      return null;
    }
    if (
      closestDietDiscountDetails &&
      closestDietDiscountDetails.discountType === 'PERCENTAGE'
    ) {
      return `${closestDietDiscountDetails.discount}% zniżki`;
    }
    return (
      closestDietDiscountDetails &&
      `${closestDietDiscountDetails.discount}PLN zniżki za każdy dzień zamówienia`
    );
  };

  return (
    <div className="discount-bar">
      <div className="discount-bar__bar">
        <div
          className="discount-bar__indicator"
          style={{ width: `${closestDietDiscountDetails?.barPercentage}%` }}
        />
      </div>
      <div className="label-m font-weight-400">
        Dodaj jeszcze
        <span className="color-primary font-weight-500">
          {' '}
          {closestDietDiscountDetails?.daysTillDiscount} dni
        </span>{' '}
        aby uzyskać{' '}
        <span className="font-weight-500 font-bold">
          {handleDiscountType()}
        </span>
      </div>
    </div>
  );
};

DiscountBar.propTypes = {
  closestDietDiscountDetails: PropTypes.shape({
    barPercentage: PropTypes.number,
    daysTillDiscount: PropTypes.number,
    discount: PropTypes.number,
    minimumDays: PropTypes.number,
  }),
  handleDiscountType: PropTypes.string,
};

export default DiscountBar;
