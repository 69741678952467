import {
  BaseDeliveryMealT,
  CustomDeliveryMealsT,
  StepMenuConfigurationBasketT,
  StepMenuConfigurationSliceT,
} from '@features/orderForm/stepMenuConfiguration/types/menuConf.type';
import {
  prepareDeleteCustomDeliveryMeals,
  prepareFirstConfiguredDay,
  prepareInitialCustomDeliveryMeals,
  prepareNewCustomDeliveryMeals,
} from '@features/orderForm/stepMenuConfiguration/utils/menuConf.utils';
import { getSelectedDaysISO } from '@redux/selectors/orderFormSelector';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { orderFormSteps } from '@services/api/form/open/order-form/steps/orderFormSteps.api';

export const initialStateMenuConfiguration: StepMenuConfigurationSliceT = {
  activeDay: '',
  baseDeliveryMeals: [],
  customDeliveryMeals: {},
  loadingCarouselId: 'all',
  shouldUpdateData: false,
  shouldRefetchMeals: false,
};

export const changeMenuConfDay = createAsyncThunk(
  'stepMenuConfigurationSlice/changeMenuConfDay',
  async (
    {
      companyId,
      date,
      scrollUp,
    }: {
      companyId: string;
      date: string;
      scrollUp: () => void;
    },
    // TODO: any
    { dispatch, getState }: any
  ) => {
    const selectedDays = getSelectedDaysISO(getState());

    const { data } =
      // Skorzystano z serializeQueryArgs, dlatego IDE wskazuje błąd, ale na podstawie tego wątku można go zignorować: https://github.com/reduxjs/redux-toolkit/issues/3263#issuecomment-1467695736
      // @ts-expect-error
      orderFormSteps.endpoints.getMenuConfigurationMealsPerDay.select({
        companyId,
        date,
      })(getState());

    const shouldUpdateAlreadyCachedData =
      data && typeof data === 'object' && 'meals' in data;
    if (selectedDays?.includes(date)) {
      dispatch(changeActiveDay(date));
      if (shouldUpdateAlreadyCachedData) {
        //To służy do inicjalnego zaktualizowania posiłków na już odwiedzonym dniu
        setTimeout(() => {
          dispatch(updateMealsData({ value: true }));
        }, 300);
      }
      scrollUp();
    }
  }
);

const stepMenuConfigurationSlice = createSlice({
  name: 'stepMenuConfigurationSlice',
  initialState: initialStateMenuConfiguration,
  reducers: {
    addCustomDeliveryMeal: (state, action) => {
      state.customDeliveryMeals[state.activeDay] = action.payload;
    },
    changeActiveDay: (state, action) => {
      state.activeDay = action.payload;
      state.loadingCarouselId = 'all';
    },
    changeMealCalories: (
      state,
      action: PayloadAction<{
        carouselId: string;
        newCustomDeliveryMealsForActiveDay: CustomDeliveryMealsT[keyof CustomDeliveryMealsT];
      }>
    ) => {
      const { carouselId, newCustomDeliveryMealsForActiveDay } = action.payload;
      state.loadingCarouselId = carouselId;
      state.customDeliveryMeals[state.activeDay] =
        newCustomDeliveryMealsForActiveDay;
    },
    deleteCustomDeliveryMeal: (state, action) => {
      const customDeliveryMealsForActiveDay =
        state.customDeliveryMeals[state.activeDay];
      if (customDeliveryMealsForActiveDay) {
        state.customDeliveryMeals[state.activeDay] =
          prepareDeleteCustomDeliveryMeals(
            action.payload,
            customDeliveryMealsForActiveDay
          );
      }
    },
    discardAllDays: state => {
      state.customDeliveryMeals = {};
      state.shouldUpdateData = false;
    },
    discardDay: (state, action: PayloadAction<string>) => {
      const dayToDelete = action.payload;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [dayToDelete]: _, ...newObj } = state.customDeliveryMeals;
      state.customDeliveryMeals = newObj;
      state.activeDay = '';
      state.shouldUpdateData = false;
    },
    editMenuConfiguration: (
      state,
      action: PayloadAction<StepMenuConfigurationBasketT>
    ) => {
      const { baseDeliveryMeals, customDeliveryMeals } = action.payload;

      state.activeDay = prepareFirstConfiguredDay(customDeliveryMeals) || '';
      state.baseDeliveryMeals = baseDeliveryMeals;
      state.customDeliveryMeals = customDeliveryMeals;
      state.loadingCarouselId = initialStateMenuConfiguration.loadingCarouselId;
      state.shouldUpdateData = initialStateMenuConfiguration.shouldUpdateData;
      state.shouldRefetchMeals =
        initialStateMenuConfiguration.shouldRefetchMeals;
    },
    initiateCustomDeliveryMeals: state => {
      state.customDeliveryMeals[state.activeDay] =
        prepareInitialCustomDeliveryMeals(state.baseDeliveryMeals);
      state.shouldRefetchMeals = true;
    },
    refetchMeals: (state, action) => {
      state.shouldRefetchMeals = action.payload;
    },
    resetMenuConfiguration: () => initialStateMenuConfiguration,
    selectCustomMeal: (
      state,
      action: PayloadAction<{
        carouselId: string;
        dietCaloriesMealId: number;
        name: string;
      }>
    ) => {
      const customDeliveryMealsForActiveDay =
        state.customDeliveryMeals[state.activeDay];
      if (customDeliveryMealsForActiveDay) {
        state.customDeliveryMeals[state.activeDay] =
          prepareNewCustomDeliveryMeals(
            action.payload,
            customDeliveryMealsForActiveDay
          );
      }
    },
    selectBaseDeliveryMeals: (
      state,
      action: PayloadAction<Array<BaseDeliveryMealT>>
    ) => {
      state.baseDeliveryMeals = action.payload;
    },
    updateMealsData: (
      state,
      action: PayloadAction<{ carouselId?: string; value: boolean }>
    ) => {
      const { carouselId, value } = action.payload;
      state.shouldUpdateData = value;
      if (value && carouselId) {
        state.loadingCarouselId = carouselId;
      }
      if (!value) {
        state.loadingCarouselId = '';
      }
    },
  },
});

export const {
  addCustomDeliveryMeal,
  changeActiveDay,
  changeMealCalories,
  deleteCustomDeliveryMeal,
  discardAllDays,
  discardDay,
  editMenuConfiguration,
  initiateCustomDeliveryMeals,
  refetchMeals,
  resetMenuConfiguration,
  selectBaseDeliveryMeals,
  selectCustomMeal,
  updateMealsData,
} = stepMenuConfigurationSlice.actions;

export default stepMenuConfigurationSlice.reducer;
