import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input';
import { initialPhoneNumberValues } from '../utils/prefix.utils';

export const usePrefix = (number: string) => {
  const trimNumber = number?.replace(/\s+/g, '');
  const numberWithoutPossibleZerosInFront = String(parseInt(trimNumber, 10));
  const numberWithPlus = `${
    numberWithoutPossibleZerosInFront.includes('+') ? '' : '+'
  }${numberWithoutPossibleZerosInFront}`;

  const isNumberValid = isValidPhoneNumber(numberWithPlus);

  if (isNumberValid) {
    const parsedNumber = parsePhoneNumber(numberWithPlus);
    const phone = Number(parsedNumber?.nationalNumber);
    const prefix = parsedNumber?.countryCallingCode;
    return {
      phone,
      prefix,
      phoneToDisplay: number
        ? // TODO: types
          // @ts-expect-error
          formatPhoneNumberIntl(`+${prefix}${phone}`) || `+${prefix}${phone}`
        : '',
    };
  }
  const numberWithAdded48 = `+48${numberWithPlus.slice(1)}`;
  const parsedNumber = parsePhoneNumber(numberWithAdded48);
  const phone = Number(parsedNumber?.nationalNumber);
  const prefix = initialPhoneNumberValues.phoneNumberPrefix;

  return {
    phone,
    prefix,
    phoneToDisplay: number
      ? // TODO: types
        // @ts-expect-error
        formatPhoneNumberIntl(`+${prefix}${phone}`) || `+${prefix}${phone}`
      : '',
  };
};

//
// SideOrdersGrid - lista dodatków przy składaniu zamówienia
// SelectSideOrdersModal - lista dnia dla wybranego dodatku przy składaniu zamówienia
