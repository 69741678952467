import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { changeCalculatorStep } from '../../../../../../features/dietCalculator/dietCalculatorSlice';
import { classNamesUtil } from '../../../../../../utils/classNamesUtil.util';

const AsideProgressBar = ({
  age,
  goal,
  height,
  physicalActivity,
  sex,
  step,
  weight,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="steps-page-progress hidden-up-md">
        <div className="container">
          <ul className="steps-page-progress__list">
            <li
              className={classNamesUtil(
                'steps-page-progress__item steps-page-progress__item--not-wrap',
                {
                  'steps-page-progress__item--completed': step > 1,
                  'steps-page-progress__item--active': step === 1,
                }
              )}
            >
              <div
                className={classNamesUtil('steps-page-progress__box', {
                  'steps-page-progress__box--completed': step > 1,
                  'steps-page-progress__box--active': step === 1,
                })}
              >
                {step <= 1 ? 1 : <FontAwesomeIcon icon={faCheck} />}
              </div>
              <span>Płeć</span>
            </li>
            <span className="steps-page-progress__arrow">{'>'}</span>
            <li
              className={classNamesUtil(
                'steps-page-progress__item steps-page-progress__item--not-wrap',
                {
                  'steps-page-progress__item--completed': step > 2,
                  'steps-page-progress__item--active': step === 2,
                }
              )}
            >
              <div
                className={classNamesUtil('steps-page-progress__box', {
                  'steps-page-progress__box--completed': step > 2,
                  'steps-page-progress__box--active': step === 2,
                })}
              >
                {step <= 2 ? 2 : <FontAwesomeIcon icon={faCheck} />}
              </div>
              <span>Parametry</span>
            </li>
            <span className="steps-page-progress__arrow">{'>'}</span>
            <li
              className={classNamesUtil(
                'steps-page-progress__item steps-page-progress__item--not-wrap',
                {
                  'steps-page-progress__item--completed': step > 3,
                  'steps-page-progress__item--active': step === 3,
                }
              )}
            >
              <div
                className={classNamesUtil('steps-page-progress__box', {
                  'steps-page-progress__box--completed': step > 3,
                  'steps-page-progress__box--active': step === 3,
                })}
              >
                {step <= 3 ? 3 : <FontAwesomeIcon icon={faCheck} />}
              </div>
              <span>Aktywność fizyczna</span>
            </li>
            <span className="steps-page-progress__arrow">{'>'}</span>
            <li
              className={classNamesUtil(
                'steps-page-progress__item steps-page-progress__item--not-wrap',
                {
                  'steps-page-progress__item--completed': step > 4,
                  'steps-page-progress__item--active': step === 4,
                }
              )}
            >
              <div
                className={classNamesUtil('steps-page-progress__box', {
                  'steps-page-progress__box--completed': step > 4,
                  'steps-page-progress__box--active': step === 4,
                })}
              >
                {step <= 4 ? 4 : <FontAwesomeIcon icon={faCheck} />}
              </div>
              <span>Twój cel</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="calculator-modal__left-sidebar">
        <div
          className={classNamesUtil('calculator-modal__steps', {
            'calculator-modal__steps--smaller': step === 6,
          })}
        >
          <div className="display-flex justify-content-space-between align-items-center">
            <h2 className="h200 no-select color-white">Konfigurator</h2>
          </div>
          <div className="calculator-modal__progressbar no-select">
            <div
              className="calculator-modal__step"
              onClick={() => {
                if (sex) {
                  dispatch(changeCalculatorStep(1));
                }
              }}
              onKeyDown={() => {
                if (sex) {
                  dispatch(changeCalculatorStep(1));
                }
              }}
              role="button"
              tabIndex={0}
            >
              <div
                className={classNamesUtil('calculator-modal__bullet', {
                  'calculator-modal__bullet--active': step === 1,
                  'calculator-modal__bullet--completed': step > 1,
                })}
              />
              <p className="calculator-modal__step-text calculator-modal__step-text--active">
                Płeć
              </p>
            </div>
            <div
              className="calculator-modal__step"
              onClick={() => {
                if (age && weight && height && sex !== '') {
                  dispatch(changeCalculatorStep(2));
                }
              }}
              onKeyDown={() => {
                if (age && weight && height && sex !== '') {
                  dispatch(changeCalculatorStep(2));
                }
              }}
              role="button"
              tabIndex={0}
            >
              <div
                className={classNamesUtil('calculator-modal__bullet', {
                  'calculator-modal__bullet--active': step === 2,
                  'calculator-modal__bullet--completed': step > 2,
                })}
              />
              <p
                className={classNamesUtil('calculator-modal__step-text', {
                  'calculator-modal__step-text--active': step > 1,
                })}
              >
                Parametry
              </p>
            </div>
            <div
              className="calculator-modal__step"
              onClick={() => {
                if (typeof physicalActivity === 'number') {
                  dispatch(changeCalculatorStep(3));
                }
              }}
              onKeyDown={() => {
                if (typeof physicalActivity === 'number') {
                  dispatch(changeCalculatorStep(3));
                }
              }}
              role="button"
              tabIndex={0}
            >
              <div
                className={classNamesUtil('calculator-modal__bullet', {
                  'calculator-modal__bullet--active': step === 3,
                  'calculator-modal__bullet--completed': step > 3,
                })}
              />
              <p
                className={classNamesUtil('calculator-modal__step-text', {
                  'calculator-modal__step-text--active': step > 2,
                })}
              >
                Aktywność fizyczna
              </p>
            </div>
            <div
              className="calculator-modal__step"
              onClick={() => {
                if (goal) {
                  dispatch(changeCalculatorStep(4));
                }
              }}
              onKeyDown={() => {
                if (goal) {
                  dispatch(changeCalculatorStep(4));
                }
              }}
              role="button"
              tabIndex={0}
            >
              <div
                className={classNamesUtil(
                  'calculator-modal__bullet-without-line',
                  {
                    'calculator-modal__active-without-line': step === 4,
                    'calculator-modal__completed-without-line': step > 4,
                  }
                )}
              />
              <p
                className={classNamesUtil('calculator-modal__step-text', {
                  'calculator-modal__step-text--active': step > 3,
                })}
              >
                Twój cel
              </p>
            </div>
            <div className="calculator-modal__steps-bottom no-select">
              {step === 5 && (
                <div
                  className="label-s color-white cursor-pointer"
                  onClick={() => {
                    dispatch(changeCalculatorStep(1));
                  }}
                  onKeyDown={() => {
                    dispatch(changeCalculatorStep(1));
                  }}
                  role="link"
                  tabIndex={0}
                >
                  <FontAwesomeIcon
                    className="spacer-right-14"
                    icon={faArrowLeft}
                  />
                  Edytuj konfigurację
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AsideProgressBar;
