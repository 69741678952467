import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getCompanyWidgetSettings,
  getCompanyWidgetStyles,
} from '../../services/api/form/open/company.api';
import { RootState } from '../../config/hooks';
import { createSelector } from 'reselect';

type GeneralInitialStateT = {
  isThirdCookiesEnabled: boolean;
  config: any;
  configLoader: boolean;
  configRequestError: boolean;
  wordpressCompany: string;
};

const generalInitialState: GeneralInitialStateT = {
  isThirdCookiesEnabled: false,
  config: {},
  configLoader: true,
  configRequestError: false,
  wordpressCompany: '',
};

export const fetchConfig = createAsyncThunk(
  'general/fetchConfig',
  async (server: string) => {
    const responseCompanyWidgetSettings = await getCompanyWidgetSettings(
      server
    );
    const responseCompanyWidgetStyles = await getCompanyWidgetStyles(server);

    return {
      server: responseCompanyWidgetSettings.data,
      style: responseCompanyWidgetStyles.data,
    };
  }
);

const generalSlice = createSlice({
  name: 'general',
  initialState: generalInitialState,
  reducers: {
    addUserComUserId(state, action) {
      state.config.server.userCom.userId = action.payload;
    },
    changeThirdCookies(state, action) {
      state.isThirdCookiesEnabled = action.payload;
    },
    changeWordpressCompany(state, action) {
      state.wordpressCompany = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchConfig.pending, state => {
      state.configLoader = true;
    });
    builder.addCase(fetchConfig.fulfilled, (state, action) => {
      state.config = action.payload;
      state.configLoader = false;
      state.configRequestError = false;
    });
    builder.addCase(fetchConfig.rejected, state => {
      state.configLoader = false;
      state.configRequestError = true;
    });
  },
});

// reducer
export default generalSlice.reducer;

// actions
export const { addUserComUserId, changeThirdCookies, changeWordpressCompany } =
  generalSlice.actions;

// selectors
export const getGeneralReducer = (state: RootState) => state.general;
export const getWordpressCompany = (state: RootState) =>
  getGeneralReducer(state).wordpressCompany;
export const getIsThirdCookiesEnabled = (state: RootState) =>
  getGeneralReducer(state).isThirdCookiesEnabled;
export const getConfig = (state: RootState) => getGeneralReducer(state).config;
export const getServerConfig = (state: RootState) =>
  getConfig(state).server || {};
export const getStyleConfig = (state: RootState) =>
  getConfig(state).style || {};
export const getConfigLoader = (state: RootState) =>
  getGeneralReducer(state).configLoader;
export const getConfigRequestError = (state: RootState) =>
  getGeneralReducer(state).configRequestError;
export const getGA4Id = (state: RootState) =>
  getServerConfig(state)?.analytics?.ga4Id;

/**
 * For now, we are checking only if companyName equals 'burakdiet'. When we decide to enable
 * custom loyalty program to other caterings, then we can replace it with other
 * property (most likely from BE) stating that catering has custom loyalty program.
 * */
export const getIsCustomLoyaltyProgram = (state: RootState) =>
  getWordpressCompany(state) === 'burakdieta';

export const getIsKuchniaVikinga = createSelector([getConfig], config => {
  const { companyId } = config.server;

  return companyId === 'kuchniavikinga';
});
