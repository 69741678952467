export const parseNumber = (number: number, toFixed = 2) => {
  if (isNaN(number)) {
    return 0;
  }
  if (!number) {
    return 0;
  }
  if (number > 0) {
    return (Math.round(number * 100) / 100).toFixed(toFixed).replace('.', ',');
  }
  return 0;
};
