import React from 'react';

const UserIcon = ({ className }) => {
  return (
    <svg
      className={className}
      fill="none"
      height="205"
      viewBox="0 0 218 205"
      width="218"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M155.293 114.607L175.746 110.212C188.27 107.52 191.875 91.382 181.687 83.6169L174.358 78.0312C168.705 73.7223 166.852 66.0371 169.921 59.6255L175.367 48.2474C182.129 34.1202 165.437 20.3861 152.876 29.742L149.538 32.229C142.532 37.4473 132.53 35.2642 128.335 27.6013C123.553 18.8644 111.552 17.518 104.953 24.9782L63.6661 71.6525C62.4467 73.0311 60.972 74.1607 59.3234 74.9791L44.3607 82.407C32.3485 88.3702 38.158 106.568 51.4035 104.468C61.2721 102.903 68.443 113.619 63.2327 122.145L43.1359 155.031C34.8285 168.625 50.6433 184.085 64.0452 175.471L91.8436 157.604C99.5106 152.677 109.764 155.669 113.578 163.947L114.751 166.493C121.475 181.09 143.338 176.322 143.375 160.251L143.445 129.238C143.461 122.181 148.394 116.09 155.293 114.607Z"
        fill="#06257F"
        opacity="0.1"
      />
      <path
        clipRule="evenodd"
        d="M130.201 135.222C130.201 130.658 131.347 126.364 133.357 122.609L123.34 117.12C120.754 115.7 119.142 112.973 119.142 110.011V101.889C119.142 101.889 124.932 94.9311 127.142 85.26C128.976 84.0689 130.201 82.0089 130.201 79.6667V70.7778C130.201 68.8222 129.336 67.0733 127.989 65.8511V53C127.989 53 130.615 33 103.659 33C76.7043 33 79.3297 53 79.3297 53V65.8511C77.9827 67.0733 77.1179 68.8222 77.1179 70.7778V79.6667C77.1179 82.0089 78.3433 84.0689 80.1768 85.26C82.3864 94.9311 88.1769 101.889 88.1769 101.889V110.011C88.1769 112.973 86.5645 115.7 83.9789 117.12L64.2453 127.933C58.5477 131.056 55 137.058 55 143.582V153H137C132.787 148.28 130.201 142.062 130.201 135.222Z"
        fill="white"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M180 139C180 153.359 168.359 165 154 165C139.642 165 128 153.359 128 139C128 124.642 139.642 113 154 113C168.359 113 180 124.642 180 139Z"
        fill="#FFEBE5"
        fillRule="evenodd"
        stroke="#FF5226"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="M154 135V152"
        stroke="#FF5226"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M156 126C156 127.104 155.104 128 154 128C152.896 128 152 127.104 152 126C152 124.896 152.896 124 154 124C155.104 124 156 124.896 156 126Z"
        fill="#FF5226"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default UserIcon;
