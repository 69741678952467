import React, { useState } from 'react';
import { Input } from '../../../../../common/Input';
import { useDispatch } from 'react-redux';
import {
  changeCalculatorStep,
  changeCalculatorValue,
} from '../../../../../../features/dietCalculator/dietCalculatorSlice';
import Button from '../../../../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';

const DietCalculatorStepSecond = ({ age, height, step, weight }) => {
  const dispatch = useDispatch();

  const minWeight = 20;
  const maxWeight = 200;

  const minHeight = 100;
  const maxHeight = 230;

  const [weightError, setWeightError] = useState(false);
  const [heightError, setHeightError] = useState(false);

  const formValidation = () => {
    if (weight && (weight > maxWeight || weight < minWeight)) {
      if (height && (height > maxHeight || height < minHeight)) {
        setHeightError(true);
      }
      return setWeightError(true);
    }

    if (height && (height > maxHeight || height < minHeight)) {
      return setHeightError(true);
    }

    setHeightError(false);
    return setWeightError(false);
  };

  const handleClickButton = () => {
    formValidation();
    dispatch(changeCalculatorStep(3));
  };

  const handleOnChange = (value, name) => {
    if (+value === 0 || value === '') {
      dispatch(changeCalculatorValue({ name, value: null }));
      return;
    }
    dispatch(changeCalculatorValue({ name, value: +value }));
  };

  return (
    <>
      <div className="calculator-modal__mobile-padding no-select">
        <div>
          <h2 className="h300">Podaj swój wiek, wzrost, oraz wagę</h2>
          <p className="body-m spacer-top-16 spacer-bottom-14">
            Potrzebujemy tych informacji, aby lepiej dopasować kaloryczność
            zapotrzebowania dziennego.
          </p>
        </div>
        <div className="calculator-modal__slider-container">
          <p className="label-m spacer-bottom-8">
            Wiek <span className="color-gray-400">(lat)</span>
          </p>
          <Input
            inputMode="numeric"
            maskCharacter=" "
            masked
            maskPattern="99"
            name="age"
            onChange={({ target: { value } }) => {
              handleOnChange(value, 'age');
            }}
            pattern="[0-9]*"
            placeholder="25"
            value={age}
          />
        </div>
        <div className="calculator-modal__slider-container">
          <p className="label-m spacer-bottom-8">
            Waga <span className="color-gray-400">(kg)</span>
          </p>
          <Input
            errorDown
            errorMsg={weightError && 'Nieodpowiednia wartość'}
            inputMode="numeric"
            maskCharacter=" "
            masked
            maskPattern="999"
            name="weight"
            onChange={({ target: { value } }) => {
              handleOnChange(value, 'weight');
            }}
            pattern="[0-9]*"
            placeholder="60"
            value={weight}
          />
        </div>
        <div className="calculator-modal__slider-container">
          <p className="label-m spacer-bottom-8">
            Wzrost <span className="color-gray-400">(cm)</span>
          </p>
          <Input
            errorDown
            errorMsg={heightError && 'Nieodpowiednia wartość'}
            inputMode="numeric"
            maskCharacter=" "
            masked
            maskPattern="999"
            name="height"
            onChange={({ target: { value } }) => {
              handleOnChange(value, 'height');
            }}
            pattern="[0-9]*"
            placeholder="170"
            value={height}
          />
        </div>
      </div>
      <div className="calculator-modal__footer-modal calculator-modal__footer-modal--space-between">
        <Button
          colorBlank
          icon={<FontAwesomeIcon icon={faArrowLeft} />}
          label="Wstecz"
          onClick={() => {
            dispatch(changeCalculatorStep(1));
          }}
        />
        <span className="label-s color-gray-900">
          {step}/<span className="color-primary">5</span>
        </span>
        <Button
          disabled={weightError || heightError || !age || !weight || !height}
          icon={<FontAwesomeIcon icon={faArrowRight} />}
          iconRight
          label="Dalej"
          onClick={handleClickButton}
        />
      </div>
    </>
  );
};

export default DietCalculatorStepSecond;
