import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import EyeIcon from '../../assets/icons/EyeIcon';
import { classNamesUtil } from '../../utils/classNamesUtil.util';

export const Input = ({
  containerClass,
  disabled,
  errorDown,
  errorMsg,
  hint,
  id,
  inputClass,
  label,
  maskCharacter,
  masked,
  maskPattern,
  name,
  onClick,
  onKeyPress,
  passwordInput,
  required,
  searchIcon,
  type = 'text',
  value,
  ...restProps
}) => {
  const [inputType, setInputType] = useState(type);
  const actionClasses = classNamesUtil({
    'has-error': errorMsg,
    'is-disabled': disabled,
    'is-required': required,
    'password-input': passwordInput,
  });
  const loginClasses = classNamesUtil({
    'justify-content-between ': errorDown,
  });

  const handleChangeInputType = () => {
    if (inputType === 'password') {
      setInputType('text');
    } else {
      setInputType('password');
    }
  };

  return (
    <div className={classNamesUtil(containerClass)} onClick={onClick}>
      <div
        className={classNamesUtil('form-input', {
          [`${inputClass}`]: !!inputClass,
          [`${actionClasses}`]: !!actionClasses,
        })}
      >
        {label && (
          <div className="input-label">
            {errorDown ? (
              <label className={loginClasses + actionClasses} htmlFor={id}>
                <div>
                  {label} {required && <span className="color-error">*</span>}
                </div>
              </label>
            ) : (
              <label className={actionClasses} htmlFor={id}>
                <div>
                  {label} {required && <span>*</span>}
                </div>{' '}
                {errorMsg && (
                  <span className="input-validation-text">{errorMsg}</span>
                )}
              </label>
            )}
          </div>
        )}
        <div className="position-rel">
          {searchIcon && (
            <FontAwesomeIcon
              className="font-size-20 spacer-right-16"
              icon={faSearch}
            />
          )}
          {masked ? (
            <InputMask
              className={actionClasses}
              mask={maskPattern}
              maskChar={maskCharacter}
              name={name}
              onKeyPress={onKeyPress}
              type={inputType}
              value={value}
              {...restProps}
            />
          ) : (
            <input
              className={actionClasses}
              disabled={disabled}
              id={id}
              name={id}
              onKeyDown={onKeyPress}
              type={inputType}
              value={value}
              {...restProps}
            />
          )}
          {passwordInput && (
            <EyeIcon className="eye-password" onClick={handleChangeInputType} />
          )}
        </div>

        {errorDown
          ? errorMsg && (
              <span className="input-validation-text spacer-top-4 display-block">
                {errorMsg}
              </span>
            )
          : hint && (
              <div className="input-hint spacer-top-4 display-block">
                {hint}
              </div>
            )}
      </div>
    </div>
  );
};

Input.defaultProps = {
  containerClass: '',
  disabled: false,
  inputClass: '',
  label: '',
};

Input.propTypes = {
  containerClass: PropTypes.string,
  disabled: PropTypes.bool,
  errorDown: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  errorMsg: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  hint: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  id: PropTypes.string,
  inputClass: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  maskCharacter: PropTypes.string,
  masked: PropTypes.bool,
  maskPattern: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  passwordInput: PropTypes.bool,
  prefix: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  required: PropTypes.bool,
  searchIcon: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
