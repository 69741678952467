import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { DateTime } from 'luxon';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import {
  getOrderForm,
  getSelectedProgram,
} from '../../../../redux/selectors/orderFormSelector';
import {
  getSelectedDays,
  isOrderingNotAllowed,
  toggleOrderDay,
} from '../utils/calendar.utils';
import {
  checkIfNextWeekendDayAllowed,
  isDayNotDeliverableLogic,
  isDayOutsideDaysLimit,
} from '../utils/selectDays.utils';
import { useGetApiFormSettingsQuery } from '../../../../services/api/form/open/open.api';
import {
  getIsKuchniaVikinga,
  getServerConfig,
} from '../../../../features/general/generalSlice';

type Props = {
  baseDate: DateTime;
  day: DateTime;
  setSelectedValue: Dispatch<SetStateAction<number>>;
};

const CalendarDay = ({ baseDate, day, setSelectedValue }: Props) => {
  const dispatch = useAppDispatch();

  const selectedProgram = useAppSelector(getSelectedProgram);
  const orderForm = useAppSelector(getOrderForm);
  const { companyId } = useAppSelector(getServerConfig);
  const isKuchniaVikinga = useAppSelector(getIsKuchniaVikinga);

  const { data: formSettings } = useGetApiFormSettingsQuery(undefined, {
    skip: !companyId,
  });

  const [classes, setClasses] = useState('');

  const memoIsOrderingNotAllowed = useMemo(
    () =>
      isOrderingNotAllowed(day, selectedProgram, formSettings) ||
      isDayOutsideDaysLimit(
        day.toMillis(),
        formSettings?.orderSettings.maximumOrderAdvanceDays
      ),
    // ***
    // Do not delete the eslint rule. Calendar optimization
    // ***
    //eslint-disable-next-line
    [day.month, selectedProgram, formSettings]
  );

  useEffect(() => {
    let classNames = '';

    if (orderForm.selectedDays.includes(day.toMillis())) {
      classNames += 'calendar__day--active ';
    }

    if (isDayNotDeliverableLogic(day, formSettings, isKuchniaVikinga)) {
      classNames += 'calendar__day--no-service ';
    }

    if (memoIsOrderingNotAllowed) {
      classNames += 'calendar__day--past ';
    }

    if (checkIfNextWeekendDayAllowed(day, orderForm, formSettings)) {
      classNames += 'calendar__day--locked ';
    }

    if (DateTime.local().toISODate() === day.toISODate()) {
      classNames += 'calendar__day--today ';
    }

    setClasses(classNames);
  }, [
    day,
    formSettings,
    isKuchniaVikinga,
    memoIsOrderingNotAllowed,
    orderForm,
  ]);

  return (
    <div
      className={`calendar__day no-select ${classes}`}
      key={day.toMillis()}
      onClick={() => {
        if (
          memoIsOrderingNotAllowed ||
          isDayNotDeliverableLogic(day, formSettings, isKuchniaVikinga)
        ) {
          return null;
        }

        if (orderForm.selectedDays?.length > 0) {
          return dispatch(toggleOrderDay(day, setSelectedValue));
        }

        return dispatch(getSelectedDays(day, isKuchniaVikinga));
      }}
    >
      {baseDate.month === day.month && day.day}
    </div>
  );
};

export default CalendarDay;
