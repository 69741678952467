import {
  salesManagoApiAddContactExtEvent,
  salesManagoApiNewOModifyingExistingContact,
} from '../api';
import { getTimeStamp } from '../helpers';

export const salesManagoRegisterEvent = async (
  clientData,
  salesManago,
  serverUrl
) => {
  try {
    const {
      allowMarketingMessages,
      companyName: company,
      email,
      firstName,
      lastName,
      phone,
    } = clientData;

    const name = `${firstName} ${lastName}`;
    const { clientId } = salesManago;

    await salesManagoApiAddContactExtEvent(
      {
        contactEvent: {
          contactExtEventType: 'OTHER',
          date: getTimeStamp(),
          description: 'Rejestracja',
          detail1: 'Rejestracja',
        },
        email,
      },
      serverUrl,
      salesManago
    );

    const tags = ['zamowienie_gosc'];
    const removeTags = [];

    if (allowMarketingMessages) {
      removeTags.push('Masterlife');
    } else {
      tags.push('Masterlife');
    }

    const response = await salesManagoApiNewOModifyingExistingContact(
      {
        async: false,
        contact: {
          company,
          email,
          name,
          phone,
          state: 'CUSTOMER',
        },
        forceOptIn: allowMarketingMessages,
        forceOptOut: !allowMarketingMessages,
        forcePhoneOptIn: allowMarketingMessages,
        forcePhoneOptOut: !allowMarketingMessages,
        tags,
        removeTags,
      },
      serverUrl,
      salesManago
    );

    const { contactId } = response.data;

    const salesMenagoTagFirst = document.createElement('script');
    const salesMenagoTagSecond = document.createElement('script');

    salesMenagoTagFirst.type = 'text/javascript';
    salesMenagoTagSecond.type = 'text/javascript';

    salesMenagoTagFirst.innerHTML = `var _smid = '${clientId}';var _smclientid = '${contactId}';`;
    salesMenagoTagSecond.src = 'https://www.salesmanago.pl/static/sm.js';

    document.body.appendChild(salesMenagoTagFirst);
    document.body.appendChild(salesMenagoTagSecond);
  } catch (e) {
    console.log(e, 'e');
  }
};
