import React from 'react';
import Modal from 'react-modal';
import {
  changeIsModalOpen,
  getDietCalculatorReducer,
} from '../../../../../features/dietCalculator/dietCalculatorSlice';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import AsideProgressBar from './components/AsideProgressBar';
import DietCalculatorStepFirst from './components/DietCalculatorStepFirst';
import DietCalculatorStepSecond from './components/DietCalculatorStepSecond';
import DietCalculatorStepThird from './components/dietCalculatorStepThird.jsx';
import DietCalculatorStepFourth from './components/DietCalculatorStepFourth';
import DietCalculatorStepFifth from './components/DietCalculatorStepFifth';
import { useAppSelector } from '../../../../../config/hooks';

const DietCalculator = ({ caloriesList, handleSelectCalorie }) => {
  const dispatch = useDispatch();

  const {
    age,
    calorie,
    goal,
    height,
    isModalOpen,
    physicalActivity,
    sex,
    step,
    weight,
  } = useAppSelector(getDietCalculatorReducer);

  return (
    <Modal
      className="calculator-modal"
      isOpen={isModalOpen}
      overlayClassName="calculator-modal-overlay"
    >
      <div className="diet-configurator__wrapper">
        <span
          className="calculator-modal__close hidden-down-md"
          onClick={() => {
            dispatch(changeIsModalOpen(false));
          }}
          onKeyPress={() => {
            dispatch(changeIsModalOpen(false));
          }}
          role="button"
          tabIndex={0}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
        <div className="calculator-modal__header-modal">
          <h2 className="h200">Konfigurator</h2>
          <span
            className="calculator-modal__header-modal-close"
            onClick={() => {
              dispatch(changeIsModalOpen(false));
            }}
            onKeyPress={() => {
              dispatch(changeIsModalOpen(false));
            }}
            role="button"
            tabIndex={0}
          >
            <FontAwesomeIcon className="font-size-20" icon={faTimes} />
          </span>
        </div>
        <AsideProgressBar
          age={age}
          goal={goal}
          height={height}
          physicalActivity={physicalActivity}
          sex={sex}
          step={step}
          weight={weight}
        />
        <div className="calculator-modal__questions">
          {(() => {
            switch (step) {
              case 1:
                return <DietCalculatorStepFirst sex={sex} step={step} />;
              case 2:
                return (
                  <DietCalculatorStepSecond
                    age={age}
                    height={height}
                    step={step}
                    weight={weight}
                  />
                );
              case 3:
                return (
                  <DietCalculatorStepThird
                    physicalActivity={physicalActivity}
                    step={step}
                  />
                );
              case 4:
                return <DietCalculatorStepFourth goal={goal} step={step} />;
              case 5:
                return (
                  <DietCalculatorStepFifth
                    calorie={calorie}
                    caloriesList={caloriesList}
                    handleSelectCalorie={handleSelectCalorie}
                    step={step}
                  />
                );
              default:
                return null;
            }
          })()}
        </div>
      </div>
    </Modal>
  );
};

export default DietCalculator;
