import { getServerConfig } from '@features/general/generalSlice';
import { useAppSelector } from '@config/hooks';
import { getOrderForm } from '@redux/selectors/orderFormSelector';
import { checkIsSalesManagoEnabled } from '@salesManago/helpers';
import { salesManagoUpdateCart } from '@salesManago/events/shopCart';
import { getPromoCode } from '@redux/selectors/basketSelector';
import { getSelectedCity } from '@redux/selectors/summaryReducer.selectors';
import { getOneOffsSelectedFees } from '@features/orderForm/stepShoppingCart/redux/oneOffSideOrders.selector';
import { useGetApiFormSettingsQuery } from '@services/api/form/open/open.api';
import { getSelectedSideOrders } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.selector';

export const useSalesManago = () => {
  const { salesManago, serverUrl } = useAppSelector(getServerConfig);
  const orderForm = useAppSelector(getOrderForm);
  const promoCode = useAppSelector(getPromoCode);
  const selectedCity = useAppSelector(getSelectedCity);
  const selectedFees = useAppSelector(getOneOffsSelectedFees);
  const selectedSideOrders = useAppSelector(getSelectedSideOrders);

  const { data: formSettings, isFetching } = useGetApiFormSettingsQuery();

  const { companyName = '' } = formSettings || {};

  const triggerSalesManagoUpdateCart: () => void = () => {
    const shouldBlockSalesManagoEvent =
      !checkIsSalesManagoEnabled(salesManago) ||
      !orderForm.eventId ||
      isFetching;

    if (shouldBlockSalesManagoEvent) {
      return () => {};
    }
    return salesManagoUpdateCart(
      orderForm,
      promoCode.code,
      selectedCity,
      selectedFees,
      serverUrl,
      salesManago,
      companyName,
      selectedSideOrders
    ).catch(e => {
      console.log(e, 'e');
    });
  };

  return { triggerSalesManagoUpdateCart };
};
