import React from 'react';

const OrderConfirmationIcon = () => {
  return (
    <svg
      className="order-success-icon-color"
      fill="none"
      height="160"
      viewBox="0 0 200 160"
      width="200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M146.295 95.6072L166.748 91.2119C179.271 88.5204 182.877 72.382 172.689 64.6169L165.36 59.0312C159.707 54.7223 157.854 47.0371 160.923 40.6255L166.369 29.2474C173.13 15.1202 156.439 1.38608 143.878 10.742L140.539 13.229C133.534 18.4473 123.532 16.2642 119.337 8.60127C114.555 -0.135615 102.554 -1.48195 95.9548 5.97817L54.6681 52.6525C53.4486 54.0311 51.9739 55.1607 50.3253 55.9791L35.3627 63.407C23.3505 69.3702 29.16 87.568 42.4054 85.4679C52.274 83.9032 59.4449 94.619 54.2347 103.145L34.1378 136.031C25.8304 149.625 41.6452 165.085 55.0472 156.471L82.8456 138.604C90.5126 133.677 100.766 136.669 104.58 144.947L105.753 147.493C112.477 162.09 134.34 157.322 134.377 141.251L134.447 110.238C134.463 103.181 139.396 97.09 146.295 95.6072Z"
        fill="#06257F"
        opacity="0.1"
      />
      <path
        clipRule="evenodd"
        d="M150.516 134H49.482C45.35 134 42 130.65 42 126.516V54H158V126.516C158 130.65 154.65 134 150.516 134Z"
        fill="white"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M120 94C120 105.045 111.045 114 100 114C88.955 114 80 105.045 80 94C80 82.955 88.955 74 100 74C111.045 74 120 82.955 120 94Z"
        fill="#F2F5FC"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M110.001 85.667L99.6446 100.547L91.668 94.0003"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M44 26H156V54H44V26Z"
        fill="#D0E8F9"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M44 26H156V54H44V26Z"
        fill="#ECEEF8"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M140 38V54V38Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M48 26L60 38M60 38L48 54M60 38V54M152 26L140 38M140 38L152 54M140 38V54"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M90 36C90 37.104 89.104 38 88 38C86.896 38 86 37.104 86 36C86 34.896 86.896 34 88 34C89.104 34 90 34.896 90 36Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M96 30C96 31.104 95.104 32 94 32C92.896 32 92 31.104 92 30C92 28.896 92.896 28 94 28C95.104 28 96 28.896 96 30Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M96 42C96 43.104 95.104 44 94 44C92.896 44 92 43.104 92 42C92 40.896 92.896 40 94 40C95.104 40 96 40.896 96 42Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M102 36C102 37.104 101.104 38 100 38C98.896 38 98 37.104 98 36C98 34.896 98.896 34 100 34C101.104 34 102 34.896 102 36Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M78 36C78 37.104 77.104 38 76 38C74.896 38 74 37.104 74 36C74 34.896 74.896 34 76 34C77.104 34 78 34.896 78 36Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M84 30C84 31.104 83.104 32 82 32C80.896 32 80 31.104 80 30C80 28.896 80.896 28 82 28C83.104 28 84 28.896 84 30Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M84 42C84 43.104 83.104 44 82 44C80.896 44 80 43.104 80 42C80 40.896 80.896 40 82 40C83.104 40 84 40.896 84 42Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M66 36C66 37.104 65.104 38 64 38C62.896 38 62 37.104 62 36C62 34.896 62.896 34 64 34C65.104 34 66 34.896 66 36Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M60 30C60 31.104 59.104 32 58 32C56.896 32 56 31.104 56 30C56 28.896 56.896 28 58 28C59.104 28 60 28.896 60 30Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M72 30C72 31.104 71.104 32 70 32C68.896 32 68 31.104 68 30C68 28.896 68.896 28 70 28C71.104 28 72 28.896 72 30Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M72 42C72 43.104 71.104 44 70 44C68.896 44 68 43.104 68 42C68 40.896 68.896 40 70 40C71.104 40 72 40.896 72 42Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M138 36C138 37.104 137.104 38 136 38C134.896 38 134 37.104 134 36C134 34.896 134.896 34 136 34C137.104 34 138 34.896 138 36Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M144 30C144 31.104 143.104 32 142 32C140.896 32 140 31.104 140 30C140 28.896 140.896 28 142 28C143.104 28 144 28.896 144 30Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M108 30C108 31.104 107.104 32 106 32C104.896 32 104 31.104 104 30C104 28.896 104.896 28 106 28C107.104 28 108 28.896 108 30Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M108 42C108 43.104 107.104 44 106 44C104.896 44 104 43.104 104 42C104 40.896 104.896 40 106 40C107.104 40 108 40.896 108 42Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M114 36C114 37.104 113.104 38 112 38C110.896 38 110 37.104 110 36C110 34.896 110.896 34 112 34C113.104 34 114 34.896 114 36Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M126 36C126 37.104 125.104 38 124 38C122.896 38 122 37.104 122 36C122 34.896 122.896 34 124 34C125.104 34 126 34.896 126 36Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M90 48C90 49.104 89.104 50 88 50C86.896 50 86 49.104 86 48C86 46.896 86.896 46 88 46C89.104 46 90 46.896 90 48Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M102 48C102 49.104 101.104 50 100 50C98.896 50 98 49.104 98 48C98 46.896 98.896 46 100 46C101.104 46 102 46.896 102 48Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M78 48C78 49.104 77.104 50 76 50C74.896 50 74 49.104 74 48C74 46.896 74.896 46 76 46C77.104 46 78 46.896 78 48Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M66 48C66 49.104 65.104 50 64 50C62.896 50 62 49.104 62 48C62 46.896 62.896 46 64 46C65.104 46 66 46.896 66 48Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M138 48C138 49.104 137.104 50 136 50C134.896 50 134 49.104 134 48C134 46.896 134.896 46 136 46C137.104 46 138 46.896 138 48Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M114 48C114 49.104 113.104 50 112 50C110.896 50 110 49.104 110 48C110 46.896 110.896 46 112 46C113.104 46 114 46.896 114 48Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M126 48C126 49.104 125.104 50 124 50C122.896 50 122 49.104 122 48C122 46.896 122.896 46 124 46C125.104 46 126 46.896 126 48Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M120 30C120 31.104 119.104 32 118 32C116.896 32 116 31.104 116 30C116 28.896 116.896 28 118 28C119.104 28 120 28.896 120 30Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M132 30C132 31.104 131.104 32 130 32C128.896 32 128 31.104 128 30C128 28.896 128.896 28 130 28C131.104 28 132 28.896 132 30Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M120 42C120 43.104 119.104 44 118 44C116.896 44 116 43.104 116 42C116 40.896 116.896 40 118 40C119.104 40 120 40.896 120 42Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M132 42C132 43.104 131.104 44 130 44C128.896 44 128 43.104 128 42C128 40.896 128.896 40 130 40C131.104 40 132 40.896 132 42Z"
        fill="#1B42B2"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default OrderConfirmationIcon;
