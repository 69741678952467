import React, { useEffect, useState } from 'react';
import Navigation from '../components/common/navigation';
import { useEffectOnce } from 'react-use';
import { useDispatch } from 'react-redux';
import { getSummaryReducer } from '../redux/selectors/summaryReducer.selectors';
import OrderConfirmationCash from '../components/pages/StepTransaction/views/OrderConfirmationCash';
import {
  changePaymentType,
  resetSummary,
} from '../redux/actions/summaryActions';
import { Link, useNavigate } from 'react-router-dom';
import OrderConfirmationLoan from '../components/pages/StepTransaction/views/OrderConfirmationLoan';
import OrderConfirmationOnline from '../components/pages/StepTransaction/views/OrderConfirmationOnline';
import OrderConfirmationTransfer from '../components/pages/StepTransaction/views/OrderConfirmationTransfer';
import {
  resetCalendar,
  resetOrderForm,
  restoreInitialOrderForm,
} from '../redux/actions/orderFormActions';
import { resetBasket } from '../redux/actions/basketActions';
import BoxStep from '../components/common/BoxStep';
import OrderConfirmationIcon from '../assets/icons/orderConfirmationIcon';
import Button from '../components/common/Button';
import OrderConfirmationExternalVoucher from '../components/pages/StepTransaction/views/OrderConfirmationExternalVoucher';
import { getServerConfig } from '../features/general/generalSlice';
import { useEnhancedEcommercePageInfo } from '../hooks/useEnhancedEcommercePageInfo';
import {
  useGetApiFormSettingsQuery,
  useGetApiParamsQuery,
} from '../services/api/form/open/open.api';
import { useAppSelector } from '../config/hooks';

const StepTransactionConfirmation = () => {
  const { companyId, panelUrl } = useAppSelector(getServerConfig);
  const {
    data: {
      generalInformation: { email = '' } = {},
      paymentInformation: { bankAccount = '' } = {},
    } = {},
  } = useGetApiFormSettingsQuery(undefined, {
    skip: !companyId,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const summaryReducer = useAppSelector(getSummaryReducer);

  const { data: params } = useGetApiParamsQuery(companyId, {
    skip: !companyId,
  });

  const [successPageData, setSuccessPageData] = useState('');
  const [orderId, setOrderId] = useState('');
  const [zeroCost, setZeroCost] = useState(false);

  useEffectOnce(() => {
    dispatch(resetOrderForm());
    dispatch(restoreInitialOrderForm());
    dispatch(resetCalendar());
    dispatch(resetBasket());
    dispatch(resetSummary());
  });

  const { canSend, sendEvent } = useEnhancedEcommercePageInfo();
  useEffect(() => {
    canSend && sendEvent();
  }, [canSend, sendEvent]);

  const { paymentType } = summaryReducer || {};

  useEffectOnce(() => {
    const data = JSON?.parse(
      // @ts-ignore
      sessionStorage?.getItem('success-page-data-form')
    );
    setSuccessPageData(data);

    // @ts-ignore
    setOrderId(JSON?.parse(window?.sessionStorage?.getItem('order-id-form')));

    if (!data) {
      navigate('/', { replace: true });
    }
  });

  useEffectOnce(() => {
    const zeroCodeForm = sessionStorage?.getItem('zero-cost-form');

    if (zeroCodeForm) {
      setZeroCost(true);
    }
  });

  useEffect(() => {
    if (!paymentType && successPageData) {
      // @ts-ignore
      dispatch(changePaymentType(successPageData.paymentType));
    }
  }, [dispatch, paymentType, successPageData]);

  return (
    <>
      <Navigation />
      <div className="steps-page">
        <div className="steps-page__container">
          {(() => {
            if (zeroCost) {
              return (
                <BoxStep>
                  <div className="display-flex flex-direction-column align-items-center justify-content-center text-align-center">
                    <OrderConfirmationIcon />
                    <h4 className="h300 spacer-bottom-8 spacer-top-24">
                      Zamówienie zostało złożone
                    </h4>
                    <p className="body-l padding-bottom-40 max-width-500">
                      W trakcie składania zamówienia pojawił się błąd płatności,
                      o dalszym etapie realizacji zamówienia zostaniesz
                      poinformowany przez catering.
                    </p>
                    <Link to="/">
                      <Button
                        colorBlank
                        containerClass="spacer-top-24"
                        label="Wróć na stronę główną"
                        sizeMedium
                      />
                    </Link>
                  </div>
                </BoxStep>
              );
            }

            if (!zeroCost) {
              return (
                <>
                  {paymentType === 'ONLINE' && (
                    <OrderConfirmationOnline
                      loyaltyProgramEnabled={!!params?.loyaltyProgramEnabled}
                      orderId={orderId}
                      panelUrl={panelUrl}
                      successPageData={successPageData}
                    />
                  )}
                  {paymentType === 'BANK_TRANSFER' && (
                    <OrderConfirmationTransfer
                      bankAccount={bankAccount}
                      email={email}
                      loyaltyProgramEnabled={!!params?.loyaltyProgramEnabled}
                      orderId={orderId}
                      successPageData={successPageData}
                    />
                  )}
                  {paymentType === 'CASH' && (
                    <OrderConfirmationCash
                      loyaltyProgramEnabled={!!params?.loyaltyProgramEnabled}
                      orderId={orderId}
                      successPageData={successPageData}
                    />
                  )}
                  {paymentType === 'INSTALLMENTS' && (
                    <OrderConfirmationLoan
                      loyaltyProgramEnabled={!!params?.loyaltyProgramEnabled}
                      orderId={orderId}
                      successPageData={successPageData}
                    />
                  )}
                  {paymentType === 'EXTERNAL_VOUCHER' && (
                    <OrderConfirmationExternalVoucher />
                  )}
                </>
              );
            }
          })()}
        </div>
      </div>
    </>
  );
};

export default StepTransactionConfirmation;
