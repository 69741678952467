import React from 'react';

type Props = {
  className: string;
};

const SmallMaleIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      fill="none"
      height="64"
      viewBox="0 0 64 64"
      width="64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M60 32C60 47.4634 47.4634 60 32 60C16.5366 60 4 47.4634 4 32C4 16.5366 16.5366 4 32 4C47.4634 4 60 16.5366 60 32Z"
        fill="white"
        fillRule="evenodd"
        stroke="#FD5A58"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M23.6656 47.9038L15.311 52.4609C14.8208 52.7283 14.3802 53.0569 13.9702 53.4161C18.8422 57.5221 25.1301 59.9998 32.0001 59.9998C38.8194 59.9998 45.0671 57.5591 49.9243 53.508C49.4763 53.1298 48.9903 52.7895 48.4514 52.5201L39.5052 48.0475C38.3492 47.4695 37.6191 46.2883 37.6191 44.996V41.4849C37.8706 41.1997 38.158 40.8309 38.4655 40.3977C39.6848 38.6754 40.6072 36.7809 41.2464 34.7935C42.3929 34.4395 43.2381 33.3797 43.2381 32.1203V28.3735C43.2381 27.5494 42.8726 26.8129 42.302 26.2973V20.8812C42.302 20.8812 43.4146 12.4526 32.0001 12.4526C20.5856 12.4526 21.6982 20.8812 21.6982 20.8812V26.2973C21.1276 26.8129 20.7621 27.5494 20.7621 28.3735V32.1203C20.7621 33.1071 21.2809 33.9757 22.0564 34.4765C22.9926 38.5529 25.4439 41.4849 25.4439 41.4849V44.9083C25.4439 46.1572 24.7624 47.3058 23.6656 47.9038Z"
        fill="#FFEFEF"
        fillRule="evenodd"
        stroke="#FD5A58"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default SmallMaleIcon;
