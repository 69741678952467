import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RegisterConfirmation from './RegisterConfirmation';
import SuccessRegisterConfirmation from './SuccessRegisterConfirmation';
import BoxStep from '../../../common/BoxStep';
import OrderConfirmationIcon from '../../../../assets/icons/orderConfirmationIcon';
import { getServerConfig } from '../../../../features/general/generalSlice';
import { useAppSelector } from '../../../../config/hooks';

type Props = {
  loyaltyProgramEnabled: boolean;
  orderId: string;
  successPageData: any;
};

const OrderConfirmationLoan = ({
  loyaltyProgramEnabled,
  orderId,
  successPageData,
}: Props) => {
  const [sent, setSent] = useState(false);

  const { methodOfPurchase } = successPageData;

  const { loginEnabled } = useAppSelector(getServerConfig);

  return sent ? (
    <SuccessRegisterConfirmation />
  ) : (
    <BoxStep>
      <div className="order-confirmation-page">
        <OrderConfirmationIcon />
        <h4 className="h400 spacer-bottom-8 spacer-top-24">
          Zamówienie zostało przyjęte do weryfikacji
        </h4>
        <p className="body-l spacer-bottom-40 max-width-545">
          Dziękujemy! O dalszym etapie rozpatrywania wniosku zostaniesz
          poinformowany przez dostawcę płatności.
        </p>
      </div>
      {methodOfPurchase === 'guest' && loginEnabled && (
        <RegisterConfirmation
          loyaltyProgramEnabled={loyaltyProgramEnabled}
          orderId={orderId}
          setSent={setSent}
          successPageData={successPageData}
        />
      )}
    </BoxStep>
  );
};

OrderConfirmationLoan.propTypes = {
  loyaltyProgramEnabled: PropTypes.bool,
  orderId: PropTypes.string,
  successPageData: PropTypes.shape({}),
};

export default OrderConfirmationLoan;
