import { classNamesUtil } from 'utilsRoot/classNamesUtil.util';
import styles from '../styles/MenuConfSideOrders.module.scss';
import { parseNumber } from '@utils/parseNumber.util';
import { SideOrderOrCourseT } from '@features/coursesAsSideOrders/types/coursesAsSideOrders.type';
import {
  decreaseSideOrder,
  increaseSideOrder,
  removeSideOrder,
  SideOrderIdT,
} from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.slice';
import { useAppDispatch } from '@config/hooks';
import DaysAmount from '@components/common/DaysAmount';
import Button from '@components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import placeholder from '@assets/images/placeholder.jpg';

type Props = {
  activeDay: string;
  maxMealsNumber: number;
  multiplier: number;
  selected: boolean;
  selectedMealsAndSideOrders: number;
  sideOrder: SideOrderOrCourseT;
  sideOrderKey: SideOrderIdT;
};
const MenuConfSideOrder = ({
  activeDay,
  maxMealsNumber,
  multiplier,
  selected,
  selectedMealsAndSideOrders,
  sideOrder,
  sideOrderKey,
}: Props) => {
  const dispatch = useAppDispatch();

  const {
    defaultPrice,
    imageUrl,
    limitedByMaximumSelectedMeals,
    maxQuantity,
    minQuantity,
    subtitle,
    title,
  } = sideOrder || {};

  const shouldDisableButton =
    limitedByMaximumSelectedMeals &&
    selectedMealsAndSideOrders + (minQuantity || 1) > maxMealsNumber;

  const disableDecrease =
    !!multiplier && !!minQuantity && multiplier - 1 < minQuantity;

  const disableIncrease =
    (!!multiplier && !!maxQuantity && multiplier + 1 > maxQuantity) ||
    (limitedByMaximumSelectedMeals &&
      selectedMealsAndSideOrders + 1 > maxMealsNumber);

  return (
    <div
      className={classNamesUtil(`${styles['side-order']}`, {
        [`${styles['selected']}`]: selected,
      })}
    >
      {selected && (
        <div className={styles['check']}>
          <FontAwesomeIcon className="font-size-15" icon={faCheck} />
        </div>
      )}

      <div className="display-flex">
        <img
          alt={`Dodatek - ${title}`}
          className={styles['img']}
          src={imageUrl || placeholder}
        />
        <div className={styles['description']}>
          <p className="label-m spacer-bottom-8">{title}</p>
          {subtitle && <p className="body-s">{subtitle}</p>}
        </div>
      </div>

      <div className={styles['wrapper']}>
        <div className="display-flex spacer-top-8">
          {selected ? (
            <>
              <div className={styles['days-amount']}>
                <DaysAmount
                  days={multiplier}
                  decrease={() => {
                    dispatch(
                      decreaseSideOrder({
                        activeDay,
                        minQuantity,
                        sideOrderKey,
                      })
                    );
                  }}
                  disableDecrease={disableDecrease}
                  disableIncrease={disableIncrease}
                  increase={() => {
                    dispatch(
                      increaseSideOrder({
                        activeDay,
                        defaultPrice,
                        limitedByMaximumSelectedMeals,
                        maxQuantity,
                        minQuantity,
                        sideOrderKey,
                      })
                    );
                  }}
                  withoutBorders
                />
              </div>

              <Button
                colorBlank
                label="Usuń"
                onClick={() => {
                  dispatch(
                    removeSideOrder({
                      activeDay,
                      sideOrderKey,
                    })
                  );
                }}
              />
            </>
          ) : (
            <Button
              colorBlank
              containerClass="color-primary"
              disabled={shouldDisableButton}
              icon={<FontAwesomeIcon icon={faPlusCircle} />}
              label="Dodaj"
              onClick={() => {
                dispatch(
                  increaseSideOrder({
                    activeDay,
                    defaultPrice,
                    limitedByMaximumSelectedMeals,
                    maxQuantity,
                    minQuantity,
                    sideOrderKey,
                  })
                );
              }}
            />
          )}
        </div>

        {!!defaultPrice ? (
          <div className="label-m color-primary-500 spacer-top-8">
            {parseNumber(defaultPrice)} zł/szt.
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default MenuConfSideOrder;
