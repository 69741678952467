import { DateTime } from 'luxon';

const datesAdapter = {
  // Get locale string (e.g. 24.12.2024) from ISO
  getLocaleString: (date: string) => DateTime.fromISO(date).toLocaleString(),
  // Get today in ISO format
  getToday: () => DateTime.now().toFormat('yyyy-MM-dd'),
  // Get formatted date from ISO
  getFormattedDate: (date: string, format: string) =>
    DateTime.fromISO(date).toFormat(format),
  // Get weekday name short (e.g. pon.) from ISO
  getWeekdayNameShort: (date: string) =>
    DateTime.fromISO(date).toLocaleString({ weekday: 'short' }),
  // Get weekday name long (e.g. poniedziałek) from ISO
  getWeekdayNameLong: (date: string) =>
    DateTime.fromISO(date).toLocaleString({ weekday: 'long' }),
  // Get month name short (e.g. gru) from ISO
  getMonthNameShort: (date: string) =>
    DateTime.fromISO(date).toLocaleString({ month: 'short' }),
  // Get day number from ISO
  getDayNumber: (date: string) => DateTime.fromISO(date).day,
  // Get ISO date from millis
  getISODateFromMillis: (date: number) => DateTime.fromMillis(date).toISODate(),
  // Get Millis from ISO
  getMillisFromISO: (date: string) => DateTime.fromISO(date).toMillis(),
};

export const {
  getDayNumber,
  getFormattedDate,
  getISODateFromMillis,
  getLocaleString,
  getMillisFromISO,
  getMonthNameShort,
  getToday,
  getWeekdayNameLong,
  getWeekdayNameShort,
} = datesAdapter;
