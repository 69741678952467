import React, { useEffect } from 'react';
import Navigation from '../components/common/navigation';
import ProgressBar from '../components/common/ProgressBar';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/pro-regular-svg-icons';
import {
  getOrderForm,
  getSelectedProgram,
} from '@redux/selectors/orderFormSelector';
import { getSelectedCity } from '@redux/selectors/summaryReducer.selectors';
import { useAppDispatch, useAppSelector } from '@config/hooks';
import { getBasketEdition } from '@redux/selectors/basketSelector';
import InfoToast from '@features/orderForm/stepMenuConfiguration/views/InfoToast';
import { useEnhancedEcommercePageInfo } from '@hooks/useEnhancedEcommercePageInfo';
import ThirdStepSummaryBar from '@features/orderForm/stepSelectSideOrders/views/ThirdStepSummaryBar';
import SideOrdersGrid from '@features/coursesAsSideOrders/views/SideOrdersGrid';
import SideOrdersSummary from '@features/orderForm/stepSelectSideOrders/views/SideOrdersSummary';
import {
  getChooseSideOrdersLimitedByMaximumSelectedMeals,
  getSelectedSideOrders,
} from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.selector';
import {
  changeLimitedByMaximumSelectedMeals,
  resetErrorAndSelectedSideOrders,
} from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.slice';
import useCalculateItemPrice from '@services/hook/calculatePrices/useCalculateItemPrice.hook';

type Props = {
  companyId: string;
};

const StepSelectSideOrders = ({ companyId }: Props) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const orderForm = useAppSelector(getOrderForm);
  const selectedCity = useAppSelector(getSelectedCity);
  const clearLimitedByMaximumSelectedMeals = useAppSelector(
    getChooseSideOrdersLimitedByMaximumSelectedMeals
  );
  const { isEdited } = useAppSelector(getBasketEdition);
  const selectedProgram = useAppSelector(getSelectedProgram);
  const selectedSideOrders = useAppSelector(getSelectedSideOrders);

  const { selectedCalories, selectedDiet, selectedOption } = orderForm || {};
  const { cityId } = selectedCity || {};

  const { prices } = useCalculateItemPrice();

  useEffect(() => {
    if (!selectedDiet || !selectedOption || !selectedCalories || !cityId) {
      navigate('/', { replace: true });
    }
  }, [cityId, navigate, selectedCalories, selectedDiet, selectedOption]);

  useEffect(() => {
    if (
      isEdited &&
      selectedProgram === 'standard' &&
      clearLimitedByMaximumSelectedMeals
    ) {
      dispatch(resetErrorAndSelectedSideOrders());
    }
    return () => {
      dispatch(changeLimitedByMaximumSelectedMeals(false));
    };
  }, [clearLimitedByMaximumSelectedMeals, dispatch, isEdited, selectedProgram]);

  const { canSend, sendEvent } = useEnhancedEcommercePageInfo();
  useEffect(() => {
    canSend && sendEvent();
  }, [canSend, sendEvent]);

  return (
    <>
      <Navigation />
      <ProgressBar />
      <div className="steps-page steps-page--select-side-orders-page">
        <div className="steps-page__container">
          <Link className="steps-page__back-button" to="/zamowienie">
            <Button
              buttonLink
              icon={<FontAwesomeIcon icon={faArrowCircleLeft} />}
              label="Powrót"
            />
          </Link>

          {isEdited &&
            selectedProgram === 'standard' &&
            clearLimitedByMaximumSelectedMeals && (
              <InfoToast description="Z uwagi na zmianę liczby posiłków z diety zostały usunięte dodatki wliczane do limitu posiłków. Skonfiguruj te dodatki na nowo." />
            )}

          <div className="steps-page-select-side-orders">
            <SideOrdersGrid
              companyId={companyId}
              selectedSideOrders={selectedSideOrders}
            />
            <SideOrdersSummary
              prices={prices}
              selectedSideOrders={selectedSideOrders}
            />
          </div>
        </div>
      </div>
      <ThirdStepSummaryBar />
    </>
  );
};

export default StepSelectSideOrders;
