import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../config/hooks';
import { HIGH_OVERLOAD_TIMEOUT } from '../../features/modalError/modalError.const';

type initialStateT = {
  disableOrder: boolean;
};

const initialState: initialStateT = {
  disableOrder: false,
};

const serverOverloadSlice = createSlice({
  name: 'serverOverloadSlice',
  initialState,
  reducers: {
    setTemporarilyDisableOrder: (state, action) => {
      state.disableOrder = action.payload;
    },
  },
});

export const temporarilyDisableOrderButton = createAsyncThunk(
  'serverOverloadSlice/disableOrder',
  async (_, { dispatch }) => {
    dispatch(setTemporarilyDisableOrder(true));
    setTimeout(() => {
      dispatch(setTemporarilyDisableOrder(false));
    }, HIGH_OVERLOAD_TIMEOUT);
  }
);

export const getServerOverloadReducer = (state: RootState) =>
  state.serverOverload;

export const getTemporarilyDisableOrder = (state: RootState) =>
  getServerOverloadReducer(state).disableOrder;

export const { setTemporarilyDisableOrder } = serverOverloadSlice.actions;

export default serverOverloadSlice.reducer;
