import styles from '../../styles/SummaryPrices.module.scss';
import { CartPricesT } from '../../../../../@types/calculatePrice.type';
import { parsePrice } from '../../../../../utils';
import { useAppSelector } from '../../../../../config/hooks';
import { getPdfFile } from '../../../../../redux/selectors/basketSelector';
import { useLocation } from 'react-router-dom';
import React from 'react';
import {
  burakLoyaltyProgramData,
  defaultLoyaltyProgramData,
} from '../../../../../data/loyaltyProgramData';
import { getIsCustomLoyaltyProgram } from '../../../../general/generalSlice';

type Props = {
  prices: CartPricesT;
};

const SummaryPricesBE = ({ prices }: Props) => {
  const { pathname } = useLocation();

  const { hasFile } = useAppSelector(getPdfFile);
  const isCustomLoyaltyProgram = useAppSelector(getIsCustomLoyaltyProgram);

  const loyaltyProgramData = isCustomLoyaltyProgram
    ? burakLoyaltyProgramData.orderFormSummaryPrices
    : defaultLoyaltyProgramData.orderFormSummaryPrices;

  const finalPriceWithAdditionalFees = parsePrice(
    prices.totalCostWithoutDiscounts + prices.totalOneTimeSideOrdersCost
  );

  return (
    <>
      <div className={styles['wrapper']}>
        <p>Suma zamówienia</p>

        <div>
          {hasFile
            ? finalPriceWithAdditionalFees
            : parsePrice(prices.totalCostWithoutDiscounts)}{' '}
          zł
        </div>
      </div>

      {hasFile ? (
        <>
          <div className="display-flex flex-column justify-content-space-between align-items-center spacer-bottom-10">
            <p className="body-m spacer-right-12 flex-1 color-gray-800">
              {pathname === '/koszyk' ? 'Załącznik PDF' : 'Kod Groupon'}
            </p>

            <p className="label-m color-success">
              {pathname === '/koszyk'
                ? `-${finalPriceWithAdditionalFees} zł`
                : 'dodany'}
            </p>
          </div>

          {pathname === '/platnosc' && (
            <>
              <p className="body-s spacer-bottom-8">
                Zniżka zostanie zaliczona po zakończeniu procesu
              </p>

              <div className="box-step__pdf-file-info">
                Uwaga: cena zostanie zaktualizowana po zakończeniu zamówienia i
                sprawdzeniu ważności dokumentu Groupon
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {!!prices.totalDeliveryCost && (
            <div className={styles['wrapper']}>
              <p>Dostawa</p>

              <div>+{parsePrice(prices.totalDeliveryCost)} zł</div>
            </div>
          )}

          {!!prices.totalOneTimeSideOrdersCost && (
            <div className={styles['wrapper']}>
              <p>Dodatkowe produkty</p>

              <div>+{parsePrice(prices.totalOneTimeSideOrdersCost)} zł</div>
            </div>
          )}

          {!!prices.totalPromoCodeDiscount && (
            <div className={styles['wrapper']}>
              <p>Kod rabatowy</p>

              <div>-{parsePrice(prices.totalPromoCodeDiscount)} zł</div>
            </div>
          )}

          {!!prices.totalOrderLengthDiscount && (
            <div className={styles['wrapper']}>
              <p>Zniżka za długość zamówienia</p>

              <div>-{parsePrice(prices.totalOrderLengthDiscount)} zł</div>
            </div>
          )}

          {!!prices.totalDeliveriesOnDateDiscount && (
            <div className={styles['wrapper']}>
              <p>Zniżka za ilość diet</p>

              <div>-{parsePrice(prices.totalDeliveriesOnDateDiscount)} zł</div>
            </div>
          )}

          {!!prices.totalLoyaltyPointsDiscount && (
            <div className={styles['wrapper']}>
              <p>{loyaltyProgramData.text1}</p>

              <div>-{parsePrice(prices.totalLoyaltyPointsDiscount)} zł</div>
            </div>
          )}

          {!!prices.totalPickupPointDiscount && (
            <div className={styles['wrapper']}>
              <p>Zniżka za punkt odbioru</p>

              <div>-{parsePrice(prices.totalPickupPointDiscount)} zł</div>
            </div>
          )}
        </>
      )}

      <div className={`${styles['wrapper']} ${styles['final-cost']}`}>
        <p>Do zapłaty</p>

        <div>{parsePrice(prices.totalCostToPay)} zł</div>
      </div>
    </>
  );
};

export default SummaryPricesBE;
