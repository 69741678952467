const parseActivity = physicalActivity => {
  switch (physicalActivity) {
    case 0:
      return 1.2;
    case 33:
      return 1.4;
    case 66:
      return 1.6;
    case 100:
      return 1.75;
    default:
      return 1;
  }
};

const parseGoal = goal => {
  switch (goal) {
    case 'less':
      return -500;
    case 'same':
      return 0;
    case 'more':
      return 300;
    default:
      return 0;
  }
};

export const calculateCalories = (
  age,
  goal,
  height,
  physicalActivity,
  sex,
  weight
) => {
  if (sex === 'male') {
    return Math.round(
      (66.47 + 13.75 * weight + 5 * height - 6.75 * age) *
        parseActivity(physicalActivity) +
        parseGoal(goal)
    );
  }

  return Math.round(
    (665.09 + 9.56 * weight + 1.85 * height - 4.67 * age) *
      parseActivity(physicalActivity) +
      parseGoal(goal)
  );
};
