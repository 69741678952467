import React from 'react';
import Button from '../../../../components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
import { checkIfCaloriesDietIsInMenuConfiguration } from '../../../../components/pages/basket/helpers/basketHelpers';
import { storeLastItemIntoOrderForm } from '../../../../redux/actions/orderFormActions';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import { getCompanyDietCaloriesMenuConfiguration } from '../../../../redux/selectors/orderFormDietDetailsSelector';
import {
  getSelectedCaloriesId,
  getSelectedDietId,
} from '../../../../redux/selectors/orderFormSelector';
import { getBasket } from '../../../../redux/selectors/basketSelector';

const BackButton = () => {
  const dispatch = useAppDispatch();
  const companyDietCaloriesMenuConfiguration = useAppSelector(
    getCompanyDietCaloriesMenuConfiguration
  );
  const selectedCaloriesId = useAppSelector(getSelectedCaloriesId);
  const selectedDietId = useAppSelector(getSelectedDietId);
  const basket = useAppSelector(getBasket);

  const checkBackButton = () => {
    if (
      checkIfCaloriesDietIsInMenuConfiguration(
        selectedCaloriesId,
        companyDietCaloriesMenuConfiguration
      )
    ) {
      const findDiet = basket.find(
        (item: {
          selectedDiet: { dietId: number };
          selectedCalories: { dietCaloriesId: number };
        }) =>
          item?.selectedDiet?.dietId === selectedDietId &&
          item?.selectedCalories?.dietCaloriesId === selectedCaloriesId
      );

      dispatch(storeLastItemIntoOrderForm(findDiet));
    }
  };

  return (
    <Link className="steps-page__back-button" to="/zamowienie">
      <Button
        buttonLink
        icon={<FontAwesomeIcon icon={faArrowCircleLeft} />}
        label="Powrót"
        onClick={() => {
          checkBackButton();
        }}
      />
    </Link>
  );
};

export default BackButton;
