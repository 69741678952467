import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SideOrderT } from 'typesRoot/sideOrder.type';

type initialStateT = {
  selectedFees: Array<SideOrderT>;
};

const initialState: initialStateT = {
  selectedFees: [],
};

const oneOffSideOrdersSlice = createSlice({
  name: 'oneOffSideOrdersSlice',
  initialState,
  reducers: {
    addOneOff: (state, action: PayloadAction<SideOrderT>) => {
      state.selectedFees = [...state.selectedFees, action.payload];
    },
    changeOneOffAmount: (
      state,
      action: PayloadAction<{ oneOff: SideOrderT; newAmount: number }>
    ) => {
      const { newAmount, oneOff } = action.payload;
      const { possibleSideOrderId: oneOffId } = oneOff;
      const selectedOneOffIndex = state.selectedFees.findIndex(
        selectedFee => selectedFee.possibleSideOrderId === oneOffId
      );
      if (selectedOneOffIndex !== -1) {
        state.selectedFees = [
          ...state.selectedFees.slice(0, selectedOneOffIndex),
          { ...oneOff, amount: newAmount },
          ...state.selectedFees.slice(selectedOneOffIndex + 1),
        ];
      }
    },
    removeOneOff: (state, action: PayloadAction<SideOrderT>) => {
      const oneOffId = action.payload.possibleSideOrderId;
      state.selectedFees = state.selectedFees.filter(
        selectedFee => selectedFee.possibleSideOrderId !== oneOffId
      );
    },
    selectOneOffs: (state, action) => {
      state.selectedFees = action.payload;
    },
    resetOneOffs: (state) => {
      state.selectedFees = []
    },
  },
});

export const { addOneOff, changeOneOffAmount, removeOneOff, resetOneOffs, selectOneOffs } =
  oneOffSideOrdersSlice.actions;

export default oneOffSideOrdersSlice.reducer;
