import { BasketItemT } from 'typesRoot/basketItem.type';
import { DietlyOpenCityT } from '@services/api/form/open/formOpenCities.api';
import { DateTime } from 'luxon';
import { findUtmostDates } from '../../../utils';

export const generateItem = ({
  basketItem,
  city,
  clientLifecycleState,
  price,
}: {
  basketItem: BasketItemT;
  city: DietlyOpenCityT;
  clientLifecycleState?: string;
  price: number;
}) => {
  const {
    catering,
    companyName,
    days,
    saturdays,
    selectedCalories,
    selectedDays,
    selectedDiet,
    selectedOption,
    selectedSideOrders,
    sets,
    sundays,
    testOrder,
  } = basketItem;

  const firstDay = selectedDays.length
    ? DateTime.fromMillis(findUtmostDates('first', selectedDays))?.toISODate()
    : '';
  const lastDay = selectedDays.length
    ? DateTime.fromMillis(findUtmostDates('last', selectedDays))?.toISODate()
    : '';

  const item = {
    item_name: selectedDiet?.dietName,
    item_id: `${companyName || selectedDiet?.companyName}-${
      selectedCalories?.dietCaloriesId
    }`,
    item_brand: catering || selectedDiet?.companyName,
    item_category: selectedDiet?.dietTag,
    item_variant: `${selectedOption?.dietOptionName}-${selectedCalories?.calories}`,
    price,
    quantity: sets,
    dimension12: days,
    dimension13:
      selectedSideOrders && Object.keys(selectedSideOrders || {}).length > 0
        ? 1
        : 0,
    dimension14: testOrder ? 1 : 0,
    dimension15: saturdays || sundays ? 1 : 0,
    dimension16: firstDay,
    dimension17: lastDay,
    dimension18: city.name,
  };

  if (clientLifecycleState) {
    return { ...item, dimension20: clientLifecycleState };
  }

  return item;
};

export const generateItemsArray = ({
  basket,
  city,
  clientLifecycleState,
  price,
}: {
  basket: Array<BasketItemT>;
  city: DietlyOpenCityT;
  clientLifecycleState?: string;
  price: number;
}) =>
  basket?.map(item =>
    generateItem({ basketItem: item, city, clientLifecycleState, price })
  ) ?? [];
