import { useAppSelector } from '@config/hooks';
import { getOrderForm } from '@redux/selectors/orderFormSelector';
import { getSelectedCity } from '@redux/selectors/summaryReducer.selectors';
import { getServerConfig } from '@features/general/generalSlice';
import { CalculatePriceItemT } from 'typesRoot/calculatePrice.type';
import { getSelectedSideOrders } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.selector';
import { generateUserComAddEvent } from '@userCom/utilis/userCom.util';

const useUserComAddEvent = (prices?: CalculatePriceItemT) => {
  const orderForm = useAppSelector(getOrderForm);
  const selectedCity = useAppSelector(getSelectedCity);
  const { userCom } = useAppSelector(getServerConfig);
  const selectedSideOrders = useAppSelector(getSelectedSideOrders);

  if (!prices) {
    return { trigger: () => {} };
  }

  const trigger = generateUserComAddEvent({
    orderForm,
    prices,
    selectedCity,
    selectedSideOrders,
    userCom,
  });

  return { trigger };
};

export default useUserComAddEvent;
