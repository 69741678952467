import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InfoToast = ({ description, title }) => {
  const [isOpen, setIsOpen] = useState(true);

  if (isOpen) {
    return (
      <div className="info-toast">
        <svg
          fill="none"
          height="32"
          viewBox="0 0 32 32"
          width="32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16" cy="16" fill="#3399FF" r="14" />
          <circle cx="16" cy="10" fill="white" r="2" />
          <path
            d="M16 16V22"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
        </svg>
        <div className="info-toast__wrapper">
          <FontAwesomeIcon
            className="info-toast__close"
            icon={faTimes}
            onClick={() => {
              setIsOpen(false);
            }}
          />
          {title && <div className="label-m spacer-bottom-4">{title}</div>}
          <div className="body-s">{description}</div>
        </div>
      </div>
    );
  }

  return <></>;
};

InfoToast.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};

export default InfoToast;
