import React from 'react';
import { useMedia } from 'react-use';

import styles from '@features/orderForm/common/styles/stepsPage.module.scss';
import { SelectedSideOrdersT } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.slice';
import { getSelectedDays } from '@redux/selectors/orderFormSelector';
import { parseNumber } from '@utils/parseNumber.util';
import { useAppSelector } from '@config/hooks';
import BoxStep from '@components/common/BoxStep';
import NextStepButton from '@components/pages/StepSelectDays/views/NextStepButton';
import useSideOrdersSummary from '@features/orderForm/stepSelectSideOrders/services/hooks/useSideOrdersSummary';
import { CalculatePriceItemT } from 'typesRoot/calculatePrice.type';

type SideOrdersSummaryProps = {
  prices: CalculatePriceItemT | undefined;
  selectedSideOrders: SelectedSideOrdersT;
};

const SideOrdersSummary = ({
  prices,
  selectedSideOrders,
}: SideOrdersSummaryProps) => {
  const selectedDays = useAppSelector(getSelectedDays);

  const { numberOfSideOrders, priceOfSideOrders } = useSideOrdersSummary({
    selectedSideOrders,
  });

  const isMobile = useMedia('(max-width: 576px)', false);

  return (
    <BoxStep smallPaddings title="Suma dodatków">
      <div className={`${styles['steps-page__divide']} spacer-top-8`} />

      <div className="display-flex justify-content-space-between align-items-center spacer-vertical-16">
        <p className="body-m color-gray-800">Liczba dni</p>

        <p className="label-m">{selectedDays.length}</p>
      </div>

      <div className="display-flex justify-content-space-between align-items-center spacer-vertical-16">
        <p className="body-m color-gray-800">Liczba dodatków</p>

        <p className="label-m">{numberOfSideOrders}</p>
      </div>

      <div className={`${styles['steps-page__divide']} spacer-top-8`} />

      <div className="display-flex justify-content-space-between align-items-center spacer-vertical-16">
        <p className="body-m color-gray-800">Dodatki łącznie</p>

        <p className="label-m">{parseNumber(priceOfSideOrders)} zł</p>
      </div>

      {!isMobile && <NextStepButton prices={prices} />}
    </BoxStep>
  );
};

export default SideOrdersSummary;
