import { IntlProvider } from 'react-intl';
import { getLang } from '../features/locale/localeSlice';
import importMessages from './translationProvider';
import { useAppSelector } from '../config/hooks';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const ConnectedIntlProvider = ({ children }: Props) => {
  const lang = useAppSelector(getLang);
  const messages = importMessages(lang);

  return (
    <IntlProvider locale={lang} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export default ConnectedIntlProvider;
