import React from 'react';
// @ts-ignore
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { customFind, findDietTagImg } from '../../utils';
import { useWindowSize } from 'react-use';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import Button from './Button';
import { DietT } from '../../@types/menu.type';
import { classNamesUtil } from '../../utils/classNamesUtil.util';
import { useGetDietTagInfoAllQuery } from '../../services/api/form/open/dietTagInfo.api';

type Props = {
  textAlignLeft?: boolean;
  containerClass?: string;
  dietDescription: string;
  dietImageUrl?: string | null;
  dietName: string;
  dietTag?: string;
  fixedBigHeight?: boolean;
  fixedSmallHeight?: boolean;
  fixedExtraSmallHeight?: boolean;
  heightList: number;
  isActive: boolean;
  mealExchange?: boolean;
  onClick: (
    event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => void;
  readMore: string;
  readMoreOnClick?: () => void;
  setHeight: (heightList: number) => void;
  setReadMore: (dietName: string) => void;
  withoutImage?: boolean;
  isNewDescription?: boolean;
  diet?: DietT;
};

const Card = ({
  containerClass,
  dietDescription,
  dietImageUrl,
  dietName,
  dietTag,
  fixedBigHeight,
  fixedExtraSmallHeight,
  fixedSmallHeight,
  heightList,
  isActive,
  isNewDescription,
  onClick,
  readMore,
  readMoreOnClick,
  setHeight,
  setReadMore,
  textAlignLeft,
  withoutImage,
}: Props) => {
  const { width } = useWindowSize();

  const { data: dietTagsData } = useGetDietTagInfoAllQuery();

  return (
    <div
      className={classNamesUtil('diet-card', {
        'diet-card--active': isActive,
        'diet-card--fixed-big-height': !!fixedBigHeight && width > 767,
        'diet-card--fixed-small-height': !!fixedSmallHeight && width > 767,
        'diet-card--fixed-extra-small-height': !!fixedExtraSmallHeight,
        [containerClass || '']: !!containerClass,
      })}
      onClick={fixedSmallHeight || withoutImage ? onClick : undefined}
      onKeyDown={fixedSmallHeight || withoutImage ? onClick : undefined}
      role="button"
      tabIndex={0}
    >
      <div
        className={classNamesUtil('diet-card__container', {
          'diet-card__container--open':
            readMore === dietName && !isNewDescription,
        })}
      >
        {isActive && (
          <div className="diet-card__check">
            <FontAwesomeIcon className="font-size-15" icon={faCheck} />
          </div>
        )}
        {!withoutImage && (
          <>
            <img
              alt={dietName}
              className="diet-card__image"
              src={
                dietImageUrl
                  ? dietImageUrl
                  : findDietTagImg(dietTag, dietTagsData)
              }
            />
          </>
        )}
        <div className="diet-card__wrapper">
          <h3
            className={classNamesUtil('diet-card__name', {
              'diet-card__name--active': isActive,
              'diet-card__name--align-left': !!textAlignLeft,
            })}
          >
            {dietName}
          </h3>

          <p
            className={classNamesUtil('diet-card__description', {
              'diet-card__line-clamp':
                readMore !== dietName || !!isNewDescription,
            })}
          >
            {dietDescription?.trim() ||
              customFind('dietTagId', dietTag)(dietTagsData)
                ?.dietDescriptions?.[0]?.description}
          </p>
          <span
            className="diet-card__more"
            onClick={e => {
              readMoreOnClick && readMoreOnClick();
              e.stopPropagation();
              if (readMore === dietName) return setReadMore('');
              setReadMore(dietName);
              setHeight(heightList);
            }}
            onKeyDown={e => {
              e.stopPropagation();
              if (readMore === dietName) return setReadMore('');
              setReadMore(dietName);
              setHeight(heightList);
            }}
            role="button"
            tabIndex={0}
          >
            {isNewDescription && (
              <>
                Zobacz opis i menu
                <FontAwesomeIcon
                  className="spacer-left-6"
                  icon={faChevronRight}
                />
              </>
            )}
          </span>
          {!withoutImage && (
            <Button
              blockButton
              colorBlank
              containerClass={classNamesUtil('diet-card__select', {
                'diet-card__select--active': isActive,
              })}
              icon={
                isActive && (
                  <FontAwesomeIcon className="font-size-15" icon={faCheck} />
                )
              }
              label={isActive ? 'Wybrany' : 'Wybierz'}
              onClick={onClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
