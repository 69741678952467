import React, { ForwardedRef, forwardRef } from 'react';
// @ts-ignore
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { classNamesUtil } from '../../utils/classNamesUtil.util';

type Props = {
  children: React.ReactNode;
  checkIcon?: boolean;
  stepNumber?: number | string;
  title?: string;
  containerClass?: string;
  smallPaddings?: boolean;
  noBottomMargin?: boolean;
  borderTitle?: boolean;
  mealExchangePage?: boolean;
  secondaryTitle?: boolean;
  overflowHidden?: boolean;
  label?: number;
  price?: number;
};

const BoxStep = forwardRef(
  (
    {
      borderTitle,
      checkIcon,
      children,
      containerClass,
      label,
      mealExchangePage,
      noBottomMargin,
      overflowHidden,
      price,
      secondaryTitle,
      smallPaddings,
      stepNumber,
      title,
    }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div className="position-rel width-100-per" ref={ref}>
        <div className="hidden-down-md">
          {checkIcon && (
            <div className="box-step__number-step color-success">
              <FontAwesomeIcon icon={faCheckCircle} />
            </div>
          )}
          {stepNumber && (
            <div className="box-step__number-step h300">{stepNumber}</div>
          )}
        </div>
        <div
          className={classNamesUtil('box-step', {
            'box-step--small-paddings': !!smallPaddings,
            'box-step--overflow-hidden': !!overflowHidden,
            'box-step--meal-exchange': !!mealExchangePage,
            'box-step--no-bottom-margin': !!noBottomMargin,
            [containerClass as string]: !!containerClass,
          })}
        >
          {title && (
            <div
              className={classNamesUtil('box-step__top-wrapper', {
                'box-step__top-wrapper--border-bottom': !!borderTitle,
              })}
            >
              <div className="display-flex align-items-center flex-wrap justify-content-space-between">
                <div className="display-flex align-items-center">
                  <div className="hidden-up-md">
                    {stepNumber && (
                      <div className="box-step__number-step h300">
                        {stepNumber}
                      </div>
                    )}
                    {checkIcon && (
                      <div className="box-step__number-step color-success">
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </div>
                    )}
                  </div>
                  <div className={'box-step__flex-breaker'}>
                    <div className="display-flex align-items-center">
                      <h3 className="box-step__title">{title}</h3>
                      {!!label && (
                        <div className="box-step__title-label">{label}</div>
                      )}
                    </div>
                    {secondaryTitle && (
                      <span className="box-step__title-secondary">
                        (wybierz dowolną liczbę cateringów)
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {!!price && (
                <div className="hidden-down-md">
                  <div className="display-flex flex-wrap align-items-center spacer-bottom-4 spacer-left-28">
                    <div className="body-m spacer-right-16 color-gray-800">
                      Dodatkowa cena za dzień
                    </div>
                    <h5 className="h500 color-primary">
                      {price.toFixed(2)} zł
                    </h5>
                  </div>
                </div>
              )}
            </div>
          )}
          {children}
          {!!price && (
            <div className="hidden-up-md">
              <div className="spacer-top-16 display-flex justify-content-space-between flex-wrap align-items-center spacer-bottom-4">
                <div className="body-m spacer-right-16 color-gray-800">
                  Dodatkowa cena za dzień
                </div>
                <h5 className="h500 color-primary">{price.toFixed(2)} zł</h5>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default BoxStep;
