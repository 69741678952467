type Props = {
  height?: string;
  width?: string;
  color?: string;
};

const ProductReturn = ({
  color = '#62748C',
  height = '22',
  width = '22',
}: Props) => {
  return (
    <svg fill="none" height={height} viewBox="0 0 22 22" width={width}>
      <path
        d="M21.75 11C21.75 16.9277 16.9277 21.75 11 21.75C5.0723 21.75 0.25 16.9277 0.25 11C0.25 10.5859 0.5859 10.25 1 10.25C1.4141 10.25 1.75 10.5859 1.75 11C1.75 16.1006 5.8994 20.25 11 20.25C16.1006 20.25 20.25 16.1006 20.25 11C20.25 5.8994 16.1006 1.75 11 1.75C8.5849 1.75 6.2855 2.716 4.5792 4.3701H6.9199C7.334 4.3701 7.6699 4.706 7.6699 5.1201C7.6699 5.5342 7.334 5.8701 6.9199 5.8701H2.9199C2.5058 5.8701 2.1699 5.5342 2.1699 5.1201V1.1201C2.1699 0.706 2.5058 0.3701 2.9199 0.3701C3.334 0.3701 3.6699 0.706 3.6699 1.1201V3.1845C5.638 1.3328 8.2515 0.25 11 0.25C16.9277 0.25 21.75 5.0723 21.75 11ZM11 4.75C10.5859 4.75 10.25 5.0859 10.25 5.5V6.2559C9.1658 6.2871 8.2147 6.9848 7.8691 8.0191C7.6503 8.6749 7.7002 9.3765 8.0097 9.9947C8.3183 10.6134 8.8505 11.0743 9.5058 11.2926L12.0195 12.1305C12.2949 12.2223 12.5185 12.4161 12.6484 12.6759C12.7783 12.9357 12.7998 13.2306 12.707 13.5065C12.5595 13.9513 12.1445 14.2502 11.6758 14.2502H10.5811C9.959 14.2502 9.4082 13.8532 9.212 13.2629C9.0802 12.8708 8.6593 12.656 8.2628 12.7888C7.8702 12.9197 7.6573 13.3445 7.7882 13.7375C8.155 14.8346 9.1215 15.5964 10.2501 15.724V16.5002C10.2501 16.9143 10.586 17.2502 11.0001 17.2502C11.4142 17.2502 11.7501 16.9143 11.7501 16.5002V15.7443C12.8343 15.7131 13.7854 15.0154 14.131 13.9811C14.3498 13.3253 14.2999 12.6237 13.9904 12.0055C13.6818 11.3868 13.1496 10.9259 12.4943 10.7076L9.9806 9.8697C9.7052 9.7779 9.4816 9.5841 9.3517 9.3243C9.2218 9.0645 9.2003 8.7696 9.2931 8.4937C9.4406 8.0489 9.8556 7.75 10.3243 7.75H11.419C12.0411 7.75 12.5919 8.147 12.7881 8.7373C12.919 9.1289 13.3379 9.3433 13.7373 9.2114C14.1299 9.0805 14.3428 8.6557 14.2119 8.2627C13.8451 7.1656 12.8786 6.4038 11.75 6.2762V5.5C11.75 5.0859 11.4141 4.75 11 4.75Z"
        fill={color}
      />
    </svg>
  );
};

export default ProductReturn;
