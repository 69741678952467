import React from 'react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from './Button';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import {
  getMealExchangeEditModalClickedArea,
  getMealExchangeEditModalStatus,
} from '@redux/selectors/orderFormSelector';
import {
  mealExchangeChangeEditModalStatus,
  select,
} from '@redux/actions/orderFormActions';
import MenuMediumIllustration from '../../assets/icons/menuMediumIllustration';
import { useAppSelector } from '@config/hooks';
import {
  discardAllDays,
  resetMenuConfiguration,
} from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.slice';
import { resetSideOrderState } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.slice';

const modalContent = {
  firstStepProgram: {
    titleText: 'Czy na pewno chcesz zmienić rodzaj programu?',
    messageText:
      'Przy zmianie programu, dotychczasowa konfiguracja menu zostanie zastąpiona nową dietą.',
    buttonText: 'Tak, zmień program',
  },
  firstStepVariant: {
    titleText: 'Czy na pewno chcesz zmienić wariant bazowy?',
    messageText:
      'Przy zmianie wariantu, dotychczasowa konfiguracja menu zostanie zastąpiona nową dietą.',
    buttonText: 'Tak, zmień wariant bazowy',
  },
  firstStepCalories: {
    titleText: 'Czy na pewno chcesz zmienić kalorie?',
    messageText:
      'Przy zmianie kalorii, dotychczasowa konfiguracja menu zostanie zastąpiona nową dietą.',
    buttonText: 'Tak, zmień kalorie',
  },
  firstStepMeals: {
    titleText: 'Czy na pewno chcesz zmienić posiłki?',
    messageText:
      'Przy zmianie posiłków, dotychczasowa konfiguracja menu zostanie zastąpiona nową dietą.',
    buttonText: 'Tak, zmień posiłki',
  },
  secondStepEditMealExchangeDay: {
    titleText:
      'Czy na pewno chcesz odznaczyć dzień z ułożoną wymianą posiłków?',
    messageText:
      'Przy próbie odznaczenia dnia który ma ułożoną wymianę posiłków zostanie ona usunięta.',
    buttonText: 'Tak, odznacz dzień',
  },
  secondStepEditMealExchangeRemoveAllDays: {
    titleText: 'Czy na pewno chcesz odznaczyć dni z ułożoną wymianą posiłków?',
    messageText:
      'Przy próbie odznaczenia dni które mają ułożoną wymianę posiłków zostanie ona usunięta.',
    buttonText: 'Tak, odznacz dni',
  },
  secondStepEditMealExchangeInput: {
    titleText: 'Czy na pewno chcesz zmienić dni z ułożoną wymianą posiłków?',
    messageText:
      'Przy próbie zmiany dni które mają ułożoną wymianę posiłków zostanie ona usunięta.',
    buttonText: 'Tak, zmień dni',
  },
};

const MealExchangeEditModal = ({
  handleEditMealExchangeDay,
  handleEditMealExchangeInput,
  handleEditMealExchangeRemoveAllDays,
  handleSelectCalorie,
  handleSelectMeals,
  handleSelectOption,
  handleSelectProgram,
  notResetMealExchange,
  optionClick,
  selectedValue,
  setSelectedValue,
}) => {
  const dispatch = useDispatch();
  const clickedArea = useAppSelector(getMealExchangeEditModalClickedArea);
  const modalStatus = useAppSelector(getMealExchangeEditModalStatus);

  return (
    <Modal
      className="custom-modal-another-catering"
      isOpen={modalStatus}
      overlayClassName="overlay-form-fixed"
    >
      <div className="display-flex flex-direction-column height-100-per justify-content-space-between position-rel">
        <FontAwesomeIcon
          className="meal-modal__close font-size-18"
          icon={faTimes}
          onClick={() => {
            dispatch(mealExchangeChangeEditModalStatus(false));
            setSelectedValue && setSelectedValue('');
          }}
        />
        <div className="text-align-center display-flex flex-direction-column align-items-center padding-top-80 padding-right-56 padding-bottom-80 padding-left-56">
          <MenuMediumIllustration />
          <p className="h400 spacer-top-24 spacer-bottom-16">
            {modalContent[clickedArea]?.titleText}
          </p>
          <p className="body-l">{modalContent[clickedArea]?.messageText}</p>
        </div>
        <div className="custom-modal-another-catering__buttons justify-content-flex-start">
          <Button
            blockButton
            colorClean
            containerClass="spacer-right-8"
            label="Anuluj"
            onClick={() => {
              dispatch(mealExchangeChangeEditModalStatus(false));
              setSelectedValue && setSelectedValue('');
            }}
          />
          <Button
            blockButton
            label={modalContent[clickedArea]?.buttonText}
            onClick={() => {
              switch (clickedArea) {
                case 'firstStepProgram':
                  handleSelectProgram(selectedValue);
                  dispatch(select('selectedOption', ''));
                  dispatch(select('selectedCalories', ''));
                  dispatch(discardAllDays());
                  dispatch(resetSideOrderState());
                  break;
                case 'firstStepVariant':
                  handleSelectOption(optionClick, selectedValue);
                  if ('dietOptionId' in selectedValue) {
                    dispatch(select('selectedOption', selectedValue));
                  }
                  dispatch(select('selectedCalories', ''));
                  dispatch(discardAllDays());
                  dispatch(resetSideOrderState());
                  break;
                case 'firstStepCalories':
                  handleSelectCalorie(selectedValue);
                  dispatch(discardAllDays());
                  dispatch(resetSideOrderState());
                  break;
                case 'firstStepMeals':
                  handleSelectMeals(selectedValue);
                  dispatch(discardAllDays());
                  dispatch(resetSideOrderState());
                  break;
                case 'secondStepEditMealExchangeDay':
                  handleEditMealExchangeDay(selectedValue);
                  break;
                case 'secondStepEditMealExchangeRemoveAllDays':
                  handleEditMealExchangeRemoveAllDays();
                  dispatch(discardAllDays());
                  dispatch(resetSideOrderState());
                  break;
                case 'secondStepEditMealExchangeInput':
                  handleEditMealExchangeInput();
                  dispatch(discardAllDays());
                  dispatch(resetSideOrderState());
                  break;
                default:
                  return null;
              }

              !notResetMealExchange && dispatch(resetMenuConfiguration());
              dispatch(mealExchangeChangeEditModalStatus(false));
              setSelectedValue && setSelectedValue('');
            }}
            sizeLarge
          />
        </div>
      </div>
    </Modal>
  );
};

MealExchangeEditModal.propTypes = {
  handleEditMealExchangeDay: PropTypes.func,
  handleEditMealExchangeInput: PropTypes.func,
  handleEditMealExchangeRemoveAllDays: PropTypes.func,
  handleSelectCalorie: PropTypes.func,
  handleSelectMeals: PropTypes.func,
  handleSelectOption: PropTypes.func,
  handleSelectProgram: PropTypes.func,
  notResetMealExchange: PropTypes.bool,
  optionClick: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  setSelectedValue: PropTypes.func,
};

export default MealExchangeEditModal;
