import React, { RefObject } from 'react';

import {
  getMenuConfActiveDay,
  getMenuConfCustomDeliveryMeals,
} from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.selector';
import {
  changeMealCalories,
  refetchMeals,
} from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.slice';
import { MealFromAnotherCaloriesT } from '@features/orderForm/stepMenuConfiguration/types/menuConf.type';
import {
  parseCaloriesFromItem,
  prepareChangeCaloriesCustomDeliveryMeals,
  scrollToNextMeal,
} from '@features/orderForm/stepMenuConfiguration/utils/menuConf.utils';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSelectedCaloriesId } from '@redux/selectors/orderFormSelector';
import { UseStateT } from 'typesRoot/generics.type';
import { useAppDispatch, useAppSelector } from '@config/hooks';
import { getServerConfig } from '@features/general/generalSlice';
import { getSelectedCity } from '@redux/selectors/summaryReducer.selectors';
import { useGetMenuConfigurationChangeCaloriesQuery } from '@services/api/form/open/order-form/steps/orderFormSteps.api';
import CustomModal from '@components/common/CustomModal';
import CoinsIcon from '@assets/icons/CoinsIcon';
import Spinner from '@components/common/Spinner';

type Props = {
  calories: number;
  carouselId: string;
  carouselName: string;
  isOpen: boolean;
  refs: Record<string, RefObject<HTMLDivElement>>;
  setIsOpen: UseStateT<boolean>;
};

const MenuConfChangeCaloriesModal = ({
  calories,
  carouselId,
  carouselName,
  isOpen,
  refs,
  setIsOpen,
}: Props) => {
  const dispatch = useAppDispatch();

  const { cityId } = useAppSelector(getSelectedCity) || {};
  const activeDay = useAppSelector(getMenuConfActiveDay);
  const selectedDietCaloriesId = useAppSelector(getSelectedCaloriesId);
  const customDeliveryMeals = useAppSelector(getMenuConfCustomDeliveryMeals);
  const { companyId } = useAppSelector(getServerConfig);

  const dietCaloriesMealId =
    ((customDeliveryMeals[activeDay] || {})[carouselId] || {})
      .dietCaloriesMealId || 0;

  const { data, isFetching } = useGetMenuConfigurationChangeCaloriesQuery(
    {
      cityId,
      companyId: companyId as string,
      date: activeDay,
      dietCaloriesId: selectedDietCaloriesId,
      dietCaloriesMealId,
    },
    {
      skip:
        !cityId || !dietCaloriesMealId || !selectedDietCaloriesId || !activeDay,
    }
  );

  const { name = '', options = [] } = data || {};

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleChangeCalories = async (item: MealFromAnotherCaloriesT) => {
    const itemCalories = parseCaloriesFromItem(item);
    if (itemCalories === calories) {
      scrollToNextMeal(refs, carouselId);
      closeModal();
      return;
    }
    // Zmień kaloryczność posiłku - zaktualizuj customDeliveryMeals, pobierz dane dla nowego posiłku, zaktualizuj mealsPerDay
    const { dietCaloriesMealId } = item;
    const customDeliveryMealsForActiveDay = customDeliveryMeals[activeDay];
    if (customDeliveryMealsForActiveDay) {
      const newCustomDeliveryMealsForActiveDay =
        prepareChangeCaloriesCustomDeliveryMeals(
          carouselId,
          dietCaloriesMealId,
          carouselName,
          customDeliveryMealsForActiveDay
        );

      dispatch(
        changeMealCalories({ carouselId, newCustomDeliveryMealsForActiveDay })
      );
      dispatch(refetchMeals(true));
      scrollToNextMeal(refs, carouselId);
      closeModal();
    }
    // sendEvent(EventName.ChangeKcal);
  };

  return (
    <CustomModal
      className="meal-calories-modal"
      isOpen={isOpen}
      onRequestClose={closeModal}
      overlayClassName="custom-modal-overlay"
    >
      <FontAwesomeIcon
        className="meal-calories-modal__close font-size-20"
        icon={faTimes}
        onClick={closeModal}
      />
      <h3 className="meal-calories-modal__title">Zmień kaloryczność</h3>
      <p className="body-m spacer-bottom-40">
        {isFetching ? 'Ładuję dostępne posiłki' : name}
      </p>
      {isFetching ? (
        <Spinner />
      ) : (
        options.map(item => {
          const {
            dietCaloriesMealId = 0,
            info = '',
            name: itemName = '',
            price = '',
          } = item;

          const isActive = parseCaloriesFromItem(item) === calories;

          return (
            <div
              className={`meal-calories-modal__card ${
                isActive ? 'meal-calories-modal__card--active' : ''
              }`}
              key={dietCaloriesMealId}
              onClick={() => handleChangeCalories(item)}
              onKeyDown={() => handleChangeCalories(item)}
              role="button"
              tabIndex={0}
            >
              {isActive && (
                <div className="meal__check">
                  <FontAwesomeIcon className="font-size-15" icon={faCheck} />
                </div>
              )}
              <h6 className="label-l spacer-bottom-4">{itemName}</h6>
              <div className="display-flex align-items-center justify-content-space-between">
                <p className="body-m color-gray-400">{info}</p>
                <div className="display-flex align-items-center spacer-left-6 flex-shrink-0">
                  <CoinsIcon color="#06257F" height="16" width="16" />
                  <span className="label-m color-primary-500 spacer-left-4">
                    {price}
                  </span>
                </div>
              </div>
            </div>
          );
        })
      )}
    </CustomModal>
  );
};

export default MenuConfChangeCaloriesModal;
