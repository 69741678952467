import React from 'react';

const CaloriesIcon = ({ className }) => {
  return (
    <svg
      className={className}
      fill="none"
      height="160"
      viewBox="0 0 200 160"
      width="200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M127.009 23.0874L102.453 27.8462C98.831 28.5482 95.1343 27.057 93.013 24.0382C88.8278 18.0825 79.7448 18.9726 76.7954 25.6275L65.4964 51.1228C64.5132 53.3412 63.0066 55.2879 61.1057 56.796L29.4925 81.8776C20.3891 89.1 25.0232 103.745 36.6242 104.416L41.4716 104.697C50.3394 105.21 54.5322 115.879 48.3863 122.292C40.7929 130.216 49.0435 142.977 59.3855 139.305L81.3517 131.505C84.9776 130.218 88.9597 130.373 92.4744 131.939L126.801 147.23C134.928 150.85 144.402 146.641 147.164 138.184L147.978 135.69C149.268 131.741 152.137 128.504 155.902 126.75L187.742 111.913C198.612 106.848 194.061 90.4669 182.137 91.7342C171.285 92.8876 165.964 78.8825 174.844 72.5394L176.883 71.083C186.901 63.9276 179.838 48.1873 167.833 50.9133L143.966 56.333C134.267 58.5353 127.965 46.4183 135.337 39.7419C142.588 33.1747 136.613 21.2261 127.009 23.0874Z"
        fill="#1B42B2"
        opacity="0.1"
      />
      <path
        clipRule="evenodd"
        d="M156.141 129.14C156.141 132.456 152.563 135.14 148.141 135.14L156.141 129.14Z"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M46.1406 125.14C46.1406 129.562 49.7186 133.14 54.1406 133.14M154.141 33.1396C154.141 27.6116 150.563 23.1396 146.141 23.1396M154.141 111.14C154.141 122.194 145.195 131.14 134.141 131.14M156.141 129.14C156.141 132.456 152.563 135.14 148.141 135.14"
        stroke="#FF5226"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M112.236 65.2837C112.236 65.2837 117.644 33.9347 95.5976 20C95.5976 29.7585 90.3503 39.3173 80.935 47.6822C71.5197 56.0472 53.8143 74.8217 54.0015 94.8981C54.1533 111.121 62.9134 130.966 83.15 138C83.5347 119.298 99.0127 111.63 99.7573 92.7933C111.259 104.024 116.209 119.727 116.379 137.734C130.954 132.532 143.566 120.093 145.345 98.6168C147.216 75.9905 132.978 56.85 126.198 49.1173"
        fill="#fff"
      />
      <path
        d="M112.236 65.2837C112.236 65.2837 117.644 33.9347 95.5976 20C95.5976 29.7585 90.3503 39.3173 80.935 47.6822C71.5197 56.0472 53.8143 74.8217 54.0015 94.8981C54.1533 111.121 62.9134 130.966 83.15 138C83.5347 119.298 99.0127 111.63 99.7573 92.7933C111.259 104.024 116.209 119.727 116.379 137.734C130.954 132.532 143.566 120.093 145.345 98.6168C147.216 75.9905 132.978 56.85 126.198 49.1173C124.456 52.934 119.225 61.5106 112.236 65.2837Z"
        stroke="#1B42B2"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M98.3039 45C96.2666 47.8852 93.726 50.6767 90.7238 53.3363C82.0814 60.9924 65.8295 78.1758 66.0014 96.5508C66.0657 103.402 67.8123 110.959 71.5 117.744"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};

export default CaloriesIcon;
