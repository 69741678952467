import React, { useEffect, useRef, useState } from 'react';
import {
  faArrowCircleLeft,
  faArrowLeft,
  faSync,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProgressBar from '../components/common/ProgressBar';
import Calendar from '../components/pages/StepSelectDays/views/Calendar';
import NumberOfDays from '../components/pages/StepSelectDays/views/NumberOfDays';
import UnselectAll from '../components/pages/StepSelectDays/views/UnselectAll';
import ValidationMessage from '../components/pages/StepSelectDays/views/ValidationMessage';
import WeekendCheckbox from '../components/pages/StepSelectDays/views/WeekendCheckbox';
import useIsWide from '../hooks/useIsWide';
import {
  getOrderForm,
  getSelectedProgram,
} from '@redux/selectors/orderFormSelector';
import { getBasket, getBasketEdition } from '@redux/selectors/basketSelector';
import { getSelectedCity } from '@redux/selectors/summaryReducer.selectors';
import { usePrevious } from 'react-use';
import { Link, useNavigate } from 'react-router-dom';
import { storeHistory } from '@redux/actions/orderFormHistoryActions';
import BoxStep from '../components/common/BoxStep';
import {
  resetCalendar,
  setDefaultDays,
  toggleDay,
} from '@redux/actions/orderFormActions';
import Button from '../components/common/Button';
import Navigation from '../components/common/navigation';
import { DateTime } from 'luxon';
import MealExchangeEditModal from '../components/common/mealExchangeEditModal';
import SecondStepSideBar from '../components/pages/StepSelectDays/views/SecondStepSideBar';
import SecondStepSummaryBar from '../components/pages/StepSelectDays/views/SecondStepSummaryBar';
import { useEnhancedEcommercePageInfo } from '@hooks/useEnhancedEcommercePageInfo';
import { useAppDispatch, useAppSelector } from '@config/hooks';
import {
  addOrSubtractDay,
  getLatestSelectedDay,
  getSelectedDaysLength,
  isDayOutsideDaysLimit,
  setDays,
  validateQuantityOfOrder,
} from '@components/pages/StepSelectDays/utils/selectDays.utils';
import { useGetApiFormSettingsQuery } from '@services/api/form/open/open.api';
import { getServerConfig } from '@features/general/generalSlice';
import { classNamesUtil } from '../utils/classNamesUtil.util';
import useCalculateItemPrice from '../services/hook/calculatePrices/useCalculateItemPrice.hook';
import { discardDay } from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.slice';
import { getMenuConfCustomDeliveryMeals } from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.selector';

const StepSelectDays = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isWide = useIsWide();

  const orderForm = useAppSelector(getOrderForm);
  const basket = useAppSelector(getBasket);
  const basketEdition = useAppSelector(getBasketEdition);
  const selectedCity = useAppSelector(getSelectedCity);
  const selectedProgram = useAppSelector(getSelectedProgram);
  const { companyId } = useAppSelector(getServerConfig);
  const customDeliveryMeals = useAppSelector(getMenuConfCustomDeliveryMeals);

  const {
    days,
    selectedCalories,
    selectedDays,
    selectedDiet,
    selectedOption,
    testOrder,
  } = orderForm || {};

  const { data: formSettings, isSuccess } = useGetApiFormSettingsQuery(
    undefined,
    {
      skip: !companyId,
    }
  );

  const [warningInput, setWarningInput] = useState(true);
  const [selectedValue, setSelectedValue] = useState(0);
  const [validationStatus, setValidationStatus] = useState({
    message: '',
    value: '',
  });
  const [showMessagesFixed, setShowMessagesFixed] = useState(true);
  const prevShowMessagesFixed = usePrevious(showMessagesFixed);

  const calendarRef = useRef(null);

  //Validation message will be shown again after closing if user still doesn't support correct data
  useEffect(() => {
    if (showMessagesFixed === prevShowMessagesFixed) {
      setShowMessagesFixed(true);
    }
  }, [prevShowMessagesFixed, showMessagesFixed]);

  const clearValidationStatus = () => {
    setValidationStatus({
      message: '',
      value: '',
    });
  };

  // disable validation if the days of the order are selected
  useEffect(() => {
    if (selectedDays?.length !== 0) {
      clearValidationStatus();
    }
  }, [selectedDays?.length]);

  // scroll to elements with error
  useEffect(() => {
    if (validationStatus.value) {
      // @ts-ignore
      calendarRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [validationStatus.value]);

  useEffect(() => {
    if (
      !selectedDiet ||
      !selectedOption ||
      !selectedCalories ||
      !selectedCity?.cityId
    ) {
      navigate('/', { replace: true });
    }
    dispatch(storeHistory(2));
  }, [
    dispatch,
    navigate,
    selectedCalories,
    selectedCity?.cityId,
    selectedDiet,
    selectedOption,
  ]);

  useEffect(() => {
    if (formSettings) {
      if (
        !basketEdition.isEdited &&
        !selectedDays?.length &&
        !days &&
        !testOrder
      ) {
        dispatch(
          setDefaultDays(formSettings?.orderSettings?.defaultDaySuggestion)
        );
      }

      if (days === 0) {
        if (testOrder) {
          dispatch(
            setDefaultDays(formSettings?.testOrderSettings?.minimumDays)
          );
        } else {
          dispatch(
            setDefaultDays(formSettings?.orderSettings?.defaultDaySuggestion)
          );
        }
      }
    }
  }, [
    basketEdition.isEdited,
    days,
    dispatch,
    formSettings,
    selectedDays?.length,
    testOrder,
  ]);

  const { canSend, sendEvent } = useEnhancedEcommercePageInfo();

  useEffect(() => {
    canSend && sendEvent();
  }, [canSend, sendEvent]);

  const handleChangeDaysOnClick = (
    operation: 'add' | 'minus',
    direction: 'minus' | 'plus'
  ) => {
    const latestDay = getLatestSelectedDay(selectedDays);
    const nextDay =
      (latestDay &&
        DateTime.fromMillis(latestDay).plus({ day: 1 }).toMillis()) ||
      0;

    const nextDayIsOutsideDaysLimit = isDayOutsideDaysLimit(
      nextDay,
      formSettings?.orderSettings?.maximumOrderAdvanceDays
    );

    if (operation === 'add' && nextDayIsOutsideDaysLimit) {
      return;
    }

    if (getSelectedDaysLength(selectedDays) > 0) {
      dispatch(addOrSubtractDay(operation));
    }

    dispatch(setDays(direction));
  };

  const handleEditMealExchangeDay = (day: number) => {
    if (selectedValue) {
      dispatch(toggleDay(day));
      const dayISO = DateTime.fromMillis(day).toISODate();
      if (dayISO) {
        dispatch(discardDay(dayISO));
      }
    } else {
      handleChangeDaysOnClick('minus', 'minus');
    }
  };

  const { isFetching, prices } = useCalculateItemPrice();
  return (
    <>
      <Navigation />

      <ProgressBar />

      <MealExchangeEditModal
        handleEditMealExchangeDay={handleEditMealExchangeDay}
        handleEditMealExchangeInput={() => {
          setWarningInput(false);
        }}
        handleEditMealExchangeRemoveAllDays={() => {
          dispatch(resetCalendar());
        }}
        notResetMealExchange
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
      />

      {!isSuccess && !formSettings ? (
        <section className="steps-page__loader">
          <FontAwesomeIcon
            className="font-size-60 color-primary"
            icon={faSync}
            spin
          />
        </section>
      ) : (
        <>
          <div className="steps-page">
            <div className="steps-page__container">
              <Link className="steps-page__back-button" to="/">
                <Button
                  buttonLink
                  icon={<FontAwesomeIcon icon={faArrowCircleLeft} />}
                  label="Powrót"
                />
              </Link>
              <div className="steps-page-second">
                <div className="steps-page-second__container">
                  {validationStatus.value &&
                    validationStatus.value !== 'Days not selected' &&
                    showMessagesFixed && (
                      <ValidationMessage
                        clearValidationStatus={clearValidationStatus}
                        setShowMessagesFixed={setShowMessagesFixed}
                        validationStatus={validationStatus}
                      />
                    )}

                  <BoxStep noBottomMargin title="Szczegóły zamówienia">
                    <div
                      className={classNamesUtil(
                        'steps-page-second__action-buttons',
                        {
                          'align-items-center':
                            !formSettings?.orderSettings?.deliveryOnSaturday &&
                            !formSettings?.orderSettings?.deliveryOnSunday,
                          'align-items-start':
                            !!formSettings?.orderSettings?.deliveryOnSaturday ||
                            !!formSettings?.orderSettings?.deliveryOnSunday,
                        }
                      )}
                    >
                      <div className="display-flex flex-wrap align-items-center">
                        <NumberOfDays
                          customDeliveryMeals={customDeliveryMeals}
                          formSettings={formSettings}
                          handleChangeDaysOnClick={handleChangeDaysOnClick}
                          orderForm={orderForm}
                          selectedDays={selectedDays}
                          selectedProgram={selectedProgram}
                          warningInput={warningInput}
                        />

                        {!!selectedDays?.length && (
                          <UnselectAll
                            customDeliveryMeals={customDeliveryMeals}
                            formSettings={formSettings}
                            orderForm={orderForm}
                            selectedProgram={selectedProgram}
                          />
                        )}
                      </div>

                      <WeekendCheckbox
                        formSettings={formSettings}
                        orderForm={orderForm}
                        selectedCity={selectedCity}
                        selectedProgram={selectedProgram}
                      />
                    </div>

                    <div ref={calendarRef}>
                      <Calendar
                        orderForm={orderForm}
                        setSelectedValue={setSelectedValue}
                        textCalendar1={formSettings?.additionalTexts.calendar1}
                        textCalendar2={formSettings?.additionalTexts.calendar2}
                        validateQuantityOfOrder={validateQuantityOfOrder(
                          basket,
                          basketEdition,
                          orderForm,
                          formSettings
                        )}
                        validationDays={validationStatus}
                      />
                    </div>
                  </BoxStep>
                </div>

                <SecondStepSideBar
                  isFetching={isFetching}
                  isSummaryBarDisplayed={isWide}
                  prices={prices}
                  setValidationStatus={setValidationStatus}
                />

                <Link className="hidden-up-md" to="/">
                  <Button
                    icon={<FontAwesomeIcon icon={faArrowLeft} />}
                    label="Wstecz"
                  />
                </Link>
              </div>
            </div>
          </div>

          {isWide && (
            <SecondStepSummaryBar
              days={selectedDays.length}
              prices={prices}
              setValidationStatus={setValidationStatus}
            />
          )}
        </>
      )}
    </>
  );
};

export default StepSelectDays;
