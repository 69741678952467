import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from '../../../common/radio';
import PaymentOnlineIcon from '../../../../assets/icons/paymentOnlineIcon';
import MoneyInsertIcon from '../../../../assets/icons/moneyInsertIcon';
import CoinsIcon from '../../../../assets/icons/CoinsIcon';
import CalendarIcon from '../../../../assets/icons/calendarIcon';

type Props = {
  activePayment: string;
  finalAmountToPayAfterDiscountsAndDeliveryFee: number;
  isLoanEnabled: boolean;
  partnerId?: string;
  paymentTypes: Array<string>;
  setActivePayment: (value: string) => void;
};

const PaymentMethods = ({
  activePayment,
  finalAmountToPayAfterDiscountsAndDeliveryFee,
  isLoanEnabled,
  partnerId,
  paymentTypes,
  setActivePayment,
}: Props) => {
  return (
    <>
      <p className="label-m spacer-bottom-16">Metoda płatności</p>
      <div className="display-flex flex-direction-column">
        {paymentTypes?.includes('ONLINE') && (
          <div className="spacer-bottom-16">
            <Radio
              checked={activePayment === 'ONLINE'}
              id="ONLINE"
              label={
                <div className="display-flex align-items-center">
                  <PaymentOnlineIcon />
                  <div className="spacer-left-10">Szybka płatność online</div>
                </div>
              }
              name="ONLINE"
              onChange={() => {
                setActivePayment('ONLINE');
              }}
            />
          </div>
        )}
        {paymentTypes?.includes('BANK_TRANSFER') && (
          <div className="spacer-bottom-16">
            <Radio
              checked={activePayment === 'BANK_TRANSFER'}
              id="BANK_TRANSFER"
              label={
                <div className="display-flex align-items-center">
                  <MoneyInsertIcon />
                  <div className="spacer-left-10">Przelew</div>
                </div>
              }
              name="BANK_TRANSFER"
              onChange={() => {
                setActivePayment('BANK_TRANSFER');
              }}
            />
          </div>
        )}
        {paymentTypes?.includes('CASH') && (
          <div className="spacer-bottom-16">
            <Radio
              checked={activePayment === 'CASH'}
              id="CASH"
              label={
                <div className="display-flex align-items-center">
                  <CoinsIcon />
                  <div className="spacer-left-10">Gotówka</div>
                </div>
              }
              name="CASH"
              onChange={() => {
                setActivePayment('CASH');
              }}
            />
          </div>
        )}
        {isLoanEnabled &&
          partnerId &&
          finalAmountToPayAfterDiscountsAndDeliveryFee > 0 && (
            <div className="spacer-bottom-16">
              <Radio
                checked={activePayment === 'INSTALLMENTS'}
                id="INSTALLMENTS"
                label={
                  <div className="display-flex align-items-center">
                    <CalendarIcon />
                    <div className="spacer-left-10">Raty</div>
                  </div>
                }
                name="INSTALLMENTS"
                onChange={() => {
                  setActivePayment('INSTALLMENTS');
                }}
              />
            </div>
          )}
      </div>
    </>
  );
};

PaymentMethods.propTypes = {
  finalAmountToPayAfterDiscountsAndDeliveryFee: PropTypes.number,
  isLoanEnabled: PropTypes.bool,
  partnerId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  paymentTypes: PropTypes.array,
  setActivePayment: PropTypes.func,
};

export default PaymentMethods;
