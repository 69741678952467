import React from 'react';

const OrderIcon = ({ className }) => {
  return (
    <svg
      className={className}
      fill="none"
      height="205"
      viewBox="0 0 218 205"
      width="218"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M155.294 114.607L175.746 110.212C188.27 107.52 191.875 91.382 181.687 83.6169L174.359 78.0312C168.705 73.7223 166.853 66.0371 169.921 59.6255L175.367 48.2474C182.129 34.1202 165.437 20.3861 152.877 29.742L149.538 32.229C142.532 37.4473 132.53 35.2642 128.336 27.6013C123.554 18.8644 111.552 17.518 104.953 24.9782L63.6666 71.6525C62.4471 73.0311 60.9725 74.1607 59.3239 74.9791L44.3612 82.407C32.349 88.3702 38.1585 106.568 51.404 104.468C61.2726 102.903 68.4434 113.619 63.2332 122.145L43.1363 155.031C34.8289 168.625 50.6437 184.085 64.0457 175.471L91.8441 157.604C99.5111 152.677 109.765 155.669 113.578 163.947L114.751 166.493C121.476 181.09 143.339 176.322 143.375 160.251L143.446 129.238C143.462 122.181 148.395 116.09 155.294 114.607Z"
        fill="#06257F"
        opacity="0.1"
      />
      <path
        clipRule="evenodd"
        d="M159.516 153H58.482C54.35 153 51 149.65 51 145.516V73H167V145.516C167 149.65 163.65 153 159.516 153Z"
        fill="white"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M53 45H165V73H53V45Z"
        fill="#D0E8F9"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M53 45H165V73H53V45Z"
        fill="#ECEEF8"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="M133 92V101C133 114.2 122.2 125 109 125C95.8 125 85 114.2 85 101V92"
        stroke="#FF5226"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M149 57V73V57Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M57 45L69 57M69 57L57 73M69 57V73M161 45L149 57M149 57L161 73M149 57V73"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M99 55C99 56.104 98.104 57 97 57C95.896 57 95 56.104 95 55C95 53.896 95.896 53 97 53C98.104 53 99 53.896 99 55Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M105 49C105 50.104 104.104 51 103 51C101.896 51 101 50.104 101 49C101 47.896 101.896 47 103 47C104.104 47 105 47.896 105 49Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M105 61C105 62.104 104.104 63 103 63C101.896 63 101 62.104 101 61C101 59.896 101.896 59 103 59C104.104 59 105 59.896 105 61Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M111 55C111 56.104 110.104 57 109 57C107.896 57 107 56.104 107 55C107 53.896 107.896 53 109 53C110.104 53 111 53.896 111 55Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M87 55C87 56.104 86.104 57 85 57C83.896 57 83 56.104 83 55C83 53.896 83.896 53 85 53C86.104 53 87 53.896 87 55Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M93 49C93 50.104 92.104 51 91 51C89.896 51 89 50.104 89 49C89 47.896 89.896 47 91 47C92.104 47 93 47.896 93 49Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M93 61C93 62.104 92.104 63 91 63C89.896 63 89 62.104 89 61C89 59.896 89.896 59 91 59C92.104 59 93 59.896 93 61Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M75 55C75 56.104 74.104 57 73 57C71.896 57 71 56.104 71 55C71 53.896 71.896 53 73 53C74.104 53 75 53.896 75 55Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M69 49C69 50.104 68.104 51 67 51C65.896 51 65 50.104 65 49C65 47.896 65.896 47 67 47C68.104 47 69 47.896 69 49Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M81 49C81 50.104 80.104 51 79 51C77.896 51 77 50.104 77 49C77 47.896 77.896 47 79 47C80.104 47 81 47.896 81 49Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M81 61C81 62.104 80.104 63 79 63C77.896 63 77 62.104 77 61C77 59.896 77.896 59 79 59C80.104 59 81 59.896 81 61Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M147 55C147 56.104 146.104 57 145 57C143.896 57 143 56.104 143 55C143 53.896 143.896 53 145 53C146.104 53 147 53.896 147 55Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M153 49C153 50.104 152.104 51 151 51C149.896 51 149 50.104 149 49C149 47.896 149.896 47 151 47C152.104 47 153 47.896 153 49Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M117 49C117 50.104 116.104 51 115 51C113.896 51 113 50.104 113 49C113 47.896 113.896 47 115 47C116.104 47 117 47.896 117 49Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M117 61C117 62.104 116.104 63 115 63C113.896 63 113 62.104 113 61C113 59.896 113.896 59 115 59C116.104 59 117 59.896 117 61Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M123 55C123 56.104 122.104 57 121 57C119.896 57 119 56.104 119 55C119 53.896 119.896 53 121 53C122.104 53 123 53.896 123 55Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M135 55C135 56.104 134.104 57 133 57C131.896 57 131 56.104 131 55C131 53.896 131.896 53 133 53C134.104 53 135 53.896 135 55Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M99 67C99 68.104 98.104 69 97 69C95.896 69 95 68.104 95 67C95 65.896 95.896 65 97 65C98.104 65 99 65.896 99 67Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M111 67C111 68.104 110.104 69 109 69C107.896 69 107 68.104 107 67C107 65.896 107.896 65 109 65C110.104 65 111 65.896 111 67Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M87 67C87 68.104 86.104 69 85 69C83.896 69 83 68.104 83 67C83 65.896 83.896 65 85 65C86.104 65 87 65.896 87 67Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M75 67C75 68.104 74.104 69 73 69C71.896 69 71 68.104 71 67C71 65.896 71.896 65 73 65C74.104 65 75 65.896 75 67Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M147 67C147 68.104 146.104 69 145 69C143.896 69 143 68.104 143 67C143 65.896 143.896 65 145 65C146.104 65 147 65.896 147 67Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M123 67C123 68.104 122.104 69 121 69C119.896 69 119 68.104 119 67C119 65.896 119.896 65 121 65C122.104 65 123 65.896 123 67Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M135 67C135 68.104 134.104 69 133 69C131.896 69 131 68.104 131 67C131 65.896 131.896 65 133 65C134.104 65 135 65.896 135 67Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M129 49C129 50.104 128.104 51 127 51C125.896 51 125 50.104 125 49C125 47.896 125.896 47 127 47C128.104 47 129 47.896 129 49Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M141 49C141 50.104 140.104 51 139 51C137.896 51 137 50.104 137 49C137 47.896 137.896 47 139 47C140.104 47 141 47.896 141 49Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M129 61C129 62.104 128.104 63 127 63C125.896 63 125 62.104 125 61C125 59.896 125.896 59 127 59C128.104 59 129 59.896 129 61Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <path
        className="steps-page-basket__empty-basket-dot"
        clipRule="evenodd"
        d="M141 61C141 62.104 140.104 63 139 63C137.896 63 137 62.104 137 61C137 59.896 137.896 59 139 59C140.104 59 141 59.896 141 61Z"
        fill="#06257F"
        fillRule="evenodd"
      />
      <circle
        cx="85"
        cy="88"
        r="4"
        stroke="#FF5226"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <circle
        cx="133"
        cy="88"
        r="4"
        stroke="#FF5226"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default OrderIcon;
