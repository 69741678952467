import React, { ChangeEvent, useState } from 'react';
import PropTypes from 'prop-types';
import CoinAlternativeIcon from '../../../../assets/icons/coinAlternativeIcons';
import { numberWithSpaces } from '../../../../utils';
import { Field, Form, Formik } from 'formik';
import { Input } from '../../../common/Input';
import Button from '../../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/pro-regular-svg-icons';
import * as Yup from 'yup';
import { postRegisterOrderConfirmation } from '../../../../services/api/form/order.api';
import {
  getIsCustomLoyaltyProgram,
  getServerConfig,
} from '../../../../features/general/generalSlice';
import {
  burakLoyaltyProgramData,
  defaultLoyaltyProgramData,
} from '../../../../data/loyaltyProgramData';
import { BurakProgramSmallIcon } from '../../../../assets/icons/BurakCoin/BurakProgramSmallIcon';
import { useAppSelector } from '../../../../config/hooks';

type Props = {
  loyaltyProgramEnabled: boolean;
  orderId: string;
  setSent: (b: boolean) => void;
  successPageData: any;
};

const RegisterConfirmation = ({
  loyaltyProgramEnabled,
  orderId,
  setSent,
  successPageData,
}: Props) => {
  const [sending, setSending] = useState(false);
  const { apiKey, companyId, serverUrl } = useAppSelector(getServerConfig);
  const isCustomLoyaltyProgram = useAppSelector(getIsCustomLoyaltyProgram);

  const loyaltyProgramData = isCustomLoyaltyProgram
    ? burakLoyaltyProgramData.registerConfirmation
    : defaultLoyaltyProgramData.registerConfirmation;

  const register = (data: unknown) => {
    postRegisterOrderConfirmation(data, serverUrl, companyId, apiKey)
      .then(
        response => {
          const { status } = response;
          if (status === 200) {
            setSent(true);
            window.scrollTo(0, 0);
            sessionStorage.removeItem('order-id-form');
          }
          return Promise.reject(response);
        },
        err => {
          console.log(`Cannot register after order. Error message: ${err}`);
          setSent(false);
        }
      )
      .finally(() => {
        setSending(false);
      });
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .matches(/(?=.*[a-z])/, 'Wymagana przynajmniej 1 mała litera')
      .matches(/(?=.*[A-Z])/, 'Wymagana przynajmniej 1 wielka litera')
      .matches(/(?=.*[0-9])/, 'Wymagana przynajmniej 1 cyfra')
      .matches(/^[\S]+$/, 'Hasło powinno być bez spacji')
      .min(8, 'Hasło jest za krótkie')
      .max(255, 'Hasło jest za długie')
      .required('Hasło jest wymagane'),
  });

  const {
    allowMessages,
    company,
    companyId: sourceCompanyName,
    companyName,
    email,
    loyaltyPointsForRegistration,
    name,
    phone,
    surname,
    taxId,
  } = successPageData || {};

  return (
    <>
      {loyaltyProgramEnabled ? (
        <div className="steps-page__transaction-page-wrapper">
          <div className="text-align-center">
            {isCustomLoyaltyProgram ? (
              BurakProgramSmallIcon
            ) : (
              <CoinAlternativeIcon className="coin-alternative-icon-color" />
            )}
            <h4 className="h300 spacer-bottom-8 spacer-top-24">
              Odbierz {numberWithSpaces(loyaltyPointsForRegistration)}{' '}
              {loyaltyProgramData.text1}
            </h4>
            <p className="body-l">
              Następne zamówienie, będziesz mógł zamówić za{' '}
              <span className="color-primary font-weight-500">
                {numberWithSpaces(loyaltyPointsForRegistration / 100)} zł taniej
              </span>
              .
              <br />
              Wystarczy, że założysz darmowe konto i uzyskasz dostęp do panelu
              klienta.
            </p>
          </div>
        </div>
      ) : (
        <div className="steps-page__transaction-page-wrapper">
          <p className="body-l text-align-center">
            Załóż darmowe konto i uzyskaj dostęp do panelu klienta.
          </p>
        </div>
      )}
      <div className="steps-page__transaction-page-wrapper">
        <p className="body-l text-align-center">
          Załóż darmowe konto i uzyskaj dostęp do Panelu Klienta.
        </p>
      </div>
      <Formik
        initialValues={{
          password: '',
        }}
        onSubmit={values => {
          setSending(true);
          register({
            password: values.password,
            firstName: name,
            lastName: surname,
            email,
            phoneNumber: phone,
            company,
            companyName,
            taxId,
            sourceCompanyName,
            allowMessages,
            dietlySignup: false,
            orderId,
          });
        }}
        validationSchema={validationSchema}
      >
        {({ errors, setFieldValue, touched, values }) => (
          <Form className="steps-page__transaction-page-form">
            <Field
              component={Input}
              containerClass="width-100-per"
              disabled
              label="Email"
              value={successPageData?.email}
            />
            <Field
              component={Input}
              containerClass="width-100-per"
              errorDown
              errorMsg={touched?.password && errors?.password}
              id="password"
              label="Hasło"
              name="password"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setFieldValue('password', e.target.value);
              }}
              passwordInput
              placeholder="Podaj hasło"
              type="password"
              value={values.password}
            />
            <Button
              blockButton
              disabled={!values?.password?.length}
              icon={sending && <FontAwesomeIcon icon={faSyncAlt} spin />}
              label={sending ? 'Zakładanie konta' : 'Załóż konto'}
              sizeLarge
              type="submit"
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

RegisterConfirmation.propTypes = {
  loyaltyProgramEnabled: PropTypes.bool,
  orderId: PropTypes.string,
  setSent: PropTypes.func,
  successPageData: PropTypes.object,
};

export default RegisterConfirmation;
