import { DateTime } from 'luxon';

export const renderOpeningHours = (
  holidaysFrom: string | null,
  holidaysTo: string | null,
  saturdayFrom: string | null,
  saturdayTo: string | null,
  weekdaysFrom: string | null,
  weekdaysTo: string | null
) => {
  const texts = [];
  const parseTime = (time: string) => DateTime.fromISO(time).toFormat('HH:mm');

  if (weekdaysFrom && weekdaysTo) {
    texts.push(`pon-pt: ${parseTime(weekdaysFrom)} - ${parseTime(weekdaysTo)}`);
  }

  if (saturdayFrom && saturdayTo) {
    texts.push(`sob: ${parseTime(saturdayFrom)} - ${parseTime(saturdayTo)}`);
  }

  if (holidaysFrom && holidaysTo) {
    texts.push(`niedz: ${parseTime(holidaysFrom)} - ${parseTime(holidaysTo)}`);
  }

  if (texts.length > 0) {
    return `Godziny otwarcia: ${texts.join(', ')}`;
  }

  return '';
};
