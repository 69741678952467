import React from 'react';
import Lightbox from 'yet-another-react-lightbox';

import('yet-another-react-lightbox/styles.css');

type Props = {
  closeOnClick?: boolean;
  index: number;
  isOpen: boolean;
  onClose: () => void;
  singleImage?: boolean;
  slides: Array<{ src: string }>;
};

const CustomLightbox = ({
  closeOnClick = false,
  index,
  isOpen,
  onClose,
  singleImage = false,
  slides,
}: Props) => {
  const handleClose = () => {
    if (closeOnClick) {
      onClose();
    }
  };

  if (isOpen) {
    return (
      <div
        onClick={handleClose}
        onKeyDown={handleClose}
        role="button"
        tabIndex={0}
      >
        <Lightbox
          carousel={{
            finite: singleImage,
          }}
          close={onClose}
          index={index}
          open={isOpen}
          render={
            singleImage
              ? {
                  buttonNext: () => null,
                  buttonPrev: () => null,
                }
              : {}
          }
          slides={slides}
        />
      </div>
    );
  }

  return <></>;
};

export default CustomLightbox;
