import { useWindowSize } from 'react-use';
import Tooltip from '../../../../common/Tooltip';
import ThumbUpIcon from '../../../../../assets/icons/ThumbUpIcon';
import BigStarRating from '../../../../common/BigStarRating';
import { getPriceCategory } from '../../../../../utils';
import DollarRatings from '../../../../common/DollarRatings';
import { PriceCategory } from '../../../../../@types/cateringCityDetails.type';
import { DietT } from '../../../../../@types/menu.type';

type Props = {
  averageRatePercentile: DietT['averageRate'] | undefined;
  numberOfRates: DietT['reviewsNumber'] | undefined;
  priceCategory?: PriceCategory;
  starRating: DietT['averageFeedback'] | undefined;
};

const DietDescriptionAndMenuModalRating = ({
  averageRatePercentile,
  numberOfRates,
  priceCategory,
  starRating,
}: Props) => {
  const { width } = useWindowSize();

  return (
    <div className="catering-header__wrapper">
      <div className="catering-header__dollar-container">
        <div className="hidden-down-lg">
          <Tooltip
            containerClass="text-align-center"
            placementTooltip="top"
            tooltipS
            tooltipText={
              <div className="white-space-nowrap">
                {getPriceCategory(priceCategory)}
              </div>
            }
          >
            <DollarRatings priceCategory={priceCategory} />
          </Tooltip>
        </div>
      </div>
      <div className="catering-header__wrapper-box cursor-pointer dis">
        <Tooltip
          containerClass="text-align-center"
          placementTooltip={
            width < 430 ? 'bottom-end' : width < 768 ? 'right' : 'top'
          }
          tooltipL={width > 600}
          tooltipM={width > 500}
          tooltipText={
            width < 580 ? (
              <div className="white-space-nowrap">
                Procent pozytywnie
                <br />
                ocenionych posiłków w Dietly.
                <br />
                Ocena generalna cateringu,
                <br />
                oraz liczba opinii.
              </div>
            ) : (
              <div className="white-space-nowrap">
                Procent pozytywnie ocenionych posiłków w Dietly.
                <br />
                Ocena generalna cateringu, oraz liczba opinii.
              </div>
            )
          }
          tooltipXxs={width > 400}
        >
          <div className="display-flex">
            <div className="catering-offer__diet-opinion spacer-right-12">
              <div className="catering-offer__diet-opinion-wrapper">
                <ThumbUpIcon height="16" width="16" />
              </div>
              <span className="color-white label-m">
                {averageRatePercentile?.toFixed()}%
              </span>
            </div>
            <BigStarRating
              extendedNumberOfRatesDescription
              numberOfRates={numberOfRates}
              rate={starRating}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default DietDescriptionAndMenuModalRating;
