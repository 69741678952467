import { Field } from 'formik';
import React, { ChangeEvent } from 'react';
import { useAppDispatch } from '../../../../../config/hooks';
import { changeClientInput } from '../../../../../redux/actions/summaryActions';
import BoxStep from '../../../../common/BoxStep';
import Checkbox from '../../../../common/checkbox';
import { Input } from '../../../../common/Input';
import styles from '../../styles/phoneNumber.module.scss';
import PrefixPhoneNumber from './PrefixPhoneNumber';

type Props = {
  errors: any;
  handleChange: any;
  loginEnabled: boolean;
  methodOfPurchase: string;
  newClientDetails: any;
  setSelectedPrefix: (prefix: string) => void;
  touched: any;
};

const CustomerData = ({
  errors,
  handleChange,
  loginEnabled,
  methodOfPurchase,
  newClientDetails,
  setSelectedPrefix,
  touched,
}: Props) => {
  const dispatch = useAppDispatch();

  const {
    email,
    name,
    password,
    passwordConfirmation,
    phone,
    surname,
    willingToRegister,
  } = newClientDetails || {};

  return (
    <BoxStep title="Dane klienta">
      <div className="steps-page-third__wrapper spacer-top-40">
        <Field
          component={Input}
          containerClass="spacer-bottom-24"
          data-lpignore="true"
          errorDown
          errorMsg={touched['name'] && errors['name']}
          id="name"
          label="Imię"
          name="name"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleChange(e);
            const {
              target: { value },
            } = e;

            dispatch(changeClientInput(e.target.name, value));
          }}
          required
          value={name}
        />
        <Field
          component={Input}
          containerClass="spacer-bottom-24"
          data-lpignore="true"
          errorDown
          errorMsg={touched['surname'] && errors.surname}
          id="surname"
          label="Nazwisko"
          name="surname"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleChange(e);
            const {
              target: { value },
            } = e;
            dispatch(changeClientInput(e.target.name, value));
          }}
          required
          value={surname}
        />
        <Field
          component={Input}
          containerClass="spacer-bottom-24"
          data-lpignore="true"
          errorDown
          errorMsg={touched['email'] && errors.email}
          id="email"
          label="Email"
          name="email"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleChange(e);
            const {
              target: { value },
            } = e;
            dispatch(changeClientInput(e.target.name, value));
          }}
          required
          value={email}
        />
        <div className={styles['phone-wrapper']}>
          <label
            className={`label-m ${
              touched['phone'] && 'phone' in errors
                ? 'color-error'
                : 'color-gray-500'
            }`}
            htmlFor="phone"
          >
            Telefon <span className="color-error">*</span>
          </label>
          <div className={styles['phone-container']}>
            <PrefixPhoneNumber
              onSelect={selectedItem => setSelectedPrefix(selectedItem.prefix)}
            />
            {/*@ts-ignore*/}
            <Input
              containerClass={styles['phone-input']}
              data-lpignore="true"
              errorDown
              errorMsg={touched['phone'] && errors.phone}
              id="phone"
              maskCharacter={''}
              masked
              maskPattern="999999999999"
              name="phone"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleChange(e);
                const {
                  target: { value },
                } = e;
                dispatch(changeClientInput(e.target.name, value));
              }}
              required
              value={phone}
            />
          </div>
        </div>
      </div>
      {methodOfPurchase === 'guest' ? (
        <>
          {loginEnabled && (
            <>
              <Checkbox
                checked={willingToRegister}
                id="willingToRegister"
                label={
                  <div className="display-flex align-items-center font-weight-500">
                    Chcę założyć konto
                    <span className="steps-page-third__label">Polecane</span>
                  </div>
                }
                name="willingToRegister"
                onChange={e => {
                  handleChange(e);
                  const {
                    target: { checked },
                  } = e;
                  dispatch(changeClientInput(e.target.name, checked));
                }}
              />
              <p className="body-m max-width-380 spacer-left-28 spacer-top-4">
                Uzyskasz możliwość edycji swojego zamówienia, adresów dostawy
                oraz podglądu i oceny posiłków.
              </p>
            </>
          )}
          {willingToRegister && (
            <div className="steps-page-third__wrapper spacer-top-24">
              <Field
                component={Input}
                containerClass="spacer-bottom-24"
                data-lpignore="true"
                errorDown
                errorMsg={touched['password'] && errors.password}
                id="password"
                label="Hasło"
                name="password"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleChange(e);
                  const {
                    target: { value },
                  } = e;
                  dispatch(changeClientInput(e.target.name, value));
                }}
                passwordInput
                required
                type="password"
                value={password}
              />
              <Field
                component={Input}
                containerClass="spacer-bottom-24"
                data-lpignore="true"
                errorDown
                errorMsg={
                  touched['passwordConfirmation'] && errors.passwordConfirmation
                }
                id="passwordConfirmation"
                label="Powtórz hasło"
                name="passwordConfirmation"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleChange(e);
                  const {
                    target: { value },
                  } = e;
                  dispatch(changeClientInput(e.target.name, value));
                }}
                passwordInput
                required
                type="password"
                value={passwordConfirmation}
              />
            </div>
          )}
        </>
      ) : (
        <div className="steps-page-third__wrapper">
          <Field
            component={Input}
            containerClass="spacer-bottom-24"
            data-lpignore="true"
            errorDown
            errorMsg={touched['password'] && errors.password}
            id="password"
            label="Hasło"
            name="password"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleChange(e);
              const {
                target: { value },
              } = e;
              dispatch(changeClientInput(e.target.name, value));
            }}
            passwordInput
            required
            type="password"
            value={password}
          />
          <Field
            component={Input}
            containerClass="spacer-bottom-24"
            data-lpignore="true"
            errorDown
            errorMsg={
              touched['passwordConfirmation'] && errors.passwordConfirmation
            }
            id="passwordConfirmation"
            label="Powtórz hasło"
            name="passwordConfirmation"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleChange(e);
              const {
                target: { value },
              } = e;
              dispatch(changeClientInput(e.target.name, value));
            }}
            passwordInput
            required
            type="password"
            value={passwordConfirmation}
          />
        </div>
      )}
    </BoxStep>
  );
};

export default CustomerData;
