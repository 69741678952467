import { useMemo, useState } from 'react';
import en from 'react-phone-number-input/locale/en.json';
import pl from 'react-phone-number-input/locale/pl.json';
import ua from 'react-phone-number-input/locale/ua.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import { countriesToShow } from '../../utils/summaryHelpers.utils';
import { useCombobox } from 'downshift';
import styles from '../../styles/phoneNumber.module.scss';
import { motion } from 'framer-motion';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { changeClientInput } from '../../../../../redux/actions/summaryActions';
import { useEffectOnce } from 'react-use';
import { getNewClientDetails } from '../../../../../redux/selectors/summaryReducer.selectors';
import { initialPhoneNumberValues } from '../../utils/prefix.utils';
import { PrefixT } from '../../../../../features/orderForm/stepSummary/types/stepSummary.type';

type Props = {
  containerClass?: string;
  onSelect?: (k: PrefixT) => void;
};

const PrefixPhoneNumber = ({ containerClass, onSelect }: Props) => {
  const dispatch = useAppDispatch();
  const locale = navigator.language || 'pl';

  const labelsLanguage = useMemo(() => {
    if (locale === 'en' || locale === 'en-US') {
      return en;
    }
    if (locale === 'ua') {
      return ua;
    }
    return pl;
  }, [locale]);

  const allLabels: { [k: string]: string } = useMemo(
    () =>
      getCountries().reduce(
        (acc, curr) => ({ ...acc, [curr]: getCountryCallingCode(curr) }),
        {}
      ),
    []
  );

  const countries = useMemo(
    () =>
      countriesToShow
        .map(country => ({
          short: country,
          prefix: allLabels[country] as string,
          //@ts-expect-error
          name: labelsLanguage[country] as string,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [allLabels, labelsLanguage]
  );

  const [inputItems, setInputItems] = useState(countries);

  const initialSelectedItem = {
    name: pl['PL'],
    short: 'PL',
    prefix: initialPhoneNumberValues.phoneNumberPrefix,
  };

  const newClientDetails = useAppSelector(getNewClientDetails);

  useEffectOnce(() => {
    if (
      newClientDetails?.phoneNumberPrefix !==
      initialPhoneNumberValues.phoneNumberPrefix
    ) {
      dispatch(
        changeClientInput('phoneNumberPrefix', initialSelectedItem.prefix)
      );
    }
  });

  const {
    getInputProps,
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    inputValue,
    isOpen,
    setInputValue,
  } = useCombobox({
    id: 'prefix-phone-number',
    initialSelectedItem,
    items: inputItems,
    itemToString: (item: PrefixT | null) => (item ? `+${item.prefix}` : ''),

    onInputValueChange: ({ inputValue }) => {
      if (!inputValue || inputValue === '+') {
        setInputItems(countries);
        return;
      }
      const inputValueWithoutPlus = inputValue.replace('+', '');
      const newItems = countries.filter(
        country =>
          country.name
            .toLowerCase()
            .includes(inputValueWithoutPlus.toLowerCase()) ||
          country.prefix.includes(String(inputValueWithoutPlus))
      );
      setInputItems(newItems);
    },
    onIsOpenChange: ({ isOpen, selectedItem: newItem }) => {
      if (!isOpen) {
        setInputValue(`+${newItem?.prefix}`);
        if (typeof window !== 'undefined') {
          document.getElementById('prefix-phone-number-input')?.blur();
        }
      }
    },
    onSelectedItemChange: ({ selectedItem: newItem }) => {
      if (newItem) {
        dispatch(changeClientInput('phoneNumberPrefix', newItem?.prefix));
        onSelect && onSelect(newItem);
      }
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
          return {
            ...changes,
            isOpen: false,
          };
        default:
          return changes;
      }
    },
  });

  const inputProps = {
    autoComplete: 'off',
    // onBlur: () => {},
    placeholder: 'Prefiks',
    value: inputValue || '',
    maxLength: 255,
    id: 'prefix-phone-number-input',
    name: 'prefix-phone-number-input',
    type: 'text',
  };

  //ANIMATIONS
  const list = {
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  };
  const variants = {
    visible: (i: number) => ({
      x: 0,
      opacity: 1,
      transition: {
        delay: i * 0.04,
      },
    }),
    hidden: { x: 45, opacity: 0 },
  };

  return (
    <div
      className={`${styles['prefix']}${
        containerClass ? ` ${containerClass}` : ''
      }`}
    >
      <div className={styles['input-container']} {...getToggleButtonProps()}>
        <input className={styles['input']} {...getInputProps(inputProps)} />
      </div>
      <FontAwesomeIcon
        className={`dds-combobox__chevron${
          isOpen ? ` dds-combobox__chevron--rotate` : ''
        }`}
        icon={faChevronDown}
      />
      <ul
        className={`has-scrollbar-y ${
          isOpen ? styles['menu'] : `dds-combobox__menu--closed`
        }`}
        {...getMenuProps()}
      >
        {isOpen && (
          <motion.li
            animate="visible"
            className="dds-combobox__label padding-bottom-4"
            initial="hidden"
            variants={list}
          >
            Wybierz prefiks
          </motion.li>
        )}
        {isOpen &&
          inputItems.map((item: PrefixT, index: number) => (
            <motion.li
              animate="visible"
              className="dds-combobox__item"
              initial="hidden"
              key={item.short}
              variants={variants}
              {...getItemProps({ item, index })}
            >
              {`${item.name} (+${item.prefix})`}
            </motion.li>
          ))}
        {isOpen && !inputItems.length && (
          <motion.li
            animate="visible"
            className="dds-combobox__item dds-combobox__item--none"
            initial="hidden"
            variants={variants}
          >
            Brak wyników
          </motion.li>
        )}
      </ul>
    </div>
  );
};

export default PrefixPhoneNumber;
