// @ts-nocheck
import React, { ForwardedRef, forwardRef } from 'react';
import Modal, { Styles } from 'react-modal';

Modal.setAppElement('body');

type Props = {
  className?: string;
  children: React.ReactNode;
  isOpen: boolean;
  onRequestClose?: () => void;
  overlayClassName?: string;
  style?: Styles;
};

const CustomModal = forwardRef(
  (
    {
      children,
      className,
      isOpen,
      onRequestClose,
      overlayClassName,
      style,
    }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <Modal
        className={className}
        closeTimeoutMS={300}
        isOpen={isOpen}
        onRequestClose={() => onRequestClose && onRequestClose()}
        overlayClassName={overlayClassName}
        style={style}
      >
        <div className="width-100-per height-100-per" ref={ref}>
          {children}
        </div>
      </Modal>
    );
  }
);

export default CustomModal;
