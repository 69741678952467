import styles from '../../styles/SummaryPrices.module.scss';
import SummaryPricesBE from './SummaryPricesBE';
import { parsePrice } from 'utilsRoot/index';
import RecalculatePriceLoader from '../RecalculatePriceLoader';
import useCalculateCartPrice from '../../../../../services/hook/calculatePrices/useCalculateCartPrice.hook';

const SummaryPrices = () => {
  const { isError, isFetching, prices } = useCalculateCartPrice();

  const totalLowest30DaysCostWithoutDiscounts =
    prices?.cart.totalLowest30DaysCostWithoutDiscounts || 0;
  const totalCostWithoutDiscounts = prices?.cart.totalCostWithoutDiscounts || 0;

  const lowestPercentage = (
    (totalLowest30DaysCostWithoutDiscounts * 100) / totalCostWithoutDiscounts -
    100
  )?.toFixed(0);

  return (
    <div className={styles['container']}>
      <div className={styles['prices']}>
        <RecalculatePriceLoader isFetching={isFetching || isError} />

        {prices && <SummaryPricesBE prices={prices.cart} />}

        {!!totalLowest30DaysCostWithoutDiscounts &&
          totalLowest30DaysCostWithoutDiscounts > 0 && (
            <div className="body-s text-align-right spacer-bottom-16">
              Najniższa oferowana cena w ciągu ostatnich 30 dni:{' '}
              {parsePrice(totalLowest30DaysCostWithoutDiscounts)} zł{' '}
              {Number(lowestPercentage) < 0 && (
                <span className="color-success">{lowestPercentage}%</span>
              )}
            </div>
          )}
      </div>
    </div>
  );
};

export default SummaryPrices;
