import s from '../styles/RatesExplanation.module.scss';
import Button from '../../../components/common/Button';
import { Dispatch, SetStateAction } from 'react';

type Props = {
  setIsRatesExplanationVisible: Dispatch<SetStateAction<boolean>>;
};

const RatesExplanation = ({ setIsRatesExplanationVisible }: Props) => {
  return (
    <div className={s['modal']}>
      <div className={s['container']}>
        <p className={s['title']}>Jak działają opinie?</p>
        <p className={s['description--first']}>
          Tylko zarejestrowani klienci, którzy kupili ten produkt, mogą
          wystawiać oceny i recenzje.
        </p>
        <p className={s['description']}>
          Ocena podawana w gwiazdkach (do 5) jest średnią wszystkich ocen.
          Klienci mogą również pozostawić pisemną recenzję. Po moderacji
          publikujemy zarówno pozytywne, jak i negatywne recenzje.
        </p>
        <p className={`spacer-top-24 ${s['description']}`}>
          Szczegółowe informacje na temat zasad wystawiania ocen oraz opinii są
          dostępne
          <a
            className={s['link']}
            href={
              'https://ml-assets.com/docs/terms-of-use/zasadywystawianiaoceniopiniiorazwyliczaniasredniejocenycateringunadietly-bdd0.pdf'
            }
            rel="noreferrer noopener nofollow"
            target="_blank"
          >
            tutaj
          </a>
        </p>
      </div>
      <div className={s['bottom']}>
        <Button
          colorPrimary
          containerClass={s['close-button']}
          label="OK"
          onClick={() => setIsRatesExplanationVisible(false)}
        />
      </div>
    </div>
  );
};

export default RatesExplanation;
