import * as actionTypes from '../actions/actionTypes';
import { initialPhoneNumberValues } from '../../components/pages/StepSummary/utils/prefix.utils';

const initialState = {
  newClientDetails: {
    name: '',
    surname: '',
    email: '',
    phone: '',
    phoneNumberPrefix: initialPhoneNumberValues.phoneNumberPrefix,
    company: false,
    taxId: '',
    companyName: '',
    willingToRegister: false,
    password: '',
    passwordConfirmation: '',
    companyStreet: '',
    companyApartmentNumber: '',
    companyBuildingNumber: '',
    companyCity: '',
    companyZipCode: '',
  },
  newAddressDetails: {
    city: '',
    zipCode: '',
    street: '',
    buildingNumber: '',
    apartmentNumber: '',
    gate: '',
    floor: '',
    gateCode: '',
    note: '',
    deliveryHour: '',
  },
  methodOfPurchase: '',
  typOfPayment: '',
  isClientLoggedIn: '',
  deliveryMethod: '',
  selectedProfileAddress: 0,
  paymentType: '',
  discountForAdditionalSets: 0,
  finalSum: 0,
  finalAmountToPay: 0,
  promoCodeDiscount: 0,
  marketingCatering: false,
  allowCateringEmailMessages: false,
  allowCateringSMSMessages: false,
  regulationsCatering: false,
  paymentUrl: '',
  pickupPointId: '',
  pickupPointDiscount: null,
  originId: '',
};

export const summaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_ZIPCODE:
      return {
        ...state,
        newAddressDetails: {
          ...state.newAddressDetails,
          zipCode: '',
        },
      };
    case actionTypes.SELECT_CITY_OR_ZIPCODE:
      return {
        ...state,
        newAddressDetails: {
          ...state.newAddressDetails,
          city:
            action.optionType === 'city'
              ? action.option
              : state.newAddressDetails.city,
          zipCode:
            action.optionType === 'zipCode'
              ? action.option
              : state.newAddressDetails.zipCode,
        },
      };
    case actionTypes.SELECT_ORIGIN:
      return {
        ...state,
        originId: action.origin,
      };
    case actionTypes.STORE_PAYMENT_URL:
      return {
        ...state,
        paymentUrl: action.paymentUrl,
      };
    case actionTypes.RESET_PAYMENT_URL:
      return {
        ...state,
        paymentUrl: '',
      };
    case actionTypes.AGREEMENTS_CHECKBOX_TOGGLE:
      return {
        ...state,
        [action.field]: action.checked,
      };
    case actionTypes.ALL_AGREEMENTS_CHECKBOXES_TOGGLE:
      const changedFields = action.fields.reduce((acc, curr) => {
        return {
          ...acc,
          [curr]: action.payload,
        };
      }, {});
      return {
        ...state,
        ...changedFields,
      };
    case actionTypes.STORE_FINAL_PRICES_AND_DISCOUNTS:
      return {
        ...state,
        discountForAdditionalSets: action.object.discountForAdditionalSets,
        finalSum: action.object.finalSum,
        finalAmountToPay: action.object.finalAmountToPay,
        promoCodeDiscount: action.object.promoCodeDiscount,
      };
    case actionTypes.SELECT_DELIVERY_HOURS:
      return {
        ...state,
        newAddressDetails: {
          ...state.newAddressDetails,
          deliveryHour: action.id,
        },
      };
    case actionTypes.CHANGE_CLIENT_DETAILS_INPUT:
      return {
        ...state,
        newClientDetails: {
          ...state.newClientDetails,
          [action.field]: action.value,
        },
      };
    case actionTypes.CHANGE_ADDRESS_INPUT:
      return {
        ...state,
        newAddressDetails: {
          ...state.newAddressDetails,
          [action.field]: action.value,
        },
      };
    case actionTypes.CHOOSE_ADDRESS:
      return {
        ...state,
        selectedProfileAddress: action.addressId,
      };
    case actionTypes.CHOOSE_PICKUP_POINT_ID:
      return {
        ...state,
        pickupPointId: action.pickupPointId,
        pickupPointDiscount: action.discount,
      };
    case actionTypes.CHANGE_DELIVERY_METHOD:
      return {
        ...state,
        deliveryMethod: action.value,
      };
    case actionTypes.CHANGE_METHOD_OF_PURCHASE:
      return {
        ...state,
        methodOfPurchase: action.method,
        newClientDetails: {
          ...state.newClientDetails,
          willingToRegister:
            action.method === 'register'
              ? true
              : state.newClientDetails.willingToRegister,
        },
      };
    case actionTypes.CHANGE_PAYMENT_TYPE:
      return {
        ...state,
        paymentType: action.value,
      };
    case actionTypes.RESET_SUMMARY:
      return initialState;
    default:
      return state;
  }
};
