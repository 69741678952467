import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import { clearBasketLocalStorage, parseOrderPeriod } from '../../utils';
import { storeBasket } from '@redux/actions/basketActions';
import { Link } from 'react-router-dom';
import { selectCityOrZipcode } from '@redux/actions/summaryActions';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { getBasket } from '@redux/selectors/basketSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/pro-solid-svg-icons';
import { useAppSelector } from '@config/hooks';
import { resetOneOffs } from '@features/orderForm/stepShoppingCart/redux/oneOffSideOrders.slice';

const CheckBasketModal = ({
  daysParams,
  isBasketModalOpen,
  setIsBasketModalOpen,
}) => {
  const dispatch = useDispatch();
  const basket = useAppSelector(getBasket);

  return (
    <Modal
      className="modal-basket-check"
      isOpen={isBasketModalOpen}
      overlayClassName="overlay-form-fixed"
    >
      <FontAwesomeIcon
        className="font-size-24 spacer-bottom-20 color-primary"
        icon={faShoppingCart}
      />
      <div className="h400 text-align-center spacer-bottom-20">
        Hej, wygląda, że Twój koszyk nie jest pusty
      </div>
      <div className="spacer-bottom-20">
        {basket?.map(item => {
          const {
            orderId,
            selectedCalories: { calories } = {},
            selectedDays,
            selectedDiet: { dietName } = {},
            selectedOption: { dietOptionName } = {},
            sets,
          } = item || {};

          return (
            <div className="modal-basket-check__item" key={orderId}>
              {dietName}, {dietOptionName}, {calories} kcal{' '}
              <div className="modal-basket-check__date">
                {parseOrderPeriod(selectedDays)}
              </div>{' '}
              x{sets}
            </div>
          );
        })}
      </div>
      <div className="display-flex justify-content-center spacer-bottom-20">
        <Button
          label="Dodaj kolejna diete"
          onClick={() => setIsBasketModalOpen(false)}
          sizeMedium
        />
      </div>
      <div className="modal-basket-check__buttons">
        <Button
          colorBlank
          containerClass="spacer-bottom-20"
          label="Wyczyść koszyk"
          onClick={() => {
            setIsBasketModalOpen(false);
            clearBasketLocalStorage();
            dispatch(storeBasket([]));
            dispatch(resetOneOffs());
          }}
          sizeMedium
        />
        <Link to="/koszyk">
          <Button
            label="Przejdź do koszyka"
            onClick={() => {
              if (daysParams) {
                const storageSelectedCity =
                  localStorage.getItem('selected-city-form');

                dispatch(
                  selectCityOrZipcode(JSON.parse(storageSelectedCity), 'city')
                );
              }
            }}
            sizeMedium
          />
        </Link>
      </div>
    </Modal>
  );
};

CheckBasketModal.propTypes = {
  daysParams: PropTypes.string,
  isBasketModalOpen: PropTypes.bool,
  setIsBasketModalOpen: PropTypes.func,
};

export default CheckBasketModal;
