import { useState } from 'react';

import {
  getFormattedDate,
  getWeekdayNameLong,
} from '@adapters/dates/dates.adapter';
import styles from '@features/orderForm/stepMenuConfiguration/styles/MenuConfSideOrders.module.scss';
import {
  amountOfSelectedSideOrders,
  amountOfSelectedSideOrdersLimitedByMaximumSelectedMeals,
} from '@features/orderForm/stepMenuConfiguration/utils/menuConf.utils';
import MenuConfSideOrder from '@features/orderForm/stepMenuConfiguration/views/MenuConfSideOrder';
import { getSelectedSideOrders } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.selector';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useGetSideOrdersWithCoursesQuery } from '@services/api/form/open/possibleSideOrders/possibleSideOrders.api';
import { useAppSelector } from '@config/hooks';
import { capitalizeFirstLetter } from '@salesManago/helpers';
import Button from '@components/common/Button';
import { parseKeyFromSideOrderId } from '@features/coursesAsSideOrders/utils/coursesAsSideOrders.util';
import {
  getMenuConfActiveDay,
  getMenuConfSelectedMealsAmountPerDay,
} from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.selector';
import { getIsKuchniaVikinga } from '@features/general/generalSlice';
import { checkIfSideOrderAvailableInSelectedDatesRange } from '@features/coursesAsSideOrders/utils/sideOrders.util';

type SwiperElement = {
  slideNext: () => void;
  slidePrev: () => void;
};

type Props = {
  companyId: string;
  maxMealsNumber: number;
};

const MenuConfSideOrders = ({ companyId, maxMealsNumber }: Props) => {
  const activeDay = useAppSelector(getMenuConfActiveDay);
  const selectedSideOrders = useAppSelector(getSelectedSideOrders);
  const isKuchniaVikinga = useAppSelector(getIsKuchniaVikinga);

  const sideOrdersAmount = amountOfSelectedSideOrders(
    selectedSideOrders,
    activeDay
  );

  const sideOrdersLimitedByMaximumSelectedMeals =
    amountOfSelectedSideOrdersLimitedByMaximumSelectedMeals(
      selectedSideOrders,
      activeDay
    );

  const selectedMealsAmount = useAppSelector(
    getMenuConfSelectedMealsAmountPerDay
  );

  const [swiper, setSwiper] = useState<SwiperElement | null>(null);

  const goNextSlide = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrevSlide = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const { data: sideOrders } = useGetSideOrdersWithCoursesQuery({ companyId });

  if (!sideOrders || sideOrders?.length === 0) {
    return null;
  }

  return (
    <div className={styles['container']}>
      <div className={styles['top-wrapper']}>
        <h3 className={`h300 ${styles['title']}`}>
          Dodatki
          <span className="hidden-down-sm spacer-left-6">
            na {capitalizeFirstLetter(getWeekdayNameLong(activeDay))} (
            {getFormattedDate(activeDay, 'dd.MM.yyyy')})
          </span>
        </h3>

        {sideOrdersAmount > 0 && (
          <div className={styles['label']}>{sideOrdersAmount}</div>
        )}
      </div>

      <div className={styles['list']}>
        <Button
          colorBlank
          containerClass={`${styles['slider-button']} ${styles['slider-button--left']}`}
          label={<FontAwesomeIcon icon={faArrowLeft} />}
          onClick={goPrevSlide}
        />

        <div
          className={`${styles['slider-overlay']} ${styles['slider-overlay--left']}`}
        />

        <Swiper
          breakpoints={{
            0: {
              slidesPerView: 'auto',
              spaceBetween: 16,
            },
          }}
          className="overflow-inherit"
          longSwipesRatio={0.2}
          onSwiper={setSwiper}
          threshold={20}
        >
          {sideOrders.map(sideOrder => {
            const { sideOrderId } = sideOrder || {};
            const sideOrderKey = parseKeyFromSideOrderId(sideOrderId);

            const multiplier =
              selectedSideOrders[activeDay]?.[sideOrderKey]?.multiplier || 0;

            const selectedMealsAndSideOrders =
              selectedMealsAmount + sideOrdersLimitedByMaximumSelectedMeals;

            const selected = multiplier > 0;

            if (isKuchniaVikinga) {
              const isSideOrderIsAvailable =
                checkIfSideOrderAvailableInSelectedDatesRange({
                  days: [activeDay],
                  sideOrderFrom: sideOrder.dateFrom,
                  sideOrderTo: sideOrder.dateTo,
                });
              if (!isSideOrderIsAvailable) {
                return null;
              }
            }
            return (
              <SwiperSlide
                className="height-auto width-auto"
                key={sideOrderKey}
              >
                <MenuConfSideOrder
                  activeDay={activeDay}
                  maxMealsNumber={maxMealsNumber}
                  multiplier={multiplier}
                  selected={selected}
                  selectedMealsAndSideOrders={selectedMealsAndSideOrders}
                  sideOrder={sideOrder}
                  sideOrderKey={sideOrderKey}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>

        <Button
          colorBlank
          containerClass={`${styles['slider-button']} ${styles['slider-button--right']}`}
          icon={<FontAwesomeIcon icon={faArrowRight} />}
          onClick={goNextSlide}
        />

        <div
          className={`${styles['slider-overlay']} ${styles['slider-overlay--right']}`}
        />
      </div>
    </div>
  );
};

export default MenuConfSideOrders;
