import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BoxStep from '../../../common/BoxStep';
import { Link } from 'react-router-dom';
import OrderConfirmationIcon from '../../../../assets/icons/orderConfirmationIcon';
import Button from '../../../common/Button';
import SuccessRegisterConfirmation from './SuccessRegisterConfirmation';
import RegisterConfirmation from './RegisterConfirmation';
import { getServerConfig } from '../../../../features/general/generalSlice';
import YourLoyaltyPoints from './YourLoyaltyPoints';
import { useAppSelector } from '../../../../config/hooks';

type Props = {
  loyaltyProgramEnabled: boolean;
  orderId: string;
  panelUrl: string;
  successPageData: any;
};

const OrderConfirmationOnline = ({
  loyaltyProgramEnabled,
  orderId,
  panelUrl,
  successPageData,
}: Props) => {
  const [sent, setSent] = useState(false);
  const { companyId, email, methodOfPurchase, name } = successPageData;

  const { isCustomPanel, loginEnabled } = useAppSelector(getServerConfig);

  return sent ? (
    <SuccessRegisterConfirmation />
  ) : (
    <>
      <BoxStep>
        <div className="text-align-center">
          <OrderConfirmationIcon />
          <h4 className="h300 spacer-bottom-8 spacer-top-24">
            {methodOfPurchase === 'guest'
              ? 'Zamówienie zostało przyjęte'
              : 'Potwierdzenie transakcji'}
          </h4>
          {methodOfPurchase === 'guest' && (
            <div className="display-flex flex-direction-column">
              <div className="text-align-center">
                <p className="body-l padding-bottom-40">
                  {name}, dziękujemy za złożenie zamówienia.
                  <br />
                  Twój catering jest w trakcje realizacji.
                </p>
              </div>
              {loginEnabled && (
                <RegisterConfirmation
                  loyaltyProgramEnabled={loyaltyProgramEnabled}
                  orderId={orderId}
                  setSent={setSent}
                  successPageData={successPageData}
                />
              )}
            </div>
          )}
          {methodOfPurchase !== 'guest' && (
            <>
              <p className="body-l spacer-auto max-width-500 text-align-center">
                {name}, dziękujemy za złożenie zamówienia.
                {methodOfPurchase === 'login' &&
                  ` Aby sprawdzić i dowolnie zmieniać szczegóły zamówienia wejdź na Panel ${
                    isCustomPanel ? 'Klienta' : 'Dietly'
                  }.`}
                {methodOfPurchase === 'register' &&
                  ` Aby sprawdzić i dowolnie zmieniać szczegóły zamówienia w Panelu ${
                    isCustomPanel ? 'Klienta' : 'Dietly'
                  }, potwierdź swój adres w skrzynce mailowej.`}
              </p>
              {methodOfPurchase === 'register' ? (
                <Link className="spacer-vertical-40 display-block" to="/">
                  <Button
                    colorBlank
                    containerClass="spacer-auto"
                    label="Wróć na stronę główną"
                    sizeMedium
                  />
                </Link>
              ) : (
                <a
                  className="spacer-vertical-40 display-block"
                  href={`${panelUrl}/?company=${companyId}&orderid=${orderId}`}
                >
                  <Button
                    colorBlank
                    containerClass="spacer-auto"
                    label={`Przejdź do Panelu ${
                      isCustomPanel ? 'Klienta' : 'Dietly'
                    }`}
                    sizeMedium
                  />
                </a>
              )}
            </>
          )}
        </div>
        {methodOfPurchase === 'guest' && (
          <div className="display-flex flex-direction-column align-items-center spacer-top-30">
            <p className="body-l max-width-425 text-align-center">
              Na adres {email} wysłaliśmy
              <br />
              wiadomość z potwierdzeniem transakcji.
            </p>
            <Link to="/">
              <Button
                colorBlank
                containerClass="spacer-top-24"
                label="Wróć na stronę główną"
                sizeMedium
              />
            </Link>
          </div>
        )}

        <YourLoyaltyPoints />
      </BoxStep>
    </>
  );
};

OrderConfirmationOnline.propTypes = {
  loyaltyProgramEnabled: PropTypes.bool,
  orderId: PropTypes.string,
  panelUrl: PropTypes.string,
  successPageData: PropTypes.shape({}),
};

export default OrderConfirmationOnline;
