import { DeliveryTimeT } from '@services/api/form/open/formOpenCities.api';
import { Prettify } from 'typesRoot/generics.type';
import { ProfileAddressT } from 'typesRoot/profile/profile.type';

export enum ComboboxSize {
  S = 'S',
  M = 'M',
  L = 'L',
}

export type SupportedCityT = {
  cityId: number;
  name: string;
  sanitizedName: string;
  municipality: string;
  county: string;
  province: string;
  sectorId: number;
  deliveryFee: number;
  deliveryTime: Array<DeliveryTimeT>;
};

export type ProfileAddressWithSupportedCityT = Prettify<
  ProfileAddressT & {
    supportedCity: SupportedCityT;
  }
>;
