import { RootState } from '../../config/hooks';

export const getEnhancedEcommerceSlice = (state: RootState) =>
  state.enhancedEcommerceSlice;

export const getEnhancedEcommerceCurrentPage = (state: RootState) =>
  getEnhancedEcommerceSlice(state).currentPage;
export const getEnhancedEcommerceIsAdblockEnabled = (state: RootState) =>
  getEnhancedEcommerceSlice(state).isAdblockEnabled;
export const getEnhancedEcommercePrevPage = (state: RootState) =>
  getEnhancedEcommerceSlice(state).prevPage;
export const getEnhancedEcommerceUserID = (state: RootState) =>
  getEnhancedEcommerceSlice(state).userID;
