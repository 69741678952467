import React from 'react';

const CoinIcon = ({ className }) => {
  return (
    <svg
      className={className}
      fill="none"
      height="59"
      viewBox="0 0 60 59"
      width="60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M48.1078 7.50976C57.6758 17.0778 56.5678 33.6978 45.6328 44.6328C34.6978 55.5668 18.0778 56.6758 8.50976 47.1078C-1.05824 37.5398 0.0497551 20.9188 10.9848 9.98476C21.9188 -0.950245 38.5398 -2.05824 48.1078 7.50976Z"
        fill="#ECEEF8"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M45.6332 44.6342C35.5192 54.7472 20.5472 56.4472 10.7842 49.0772C11.3832 49.8702 12.0342 50.6332 12.7522 51.3512C22.3202 60.9192 38.9412 59.8112 49.8762 48.8762C60.8102 37.9422 61.9182 21.3212 52.3502 11.7532C51.6322 11.0352 50.8692 10.3842 50.0762 9.78516C57.4462 19.5482 55.7462 34.5202 45.6332 44.6342Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M45.6332 44.6342C35.5192 54.7472 20.5472 56.4472 10.7842 49.0772C11.3832 49.8702 12.0342 50.6332 12.7522 51.3512C22.3202 60.9192 38.9412 59.8112 49.8762 48.8762C60.8102 37.9422 61.9182 21.3212 52.3502 11.7532C51.6322 11.0352 50.8692 10.3842 50.0762 9.78516C57.4462 19.5482 55.7462 34.5202 45.6332 44.6342Z"
        fill="white"
        fillRule="evenodd"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M47.0469 43.2188L51.1909 47.3627L47.0469 43.2188Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M47.0469 43.2188L51.1909 47.3627"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M47.0469 43.2188L51.1909 47.3627L47.0469 43.2188Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M47.0469 43.2188L51.1909 47.3627"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M43.4131 46.6562L47.6561 50.8982L43.4131 46.6562Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M43.4131 46.6562L47.6561 50.8982"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M43.4131 46.6562L47.6561 50.8982L43.4131 46.6562Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M43.4131 46.6562L47.6561 50.8982"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M50.0371 39.1367L54.2791 43.3797L50.0371 39.1367Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M50.0371 39.1367L54.2791 43.3797"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M50.0371 39.1367L54.2791 43.3797L50.0371 39.1367Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M50.0371 39.1367L54.2791 43.3797"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M52.4502 34.4805L56.6942 38.7245L52.4502 34.4805Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M52.4502 34.4805L56.6942 38.7245"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M52.4502 34.4805L56.6942 38.7245L52.4502 34.4805Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M52.4502 34.4805L56.6942 38.7245"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M39.2686 49.583L43.5116 53.825L39.2686 49.583Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M39.2686 49.583L43.5116 53.825"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M39.2686 49.583L43.5116 53.825L39.2686 49.583Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M39.2686 49.583L43.5116 53.825"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M34.4639 51.8477L38.7069 56.0897L34.4639 51.8477Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M34.4639 51.8477L38.7069 56.0897"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M34.4639 51.8477L38.7069 56.0897L34.4639 51.8477Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M34.4639 51.8477L38.7069 56.0897"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M27.5986 53.4688L31.8416 57.7118L27.5986 53.4688Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M27.5986 53.4688L31.8416 57.7118"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M27.5986 53.4688L31.8416 57.7118L27.5986 53.4688Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M27.5986 53.4688L31.8416 57.7118"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M20.3076 53.25L24.5086 57.45L20.3076 53.25Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M20.3076 53.25L24.5086 57.45"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M20.3076 53.25L24.5086 57.45L20.3076 53.25Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M20.3076 53.25L24.5086 57.45"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M54.3066 27.8506L58.5496 32.0936L54.3066 27.8506Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M54.3066 27.8506L58.5496 32.0936"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M54.3066 27.8506L58.5496 32.0936L54.3066 27.8506Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M54.3066 27.8506L58.5496 32.0936"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M54.4478 20.9209L58.6978 25.1709L54.4478 20.9209Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M54.4478 20.9209L58.6978 25.1709"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M54.4478 20.9209L58.6978 25.1709L54.4478 20.9209Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M54.4478 20.9209L58.6978 25.1709"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M43.5279 12.0889C50.8829 19.4439 50.0319 32.2199 41.6259 40.6259C33.2199 49.0309 20.4439 49.8829 13.0889 42.5279C5.73393 35.1729 6.58593 22.3969 14.9919 13.9919C23.3969 5.58593 36.1739 4.73393 43.5279 12.0889Z"
        fillRule="evenodd"
        stroke="#06257F"
        strokeDasharray="1.98 5.95"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M58.4297 54.4297C58.4297 56.0877 56.6407 57.4297 54.4297 57.4297L58.4297 54.4297Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M3.42969 52.4297C3.42969 54.6407 5.21869 56.4297 7.42969 56.4297M57.4297 6.42969C57.4297 3.66569 55.6407 1.42969 53.4297 1.42969M57.4297 45.4297C57.4297 50.9567 52.9567 55.4297 47.4297 55.4297M58.4297 54.4297C58.4297 56.0877 56.6407 57.4297 54.4297 57.4297"
        stroke="#06257F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default CoinIcon;
