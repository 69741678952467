import * as actionTypes from '../../redux/actions/actionTypes';

const initialState = {
  basket: [],
  edition: {
    isEdited: false,
    step: '',
  },
  promoCode: {
    code: '',
    flags: {
      fetching: false,
      error: null,
    },
  },
  additionalFees: {
    selectedFees: [],
    availableFees: [],
  },
  loyaltyPoints: {
    usedLoyaltyPoints: 0,
  },
  pdfFile: {
    file: '',
    hasFile: false,
  },
};

export const basketReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_PROMOCODE: {
      return {
        ...state,
        promoCode: {
          ...state.promoCode,
          code: '',
        },
      };
    }
    case actionTypes.SET_USED_LOYALTY_POINTS: {
      return {
        ...state,
        loyaltyPoints: {
          usedLoyaltyPoints: action.points,
        },
      };
    }
    case actionTypes.CANCEL_LOYALTY_POINTS: {
      return {
        ...state,
        loyaltyPoints: {
          usedLoyaltyPoints: 0,
        },
      };
    }
    case actionTypes.CHANGE_HAS_PDF_FILE: {
      return {
        ...state,
        pdfFile: {
          ...state.pdfFile,
          hasFile: action.boolean,
        },
      };
    }
    case actionTypes.CHANGE_PDF_FILE: {
      return {
        ...state,
        pdfFile: {
          ...state.pdfFile,
          file: action.file,
        },
      };
    }
    case actionTypes.RESET_PDF_FILE: {
      return {
        ...state,
        pdfFile: {
          file: '',
          hasFile: false,
        },
      };
    }
    case actionTypes.RESET_BASKET:
      return {
        ...initialState,
      };
    case actionTypes.STORE_BASKET:
      return {
        ...state,
        basket: action.basket,
      };
    case actionTypes.PROMO_CODE_ERROR_FETCHING:
      return {
        ...state,
        promoCode: {
          ...state.promoCode,
          code: '',
          flags: {
            fetching: false,
            error: action.error,
          },
        },
      };
    case actionTypes.PROMO_CODE_ERROR_RESET:
      return {
        ...state,
        promoCode: {
          ...state.promoCode,
          flags: {
            fetching: false,
            error: null,
          },
        },
      };
    case actionTypes.STORE_PROMO_CODE:
      return {
        ...state,
        promoCode: {
          ...state.promoCode,
          code: action.promoCode,
          flags: {
            fetching: false,
            error: null,
          },
        },
      };
    case actionTypes.UPDATE_ITEM:
      return {
        ...state,
        basket: state.basket.map(item => {
          if (item.orderId === action.id) {
            return {
              ...item,
              ...action.order,
            };
          }
          return item;
        }),
      };
    case actionTypes.RESET_EDITION:
      return {
        ...state,
        edition: {
          isEdited: false,
          step: '',
        },
      };
    case actionTypes.EDIT_ITEM_IN_BASKET:
      return {
        ...state,
        edition: {
          isEdited: action.id,
          step: action.step,
        },
      };
    case actionTypes.CHANGE_SETS_IN_BASKET:
      return {
        ...state,
        basket: state.basket.map(item => {
          if (item.orderId === action.id) {
            return {
              ...item,
              sets: action.sets,
            };
          }
          return item;
        }),
      };
    case actionTypes.DELETE_ITEM:
      return {
        ...state,
        basket: state.basket.filter(item => item.orderId !== action.id),
      };
    case actionTypes.ADD_TO_BASKET:
      return {
        ...state,
        basket: state.basket.concat(action.order),
      };
    default:
      return state;
  }
};
