import MenuNotPublishedYet from '@features/orderForm/stepMenuConfiguration/views/MenuNotPublishedYet';
import { useAppSelector } from '@config/hooks';
import { getMenuConfActiveDay } from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.selector';
import BoxStep from '@components/common/BoxStep';
import { prepareMenuConfTitle } from '@features/orderForm/stepMenuConfiguration/utils/menuConf.utils';

const MenuConfigurationError = () => {
  const activeDay = useAppSelector(getMenuConfActiveDay);
  return (
    <BoxStep
      containerClass="steps-page__box-step-borders"
      mealExchangePage
      noBottomMargin
      overflowHidden
      title={
        activeDay ? `Konfiguruj menu na ${prepareMenuConfTitle(activeDay)}` : ''
      }
    >
      <MenuNotPublishedYet />
    </BoxStep>
  );
};

export default MenuConfigurationError;
