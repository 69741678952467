import axios from 'axios';
import { getTimeStamp } from '../helpers';

const requestData = (data, salesManago) => ({
  ...data,
  ...salesManago,
  requestTime: getTimeStamp(),
});

const getSalesManagoEndpoint = clientId => {
  if (clientId === '5nq0liansu9jzelz' || clientId === '75436203184569c4') {
    return 'salesManago';
  }

  if (
    clientId === 'e91162955114bc0a' ||
    clientId === '9a6fcb8fbb206d42' ||
    clientId === '840a88965590f7e7' ||
    clientId === '9c03d62af9383ed1' ||
    clientId === '75436203184569c4'
  ) {
    return 'salesManago2';
  }

  return 'salesManago3';
};

export const salesManagoApiAddContactExtEvent = (
  data,
  serverUrl,
  salesManago
) =>
  axios.post(
    `${serverUrl}/${getSalesManagoEndpoint(
      salesManago?.clientId
    )}/v2/contact/addContactExtEvent`,
    requestData(data, salesManago)
  );

export const salesManagoApiUpdateContactExtEvent = (
  data,
  serverUrl,
  salesManago
) =>
  axios.post(
    `${serverUrl}/${getSalesManagoEndpoint(
      salesManago?.clientId
    )}/v2/contact/updateContactExtEvent`,
    requestData(data, salesManago)
  );

export const salesManagoApiNewOModifyingExistingContact = (
  data,
  serverUrl,
  salesManago
) =>
  axios.post(
    `${serverUrl}/${getSalesManagoEndpoint(
      salesManago?.clientId
    )}/contact/upsert`,
    requestData(data, salesManago)
  );
