import { createSlice } from '@reduxjs/toolkit';

type initialStateT = {
  isAdblockEnabled: string;
  prevPage: string;
  currentPage: string;
  userID: string;
};

const initialCurrentPage = window.location.hash.split('#')[1] || 'asd';

const initialState: initialStateT = {
  isAdblockEnabled: 'idle',
  prevPage: '',
  currentPage: initialCurrentPage,
  userID: '',
};

const enhancedEcommerceSlice = createSlice({
  name: 'enhancedEcommerceSlice',
  initialState,
  reducers: {
    setIsAdblockEnabled: (state, action) => {
      state.isAdblockEnabled = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPrevPage: (state, action) => {
      state.prevPage = action.payload;
    },
    setUserId: (state, action) => {
      state.userID = action.payload;
    },
  },
});
export const { setCurrentPage, setIsAdblockEnabled, setPrevPage, setUserId } =
  enhancedEcommerceSlice.actions;

export default enhancedEcommerceSlice.reducer;
