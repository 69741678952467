import React from 'react';
import { BurakProgramSmallIcon } from '@assets/icons/BurakCoin/BurakProgramSmallIcon';
import CoinIcon from '../../../../assets/icons/coinIcons';
import { useAppSelector } from '@config/hooks';
import { useGetApiParamsQuery } from '@services/api/form/open/open.api';
import {
  getIsCustomLoyaltyProgram,
  getServerConfig,
} from '../../../general/generalSlice';
import { numberWithSpaces } from 'utilsRoot/index';
import {
  burakLoyaltyProgramData,
  defaultLoyaltyProgramData,
} from '@data/loyaltyProgramData';
import useCalculateCartPrice from '@services/hook/calculatePrices/useCalculateCartPrice.hook';

const LoyaltyPointsInfo = () => {
  const { companyId } = useAppSelector(getServerConfig);
  const { data: params } = useGetApiParamsQuery(companyId, {
    skip: !companyId,
  });

  const isCustomLoyaltyProgram = useAppSelector(getIsCustomLoyaltyProgram);

  const { prices } = useCalculateCartPrice();

  const { cart: { totalCostToPay = 0 } = {} } = prices || {};

  const loyaltyProgramData = isCustomLoyaltyProgram
    ? burakLoyaltyProgramData.basket
    : defaultLoyaltyProgramData.basket;
  return (
    <>
      {params?.loyaltyProgramEnabled && (
        <div className="display-flex align-items-center flex-wrap spacer-top-24">
          <div className="spacer-right-16 spacer-bottom-8">
            {isCustomLoyaltyProgram ? (
              BurakProgramSmallIcon
            ) : (
              <CoinIcon className="coin-icon-color" />
            )}
          </div>
          <div className="max-width-510">
            <h4 className="label-m spacer-bottom-8">
              Otrzymasz{' '}
              {!!totalCostToPay
                ? numberWithSpaces(
                    (Math.floor(totalCostToPay / 50) * 50)?.toFixed(0)
                  )
                : 0}{' '}
              {loyaltyProgramData.text1}
            </h4>
            <p className="body-s">{loyaltyProgramData.text2}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default LoyaltyPointsInfo;
