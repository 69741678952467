import { Field, Form, Formik } from 'formik';
import { ChangeEvent, useState } from 'react';
import * as yup from 'yup';
import { validateClientAuthorizationCode } from '../../utils/summaryHelpers.utils';
import { classNamesUtil } from '../../../../../utils/classNamesUtil.util';

type Props = {
  breakAfter: any;
  companyName: string;
  numberOfInputs: number;
  openModal: (b: boolean) => void;
  phoneNumber: string;
  submitForm: () => void;
};

const OtpInput = ({
  breakAfter,
  companyName,
  numberOfInputs,
  openModal,
  phoneNumber,
  submitForm,
}: Props) => {
  const [isInvalidCode, setIsInvalidCode] = useState(false);

  const setInitialValues = () => {
    const initialValues = {};
    const fieldValidation = {};
    for (let i = 0; i < Number(numberOfInputs); i++) {
      // @ts-ignore
      initialValues[`field${i}`] = '';
      // @ts-ignore
      fieldValidation[`field${i}`] = yup.string().required();
    }
    return { initialValues, fieldValidation };
  };

  return (
    <Formik
      initialValues={setInitialValues().initialValues}
      onSubmit={(values, actions) => {
        const authorizationCode = Object.values(values).join('');

        validateClientAuthorizationCode(
          companyName,
          phoneNumber,
          authorizationCode
        )
          .then(res => {
            if (res.status === 200) {
              submitForm();
              openModal(false);
            }
          })
          .catch(err => {
            setIsInvalidCode(true);
            console.log(err);
          });
        actions.resetForm();
      }}
      validationSchema={yup
        .object()
        .shape({ ...setInitialValues().fieldValidation })}
    >
      {({ values }) => (
        <div className="otp-input">
          <Form
            className={`otp-input__form ${
              isInvalidCode ? 'spacer-bottom-8' : 'spacer-bottom-32'
            }`}
            id="otp"
          >
            {Object.keys(setInitialValues().initialValues).map(
              (field, index) => (
                <>
                  <Field
                    className={classNamesUtil('otp-input__input', {
                      'otp-input__input--error': isInvalidCode,
                    })}
                    id={field}
                    key={field}
                    maxLength={1}
                    name={field}
                    onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                      setIsInvalidCode(false);
                      e.target.value = '';
                    }}
                    onInput={(e: any) => {
                      e.target.nextSibling && e.target.nextSibling.focus();
                      e.target.nextSibling &&
                        e.target.nextSibling.matches('.break') &&
                        e.target.nextSibling.nextSibling.focus();
                    }}
                    type="text"
                    // @ts-ignore
                    value={values[field]}
                  />
                  {index === Number(breakAfter) - 1 && (
                    <div className="break" />
                  )}
                </>
              )
            )}
          </Form>
          {isInvalidCode && (
            <p className="body-m otp-error-message">
              Podany kod jest nieprawidłowy. Sprawdź otrzymaną wiadomość i
              spróbuj jeszcze raz.
            </p>
          )}
        </div>
      )}
    </Formik>
  );
};

export default OtpInput;
