import * as actionTypes from '../../redux/actions/actionTypes';

const initialState = {
  companyId: '',
  companyDietDetails: [],
  companyDietCaloriesDetails: [],
};

export const orderFormDietDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STORE_DETAILS:
      return {
        ...state,
        [action.prop]: action.value,
      };
    default:
      return state;
  }
};
