import React from 'react';
import PropTypes from 'prop-types';
import { classNamesUtil } from '../../utils/classNamesUtil.util';

export const Textarea = ({
  containerClass,
  disabled,
  error,
  id,
  inputClass,
  label,
  labelLink,
  maxLength,
  name,
  onKeyPress,
  placeholder,
  required,
  value,
  ...restProps
}) => {
  const actionClasses = classNamesUtil('', {
    'has-error': !!error,
    'is-disabled': !!disabled,
    'is-required': !!required,
  });
  return (
    <div
      className={classNamesUtil('', {
        [`${containerClass}`]: !!containerClass,
      })}
    >
      <div
        className={classNamesUtil('form-input', {
          [`${inputClass}`]: !!inputClass,
          [`${actionClasses}`]: !!actionClasses,
        })}
      >
        <div className="input-label">
          <label className={actionClasses} htmlFor={id}>
            {label}
          </label>

          {labelLink && labelLink}
        </div>

        <textarea
          className={actionClasses}
          disabled={disabled}
          id={id}
          name={name}
          onKeyDown={onKeyPress}
          placeholder={placeholder}
          value={value}
          {...restProps}
        />

        {maxLength && (
          <div
            className={classNamesUtil('body-m spacer-top-8', {
              'color-error': String(value).length > Number(maxLength),
            })}
          >
            {String(value).length}/{maxLength}
          </div>
        )}

        {error && <div className="body-m color-error">{error}</div>}
      </div>
    </div>
  );
};

Textarea.defaultProps = {
  containerClass: '',
  disabled: false,
  inputClass: '',
  label: '',
  labelLink: '',
};

Textarea.propTypes = {
  containerClass: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  id: PropTypes.string,
  inputClass: PropTypes.string,
  label: PropTypes.string,
  labelLink: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
