import { formApi } from '@services/api/form';

export const getFormSettings = state =>
  formApi.endpoints.getApiFormSettings.select()(state).data;
export const getOrderSettings = state => getFormSettings(state)?.orderSettings;
export const getTestOrderSettings = state =>
  getFormSettings(state)?.testOrderSettings;
export const getAdditionalTexts = state =>
  getFormSettings(state)?.additionalTexts;
export const getMenuConfigurationCaloriesSwitchEnabled = state =>
  getOrderSettings(state).menuConfigurationCaloriesSwitchEnabled;
export const getCustomStandardDietTexts = state =>
  getAdditionalTexts(state).customStandardDietTexts;
export const getStandardDietTexts = state =>
  getAdditionalTexts(state).standardDietTexts;
export const getCustomMenuConfigurationDietTexts = state =>
  getAdditionalTexts(state).customMenuConfigurationDietTexts;
export const getMenuConfigurationDietTexts = state =>
  getAdditionalTexts(state).menuConfigurationDietTexts;
