import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { selectCityOrZipcode } from '@redux/actions/summaryActions';
import { storeBasket } from '@redux/actions/basketActions';
import { PriceCategory } from 'typesRoot/cateringCityDetails.type';

export const createArray = number => [...Array(number).keys()];

export const getInitialDatesArray = (daysBack = 7, daysAhead = 7) => {
  const today = DateTime.local();
  const daysBackArray = createArray(daysBack + 1).map(day =>
    today.minus({ days: day }).toISODate()
  );
  const daysAheadArray = createArray(daysAhead + 1).map(day =>
    today.plus({ days: day }).toISODate()
  );

  return Array.from(
    new Set([
      ...daysBackArray.reverse(),
      DateTime.local().toISODate(),
      ...daysAheadArray,
    ])
  );
};

export const preventKeyPress = e => {
  const { key } = e;
  if (['e', 'E', '+', '-', ',', '.'].includes(key)) {
    e.preventDefault();
  }
};

export const customSort = key => array => {
  if (!array) {
    return [];
  }
  return [...array]?.sort((prev, next) => prev[key]?.localeCompare(next[key]));
};

export const customSortDiets = () => array => {
  if (!array) {
    return [];
  }
  return [...array]?.sort((prev, next) => {
    if (prev?.priority !== next?.priority) {
      return prev?.priority - next?.priority;
    }
    return prev?.dietName?.localeCompare(next?.dietName);
  });
};

export const customNumSort = key => array => {
  if (!array) {
    return [];
  }
  return [...array]?.sort((prev, next) => prev[key] - next[key]);
};

export const customFind = (key, element) => array =>
  array?.find(item => item[key] === element);

export const customMap = key => array => array?.map(item => item[key]);

export const parseOrderPeriod = dates => {
  const firstDay = findUtmostDates('first', dates);
  const parsedFirstDay = DateTime.fromMillis(firstDay)
    .setLocale('fr')
    .toLocaleString();
  const lastDay = findUtmostDates('last', dates);
  const parsedLastDay = DateTime.fromMillis(lastDay)
    .setLocale('fr')
    .toLocaleString();

  return `${parsedFirstDay} - ${parsedLastDay}`;
};

export const parseOrderDay = (dates, whichDate) => {
  const day = findUtmostDates(whichDate, dates);
  const parsedDay = DateTime.fromMillis(day).setLocale('fr').toLocaleString();

  return `${parsedDay}`;
};

export const findUtmostDates = (firstOrLast, dates) => {
  return (
    dates &&
    dates.reduce((acc, curr) => {
      if (acc > curr) {
        return firstOrLast === 'first' ? curr : acc;
      }
      return firstOrLast === 'first' ? acc : curr;
    })
  );
};

export const clearBasketLocalStorage = () => {
  localStorage.removeItem('diet-basket-form');
  localStorage.removeItem('selected-city-form');
  localStorage.removeItem('additional-fee-form');
};

export const mapMeals = (meals, dietCaloriesId) => {
  if (!meals) {
    return undefined;
  }
  return meals?.map(meal => ({
    ...meal,
    dietCaloriesId,
    selected: true,
  }));
};

export const scrollToRef = ref =>
  window.scroll({ top: ref?.current?.offsetTop - 70, behavior: 'smooth' });

export const checkIfNotAllMealsSelected = selectedMeals => {
  if (!selectedMeals) {
    return false;
  }
  return selectedMeals?.some(meal => !meal.selected);
};

export const displaySelectedMeals = selectedMeals => {
  if (!selectedMeals) {
    return null;
  }
  return customNumSort('mealPriority')([...selectedMeals])
    .filter(meal => meal.selected)
    .map(meal => meal.mealName)
    .join(', ');
};

export const numberWithSpaces = number => {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') ?? 0;
};

export const setSelectedCityInStorage = (city, basket) => {
  if (basket?.length > 0) {
    return localStorage.setItem('selected-city-form', JSON.stringify(city));
  }
  return null;
};

export const parsePrice = (price, toFixed = 2) => {
  if (isNaN(price)) {
    return 0;
  }

  if (typeof price === 'number') {
    return price?.toFixed(toFixed).replace('.', ',');
  }

  return 0;
};

export const toastNotification = (message, status) => {
  const options = {
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
  };

  if (status === 'info') {
    return toast.info(message, options);
  }

  if (status === 'error') {
    return toast.error(message, options);
  }

  return toast.success(message, options);
};

export const findDietTagImg = (dietTag, dietTagsData) =>
  customFind('dietTagId', dietTag)(dietTagsData)?.imageUrl ??
  'https://static.dietly.pl/form/test-image.png';

export const checkIsBasketInLocalStorage = (
  basket,
  addressDetails,
  dispatch
) => {
  if (!basket.length) {
    const basketFromStorage = localStorage.getItem('diet-basket-form');
    const parsedBasket = basketFromStorage && JSON.parse(basketFromStorage);

    const storageSelectedCity = localStorage.getItem('selected-city-form');
    const parsedStorageSelectedCity =
      storageSelectedCity && JSON.parse(storageSelectedCity);

    if (parsedStorageSelectedCity && !addressDetails?.city) {
      dispatch(selectCityOrZipcode(parsedStorageSelectedCity, 'city'));
    }
    if (parsedBasket && parsedBasket.length > 0) {
      dispatch(storeBasket(parsedBasket));
    }
  }
};

export const checkIsParamsValid = (
  companyDietDetails,
  dietParams,
  optionParams,
  caloriesParams
) => {
  const selectedDiet = companyDietDetails?.find(diet => {
    return diet?.dietName?.toLowerCase() === dietParams?.toLowerCase();
  });

  const selectedOption = selectedDiet?.dietOptions?.find(option => {
    return (
      option?.dietOptionName?.toLowerCase() === optionParams?.toLowerCase()
    );
  });

  const selectedCalories = selectedOption?.dietCalories?.find(calories => {
    return calories?.calories === parseInt(caloriesParams, 10);
  });

  return selectedDiet && selectedOption && selectedCalories;
};

function hexToHSL(H, value) {
  let r = 0,
    g = 0,
    b = 0;
  if (H?.length === 4) {
    r = '0x' + H[1] + H[1];
    g = '0x' + H[2] + H[2];
    b = '0x' + H[3] + H[3];
  } else if (H?.length === 7) {
    r = '0x' + H[1] + H[2];
    g = '0x' + H[3] + H[4];
    b = '0x' + H[5] + H[6];
  }
  r /= 255;
  g /= 255;
  b /= 255;
  let cMin = Math.min(r, g, b),
    cMax = Math.max(r, g, b),
    delta = cMax - cMin,
    //eslint-disable-next-line
    h = 0,
    s = 0,
    l = 0;

  if (delta === 0) h = 0;
  else if (cMax === r) h = ((g - b) / delta) % 6;
  else if (cMax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cMax + cMin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return 'hsl(' + h + ',' + s + '%,' + value + '%)';
}

export const replaceStyles = configStyles => {
  const {
    backgroundColor,
    backgroundImage,
    backgroundOpacity,
    buttonPrimaryBackground,
    buttonPrimaryBackgroundHover,
    buttonPrimaryText,
    buttonSecondaryBackground,
    buttonSecondaryText,
    elementsToHide,
    errorColor,
    infoColor,
    logoMaxHeight,
    logoMaxWidth,
    primaryColor,
    primaryColorAccent,
    successColor,
    toastNotificationErrorBackground,
    toastNotificationErrorText,
    toastNotificationInfoBackground,
    toastNotificationInfoText,
    toastNotificationSuccessBackground,
    toastNotificationSuccessText,
  } = configStyles || {};

  if (backgroundColor && backgroundColor !== '#') {
    document.documentElement.style.setProperty(
      '--backgroundColor',
      backgroundColor
    );
  }

  if (backgroundImage) {
    document.documentElement.style.setProperty(
      '--backgroundImage',
      `url(${backgroundImage})`
    );
  }

  if (backgroundOpacity) {
    document.documentElement.style.setProperty(
      '--backgroundOpacity',
      backgroundOpacity
    );
  }

  if (toastNotificationSuccessText && toastNotificationSuccessText !== '#') {
    document.documentElement.style.setProperty(
      '--toastNotificationSuccessText',
      toastNotificationSuccessText
    );
  }

  if (
    toastNotificationSuccessBackground &&
    toastNotificationSuccessBackground !== '#'
  ) {
    document.documentElement.style.setProperty(
      '--toastNotificationSuccessBackground',
      toastNotificationSuccessBackground
    );
  }

  if (primaryColorAccent && primaryColorAccent !== '#') {
    document.documentElement.style.setProperty(
      '--primaryColorAccent',
      primaryColorAccent
    );
    document.documentElement.style.setProperty(
      '--primaryColorAccent85',
      hexToHSL(primaryColorAccent, 85)
    );
  }

  if (toastNotificationInfoText && toastNotificationInfoText !== '#') {
    document.documentElement.style.setProperty(
      '--toastNotificationInfoText',
      toastNotificationInfoText
    );
  }

  if (
    toastNotificationInfoBackground &&
    toastNotificationInfoBackground !== '#'
  ) {
    document.documentElement.style.setProperty(
      '--toastNotificationInfoBackground',
      toastNotificationInfoBackground
    );
  }

  if (toastNotificationErrorText && toastNotificationErrorText !== '#') {
    document.documentElement.style.setProperty(
      '--toastNotificationErrorText',
      toastNotificationErrorText
    );
  }

  if (
    toastNotificationErrorBackground &&
    toastNotificationErrorBackground !== '#'
  ) {
    document.documentElement.style.setProperty(
      '--toastNotificationErrorBackground',
      toastNotificationErrorBackground
    );
  }

  if (buttonPrimaryText && buttonPrimaryText !== '#') {
    document.documentElement.style.setProperty(
      '--buttonPrimaryText',
      buttonPrimaryText
    );
  }

  if (buttonSecondaryBackground && buttonSecondaryBackground !== '#') {
    document.documentElement.style.setProperty(
      '--buttonSecondaryBackground',
      buttonSecondaryBackground
    );
  }

  if (buttonSecondaryText && buttonSecondaryText !== '#') {
    document.documentElement.style.setProperty(
      '--buttonSecondaryText',
      buttonSecondaryText
    );
  }

  if (elementsToHide) {
    document.documentElement.style.setProperty(
      '--elementsToHide',
      elementsToHide
    );
  }
  if (errorColor && errorColor !== '#') {
    document.documentElement.style.setProperty('--errorColor', errorColor);
  }

  if (successColor && successColor !== '#') {
    document.documentElement.style.setProperty('--successColor', successColor);
  }
  if (infoColor && infoColor !== '#') {
    document.documentElement.style.setProperty('--infoColor', infoColor);
  }
  if (logoMaxHeight) {
    document.documentElement.style.setProperty(
      '--logoMaxHeight',
      logoMaxHeight
    );
  }
  if (logoMaxWidth) {
    document.documentElement.style.setProperty('--logoMaxWidth', logoMaxWidth);
  }
  if (primaryColor && primaryColor !== '#') {
    document.documentElement.style.setProperty('--primaryColor', primaryColor);
    document.documentElement.style.setProperty(
      '--primaryColor10',
      hexToHSL(primaryColor, 60)
    );
    document.documentElement.style.setProperty(
      '--primaryColor17',
      hexToHSL(primaryColor, 80)
    );
  }

  if (buttonPrimaryBackground && buttonPrimaryBackground !== '#') {
    document.documentElement.style.setProperty(
      '--buttonPrimaryBackground',
      buttonPrimaryBackground
    );
  }
  if (buttonPrimaryBackgroundHover && buttonPrimaryBackgroundHover !== '#') {
    document.documentElement.style.setProperty(
      '--buttonPrimaryBackgroundHover',
      buttonPrimaryBackgroundHover
    );
  } else {
    document.documentElement.style.setProperty(
      '--buttonPrimaryBackgroundHover',
      hexToHSL(buttonPrimaryBackground, 60)
    );
  }
};

export const checkIsDayInMealExchange = (
  day,
  customDeliveryMeals,
  array = false
) => {
  if (array) {
    return day.some(
      item => DateTime.fromMillis(item).toISODate() || '' in customDeliveryMeals
    );
  }
  return day in customDeliveryMeals;
};

export const getPriceCategory = category => {
  if (category === PriceCategory.Average) {
    return 'Catering standardowy';
  }
  if (category === PriceCategory.Expensive) {
    return 'Catering premium';
  }
  if (category === PriceCategory.Cheap) {
    return 'Catering ekonomiczny';
  }
  return 'Brak możliwości wyceny';
};

export const declination = (number, one, moreThanOne, moreThanFour) => {
  if (number === 1) {
    return `${number} ${one}`;
  }

  if (number > 1 && number < 5) {
    return `${number} ${moreThanOne}`;
  }

  return `${number} ${moreThanFour}`;
};
