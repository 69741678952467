import { useEffectOnce } from 'react-use';

import { nanoid } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';
export const GUEST_COOKIE_NAME = 'GUEST_SESSION';
const useGuestCookie = () => {

  const setInitialCookie = () => {
    const cookies = new Cookies();
    const cookieValue = cookies.get(GUEST_COOKIE_NAME);

    if (typeof window !== 'undefined' && window.performance) {
      const navigation = performance.getEntriesByType(
        'navigation'
      )[0] as PerformanceNavigationTiming;
      if (navigation && navigation.type === 'reload' && cookieValue) {
        cookies.set(GUEST_COOKIE_NAME, cookieValue, {
          path: '/',
          secure: true
        });
      } else {
        cookies.set(GUEST_COOKIE_NAME, nanoid(), {
          path: '/',
          secure: true
        });
      }
    }
  };

  useEffectOnce(() => {
    setInitialCookie();
  });
};

export default useGuestCookie;
