import React from 'react';
import { OrderFormT } from '../../../../@types/orderForm.type';
import { FormSettingsT } from '../../../../@types/settings.type';
import { useAppDispatch } from '../../../../config/hooks';
import {
  selectOrderDays,
  setDefaultDays,
  toggleOrderCheckbox,
} from '../../../../redux/actions/orderFormActions';
import { getTestOrderPrice } from '../../../../utils/orderFormPrices';
import Checkbox from '../../../common/checkbox';

type Props = {
  formSettings?: FormSettingsT;
  orderForm: OrderFormT;
  selectedCity: any;
  selectedProgram: string;
};

const WeekendCheckbox = ({
  formSettings,
  orderForm,
  selectedCity,
  selectedProgram,
}: Props) => {
  const dispatch = useAppDispatch();

  return (
    <div className="display-flex align-items-end flex-wrap">
      {(formSettings?.orderSettings?.deliveryOnSaturday ||
        formSettings?.orderSettings?.deliveryOnSunday) && (
        <div className="spacer-bottom-24">
          <span className="label-m color-gray-500 spacer-bottom-16 display-inline-block">
            Dni weekendowe
          </span>

          <div className="display-flex">
            {formSettings?.orderSettings?.deliveryOnSaturday && (
              <Checkbox
                checked={orderForm?.saturdays}
                containerClass="spacer-right-24"
                disabled={!formSettings?.orderSettings?.deliveryOnSaturday}
                id="saturdays"
                label="Soboty"
                name="saturdays"
                onChange={({ target: { checked, name } }) =>
                  dispatch(toggleOrderCheckbox(name, checked))
                }
              />
            )}

            {formSettings?.orderSettings?.deliveryOnSunday && (
              <Checkbox
                checked={orderForm?.sundays}
                containerClass="spacer-right-24"
                disabled={!formSettings?.orderSettings?.deliveryOnSunday}
                id="sundays"
                label="Niedziele"
                name="sundays"
                onChange={({ target: { checked, name } }) =>
                  dispatch(toggleOrderCheckbox(name, checked))
                }
              />
            )}
          </div>
        </div>
      )}

      {formSettings?.testOrderSettings?.allowTestOrder &&
        getTestOrderPrice(
          orderForm?.selectedDietCaloriesIdPrices,
          selectedCity?.sectorId
        ) &&
        selectedProgram !== 'menuConfiguration' && (
          <div className="spacer-bottom-24">
            <Checkbox
              checked={orderForm?.testOrder}
              id="testOrder"
              label="Zamówienie testowe"
              name="testOrder"
              onChange={({ target: { checked, name } }) => {
                dispatch(toggleOrderCheckbox(name, checked));
                dispatch(selectOrderDays([]));

                if (!orderForm?.testOrder) {
                  dispatch(
                    setDefaultDays(formSettings?.testOrderSettings?.minimumDays)
                  );
                } else {
                  dispatch(
                    setDefaultDays(
                      formSettings?.orderSettings?.defaultDaySuggestion || 10
                    )
                  );
                }
              }}
            />
          </div>
        )}
    </div>
  );
};

export default WeekendCheckbox;
