import React from 'react';

const CalendarSmallIllustration = () => {
  return (
    <svg
      fill="none"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 2.75V7.75H10.25V2.75H14.5Z"
        fill="white"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M9.75 8.25H15V2.25H9.75V8.25Z"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M37.75 2.75V7.75H33.5V2.75H37.75Z"
        fill="white"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M33 8.25H38.25V2.25H33V8.25Z"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M38.25 5.25V8.25H33V5.25H15V8.25H9.75V5.25H5.25V12.75H42.75V5.25H38.25Z"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M10.5 24.75H17.25V18H10.5V24.75Z"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M10.5 31.5H17.25V24.75H10.5V31.5Z"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M10.5 38.25H17.25V31.5H10.5V38.25Z"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M17.25 24.75H24V18H17.25V24.75Z"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M17.25 31.5H24V24.75H17.25V31.5Z"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M17.25 38.25H24V31.5H17.25V38.25Z"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M24 38.25H30.75V31.5H24V38.25Z"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M30.75 38.25H37.5V31.5H30.75V38.25Z"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M30.75 31.5H37.5V24.75H30.75V31.5Z"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M24 24.75H30.75V18H24V24.75Z"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M30.75 24.75H37.5V18H30.75V24.75Z"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M42.75 5.25H38.25V8.25H33V5.25H15V8.25H9.75V5.25H5.25V43.5H42.75Z"
        fillRule="evenodd"
        stroke="#1B42B2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default CalendarSmallIllustration;
