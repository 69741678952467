import React, { useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import {
  chooseExistingAddress,
  resetSelectedZipCode,
  selectCityOrZipcode,
} from '../../../../../redux/actions/summaryActions';
import { ComboboxSize } from '../../../../../@types/city/city.type';
import * as yup from 'yup';
import styles from '../../styles/sidebarAddressDetails.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { saveAddress } from '../../utils/sidebarAddressDetails.utils';
import { DietlyOpenCityT } from '../../../../../services/api/form/open/formOpenCities.api';
import { useAppDispatch, useAppSelector } from '../../../../../config/hooks';
import { getProfileAddresses } from '../../../../../redux/selectors/profileSelector';
import { Input } from '../../../../common/Input';
import ComboboxCitiesOrder from '../../../../common/search/ComboboxCitiesOrder';
import ComboboxZipCodes from '../../../../common/search/ComboboxZipCodes';
import { Textarea } from '../../../../common/textArea';
import Button from '../../../../common/Button';
import { usePostApiAddressMutation } from '../../../../../services/api/form/profile/profile.api';
import { ZipCodeT } from '../../../../../features/orderForm/stepSummary/types/stepSummary.type';

type Props = {
  companyId: string;
  setIsSidebarOpen: (b: boolean) => void;
};

const SidebarAddressDetails = ({ companyId, setIsSidebarOpen }: Props) => {
  const dispatch = useAppDispatch();
  const profileAddresses = useAppSelector(getProfileAddresses);

  const [postApiAddress, { isLoading }] = usePostApiAddressMutation();

  useEffect(() => {
    return () => {
      dispatch(resetSelectedZipCode());
    };
  }, [dispatch]);

  type FormValues = {
    apartmentNumber: string;
    houseNumber: string;
    floor: string;
    staircase: string;
    gateKey: string;
    info: string;
    street: string;
    formikCity: null | DietlyOpenCityT;
    formikZipCode: null | ZipCodeT;
  };

  const handleOnSubmit = async (values: FormValues) => {
    await saveAddress(
      values,
      profileAddresses,
      setIsSidebarOpen,
      postApiAddress
    );
  };

  const validationSchema = yup.object().shape({
    apartmentNumber: yup.string().max(255, 'Numer mieszkania jest za długi'),
    floor: yup.string().max(10, 'Numer piętra jest za długi'),
    formikCity: yup.object().shape({
      cityId: yup.number().required(),
      name: yup.string().required(),
    }),
    formikZipCode: yup.object().shape({
      code: yup.string().required(),
      zipCodeId: yup.number().required(),
    }),
    gateKey: yup.string().max(255, 'Numer kodu do klatki jest za długi'),
    houseNumber: yup
      .string()
      .required('Numer domu jest wymagany')
      .max(255, 'Numer mieszkania jest za długi'),
    info: yup.string().max(500, 'Notatka jest za długa'),
    staircase: yup.string().max(10, 'Numer klatki jest za długi'),
    street: yup
      .string()
      .required('Ulica jest wymagana')
      .max(255, 'Nazwa ulicy jest za długa'),
  });

  const initialValues: FormValues = {
    apartmentNumber: '',
    houseNumber: '',
    floor: '',
    staircase: '',
    gateKey: '',
    info: '',
    street: '',
    formikCity: null,
    formikZipCode: null,
  };

  return (
    <div className={styles['container']}>
      <h2 className="h200 spacer-bottom-40">Dodanie nowego adresu</h2>
      <Formik
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, setFieldValue, touched, values }) => (
          <Form className={styles['form']}>
            <Field
              component={Input}
              errorDown
              errorMsg={touched.street && errors.street}
              id="street"
              label={
                <span>
                  Ulica
                  <span className="color-error spacer-left-4">*</span>
                </span>
              }
              name="street"
              onChange={(e: { target: { value: string } }) => {
                setFieldValue('street', e.target.value);
              }}
              value={values.street}
            />

            <div className={styles['wrapper']}>
              <Field
                component={Input}
                errorDown
                errorMsg={touched.houseNumber && errors.houseNumber}
                id="houseNumber"
                label={
                  <span>
                    Nr budynku
                    <span className="color-error spacer-left-4">*</span>
                  </span>
                }
                name="houseNumber"
                onChange={(e: { target: { value: string } }) => {
                  setFieldValue('houseNumber', e.target.value);
                }}
                value={values.houseNumber}
              />

              <Field
                component={Input}
                errorDown
                errorMsg={touched.apartmentNumber && errors.apartmentNumber}
                id="apartmentNumber"
                label="Numer lokalu"
                name="apartmentNumber"
                onChange={(e: { target: { value: string } }) => {
                  setFieldValue('apartmentNumber', e.target.value);
                }}
                value={values.apartmentNumber}
              />
            </div>

            <div className={styles['cityZipcodeWrapper']}>
              <div>
                <p
                  className={`label-m spacer-bottom-8 color-gray-500 ${
                    touched.formikCity && errors.formikCity
                      ? ` color-error`
                      : ''
                  }`}
                >
                  Miasto
                </p>
                <Field
                  companyName={companyId}
                  component={ComboboxCitiesOrder}
                  isError={touched.formikCity && errors.formikCity}
                  name="formikCity"
                  onSelect={(city: DietlyOpenCityT) => {
                    setFieldValue('formikCity', city);
                    setFieldValue('formikZipCode', null);
                    dispatch(selectCityOrZipcode(city, 'city'));
                    dispatch(chooseExistingAddress(0));
                  }}
                  placeholder=" "
                  size={ComboboxSize.L}
                />
                {touched.formikCity && errors.formikCity && (
                  <p className="body-m color-error spacer-top-4">
                    Miasto jest wymagane
                  </p>
                )}
              </div>

              <div>
                <p
                  className={`label-m spacer-bottom-8 color-gray-500 ${
                    touched.formikZipCode && errors.formikZipCode
                      ? ` color-error`
                      : ''
                  }`}
                >
                  Kod pocztowy
                </p>
                <Field
                  company={companyId}
                  component={ComboboxZipCodes}
                  // @ts-ignore
                  forceCityId={values.formikCity?.cityId}
                  isError={touched.formikZipCode && errors.formikZipCode}
                  name="formikZipCode"
                  onSelect={(zipCode: ZipCodeT) => {
                    setFieldValue('formikZipCode', zipCode);
                    dispatch(selectCityOrZipcode(zipCode, 'zipCode'));
                  }}
                  size={ComboboxSize.L}
                />
                {touched.formikZipCode && errors.formikZipCode && (
                  <p className="body-m color-error spacer-top-4">
                    Kod pocztowy jest wymagany
                  </p>
                )}
              </div>
            </div>

            <div className={styles['wrapper']}>
              <Field
                component={Input}
                errorDown
                errorMsg={touched.floor && errors.floor}
                id="floor"
                label="Piętro"
                name="floor"
                onChange={(e: { target: { value: string } }) => {
                  setFieldValue('floor', e.target.value);
                }}
                value={values.floor}
              />
              <Field
                component={Input}
                errorDown
                errorMsg={touched.staircase && errors.staircase}
                id="staircase"
                label="Klatka"
                name="staircase"
                onChange={(e: { target: { value: string } }) => {
                  setFieldValue('staircase', e.target.value);
                }}
                value={values.staircase}
              />
            </div>

            <div className={styles['gateKey']}>
              <Field
                component={Input}
                errorDown
                errorMsg={touched.gateKey && errors.gateKey}
                id="gateKey"
                label="Kod domofonu"
                name="gateKey"
                onChange={(e: { target: { value: string } }) => {
                  setFieldValue('gateKey', e.target.value);
                }}
                value={values.gateKey}
              />
              <div className="display-flex label-s color-gray-500">
                <FontAwesomeIcon
                  className="spacer-right-10"
                  icon={faExclamationCircle}
                />
                <p>
                  Kod domofonu pomoże dostawcy w bezkontaktowej obsłudze
                  zamówienia.
                </p>
              </div>
            </div>

            <Field
              component={Textarea}
              error={touched['info'] && errors.info}
              id="info"
              label="Uwagi do adresu"
              maxLength={500}
              name="info"
              onChange={(e: { target: { value: string } }) => {
                setFieldValue('info', e.target.value);
              }}
              placeholder="Wpisz uwagi dla dostawcy"
              rows="4"
              value={values.info}
            />

            <Button
              colorPrimary
              disabled={isLoading}
              label={isLoading ? 'Zapisuję...' : 'Zapisz'}
              type="submit"
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SidebarAddressDetails;
