import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../config/hooks';

const defaultLocale = 'en-GB';

type LocaleInitialStateT = {
  lang: string;
};

export const localeInitialState: LocaleInitialStateT = {
  lang: defaultLocale,
};

const localeSlice = createSlice({
  name: 'locale',
  initialState: localeInitialState,
  reducers: {
    changeLanguage(state, action) {
      state.lang = action.payload;
    },
  },
});

// reducer
export default localeSlice.reducer;

// actions
export const { changeLanguage } = localeSlice.actions;

// selectors
export const getLocaleReducer = (state: RootState) => state.locale;

export const getLang = (state: RootState) => getLocaleReducer(state).lang;
