import React from 'react';
import PropTypes from 'prop-types';

export const Radio = ({ checked, id, label, name, onChange, value }) => {
  return (
    <div className="input-radio">
      <input
        checked={checked}
        id={id}
        name={name}
        onChange={onChange}
        type="radio"
        value={value}
      />
      <label className="body-l" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

Radio.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
