import { SelectedSideOrdersT } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.slice';

export const createSideOrdersArray = (
  selectedSideOrders: SelectedSideOrdersT
) =>
  Object.values(selectedSideOrders || {}).reduce<Array<string>>((acc, curr) => {
    acc.push(...Object.keys(curr));
    return [...new Set(acc)];
  }, []);

export const parseSideOrdersObjectToArray = (
  sideOrdersObject: SelectedSideOrdersT
) => {
  // it's actually not 'string' but 'SideOrderIdT', but Object.keys don't understand it
  const arr: Array<Array<string>> = [];
  // eslint-disable-next-line array-callback-return
  Object.values(sideOrdersObject).map(sideOrder => {
    arr.push(Object.keys(sideOrder));
  });

  return [...new Set(arr.flat())];
};
