import {
  CalculatePriceRequestT,
  useGetOpenCalculatePriceQuery,
  useGetProfileCalculatePriceQuery,
} from '../../api/form/open/shopping-cart.api';

const useCalculatePriceRequestsHook = (
  data: CalculatePriceRequestT,
  isUserLogged: boolean,
  isSkip: boolean
) => {
  const {
    data: openData,
    isError: openIsError,
    isFetching: openIsFetching,
    isUninitialized: openIsUninitialized,
  } = useGetOpenCalculatePriceQuery(data, {
    skip: isUserLogged || isSkip,
  });

  const {
    data: profileData,
    isError: profileIsError,
    isFetching: profileIsFetching,
    isUninitialized: profileIsUninitialized,
  } = useGetProfileCalculatePriceQuery(data, {
    skip: !isUserLogged || isSkip,
  });

  const isAnyUninitialized = isUserLogged
    ? profileIsUninitialized
    : openIsUninitialized;

  const isFetching = openIsFetching || profileIsFetching || isAnyUninitialized;
  const isError = openIsError || profileIsError;

  const prices = profileData ?? openData;

  const {
    cart: {
      totalCostWithoutDiscounts = 0,
      totalDeliveriesOnDateDiscount = 0,
      totalOrderLengthDiscount = 0,
    } = {},
  } = prices || {};

  return {
    prices,
    isError,
    isFetching,
    finalSum:
      totalCostWithoutDiscounts -
      totalDeliveriesOnDateDiscount -
      totalOrderLengthDiscount,
  };
};

export default useCalculatePriceRequestsHook;
