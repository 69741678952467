// order form
export const CHANGE_STEP = 'CHANGE_STEP';
export const CHANGE_MIN_MAX_MODAL_STATUS = 'CHANGE_MIN_MAX_MODAL_STATUS';
export const RESTORE_INITIAL_ORDER_FORM = 'RESTORE_INITIAL_ORDER_FORM';
export const SELECT = 'SELECT';
export const STORE_MEALS = 'STORE_MEALS';
export const TOGGLE_MEAL = 'TOGGLE_MEAL';
export const SET_DAYS = 'SET_DAYS';
export const TOGGLE_CHECKBOX = 'TOGGLE_CHECKBOX';
export const STORE_SIDE_ORDERS = 'STORE_SIDE_ORDERS';
export const SELECT_DAYS = 'SELECT_DAYS';
export const TOGGLE_DAY = 'TOGGLE_DAY';
export const STORE_FILTERED_WEEKENDS = 'STORE_FILTERED_WEEKENDS';
export const STORE_EXTRA_DAYS = 'STORE_EXTRA_DAYS';
export const STORE_DAY = 'STORE_DAY';
export const DELETE_DAY = 'DELETE_DAY';
export const RESET_FILTERED_DAYS = 'RESET_FILTERED_DAYS ';
export const RESET_CALENDAR = 'RESET_CALENDAR ';
export const SET_DEFAULT_DAYS = 'SET_DEFAULT_DAYS ';
export const SET_DEFAULT_WEEKENDS = 'SET_DEFAULT_WEEKENDS';
export const UPDATE_ORDER_FORM_REDUCER = 'UPDATE_ORDER_FORM_REDUCER';
export const STORE_ORDER_FROM_PRICING = 'STORE_ORDER_FROM_PRICING';
export const STORE_CITY = 'STORE_CITY';
export const STORE_CATERING = 'STORE_CATERING';
export const STORE_LAST_ITEM_INTO_ORDER_FORM =
  'STORE_LAST_ITEM_INTO_ORDER_FORM';
export const RESET_PREVIOUSLY_SELECTED_DIET_DETAILS =
  'RESET_PREVIOUSLY_SELECTED_DIET_DETAILS';
export const STORE_PRICES = 'STORE_PRICES';
export const STORE_DETAILS = 'STORE_DETAILS';
export const RESET_SIDE_ORDERS = 'RESET_SIDE_ORDERS';

export const MEAL_EXCHANGE_SELECT_PROGRAM = 'MEAL_EXCHANGE_SELECT_PROGRAM';
export const MEAL_EXCHANGE_CHANGE_EDIT_MODAL_CLICKED_AREA =
  'MEAL_EXCHANGE_CHANGE_EDIT_MODAL_CLICKED_AREA';
export const MEAL_EXCHANGE_CHANGE_EDIT_MODAL_STATUS =
  'MEAL_EXCHANGE_CHANGE_EDIT_MODAL_STATUS';

// basket
export const DELETE_ITEM = 'DELETE_ITEM';
export const CHANGE_SETS_IN_BASKET = 'CHANGE_SETS_IN_BASKET ';
export const EDIT_ITEM_IN_BASKET = 'EDIT_ITEM_IN_BASKET';
export const RESET_EDITION = 'RESET_EDITION';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const STORE_PROMO_CODE = 'STORE_PROMO_CODE';
export const PROMO_CODE_ERROR_FETCHING = 'PROMO_CODE_ERROR_FETCHING';
export const PROMO_CODE_ERROR_RESET = 'PROMO_CODE_ERROR_RESET';
export const STORE_BASKET = 'STORE_BASKET';
export const ADD_TO_BASKET = 'ADD_TO_BASKET';
export const RESET_BASKET = 'RESET_BASKET';
export const RESET_PDF_FILE = 'RESET_PDF_FILE';
export const RESET_PROMOCODE = 'RESET_PROMOCODE';
export const SET_USED_LOYALTY_POINTS = 'SET_USED_LOYALTY_POINTS';
export const CANCEL_LOYALTY_POINTS = 'CANCEL_LOYALTY_POINTS';
export const CHANGE_PDF_FILE = 'CHANGE_PDF_FILE';
export const CHANGE_HAS_PDF_FILE = 'CHANGE_HAS_PDF_FILE';

// third step
export const CHANGE_METHOD_OF_PURCHASE = 'CHANGE_METHOD_OF_PURCHASE';
export const CHANGE_DELIVERY_METHOD = 'CHANGE_DELIVERY_METHOD';
export const CHOOSE_ADDRESS = 'CHOOSE_ADDRESS';
export const CHOOSE_PICKUP_POINT_ID = 'CHOOSE_PICKUP_POINT_ID';
export const CHANGE_ADDRESS_INPUT = 'CHANGE_ADDRESS_INPUT';
export const CHANGE_CLIENT_DETAILS_INPUT = 'CHANGE_CLIENT_DETAILS_INPUT';
export const SELECT_DELIVERY_HOURS = 'SELECT_DELIVERY_HOURS';
export const AGREEMENTS_CHECKBOX_TOGGLE = 'AGREEMENTS_CHECKBOX_TOGGLE';
export const ALL_AGREEMENTS_CHECKBOXES_TOGGLE =
  'ALL_AGREEMENTS_CHECKBOXES_TOGGLE';
export const SELECT_ORIGIN = 'SELECT_ORIGIN';
export const SELECT_CITY_OR_ZIPCODE = 'SELECT_CITY_OR_ZIPCODE';
export const RESET_ZIPCODE = 'RESET_ZIPCODE';
export const STORE_FINAL_PRICES_AND_DISCOUNTS =
  'STORE_FINAL_PRICES_AND_DISCOUNTS';
export const CHANGE_PAYMENT_TYPE = 'CHANGE_PAYMENT_TYPE';
export const RESET_SUMMARY = 'RESET_SUMMARY';

// profile
export const STORE_PROFILE = 'STORE_PROFILE';
export const LOGIN_COMPLETED = 'LOGIN_COMPLETED';
export const LOGIN_BEGIN = 'LOGIN_BEGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const RESET_LOGIN_VALIDATION = 'RESET_LOGIN_VALIDATION';
export const TOGGLE_REMEMBER_ME = 'TOGGLE_REMEMBER_ME';
export const STORE_PAYMENT_URL = 'STORE_PAYMENT_URL';
export const RESET_PAYMENT_URL = 'RESET_PAYMENT_URL';
export const RESET_PROFILE = 'RESET_PROFILE';
export const MARK_PROFILE_FETCHED = 'MARK_PROFILE_FETCHED';
export const STORE_LOYALTY_POINTS_SUM = 'STORE_LOYALTY_POINTS_SUM';

// common
export const STORE_HISTORY = 'STORE_HISTORY';

// its for global rest
export const RESET_ORDER_FORM = 'RESET_ORDER_FORM';
