import React, { forwardRef } from 'react';
// @ts-ignore
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/pro-regular-svg-icons';
import { classNamesUtil } from '../../utils/classNamesUtil.util';

type Props = {
  active?: boolean;
  blockButton?: boolean;
  buttonLink?: boolean;
  colorBlank?: boolean;
  colorClean?: boolean;
  colorError?: boolean;
  colorErrorAlt?: boolean;
  colorPrimary?: boolean;
  colorPrimaryAlternative?: boolean;
  colorSuccess?: boolean;
  colorSuccessAlternative?: boolean;
  containerClass?: string;
  disabled?: boolean;
  icon?: boolean | string | React.ReactNode;
  iconRight?: boolean;
  id?: string;
  isLoading?: boolean;
  onClick?: () => void;
  label?: string | React.ReactNode | undefined;
  mark?: string;
  noPointerEvents?: boolean;
  sizeLarge?: boolean;
  sizeMedium?: boolean;
  type?: 'submit' | 'button' | 'reset';
};

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      active,
      blockButton,
      buttonLink,
      colorBlank,
      colorClean,
      colorError,
      colorErrorAlt,
      colorPrimary,
      colorPrimaryAlternative,
      colorSuccess,
      colorSuccessAlternative,
      containerClass,
      disabled,
      icon,
      iconRight,
      id,
      isLoading,
      label,
      mark,
      noPointerEvents,
      onClick,
      sizeLarge,
      sizeMedium,
      type = 'button',
      ...restProps
    }: Props,
    ref
  ) => {
    return (
      <button
        className={classNamesUtil('button', {
          [`${containerClass}`]: !!containerClass,
          'button-blank': !!colorBlank,
          'button-block': !!blockButton,
          'button-clean': !!colorClean,
          'button-error': !!colorError,
          'button-error-alt': !!colorErrorAlt,
          'button-icon-right': !!iconRight || !!isLoading,
          'button-l': !!sizeLarge,
          'button-link': !!buttonLink,
          'button-m': !!sizeMedium,
          'button-no-label': !label,
          'button-primary': !!colorPrimary,
          'button-primary-alternative': !!colorPrimaryAlternative,
          'button-success': !!colorSuccess,
          'button-success-alternative': !!colorSuccessAlternative,
          'is-active': !!active,
        })}
        disabled={disabled || isLoading}
        id={id}
        onClick={onClick}
        ref={ref}
        type={type}
        {...restProps}
      >
        {label && (
          <div
            className={`button-label ${
              noPointerEvents ? 'pointer-events-none' : ''
            }`}
            id={id}
          >
            {label}
          </div>
        )}

        {isLoading && <FontAwesomeIcon icon={faSyncAlt} spin />}

        {!isLoading && icon && (
          <div className="button-icon" id={id}>
            {icon}
          </div>
        )}

        {mark && (
          <span className="button-mark" id={id}>
            {mark}
          </span>
        )}
      </button>
    );
  }
);

export default Button;
