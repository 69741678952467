//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { useCombobox } from 'downshift';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons';
import useIsWide from '../../../hooks/useIsWide';
import {
  filterCitiesByCatering,
  initiallyFetchTop30Cities,
  sanitizeInputValue,
} from './helpers/comboboxHelpers';
import { motion } from 'framer-motion';
import { useDebounce } from 'react-use';
import { Helmet } from 'react-helmet';
import { faLongArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import Button from '../Button';
import { DietlyOpenCityT } from '../../../services/api/form/open/formOpenCities.api';
import { ComboboxSize } from '../../../@types/city/city.type';
import { getServerConfig } from '../../../features/general/generalSlice';
import { useAppSelector } from '../../../config/hooks';

type Props = {
  citiesSize?: number;
  companyName: string;
  containerClass?: string;
  isError?: boolean;
  initialSelectedItem?: DietlyOpenCityT;
  onSelect?: (k: DietlyOpenCityT) => void;
  redirectAfterwards?: boolean;
  size?: ComboboxSize;
  placeholder?: string;
  unsafe_resetSelectedValue?: boolean;
};

const ComboboxCitiesOrder = ({
  companyName,
  containerClass,
  initialSelectedItem,
  isError = false,
  onSelect,
  placeholder,
  size = ComboboxSize.M,
  unsafe_resetSelectedValue = false,
}: Props) => {
  const [top30Cities, setTop30Cities] = useState<DietlyOpenCityT[] | []>([]);
  const [inputItems, setInputItems] = useState<DietlyOpenCityT[]>([]);
  const [isLoadingMain, setIsLoadingMain] = useState<boolean>(false);
  const isWide = useIsWide();
  const haveNotFetchedTop30CitiesYet = !top30Cities.length;
  const { apiKey, serverUrl } = useAppSelector(getServerConfig);

  const {
    closeMenu,
    getInputProps,
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    inputValue,
    isOpen,
    selectedItem,
    selectItem,
    setInputValue,
    toggleMenu,
  } = useCombobox({
    id: 'combobox-cities',
    initialSelectedItem,
    items: inputItems,
    itemToString: (item: DietlyOpenCityT | null) => (item ? item.name : ''),
    onSelectedItemChange: ({ selectedItem: newItem, type }) => {
      if (
        selectedItem?.cityId !== 0 &&
        selectedItem?.cityId !== newItem?.cityId &&
        type === useCombobox.stateChangeTypes.ItemClick
      ) {
        if (onSelect && newItem) {
          onSelect(newItem);
        }
      }
      return null;
    },
    onInputValueChange: ({ inputValue }) => {
      if (!inputValue) {
        setInputItems(top30Cities);
      }
    },
    onIsOpenChange: changes => {
      const { isOpen, selectedItem: newItem } = changes;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      haveNotFetchedTop30CitiesYet
        ? initiallyFetchTop30Cities(
            serverUrl,
            apiKey,
            companyName,
            setTop30Cities,
            setInputItems,
            setIsLoadingMain
          )
        : null;
      if (newItem) {
        isOpen ? setInputValue('') : setInputValue(newItem?.name);
      }
    },
  });

  const replaceInputValue = inputValue.replace(
    /[^A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ\- ]/gi,
    ''
  );

  const shouldPreventDebounce =
    selectedItem?.sanitizedName !==
    sanitizeInputValue(replaceInputValue ?? '').replace(/ /g, '');

  //Manually change input value from outside
  useEffect(() => {
    if (unsafe_resetSelectedValue) {
      //@ts-ignore
      selectItem(null);
    }
  }, [unsafe_resetSelectedValue, selectItem]);

  const inputProps = {
    autoComplete: 'off',
    onBlur: () => {
      setIsLoadingMain(false);
    },
    onKeyPress: (event: React.KeyboardEvent) => {
      const [firstCity] = inputItems;
      if (event.charCode === 13 && firstCity) {
        selectItem(firstCity);
        closeMenu();
      }
    },
    placeholder: placeholder || 'Miasto',
    value: replaceInputValue || '',
  };

  //HANDLE DEBOUNCE
  const [,] = useDebounce(
    () =>
      !!replaceInputValue &&
      shouldPreventDebounce &&
      filterCitiesByCatering(
        serverUrl,
        apiKey,
        companyName,
        replaceInputValue,
        setInputItems,
        setIsLoadingMain
      ),

    400,
    [replaceInputValue]
  );

  //ANIMATIONS
  const list = {
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  };
  const variants = {
    visible: (i: number) => ({
      x: 0,
      opacity: 1,
      transition: {
        delay: i * 0.04,
      },
    }),
    hidden: { x: 45, opacity: 0 },
  };

  useEffect(() => {
    if (initialSelectedItem?.cityId) {
      selectItem(initialSelectedItem);
    }
  }, [initialSelectedItem, selectItem]);

  const handleBackButton = () => {
    toggleMenu();
  };

  return (
    <>
      {isOpen && isWide && (
        <Helmet>
          {/* eslint-disable-next-line react/style-prop-object */}
          <body style="overflow: hidden;" />
        </Helmet>
      )}
      <div
        className={`dds-combobox__container${
          containerClass ? ` ${containerClass}` : ''
        }`}
      >
        <FontAwesomeIcon
          className="dds-combobox__location"
          icon={faMapMarkerAlt}
        />
        <div
          className={`dds-combobox__input-container${
            isError ? ` dds-combobox__error` : ''
          }`}
          {...getToggleButtonProps()}
        >
          {isOpen && isWide && (
            <div
              className={`dds-combobox__mobile-bar dds-combobox__mobile-size-${size}`}
              onClick={e => e.stopPropagation()}
              onKeyDown={e => e.stopPropagation()}
              role="button"
              tabIndex={0}
            >
              <Button
                colorClean
                containerClass={`dds-combobox__mobile-back dds-combobox__mobile-size-${size}`}
                icon={<FontAwesomeIcon icon={faLongArrowLeft} />}
                onClick={() => handleBackButton()}
              />
              <input
                className={`dds-combobox__input-mobile dds-combobox__mobile-size-${size}`}
                {...getInputProps(inputProps)}
              />
            </div>
          )}
          <input
            className={`dds-combobox__input dds-combobox__input-size-${size}`}
            {...getInputProps(inputProps)}
          />
        </div>
        <FontAwesomeIcon
          className={`dds-combobox__chevron${
            isOpen ? ` dds-combobox__chevron--rotate` : ''
          }`}
          icon={faChevronDown}
        />
        <ul
          className={`${
            isOpen
              ? `dds-combobox__menu dds-combobox__menu-size-${size}`
              : `dds-combobox__menu--closed`
          }${
            isOpen && isWide ? ` dds-combobox__menu-mobile-size-${size}` : ''
          }`}
          {...getMenuProps()}
        >
          {isOpen && isLoadingMain ? (
            <motion.div
              animate="visible"
              className="dds-combobox__loader"
              initial="hidden"
              variants={list}
            >
              Ładowanie...
            </motion.div>
          ) : (
            <>
              {isOpen && !replaceInputValue?.length && (
                <motion.li
                  animate="visible"
                  className="dds-combobox__label"
                  initial="hidden"
                  variants={list}
                >
                  Miasta cateringu
                </motion.li>
              )}
              {isOpen &&
                inputItems &&
                inputItems.map((item: DietlyOpenCityT, index: number) => (
                  <motion.li
                    animate="visible"
                    className="dds-combobox__item"
                    initial="hidden"
                    key={`${item.cityId}${item.name}`}
                    variants={variants}
                    {...getItemProps({ item, index })}
                  >
                    <FontAwesomeIcon
                      className="dds-combobox__location-item"
                      icon={faMapMarkerAlt}
                    />
                    <div className="dds-combobox__item-text">
                      <p>{item.name}</p>
                      <p className="body-s">pow. {item.county}</p>
                    </div>
                  </motion.li>
                ))}
              {isOpen && !!replaceInputValue?.length && !inputItems?.length && (
                <li className="dds-combobox__item dds-combobox__item--empty">
                  Brak miasta o takiej nazwie
                </li>
              )}
            </>
          )}
        </ul>
      </div>
    </>
  );
};

export default ComboboxCitiesOrder;
