import { MenuConfMealT } from '@features/orderForm/stepMenuConfiguration/types/menuConf.type';
import { amountOfSelectedSideOrders } from '@features/orderForm/stepMenuConfiguration/utils/menuConf.utils';
import { getSelectedSideOrders } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.selector';
import {
  getMenuConfActiveDay,
  getMenuConfCustomDeliveryMeals,
  getMenuConfSelectedMealsAmountPerDay,
} from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.selector';
import { useAppSelector } from '@config/hooks';
import ForkAndKnifeIcon from '@assets/icons/forkAndKnifeIcon';
import { declination } from 'utilsRoot/index';
import CartIcon from '@assets/icons/cartIcon';
import FireIcon from '@assets/icons/fireIcon';

type Props = {
  mealsPerDay: Array<MenuConfMealT>;
};

const MealsAndCaloriesAndSideOrdersSummary = ({ mealsPerDay }: Props) => {
  const activeDay = useAppSelector(getMenuConfActiveDay);
  const customDeliveryMeals = useAppSelector(getMenuConfCustomDeliveryMeals);
  const mealsAmount = useAppSelector(getMenuConfSelectedMealsAmountPerDay);
  const selectedSideOrders = useAppSelector(getSelectedSideOrders);

  const sideOrdersAmount = amountOfSelectedSideOrders(
    selectedSideOrders,
    activeDay
  );

  const caloriesSum = mealsPerDay.reduce<number>((acc, curr) => {
    if (activeDay && customDeliveryMeals && activeDay in customDeliveryMeals) {
      const dietCaloriesMealId = (customDeliveryMeals[activeDay] || {})[
        curr.carouselId
      ]?.dietCaloriesMealId;
      if (dietCaloriesMealId) {
        const info = curr.options.find(
          option => option.dietCaloriesMealId === dietCaloriesMealId
        )?.info;
        if (info) {
          const numberMatch = info.match(/(\d+)\s*kcal/);
          if (numberMatch) {
            const caloriesString = numberMatch[1];
            if (caloriesString && !isNaN(Number(caloriesString))) {
              acc = acc + Number(caloriesString);
            }
          }
        }
      }
    }
    return acc;
  }, 0);

  return (
    <div className="display-flex flex-wrap">
      <div className="display-flex align-items-center spacer-top-8">
        <ForkAndKnifeIcon
          className="spacer-right-4 hidden-down-sm"
          color="#8FA1B3"
        />
        <p className="body-m">
          {declination(mealsAmount, 'posiłek', 'posiłki', 'posiłków')}
        </p>
      </div>
      {!!sideOrdersAmount && (
        <div className="display-flex align-items-center spacer-top-8">
          <span className="body-m spacer-horizontal-4 hidden-up-sm">•</span>
          <CartIcon
            className="spacer-left-20 spacer-right-4 hidden-down-sm"
            color="#8FA1B3"
            height="24"
            width="24"
          />
          <p className="body-m">
            {declination(sideOrdersAmount, 'dodatek', 'dodatki', 'dodatków')}
          </p>
        </div>
      )}
      <div className="display-flex align-items-center spacer-top-8">
        <span className="body-m spacer-horizontal-4 hidden-up-sm">•</span>
        <FireIcon className="spacer-left-20 spacer-right-4 hidden-down-sm" />
        <p className="body-m">{Math.floor(caloriesSum)} kcal</p>
      </div>
    </div>
  );
};

export default MealsAndCaloriesAndSideOrdersSummary;
