import { checkIsSalesManagoEnabled } from '@salesManago/helpers';
import { salesManagoUpdatePromoCode } from '@salesManago/events/shopCart';
import { useAppSelector } from '@config/hooks';
import { getBasket, getPromoCode } from '@redux/selectors/basketSelector';
import { getOneOffsSelectedFees } from '@features/orderForm/stepShoppingCart/redux/oneOffSideOrders.selector';
import { getServerConfig } from '@features/general/generalSlice';
import { getSelectedSideOrders } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.selector';
import useCalculateCartPrice from '@services/hook/calculatePrices/useCalculateCartPrice.hook';

export const useSalesManagoUpdatePromoCode = () => {
  const promoCode = useAppSelector(getPromoCode);
  const basket = useAppSelector(getBasket);
  const selectedFees = useAppSelector(getOneOffsSelectedFees) || [];
  const { companyId, salesManago, serverUrl } = useAppSelector(getServerConfig);
  const selectedSideOrders = useAppSelector(getSelectedSideOrders);

  const { prices } = useCalculateCartPrice();

  const trigger = () => {
    if (
      checkIsSalesManagoEnabled(salesManago) &&
      !!promoCode.code?.promoCodeId &&
      !!basket?.length &&
      !!basket[0].eventId
    ) {
      salesManagoUpdatePromoCode({
        code: promoCode.code?.code,
        item: basket[0],
        selectedFees,
        description: 'Dodanie promoCode',
        serverUrl,
        salesManago,
        companyId,
        selectedSideOrders,
        cartPrices: prices?.cart,
      }).catch(e => {
        console.log(e, 'e');
      });
    }
    return () => {};
  };

  return { trigger };
};
