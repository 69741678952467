import * as actionTypes from '../actions/actionTypes';

const initialState = {
  profile: {},
  cities: {},
  zipCodes: {},
  flags: {
    fetchingProfile: false,
    error: null,
    postingLogin: false,
    loginError: null,
  },
  loginValidation: '',
  rememberMe: false,
  wasProfileFetched: false,
  loyaltyPoints: {
    companyId: '',
    loyaltyPointsSum: '',
  },
};

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STORE_LOYALTY_POINTS_SUM:
      return {
        ...state,
        loyaltyPoints: {
          companyId: action.companyId,
          loyaltyPointsSum: action.points,
        },
      };
    case actionTypes.MARK_PROFILE_FETCHED:
      return {
        ...state,
        wasProfileFetched: true,
      };
    case actionTypes.RESET_PROFILE: {
      return {
        ...state,
        profile: {},
      };
    }
    case actionTypes.TOGGLE_REMEMBER_ME:
      return {
        ...state,
        rememberMe: action.checked,
      };
    case actionTypes.RESET_LOGIN_VALIDATION:
      return {
        ...state,
        loginValidation: '',
      };
    case actionTypes.LOGIN_BEGIN:
      return {
        ...state,
        flags: {
          ...state.flags,
          postingLogin: true,
          loginError: null,
        },
      };
    case actionTypes.LOGIN_COMPLETED:
      return {
        ...state,
        flags: {
          ...state.flags,
          postingLogin: false,
          loginError: null,
        },
      };
    case actionTypes.LOGIN_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          postingLogin: false,
          loginError: action.error,
        },
      };
    case actionTypes.STORE_PROFILE:
      return {
        ...state,
        profile: action.profile,
        cities: action.cities,
        zipCodes: action.zipCodes,
      };
    default:
      return state;
  }
};
