export const getDietOptions = selectedDiet => {
  if (!selectedDiet) {
    return [];
  }
  return selectedDiet.dietOptions ?? [];
};

export const getDietOptionCalories = (
  selectedOption,
  selectedProgram,
  companyDietCaloriesMenuConfiguration
) => {
  if (!selectedOption) {
    return [];
  }

  if (selectedOption?.dietCalories) {
    const standard = [...selectedOption?.dietCalories];

    const menuConfiguration = [...selectedOption?.dietCalories].filter(item1 =>
      companyDietCaloriesMenuConfiguration.some(
        item2 => item1.dietCaloriesId === item2.dietCaloriesId
      )
    );

    const result =
      selectedProgram === 'standard' ? standard : menuConfiguration;

    return result?.sort((prev, next) => prev.calories - next.calories);
  }
};

export const checkIfAllMealsSelected = selectedMeals => {
  return selectedMeals && selectedMeals.every(meal => meal.selected);
};

// checks if meals should be visible in pricing
export const checkCustomDietOptions = (selectedDiet, formSettings) => {
  const { orderSettings: { customDietOptions } = {} } = formSettings || {};

  return customDietOptions && selectedDiet?.customDietOptions;
};

export const getNumberOfSelectedMeals = selectedMeals => {
  return selectedMeals && selectedMeals.filter(meal => meal.selected).length;
};

export const getMinimumNumberOfMeals = formSettings => {
  const { orderSettings: { minimumSelectedMeals } = {} } = formSettings || {};

  if (!minimumSelectedMeals) {
    return 1;
  }
  return minimumSelectedMeals;
};

// gets sum of deducted meals
export const displayMinimumSelectedMealsInfo = (
  optionalSwitchOff,
  setMinimumSelectedMealsInfo
) => {
  if (optionalSwitchOff) {
    setMinimumSelectedMealsInfo(true);
    setTimeout(() => setMinimumSelectedMealsInfo(false), 2000);
  }
  if (!optionalSwitchOff) {
    return setMinimumSelectedMealsInfo(false);
  }
  return null;
};

export const handleMeal =
  (
    dietMealId,
    toggleMeal,
    selectedMeals,
    formSettings,
    displayMinimumSelectedMeals,
    setMinimumSelectedMealsInfo
  ) =>
  dispatch => {
    const numberOfSelectedMeals = getNumberOfSelectedMeals(selectedMeals);
    const minimumNumberOfMeals = getMinimumNumberOfMeals(formSettings);
    const toggledMeal = selectedMeals.find(
      meal => meal.dietMealId === dietMealId
    );
    const isMealSelected = toggledMeal && toggledMeal.selected;

    if (numberOfSelectedMeals === minimumNumberOfMeals && isMealSelected) {
      displayMinimumSelectedMealsInfo(true, setMinimumSelectedMealsInfo);
    }

    displayMinimumSelectedMeals &&
      displayMinimumSelectedMealsInfo(false, setMinimumSelectedMealsInfo);

    return dispatch(
      toggleMeal(dietMealId, numberOfSelectedMeals, minimumNumberOfMeals)
    );
  };

export const calculateEstimateCalories = (selectedCalories, selectedMeals) => {
  const { calories } = selectedCalories || {};
  return (
    selectedMeals &&
    selectedMeals.reduce((acc, curr) => {
      if (curr.selected) {
        acc = acc + (curr.mealCaloriesPercentage / 100) * calories;
      }
      return acc;
    }, 0)
  );
};

export const checkIfForwardButtonActive = (
  step,
  orderForm,
  city,
  formSettings
) => {
  const { selectedCalories, selectedDiet, selectedMeals, selectedOption } =
    orderForm || {};

  if (step === 'first') {
    const { cityId } = city || {};

    if (checkCustomDietOptions(selectedDiet, formSettings)) {
      return (
        cityId &&
        selectedDiet &&
        selectedOption &&
        selectedCalories &&
        selectedMeals
      );
    }
    return cityId && selectedDiet && selectedOption && selectedCalories;
  }
  return false;
};

const parseText = (number, one, moreThanOne, moreThanFour) => {
  if (number === 1) {
    return `${number} ${one}`;
  }

  if (number > 1 && number < 5) {
    return `${number} ${moreThanOne}`;
  }

  return `${number} ${moreThanFour}`;
};

export const defaultMenuConfigurationDietTexts = [
  'Kilka dań do wyboru w ramach posiłku',
  'Możliwość zmiany przy zamówieniu i w każdym momencie w panelu klienta',
  'Darmowe wymiany w cenie',
];

export const getDefaultStandardDietTexts = (
  standardDietsCount,
  cateringName
) => [
  `Aż ${parseText(
    standardDietsCount,
    'zbilansowana dieta',
    'zbilansowane diety',
    'zbilansowanych diet'
  )} do wyboru`,
  `Przygotowany przez najlepszych dietetyków ${cateringName}`,
  'Najniższa cena zestawu',
];
