import { DateTime } from 'luxon';
import {
  GenerateDateListParamsT,
  SideOrderWithOrderDatesT,
} from '../types/coursesAsSideOrders.type';

export const DATE_FORMAT = 'yyyy-MM-dd';

export function checkIfSideOrderAvailableInSelectedDatesRange({
  days,
  sideOrderFrom,
  sideOrderTo,
}: {
  days: string[];
  sideOrderFrom: string | null | undefined;
  sideOrderTo: string | null | undefined;
}) {
  if (days.length < 1) {
    return false;
  }
  const orderDateStart = days[0] as string;
  const orderDateEnd = days.at(-1) as string;

  const sideOrderStartDate = sideOrderFrom ? sideOrderFrom : orderDateStart;
  const sideOrderEndDate = sideOrderTo ? sideOrderTo : orderDateEnd;

  const sideOrderDates = getDatesInRange(sideOrderStartDate, sideOrderEndDate);

  const result = sideOrderDates.filter(date => days.includes(date));

  return result.length > 0 ? true : false;
}

export function isSideOderInOrderDays({
  orderDateEnd,
  orderDateStart,
  sideOrderFrom,
  sideOrderTo,
}: SideOrderWithOrderDatesT) {
  const result = sideOrderDaysInOrderDays({
    orderDateStart,
    orderDateEnd,
    sideOrderFrom,
    sideOrderTo,
  });

  return result.length > 0 ? true : false;
}

export function sideOrderDaysInOrderDays({
  orderDateEnd,
  orderDateStart,
  sideOrderFrom,
  sideOrderTo,
}: SideOrderWithOrderDatesT): string[] {
  const sideOrderStartDate = sideOrderFrom ? sideOrderFrom : orderDateStart;
  const sideOrderEndDate = sideOrderTo ? sideOrderTo : orderDateEnd;

  const orderDates = getDatesInRange(orderDateStart, orderDateEnd);
  const sideOrderDates = getDatesInRange(sideOrderStartDate, sideOrderEndDate);

  const result: string[] = sideOrderDates.filter(date =>
    orderDates.includes(date)
  );

  return result;
}

export function getDatesInRange(startDate: string, endDate: string): string[] {
  const dates: string[] = [];
  let currentDate = DateTime.fromISO(startDate);

  while (
    currentDate < DateTime.fromISO(endDate) ||
    currentDate.equals(DateTime.fromISO(endDate))
  ) {
    dates.push(currentDate.toFormat(DATE_FORMAT));
    currentDate = currentDate.plus({ days: 1 });
  }

  return dates;
}

/**
 * Generates a list of date strings between the specified date range.
 *
 * @param {Object} params - The parameters for generating the date list.
 * @param {string} params.dateFrom - The start date in 'YYYY-MM-DD' format.
 * @param {string} params.dateTo - The end date in 'YYYY-MM-DD' format.
 * @param {string} params.endLimit - The fallback end date if `dateTo` is not provided.
 * @param {string} params.startLimit - The fallback start date if `dateFrom` is not provided.
 * @returns {string[] | null} An array of date strings in 'YYYY-MM-DD' format, or null if the start date is after the end date. Empty array is for unlimited days (side order is available all time), null for error/not correct
 *
 */
export function generateDateList(
  params: GenerateDateListParamsT
): string[] | null {
  const { dateFrom, dateTo, endLimit, startLimit } = params;

  const limitStart = DateTime.fromISO(startLimit);
  const limitEnd = DateTime.fromISO(endLimit);
  let startDate: DateTime;
  let endDate: DateTime;

  if (dateFrom && dateTo) {
    startDate = DateTime.fromISO(dateFrom);
    endDate = DateTime.fromISO(dateTo);
  } else if (dateFrom) {
    startDate = DateTime.fromISO(dateFrom);
    endDate = DateTime.fromISO(endLimit);
  } else if (dateTo) {
    startDate = DateTime.fromISO(startLimit);
    endDate = DateTime.fromISO(dateTo);
  } else {
    // both empty
    return [];
  }

  if (startDate < limitStart) {
    startDate = limitStart;
  }
  if (endDate > limitEnd) {
    endDate = limitEnd;
  }
  if (startDate > endDate) {
    return null;
  }

  return getDaysBetween(startDate, endDate);
}

function getDaysBetween(start: DateTime, end: DateTime) {
  const range = [];
  let current = start;
  while (!(current > end)) {
    range.push(current.toFormat(DATE_FORMAT));
    current = current.plus({ days: 1 });
  }
  return range;
}
