import React, { ChangeEvent, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../config/hooks';
import { getPromoCode } from '../../../../redux/selectors/basketSelector';
import { Input } from '../../../common/Input';
import Button from '../../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import usePromoCode from '../../../../hooks/usePromoCode.hook';
import ActiveCode from './ActiveCode';
import { promoCodeResetError } from '../../../../redux/actions/basketActions';

const PromoCodes = () => {
  const dispatch = useAppDispatch();

  const promoCode = useAppSelector(getPromoCode);

  const [inputValue, setInputValue] = useState('');

  const { fetchPromoCode, isLoading } = usePromoCode();

  const disabledButton =
    !inputValue ||
    inputValue.toLowerCase() === promoCode.code?.code?.toLowerCase();

  const handleReset = () => {
    if (promoCode.flags.error) {
      dispatch(promoCodeResetError());
    }
  };

  const handleOnAddPromoCode = async () => {
    if (disabledButton) {
      return;
    }

    try {
      handleReset();
      await fetchPromoCode(inputValue);
      setInputValue('');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="label-l">Kody rabatowe i vouchery</div>

      {promoCode.code?.code && (
        <div className="label-m spacer-bottom-16 spacer-top-16">
          Aktywne kody rabatowe
        </div>
      )}

      {promoCode.code?.code && <ActiveCode code={promoCode.code} />}

      {!promoCode.code?.code && (
        <>
          {/*@ts-ignore*/}
          <Input
            containerClass="spacer-vertical-16"
            onBlur={handleReset}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleReset();

              setInputValue(e.target.value);
            }}
            onFocus={handleReset}
            onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event && event.key === 'Enter') {
                handleOnAddPromoCode();
              }
            }}
            placeholder="Wpisz kod rabatowy"
            value={inputValue}
          />

          <Button
            blockButton
            colorPrimary
            containerClass="spacer-top-8"
            disabled={disabledButton}
            isLoading={isLoading}
            label="Dodaj"
            onClick={handleOnAddPromoCode}
            sizeMedium
          />
        </>
      )}

      {promoCode.flags.error && (
        <div className="display-flex align-items-center color-error label-s spacer-top-20">
          <FontAwesomeIcon
            className="spacer-right-10 font-size-20"
            icon={faExclamationCircle}
          />
          {promoCode.flags.error}
        </div>
      )}
    </>
  );
};

export default PromoCodes;
