import Modal from 'react-modal';
import { useEffectOnce } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import Button from '../../../../common/Button';
import BlikIcon from '../../../../../assets/icons/blikIcon';
import OtpInput from './OtpInput';
import { sendAuthorizationCodeToClient } from '../../utils/summaryHelpers.utils';

type Props = {
  companyName: string;
  isPhoneNumberConfirmationModalOpen: boolean;
  phoneNumber: string;
  promoCode: string;
  setIsPhoneNumberConfirmationModalOpen: (b: boolean) => void;
  submitForm: () => void;
};

const PhoneNumberConfirmationModal = ({
  companyName,
  isPhoneNumberConfirmationModalOpen,
  phoneNumber,
  promoCode,
  setIsPhoneNumberConfirmationModalOpen,
  submitForm,
}: Props) => {
  const handleCloseModal = () => {
    setIsPhoneNumberConfirmationModalOpen(false);
  };

  useEffectOnce(() =>
    sendAuthorizationCodeToClient(companyName, phoneNumber.replace(/\s/g, ''))
  );

  return (
    <Modal
      className="phone-number-confirmation-modal"
      closeTimeoutMS={300}
      isOpen={isPhoneNumberConfirmationModalOpen}
      onRequestClose={() => handleCloseModal && handleCloseModal()}
      overlayClassName="custom-modal-overlay"
    >
      <FontAwesomeIcon
        className="modal-close font-size-24 cursor-pointer color-gray-400"
        icon={faTimes}
        onClick={handleCloseModal}
      />
      <div className="phone-number-confirmation-modal__body">
        <BlikIcon />
        <h4 className="h400 spacer-bottom-8 spacer-top-24">
          Potwierdź numer telefonu
        </h4>
        <p className="body-l">
          Aby skorzystać ze zniżki{' '}
          <span className="font-weight-500">{promoCode}</span> potrzebujemy
          sprawdzić czy Twój numer jest aktywny i czy było już składane
          zamówienie na Dietly.pl
        </p>
        <p className="body-l">
          Na numer +48 {phoneNumber} wysłaliśmy wiadomość z 6-cyfrowym kodem.
        </p>
        <OtpInput
          breakAfter={3}
          companyName={companyName}
          numberOfInputs={6}
          openModal={setIsPhoneNumberConfirmationModalOpen}
          phoneNumber={phoneNumber.replace(/\s/g, '')}
          submitForm={submitForm}
        />
        <p className="body-l">
          Jeśli wiadomość nie dotrzę w ciągu 3 minut{' '}
          <button
            className="font-weight-500 color-primary"
            onClick={() =>
              sendAuthorizationCodeToClient(
                companyName,
                phoneNumber.replace(/\s/g, '')
              )
            }
          >
            spróbuj wysłać ponownie
          </button>
        </p>
        <Button
          containerClass="spacer-right-10"
          label="Aktywuj promocję"
          type="submit"
        />
      </div>
    </Modal>
  );
};

export default PhoneNumberConfirmationModal;
