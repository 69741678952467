import React from 'react';
import BoxStep from '../../../common/BoxStep';
import { getOrderForm } from '@redux/selectors/orderFormSelector';
import NextStepButton from './NextStepButton';
import { useAppSelector } from '@config/hooks';
import { CalculatePriceItemT } from 'typesRoot/calculatePrice.type';
import RecalculatePriceLoader from '../../../../features/orderForm/stepSummary/views/RecalculatePriceLoader';
import SecondStepPrices from './SecondStepPrices';
import YourOrder from './YourOrder';

type Props = {
  isFetching: boolean;
  isSummaryBarDisplayed?: boolean;
  prices?: CalculatePriceItemT;
  setValidationStatus: (value: { message: string; value: string }) => void;
};

const SecondStepSideBar = ({
  isFetching,
  isSummaryBarDisplayed,
  prices,
  setValidationStatus,
}: Props) => {
  const orderForm = useAppSelector(getOrderForm);

  const { selectedCalories, selectedDays, selectedDiet, selectedOption, sets } =
    orderForm || {};

  const { dietImageUrl, dietName, dietTag } = selectedDiet || {};

  const { calories } = selectedCalories || {};

  return (
    <aside className="steps-page__aside">
      <BoxStep borderTitle smallPaddings title="Twoje zamówienie">
        <YourOrder
          calories={calories}
          dietImageUrl={dietImageUrl}
          dietName={dietName}
          dietOptionName={selectedOption?.dietOptionName}
          dietTag={dietTag}
        />
      </BoxStep>

      <div className="steps-page-second__summary position-rel">
        <RecalculatePriceLoader
          isFetching={isFetching && selectedDays?.length}
        />

        <BoxStep borderTitle smallPaddings title="Podsumowanie">
          <div className="display-flex justify-content-space-between spacer-top-18 spacer-bottom-24">
            <span className="body-m color-gray-800">Liczba dni</span>

            <span className="label-m">{selectedDays.length}</span>
          </div>

          <div className="display-flex justify-content-space-between spacer-top-24 spacer-bottom-24">
            <span className="body-m color-gray-800">Liczba zestawów</span>

            <span className="label-m">{sets}</span>
          </div>

          <SecondStepPrices days={selectedDays.length} prices={prices} />

          {!isSummaryBarDisplayed && (
            <NextStepButton
              prices={prices}
              setValidationStatus={setValidationStatus}
            />
          )}
        </BoxStep>
      </div>
    </aside>
  );
};

export default SecondStepSideBar;
