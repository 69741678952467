import { useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import { CalculatePriceRequestT } from '../../api/form/open/shopping-cart.api';
import useCalculatePrice from './useCalculatePrice.hook';
import { BasketItemT } from 'typesRoot/basketItem.type';
import {
  generateSideOrdersList,
  mapDeliveryDates,
  mapSelectedMeals,
} from '@components/pages/StepSummary/utils/generateOrderData.util';
import { PaymentTypeE } from 'typesRoot/common.enum';
import { generateSelectedFees } from '@components/pages/StepSummary/utils/generateSelectedFees.util';
import useCalculatePriceRequestsHook from './useCalculatePriceRequests.hook';
import { DEBOUNCE_CALCULATE_PRICE } from './calculatePrice.const';
import { useAppSelector } from '@config/hooks';
import { getServerConfig } from '@features/general/generalSlice';

const useCalculateCartPrice = () => {
  const { companyId } = useAppSelector(getServerConfig) || {};

  const {
    basket,
    isUserLogged,
    loyaltyProgramPoints,
    loyaltyProgramPointsGlobal,
    newAddressDetails,
    pickupPointId,
    promoCodes,
    selectedFees,
  } = useCalculatePrice();

  const [data, setData] = useState<CalculatePriceRequestT | null>(null);

  const simpleOrders = useMemo(
    () =>
      basket.flatMap((item: BasketItemT, index: number) => {
        const {
          orderId,
          selectedCalories,
          selectedDays,
          selectedMeals,
          selectedSideOrders,
          sets,
          stepMenuConfiguration,
          testOrder,
        } = item;

        const { customDeliveryMealsForCalculatePrice } =
          stepMenuConfiguration || {};

        const data = Array.from({ length: sets }, () => ({
          itemId: orderId,
          deliveryDates: mapDeliveryDates(selectedDays),
          customDeliveryMeals: customDeliveryMealsForCalculatePrice,
          deliveryMeals: mapSelectedMeals(selectedMeals),
          dietCaloriesId: selectedCalories.dietCaloriesId,
          paymentType: PaymentTypeE.ONLINE,
          pickupPointId,
          sideOrders: generateSideOrdersList(selectedSideOrders),
          testOrder,
        }));

        // this is only added to the first diet
        if (index === 0) {
          generateSelectedFees(selectedFees, data);
        }

        return data;
      }),
    [basket, pickupPointId, selectedFees]
  );

  const orderData = useMemo(
    () => ({
      promoCodes,
      simpleOrders,
      loyaltyProgramPoints,
      loyaltyProgramPointsGlobal,
      companyId,
      cityId: newAddressDetails.city.cityId,
    }),
    [
      companyId,
      loyaltyProgramPoints,
      loyaltyProgramPointsGlobal,
      newAddressDetails.city.cityId,
      promoCodes,
      simpleOrders,
    ]
  );

  useDebounce(
    () => {
      setData(orderData);
    },
    DEBOUNCE_CALCULATE_PRICE,
    [orderData]
  );

  const isSkip = !data || data?.simpleOrders?.length === 0 || !data.cityId;

  const { finalSum, isError, isFetching, prices } =
    useCalculatePriceRequestsHook(
      data as CalculatePriceRequestT,
      isUserLogged,
      isSkip
    );

  return {
    prices,
    isError,
    isFetching,
    finalSum,
  };
};

export default useCalculateCartPrice;
