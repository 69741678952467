import {
  parseKeyFromSideOrderId,
  parseTypeAndIdFromKey,
} from '@features/coursesAsSideOrders/utils/coursesAsSideOrders.util';
import { DateTime } from 'luxon';
import { AppDispatch } from '@config/hooks';
import { UseStateT } from 'typesRoot/generics.type';
import {
  SelectedSideOrdersT,
  SideOrderIdT,
  triggerSideOrderError,
} from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.slice';

const reduceList = (
  sortedDays: Array<number>,
  list: SelectedSideOrdersT,
  selectedSideOrderKey: SideOrderIdT,
  multiplier: number,
  defaultPrice: number,
  limitedByMaximumSelectedMeals: boolean,
  maxQuantity: number | null,
  minQuantity: number | null
): SelectedSideOrdersT =>
  sortedDays.reduce((acc: SelectedSideOrdersT, curr) => {
    const isoDay = DateTime.fromMillis(curr).toISODate() || '';
    const [type, id] = parseTypeAndIdFromKey(selectedSideOrderKey);

    acc[isoDay] = {
      ...list[isoDay],
      [selectedSideOrderKey]: {
        ...list[isoDay]?.[selectedSideOrderKey],
        sideOrderId: { id, type },
        defaultPrice,
        limitedByMaximumSelectedMeals,
        maxQuantity,
        minQuantity,
        multiplier,
      },
    };

    return acc;
  }, {});

export const decreaseAllSideOrders =
  ({
    allSideOrdersAmount,
    defaultPrice,
    limitedByMaximumSelectedMeals,
    maxQuantity,
    minQuantity,
    selectedSideOrderKey,
    setAllSideOrdersAmount,
    setSideOrdersState,
    sideOrdersState,
    sortedDays,
  }: {
    allSideOrdersAmount: number;
    defaultPrice: number;
    limitedByMaximumSelectedMeals: boolean;
    maxQuantity: number | null;
    minQuantity: number | null;
    selectedSideOrderKey: SideOrderIdT;
    setAllSideOrdersAmount: UseStateT<number>;
    setSideOrdersState: UseStateT<SelectedSideOrdersT>;
    sideOrdersState: SelectedSideOrdersT;
    sortedDays: Array<number>;
  }) =>
  (dispatch: AppDispatch) => {
    if (minQuantity && allSideOrdersAmount <= minQuantity) {
      setSideOrdersState(
        reduceList(
          sortedDays,
          sideOrdersState,
          selectedSideOrderKey,
          0,
          defaultPrice,
          limitedByMaximumSelectedMeals,
          maxQuantity,
          minQuantity
        )
      );

      setAllSideOrdersAmount(0);
    } else {
      const multiplier = allSideOrdersAmount - 1;

      setSideOrdersState(
        reduceList(
          sortedDays,
          sideOrdersState,
          selectedSideOrderKey,
          multiplier,
          defaultPrice,
          limitedByMaximumSelectedMeals,
          maxQuantity,
          minQuantity
        )
      );

      setAllSideOrdersAmount(multiplier);
    }

    dispatch(triggerSideOrderError(''));
  };

export const increaseAllSideOrders =
  ({
    allSideOrdersAmount,
    defaultPrice,
    deliveryMealCounts,
    limitedByMaximumSelectedMeals,
    maxQuantity,
    mealsLimit,
    minQuantity,
    selectedSideOrderKey,
    selectedSideOrders,
    setAllSideOrdersAmount,
    setSideOrdersState,
    sideOrdersState,
    sortedDays,
  }: {
    allSideOrdersAmount: number;
    defaultPrice: number;
    deliveryMealCounts: Record<string, number>;
    limitedByMaximumSelectedMeals: boolean;
    maxQuantity: number | null;
    mealsLimit: number;
    minQuantity: number | null;
    selectedSideOrderKey: SideOrderIdT;
    selectedSideOrders: SelectedSideOrdersT;
    setAllSideOrdersAmount: UseStateT<number>;
    setSideOrdersState: UseStateT<SelectedSideOrdersT>;
    sideOrdersState: SelectedSideOrdersT;
    sortedDays: Array<number>;
  }) =>
  (dispatch: AppDispatch) => {
    if (limitedByMaximumSelectedMeals) {
      const globalList = Object.keys(selectedSideOrders).reduce(
        (acc: Array<number>, curr) => {
          acc.push(
            Object.values(selectedSideOrders[curr] || {})
              .filter(
                item =>
                  parseKeyFromSideOrderId(item.sideOrderId) !==
                    selectedSideOrderKey && item.limitedByMaximumSelectedMeals
              )
              .reduce((acc1, curr1) => acc1 + curr1.multiplier, 0)
          );
          return acc;
        },
        []
      );

      const highestGlobalValue = globalList.length
        ? Math.max(...globalList)
        : 0;

      const highestLocalValue =
        Object.keys(sideOrdersState || {}).length === 0
          ? 0
          : Math.max(
              ...Object.keys(sideOrdersState).reduce(
                (acc: Array<number>, curr) => {
                  acc.push(
                    Object.values(sideOrdersState[curr] || {})
                      .filter(
                        item =>
                          parseKeyFromSideOrderId(item.sideOrderId) ===
                            selectedSideOrderKey &&
                          item.limitedByMaximumSelectedMeals
                      )
                      .reduce((acc1, curr1) => acc1 + curr1.multiplier, 0)
                  );
                  return acc;
                },
                []
              )
            );

      const highestSelectedMealsLength = Math.max(
        ...Object.values(deliveryMealCounts || {})
      );

      const wholeHighestValue =
        highestGlobalValue + allSideOrdersAmount + highestSelectedMealsLength;

      if (minQuantity) {
        if (highestLocalValue === 0) {
          if (wholeHighestValue >= mealsLimit) {
            dispatch(triggerSideOrderError('maxMeals'));
            return;
          }
        }

        const sideOrderFactor =
          highestLocalValue >= minQuantity ? 1 : minQuantity || 1;

        if (sideOrderFactor + wholeHighestValue > mealsLimit) {
          dispatch(triggerSideOrderError('maxMeals'));
          return;
        }
      }

      if (wholeHighestValue >= mealsLimit) {
        dispatch(triggerSideOrderError('maxMeals'));
        return;
      }
    }

    if (maxQuantity && allSideOrdersAmount >= maxQuantity) {
      dispatch(triggerSideOrderError('maxSideOrders'));
      return;
    }

    const setState = (value: number) => {
      setSideOrdersState(
        reduceList(
          sortedDays,
          sideOrdersState,
          selectedSideOrderKey,
          value,
          defaultPrice,
          limitedByMaximumSelectedMeals,
          maxQuantity,
          minQuantity
        )
      );
      setAllSideOrdersAmount(value);
    };

    if (minQuantity && allSideOrdersAmount === 0) {
      setState(minQuantity);
    } else {
      setState(allSideOrdersAmount + 1);
    }
    dispatch(triggerSideOrderError(''));
  };

export const decreaseSideOrdersItem =
  ({
    isoDay,
    minQuantity,
    multiplier,
    selectedSideOrderKey,
    setAllSideOrdersAmount,
    setSideOrdersState,
  }: {
    isoDay: string;
    minQuantity: number | null;
    multiplier: number;
    selectedSideOrderKey: SideOrderIdT;
    setAllSideOrdersAmount: UseStateT<number>;
    setSideOrdersState: UseStateT<SelectedSideOrdersT>;
  }) =>
  (dispatch: AppDispatch) => {
    const setState = (value: number) => {
      setSideOrdersState(prevState => {
        // TODO ANY
        const updatedState: any = {
          ...prevState,
          [isoDay]: {
            ...(prevState[isoDay] || {}),
            [selectedSideOrderKey]: {
              ...(prevState[isoDay]?.[selectedSideOrderKey] || {}),
              multiplier: value,
            },
          },
        };

        if (value === 0) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [selectedSideOrderKey]: _, ...rest } = updatedState[isoDay];
          if (Object.keys(rest).length === 0) {
            delete updatedState[isoDay];
          } else {
            updatedState[isoDay] = {
              ...rest,
            };
          }
        }

        return updatedState;
      });

      setAllSideOrdersAmount(0);
    };

    if (minQuantity && multiplier <= minQuantity) {
      setState(0);
    } else {
      setState(multiplier - 1);
    }

    dispatch(triggerSideOrderError(''));
  };

export const increaseSideOrdersItem =
  ({
    defaultPrice,
    isoDay,
    limitedByMaximumSelectedMeals,
    maxQuantity,
    mealsLimit,
    minQuantity,
    multiplier,
    selectedMealsLength,
    selectedSideOrderKey,
    selectedSideOrders,
    setAllSideOrdersAmount,
    setSideOrdersState,
    sideOrdersState,
  }: {
    defaultPrice: number;
    isoDay: string;
    limitedByMaximumSelectedMeals: boolean;
    maxQuantity: number | null;
    mealsLimit: number;
    minQuantity: number | null;
    multiplier: number;
    selectedSideOrderKey: SideOrderIdT;
    selectedMealsLength: number;
    selectedSideOrders: SelectedSideOrdersT;
    setAllSideOrdersAmount: UseStateT<number>;
    setSideOrdersState: UseStateT<SelectedSideOrdersT>;
    sideOrdersState: SelectedSideOrdersT;
  }) =>
  (dispatch: AppDispatch) => {
    if (limitedByMaximumSelectedMeals) {
      const amountFromGlobalState = Object.values(
        selectedSideOrders?.[isoDay] || {}
      )
        .filter(
          item =>
            parseKeyFromSideOrderId(item.sideOrderId) !==
              selectedSideOrderKey && item.limitedByMaximumSelectedMeals
        )
        .reduce((acc, curr) => acc + curr.multiplier, 0);

      const amountFromLocalState = Object.values(
        sideOrdersState?.[isoDay] || {}
      )
        .filter(
          item =>
            parseKeyFromSideOrderId(item.sideOrderId) ===
              selectedSideOrderKey && item.limitedByMaximumSelectedMeals
        )
        .reduce((acc, curr) => acc + curr.multiplier, 0);

      const wholeAmount =
        amountFromGlobalState + multiplier + selectedMealsLength;

      if (minQuantity) {
        if (amountFromLocalState === 0) {
          if (wholeAmount > mealsLimit) {
            dispatch(triggerSideOrderError(`maxSingleMeals-${isoDay}`));
            return;
          }

          if (minQuantity + wholeAmount > mealsLimit) {
            dispatch(triggerSideOrderError(`maxSingleMeals-${isoDay}`));
            return;
          }
        }
      }

      if (wholeAmount >= mealsLimit) {
        dispatch(triggerSideOrderError(`maxSingleMeals-${isoDay}`));
        return;
      }
    }

    if (maxQuantity && multiplier >= maxQuantity) {
      dispatch(triggerSideOrderError(`maxSingleSideOrder-${isoDay}`));
      return;
    }

    const [type = '', id = ''] = parseTypeAndIdFromKey(selectedSideOrderKey);

    const setState = (value: number) => {
      setSideOrdersState(prevState => ({
        ...prevState,
        [isoDay]: {
          ...(prevState[isoDay] || {}),
          [selectedSideOrderKey]: {
            defaultPrice,
            limitedByMaximumSelectedMeals,
            maxQuantity,
            minQuantity,
            multiplier: value,
            sideOrderId: { type, id },
          },
        },
      }));

      setAllSideOrdersAmount(0);
    };

    if (minQuantity && multiplier === 0) {
      setState(minQuantity);
    } else {
      setState(multiplier + 1);
    }

    dispatch(triggerSideOrderError(''));
  };

export const calculateSummary = (
  sideOrdersState: SelectedSideOrdersT,
  sideOrderKey: SideOrderIdT
): {
  days: number;
  numberOfSideOrders: number;
  priceOfSideOrders: number;
} =>
  Object.values(sideOrdersState).reduce(
    (
      acc: {
        days: number;
        numberOfSideOrders: number;
        priceOfSideOrders: number;
      },
      curr: SelectedSideOrdersT[number]
    ) => {
      const filter = Object.values(curr).filter(
        item =>
          parseKeyFromSideOrderId(item.sideOrderId) === sideOrderKey &&
          item.multiplier
      );

      if (filter.length) {
        acc.priceOfSideOrders += filter.reduce(
          (sum, li) => sum + li.defaultPrice * li.multiplier,
          0
        );
        acc.numberOfSideOrders += filter.reduce(
          (sum, li) => sum + li.multiplier,
          0
        );
        acc.days++;
      }

      return acc;
    },
    {
      days: 0,
      numberOfSideOrders: 0,
      priceOfSideOrders: 0,
    }
  );

export const declination = (
  number: number,
  one: string,
  moreThanOne: string,
  moreThanFour: string,
  withoutNumber = false
) => {
  if (withoutNumber) {
    if (number === 1) {
      return one;
    }

    if (number > 1 && number < 5) {
      return moreThanOne;
    }

    return moreThanFour;
  }

  if (number === 1) {
    return `${number} ${one}`;
  }

  if (number > 1 && number < 5) {
    return `${number} ${moreThanOne}`;
  }

  return `${number} ${moreThanFour}`;
};
