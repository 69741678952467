import {
  GetSideOrderOrCourseByIdArgsT,
  GetSideOrderOrCourseByIdReturnT,
  GetSideOrdersWithCoursesArgsT,
  GetSideOrdersWithCoursesReturnT,
  SideOrderOrCourseFromApiT,
  SideOrderOrCourseTypeE,
} from '@features/coursesAsSideOrders/types/coursesAsSideOrders.type';
import {
  GetPossibleSideOrdersResponseT,
  isOneOffSideOrder,
  SideOrderT,
} from 'typesRoot/sideOrder.type';
import { formApi } from '@services/api/form';

export const possibleSideOrdersApi = formApi.injectEndpoints({
  endpoints: build => ({
    getPossibleSideOrders: build.query<
      GetPossibleSideOrdersResponseT,
      {
        companyName: string;
        withCustomDates?: boolean;
      }
    >({
      query: ({ companyName, withCustomDates = true }) => ({
        method: 'GET',
        url: 'form/open/possible-side-orders',
        params: {
          withCustomDates,
        },
        headers: {
          'company-id': companyName,
        },
      }),
      keepUnusedDataFor: 3600,
      transformResponse: (res: Array<SideOrderT>, _, { withCustomDates }) => {
        if (!withCustomDates) {
          return {
            oneOffs: [],
            possibleSideOrders: res.filter(el => !el.deleted),
          };
        }
        return res
          .filter(el => !el.deleted)
          .reduce<GetPossibleSideOrdersResponseT>(
            (acc, curr) => {
              if (isOneOffSideOrder(curr)) {
                acc.oneOffs = [...acc.oneOffs, curr];
                return acc;
              }
              const currSideOrder = {
                brand: curr.brand,
                customDates: curr.customDates,
                defaultPrice: curr.defaultPrice,
                deleted: curr.deleted,
                deliveriesLimit: curr.deliveriesLimit,
                description: curr.description,
                imageUrl: curr.imageUrl,
                limitedByMaximumSelectedMeals:
                  curr.limitedByMaximumSelectedMeals,
                maxQuantity: curr.maxQuantity,
                minQuantity: curr.minQuantity,
                name: curr.name,
                possibleSideOrderId: curr.possibleSideOrderId,
                publiclyAvailable: curr.publiclyAvailable,
                taxValue: curr.taxValue,
              };

              acc.possibleSideOrders = [
                ...acc.possibleSideOrders,
                currSideOrder,
              ];
              return acc;
            },
            {
              oneOffs: [],
              possibleSideOrders: [],
            }
          );
      },
    }),
    getSideOrdersWithCourses: build.query<
      GetSideOrdersWithCoursesReturnT,
      GetSideOrdersWithCoursesArgsT
    >({
      query: ({ companyId }) => ({
        method: 'GET',
        url: 'form/open/order-form/steps/side-orders',
        headers: {
          'company-id': companyId,
        },
      }),
      keepUnusedDataFor: 3600,
      transformResponse: (res: {
        elements: Array<SideOrderOrCourseFromApiT>;
      }) => {
        return res.elements.map((el: SideOrderOrCourseFromApiT) => ({
          dateFrom: el.dateFrom,
          dateTo: el.dateTo,
          defaultPrice: el.defaultPrice,
          imageUrl: el.imageUrl,
          limitedByMaximumSelectedMeals: el.limitedByMaximumSelectedMeals,
          maxQuantity: el.maxQuantity,
          minQuantity: el.minQuantity,
          rateScore: el.rateScore,
          rateNumber: el.rateNumber,
          subtitle: el.subtitle,
          sideOrderId: {
            id: (el.id.type === SideOrderOrCourseTypeE.POSSIBLE_SIDE_ORDER
              ? el.id.possibleSideOrderId
              : el.id.type === SideOrderOrCourseTypeE.COURSE_AS_SIDE_ORDER
              ? el.id.courseAsSideOrderId
              : 0) as number,
            type: el.id.type,
          },
          title: el.title,
        }));
      },
    }),
    getSideOrderOrCourseById: build.query<
      GetSideOrderOrCourseByIdReturnT,
      GetSideOrderOrCourseByIdArgsT
    >({
      query: ({ companyId, sideOrderOrCourseId, type }) => ({
        method: 'GET',
        url: `form/open/order-form/steps/side-orders/${type}/${sideOrderOrCourseId}`,
        headers: {
          'company-id': companyId,
        },
      }),
      keepUnusedDataFor: 3600,
    }),
  }),
});

export const {
  useGetPossibleSideOrdersQuery,
  useGetSideOrderOrCourseByIdQuery,
  useGetSideOrdersWithCoursesQuery,
} = possibleSideOrdersApi;

export const { getPossibleSideOrders, getSideOrdersWithCourses } =
  possibleSideOrdersApi.endpoints;
