import axios from 'axios';
import {
  setModalError,
  setModalErrorLoading,
} from '../services/redux/modalError.slice';
import { Dispatch, useEffect } from 'react';
import { modalErrorPresentationE } from '../types/modalError.type';

export const useModalErrorAxiosInterceptor = (
  dispatch: Dispatch<{
    payload?: { message: string; presentation: modalErrorPresentationE };
    type: string;
  }>
) => {
  useEffect(() => {
    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        dispatch(setModalErrorLoading(false));

        if (error.response?.status === 490) {
          const { message = '', presentation } = error.response.data || {};
          presentation !== modalErrorPresentationE.Validation &&
            message !== '' &&
            dispatch(
              setModalError({
                message,
                presentation,
              })
            );
          return Promise.reject(error);
        } else if (error.response?.status === 403) {
          const { config: { url = '' } = {} } = error;
          if (
            (url.includes('form/profile/order') && !url.includes('orders')) ||
            (url.includes('form/open/order') && !url.includes('orders'))
          ) {
            dispatch(
              setModalError({
                message: 'Złożenie zamówienia nie powiodło się.',
                presentation: modalErrorPresentationE.Modal,
              })
            );
          }
          return Promise.reject(error);
        } else {
          return Promise.reject(error);
        }
      }
    );
  }, [dispatch]);
};
