import React from 'react';

type Props = {
  className?: string;
};

const ProfileIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      fill="none"
      height="160"
      viewBox="0 0 200 160"
      width="200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M127.009 23.0877L102.453 27.8465C98.831 28.5485 95.1343 27.0573 93.013 24.0385C88.8278 18.0828 79.7448 18.9729 76.7954 25.6278L65.4964 51.1231C64.5132 53.3415 63.0066 55.2882 61.1057 56.7963L29.4925 81.8779C20.3891 89.1003 25.0232 103.745 36.6242 104.417L41.4716 104.697C50.3394 105.21 54.5322 115.879 48.3863 122.293C40.7929 130.216 49.0435 142.978 59.3855 139.305L81.3517 131.506C84.9776 130.218 88.9597 130.373 92.4744 131.939L126.801 147.23C134.928 150.85 144.402 146.642 147.164 138.184L147.978 135.69C149.268 131.742 152.137 128.504 155.902 126.75L187.742 111.913C198.612 106.849 194.061 90.4672 182.137 91.7345C171.285 92.8879 165.964 78.8828 174.844 72.5397L176.883 71.0833C186.901 63.9279 179.838 48.1876 167.833 50.9136L143.966 56.3333C134.267 58.5356 127.965 46.4186 135.337 39.7422C142.588 33.175 136.613 21.2264 127.009 23.0877Z"
        fill="#06257F"
        opacity="0.1"
      />
      <path
        clipRule="evenodd"
        d="M42 22H158V138H42V22Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M42 22H158V138H42V22Z"
        fillRule="evenodd"
        stroke="#06257F"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M42 22H158V46H42V22Z"
        fill="#ECEEF8"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M42 22H158V46H42V22Z"
        fillRule="evenodd"
        stroke="#06257F"
        strokeWidth="2"
      />
      <path
        d="M122 122H76M112 114H86H112Z"
        stroke="#06257F"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M60 34C60 37.314 57.314 40 54 40C50.686 40 48 37.314 48 34C48 30.686 50.686 28 54 28C57.314 28 60 30.686 60 34Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M60 34C60 37.314 57.314 40 54 40C50.686 40 48 37.314 48 34C48 30.686 50.686 28 54 28C57.314 28 60 30.686 60 34Z"
        fillRule="evenodd"
        stroke="#06257F"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M78 34C78 37.314 75.314 40 72 40C68.686 40 66 37.314 66 34C66 30.686 68.686 28 72 28C75.314 28 78 30.686 78 34Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M78 34C78 37.314 75.314 40 72 40C68.686 40 66 37.314 66 34C66 30.686 68.686 28 72 28C75.314 28 78 30.686 78 34Z"
        fillRule="evenodd"
        stroke="#06257F"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M96 34C96 37.314 93.314 40 90 40C86.686 40 84 37.314 84 34C84 30.686 86.686 28 90 28C93.314 28 96 30.686 96 34Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M96 34C96 37.314 93.314 40 90 40C86.686 40 84 37.314 84 34C84 30.686 86.686 28 90 28C93.314 28 96 30.686 96 34Z"
        fillRule="evenodd"
        stroke="#06257F"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M124 80C124 93.254 113.254 104 100 104C86.746 104 76 93.254 76 80C76 66.746 86.746 56 100 56C113.254 56 124 66.746 124 80Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M124 80C124 93.254 113.254 104 100 104C86.746 104 76 93.254 76 80C76 66.746 86.746 56 100 56C113.254 56 124 66.746 124 80Z"
        fill="white"
        fillRule="evenodd"
        stroke="#FF5226"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M105.863 95.1339C104.969 94.6878 104.403 93.7719 104.403 92.7719V90.0558C104.599 89.8358 104.821 89.5498 105.059 89.2138C106.003 87.8818 106.715 86.4158 107.211 84.8778C108.097 84.6038 108.753 83.7838 108.753 82.8098V79.9098C108.753 79.2718 108.469 78.7018 108.027 78.3038V74.1138C108.027 74.1138 108.889 67.5898 100.055 67.5898C91.2233 67.5898 92.0833 74.1138 92.0833 74.1138V78.3038C91.6433 78.7018 91.3593 79.2718 91.3593 79.9098V82.8098C91.3593 83.5738 91.7613 84.2458 92.3613 84.6338C93.0853 87.7878 94.9833 90.0558 94.9833 90.0558V92.7058C94.9833 93.6718 94.4553 94.5598 93.6073 95.0238L87.1413 98.5499C86.6993 98.7919 86.3053 99.0918 85.9473 99.4278C89.9013 102.292 94.7453 104 99.9993 104C105.245 104 110.081 102.298 114.031 99.4438C113.657 99.1138 113.243 98.8238 112.785 98.5958L105.863 95.1339Z"
        fill="#FFEBE5"
        fillRule="evenodd"
        stroke="#FF5226"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default ProfileIcon;
