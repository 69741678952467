import { sendUserComEvent, USER_COM_EVENT, USER_COM_TYPE } from '../api';
import { BasketItemT } from 'typesRoot/basketItem.type';
import { UserComT } from '../utilis/userCom.type';

export const userComCheckoutProductEvent = (
  items: BasketItemT[],
  userCom: UserComT
) => {
  if (!items.length || !userCom) {
    return;
  }

  items.forEach((item: BasketItemT) => {
    const data = {
      payload: {
        product_id: item?.selectedDiet.dietId,
        product_name: item?.selectedDiet.dietName,
        URL: window.location.origin,
        event_type: USER_COM_EVENT.CHECKOUT,
      }
    };
    sendUserComEvent({type: USER_COM_TYPE.PRODUCT_EVENT, data})
  });
};
