// @ts-nocheck
import React, { useRef } from 'react';
import { useClickAway } from 'react-use';
import Modal from 'react-modal';
import CloseIcon from '../../assets/icons/CloseIcon';
import useIsWide from '../../hooks/useIsWide';

type Props = {
  children: React.ReactNode;
  childrenContainerClass?: string;
  className?: string;
  enableClickAway?: boolean;
  isModalOpen: boolean;
  showHeader?: boolean;
  onRequestClose?: () => void;
  overlayClassName?: string;
  setIsModalOpen: (key: boolean) => void;
  headerTitle?: string;
};

const CustomModalTS = ({
  children,
  childrenContainerClass,
  className,
  enableClickAway,
  headerTitle,
  isModalOpen,
  onRequestClose,
  overlayClassName,
  setIsModalOpen,
  showHeader,
}: Props) => {
  const isWide = useIsWide('max', 'desktop');

  const ref = useRef(null);

  useClickAway(ref, () => !isWide && enableClickAway && setIsModalOpen(false));

  return (
    <Modal
      className={`custom-modal-ts ${className ? className : ''}`}
      closeTimeoutMS={300}
      isOpen={isModalOpen}
      onRequestClose={() => onRequestClose && onRequestClose()}
      overlayClassName={`custom-modal-ts__overlay ${
        overlayClassName ? overlayClassName : ''
      }`}
    >
      <div className="custom-modal-ts__container" ref={ref}>
        {showHeader && (
          <div className="custom-modal-ts__header">
            <h3>{headerTitle}</h3>
            <div
              className="custom-modal-ts__close"
              onClick={() => setIsModalOpen(false)}
              onKeyDown={() => setIsModalOpen(false)}
              role="button"
              tabIndex={0}
            >
              <CloseIcon />
            </div>
          </div>
        )}

        <div
          className={`custom-modal-ts__ref ${
            childrenContainerClass ? childrenContainerClass : ''
          }`}
        >
          {children}
        </div>
      </div>
    </Modal>
  );
};

export default CustomModalTS;
