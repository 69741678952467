import { getServerConfig } from '../../../../../features/general/generalSlice';
import { useGetApiParamsQuery } from '../../../../../services/api/form/open/open.api';
import CustomModal from '../../../../common/CustomModal';
import DietDescriptionAndMenuModalRating from './DietDescriptionAndMenuModalRating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../../../common/Button';
import { Menu } from '../menu/Menu';
import { customFind, findDietTagImg } from '../../../../../utils';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { PriceCategory } from '../../../../../@types/cateringCityDetails.type';
import { DietT } from '../../../../../@types/menu.type';
import { useAppSelector } from '../../../../../config/hooks';
import { useState } from 'react';
import RatesExplanation from '../../../../../features/ratesExplanation/views/RatesExplanation';
import { useGetDietTagInfoAllQuery } from '../../../../../services/api/form/open/dietTagInfo.api';

type Props = {
  diets: DietT[];
  isOpen: boolean;
  onClick: () => void;
  onRequestClose: () => void;
  selectedDiet: DietT | undefined;
  priceCategory?: PriceCategory;
};

const DietDescriptionAndMenuModal = ({
  diets,
  isOpen,
  onClick,
  onRequestClose,
  priceCategory,
  selectedDiet,
}: Props) => {
  const { data: dietTagsData } = useGetDietTagInfoAllQuery();

  const { companyId } = useAppSelector(getServerConfig);
  const { data: params } = useGetApiParamsQuery(companyId, {
    skip: !companyId,
  });

  const [isRatesExplanationVisible, setIsRatesExplanationVisible] =
    useState(false);

  const {
    averageFeedback,
    averageRate,
    companyName,
    dietImageUrl,
    dietName,
    dietTag,
    feedbacksNumber,
    formDescription,
  } = selectedDiet || {};

  return (
    <CustomModal
      className={`
        diet-description-and-menu-modal ${
          isRatesExplanationVisible
            ? 'diet-description-and-menu-modal--rates-explanation'
            : ''
        }
      `}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="diet-description-and-menu-modal--overlay"
    >
      {isRatesExplanationVisible ? (
        <RatesExplanation
          setIsRatesExplanationVisible={setIsRatesExplanationVisible}
        />
      ) : (
        <>
          <Button
            colorClean
            containerClass="diet-description-and-menu-modal__modal-close"
            label={<FontAwesomeIcon icon={faTimes} />}
            onClick={onRequestClose}
          />
          <header className="diet-description-and-menu-modal__header">
            <div className="image-wrapper">
              <img
                alt={dietName}
                src={dietImageUrl || findDietTagImg(dietTag, dietTagsData)}
              />
            </div>
            <div>
              <h2 className="diet-description-and-menu-modal__title">
                {dietName}
              </h2>
              {params?.visibleInDietly && (
                <>
                  <DietDescriptionAndMenuModalRating
                    averageRatePercentile={averageRate}
                    numberOfRates={feedbacksNumber}
                    priceCategory={priceCategory}
                    starRating={averageFeedback}
                  />
                  <Button
                    buttonLink
                    containerClass="spacer-top-8"
                    icon={<FontAwesomeIcon icon={faArrowRight} />}
                    iconRight
                    label="Jak działają opinie?"
                    onClick={() => {
                      setIsRatesExplanationVisible(true);
                    }}
                  />
                </>
              )}
            </div>
          </header>
          <main className="diet-description-and-menu-modal__main">
            <Menu
              companyDietDetails={diets}
              companyName={companyName}
              companyParams={{ menuVisibleInDietly: true }}
              customTitle="Menu na najbliższy tydzień"
              hideSelect
              specificDiet={selectedDiet}
            />
            <section className="description">
              <h3 className="label-l spacer-bottom-8">Opis diety</h3>
              <p className="body-m">
                {formDescription ||
                  customFind('dietTagId', dietTag)(dietTagsData)
                    ?.dietDescriptions?.[0]?.description}
              </p>
            </section>
          </main>
          <footer className="diet-description-and-menu-modal__footer">
            <Button blockButton colorBlank label="Wybierz" onClick={onClick} />
          </footer>
        </>
      )}
    </CustomModal>
  );
};

export default DietDescriptionAndMenuModal;
