import React from 'react';
import Button from '../../../../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import {
  changeCalculatorStep,
  changeCalculatorValue,
} from '../../../../../../features/dietCalculator/dietCalculatorSlice';
import { useDispatch } from 'react-redux';
import { classNamesUtil } from '../../../../../../utils/classNamesUtil.util';

const DietCalculatorStepThird = ({ physicalActivity, step }) => {
  const dispatch = useDispatch();

  const handleOnClick = value => {
    dispatch(changeCalculatorValue({ name: 'physicalActivity', value }));
    dispatch(changeCalculatorStep(4));
  };

  return (
    <>
      <div className="calculator-modal__mobile-padding">
        <div>
          <h2 className="h300">Jaka jest twoja aktywność fizyczna?</h2>
          <p className="body-m spacer-top-16 spacer-bottom-14">
            Ta informacja jest potrzebna do wyliczenia całkowitej przemiany
            materii, czyli ilość kalorii pokrywająca całkowite, dobowe
            zapotrzebowanie energetyczne organizmu.
          </p>
        </div>
        <p className="label-m">Wybierz przedział</p>
        <div
          className={classNamesUtil('calculator-modal__card-checkbox', {
            'calculator-modal__card-checkbox--active': physicalActivity === 0,
          })}
          onClick={() => {
            handleOnClick(0);
          }}
          onKeyDown={() => {
            handleOnClick(0);
          }}
          role="button"
          tabIndex={0}
        >
          <div className="flex-1">
            <p className="calculator-modal__card-checkbox-title label-m">
              Brak aktywności
            </p>
            <p className="body-s">Siedzący tryb życia</p>
          </div>
        </div>
        <div
          className={classNamesUtil('calculator-modal__card-checkbox', {
            'calculator-modal__card-checkbox--active': physicalActivity === 33,
          })}
          onClick={() => {
            handleOnClick(33);
          }}
          onKeyDown={() => {
            handleOnClick(33);
          }}
          role="button"
          tabIndex={0}
        >
          <div className="flex-1">
            <p className="calculator-modal__card-checkbox-title label-m">
              Mała aktywność
            </p>
            <p className="body-s">Aktywny tryb życia, sporadyczne ćwiczenia</p>
          </div>
        </div>
        <div
          className={classNamesUtil('calculator-modal__card-checkbox', {
            'calculator-modal__card-checkbox--active': physicalActivity === 66,
          })}
          onClick={() => {
            handleOnClick(66);
          }}
          onKeyDown={() => {
            handleOnClick(66);
          }}
          role="button"
          tabIndex={0}
        >
          <div className="flex-1">
            <p className="calculator-modal__card-checkbox-title label-m">
              Średnia aktywność
            </p>
            <p className="body-s">Dużo ruchu, 2-3 treningi w tygodniu</p>
          </div>
        </div>
        <div
          className={classNamesUtil('calculator-modal__card-checkbox', {
            'calculator-modal__card-checkbox--active': physicalActivity === 100,
          })}
          onClick={() => {
            handleOnClick(100);
          }}
          onKeyDown={() => {
            handleOnClick(100);
          }}
          role="button"
          tabIndex={0}
        >
          <div className="flex-1">
            <p className="calculator-modal__card-checkbox-title label-m">
              Duża aktywność
            </p>
            <p className="body-s">Praca fizyczna, codzienne treningi</p>
          </div>
        </div>
      </div>
      <div className="calculator-modal__footer-modal calculator-modal__footer-modal--space-between">
        <Button
          colorBlank
          icon={<FontAwesomeIcon icon={faArrowLeft} />}
          label="Wstecz"
          onClick={() => {
            dispatch(changeCalculatorStep(2));
          }}
        />
        <span className="label-s color-gray-900">
          {step}/<span className="color-primary">5</span>
        </span>
        <Button
          disabled={physicalActivity === ''}
          icon={<FontAwesomeIcon icon={faArrowRight} />}
          iconRight
          label="Dalej"
          onClick={() => {
            dispatch(changeCalculatorStep(4));
          }}
        />
      </div>
    </>
  );
};

export default DietCalculatorStepThird;
