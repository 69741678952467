import { getOrderReducer } from '@features/orderForm/common/services/redux/commonOrderFormSelectors';
import {
  BaseDeliveryMealT,
  CustomDeliveryMealsT,
} from '@features/orderForm/stepMenuConfiguration/types/menuConf.type';
import { prepareCustomDeliveryMeals } from '@features/orderForm/stepMenuConfiguration/utils/menuConf.utils';
import { getSelectedSideOrders } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.selector';
import { SelectedSideOrdersT } from '@features/orderForm/stepSelectSideOrders/services/redux/stepSelectSideOrders.slice';
import {
  getSelectedDays,
  getSelectedMeals,
} from '@redux/selectors/orderFormSelector';
import { createSelector } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { RootState } from '@config/hooks';
import { orderFormSteps } from '@services/api/form/open/order-form/steps/orderFormSteps.api';

export const getStepMenuConfigurationSlice = (state: RootState) =>
  getOrderReducer(state).stepMenuConfiguration;
export const getMenuConfActiveDay = (state: RootState): string =>
  getStepMenuConfigurationSlice(state).activeDay;
export const getMenuConfBaseDeliveryMeals = (state: RootState) =>
  getStepMenuConfigurationSlice(state).baseDeliveryMeals;
export const getMenuConfCustomDeliveryMeals = (state: RootState) =>
  getStepMenuConfigurationSlice(state).customDeliveryMeals;
export const getMenuConfShouldUpdateData = (state: RootState) =>
  getStepMenuConfigurationSlice(state).shouldUpdateData;
export const getMenuConfShouldRefetchMeals = (state: RootState) =>
  getStepMenuConfigurationSlice(state).shouldRefetchMeals;
export const getMenuConfLoadingCarouselId = (state: RootState) =>
  getStepMenuConfigurationSlice(state).loadingCarouselId;

export const getMenuConfSelectedMealsAmountPerDay = createSelector(
  [getMenuConfActiveDay, getMenuConfCustomDeliveryMeals],
  (activeDay, customDeliveryMeals) => {
    if (
      !activeDay ||
      !customDeliveryMeals ||
      !(activeDay in customDeliveryMeals)
    ) {
      return 0;
    }
    return Object.keys(customDeliveryMeals[activeDay] || {}).length;
  }
);

export const getMenuConfDietCaloriesMealIdsToRequest = createSelector(
  [
    getMenuConfActiveDay,
    getMenuConfCustomDeliveryMeals,
    getMenuConfBaseDeliveryMeals,
  ],
  (activeDay, customDeliveryMeals, baseDeliveryMeals) => {
    if (
      !activeDay ||
      !customDeliveryMeals ||
      !(activeDay in customDeliveryMeals)
    ) {
      return [];
    }
    const selectedDietCaloriesMealIds = Object.values(
      customDeliveryMeals[activeDay] || {}
    ).map(
      el =>
        el &&
        typeof el === 'object' &&
        'dietCaloriesMealId' in el &&
        el.dietCaloriesMealId
    ) as Array<number>;
    const deletedMeals = baseDeliveryMeals.filter(
      (meal: BaseDeliveryMealT) => meal.deleted
    );
    if (deletedMeals.length === 0) {
      return selectedDietCaloriesMealIds;
    }
    const deletedDietCaloriesMealIds = deletedMeals.map(
      (meal: BaseDeliveryMealT) => meal.dietCaloriesMealId
    );
    // Biorę tu idki posiłków wybranych oraz (tylko jeśli posiłek jest usunięty) bazowych
    return [...selectedDietCaloriesMealIds, ...deletedDietCaloriesMealIds];
  }
);

export const getMenuConfigurationMealsPerDayState = (
  activeDay: string,
  companyId: string
) =>
  createSelector(
    (state: RootState) =>
      // Skorzystano z serializeQueryArgs, dlatego IDE wskazuje błąd, ale na podstawie tego wątku można go zignorować: https://github.com/reduxjs/redux-toolkit/issues/3263#issuecomment-1467695736
      // @ts-expect-error
      orderFormSteps.endpoints.getMenuConfigurationMealsPerDay.select({
        companyId,
        date: `${activeDay}`,
      })(state),
    getInfoState => getInfoState
  );

export const getCalculateItemPriceCustomDeliveryMeals = createSelector(
  [getMenuConfCustomDeliveryMeals],
  customDeliveryMeals => prepareCustomDeliveryMeals(customDeliveryMeals)
);

export const getHasMenuConfigurationStarted = createSelector(
  [getMenuConfCustomDeliveryMeals],
  customDeliveryMeals =>
    customDeliveryMeals && Object.keys(customDeliveryMeals).length > 0
);

export const getDeliveryMealCounts = createSelector(
  [getSelectedDays, getMenuConfCustomDeliveryMeals, getSelectedMeals],
  (
    selectedDays: Array<number>,
    customDeliveryMeals: CustomDeliveryMealsT,
    selectedMeals: Array<{
      selected: boolean;
    }>
  ) =>
    selectedDays.reduce((acc: Record<string, number>, curr: number) => {
      const isoDate = DateTime.fromMillis(curr).toISODate() || '';
      const customDeliveryMealsDate = customDeliveryMeals[isoDate];

      acc[isoDate] = customDeliveryMealsDate
        ? Object.keys(customDeliveryMealsDate || {}).length
        : selectedMeals.filter(item => item.selected).length;

      return acc;
    }, {})
);

export const getSelectedMealsWithSideOrdersAmountForActiveDay = createSelector(
  [
    getMenuConfSelectedMealsAmountPerDay,
    getMenuConfActiveDay,
    getSelectedSideOrders,
  ],
  (
    deliveryMealsAmount: number,
    activeDay: string,
    selectedSideOrders: SelectedSideOrdersT
  ) => {
    const selectedSideOrderForActiveDay = selectedSideOrders[activeDay] || {};
    const sideOrdersAmount = Object.values(
      selectedSideOrderForActiveDay
    ).reduce<number>((acc, curr) => {
      if (curr.limitedByMaximumSelectedMeals) {
        acc = acc + curr.multiplier;
      }
      return acc;
    }, 0);
    return deliveryMealsAmount + sideOrdersAmount;
  }
);
