import { Dispatch } from 'redux';
import { checkIfUserComIsEnabled } from '@userCom/utilis/checkIfUserComIsEnabled';
import { userComOrderEvent } from '@userCom/events/order';
import { userComUpdateUserEvent } from '@userCom/users/update';
import { purchaseGA4 } from '../../../GA4/events/ecommerceEvents';
import { clearBasketLocalStorage } from 'utilsRoot/index';
import {
  resetOrderForm,
  restoreInitialOrderForm,
} from '@redux/actions/orderFormActions';
import { storePaymentUrl } from '@redux/actions/profileActions';
import { checkIsSalesManagoEnabled } from '@salesManago/helpers';
import { salesManagoRegisterEvent } from '@salesManago/events/register';
import {
  getIsKuchniaVikinga,
  getServerConfig,
} from '../../../general/generalSlice';
import { RootState } from '@config/hooks';
import { getBasketReducer } from '@redux/selectors/orderFormSelector';
import { getSummaryReducer } from '@redux/selectors/summaryReducer.selectors';
import { getProfileDetails } from '@redux/selectors/profileSelector';
import { OrderResponseT } from '../types/stepSummary.type';
import { formApi } from '@services/api/form';
import { CalculatePriceT } from 'typesRoot/calculatePrice.type';
import { PurchaseGA4ArgsT } from '@features/GA4/types/GA4.type';
import { generateTransactionId } from '@components/pages/StepSummary/utils/transactionId.util';

const setLoyaltyProgramPointsSessionStorage = (data: {
  appliedLoyaltyProgramPoints: number;
  appliedLoyaltyProgramPointsGlobal: number;
  loyaltyProgramPointsBalance: number;
  loyaltyProgramPointsGlobalBalance: number;
  companyName: string;
}) => {
  sessionStorage.setItem('loyaltyProgramPoints', JSON.stringify(data));
};

export const successOrderCallback =
  (
    orderType: 'guest' | 'profile',
    response: OrderResponseT & { signUp: boolean },
    navigate: any,
    sendGTMTransactionEvents: (
      newOrderIds: Array<string>,
      previousOrdersNumber: number,
      clientId: number,
      shoppingCartId: number,
      clientLifecycleState: string,
      email: string,
      crm_transaction_id?: string
    ) => void,
    prices: CalculatePriceT
  ) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    // @ts-ignore
    const companyName = formApi.endpoints.getApiFormSettings.select()(
      getState()
    )?.data?.generalInformation?.cateringName;

    const companyId =
      getState()?.general?.config?.server?.companyId || companyName || '';

    const config = getServerConfig(getState()) || {};
    const basketReducer = getBasketReducer(getState()) || {};
    const summaryReducer = getSummaryReducer(getState()) || {};
    const profile = getProfileDetails(getState()) || {};
    const isKuchniaVikinga = getIsKuchniaVikinga(getState());

    const { enhancedEcommerce, salesManago, serverUrl, userCom } = config;
    const { basket, promoCode: { code = '' } = {} } = basketReducer || {};
    const {
      methodOfPurchase,
      newAddressDetails: { city = {}, street = '', zipCode = {} } = {},
      newClientDetails: { name, surname },
      paymentType,
    } = summaryReducer || {};

    const {
      cart: { totalCostToPay = 0 },
    } = prices || {};

    sessionStorage.removeItem('zero-cost-form');

    const {
      clientId,
      clientLifecycleState,
      loyaltyProgramSummary,
      migratedAddresses,
      orders,
      paymentUrl,
      previousOrdersNumber,
      signUp,
    } = response;

    if (loyaltyProgramSummary) {
      setLoyaltyProgramPointsSessionStorage({
        appliedLoyaltyProgramPoints:
          loyaltyProgramSummary.appliedLoyaltyProgramPoints,
        appliedLoyaltyProgramPointsGlobal:
          loyaltyProgramSummary.appliedLoyaltyProgramPointsGlobal,
        loyaltyProgramPointsBalance:
          loyaltyProgramSummary.loyaltyProgramPointsBalance,
        loyaltyProgramPointsGlobalBalance:
          loyaltyProgramSummary.loyaltyProgramPointsGlobalBalance,
        companyName,
      });
    }

    const {
      client: {
        allowMarketingMessages = false,
        email = '',
        phoneNumber = '',
        taxId = '',
      } = {},
    } = migratedAddresses[0] || {};

    if (checkIfUserComIsEnabled(userCom)) {
      try {
        userComOrderEvent(basket, code, city, response, userCom, prices);
        userComUpdateUserEvent(
          userCom,
          {
            allowMarketingMessages,
            email,
            phoneNumber,
            taxId,
            name: profile?.firstName || name,
            surname: profile?.lastName || surname,
          },
          methodOfPurchase,
          code
        );
      } catch (e) {
        console.log('error message:', e);
      }
    }

    const newOrderIds = orders.map((item: { orderId: string }) => item.orderId);
    const shoppingCartId = orders[0]?.shoppingCartId || 0;
    let purchaseGA4ArgsList: PurchaseGA4ArgsT = {
      basket,
      city,
      totalPrice: totalCostToPay,
      coupon: code,
      shoppingCartId,
      clientLifecycleState,
      email,
      clientId,
      previousOrdersNumber,
      loggedIn: orderType === 'profile',
      typeOfPayment: paymentType,
      phone_number: phoneNumber,
    };

    const crm_transaction_id = isKuchniaVikinga
      ? generateTransactionId(response, companyId)
      : undefined;

    if (isKuchniaVikinga) {
      purchaseGA4ArgsList = {
        ...purchaseGA4ArgsList,
        crm_transaction_id,
        first_name: name,
        last_name: surname,
        street,
        postal_code: zipCode.code,
      };
    }

    sendGTMTransactionEvents(
      newOrderIds,
      previousOrdersNumber,
      clientId,
      shoppingCartId,
      clientLifecycleState,
      email,
      crm_transaction_id
    );

    if (enhancedEcommerce) {
      purchaseGA4(purchaseGA4ArgsList);
    }

    if (
      orderType === 'profile' &&
      checkIsSalesManagoEnabled(salesManago) &&
      signUp
    ) {
      salesManagoRegisterEvent(
        {
          allowMarketingMessages,
          firstName: profile?.firstName || name,
          lastName: profile?.lastName || surname,
          phoneNumber,
          companyName,
          email,
        },
        salesManago,
        serverUrl
      ).catch(e => {
        console.log(e, 'e');
      });
    }

    clearBasketLocalStorage();
    dispatch(resetOrderForm());
    dispatch(restoreInitialOrderForm());
    sessionStorage.setItem('order-id-form', JSON.stringify(orders[0]?.orderId));
    dispatch(storePaymentUrl(paymentUrl?.paymentUrl));

    if (paymentType === 'INSTALLMENTS') {
      // @ts-ignore
      return document.forms['lbl-loan-form'].submit();
    }

    if (
      paymentType === 'BANK_TRANSFER' ||
      paymentType === 'CASH' ||
      paymentUrl?.errorMessage === 'ZERO_COST' ||
      paymentUrl?.errorMessage === 'ALREADY_PAID'
    ) {
      if (
        paymentUrl?.errorMessage === 'ZERO_COST' ||
        paymentUrl?.errorMessage === 'ALREADY_PAID'
      ) {
        sessionStorage?.setItem('zero-cost-form', paymentUrl?.errorMessage);
      }
      navigate('/zaplacono', { replace: true });
    }
  };
