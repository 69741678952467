import React from 'react';
import { getServerConfig } from '../../features/general/generalSlice';
import { useGetApiFormSettingsQuery } from '../../services/api/form/open/open.api';
import { useAppSelector } from '../../config/hooks';

const Navigation = () => {
  const { companyId, webPageUrl } = useAppSelector(getServerConfig);

  const { data: { generalInformation } = {} } = useGetApiFormSettingsQuery(
    undefined,
    {
      skip: !companyId,
    }
  );

  const { cateringName, logo } = generalInformation || {};

  return (
    <div className="navigation">
      <div className="container navigation__container">
        <div className="navigation__logo">
          <a href={webPageUrl} rel="noreferrer" target="_blank">
            <img alt={cateringName} src={logo} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
