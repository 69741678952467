import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  getLoyaltyPoints,
  getPromoCode,
} from '@redux/selectors/basketSelector';
import { getProfile } from '@redux/selectors/profileSelector';
import {
  cancelLoyaltyPoints,
  setUsedLoyaltyPoints,
} from '@redux/actions/basketActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronCircleDown,
  faChevronCircleUp,
} from '@fortawesome/pro-light-svg-icons';
import { faCheckCircle, faTimes } from '@fortawesome/pro-regular-svg-icons';
import PiggyBankIcon from '../../../../assets/icons/piggyBankIcon';
import { numberWithSpaces } from 'utilsRoot/index';
import Button from '../../../common/Button';
import {
  redirectToLoginPage,
  savePromoCodeInTheLocalStorage,
} from '../../StepSummary/utils/thirdStepHelpers.utils';
import {
  getIsCustomLoyaltyProgram,
  getIsThirdCookiesEnabled,
  getServerConfig,
} from '@features/general/generalSlice';
import {
  burakLoyaltyProgramData,
  defaultLoyaltyProgramData,
} from '@data/loyaltyProgramData';
import { BurakProgramSmallIcon } from '@assets/icons/BurakCoin/BurakProgramSmallIcon';
import { useAppDispatch, useAppSelector } from '@config/hooks';

export const LoyaltyPoints = ({ finalSum }) => {
  const dispatch = useAppDispatch();
  const { usedLoyaltyPoints } = useAppSelector(getLoyaltyPoints);
  const { loyaltyPoints: { loyaltyPointsSum } = {}, profile } =
    useAppSelector(getProfile);
  const promoCode = useAppSelector(getPromoCode);
  const isThirdCookiesEnabled = useAppSelector(getIsThirdCookiesEnabled);
  const config = useAppSelector(getServerConfig);
  const isCustomLoyaltyProgram = useAppSelector(getIsCustomLoyaltyProgram);

  const loyaltyProgramData = isCustomLoyaltyProgram
    ? burakLoyaltyProgramData.loyaltyPoints
    : defaultLoyaltyProgramData.loyaltyPoints;

  const isRequestedPointsMoreThanAvailable = points => {
    const parsedPoints = points && parseInt(points, 10);
    return parsedPoints > loyaltyPointsSum;
  };

  const storeLoyaltyPoints = points => {
    const parsedPoints = points?.toFixed(0);
    if (points > loyaltyPointsSum) {
      return dispatch(setUsedLoyaltyPoints(parsedPoints));
    }
    if (finalSum * 100 <= points) {
      return dispatch(setUsedLoyaltyPoints((finalSum * 100).toFixed(0)));
    }
    return dispatch(setUsedLoyaltyPoints(parsedPoints));
  };

  const preventChangeInputValueOnScroll = e => {
    e.target.blur();
    e.stopPropagation();
  };

  const [isLoyaltyPointsOpen, toggleLoyaltyPoints] = useState(false);
  const [loyaltyPointsError, setLoyaltyPointsError] = useState(false);
  const [inputPoints, handleLoyaltyPoints] = useState('');

  if (!profile?.firstName) {
    return (
      <div className="display-flex align-items-center">
        <div className="spacer-right-20 fill-piggy-bank">
          {isCustomLoyaltyProgram ? (
            BurakProgramSmallIcon
          ) : (
            <PiggyBankIcon className="piggy-bank-icon" />
          )}
        </div>
        <div>
          <p className="body-m color-gray-800">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className="color-primary font-weight-500 cursor-pointer"
              onClick={async () => {
                await redirectToLoginPage(
                  config,
                  isThirdCookiesEnabled,
                );
                savePromoCodeInTheLocalStorage(promoCode);
              }}
            >
              Zaloguj się
            </a>
            , aby obniżyć cenę korzystając z {loyaltyProgramData.text1}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="display-flex align-items-center">
        <div className="spacer-right-20">
          {isCustomLoyaltyProgram ? (
            BurakProgramSmallIcon
          ) : (
            <PiggyBankIcon className="piggy-bank-icon" />
          )}
        </div>
        <div>
          <div className="label-m">
            {usedLoyaltyPoints && loyaltyPointsSum - usedLoyaltyPoints > 0
              ? loyaltyProgramData.text2
              : loyaltyProgramData.text3}{' '}
          </div>

          <span className="h200 color-primary spacer-right-2">
            {loyaltyPointsSum - usedLoyaltyPoints >= 0
              ? numberWithSpaces(loyaltyPointsSum - usedLoyaltyPoints)
              : numberWithSpaces(loyaltyPointsSum)}
          </span>
          <span className="h300">
            <span className="points-color">{loyaltyProgramData.text4}</span>
          </span>
        </div>
      </div>

      <div>
        <div
          className="display-flex justify-content-space-between align-items-center spacer-top-16 cursor-pointer"
          onClick={() => toggleLoyaltyPoints(!isLoyaltyPointsOpen)}
          onKeyDown={() => toggleLoyaltyPoints(!isLoyaltyPointsOpen)}
          role="button"
          tabIndex={0}
        >
          {usedLoyaltyPoints && !loyaltyPointsError ? (
            <div className="display-flex align-items-center color-success label-s">
              <FontAwesomeIcon
                className="font-size-20 spacer-right-10"
                icon={faCheckCircle}
              />
              <div>
                {usedLoyaltyPoints} {loyaltyProgramData.text5} zostało
                wykorzystane
              </div>
            </div>
          ) : (
            <div className="body-m color-gray-800">
              Skorzystaj z {loyaltyProgramData.text5}
            </div>
          )}
          {isLoyaltyPointsOpen ? (
            <FontAwesomeIcon
              className="font-size-20 color-gray-400"
              icon={faChevronCircleUp}
            />
          ) : (
            <FontAwesomeIcon
              className="font-size-20 color-gray-400"
              icon={faChevronCircleDown}
            />
          )}
        </div>
        <div>
          {(() => {
            if (isLoyaltyPointsOpen) {
              if (usedLoyaltyPoints && !loyaltyPointsError) {
                return (
                  <div
                    className="color-error label-s spacer-top-16 cursor-pointer"
                    onClick={() => {
                      dispatch(cancelLoyaltyPoints());
                      handleLoyaltyPoints('');
                    }}
                    onKeyDown={() => {
                      dispatch(cancelLoyaltyPoints());
                      handleLoyaltyPoints('');
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <FontAwesomeIcon
                      className="spacer-right-10"
                      icon={faTimes}
                    />
                    Zmień ilość {loyaltyProgramData.text5}
                  </div>
                );
              }
              return (
                <div className="spacer-top-16">
                  <div className="form-input">
                    <input
                      className={
                        usedLoyaltyPoints ? 'code-applied' : 'code-not-applied'
                      }
                      disabled={!loyaltyPointsSum}
                      min="0"
                      onChange={({ target: { valueAsNumber } }) => {
                        setLoyaltyPointsError(false);
                        return handleLoyaltyPoints(Math.abs(valueAsNumber));
                      }}
                      onKeyDown={e => {
                        const { key } = e;
                        if (['e', 'E', '+', '-', ',', '.'].includes(key)) {
                          e.preventDefault();
                        }
                        if (key === 'Enter') {
                          if (inputPoints) {
                            if (
                              isRequestedPointsMoreThanAvailable(inputPoints)
                            ) {
                              return setLoyaltyPointsError(true);
                            }
                            toggleLoyaltyPoints(!isLoyaltyPointsOpen);
                            return storeLoyaltyPoints(
                              parseInt(inputPoints, 10)
                            );
                          }
                          return null;
                        }
                        return null;
                      }}
                      onWheel={preventChangeInputValueOnScroll}
                      placeholder="Podaj liczbę punktów"
                      size={20}
                      type="number"
                      value={inputPoints}
                    />
                  </div>
                  <div className="spacer-top-4 body-s color-gray-500">
                    Każde{' '}
                    <span className="label-s">
                      100 {loyaltyProgramData.text5}
                    </span>{' '}
                    to <span className="label-s">1 zł</span> w zamówieniu
                  </div>
                  {!!inputPoints && (
                    <>
                      <Button
                        blockButton
                        containerClass="spacer-top-16"
                        disabled={!inputPoints}
                        label={`Użyj ${loyaltyProgramData.text5}`}
                        onClick={() => {
                          if (inputPoints) {
                            if (
                              isRequestedPointsMoreThanAvailable(inputPoints)
                            ) {
                              return setLoyaltyPointsError(true);
                            }
                            toggleLoyaltyPoints(!isLoyaltyPointsOpen);
                            return storeLoyaltyPoints(
                              parseInt(inputPoints, 10)
                            );
                          }
                          return null;
                        }}
                        sizeMedium
                      />
                      {loyaltyPointsError && (
                        <div className="color-error label-s spacer-top-16">
                          <FontAwesomeIcon
                            className="spacer-right-10"
                            icon={faTimes}
                          />
                          {loyaltyProgramData.text6}
                        </div>
                      )}
                    </>
                  )}
                </div>
              );
            }
            return null;
          })()}
        </div>
      </div>
    </div>
  );
};

LoyaltyPoints.propTypes = {
  finalSum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
