import React from 'react';
import BoxStep from '../../../common/BoxStep';
import OrderIcon from '../../../../assets/icons/orderIcon';
import { Link } from 'react-router-dom';
import Button from '../../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

const EmptyBasket = () => {
  return (
    <div className="steps-page">
      <div className="steps-page__container">
        <BoxStep>
          <div className="steps-page-basket__empty-basket">
            <div className="steps-page-basket__empty-basket-icon">
              <OrderIcon className="order-icon-color" />
            </div>
            <div>
              <p className="steps-page-basket__empty-basket-text">
                Wygląda na to, że nie masz żadnej diety w koszyku
              </p>
              <Link to="/">
                <div className="steps-page-basket__empty-basket-button">
                  <Button
                    icon={
                      <FontAwesomeIcon className="font-size-17" icon={faPlus} />
                    }
                    label="Dodaj pierwszą dietę"
                    sizeMedium
                  />
                </div>
              </Link>
            </div>
          </div>
        </BoxStep>
      </div>
    </div>
  );
};

export default EmptyBasket;
