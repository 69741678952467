import {
  getMenuConfActiveDay,
  getMenuConfBaseDeliveryMeals,
  getMenuConfCustomDeliveryMeals,
  getMenuConfLoadingCarouselId,
  getSelectedMealsWithSideOrdersAmountForActiveDay,
} from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.selector';
import {
  addCustomDeliveryMeal,
  updateMealsData,
} from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.slice';
import {
  BaseDeliveryMealT,
  MenuConfigurationSettingsT,
  MenuConfMealT,
} from '@features/orderForm/stepMenuConfiguration/types/menuConf.type';
import { prepareAddCustomDeliveryMeals } from '@features/orderForm/stepMenuConfiguration/utils/menuConf.utils';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '@config/hooks';
import EmptyStateMealIcon from '@assets/icons/EmptyStateMealIcon';
import Button from '@components/common/Button';
import { parsePrice } from 'utilsRoot/index';

type Props = {
  additionPrice: MenuConfMealT['firstMealAdditionPrice'];
  carouselId: string;
  carouselName: string;
  menuConfigurationSettings?: MenuConfigurationSettingsT;
};
const OrderFormCarouselMealsDeleted = ({
  additionPrice,
  carouselId,
  carouselName,
  menuConfigurationSettings,
}: Props) => {
  const dispatch = useAppDispatch();
  const activeDay = useAppSelector(getMenuConfActiveDay);
  const baseDeliveryMeals = useAppSelector(getMenuConfBaseDeliveryMeals);
  const customDeliveryMeals = useAppSelector(getMenuConfCustomDeliveryMeals);
  const numberOfMealsAndSideOrders = useAppSelector(
    getSelectedMealsWithSideOrdersAmountForActiveDay
  );
  const loadingCarouselId = useAppSelector(getMenuConfLoadingCarouselId);

  const { maxMealsNumber = 0 } = menuConfigurationSettings || {};

  const isLoading =
    loadingCarouselId === carouselId || loadingCarouselId === 'all';
  const shouldDisableAddButton =
    isLoading || numberOfMealsAndSideOrders >= maxMealsNumber;

  const handleAddNewMeal = () => {
    // Dodaj usuniętego meala = zaktualizuj customDeliveryMeals, nie pobieraj danych dla nowego posiłku (jest w cache), zaktualizuj mealsPerDay
    const baseMeal = baseDeliveryMeals.find(
      (meal: BaseDeliveryMealT) => meal.name === carouselName
    );
    if (baseMeal) {
      const dietCaloriesMealId = baseMeal.dietCaloriesMealId;
      const customDeliveryMealsForActiveDay = customDeliveryMeals[activeDay];

      if (!!customDeliveryMealsForActiveDay) {
        const newCustomDeliveryMealsForActiveDay =
          prepareAddCustomDeliveryMeals(
            carouselId,
            dietCaloriesMealId,
            carouselName,
            customDeliveryMealsForActiveDay
          );

        dispatch(addCustomDeliveryMeal(newCustomDeliveryMealsForActiveDay));
        dispatch(updateMealsData({ value: true, carouselId }));
      }
    } else {
      console.log('Cannot find base meal within cached meals');
    }

    // sendEvent(EventName.AddMeal);
  };

  return additionPrice ? (
    <div className="display-flex flex-direction-column align-items-top spacer-vertical-40">
      <h3 className="h300 display-flex align-items-center spacer-bottom-24">
        {carouselName}
      </h3>
      <div className="carousel-meals__empty-state">
        <div className="display-flex align-items-center spacer-bottom-16">
          <EmptyStateMealIcon className="spacer-right-24" />
          <p className="label-l color-gray-900">Brak dodanego posiłku</p>
        </div>
        <Button
          blockButton
          colorBlank
          containerClass="color-primary"
          disabled={shouldDisableAddButton}
          icon={<FontAwesomeIcon className="font-size-16" icon={faPlus} />}
          label={`Dodaj posiłek za ${parsePrice(additionPrice)} zł`}
          onClick={handleAddNewMeal}
          sizeMedium
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default OrderFormCarouselMealsDeleted;
