import { isValidPhoneNumber } from 'react-phone-number-input';

export const initialPhoneNumberValues = {
  phoneNumber: '',
  phoneNumberPrefix: '48',
};

export const phoneNumberErrorText = 'Wprowadź poprawny numer (np. 123456789)';

export const validatePhoneNumber = (prefix: string, value?: number) => {
  const number = `${prefix}${value}`;
  const trimNumber = number.replace(/\s+/g, '');
  const numberWithPlus = `${trimNumber.includes('+') ? '' : '+'}${trimNumber}`;
  return isValidPhoneNumber(numberWithPlus);
};
