import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/pro-solid-svg-icons';

import { BannerTypeE } from '../types/banner.type';
import { UnreachableError } from 'utilsRoot/unreachableError';

export const getBannerIcon = (bannerType: BannerTypeE) => {
  switch (bannerType) {
    case BannerTypeE.Error:
      return faExclamationTriangle;
    case BannerTypeE.Warning:
      return faExclamationCircle;
    case BannerTypeE.Info:
      return faInfoCircle;
    case BannerTypeE.Success:
      return faCheckCircle;
    case BannerTypeE.Gray:
      return faInfoCircle;
    default:
      throw new UnreachableError(
        bannerType,
        `Unexpected banner type '${bannerType}' in getBannerIcon util`
      );
  }
};

export const getBannerClass = (
  bannerType: BannerTypeE,
  styles: { [className: string]: string }
) => {
  switch (bannerType) {
    case BannerTypeE.Error:
      return styles['dds-banner--error'];
    case BannerTypeE.Warning:
      return styles['dds-banner--warning'];
    case BannerTypeE.Info:
      return styles['dds-banner--info'];
    case BannerTypeE.Success:
      return styles['dds-banner--success'];
    case BannerTypeE.Gray:
      return styles['dds-banner--gray'];
    default:
      throw new UnreachableError(
        bannerType,
        `Unexpected banner type '${bannerType}' in getBannerClass util`
      );
  }
};

export const getBannerColor = (bannerType: BannerTypeE) => {
  switch (bannerType) {
    case BannerTypeE.Error:
      return '#ff5226';
    case BannerTypeE.Warning:
      return '#ffb700';
    case BannerTypeE.Info:
      return '#3399ff';
    case BannerTypeE.Success:
      return '#10b133';
    case BannerTypeE.Gray:
      return '#304161';
    default:
      throw new UnreachableError(
        bannerType,
        `Unexpected banner type '${bannerType}' in getBannerColor util`
      );
  }
};
