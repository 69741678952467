import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle';
import {
  mealExchangeSelectProgram,
  resetOrderForm,
  resetPreviouslySelectedDietDetails,
  restoreInitialOrderForm,
} from '@redux/actions/orderFormActions';
import { useAppDispatch } from '@config/hooks';
import { resetMenuConfiguration } from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.slice';

const AddAnotherDiet = () => {
  const dispatch = useAppDispatch();
  return (
    <div className="steps-page-basket__button">
      <Link to="/">
        <Button
          blockButton
          icon={<FontAwesomeIcon icon={faPlusCircle} />}
          label="Dodaj kolejną dietę"
          onClick={() => {
            dispatch(resetOrderForm());
            dispatch(restoreInitialOrderForm());
            dispatch(resetPreviouslySelectedDietDetails());
            dispatch(mealExchangeSelectProgram(''));
            dispatch(resetMenuConfiguration());
          }}
        />
      </Link>
    </div>
  );
};

export default AddAnotherDiet;
