import { RefObject, useState } from 'react';
import { useWindowSize } from 'react-use';

import {
  getMenuConfActiveDay,
  getMenuConfCustomDeliveryMeals,
  getMenuConfLoadingCarouselId,
} from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.selector';
import {
  MenuConfigurationSettingsT,
  MenuConfMealT,
} from '@features/orderForm/stepMenuConfiguration/types/menuConf.type';
import CarouselMeal from '@features/orderForm/stepMenuConfiguration/views/OrderFormCarouselMeals/CarouselMeal';
import CarouselMealSkeleton from '@features/orderForm/stepMenuConfiguration/views/OrderFormCarouselMeals/CarouselMealSkeleton';
import OrderFormCarouselMealsAddMeal from '@features/orderForm/stepMenuConfiguration/views/OrderFormCarouselMeals/OrderFormCarouselMealsAddMeal';
import OrderFormCarouselMealsDeleted from '@features/orderForm/stepMenuConfiguration/views/OrderFormCarouselMeals/OrderFormCarouselMealsDeleted';
import OrderFormCarouselMealsTitle from '@features/orderForm/stepMenuConfiguration/views/OrderFormCarouselMeals/OrderFormCarouselMealsTitle';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import { useAppSelector } from '@config/hooks';
import { useGetApiFormSettingsQuery } from '@services/api/form/open/open.api';
import Button from '@components/common/Button';

type Props = {
  companyId: string;
  isLastMeal: boolean;
  isTheOnlyMeal: boolean;
  mealOptionsList: MenuConfMealT;
  menuConfigurationSettings?: MenuConfigurationSettingsT;
  refs: Record<string, RefObject<HTMLDivElement>>;
};
const OrderFormCarouselMeals = ({
  companyId,
  isLastMeal,
  isTheOnlyMeal,
  mealOptionsList,
  menuConfigurationSettings,
  refs,
}: Props) => {
  // Selectors
  const activeDay = useAppSelector(getMenuConfActiveDay);
  const customDeliveryMeals = useAppSelector(getMenuConfCustomDeliveryMeals);
  const loadingCarouselId = useAppSelector(getMenuConfLoadingCarouselId);

  // Hooks
  const { width } = useWindowSize();
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [isBeginningSlide, setIsBeginningSlide] = useState(true);
  const [isEndSlide, setIsEndSlide] = useState(false);

  // Queries
  const { data: formSettings } = useGetApiFormSettingsQuery(undefined, {
    skip: !companyId,
  });

  // Consts
  const {
    calories = 0,
    carouselId = '',
    firstMealAdditionPrice = 0,
    name = '',
    nextMealAdditionPrice = 0,
    options = [],
  } = mealOptionsList;

  const { maxMealsNumber = 0 } = menuConfigurationSettings || {};
  const {
    orderSettings: {
      coursePhotoVisibleInForm = false,
      visibleIngredientsInDietly = false,
      visibleNutritionInDietly = false,
    } = {},
  } = formSettings || {};

  const showArrows =
    (options.length === 2 && width < 650) ||
    (options.length === 3 && width < 1024) ||
    (options.length === 4 && width < 1350) ||
    options.length > 4;

  const showPrevMealButton = !isBeginningSlide && showArrows;

  const showNextMealButton = !isEndSlide && showArrows;

  const goPrevSlide = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };
  const goNextSlide = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };
  const isMenuMealLoading =
    loadingCarouselId === carouselId || loadingCarouselId === 'all';

  const customDeliveryMealsForActiveDay = customDeliveryMeals[activeDay];

  const showDeletedMeal =
    !isMenuMealLoading &&
    activeDay &&
    customDeliveryMeals &&
    !!customDeliveryMealsForActiveDay &&
    !(carouselId in customDeliveryMealsForActiveDay);

  if (showDeletedMeal) {
    return (
      <OrderFormCarouselMealsDeleted
        additionPrice={firstMealAdditionPrice}
        carouselId={carouselId}
        carouselName={name}
        menuConfigurationSettings={menuConfigurationSettings}
      />
    );
  }

  return (
    <div className="carousel-meals">
      <OrderFormCarouselMealsTitle
        calories={calories}
        carouselId={carouselId}
        carouselName={name}
        isTheOnlyMeal={isTheOnlyMeal}
        menuConfigurationSettings={menuConfigurationSettings}
        options={options}
        refs={refs}
      />

      <div className="position-rel">
        <div className="carousel-meals__overlay carousel-meals__overlay--left" />
        {showPrevMealButton && (
          <Button
            colorBlank
            containerClass="carousel-meals__slider-button carousel-meals__slider-button--left"
            icon={<FontAwesomeIcon icon={faArrowLeft} />}
            onClick={goPrevSlide}
          />
        )}
        <Swiper
          breakpoints={{
            0: {
              slidesPerView: 'auto',
              spaceBetween: 8,
            },
          }}
          className="overflow-inherit"
          longSwipesRatio={0.2}
          observeParents
          observer
          observeSlideChildren
          onSwiper={setSwiper}
          onTransitionEnd={(swiper: SwiperClass) => {
            setIsBeginningSlide(swiper.isBeginning);
            setIsEndSlide(swiper.isEnd);
          }}
          threshold={10}
        >
          {options.map((meal, index) => {
            const isSelected =
              activeDay in customDeliveryMeals &&
              meal.dietCaloriesMealId ===
                (customDeliveryMeals[activeDay] || {})[carouselId]
                  ?.dietCaloriesMealId;

            return (
              <SwiperSlide
                className="height-auto width-auto"
                key={meal.dietCaloriesMealId}
              >
                {isMenuMealLoading ? (
                  <CarouselMealSkeleton />
                ) : (
                  <CarouselMeal
                    carouselId={carouselId}
                    carouselName={name}
                    coursePhotoVisibleInForm={coursePhotoVisibleInForm}
                    isSelected={isSelected}
                    meal={meal}
                    refs={refs}
                    slideHorizontally={() => {
                      if (!!swiper) {
                        swiper.slideTo(index);
                      }
                    }}
                    visibleIngredients={visibleIngredientsInDietly}
                    visibleNutrition={visibleNutritionInDietly}
                  />
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
        {showNextMealButton && (
          <Button
            colorBlank
            containerClass="carousel-meals__slider-button carousel-meals__slider-button--right"
            icon={<FontAwesomeIcon icon={faArrowRight} />}
            onClick={goNextSlide}
          />
        )}
        <div className="carousel-meals__overlay carousel-meals__overlay--right" />
      </div>
      {isLastMeal && (
        <OrderFormCarouselMealsAddMeal
          additionPrice={nextMealAdditionPrice}
          carouselId={carouselId}
          carouselName={name}
          maxMealsNumber={maxMealsNumber}
        />
      )}
    </div>
  );
};

export default OrderFormCarouselMeals;
