import React from 'react';
import { OrderFormT } from 'typesRoot/orderForm.type';
import { FormSettingsT } from 'typesRoot/settings.type';
import { useAppDispatch, useAppSelector } from '@config/hooks';
import { mealExchangeChangeEditModalClickedArea } from '@redux/actions/orderFormActions';
import DaysAmount from '../../../common/DaysAmount';
import {
  getLatestSelectedDay,
  getSelectedDaysLength,
  handleChangeDays,
} from '../utils/selectDays.utils';
import { CustomDeliveryMealsT } from '@features/orderForm/stepMenuConfiguration/types/menuConf.type';
import { DateTime } from 'luxon';
import { checkIsDayInMealExchange } from 'utilsRoot/index';
import { getHasMenuConfigurationStarted } from '@features/orderForm/stepMenuConfiguration/services/redux/stepMenuConfiguration.selector';

type Props = {
  customDeliveryMeals: CustomDeliveryMealsT;
  formSettings?: FormSettingsT;
  handleChangeDaysOnClick: (
    operation: 'add' | 'minus',
    direction: 'minus' | 'plus'
  ) => void;
  orderForm: OrderFormT;
  selectedDays: any;
  selectedProgram: string;
  warningInput: boolean;
};

const NumberOfDays = ({
  customDeliveryMeals,
  formSettings,
  handleChangeDaysOnClick,
  orderForm,
  selectedDays,
  selectedProgram,
  warningInput,
}: Props) => {
  const dispatch = useAppDispatch();

  const isMenuConfigurationStarted = useAppSelector(
    getHasMenuConfigurationStarted
  );

  return (
    <div className="spacer-bottom-12 spacer-right-30">
      <span className="label-m color-gray-500 spacer-bottom-16 display-block">
        Liczba dni
      </span>

      <DaysAmount
        change={event => dispatch(handleChangeDays(event))}
        days={orderForm?.days ? orderForm?.days : ''}
        decrease={() => {
          if (
            selectedProgram === 'menuConfiguration' &&
            getSelectedDaysLength(selectedDays) > 1 &&
            checkIsDayInMealExchange(
              DateTime.fromMillis(
                getLatestSelectedDay(selectedDays)
              ).toISODate(),
              customDeliveryMeals
            )
          ) {
            dispatch(
              mealExchangeChangeEditModalClickedArea(
                'secondStepEditMealExchangeDay'
              )
            );
          } else {
            handleChangeDaysOnClick('minus', 'minus');
          }
        }}
        increase={() => {
          handleChangeDaysOnClick('add', 'plus');
        }}
        onClickInput={() => {
          if (isMenuConfigurationStarted && warningInput) {
            dispatch(
              mealExchangeChangeEditModalClickedArea(
                'secondStepEditMealExchangeInput'
              )
            );
          }
        }}
      />
    </div>
  );
};

export default NumberOfDays;
